import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export interface TinyProgressBarProps {
  initialValue?: number; // Integer between 0-100
  onChange?: (value: number) => void;
  heightInRem?: number;
  widthInPercent?: number;
}

export function TinyProgressBar({
  initialValue,
  heightInRem,
  widthInPercent,
}: TinyProgressBarProps) {
  const [progress, setProgress] = useState(initialValue);

  useEffect(() => {
    setProgress(initialValue);
  }, [initialValue]);

  return (
    <Wrapper
      height={heightInRem}
      width={widthInPercent || 100}
      className="vsly-pb-wrapper"
    >
      <Progress progress={progress} className="vsly-pb-value" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: ${(props: { height: number; width: number }) => props.height}rem;
  width: ${(props: { height: number; width: number }) => props.width}%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  background: rgba(216, 216, 216, 0.48);
  border-radius: 1rem;
  padding: 0;
  margin: 0;
`;

const Progress = styled.div`
  height: 100%;
  width: ${(props: { progress: number }) => Math.round(props.progress)}%;
  background: rgba(155, 212, 255, 0.48);
  border-radius: 1rem;
  padding: 0;
  margin: 0;
`;
