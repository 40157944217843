import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { SearchInput } from '@/components/text-input';
import { VSpace } from '@/components/spacing';
import { Campaigns } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/campaigns';
import { AdSearchContext } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/context';
import { Spinner } from '@/Spinner';
import { LoadMoreBtn } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/shared';

const Container = styled.div`
  width: 100%;
  font-family: Inter, serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

const Search = styled(SearchInput)`
  && {
    background-color: #f3f3f3;
    border: none;
    padding: 1rem 3.2rem 1rem 1.5rem;
    font-size: 1.5rem;
  }
`;

export const AdSearch: FC = () => {
  const { searchLoading, setState, state, loadMoreCampaigns } =
    useContext(AdSearchContext);
  const [loading, setLoading] = useState(false);
  return (
    <Container>
      <Search
        placeholder="Search for a specific campaign / Ad set / Ad?"
        onChange={(x) => {
          setState((draft) => {
            draft.query = x?.target?.value;
          });
        }}
      />
      <VSpace />
      {searchLoading ? (
        <SpinnerWrapper>
          <Spinner size={50} />
        </SpinnerWrapper>
      ) : (
        <>
          <Campaigns />
          {state.nextCampaigns && (
            <>
              <VSpace value={2} />
              <LoadMoreBtn
                name="campaigns"
                loading={loading}
                onClick={async () => {
                  setLoading(true);
                  await loadMoreCampaigns();
                  setLoading(false);
                }}
              />
            </>
          )}
        </>
      )}
    </Container>
  );
};

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
