import { useEffect, useRef, useState } from 'react';
import { DeviceType } from '@/utils/definitions';
import { newLayeredRulingCtx } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import { CatalogWidgetProps } from '@/webapi/use-widget-catalog-api';

export function useLayeredRuling(
  device: DeviceType,
  currentSchema: CatalogWidgetProps,
  isPostPurchase?: boolean,
) {
  const [showNewRuling, setShowRuling] = useState(false);
  const autoScrollDelayMs = useRef(700);
  const layeredRulingCtx = newLayeredRulingCtx(
    device,
    showNewRuling,
    autoScrollDelayMs,
    isPostPurchase,
  );

  useEffect(() => {
    autoScrollDelayMs.current = !showNewRuling ? 800 : 100;
  }, [showNewRuling]);
  const hasLegacyAdvancedRuling =
    currentSchema.settings.loading?.loadingEnv?.recommendationOptions
      ?.advancedRuling?.length > 0;
  return {
    showNewRuling,
    setShowRuling,
    layeredRulingCtx,
    isAdvancedEditingV2Enabled: !hasLegacyAdvancedRuling,
    autoScrollDelayMs,
  };
}
