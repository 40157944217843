import React, { useState } from 'react';
import styled from 'styled-components';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { FontFamilyInput } from '@/features/editor/widgets/custom-widget/inputs/typography/font-family';
import { FontWeightInput } from '@/features/editor/widgets/custom-widget/inputs/typography/font-weight';
import { FontStyleInput } from '@/features/editor/widgets/custom-widget/inputs/typography/font-style';
import { FontSizeInput } from '@/features/editor/widgets/custom-widget/inputs/typography/font-size';
import { FontColorInput } from '@/features/editor/widgets/custom-widget/inputs/typography/font-color';
import { TextAlignInput } from '@/features/editor/widgets/custom-widget/inputs/typography/text-align';
import { TextTransformInput } from '@/features/editor/widgets/custom-widget/inputs/typography/text-transform';
import { LetterSpacingInput } from '@/features/editor/widgets/custom-widget/inputs/typography/letter-spacing';
import { LineHeightInput } from '@/features/editor/widgets/custom-widget/inputs/typography/line-height';
import { maybe } from '@/features/details/utils';

export function FontControlsInput(props: GenericInputProps) {
  const [fontSize, setFontSize] = useState(``);
  const hideTextAlign = maybe(
    () =>
      // eslint-disable-next-line react/destructuring-assignment
      props.component?.specs?.find((spec) => spec.key === `font`)?.values
        .hideTextAlign,
  );
  const hideLineHeight = maybe(
    () =>
      // eslint-disable-next-line react/destructuring-assignment
      props.component?.specs?.find((spec) => spec.key === `font`)?.values
        .hideLineHeight,
  );
  return (
    <Wrapper>
      <FontFamilyInput {...props} />
      <FontSizeInput setFontSize={setFontSize} {...props} />
      <FontColorInput {...props} />
      <FontWeightInput {...props} />
      <FontStyleInput {...props} />
      <LetterSpacingInput {...props} />
      <TextTransformInput {...props} />
      {(!hideTextAlign || !hideLineHeight) && <TextAlignInput {...props} />}
      {(!hideLineHeight || !hideTextAlign) && (
        <LineHeightInput fontSize={fontSize} {...props} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 4rem;
  grid-gap: 1.5rem;
  align-items: center;
  overflow: hidden;

  && > :nth-child(1) {
    grid-column: span 4;
    grid-row-start: 1;
  }

  && > :nth-child(2) {
    grid-column: span 1;
    grid-row-start: 1;
  }

  && > :nth-child(3) {
    grid-column: span 1;
    grid-row-start: 1;
  }

  && > :nth-child(4) {
    grid-column: span 2;
    grid-row-start: 2;
  }

  && > :nth-child(5) {
    grid-column: span 2;
    grid-row-start: 2;
  }

  && > :nth-child(6) {
    grid-column: span 1;
    grid-row-start: 2;
  }

  && > :nth-child(7) {
    grid-column: span 1;
    grid-row-start: 2;
  }

  && > :nth-child(8) {
    grid-column: span 4;
    grid-row-start: 3;
  }
  && > :nth-child(9) {
    grid-column: span 2;
    grid-row-start: 3;
  }
`;
