import React, { useContext } from 'react';
import styled from 'styled-components';
import { BsPlayFill } from 'react-icons/bs';
import { FakeClickCta } from '@/features/editor/widgets/fake-click/shared';
import { FakeClickContext } from '@/features/editor/widgets/fake-click/context';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';

export function FakeClickEmptyState() {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);
  const { record } = useContext(FakeClickContext);

  return (
    <Wrapper device={device}>
      <span>
        CLICK ON THE &quot;START&quot; BUTTON TO START RECORDING <br />
        WE WILL RECORD YOUR INTERACTIONS AND TRY <br />
        TO MOCK UP THOSE WHEN EXPERIENCE STARTS
      </span>

      <FakeClickCta onClick={record}>
        <BsPlayFill size={18} /> <span>Start</span>
      </FakeClickCta>
    </Wrapper>
  );
}

interface WP {
  device?: DeviceType;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 4rem;
  justify-items: center;
  justify-content: center;
  align-items: center;
  padding: ${(p: WP) =>
    p?.device === DeviceType.Desktop ? `1rem 0` : `1rem 0.6rem`};

  font-size: ${(p: WP) =>
    p?.device === DeviceType.Desktop ? `1.3rem` : `1.4rem`};

  && > span {
    font-weight: bold;
  }

  button {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: min-content min-content;
    justify-content: center;
    align-items: center;
    width: fit-content !important;
    font-size: 1.4rem !important;
    font-weight: bold !important;
    border-radius: 2rem !important;
    padding: 0.9rem 2.5rem !important;
  }
`;
