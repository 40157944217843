import * as React from 'react';
import { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { SectionTitle } from '@/features/editor/widgets/custom-widget/shared/shared-styles';
import { useDetachedState } from '@/components/hooks/use-detached-state';
import { EditorContext } from '@/features/editor/context/editor-context';
import { AccountContext } from '@/features/account-context';
import { UserStatsKind } from '@/webapi/use-user-stats';

export interface BlueprintOnboardingProps {
  title: string;
  hint: string;
  duration?: number;
  delay?: number;
  nextRef?: React.MutableRefObject<HTMLElement>;
  onComplete?: () => void;
  maxHeight?: string;
  zIndex?: number;
}

export function useBlueprintOnboarding(widgetId: string) {
  const { isGoalReached, isDuplicateGoal, trackIfGoalNotReached } =
    useContext(AccountContext);
  const {
    experienceState: { currentExperience },
  } = useContext(EditorContext);

  const dedupKey = useMemo(() => `${currentExperience?.id}-${widgetId}`, []);

  const shouldRemove = useMemo(
    () =>
      isGoalReached(UserStatsKind.SHOULD_SHOW_NEW_WIDGET_ONBOARDING) ||
      isDuplicateGoal(dedupKey),
    [],
  );

  useMemo(() => {
    if (!shouldRemove) {
      trackIfGoalNotReached(
        UserStatsKind.SHOULD_SHOW_NEW_WIDGET_ONBOARDING,
        dedupKey,
      ).then();
    }
  }, []);

  return {
    shouldRunOnboarding: !shouldRemove,
  };
}

export function BlueprintOnboarding({
  title,
  hint,
  delay,
  duration,
  nextRef,
  onComplete,
  maxHeight,
  zIndex,
}: BlueprintOnboardingProps) {
  const [, setIsContentShow, isContentShownRef] = useDetachedState(false);
  const [, setIsTimeToRemove, isTimeToRemoveRef] = useDetachedState(false);
  const [, setIsRemoved, isRemovedRef] = useDetachedState(false);

  useMemo(() => {
    setTimeout(() => {
      setIsContentShow(true);
    }, delay || 1);

    setTimeout(() => {
      setIsTimeToRemove(true);

      setTimeout(() => {
        setIsRemoved(true);
        if (nextRef?.current) {
          nextRef.current.scrollIntoView({
            behavior: `smooth`,
            block: `center`,
          });
        }
        onComplete && onComplete();
      }, 500);
    }, (delay || 1) + (duration || 2000) + 500);
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!isRemovedRef?.current && (
        <Wrapper zIndex={zIndex} isTimeToRemove={isTimeToRemoveRef.current}>
          {isContentShownRef?.current && (
            <>
              <SectionTitle>{title}</SectionTitle>
              <MessageBox maxHeight={maxHeight}>{hint}</MessageBox>
            </>
          )}
        </Wrapper>
      )}
    </>
  );
}

interface WP {
  isTimeToRemove: boolean;
  maxHeight?: string;
  zIndex?: number;
}

const Wrapper = styled.div`
  position: absolute;
  overflow-y: visible;
  inset: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background: white;

  transition: opacity 0.5s ease-in;
  min-height: 15rem;
  opacity: ${(p: WP) => (p.isTimeToRemove ? 0 : 1)};
  z-index: ${(p: WP) => (p?.zIndex ? p.zIndex : 10)};

  && > * {
    animation: fadein 0.5s ease-out;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100;
    }
  } ;
`;

const MessageBox = styled.div`
  background-color: #f8f9fb;
  height: calc(100% - 2rem);
  min-height: 13rem;
  max-height: ${(p: WP) => (p?.maxHeight ? p.maxHeight : `unset`)};
  margin-top: 1rem;
  width: 100%;
  color: #a0a9af;
  font-family: 'JetBrains Mono', serif;
  font-weight: bold;
  font-size: 1.6rem;
  text-align: center;
  border-radius: 1.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20%;
  line-height: 2.5rem;
`;
