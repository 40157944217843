import React, { useContext, useRef, useState } from 'react';
import { TargetingButton } from '@/features/editor/widgets/shared/targeting-controls/targeting-button';
import { EditorContext } from '@/features/editor/context/editor-context';
import { TargetingDeviceType } from '@/webapi/use-experience-api';
import { formatEnum } from '@/utils/types';
import { DevicesModal } from '@/features/editor/widgets/shared/modals/devices-modal';

export function DeviceButton({ isDisabled }: { isDisabled?: boolean }) {
  const {
    experienceState: { currentExperience },
  } = useContext(EditorContext);
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const onClick = () => {
    setIsVisible(true);
  };

  return (
    <>
      <DevicesModal
        fromRef={ref}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
      <TargetingButton
        wrapperRef={ref}
        title="Devices"
        subtitle={`${formatEnum(
          currentExperience.targeting.device
            ? TargetingDeviceType[currentExperience.targeting.device]
            : TargetingDeviceType.ALL_DEVICES,
        )}`}
        onClick={onClick}
        isDisabled={isDisabled}
      />
    </>
  );
}
