import React, { useContext } from 'react';
import styled from 'styled-components';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';

export function AppsCatalogGridDivider({
  children,
  longTiles,
}: {
  children: any;
  longTiles: boolean;
}) {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);
  return (
    <Wrapper
      className="apps-catalog-divider"
      longTiles={longTiles}
      isDesk={device === DeviceType.Desktop}
    >
      {children}
      <Divider />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1.5rem;
  color: #949ea7;
  font-family: Inter, serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: -0.17px;
  padding: 2.5rem 0 2.5rem 1rem;

  max-width: ${(p) =>
    /* eslint-disable-next-line no-nested-ternary */
    p.longTiles
      ? p.isDesk
        ? `40rem`
        : `50rem`
      : p.isDesk
      ? `31rem`
      : `65rem`};
  align-items: center;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dcdcdc;
  opacity: 0.8;
`;
