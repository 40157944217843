import React, { useContext, useRef, useState } from 'react';
import { TargetingButton } from '@/features/editor/widgets/shared/targeting-controls/targeting-button';
import { EditorContext } from '@/features/editor/context/editor-context';
import { Experience, Targeting } from '@/webapi/use-experience-api';
import { formatEnum, truncate } from '@/utils/types';
import { AudienceModal } from '@/features/editor/widgets/shared/modals/audience/audience-modal';
import { Audience } from '@/features/dashboard/audiences/models';

export function AudienceButton({ isDisabled }: { isDisabled?: boolean }) {
  const {
    resources: { audiences },
    experienceState: { currentExperience },
    newAudiences,
  } = useContext(EditorContext);
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const onClick = () => {
    setIsVisible(true);
  };
  const subtitle = getAudienceName(
    [...audiences, ...newAudiences],
    currentExperience,
  );
  return (
    <>
      <AudienceModal
        fromRef={ref}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
      <TargetingButton
        wrapperRef={ref}
        title="Audience"
        subtitle={subtitle}
        onClick={onClick}
        isDisabled={isDisabled}
      />
    </>
  );
}

export function formatAudience(
  targeting: Targeting,
  showTwo = true,
  maxSize = 40,
): string {
  if (targeting.userDefinedAudienceId) {
    return `Primary Audience`;
  }
  if (targeting?.other?.length > 0) {
    return `Custom Audience`;
  }

  if (targeting?.facebook) {
    return `Meta campaigns`;
  }
  if (!targeting?.segments || targeting?.segments?.length === 0) {
    return `All Visitors`;
  }
  if (targeting?.segments?.length === 1) {
    return truncate(`${formatEnum(targeting.segments[0], true)}`, 40);
  }
  if (showTwo && targeting?.segments?.length === 2) {
    return truncate(
      `${formatEnum(targeting.segments[0], true)}, ${formatEnum(
        targeting.segments[1],
        true,
      )}`,
      maxSize,
    );
  }
  if (!showTwo && targeting?.segments?.length > 1) {
    return `${targeting.segments.length} Audiences`;
  }

  return `${formatEnum(targeting.segments[0], true)} and ${
    targeting.segments.length - 1
  } Others`;
}

function getAudienceName(
  audiences: Array<Audience>,
  currentExperience: Experience,
) {
  const userDefinedAudienceName = audiences
    ?.find((a) => a.id === currentExperience.targeting.userDefinedAudienceId)
    ?.name?.substring(0, 40);
  return (
    userDefinedAudienceName ||
    formatAudience(currentExperience.targeting, false)
  );
}
