import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  Product,
  ProductsResponse,
} from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/context/model';
import { usePagination } from '@/webapi/use-pagination';
import { useCatalogApi } from '@/webapi/use-catalog-api';
import { ManualProduct } from '@/webapi/use-widget-catalog-api';

export interface SlotData {
  id: number;
  product?: Product;
}

export const ManualProductsPicker = React.createContext({} as ProductPickerCtx);

type Slots = Array<{
  id: number;
  product?: Product;
}>;

export function newProductsPickerCtx(
  productIds: Array<string> | Array<ManualProduct>,
  size?: number,
): ProductPickerCtx {
  const [openSlot, setOpenSlot] = useState<undefined | number>();
  const [searchText, setSearchText] = useState<undefined | string>();
  const [productWithoutImage, setProductWithoutImage] = useState<
    undefined | Product
  >();

  const { listProducts, listProductsByText, listProductsByIds } =
    useCatalogApi();
  const {
    current,
    setCurrent,
    setArgs,
    page,
    maxPages,
    total,
    loading,
    setPage,
  } = usePagination(listProductsByText, 25);

  const debouncedSearch = useDebouncedCallback((value) => {
    setCurrent({ products: [], total: 0 });
    setArgs({ query: value });
  }, 500);
  const [slots, setSlots] = useState<Slots>(defaultSlots(size));

  useEffect(() => {
    changeSlotSize(slots, size, setSlots);
  }, [size]);

  useEffect(() => {
    debouncedSearch(searchText);
  }, [searchText]);

  async function legacyInit() {
    const response = await listProductsByIds(productIds as Array<string>);
    !!response?.products && setCurrent(response);
    setSlots(mergeSlots(slots, response));
  }

  async function init(ids: Array<ManualProduct>) {
    const response = await listProductsByIds(ids.map((x) => x.productId));
    const colorProducts = response?.products
      ?.filter(
        (p) =>
          !!ids?.find(
            (x) =>
              x.productId === p.id &&
              (x.mainValue || ``) === (p.mainValue || ``),
          ),
      )
      .sort((a, b) => {
        const predicate = (product) => (value) =>
          value.productId === product.id &&
          value.mainValue === (product.mainValue || ``);
        const aIdx = ids.findIndex(predicate(a));
        const bIdx = ids.findIndex(predicate(b));
        return aIdx - bIdx;
      });
    if (colorProducts) {
      response.products = colorProducts;
      setCurrent(response);
      setSlots(mergeSlots(slots, response));
    }
  }

  async function initialise() {
    if (productIds?.length > 0) {
      const isLegacy = typeof productIds[0] === `string`;
      if (isLegacy) {
        await legacyInit();
      } else {
        await init(productIds as Array<ManualProduct>);
      }
    } else {
      const response = await listProducts(``);
      !!response?.products && setCurrent(response);
    }
  }

  useEffect(() => {
    initialise().then();
  }, []);

  function chooseProduct(product: Product) {
    if (product.missingVariantImageMapping) {
      setProductWithoutImage(product);
    } else {
      setProductWithoutImage(undefined);
      const next = nextSlots(slots, openSlot, product);
      setSlots(next);
      setOpenSlot(undefined);
    }
  }

  return {
    productWithoutImage,
    unsetProductWithoutImage: () => setProductWithoutImage(undefined),
    slots,
    setSlots,
    openSlot,
    setOpenSlot,
    searchText,
    setSearchText: (x: string) => {
      setSearchText(x);
      debouncedSearch(x);
    },
    products: current?.products || [],
    chooseProduct,
    page,
    maxPages,
    total,
    loading,
    setPage,
    init,
  };
}

export interface ProductPickerCtx {
  init: (a: Array<ManualProduct>) => void;
  page: number;
  maxPages: number;
  total: number;
  loading: boolean;
  setPage: (x: number) => void;
  slots: Array<SlotData>;
  openSlot?: number;
  setOpenSlot: (x: number | undefined) => void;
  setSlots: (x: Array<SlotData>) => void;
  searchText: string | undefined;
  setSearchText: (x: string) => void;
  products: Array<Product>;
  productWithoutImage?: Product;
  unsetProductWithoutImage: () => void;
  chooseProduct: (product: Product) => void;
}

function defaultSlots(size: any) {
  return [...Array(size || 6).keys()].map((x) => x + 1).map((x) => ({ id: x }));
}

function mergeSlots(
  slots: Array<{ id: number; product?: Product }>,
  response: ProductsResponse,
) {
  return slots.map(({ id }, idx) => ({
    id,
    product: response.products?.[idx],
  }));
}

function nextSlots(
  slots: Array<{ id: number; product?: Product }>,
  openSlot: number,
  product: Product,
) {
  return [...slots].map((x) => {
    if (x.id === openSlot) {
      x.product = product;
    }
    return x;
  });
}

export function changeSlotSize(
  slots: Array<{ id: number; product?: Product }>,
  size: number,
  setSlots: (
    value:
      | ((
          prevState: Array<{ id: number; product?: Product }>,
        ) => Array<{ id: number; product?: Product }>)
      | Array<{ id: number; product?: Product }>,
  ) => void,
) {
  if (slots.length > size) {
    setSlots(slots.filter((x) => x.id <= size));
  } else if (slots.length < size) {
    const toAdd = size - slots.length;
    const newSlots = [...Array(toAdd).keys()]
      .map((x) => slots.length + x + 1)
      .map((x) => ({ id: x }));
    setSlots(slots.concat(newSlots));
  }
}
