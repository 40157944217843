import { useContext } from 'react';
import * as React from 'react';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';
import { DropdownInputOption } from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { RadioButtons } from '@/features/editor/widgets/custom-widget/inputs/shared/radio-buttons';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';

export const POST_PURCHASE_SUB_HEADER_OPTIONS: DropdownInputOption[] = [
  {
    label: `Add "Product Name" to your order and save "Discount"`,
    value: `Add $product to your order and save $discount`,
  },
  {
    label: `"Product Name"`,
    value: `$product`,
  },
  {
    label: `Add "Product Name"`,
    value: `Add $product`,
  },
  {
    label: `Buy "Product Name"`,
    value: `Buy $product`,
  },
];

export function PostPurchaseSubHeaderInput() {
  const { props, setProps } = useContext(PostPurchaseContext);
  const allowCustomEdit = useFeatureBit(
    FeatureBit.ENABLE_POST_PURCHASE_EXPERIMENTAL_OPTIONS,
  );

  const onChange = (val: string) => {
    setProps((draft) => {
      draft.content.subHeader = val;
    });
  };

  return (
    <RadioButtons
      title="Sub header message"
      defaultValue={
        props?.content?.subHeader ||
        POST_PURCHASE_SUB_HEADER_OPTIONS?.[0]?.value
      }
      onChange={onChange}
      options={POST_PURCHASE_SUB_HEADER_OPTIONS}
      allowCustomEdit={allowCustomEdit}
      customEditHint="* you can use $product, $discount, $afterDiscountPrice or $beforeDiscountPrice to insert dynamic values"
    />
  );
}
