export interface SolidColor {
  // rgba notation
  red?: number;
  green?: number;
  blue?: number;
  alpha?: number;

  // hsv notation
  hue?: number;
  saturation?: number;
  value?: number;

  valid?: boolean;
}

export interface GradientPoint extends SolidColor {
  left: number;
}

export interface RadialGradientColor {
  points: GradientPoint[];
}

export interface LinearGradientColor {
  points: GradientPoint[];
  angle: number;
}

export enum ColorMode {
  SOLID = `solid`,
  LINEAR = `linear`,
  RADIAL = `radial`,
}
