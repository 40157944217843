import React, { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import styled, { css } from 'styled-components';
import { Experience, ExperienceStatus } from '@/webapi/use-experience-api';
import { EditorContext } from '@/features/editor/context/editor-context';
import { useCanPublish } from '@/features/accounts/permissions';
import { centered, useSharedElement } from '@/components/use-shared-element';
import { ConfirmModal } from '@/components/confirm-modal';
import { SharedElementOverlay } from '@/components/shared-element-overlay';
import { Checkbox } from '@/components/checkbox';
import { EDITOR_EXPERIENCE_CACHE } from '@/components/hooks/use-cached-auto-save';
import { UseExperienceControls } from '@/features/editor/context/use-experience-controls';
import { VSpace } from '@/components/spacing';

function hasAllocChanged(currentExperience: Experience) {
  return !!currentExperience.variants.find(
    ({ publishedChance, chance }) =>
      typeof publishedChance !== `undefined` && publishedChance !== chance,
  );
}

export function usePublishModal(
  originRef: React.MutableRefObject<HTMLElement>,
  _currentExperience?: Experience,
  _experienceControls?: UseExperienceControls,
) {
  const edCtx = useContext(EditorContext);
  const experienceControls = _experienceControls || edCtx?.experienceControls;
  const currentExperience =
    _currentExperience || edCtx?.experienceState?.currentExperience;

  const canPublish = useCanPublish();

  const [shouldResetData, setShouldResetData] = useState(true);

  const {
    props: publishConfirmProps,
    show,
    hide,
  } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `#000000`,
        border: `2px solid #000000`,
      },
      extraTo: {
        background: `#FFFFFF`,
        border: `2px solid #FFFFFF`,
      },
    },
    originRef,
    () => centered(isRePublish ? 19 : 17, 42),
  );
  const isRePublish = currentExperience?.status === ExperienceStatus.PUBLISHED;

  let title = isRePublish ? `Changes were made, Publish?` : `Publish?`;
  let description: any = `Are you sure you want to publish this experience? Once confirm your target audience will start to receive it immediately`;
  let yesCaption = `Yes, Publish`;
  let noCaption = `No`;

  if (isRePublish) {
    description = (
      <ModalWrapper>
        <Checkbox
          initialValue={shouldResetData}
          onSelection={setShouldResetData}
        />
        <div className="header">
          Clear past collected data?{` `}
          <div className="body">
            Reset past collected data in order to start a fresh A/B experiment.
          </div>
        </div>
      </ModalWrapper>
    );
    yesCaption = `Yes, Publish`;
    noCaption = `No`;
  }

  if (hasAllocChanged(currentExperience)) {
    title = `Do you want to change the allocation?`;
    description = (
      <div>
        <VSpace value={1} />
        <p>A new test version will be made</p>
      </div>
    );
    yesCaption = `Yes`;
  }

  if (!canPublish) {
    title = `Publish?`;
    description = `Your user has insufficient privileges to publish an experience`;
    yesCaption = `Dismiss`;
  }

  const onDiscard = async () => {
    hide();
  };

  const onPublish = useCallback(async () => {
    if (canPublish) {
      EDITOR_EXPERIENCE_CACHE.disableWrites();
      await experienceControls.publish(shouldResetData);
      schedulePublishSuccessToast();
      hide();
      experienceControls.closeAndNavigateToDashboard();
      EDITOR_EXPERIENCE_CACHE.enableWrites();
    } else {
      hide();
    }
  }, [currentExperience, shouldResetData]);

  return {
    publishConfirmProps,
    onDiscard,
    onPublish,
    show,
    hide,
    canPublish,
    yesCaption,
    noCaption,
    title,
    description,
  };
}

const ModalWrapper = styled.div`
  display: grid;
  grid-template-columns: 3rem 1fr;
  text-align: left;
  grid-gap: 1rem;

  .header {
    color: #8c8c8c;
    font-weight: bold;
    line-height: 1.5;
  }

  .body {
    font-weight: 500;
  }
`;

export function PublishModal({
  publishConfirmProps,
  canPublish,
  yesCaption,
  noCaption,
  description,
  hide,
  title,
  onDiscard,
  onPublish,
}) {
  return (
    <SharedElementOverlay {...publishConfirmProps} heightMultiplier={1.2}>
      <ConfirmModal
        neutral
        onlyDismiss={!canPublish}
        yesCaption={yesCaption}
        noCaption={noCaption}
        title={title}
        description={description}
        onDiscard={onDiscard}
        onClose={hide}
        onConfirm={onPublish}
        btnSize="medium"
      />
    </SharedElementOverlay>
  );
}

function schedulePublishSuccessToast() {
  setTimeout(
    () =>
      toast(`Published Successfully`, {
        theme: `colored`,
        type: `success`,
        className: css({
          fontFamily: `JetBrains Mono, Serif`,
          fontWight: `700`,
        }),
      }),
    1000,
  );
}
