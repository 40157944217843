import { useContext } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';
import { StylingComponentsGroup } from '@/features/editor/widgets/post-purchase/styling/group';
import { PostPurchaseTextualInput } from '@/features/editor/widgets/post-purchase/inputs/textual';
import { PostPurchaseTextualAreaInput } from '@/features/editor/widgets/post-purchase/inputs/textual-area';
import { QuickEditTitle } from '@/features/editor/widgets/custom-widget/inputs/shared/radio-buttons';

export function PostPurchaseDisclaimer() {
  const { props, setProps } = useContext(PostPurchaseContext);
  return (
    <>
      <StylingComponentsGroup title="Disclaimer Content">
        <TextEditSection>
          <QuickEditTitle small>Disclaimer Text</QuickEditTitle>
          <PostPurchaseTextualAreaInput
            defaultValue={props.content.disclaimer || ``}
            onChange={(p) =>
              setProps((draft) => {
                draft.content.disclaimer = p;
              })
            }
          />
        </TextEditSection>
      </StylingComponentsGroup>

      <StylingComponentsGroup title="Disclaimer Style">
        <PostPurchaseTextualInput
          showAlignment
          defaultValue={props?.style?.disclaimer}
          onChange={(p) =>
            setProps((draft) => {
              draft.style.disclaimer = p;
            })
          }
        />
      </StylingComponentsGroup>
    </>
  );
}

const TextEditSection = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 1rem;
`;
