/* eslint-disable camelcase */
import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { GoPrimitiveDot } from 'react-icons/go';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { FBAd } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/models';
import { CheckboxInput } from '@/features/editor/widgets/custom-widget/inputs/shared/checkbox-input';
import {
  AdSearchContext,
  AdSearchState,
} from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/context';
import {
  adSetHasSelectedChild,
  campaignHasSelectedChild,
  clone,
  LoadMoreBtn,
} from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/shared';
import { FacebookAudienceContext } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/context';
import {
  FacebookAd,
  FacebookTargeting,
} from '@/components/query-builder/models';

const Container = styled.div`
  width: 100%;
  margin: 1rem 2rem 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

const Header = styled.div`
  width: 100%;
  font-size: 1.4rem;
  color: #576470;
  margin-bottom: 1rem;
  display: flex;
  justify-content: start;
  align-items: center;

  span {
    text-align: start;
    width: 550px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    user-select: text;
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

type Props = {
  ads: Array<FBAd>;
  campaignIdx: number;
  adSetIdx: number;
  next: string;
  adsetId: string;
  campaignId: string;
};
export const Ads: FC<Props> = ({
  ads,
  adSetIdx,
  campaignIdx,
  next,
  adsetId,
  campaignId,
}) => {
  if (!ads || ads?.length === 0) {
    return null;
  }
  const { loadMoreAds } = useContext(AdSearchContext);
  const [loading, setLoading] = useState(false);
  return (
    <Container>
      {ads?.map((ad, idx) => (
        <Ad
          key={ad.id}
          ad={ad}
          idx={idx}
          campaignIdx={campaignIdx}
          adSetIdx={adSetIdx}
        />
      ))}
      {next && (
        <LoadMoreBtn
          loading={loading}
          name="ads"
          onClick={async () => {
            setLoading(true);
            await loadMoreAds(next, adsetId, campaignId);
            setLoading(false);
          }}
        />
      )}
    </Container>
  );
};

const Ad: FC<{
  ad: FBAd;
  idx: number;
  campaignIdx: number;
  adSetIdx: number;
}> = ({ ad, idx, campaignIdx, adSetIdx }) => {
  const imageUrl = ad.adCreatives?.[0]?.imageUrl;
  const { setState, state } = useContext(AdSearchContext);
  const { targeting } = useContext(FacebookAudienceContext);
  const onSelect = (isSelected) => {
    handleAdSelection(state, campaignIdx, adSetIdx, ad, isSelected, targeting);
    setState((draft) => {
      draft.campaigns[campaignIdx].adSets[adSetIdx].ads[idx].isSelected =
        isSelected;
      draft.campaigns[campaignIdx].adSets[adSetIdx].isSelected =
        isSelected || adSetHasSelectedChild(draft, campaignIdx, adSetIdx);
      draft.campaigns[campaignIdx].isSelected =
        isSelected || campaignHasSelectedChild(draft, campaignIdx);
    });
  };
  const [isOpen, setIsOpen] = useState(false);
  const splitIdx = ad.name?.toLowerCase().indexOf(state?.query?.toLowerCase());
  const start = ad.name.slice(0, splitIdx);
  const middle = ad.name.slice(splitIdx, splitIdx + state.query.length);
  const end = ad.name.slice(splitIdx + state.query.length);
  return (
    <Popover
      onClickOutside={() => setIsOpen(!isOpen)}
      positions={[`top`, `right`]}
      isOpen={isOpen}
      containerStyle={{ zIndex: `99999` }}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          arrowStyle={{ zIndex: `99999` }}
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="white"
          arrowClassName="change-target-arrow"
          arrowSize={10}
        >
          <ImgWrapper>
            <img src={imageUrl} alt="ad thumbnail" />
          </ImgWrapper>
        </ArrowContainer>
      )}
    >
      <Header>
        <CheckboxInput defaultValue={ad.isSelected} onChange={onSelect} />
        <GoPrimitiveDot
          style={{ marginRight: `2rem` }}
          size={20}
          color={ad.status === `PAUSED` ? `orange` : `green`}
        />
        <span
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          onClick={() => onSelect(!ad.isSelected)}
        >
          {splitIdx < 0 ? (
            ad.name
          ) : (
            <span>
              {start}
              <mark>{middle}</mark>
              {end}
            </span>
          )}
        </span>
      </Header>
    </Popover>
  );
};

const ImgWrapper = styled.div`
  padding: 1rem;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 22px 154px 0 rgba(0, 0, 0, 0.17),
    0 18px 46px 0 rgba(177, 217, 203, 0.18), 0 8px 24px 0 rgba(0, 0, 0, 0.12);

  img {
    max-width: 275px;
  }
`;

function handleAdSelection(
  state: AdSearchState,
  campaignIdx: number,
  adSetIdx: number,
  ad: FBAd,
  isSelect: boolean,
  targeting: React.MutableRefObject<FacebookTargeting>,
) {
  const campaign = state.campaigns[campaignIdx];
  const campaign_id = campaign.id;
  const adSet = campaign.adSets[adSetIdx];
  const adSet_id = adSet.id;
  const targetedAd = targeting.current.ads.find((a) => a.id === ad.id);
  const isTargeted = !!targetedAd;
  const isParentTargeted = hasSelectedParent(targeting, adSet_id, campaign_id);
  const isExcluded = targetedAd?.isExclude;

  if (
    shuoldRemoveFromTargeting(
      isSelect,
      isTargeted,
      isParentTargeted,
      isExcluded,
    )
  ) {
    targeting.current = {
      ...targeting.current,
      ads: targeting.current.ads.filter((a) => a.id !== ad.id),
    };
  } else if (isSelect && !isTargeted && !isParentTargeted && !isExcluded) {
    const ads = [
      ...targeting.current.ads,
      clone({
        id: ad.id,
        name: ad.name,
        campaign_id,
        adSet_id,
        campaignName: campaign.name,
        adsetName: adSet.name,
      } as FacebookAd),
    ];
    targeting.current = { ...targeting.current, ads };
  } else if (!isSelect && isTargeted && isParentTargeted && !isExcluded) {
    targeting.current.ads.forEach((a) => {
      if (a.id === ad.id) {
        a.isExclude = true;
      }
    });
  } else if (!isSelect && !isTargeted && isParentTargeted && !isExcluded) {
    const ads = [
      ...targeting.current.ads,
      clone({
        id: ad.id,
        name: ad.name,
        campaign_id,
        adSet_id,
        isExclude: true,
      } as FacebookAd),
    ];
    targeting.current = { ...targeting.current, ads };
  }
}

function shuoldRemoveFromTargeting(
  isSelect: boolean,
  isTargeted: boolean,
  isParentTargeted: boolean,
  isExcluded: boolean,
) {
  return (
    (isSelect && isTargeted && isParentTargeted && !isExcluded) ||
    (isSelect && isTargeted && isParentTargeted && isExcluded) ||
    (!isSelect && isTargeted && !isParentTargeted && !isExcluded) ||
    (!isSelect && isTargeted && isParentTargeted && isExcluded)
  );
}

function hasSelectedParent(
  targeting: React.MutableRefObject<FacebookTargeting>,
  adSet_id: string,
  campaign_id: string,
) {
  return (
    !!targeting.current.adsets?.find(
      (x) => x.id === adSet_id && !x.isExclude,
    ) || !!targeting.current.campaigns?.find((x) => x.id === campaign_id)
  );
}
