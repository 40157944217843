import React, { FC, useContext, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Popover } from 'react-tiny-popover';
import { AdvancedRuling } from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/context';
import { AreYouSure } from '@/components/small-are-you-sure';
import { IconButton } from '@/features/editor/widgets/custom-widget/loading-section/shared/iconButton';

interface Props {
  num: number;
}

export const DeleteBtn: FC<Props> = ({ num }) => {
  const { isOpen } = useContext(AdvancedRuling);
  const [delPopOpen, setDelPopOpen] = useState(false);

  if (!isOpen(num)) {
    return null;
  }
  const close = () => setDelPopOpen(false);
  const { deleteRule } = useContext(AdvancedRuling);

  return (
    <Popover
      containerStyle={{ zIndex: `999999` }}
      isOpen={delPopOpen}
      positions={[`top`]}
      onClickOutside={close}
      content={<AreYouSure close={close} onYes={() => deleteRule(num)} />}
    >
      <IconButton
        onClick={() => {
          setDelPopOpen(!delPopOpen);
        }}
      >
        <StaticImage
          height={20}
          placeholder="none"
          src="../../../../../../../assets/trash.svg"
          alt="delete"
        />
      </IconButton>
    </Popover>
  );
};
