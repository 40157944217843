import * as React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import { GradientModal } from '@/components/gradient-modal';
import { WELCOME_MODAL_RECT } from '@/features/welcome-quiz/shared/base-modal';
import { WelcomeModalEnableAppEmbed } from '@/features/welcome-quiz/enable-app-embed/index';
import {
  WelcomeModalContext,
  WelcomeModalCtx,
  WelcomeModalStep,
} from '@/features/welcome-quiz/shared/context';
import { SmallCloseButton } from '@/components/close-button';

export interface AppEmbedModalProps {
  fromRef: React.MutableRefObject<HTMLElement>;
  header?: string;
  children?: any;
  isVisible: boolean;
  setIsVisible: (state: boolean) => void;
}

export function AppEmbedModal({
  isVisible,
  setIsVisible,
  header,
  fromRef,
}: AppEmbedModalProps) {
  const [footer, setFooter] = useState<any>(undefined);
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const ctx = {
    setFooter,
    setCurrentStep: (_: WelcomeModalStep) => {
      setIsVisible(false);
    },
  } as unknown as WelcomeModalCtx;

  return (
    <WelcomeModalContext.Provider value={ctx}>
      <GradientModal
        closeTop="0.5rem"
        closeRight="2.5rem"
        fromStyle={{
          borderRadius: `5rem`,
          background: `white`,
          padding: `2rem 0 0 0`,
        }}
        toStyle={{
          borderRadius: `1rem`,
          background: `white`,
          padding: `2rem 0 0 0`,
        }}
        isVisible={isVisible}
        showBackdrop
        targetPosAndSize={WELCOME_MODAL_RECT}
        fromRef={fromRef}
        overflowTop={{ heightInRem: 8 }}
        overflowBottom={{ heightInRem: 8, turnPointInPercent: 30 }}
        hideCloseButton
        disableCloseOnBackdropClick
        onClose={() => setIsVisible(false)}
        header={header || ``}
        footer={footer ? <FooterWrapper>{footer}</FooterWrapper> : ``}
      >
        <ContentWrapper>
          <SmallCloseButton
            id="app-embed-close"
            onClick={() => setIsVisible(false)}
          />
          <WelcomeModalEnableAppEmbed />
        </ContentWrapper>
      </GradientModal>
    </WelcomeModalContext.Provider>
  );
}

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
  padding: 5rem 2rem 0 2rem;

  #app-embed-close {
    position: absolute;
    top: 1rem;
    right: 2.5rem;
    z-index: 1000;
  }
`;

const FooterWrapper = styled.div`
  margin-bottom: 0.5rem;
`;
