import { CachePolicies, useFetch } from 'use-http';
import { useState } from 'react';
import { routes } from '@/webapi/routes';

enum ChatRole {
  USER = `USER`,
  ASSISTANT = `ASSISTANT`,
}

interface GPTMessage {
  role: ChatRole;
  message: string;
}

interface TextAltReq {
  context: Array<GPTMessage>;
  text: string;
  options: number;
}

interface TextAlternativeResp {
  options: Array<string>;
}

export function useTextAltApi() {
  const { post, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
    },
  }));

  const [opts, setOps] = useState<Array<string>>([]);

  const getAltText = async (
    text: string,
    options: number,
  ): Promise<TextAlternativeResp> => {
    if (text === ``) {
      text = `give me another option`;
      if (opts.length === 0) {
        return { options: [] };
      }
    }
    const response: TextAlternativeResp = await post(routes.getTextAlt, {
      text,
      options,
    } as TextAltReq);

    if (response?.options?.length > 0) {
      setOps([...opts, ...response.options]);
    }
    return response;
  };

  return {
    getAltText,
    options: opts,
    loading,
  };
}
