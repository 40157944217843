const tokens = {
  linearGradient: /^(\-(webkit|o|ms|moz)\-)?(linear\-gradient)/i,
  repeatingLinearGradient:
    /^(\-(webkit|o|ms|moz)\-)?(repeating\-linear\-gradient)/i,
  radialGradient: /^(\-(webkit|o|ms|moz)\-)?(radial\-gradient)/i,
  repeatingRadialGradient:
    /^(\-(webkit|o|ms|moz)\-)?(repeating\-radial\-gradient)/i,
  sideOrCorner:
    /^to (left (top|bottom)|right (top|bottom)|top (left|right)|bottom (left|right)|left|right|top|bottom)/i,
  extentKeywords:
    /^(closest\-side|closest\-corner|farthest\-side|farthest\-corner|contain|cover)/,
  positionKeywords: /^(left|center|right|top|bottom)/i,
  pixelValue: /^(-?(([0-9]*\.[0-9]+)|([0-9]+\.?)))px/,
  percentageValue: /^(-?(([0-9]*\.[0-9]+)|([0-9]+\.?)))\%/,
  emValue: /^(-?(([0-9]*\.[0-9]+)|([0-9]+\.?)))em/,
  angleValue: /^(-?(([0-9]*\.[0-9]+)|([0-9]+\.?)))deg/,
  startCall: /^\(/,
  endCall: /^\)/,
  comma: /^,/,
  hexColor: /^\#([0-9a-fA-F]+)/,
  literalColor: /^([a-zA-Z]+)/,
  rgbColor: /^rgb/i,
  rgbaColor: /^rgba/i,
  number: /^(([0-9]*\.[0-9]+)|([0-9]+\.?))/,
};

export function parseGradient(input :string) {

  function error(msg) {
    const err = new Error(`${input}: ${msg}`);
    // @ts-ignore
    err.source = input;
    throw err;
  }

  function getAST() {
    const ast = matchListDefinitions();

    if (input.length > 0) {
      error(`Invalid input not EOF`);
    }

    return ast;
  }

  function matchListDefinitions() {
    return matchListing(matchDefinition);
  }

  function matchDefinition() {
    return (
      matchGradient(
        `linear-gradient`,
        tokens.linearGradient,
        matchLinearOrientation,
      ) ||
      matchGradient(
        `repeating-linear-gradient`,
        tokens.repeatingLinearGradient,
        matchLinearOrientation,
      ) ||
      matchGradient(
        `radial-gradient`,
        tokens.radialGradient,
        matchListRadialOrientations,
      ) ||
      matchGradient(
        `repeating-radial-gradient`,
        tokens.repeatingRadialGradient,
        matchListRadialOrientations,
      )
    );
  }

  function matchGradient(gradientType, pattern, orientationMatcher) {
    return matchCall(pattern, (captures) => {
      const orientation = orientationMatcher();
      if (orientation) {
        if (!scan(tokens.comma)) {
          error(`Missing comma before color stops`);
        }
      }

      return {
        type: gradientType,
        orientation,
        colorStops: matchListing(matchColorStop),
      };
    });
  }

  function matchCall(pattern, callback) {
    const captures = scan(pattern);

    if (captures) {
      if (!scan(tokens.startCall)) {
        error(`Missing (`);
      }

      const result = callback(captures);

      if (!scan(tokens.endCall)) {
        error(`Missing )`);
      }

      return result;
    }
  }

  function matchLinearOrientation() {
    return matchSideOrCorner() || matchAngle();
  }

  function matchSideOrCorner() {
    return match(`directional`, tokens.sideOrCorner, 1);
  }

  function matchAngle() {
    return match(`angular`, tokens.angleValue, 1);
  }

  function matchListRadialOrientations() {
    let radialOrientations;
    let radialOrientation = matchRadialOrientation();
    let lookaheadCache;

    if (radialOrientation) {
      radialOrientations = [];
      radialOrientations.push(radialOrientation);

      lookaheadCache = input;
      if (scan(tokens.comma)) {
        radialOrientation = matchRadialOrientation();
        if (radialOrientation) {
          radialOrientations.push(radialOrientation);
        } else {
          input = lookaheadCache;
        }
      }
    }

    return radialOrientations;
  }

  function matchRadialOrientation() {
    let radialType = matchCircle() || matchEllipse();

    if (radialType) {
      // @ts-ignore
      radialType.at = matchAtPosition();
    } else {
      const extent = matchExtentKeyword();
      if (extent) {
        radialType = extent;
        const positionAt = matchAtPosition();
        if (positionAt) {
          // @ts-ignore
          radialType.at = positionAt;
        }
      } else {
        const defaultPosition = matchPositioning();
        if (defaultPosition) {
          radialType = {
            type: `default-radial`,
            // @ts-ignore
            at: defaultPosition,
          };
        }
      }
    }

    return radialType;
  }

  function matchCircle() {
    const circle = match(`shape`, /^(circle)/i, 0);

    if (circle) {
      // @ts-ignore
      circle.style = matchLength() || matchExtentKeyword();
    }

    return circle;
  }

  function matchEllipse() {
    const ellipse = match(`shape`, /^(ellipse)/i, 0);

    if (ellipse) {
      // @ts-ignore
      ellipse.style = matchDistance() || matchExtentKeyword();
    }

    return ellipse;
  }

  function matchExtentKeyword() {
    return match(`extent-keyword`, tokens.extentKeywords, 1);
  }

  function matchAtPosition() {
    if (match(`position`, /^at/, 0)) {
      const positioning = matchPositioning();

      if (!positioning) {
        error(`Missing positioning value`);
      }

      return positioning;
    }
  }

  function matchPositioning() {
    const location = matchCoordinates();

    if (location.x || location.y) {
      return {
        type: `position`,
        value: location,
      };
    }
  }

  function matchCoordinates() {
    return {
      x: matchDistance(),
      y: matchDistance(),
    };
  }

  function matchListing(matcher) {
    let captures = matcher();
    const result = [];

    if (captures) {
      result.push(captures);
      while (scan(tokens.comma)) {
        captures = matcher();
        if (captures) {
          result.push(captures);
        } else {
          error(`One extra comma`);
        }
      }
    }

    return result;
  }

  function matchColorStop() {
    const color = matchColor();

    if (!color) {
      error(`Expected color definition`);
    }

    color.length = matchDistance();
    return color;
  }

  function matchColor() {
    return (
      matchHexColor() ||
      matchRGBAColor() ||
      matchRGBColor() ||
      matchLiteralColor()
    );
  }

  function matchLiteralColor() {
    return match(`literal`, tokens.literalColor, 0);
  }

  function matchHexColor() {
    return match(`hex`, tokens.hexColor, 1);
  }

  function matchRGBColor() {
    return matchCall(tokens.rgbColor, () => ({
      type: `rgb`,
      value: matchListing(matchNumber),
    }));
  }

  function matchRGBAColor() {
    return matchCall(tokens.rgbaColor, () => ({
      type: `rgba`,
      value: matchListing(matchNumber),
    }));
  }

  function matchNumber() {
    return scan(tokens.number)[1];
  }

  function matchDistance() {
    return (
      match(`%`, tokens.percentageValue, 1) ||
      matchPositionKeyword() ||
      matchLength()
    );
  }

  function matchPositionKeyword() {
    return match(`position-keyword`, tokens.positionKeywords, 1);
  }

  function matchLength() {
    return match(`px`, tokens.pixelValue, 1) || match(`em`, tokens.emValue, 1);
  }

  function match(type, pattern, captureIndex) {
    const captures = scan(pattern);
    if (captures) {
      return {
        type,
        value: captures[captureIndex],
      };
    }
  }

  function scan(regexp) {
    let captures;
    let blankCaptures;

    blankCaptures = /^[\n\r\t\s]+/.exec(input);
    if (blankCaptures) {
      consume(blankCaptures[0].length);
    }

    captures = regexp.exec(input);
    if (captures) {
      consume(captures[0].length);
    }

    return captures;
  }

  function consume(size) {
    input = input.substr(size);
  }

  return getAST();
}
