import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { BackgroundColorContext } from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/context';
import {
  getHue,
  parseSolidColor,
} from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/utils';
import useMouseEvents from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/use-mouse-events';

type Prop = {
  eyeDropColor: string | null;
};

export function ColorHuePicker({ eyeDropColor }: Prop) {
  const { solidColor, updateSolidColor } = useContext(BackgroundColorContext);

  const hueRef = useRef<HTMLElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (eyeDropColor) {
      const radial = parseSolidColor(eyeDropColor);
      updateSolidColor(radial);
    }
  }, [eyeDropColor]);

  useEffect(() => {
    if (hueRef.current) {
      setWidth(hueRef.current.clientWidth);
    }
  }, [setWidth]);

  const mouseDownHandler = useCallback(
    (event) => {
      const elementX = event.currentTarget.getBoundingClientRect().x;
      const startX = event.pageX;
      const positionX = startX - elementX;

      const color = getHue(
        positionX,
        width,
        solidColor.saturation,
        solidColor.value,
      );

      updateSolidColor(color, true);

      return {
        startX,
        positionX,
      };
    },
    [width, solidColor.saturation, solidColor.value, updateSolidColor],
  );

  const changeObjectPositions = useCallback(
    (event, { startX, positionX }) => {
      const moveX = event.pageX - startX;
      positionX += moveX;

      const offsetX =
        // eslint-disable-next-line no-nested-ternary
        positionX > width ? width : positionX <= 0 ? 0 : positionX;
      const color = getHue(
        offsetX,
        width,
        solidColor.saturation,
        solidColor.value,
      );

      return {
        positions: {
          positionX,
          startX: event.pageX,
        },
        color,
      };
    },
    [width, solidColor.saturation, solidColor.value],
  );

  const mouseMoveHandler = useCallback(
    (event, { startX, positionX }) => {
      const { positions, color } = changeObjectPositions(event, {
        startX,
        positionX,
      });

      updateSolidColor(color, true);

      return positions;
    },
    [changeObjectPositions, updateSolidColor],
  );

  const mouseUpHandler = useCallback(
    (event, { startX, positionX }) => {
      const { positions, color } = changeObjectPositions(event, {
        startX,
        positionX,
      });

      updateSolidColor(color, true);

      return positions;
    },
    [changeObjectPositions, updateSolidColor],
  );

  const mouseEvents = useMouseEvents(
    mouseDownHandler,
    mouseMoveHandler,
    mouseUpHandler,
  );

  const onMouseDown = (event) => {
    mouseEvents(event);
  };

  // eslint-disable-next-line no-bitwise
  const offsetLeft = (((solidColor.hue * width) / 360) | 0) - 6;

  const pointerStyle = {
    left: `${offsetLeft}px`,
  };

  return (
    <Wrapper onMouseDown={onMouseDown} ref={hueRef}>
      <Picker style={pointerStyle} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 1.3rem;
  border-radius: 5px;
  position: relative;
  background: -webkit-linear-gradient(
    left,
    #ff0000,
    #ff0080,
    #ff00ff,
    #8000ff,
    #0000ff,
    #0080ff,
    #00ffff,
    #00ff80,
    #00ff00,
    #80ff00,
    #ffff00,
    #ff8000,
    #ff0000
  );
  background: -o-linear-gradient(
    left,
    #ff0000,
    #ff8000,
    #ffff00,
    #80ff00,
    #00ff00,
    #00ff80,
    #00ffff,
    #0080ff,
    #0000ff,
    #8000ff,
    #ff00ff,
    #ff0080,
    #ff0000
  );
  background: -ms-linear-gradient(
    left,
    #ff0000,
    #ff8000,
    #ffff00,
    #80ff00,
    #00ff00,
    #00ff80,
    #00ffff,
    #0080ff,
    #0000ff,
    #8000ff,
    #ff00ff,
    #ff0080,
    #ff0000
  );
  background: -moz-linear-gradient(
    left,
    #ff0000,
    #ff8000,
    #ffff00,
    #80ff00,
    #00ff00,
    #00ff80,
    #00ffff,
    #0080ff,
    #0000ff,
    #8000ff,
    #ff00ff,
    #ff0080,
    #ff0000
  );
  background: linear-gradient(
    to right,
    #ff0000,
    #ff8000,
    #ffff00,
    #80ff00,
    #00ff00,
    #00ff80,
    #00ffff,
    #0080ff,
    #0000ff,
    #8000ff,
    #ff00ff,
    #ff0080,
    #ff0000
  );
`;

const Picker = styled.div`
  position: absolute;
  top: -0.4rem;
  height: calc(100% + 0.8rem);
  width: 1.1rem;
  background: transparent;
  border: 0.4rem solid #fff;
  border-radius: 8px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.46),
    0 6px 14px 0 rgba(71, 111, 108, 0.69), 0 4px 14px 0 rgba(0, 0, 0, 0);
`;
