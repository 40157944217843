import * as React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { useContext, useEffect, useState } from 'react';
import { useThrottledCallback } from 'use-debounce';
import Portal from '@/components/portal';
import { GradientLayoutContext } from '@/components/gradient-layout';
import { UseObservableRef } from '@/components/hooks/use-observeable-ref';
import { useDetachedState } from '@/components/hooks/use-detached-state';

export interface BigArrowProps {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  invert?: number;
  flip?: boolean;
  opacity?: number;

  children?: any;

  fadeIn?: boolean;
  hideAfter?: number;
  zIndex?: number;
}

export function BigArrow(props: BigArrowProps) {
  const { children, flip, hideAfter } = props;
  const [, setOpacity, opacityRef] = useDetachedState(1);
  if (hideAfter) {
    setTimeout(() => {
      setOpacity(0);
    }, hideAfter);
  }

  return (
    <Wrapper {...props} opacity={opacityRef?.current}>
      {flip && children}
      <StaticImage
        width={100}
        src="../assets/arrowC.png"
        alt="Arrow"
        placeholder="none"
        loading="eager"
        objectFit="contain"
      />
      {!flip && children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-items: center;
  align-items: center;
  top: ${({ top }: BigArrowProps) => top}px;
  left: ${({ left }: BigArrowProps) => left}px;
  z-index: ${({ zIndex }: BigArrowProps) => zIndex || 29};
  ${(p: BigArrowProps) => (p?.fadeIn ? `animation: fadein 0.5s ease-in;` : ``)}
  opacity: ${(p: BigArrowProps) => (p?.opacity >= 0 ? p.opacity : 1)};

  .gatsby-image-wrapper {
    transform: scaleX(${({ flip }: BigArrowProps) => (flip ? `-1` : `1`)});
    filter: invert(${({ invert }: BigArrowProps) => invert || 0});
  }

  transition: opacity 0.5s ease-in;

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100;
    }
  }
`;

export interface StickyBigArrowProps extends BigArrowProps {
  observableRef: UseObservableRef<any>;
  topOffset?: number;
  leftOffset?: number;
  zIndex?: number;
}

export function StickyBigArrow({
  observableRef,
  invert,
  topOffset,
  leftOffset,
  flip,
  hideAfter,
  children,
  fadeIn,
  zIndex,
}: StickyBigArrowProps) {
  const { scrollRef } = useContext(GradientLayoutContext);
  const [wrapperRef, , refChanged] = observableRef;
  const [scrolled, setScrolled] = useState(0);

  const [rect, setRect] = useState({} as DOMRect);

  const listener = useThrottledCallback(() => {
    setScrolled((s) => s + 1);
  }, 50);

  useEffect(() => {
    scrollRef?.current?.addEventListener(`scroll`, listener);
    return () => {
      scrollRef?.current?.removeEventListener(`scroll`, listener);
    };
  }, [scrollRef]);

  useEffect(() => {
    if (wrapperRef?.current) {
      const elem = wrapperRef.current as HTMLElement;
      setRect(elem.getBoundingClientRect());
    }
  }, [refChanged, scrolled]);

  return (
    <Portal selector="#editor-wrapper">
      <BigArrow
        zIndex={zIndex}
        fadeIn={fadeIn}
        hideAfter={hideAfter}
        invert={invert}
        flip={flip}
        top={rect.top + (topOffset || 0)}
        left={rect.left + (leftOffset || 0)}
        bottom={rect.bottom}
        right={rect.right}
      >
        {children}
      </BigArrow>
    </Portal>
  );
}
