import { useContext, useMemo } from 'react';
import { CachePolicies, useFetch } from 'use-http';
import { EditorContext } from '@/features/editor/context/editor-context';
import { routes } from '@/webapi/routes';
import { Pages } from '@/webapi/pages';
import { openLinkInNewTab } from '@/utils/browser';

export function usePostPurchaseNav() {
  const { get, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: true,
    },
  }));

  const { experienceState } = useContext(EditorContext);

  const hasChanges = useMemo(
    () =>
      !!experienceState?.currentExperience?.variants?.find(
        (v) => v?.changes?.length > 0,
      ),
    [experienceState?.currentExperience?.variants],
  );

  async function getActivePostPurchaseExperienceId(): Promise<string> {
    const ret: { id: string } = await get(routes.getPostPurchase);
    return ret?.id;
  }

  async function navigateToPostPurchaseExperience() {
    if (hasChanges) {
      openLinkInNewTab(`${Pages.EDITOR}?post_purchase=t`, `vsly_post_purchase`);
    } else {
      window.location.replace(`${Pages.EDITOR}?post_purchase=t`);
    }
  }

  return {
    getActivePostPurchaseExperienceId,
    navigateToPostPurchaseExperience,
    loading,
  };
}
