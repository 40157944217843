import { useContext } from 'react';
import * as React from 'react';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';
import {
  DropdownInput,
  DropdownInputOption,
} from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { InputsDarkTheme } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';

export function PostPurchaseSummaryMode() {
  const {
    props: {
      settings: { summaryMode },
    },
    setProps,
  } = useContext(PostPurchaseContext);

  const options: DropdownInputOption[] = [
    { label: `Show Fixed Summary`, value: `fixed` },
    { label: `Show Collapsed Summary`, value: `collapsed` },
    { label: `Show Expanded Summary`, value: `expanded` },
  ];

  const onChange = (val: DropdownInputOption) => {
    setProps((draft) => {
      draft.settings.summaryMode = val.value;
    });
  };

  const defaultValue = () => {
    switch (summaryMode) {
      case `fixed`:
        return options[0];
      case `collapsed`:
        return options[1];
      case `expanded`:
        return options[2];
      default:
        return options[1];
    }
  };

  return (
    <DropdownInput
      theme={InputsDarkTheme}
      options={options}
      defaultValue={defaultValue()}
      onChange={onChange}
      optionStyles={{ textTransform: `uppercase` }}
    />
  );
}
