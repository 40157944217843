import React, { useEffect, useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import { BackgroundColorInput } from '@/features/editor/widgets/custom-widget/inputs/background/color';
import { ColorTile } from '@/features/editor/widgets/custom-widget/inputs/background/color/components/color-tile';

export interface ColorInputRichTextVslyProps {
  onChange?: (value: string) => void;
  value?: string;
  debounceDelay?: number;
}

export function ColorInputRichTextVsly({
  onChange,
  value,
  debounceDelay = 350,
}: ColorInputRichTextVslyProps) {
  const [color, setColor] = useState(value);
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    setColor(value);
  }, [value]);

  const handleChange = (colorStr) => {
    setColor(colorStr);
    onChange(colorStr);
  };
  const toggleColorPicker = () => {
    setShowPicker(!showPicker);
  };
  const onClickOutside = () => {
    toggleColorPicker();
  };
  return (
    <Popover
      isOpen={showPicker}
      positions={[`bottom`, `top`]}
      onClickOutside={onClickOutside}
      containerStyle={{ zIndex: `10000` }}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="white"
          arrowSize={20}
          arrowStyle={{
            opacity: 1,
          }}
          style={{
            position: `relative`,
            padding: 0,
            background: `white`,
            width: `60rem`,
            borderRadius: `1rem`,
            boxShadow: `0 22px 154px 0 rgba(0,0,0,0.17), 0 18px 46px 0 rgba(177,217,203,0.18), 0 8px 24px 0 rgba(0,0,0,0.12)`,
          }}
        >
          <BackgroundColorInput
            isActive
            defaultValue={rgbaToHex(color)}
            onCloseClicked={onClickOutside}
            onChange={handleChange}
            showHeader={false}
            debounceDelay={debounceDelay}
          />
        </ArrowContainer>
      )}
    >
      <ForwardRefComponent>
        <Wrapper>
          <ColorTile noLabel colorStyleStr={color} onClick={onClickOutside} />
        </Wrapper>
      </ForwardRefComponent>
    </Popover>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 4.1rem;
  grid-template-columns: 100%;
  align-items: center;
  justify-content: flex-end;
`;

const ForwardRefComponent = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>((props, ref) => (
  <div ref={ref} onClick={props.onClick}>
    {props.children}
  </div>
));

function rgbaToHex(rgba) {
  if (rgba.startsWith(`#`)) return rgba;
  const result = rgba.match(/[\d.]+/g);
  if (!result) return null;
  const hex = result
    .slice(0, 3)
    .map((num) => {
      const hexPart = parseInt(num, 10).toString(16);
      return hexPart.length === 1 ? `0${hexPart}` : hexPart;
    })
    .join(``);

  let alpha = ``;
  if (result[3] !== undefined) {
    const alphaValue = Math.round(parseFloat(result[3]) * 255).toString(16);
    alpha = alphaValue.length === 1 ? `0${alphaValue}` : alphaValue;
  }

  return `#${hex}${alpha}`;
}
