import React, { useCallback, useContext, useMemo } from 'react';
import {
  InputComponent,
  InputCustomization,
  SingleSelectInputSpec,
} from '@/webapi/checkout-widget-props';
import { RadioButtons } from '@/features/editor/widgets/custom-widget/inputs/shared/radio-buttons';
import { CheckoutExtensibilityContext } from '@/features/editor/widgets/checkout-extensibility/context';

export interface CheckoutInputSingleSelectProps {
  cust: InputCustomization;
  custId: number;
  comp: InputComponent;
  compId: number;
  spec: SingleSelectInputSpec;
  specId: number;
}

export function CheckoutInputSingleSelect({
  cust,
  custId,
  comp,
  compId,
  specId,
  spec,
}: CheckoutInputSingleSelectProps) {
  const { getValueByPath, currentProps, setCurrentProps } = useContext(
    CheckoutExtensibilityContext,
  );

  const value = useMemo(
    () => getValueByPath(custId, compId, specId).value,
    [currentProps, setCurrentProps],
  );

  const onChange = useCallback(
    (newValue: string) => {
      setCurrentProps((draft) => {
        draft[cust.key][comp.key][spec.key] = newValue;
      });
    },
    [currentProps, setCurrentProps],
  );

  return (
    <RadioButtons
      title={spec.title}
      smallTitle
      options={spec.options}
      defaultValue={value}
      onChange={onChange}
      allowCustomEdit={spec?.allowCustomEdit === true}
      customEditHint={spec?.customEditHint}
    />
  );
}
