import React, { useContext } from 'react';
import styled from 'styled-components';
import { StrategyPerSlotContext } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/context/context';

import { SearchHeader } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/search/searchHeader';
import { CondHeader } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/filter/condHeader';
import { ManualImageSelectHeader } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/search/imageSelect';

export function MainHeader() {
  const ctx = useContext(StrategyPerSlotContext);
  if (ctx.isManualImageSelectionOpen) {
    return <ManualImageSelectHeader />;
  }
  if (ctx.isProductSearchOpen) {
    return <SearchHeader />;
  }
  if (ctx.isProductCondOpen) {
    return <CondHeader />;
  }

  return (
    <Header>
      <p>Vary strategy/product per slot</p>
      <span>
        Each slot can be assigned a specific strategy / product of your choice
      </span>
    </Header>
  );
}

const Header = styled.div`
  p {
    font-size: 3rem;
    margin: 0 0 1rem 0;
  }
  span {
    font-size: 1.6rem;
    font-weight: normal;
  }
`;
