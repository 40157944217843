import styled from 'styled-components';
import { DefaultTypography } from '@/components/typography';

export const Hint = styled(DefaultTypography)`
  && {
    position: relative;
    margin: 0 0 3rem 0;
    color: #3451fa;
    font-weight: 600;
    font-size: 2.1em;
  }
`;
