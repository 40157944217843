import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { animated, useChain, useSpring, useSpringRef } from '@react-spring/web';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { CheckboxInput } from '@/features/editor/widgets/custom-widget/inputs/shared/checkbox-input';
import { CardWrapper } from '@/features/editor/widgets/custom-widget/inputs/shared/card-wrapper';
import { ColorInput } from '@/features/editor/widgets/custom-widget/inputs/shared/color-input';
import { Control } from './shared';
import { DeviceType } from '@/utils/definitions';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';

export function TextShadowInput({
  customizationIdx,
  customization,
  componentIdx,
  component,
  specIdx,
  spec,
  device,
  initialValues,
  onValuesChanged,
}: GenericInputProps) {
  const { undoRedoCount } = useContext(UndoRedoCounterContext);
  const getEnabled = useCallback(
    () => initialValues(`enabled`),
    [undoRedoCount],
  );
  const getValue = useCallback(() => initialValues(`value`), [undoRedoCount]);

  const [isEnabled, setIsEnabled] = useState(getEnabled());
  const parsedValue = parse(getValue());
  const [x, setX] = useState(parsedValue[0]);
  const [y, setY] = useState(parsedValue[1]);
  const [blur, setBlur] = useState(parsedValue[2]);
  const [color, setColor] = useState(parsedValue[3]);

  useEffect(() => {
    const parsedValue = parse(getValue());
    setX(parsedValue[0]);
    setY(parsedValue[1]);
    setBlur(parsedValue[2]);
    setColor(parsedValue[3]);
    setIsEnabled(getEnabled());
  }, [undoRedoCount]);

  useEffect(() => {
    onValuesChanged(`enabled`, isEnabled);
  }, [isEnabled]);

  useEffect(() => {
    const value = format(x, y, blur, color);
    onValuesChanged(`value`, value);
  }, [x, y, blur, color]);

  const checkmarkAnimRef = useSpringRef();
  const checkmarkAnim = useSpring({
    from: { opacity: 1 },
    to: { opacity: 0 },
    reverse: !isEnabled,
    ref: checkmarkAnimRef,
  });

  const cardAnimRef = useSpringRef();
  const cardAnim = useSpring({
    from: { opacity: 0, transform: `scale(1,0.3)` },
    to: { opacity: 1, transform: `scale(1,1)` },
    reverse: !isEnabled,
    ref: cardAnimRef,
  });

  useChain([cardAnimRef, checkmarkAnimRef], [0, 0.5]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <div style={{ position: `relative` }}>
      <animated.div
        style={{
          ...cardAnim,
          transformOrigin: `0 0`,
          position: !isEnabled ? `absolute` : `relative`,
        }}
      >
        <CardWrapper
          title={
            <CardHeader
              isEnabled={isEnabled}
              setIsEnabled={setIsEnabled}
              color={color}
              setColor={setColor}
              customizationIdx={customizationIdx}
              customization={customization}
              componentIdx={componentIdx}
              component={component}
              specIdx={specIdx}
              spec={spec}
              device={device}
            />
          }
        >
          <ControlsWrapper>
            <Control
              caption="X"
              envKey="right"
              initialValues={(
                _key?: string,
                _device?: DeviceType,
                _specIdx?: number,
              ) => x}
              onChange={(value) => setX(value)}
            />
            <Control
              caption="Y"
              envKey="bottom"
              initialValues={(
                _key?: string,
                _device?: DeviceType,
                _specIdx?: number,
              ) => y}
              onChange={(value) => setY(value)}
            />
            <Control
              caption="Blur"
              envKey="blur"
              initialValues={(
                _key?: string,
                _device?: DeviceType,
                _specIdx?: number,
              ) => blur}
              onChange={(value) => setBlur(value)}
            />
          </ControlsWrapper>
        </CardWrapper>
      </animated.div>
      <animated.div
        style={{
          ...checkmarkAnim,
          position: isEnabled ? `absolute` : `relative`,
          display: isEnabled ? `none` : `block`,
        }}
      >
        <CheckboxInput
          defaultValue={isEnabled}
          onChange={(value) => setIsEnabled(value)}
        >
          Add Text Shadow
        </CheckboxInput>
      </animated.div>
    </div>
  );
}

const ControlsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.2rem;
`;

function CardHeader({
  isEnabled,
  customizationIdx,
  customization,
  componentIdx,
  component,
  specIdx,
  spec,
  device,
  setIsEnabled,
  color,
  setColor,
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  const noop = (key: string, value: any, device?: DeviceType) => {};
  const initValues = (_key?: string, _device?: DeviceType, _specIdx?: number) =>
    color;
  return (
    <CardHeaderWrapper>
      <CheckboxInput
        defaultValue={isEnabled}
        onChange={(value) => setIsEnabled(value)}
      >
        Add Text Shadow
      </CheckboxInput>
      <ColorInput
        defaultValue={color}
        envKey="value"
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        onChange={(value) => setColor(value)}
        initialValues={initValues}
        onValuesChanged={noop}
      />
    </CardHeaderWrapper>
  );
}

const CardHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 5rem;
  grid-auto-rows: 2rem;
  justify-content: center;
  align-items: center;

  div:nth-child(2) {
    height: 3.5rem;
    width: 5rem;
  }
`;

function parse(shadowStr: string): string[] {
  return shadowStr.replaceAll(`, `, `,`).split(` `);
}

function format(x: string, y: string, blur: string, color: string) {
  return `${x} ${y} ${blur} ${color}`;
}
