import {
  KlavioEvent,
  KlavioStep,
  Trigger,
  TriggerKind,
} from '@/webapi/use-experience-api';

export function useTriggersDefaults() {
  const defaultPageLoad: Trigger = {
    kind: TriggerKind.PAGE_LOAD,
    selector: ``,
    timeoutMillis: 0,
  };

  const defaultTimeout: Trigger = {
    kind: TriggerKind.TIMEOUT,
    selector: ``,
    timeoutMillis: 2000,
  };

  const defaultInactivity: Trigger = {
    kind: TriggerKind.INACTIVITY,
    selector: ``,
    timeoutMillis: 2000,
  };

  const defaultElement: Trigger = {
    kind: TriggerKind.ELEMENT_APPEARING,
    selector: `#element-selector`,
    timeoutMillis: 0,
  };

  const defaultExitIntent: Trigger = {
    kind: TriggerKind.EXIT_INTENT,
    selector: ``,
    timeoutMillis: 0,
  };

  const defaultKlavioEvent: Trigger = {
    kind: TriggerKind.KLAVIO_EVENT,
    event: {
      meta: {
        type: KlavioEvent.NOT_SET,
        step: KlavioStep.EMAIL_SMS,
      },
      name: `klaviyoForms`,
      matchCode: ``,
    },
  };

  const defaultElementClicked: Trigger = {
    kind: TriggerKind.ELEMENT_CLICKED,
    selector: `#element-selector`,
    code: ``,
    timeoutMillis: 0,
    retries: 100,
    elementEvent: {
      name: `click`,
      once: true,
    },
  };

  const defaultElementHover: Trigger = {
    kind: TriggerKind.ELEMENT_HOVER,
    selector: `#element-selector`,
    code: ``,
    timeoutMillis: 0,
    retries: 100,
    elementEvent: {
      name: `mouseover`,
      once: true,
    },
  };

  const defaultJavascriptFunction: Trigger = {
    kind: TriggerKind.JAVASCRIPT_FUNCTION,
    selector: ``,
    code: `return new Promise(resolve => {
  // call resolve() when you want to trigger the experience
  setTimeout(resolve, 1000)
})`,
    timeoutMillis: 0,
    retries: 100,
  };

  return {
    defaultPageLoad,
    defaultTimeout,
    defaultInactivity,
    defaultElement,
    defaultElementClicked,
    defaultElementHover,
    defaultKlavioEvent,
    defaultExitIntent,
    defaultJavascriptFunction,
  };
}
