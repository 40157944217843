import * as React from 'react';
import { useContext, useState } from 'react';
import { SectionTitle } from '@/features/editor/widgets/custom-widget/shared/shared-styles';
import {
  CardBtn,
  CardDescription,
  Description,
  isAdvancedRulingV2,
  isManualV2,
  ListWrapper,
  shouldOpenAdvancedRuling,
  shouldOpenManualPicker,
  Title,
} from '@/features/editor/widgets/custom-widget/loading-section';
import { EditorContext } from '@/features/editor/context/editor-context';
import { CHECKOUT_SUPPORTED_UPSELL_STRATEGIES } from '@/features/editor/widgets/checkout-extensibility/loading/checkout-supported-strategies';
import { CheckoutExtensibilityContext } from '@/features/editor/widgets/checkout-extensibility/context';
import { getProductIds, useStrategyPerSlot } from '@/features/editor';
import { useLayeredRuling } from '@/features/editor/widgets/shared/useLayeredRuling';
import {
  ManualProductsPicker,
  newProductsPickerCtx,
} from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/context';
import {
  ManualProductPicker,
  useManualProductsModalProps,
} from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker';
import { StrategyPerSlotContext } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/context/context';
import { StrategyPerSlot } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot';
import { LayeredRulingContext } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import { LayeredRuling } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling';
import { AdvancedEditing } from '@/features/editor/widgets/custom-widget/loading-section/conditional-product';
import {
  AdvancedProductRulingPicker,
  useAdvancedRulingModalProps,
} from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import { getManualProductsSize } from '@/features/editor/widgets/custom-widget/loading-section/util';
import { defaultRecsStrategy } from '@/features/editor/widgets/checkout-extensibility/custom-widget-mock-context';

export function CheckoutRecsLoadingSection() {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);

  const {
    currentProps,
    updateLoadingCfg,
    updateRecommendationOptions,
    updateRecommendationCondition,
  } = useContext(CheckoutExtensibilityContext);

  const { currentSchema } = useContext(CustomWidgetContext);

  const {
    hide: hideManual,
    show: showManual,
    fromRef: manualRef,
    props: manualProps,
  } = useManualProductsModalProps();

  const {
    hide: hideRuling,
    show: showRuling,
    props: rulingProps,
    fromRef: rulingRef,
  } = useAdvancedRulingModalProps();

  const productIds = getProductIds(currentSchema);
  const productPickerCtx = newProductsPickerCtx(
    productIds,
    getManualProductsSize(currentSchema, device),
  );

  const {
    strategyPerSlotCtx,
    showStrategyPerSlot,
    setShowStrategyPerSlot,
    isRecsV2Enabled,
  } = useStrategyPerSlot(device, currentSchema);

  const [isConditionalModalVisible, setIsConditionalModalVisible] =
    useState(false);

  const {
    showNewRuling,
    setShowRuling,
    layeredRulingCtx,
    isAdvancedEditingV2Enabled,
  } = useLayeredRuling(device, currentSchema, true);

  return (
    <>
      <div ref={manualRef} />
      <div ref={rulingRef} />
      <SectionTitle>Which products do you want to add?</SectionTitle>
      <ListWrapper>
        <ManualProductsPicker.Provider value={productPickerCtx}>
          <ManualProductPicker
            updateRecommendationOptions={updateRecommendationOptions}
            hide={hideManual}
            {...manualProps}
          />
        </ManualProductsPicker.Provider>
        <StrategyPerSlotContext.Provider value={strategyPerSlotCtx}>
          <StrategyPerSlot
            isOpen={showStrategyPerSlot}
            close={() => setShowStrategyPerSlot(false)}
            fromRef={manualRef}
          />
        </StrategyPerSlotContext.Provider>
        <LayeredRulingContext.Provider value={layeredRulingCtx}>
          <LayeredRuling
            isOpen={showNewRuling}
            fromRef={rulingRef}
            close={() => setShowRuling(false)}
          />
        </LayeredRulingContext.Provider>
        <AdvancedEditing
          fromRef={rulingRef}
          setIsVisible={setIsConditionalModalVisible}
          isVisible={isConditionalModalVisible}
          strategy={getRecStrategy(currentProps).type}
          updateRecommendationCondition={updateRecommendationCondition}
          initialState={getInitialState(currentProps)}
        />
        <AdvancedProductRulingPicker hide={hideRuling} {...rulingProps} />

        {CHECKOUT_SUPPORTED_UPSELL_STRATEGIES.map((item) => {
          const value = getRecStrategy(currentProps);
          const sameType = item.value.type === value?.type;
          const isSelected = sameType && !value?.default;
          const shouldShowDescription =
            !isSelected ||
            isManualV2(true, item) ||
            isAdvancedRulingV2(true, item);

          return (
            <CardBtn
              isSelected={isSelected}
              key={item.label}
              onClick={() => {
                updateLoadingCfg(item);
                if (isRecsV2Enabled) {
                  shouldOpenManualPicker(item) && setShowStrategyPerSlot(true);
                } else {
                  shouldOpenManualPicker(item) && showManual();
                }
                if (isAdvancedEditingV2Enabled) {
                  shouldOpenAdvancedRuling(item) && setShowRuling(true);
                } else {
                  shouldOpenAdvancedRuling(item) && showRuling();
                }
              }}
            >
              <Title isSelected={isSelected}>{item.label}</Title>
              {shouldShowDescription && (
                <Description>{item?.description || `Explanation`}</Description>
              )}
              <CardDescription
                isRecsV2Enabled={isRecsV2Enabled}
                isAdvancedEditingV2Enabled={isAdvancedEditingV2Enabled}
                isSelected={isSelected}
                item={item}
                showCond={() => setIsConditionalModalVisible(true)}
              />
            </CardBtn>
          );
        })}
      </ListWrapper>
    </>
  );
}

export function getRecStrategy(currentParams: any) {
  return currentParams?.loadingValue?.value || defaultRecsStrategy().value;
}

function getInitialState(props: any) {
  return JSON.parse(
    JSON.stringify(props.loadingEnv?.recommendationOptions?.condition || []),
  );
}
