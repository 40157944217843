import React, { useContext } from 'react';
import { StrategyPerSlotContext } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/context/context';
import { ProductSearchInner } from '@/features/editor/widgets/custom-widget/loading-section';

export function ProductSearch() {
  const ctx = useContext(StrategyPerSlotContext);
  const { searchLoading } = ctx;
  const { searchTotal } = ctx;
  const { setManualImageSelectionOpen, selectProductForSlot } = ctx;
  const { productSearchSlotOpen } = ctx;
  if (!ctx.isProductSearchOpen || ctx.isManualImageSelectionOpen) {
    return null;
  }
  return (
    <ProductSearchInner
      searchLoading={searchLoading}
      searchTotal={searchTotal}
      next={ctx.searchLoadMore}
      hasMore={ctx.searchHasMore}
      products={ctx.searchResult}
      setManualImageSelectionOpen={setManualImageSelectionOpen}
      selectProductForSlot={selectProductForSlot}
      productSearchSlotOpen={productSearchSlotOpen}
    />
  );
}
