import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { DefaultTypography } from '@/components/typography';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';
import { Component, Customization } from '@/webapi/use-widget-catalog-api';
import { getSpecHiddenStyles } from '@/features/editor/widgets/custom-widget/shared/utils';
import { GroupContentSection } from '@/features/editor/widgets/custom-widget/inputs/shared/group-content-section';
import { isComponentHasHoverStateSpec } from '@/features/editor/widgets/custom-widget/inputs/shared/utils';

export interface ComponentGroupProps {
  custIdx: number;
  customization: Customization;
  compIdx: number;
  component: Component;
  isOpen?: boolean;
  hoverMode?: boolean;
  getResponsiveValue: (
    custId: number,
    compId: number,
    specId: number,
    key: string,
    device: DeviceType,
    hover?: boolean,
  ) => any;
  setResponsiveValue: (
    custId: number,
    compId: number,
    specId: number,
    key: string,
    value: any,
    device: DeviceType,
    ignoreHistory?: boolean,
    forceSameVersion?: boolean,
    hover?: boolean,
  ) => void;
}
export const ComponentGroup = ({
  custIdx,
  compIdx,
  customization,
  component,
  getResponsiveValue,
  setResponsiveValue,
  hoverMode,
}: ComponentGroupProps) => {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);

  const [isOpen, setIsOpen] = useState(true);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  if (hoverMode && !isComponentHasHoverStateSpec(component)) return null;
  return (
    <Wrapper
      style={{ ...getSpecHiddenStyles(component, device) }}
      key={component.key}
    >
      <HeaderSection onClick={onToggle}>
        <Text>{component.name}</Text>
        <Text>{isOpen ? `-` : `+`}</Text>
      </HeaderSection>
      <GroupContentSection
        compIdx={compIdx}
        component={component}
        custIdx={custIdx}
        customization={customization}
        getResponsiveValue={getResponsiveValue}
        setResponsiveValue={setResponsiveValue}
        isOpen={isOpen}
        hoverMode={hoverMode}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #f3f6f7;
  padding: 1.5rem 2rem;
  border-radius: 10px;

  user-select: none;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const Text = styled(DefaultTypography)`
  color: #95a2ae;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: -0.39px;
  text-transform: uppercase;
`;
