export const usualAttributes = {
  align: `center`,
  font: null,
  color: `#111111`,
  size: `14px`,
  letterSpacing: `0px`,
  italic: false,
  fontWeight: `500`,
  strike: false,
  underline: false,
  transform: null,
  lineHeight: `unset`,
  direction: `ltr`,
  bold: false,
  textShadow: `unset`,
  background: false,
};

export const fontFamilyOptions = [
  `Helvetica`,
  `Roboto`,
  `Open Sans`,
  `Fredoka`,
  `Smooch Sans`,
  `Rubik`,
  `Lato`,
  `Poppins`,
  `Oswald`,
  `League Spartan`,
  `Noto Sans`,
  `Raleway`,
  `Merriweather`,
  `Playfair Display`,
  `Inter`,
  `Jetbrains Mono`,
];

export const bugStyleVersion = {
  '4120b0aef951499c8b1d3bd0fbd75918': `_40`,
  '4120b0aef951499c8b1d3bd0fbd75910': `_40`,
  '4120b0aef951499c8b1d3bd0fbd75912': `_40`,
  '52880b0aef951499c8b1d3bd0fbd75918': `_40`,
  '5872880b0aef951499c8b1d3bd0fbd759201': `_40`,
  '52510b0aef951499c8b1d3bd0fbd75910': `_40`,
  '52510b0aef951499c8b1d3bd0fbd759129': `_40`,
  '85820b0aef951499c8b1d3bd0fbd75912': `_40`,
  '3120b0aef951499c8b1d3bd0fbd75913': `_20`,
};

export function toPlainText(richTextValue: string): string {
  try {
    const plain =
      new DOMParser().parseFromString(
        decodeURIComponent(richTextValue),
        `text/html`,
      ).body.textContent || ``;
    if (plain.length === 1) {
      return ``;
    }
    if (plain === `undefined`) {
      return ``;
    }
    return plain;
  } catch (_) {
    return richTextValue;
  }
}
