import * as React from 'react';
import { useContext, useMemo } from 'react';
import { StylingComponentsGroup } from '@/features/editor/widgets/post-purchase/styling/group';
import { PostPurchaseTextualInput } from '@/features/editor/widgets/post-purchase/inputs/textual';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';
import { DropdownInputOption } from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { RadioButtons } from '@/features/editor/widgets/custom-widget/inputs/shared/radio-buttons';

export function PostPurchaseTimerStyles() {
  const { props, setProps } = useContext(PostPurchaseContext);

  const hideUnitsOptions = useMemo<DropdownInputOption[]>(
    () => [
      { label: `No`, value: `false` },
      { label: `Yes`, value: `true` },
    ],
    [],
  );

  return (
    <>
      <StylingComponentsGroup title="Timer Digits Style">
        <PostPurchaseTextualInput
          defaultValue={props.style.timer.timerTile.digitStyle}
          onChange={(p) =>
            setProps((draft) => {
              draft.style.timer.timerTile.digitStyle = p;
            })
          }
        />
      </StylingComponentsGroup>

      <StylingComponentsGroup title="Timer Units Style">
        <PostPurchaseTextualInput
          defaultValue={props.style.timer.timerTile.descStyle}
          onChange={(p) =>
            setProps((draft) => {
              draft.style.timer.timerTile.descStyle = p;
            })
          }
        />
        <RadioButtons
          title="Hide Timer Units"
          options={hideUnitsOptions}
          defaultValue={props?.style?.timer?.timerTile?.hideDesc?.value}
          onChange={(p) =>
            setProps((draft) => {
              draft.style.timer.timerTile.hideDesc.value = p;
            })
          }
        />
      </StylingComponentsGroup>

      <StylingComponentsGroup title="Timer Separator Style">
        <PostPurchaseTextualInput
          defaultValue={props.style.timer.timerTile.digitStyle}
          onChange={(p) =>
            setProps((draft) => {
              draft.style.timer.separator.style = p;
            })
          }
        />
      </StylingComponentsGroup>
    </>
  );
}
