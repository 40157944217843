import * as React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import { TargetingDeviceType } from '@/webapi/use-experience-api';
import { routes } from '@/webapi/routes';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import allPagesImageMobile from './all_pages_m.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import allPagesImageDesktop from './all_pages_d.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import otherImageMobile from './other_m.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import otherImageDesktop from './other_d.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import customUrlImageMobile from './page_url_m.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import customUrlImageDesktop from './page_url_d.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ppImageMobile from './page_pp_m.png';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ppImageDesktop from './page_pp_d.png';

export function PageImage({
  alias,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  device,
  page,
}: {
  device: TargetingDeviceType;
  alias: string;
  page: string;
}) {
  const getImage = useCallback(
    (isDesk: boolean) =>
      isScreenshotPage(page)
        ? pageImageUrl(alias, page, isDesk ? `_desk` : ``)
        : getStaticImage(page, isDesk),
    [page, alias],
  );

  const onError = ({ currentTarget }) => {
    currentTarget.onerror = null;
    currentTarget.src = `https://sdk.loomi-prod.xyz/VSLY/not_found.png`;
  };
  return (
    <PageImageWrapper isStaticImage={!isScreenshotPage(page)}>
      {device === TargetingDeviceType.MOBILE && (
        <img
          className="mobile"
          src={getImage(false)}
          alt=""
          onError={onError}
        />
      )}
      {device === TargetingDeviceType.DESKTOP && (
        <img
          className="desktop"
          src={getImage(true)}
          alt=""
          onError={onError}
        />
      )}
      {device === TargetingDeviceType.ALL_DEVICES && (
        <AllDevicesLayout>
          <img
            className="placeholder"
            src={allPagesImageMobile}
            alt=""
            onError={onError}
          />
          <img
            className="mobile"
            src={getImage(false)}
            alt=""
            onError={onError}
          />
          <img
            className="desktop"
            src={getImage(true)}
            alt=""
            onError={onError}
          />
        </AllDevicesLayout>
      )}
    </PageImageWrapper>
  );
}

const isScreenshotPage = (page: string): boolean =>
  [`home`, `plp`, `pdp`, `checkout`, `cart`].includes(page);

const getStaticImage = (page: string, isDesk: boolean): string => {
  if (page === `all` && isDesk) return allPagesImageDesktop;
  if (page === `all` && !isDesk) return allPagesImageMobile;
  if (page === `url` && isDesk) return customUrlImageDesktop;
  if (page === `url` && !isDesk) return customUrlImageMobile;
  if (page === `other` && isDesk) return otherImageDesktop;
  if (page === `other` && !isDesk) return otherImageMobile;
  if (page === `postPurchase` && isDesk) return ppImageDesktop;
  if (page === `postPurchase` && !isDesk) return ppImageMobile;
  return ``;
};

const pageImageUrl = (alias: string, page: string, suffix: string) => {
  let prefix = `https://sdk.loomi-stg.xyz`;
  if (routes.getEnv() === 2) {
    prefix = `https://sdk.loomi-prod.xyz`;
  }
  return `${prefix}/media/snapshots/${page}_${alias}${suffix}.png`;
};

const PageImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0.6rem;

  img {
    background: #f1f3f4;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.21);
    border-radius: 0.6rem;
    height: 100%;
    width: 100%;
    max-height: 10rem;
    object-fit: ${(p: any) => (p?.isStaticImage ? `cover!important` : `cover`)};
    object-position: top center;
  }

  img.desktop {
    width: 115%;
    background: #e1e6e8;
  }
`;

const AllDevicesLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;

  .placeholder {
    opacity: 0;
  }

  .mobile {
    position: absolute;
    height: 85%;
    width: 75%;

    top: 0.3rem;
    left: 0.3rem;
    z-index: 10;
  }

  .desktop {
    position: absolute;
    height: 75%;
    width: 105% !important;

    top: 1.8rem;
    left: 1rem;

    z-index: 9;
    object-fit: fill;
  }
`;
