import { useContext, useEffect } from 'react';
import { isBrowser } from '@/utils/browser';
import { MeasurementMessage } from '@/features/editor/context/use-device-preview';
import { EditorContext } from '@/features/editor/context/editor-context';
import { WidgetChange } from '@/pkg/sdk';

const imgWidgetId = `3120b0aef951499c8b1d3bd0fbd75916`;

const measurements: Record<string, DOMRect> = {};
const imgMeasurementForHover: Record<string, DOMRect> = {};

export function useMeasureWidgetImageAspectRatio() {
  const {
    experienceState: { getCurrentVariant },
  } = useContext(EditorContext);

  const findChangeWithSelector = (selector: string) =>
    getCurrentVariant()?.changes?.find(
      (change) => change.editorSelector === selector,
    );

  const handleMeasurement = (msg: MeasurementMessage) => {
    const change = findChangeWithSelector(msg.selector);
    if (change?.widgetProps) {
      const widgetId = (change?.block.value as WidgetChange)?.widgetId;
      if (!widgetId) return;

      if (widgetId !== imgWidgetId) {
        measurements[msg.selector] = msg.rect;
      } else {
        imgMeasurementForHover[msg.selector] = msg.rect;
      }
    }
  };

  const handler = (msg) => {
    try {
      const data = msg.data as MeasurementMessage;
      if (data && data?.action === `measure`) {
        handleMeasurement(data);
      }
    } catch (ignored) {
      isBrowser() && window.console.error(`loomi`, ignored);
    }
  };

  useEffect(() => {
    isBrowser() && window.frames.addEventListener(`message`, handler);
    return () => {
      isBrowser() && window.frames.removeEventListener(`message`, handler);
    };
  }, [handler]);

  return {
    measurements,
    imgMeasurementForHover,
  };
}
