import React from 'react';
import styled from 'styled-components';
import { VscChevronDown } from 'react-icons/vsc';
import { DeviceType } from '@/utils/definitions';
import { AllMutationKind } from '@/pkg/sdk';

export enum ContentKind {
  ADD_AFTER = `Add After`,
  REPLACE = `Replace`,
  ADD_BEFORE = `Add Before`,
}

const allContentKind = [
  ContentKind.ADD_AFTER,
  ContentKind.REPLACE,
  ContentKind.ADD_BEFORE,
];

export function asContentKind(kind: AllMutationKind): ContentKind {
  if (kind === `appendAfter`) return ContentKind.ADD_AFTER;
  if (kind === `appendBefore`) return ContentKind.ADD_BEFORE;
  return ContentKind.REPLACE;
}

export function asAllMutationKind(kind: ContentKind): AllMutationKind {
  if (kind === ContentKind.ADD_BEFORE) return `appendBefore`;
  if (kind === ContentKind.ADD_AFTER) return `appendAfter`;
  return `replace`;
}

export function ContentKindSelect({
  device,
  onSelection,
  defaultValue,
  options = allContentKind,
}: {
  device: DeviceType;
  onSelection?: (ContentKind) => void;
  defaultValue?: ContentKind;
  options?: ContentKind[];
}) {
  const onChange = (ev) => {
    if (onSelection) {
      onSelection(ev.target.value);
    }
  };

  return (
    <Wrapper>
      <StyledSelect device={device} onChange={onChange}>
        {options.map((opt) => (
          <option key={opt} value={opt} selected={opt === defaultValue}>
            {opt}
          </option>
        ))}
      </StyledSelect>
      <VscChevronDown size={20} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  svg {
    position: absolute;
    top: calc(50% - 10px);
    right: 1rem;
  }
`;

const StyledSelect = styled.select`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 1rem 3.5rem 1rem 2rem;
  outline: none;
  appearance: none;
  border: none;
  border-radius: 2rem;
  background: rgba(216, 216, 216, 0.45);
  color: #475466;
  font-weight: normal;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-family: JetBrains Mono, serif;

  && > * {
    text-transform: capitalize;
  }
`;
