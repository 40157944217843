import React, { useContext } from 'react';
import styled from 'styled-components';
import { SmallSelect } from '@/components/small-select';
import { QBCaption } from '@/components/query-builder/shared';
import { LayeredRulingContext } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import {
  selectKey,
  useItemSelectionOptions,
} from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule/condition/utils';

export function ItemSelection({ ruleId }: { ruleId: number }) {
  const ctx = useContext(LayeredRulingContext);
  const rule = ctx.rule(ruleId);
  const onSelection = (v: string) => {
    ctx.change(ruleId, (r) => {
      r.ruleCond.itemSelection = v;
    });
  };
  if (!rule.ruleCond.itemSelection) {
    return null;
  }
  const opts = useItemSelectionOptions(rule);
  return (
    <Row>
      <For>FOR</For>
      <SmallSelect
        key={selectKey(rule, opts)}
        maxWidth={300}
        defaultValue={rule.ruleCond.itemSelection}
        onSelection={onSelection}
        options={opts}
      />
    </Row>
  );
}

const Row = styled.div`
  margin-top: 2rem;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
`;

const For = styled(QBCaption)`
  && {
    margin-right: 2rem;
  }
`;
