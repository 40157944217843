import React from 'react';

export function PlusSvg() {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 45 45"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(8, 8)" fill="#B6BAC5">
          <rect x="0" y="11" width="28" height="6" rx="3" />
          <rect
            transform="translate(14, 14) rotate(-270) translate(-14, -14)"
            x="-1.45519152e-11"
            y="11"
            width="28"
            height="6"
            rx="3"
          />
        </g>
      </g>
    </svg>
  );
}
