import React from 'react';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { ColorInput } from '@/features/editor/widgets/custom-widget/inputs/shared/color-input';

const VALUE_KEY = `color`;

export function FontColorInput({
  customizationIdx,
  customization,
  componentIdx,
  component,
  specIdx,
  spec,
  device,
  initialValues,
  onValuesChanged,
}: GenericInputProps) {
  return (
    <ColorInput
      envKey={VALUE_KEY}
      customizationIdx={customizationIdx}
      customization={customization}
      componentIdx={componentIdx}
      component={component}
      specIdx={specIdx}
      spec={spec}
      device={device}
      initialValues={initialValues}
      onValuesChanged={onValuesChanged}
    />
  );
}
