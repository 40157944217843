import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { TiPlus } from 'react-icons/ti';
import { AdvancedRuling } from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/context';

const Container = styled.button`
  margin: 1rem 0;
  padding: 0.5rem 0;
  background-color: #f2f3f5;
  width: 95%;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  p {
    user-select: none;
    font-size: 1.7rem;
    margin-left: 2rem;
    color: #97a7b6;
    font-weight: 700;
    font-family: 'Inter', serif;
  }
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
  &:active {
    opacity: 1;
  }
`;

export const AddAnother: FC = () => {
  const { addAnother } = useContext(AdvancedRuling);
  return (
    <Container onClick={addAnother}>
      <p>Add another rule</p>
      <IconButton>
        <TiPlus size={20} />
      </IconButton>
    </Container>
  );
};

const IconButton = styled.div`
  border-radius: 50%;
  margin-right: 2rem;
  svg {
    fill: #878d92;
  }
`;
