import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import produce from 'immer';
import { toast } from 'react-toastify';
import {
  IntegrationTile,
  IntegrationTileMode,
} from '@/features/integrations/integration-tile';
import {
  ReviewProvider,
  ReviewProviderName,
  useStoreSettingsApi,
} from '@/webapi/use-store-settings-api';
import { groupByAndFlatten } from '@/utils/arrays';
import { hideLoader, showLoader } from '@/components/PageLoader';
import { ConfirmModal } from '@/components/confirm-modal';
import { SharedElementOverlay } from '@/components/shared-element-overlay';
import { centered, useSharedElement } from '@/components/use-shared-element';
import { HSpace } from '@/components/spacing';

export interface ReviewsIntegrationsProps {
  mode?: IntegrationTileMode;
  onIntegrationChanged?: (selectedProvider: ReviewProvider) => void;
  wrapperRef?: React.MutableRefObject<HTMLElement>;
}

export function ReviewsIntegrations({
  mode,
  onIntegrationChanged,
  wrapperRef,
}: ReviewsIntegrationsProps) {
  const { listReviewsProviders, setReviewsProvider, loading } =
    useStoreSettingsApi();

  const [providers, setProviders] = useState<
    Record<ReviewProviderName, ReviewProvider>
  >({} as Record<ReviewProviderName, ReviewProvider>);
  // eslint-disable-next-line no-underscore-dangle
  const [tempSelected, setTempSelected] = useState(ReviewProviderName.NA);

  const {
    props: confirmProps,
    show: showConfirm,
    hide: hideConfirm,
  } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `#dedede`,
        opacity: `0`,
      },
      extraTo: {
        background: `white`,
        opacity: `1`,
      },
    },
    wrapperRef,
    () => centered(16, 42),
  );

  useEffect(() => {
    if (loading) showLoader();
    else hideLoader();
  }, [loading]);

  useAsyncEffect(async () => {
    const fetchedProviders = await listReviewsProviders();
    const providersByName = groupByAndFlatten(
      fetchedProviders?.providers || [],
      (p) => p?.name,
    );

    setProviders(providersByName);
  }, []);

  async function updateSelectedProvider(
    name: ReviewProviderName,
    state: boolean,
  ) {
    // eslint-disable-next-line no-underscore-dangle
    const updated = produce(providers, (draft) => {
      Object.entries(draft).forEach(([key, val]) => {
        if (key === name) {
          val.isSelected = state;
        } else {
          val.isSelected = false;
        }
      });
    });
    if (
      Object.entries(updated).filter(([_, val]) => !!val.isSelected).length > 0
    ) {
      await setReviewsProvider(name);
      setProviders(updated);
      onIntegrationChanged && onIntegrationChanged(updated[name]);
    } else {
      toast(`You can't disable this integration`, {
        theme: `colored`,
        type: `info`,
      });
    }
  }

  const updateSelectedProviderAfterConfirm = useCallback(async () => {
    hideConfirm();
    await updateSelectedProvider(tempSelected, true);
  }, [tempSelected]);

  const onSelectionChanged = async (
    name: ReviewProviderName,
    state: boolean,
  ) => {
    const hasSelection =
      Object.values(providers).filter((p) => !!p.isSelected).length > 0;
    if (hasSelection && mode === `toggle`) {
      setTempSelected(name);
      showConfirm();
    } else {
      await updateSelectedProvider(name, state);
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {Object.values(providers).length > 0 && (
        <>
          <SharedElementOverlay {...confirmProps} heightMultiplier={1.2}>
            <ConfirmModal
              neutral
              yesCaption="Yes"
              noCaption="No"
              title="Are you sure?"
              description={`By clicking "Yes" you will disable the old integration and enable the current one`}
              onDiscard={hideConfirm}
              onClose={hideConfirm}
              onConfirm={updateSelectedProviderAfterConfirm}
              btnSize="medium"
            />
          </SharedElementOverlay>
          <IntegrationTile
            mode={mode}
            isDisabled={
              providers?.[ReviewProviderName.OKENDO]?.canBeSelected !== true
            }
            onChange={(state) =>
              onSelectionChanged(ReviewProviderName.OKENDO, state)
            }
            isSelected={
              providers?.[ReviewProviderName.OKENDO]?.isSelected === true
            }
            staticImage={
              <StaticImage
                width={300}
                placeholder="none"
                loading="eager"
                src="../../assets/integrations/okendo.png"
                alt="Okendo"
              />
            }
          >
            Enable Okendo reviews & rating integration with Visually.io
            Recommendations & Upsells widgets.
          </IntegrationTile>
          <IntegrationTile
            mode={mode}
            isDisabled={
              providers?.[ReviewProviderName.YOTPO]?.canBeSelected !== true
            }
            onChange={(state) =>
              onSelectionChanged(ReviewProviderName.YOTPO, state)
            }
            isSelected={
              providers?.[ReviewProviderName.YOTPO]?.isSelected === true
            }
            staticImage={
              <StaticImage
                width={300}
                placeholder="none"
                loading="eager"
                src="../../assets/integrations/yotpo.png"
                alt="Yotpo"
              />
            }
          >
            Enable Yotpo reviews & rating integration with Visually.io
            Recommendations & Upsells widgets.
          </IntegrationTile>
          <IntegrationTile
            mode={mode}
            isDisabled={
              providers?.[ReviewProviderName.SHOPIFY]?.canBeSelected !== true
            }
            onChange={(state) =>
              onSelectionChanged(ReviewProviderName.SHOPIFY, state)
            }
            isSelected={
              providers?.[ReviewProviderName.SHOPIFY]?.isSelected === true
            }
            staticImage={
              <StaticImage
                width={300}
                placeholder="none"
                loading="eager"
                src="../../assets/integrations/shopify.png"
                alt="Shopify Reviews"
              />
            }
          >
            Enable Shopify reviews & rating integration with Visually.io
            Recommendations & Upsells widgets.
          </IntegrationTile>
          <IntegrationTile
            mode={mode}
            isDisabled={
              providers?.[ReviewProviderName.KLAVIYO]?.canBeSelected !== true
            }
            onChange={(state) =>
              onSelectionChanged(ReviewProviderName.KLAVIYO, state)
            }
            isSelected={
              providers?.[ReviewProviderName.KLAVIYO]?.isSelected === true
            }
            staticImage={
              <div
                style={{
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                }}
              >
                <div style={{ margin: `1.45rem 0px 2rem 2rem` }}>
                  <StaticImage
                    height={40}
                    placeholder="none"
                    loading="eager"
                    src="klavio.png"
                    alt="Klaviyo"
                  />
                </div>
                <HSpace value={2} />
                <strong
                  style={{ fontSize: `1.1rem`, fontFamily: `Inter, serif` }}
                >
                  Product <br /> Reviews
                </strong>
              </div>
            }
          >
            Enable Klaviyo reviews & rating integration with Visually.io
            Recommendations & Upsells widgets.
          </IntegrationTile>
          <IntegrationTile
            mode={mode}
            isDisabled={
              providers?.[ReviewProviderName.STAMPED]?.canBeSelected !== true
            }
            onChange={(state) =>
              onSelectionChanged(ReviewProviderName.STAMPED, state)
            }
            isSelected={
              providers?.[ReviewProviderName.STAMPED]?.isSelected === true
            }
            staticImage={
              <StaticImage
                width={300}
                placeholder="none"
                loading="eager"
                src="../../assets/integrations/stamped.png"
                alt="Stamped"
              />
            }
          >
            Enable Stamped reviews & rating integration with Visually.io
            Recommendations & Upsells widgets.
          </IntegrationTile>
          <IntegrationTile
            mode={mode}
            isDisabled={
              providers?.[ReviewProviderName.LOOX]?.canBeSelected !== true
            }
            onChange={(state) =>
              onSelectionChanged(ReviewProviderName.LOOX, state)
            }
            isSelected={
              providers?.[ReviewProviderName.LOOX]?.isSelected === true
            }
            staticImage={
              <StaticImage
                width={300}
                placeholder="none"
                loading="eager"
                src="../../assets/integrations/loox.png"
                alt="Loox"
              />
            }
          >
            Enable Loox reviews & rating integration with Visually.io
            Recommendations & Upsells widgets.
          </IntegrationTile>
        </>
      )}
    </>
  );
}
