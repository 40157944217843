import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { RulesListHeader } from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/RulesListHeader';
import { Rule } from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/rule/Rule';
import { SaveRuleBtn } from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/rule/saveRuleBtn';
import {
  AdvancedRuling,
  newAdvancedRulingCtx,
} from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/context';
import { AddAnother } from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/rule/AddAnother';
import { OverflowBottom } from '@/components/overflow';
import { VSpace } from '@/components/spacing';

interface Props {
  hide: () => void;
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  height: 100%;
`;

export const RulesList: FC<Props> = ({ hide }) => {
  const ctx = newAdvancedRulingCtx();
  return (
    <AdvancedRuling.Provider value={ctx}>
      <ListInner hide={hide} />
    </AdvancedRuling.Provider>
  );
};

function ListInner({ hide }: { hide: () => void }) {
  const { save, ruleIds, imageSelectMode } = useContext(AdvancedRuling);

  const onSave = () => {
    save();
    hide();
  };

  return (
    <>
      <Container>
        <RulesListHeader onClick={hide} />
        <ScrollArea>
          {ruleIds.map((num) => (
            <Rule key={num} num={num} />
          ))}
          <AddAnother />
          <VSpace value={4} />
        </ScrollArea>
      </Container>
      <OverflowBottom height="12rem" />
      {!imageSelectMode && <SaveRuleBtn onClick={onSave} />}
    </>
  );
}

const ScrollArea = styled.div`
  height: 94%;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: -7rem;
`;
