import * as React from 'react';
import { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IoIosClose } from 'react-icons/io';
import { EditorContext } from '@/features/editor/context/editor-context';
import {
  ChangelogHint,
  Joystick,
} from '@/features/editor/widgets/changelog/placeholder';
import { StickyBigArrow } from '@/components/big-arrow';
import { UserStatsKind } from '@/webapi/use-user-stats';
import { AccountContext, useFeatureBit } from '@/features/account-context';
import { useObservableRef } from '@/components/hooks/use-observeable-ref';
import { FeatureBit } from '@/webapi/use-auth-api';
import { StoreStatsKind } from '@/webapi/use-store-stats';

const HIDE_HOVER_HINT = `vsly_hide_hover_hint`;

export function OnboardAddNewWidget() {
  const isV2Fute = useFeatureBit(FeatureBit.ENABLE_V2_FTUE);
  const { isGoalReached, trackIfGoalNotReached, isStoreGoalReached } =
    useContext(AccountContext);
  const [isVisible, setVisible] = useState(true);
  const {
    experienceState: { currentExperience },
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);

  const observableRef = useObservableRef(null);
  const [, refSetter] = observableRef;

  const hideHint = useMemo(() => {
    const isSilent = sessionStorage.getItem(HIDE_HOVER_HINT) === `true`;
    return (
      isV2Fute &&
      (isStoreGoalReached(StoreStatsKind.HAS_SAVED_ONE_EXPERIENCE) ||
        isStoreGoalReached(
          StoreStatsKind.SHOULD_SHOW_EDITOR_HOVER_HINT_OVERLAY,
        ) ||
        isSilent)
    );
  }, [isV2Fute, isVisible]);

  trackIfGoalNotReached(
    UserStatsKind.SHOULD_SHOW_NEW_EXPERIENCE_ONBOARDING,
    currentExperience.id,
  ).then();

  if (
    !isV2Fute &&
    isGoalReached(UserStatsKind.SHOULD_SHOW_NEW_EXPERIENCE_ONBOARDING)
  ) {
    return null;
  }

  if (hideHint) {
    return null;
  }

  const onClose = (ev) => {
    ev.stopPropagation();
    sessionStorage.setItem(HIDE_HOVER_HINT, `true`);
    setVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isV2Fute ? (
        <V2Wrapper>
          <IoIosClose className="close-btn" onClick={onClose} />
          <Joystick />
          <span>
            Hover over a section
            <br />
            to add one above,
            <br />
            below, or edit it, or you <br />
            can add a new block
            <br />
            from the options on <br />
            the right
          </span>
        </V2Wrapper>
      ) : (
        <Wrapper ref={refSetter}>
          <ChangelogHint noCta device={device}>
            Hover over a section to add or edit it,
            <br /> or try to add a new one from the options below
          </ChangelogHint>
          <StickyBigArrow
            observableRef={observableRef}
            topOffset={20}
            leftOffset={-120}
          />
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding-right: 8rem;
  margin-bottom: 2rem;

  div {
    grid-template-columns: 6rem 1fr !important;
    grid-gap: 1.5rem !important;
  }

  span {
    padding: 0.5rem 0;
    line-height: 2.3rem;
    font-size: 1.3rem !important;
    color: #818e94;
  }
`;

const V2Wrapper = styled.div`
  position: fixed;
  z-index: 100;
  left: 33vw;
  top: 50vh;
  width: 20rem;
  height: auto;
  background: rgb(111, 115, 117);
  color: white;

  display: flex;
  flex-direction: column;
  line-height: 2.3rem;
  font-size: 1.3rem !important;
  font-weight: bold;
  gap: 1.5rem;
  padding: 2rem;
  border-radius: 2rem;

  &&:before {
    content: '';
    position: absolute;
    height: 0px;
    width: 0px;
    top: calc(50% - 1rem);
    left: -1.9rem; /* 1px buffer for zooming problems while rendering*/
    border-width: 1rem;
    border-color: transparent rgb(111, 115, 117) transparent transparent;
    border-style: solid;
  }

  .close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2.3rem !important;
    cursor: pointer;
    color: white;
    background: #4d5355;
    border-radius: 50%;
    transition: opacity 0.2s linear;
  }

  .close-btn:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .close-btn:active {
    cursor: pointer;
    opacity: 0.6;
  }

  .gatsby-image-wrapper {
    width: 3rem;
  }
`;
