import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { useDebouncedCallback } from 'use-debounce';
import { CloseButton } from '@/components/close-button';
import useAutoFocus from '@/components/hooks/useAutoFocus';
import { BigButton } from '@/components/big-button';
import { buildAllUrls } from '@/features/editor/context/use-device-navigation';
import { AccountContext } from '@/features/account-context';
import { EditorContext } from '@/features/editor/context/editor-context';
import { OverflowBottom } from '@/components/overflow';

export function LinkInputPopover({
  defaultValue,
  width,
  onClose,
  onSelection,
}) {
  const { account } = useContext(AccountContext);
  const { resources } = useContext(EditorContext);
  const { ref } = useAutoFocus({ setAutoFocus: true });
  const [text, setText] = useState(defaultValue);
  const [isLink, setIsLink] = useState(true);
  const [searchResults, setSearchResults] = useState<
    { url: string; caption: string }[]
  >([]);

  const allUrls = useMemo(() => {
    const urls = buildAllUrls(
      account,
      resources?.collections,
      resources?.products,
    );
    urls.push({
      // eslint-disable-next-line no-script-url
      url: `javascript:window.loomi_api.openDrawer()`,
      caption: `Open Cart Drawer`,
    });
    return urls;
  }, []);

  const searchCatalog = useDebouncedCallback(
    (text: string) =>
      setSearchResults(
        allUrls
          .filter(
            (url) =>
              url.url.includes(text) ||
              url.caption.toLowerCase().includes(text.toLowerCase()),
          )
          .slice(0, 100),
      ),
    300,
  );

  useEffect(() => {
    const isInputLink = isValidLink(text);
    if (!isInputLink) searchCatalog(text);
    setIsLink(isInputLink);
  }, [text]);

  const onResultClicked = (ev, result: { url: string; caption: string }) => {
    ev.stopPropagation();
    setText(result.url);
  };

  return (
    <Wrapper width={width}>
      <Header>
        Add Link <CloseButton onClick={onClose} />
      </Header>
      <Content>
        <SearchInput
          value={text}
          onChange={(ev) => setText(ev.target.value)}
          minRows={1}
          maxRows={5}
          ref={ref}
          placeholder="Paste a link or search for page"
        />
        {isLink || text.length === 0 ? (
          <Hint>
            You can search for products or categories pages in order to link
            them. Or you can paste your own link.
            <br />
            Valid Links should start with http(s):// or www
          </Hint>
        ) : (
          <Results>
            {searchResults.map((result) => (
              <ResultItem
                key={result.url}
                onClick={(ev) => onResultClicked(ev, result)}
              >
                {result.caption}
              </ResultItem>
            ))}
          </Results>
        )}
        <OverflowBottom style={{ bottom: `-2.3rem` }} />
      </Content>
      <div style={{ background: `white`, zIndex: 100 }}>
        <BigButton
          disabled={!isValidLink(text)}
          color="white"
          background="#3451fa"
          noTransform
          fillWidth
          size="medium"
          onClick={() => onSelection(wrapWithProtocol(text))}
        >
          Apply
        </BigButton>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: white;
  display: grid;
  grid-template-rows: 5rem 34rem 4rem;
  justify-content: stretch;
  align-items: center;
  width: ${(props) => props.width}px;
  padding: 0rem 2rem 1rem 2rem;
  box-shadow: 0 22px 154px 0 rgba(0, 0, 0, 0.17),
    0 18px 46px 0 rgba(177, 217, 203, 0.18), 0 8px 24px 0 rgba(0, 0, 0, 0.12);
  border-radius: 1rem;
  grid-gap: 1rem;
`;

const Header = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-size: 1.3rem;
  color: black;
  font-weight: bold;

  border-bottom: 1px solid #e5e7e8;

  button {
    transform: scale(0.5, 0.5);
  }
`;

const Content = styled.div`
  align-self: stretch;
  position: relative;
`;

const SearchInput = styled(TextareaAutosize)`
  && {
    width: 100%;
    resize: none;

    font-size: 1.2rem;
    font-weight: 500;
    font-family: 'JetBrains Mono', serif;

    color: #545454;

    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: none;
    border-radius: 2rem;

    padding: 1rem 2rem;
    z-index: 100;

    :focus-visible {
      color: #545454;
      border: 1px solid rgba(0, 0, 0, 0.2);
      outline: none;
      box-shadow: 0 1px 4px 0 rgba(68, 72, 80, 0.08);
    }
  }
`;

const Results = styled.div`
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  font-family: 'JetBrains Mono', serif;
  font-size: 1.4rem;
  font-weight: 500;
  color: #111111;
  padding: 1rem 1rem;

  && > :not(:first-child) {
    margin-top: 2rem;
  }
`;

const ResultItem = styled.span`
  cursor: pointer;
  user-select: none;
  text-overflow: ellipsis;
  :hover {
    color: #3451fa;
  }
`;

const Hint = styled.div`
  width: 100%;
  padding: 5rem 15%;
  font-size: 1.4rem;
  color: #8f8d8d;
  text-align: center;
  line-height: 2.5rem;
`;

function wrapWithProtocol(url: string): string {
  if (url === ``) {
    return ``;
  }

  // eslint-disable-next-line no-script-url
  if (url.startsWith(`javascript:`)) {
    return url;
  }

  if (!url.startsWith(`http`)) {
    url = `https://${url}`;
  }
  return url;
}

function isValidLink(str: string): boolean {
  return (
    str.startsWith(`http`) ||
    str.startsWith(`www.`) ||
    // eslint-disable-next-line no-script-url
    str.startsWith(`javascript:`) ||
    str === ``
  );
}
