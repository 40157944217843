import React from 'react';
import styled from 'styled-components';

const Inventory = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
  font-size: 1rem;
  text-transform: uppercase;
`;

export function InventoryQty({
  inventoryQuantity,
}: {
  inventoryQuantity: string;
}) {
  if (Number.isNaN(parseInt(inventoryQuantity, 10))) {
    inventoryQuantity = `0`;
  }
  return <Inventory>({inventoryQuantity} items)</Inventory>;
}
