import styled from 'styled-components';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { DeviceType } from '@/utils/definitions';

export function CloseButton(props: CloseButtonProps) {
  const { children, device, wrapperRef, ...rest } = props;
  return (
    <Wrapper ref={wrapperRef} {...rest}>
      {device === DeviceType.Desktop ? (
        <StaticImage
          src="../assets/close_x.svg"
          alt="close"
          loading="eager"
          placeholder="none"
          height={20}
        />
      ) : (
        <StaticImage
          src="../assets/close_x.svg"
          alt="close"
          loading="eager"
          placeholder="none"
          height={25}
        />
      )}
      {children}
    </Wrapper>
  );
}

export function SmallCloseButton(props: CloseButtonProps) {
  const { wrapperRef, ...rest } = props;
  return (
    <Wrapper ref={wrapperRef} {...rest}>
      ✕
    </Wrapper>
  );
}

export interface CloseButtonProps
  extends React.ComponentPropsWithRef<'button'> {
  color?: string;
  device?: DeviceType;
  wrapperRef?: React.MutableRefObject<HTMLButtonElement>;
}

export const Wrapper = styled.button`
  appearance: none;
  outline: none;
  text-decoration: none;
  border: none;
  cursor: pointer;

  color: ${(props: CloseButtonProps) =>
    props.color ? props.color : `#5B656E`};

  background: transparent;

  :hover {
    opacity: 0.8; //TODO
  }

  :active {
    opacity: 0.2; //TODO
  }
`;
