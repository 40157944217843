import React, { FC, useContext } from 'react';
import { StrategyPerSlotContext } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/context/context';
import {
  ManualImageSelectHeaderInner,
  ManualImageSelectInner,
} from '@/features/editor/widgets/custom-widget/loading-section/shared/manual-image-select';

export const ManualImageSelect: FC<any> = () => {
  const ctx = useContext(StrategyPerSlotContext);
  if (!ctx.isManualImageSelectionOpen) {
    return null;
  }
  const images = ctx.manualImageSelectionOpen?.images || [];
  const onSelect = (src: string) => () => {
    ctx.selectProductForSlot(
      {
        ...ctx.manualImageSelectionOpen,
        missingVariantImageMapping: false,
        imageOverride: src,
        image: { src },
      },
      ctx.productSearchSlotOpen,
    );
    ctx.setManualImageSelectionOpen(undefined);
  };

  return <ManualImageSelectInner images={images} onSelect={onSelect} />;
};

export function ManualImageSelectHeader() {
  const ctx = useContext(StrategyPerSlotContext);
  return (
    <ManualImageSelectHeaderInner
      onBack={() => ctx.setManualImageSelectionOpen(undefined)}
    />
  );
}
