import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { animated, useSpring } from '@react-spring/web';

export interface SelectTileProps extends PropsWithChildren<any> {
  initialValue?: boolean;
  border?: string;
  onSelected?: (value: boolean) => void;
  height?: string;
}

export function SelectTile({
  initialValue,
  onSelected,
  border,
  children,
  height,
  margin,
}: SelectTileProps) {
  const onClick = () => {
    if (onSelected) {
      onSelected(!initialValue);
    }
  };

  const showCheckmarkSpring = useSpring({
    from: { opacity: 0, width: 0 },
    to: { opacity: initialValue ? 1 : 0, width: initialValue ? 30 : 0 },
  });

  return (
    <Wrapper
      border={border}
      height={height}
      margin={margin}
      selected={initialValue}
      onClick={onClick}
    >
      <animated.div style={showCheckmarkSpring}>
        <StaticImage
          imgStyle={{ marginTop: `0.2rem` }}
          placeholder="none"
          height={15}
          src="../assets/blue_v.svg"
          alt="blue checkmark"
        />
      </animated.div>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled(animated.div)`
  height: ${(props: { height?: string }) =>
    !props.height ? `5rem` : props.height};
  margin: ${(props: { margin?: string }) =>
    !props.margin ? `auto` : props.margin};
  width: 100%;

  padding: 1rem 1.3rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  overflow-x: hidden;

  border-radius: 1rem;
  border: ${(props: { border?: string }) =>
    !props.border ? `none` : props.border};

  font-family: 'JetBrains Mono', serif;
  font-size: 1.4rem;
  font-weight: 500;
  color: #5b656e;
`;
