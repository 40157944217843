import React from 'react';
import styled from 'styled-components';
import { InstagramAccount } from '@/features/editor/widgets/custom-widget/inputs/background/image/facebook/fb_api';
import { BigButton } from '@/components/big-button';

export function InstaAccountSelect({
  account,
  instagramAccounts,
  onClick,
  setAccount,
}: {
  instagramAccounts: Array<InstagramAccount>;
  account?: InstagramAccount;
  setAccount: (x: any) => void;
  onClick: () => Promise<void>;
}) {
  return (
    <AccountList>
      <AccountsTitle>
        Hi, we have detected multiple Instagram accounts
        <br />
        connected to your account, which account would you like to sync?
      </AccountsTitle>
      <AccountsScroll>
        {instagramAccounts?.map((x) => (
          <AccountWrap key={x.id}>
            <Account
              active={x.id === account?.id}
              onClick={() => setAccount(x)}
            >
              {x.name}
            </Account>
          </AccountWrap>
        ))}
      </AccountsScroll>

      <ButtonWrap>
        <BigButton
          background="blue"
          color="white"
          fillWidth
          size="large"
          disabled={!account?.id}
          onClick={onClick}
        >
          Select
        </BigButton>
      </ButtonWrap>
    </AccountList>
  );
}

const ButtonWrap = styled.div`
  margin-top: 3rem;
  width: 100%;
  padding: 0 12rem;
`;
const AccountsScroll = styled.div`
  height: 60vh;
  overflow-y: scroll;
`;
const AccountsTitle = styled.div`
  color: #828f9b;
  font-family: 'JetBrains Mono', serif;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  padding: 0 2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;
const AccountList = styled.div`
  padding: 1rem;
`;
const AccountWrap = styled.div`
  padding: 0 1rem;
  position: relative;
`;
const Account = styled.div`
  padding: 3rem 2rem;
  display: flex;
  justify-content: start;
  align-items: center;
  border: 2px solid ${(p) => (p.active ? `blue` : `#e5e9ea`)};
  border-radius: 10px;
  margin: 2rem 0;
  color: ${(p) => (p.active ? `black` : `#6a7782`)};
  font-size: 1.5rem;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15),
    0 14px 16px 0 rgba(151, 210, 206, 0.22), 0 5px 16px 0 rgba(0, 0, 0, 0);
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
  :active {
    cursor: default;
    opacity: 1;
  }
`;
