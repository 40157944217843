import * as React from 'react';
import styled from 'styled-components';
import { useMemo, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { DropdownInputOption } from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';

export interface RadioButtonsProps {
  defaultValue?: string;
  onChange?: (val: string) => void;

  title?: string;
  options?: DropdownInputOption[];
  smallTitle?: boolean;
  allowCustomEdit?: boolean;
  customEditHint?: string;
}

export function RadioButtons(props: RadioButtonsProps) {
  const {
    defaultValue,
    onChange,
    options,
    title,
    smallTitle,
    allowCustomEdit,
    customEditHint,
  } = props;

  const [isCustomClicked, setIsCustomClicked] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  const onClick = (val: string) => {
    setIsCustomClicked(false);
    onChange(val);
  };

  const isCustom = useMemo(
    () =>
      options?.find((opt) => opt.value === defaultValue) === undefined ||
      isCustomClicked,
    [options, defaultValue, isCustomClicked],
  );

  const onCustomSelection = () => {
    setIsCustomClicked(true);
  };

  return (
    <Wrapper small={smallTitle}>
      <QuickEditTitle small={smallTitle}>{title}</QuickEditTitle>
      <ChipContainer>
        {options?.map((opt) => (
          <Chip
            key={opt.value}
            isSelected={opt.value === defaultValue && !isCustom}
            onClick={() => onClick(opt.value)}
          >
            <span>{opt.label}</span>
          </Chip>
        ))}
        {allowCustomEdit && (
          <Chip isSelected={isCustom} onClick={onCustomSelection}>
            <span>Custom</span>
          </Chip>
        )}
      </ChipContainer>
      {isCustom && (
        <CustomTextLayout>
          <QuickEditTitle small>Type custom input</QuickEditTitle>
          <CustomTextInput
            onChange={(e) => onChange(e.target.value)}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            hasFocus={hasFocus}
            defaultValue={defaultValue}
          />
          {customEditHint && (
            <QuickEditTitle className="hint" small>
              {customEditHint}
            </QuickEditTitle>
          )}
        </CustomTextLayout>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${(p) => (p.small ? `1rem` : `1.5rem`)};
  width: 100%;
`;

export const QuickEditTitle = styled.span`
  color: #828f9c;
  font-family: Inter, serif;
  font-size: ${(p) => (p.small ? `1.1rem` : `1.3rem`)};
  font-weight: ${(p) => (p.small ? `600` : `bold`)};
  letter-spacing: -0.27px;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  && > * {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
`;

interface CP {
  isSelected: boolean;
}

const Chip = styled.span`
  display: flex;
  min-width: 5.5rem;
  text-align: left;
  justify-content: center;

  border-radius: 2rem;
  background-color: ${(p: CP) => (p.isSelected ? `#ffffff` : `#dfe6e9`)};
  box-shadow: ${(p: CP) =>
    p.isSelected
      ? `0 1px 1px 0 rgba(0, 0, 0, 0.15),
    0 12px 14px 0 rgba(151, 210, 206, 0.22), 0 4px 14px 0 rgba(0, 0, 0, 0)`
      : `0 2px 7px 0 rgba(0, 0, 0, 0)`};

  padding: 1rem 1.5rem;
  color: #000000;
  font-family: 'JetBrains Mono', serif;
  font-size: 1.3rem;
  letter-spacing: 0.25px;
  user-select: none;
  cursor: pointer;

  span {
    user-select: none;
    font-weight: ${(p: CP) => (p.isSelected ? `800` : `600`)};
    justify-self: center;
    align-self: center;
  }

  :hover {
    opacity: 0.7;
  }

  :active {
    opacity: 0.6;
  }

  transition: opacity 0.2s linear;
`;

const CustomTextLayout = styled.div`
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .hint {
    font-weight: normal !important;
    margin-bottom: 1rem;
  }
`;

const CustomTextInput = styled(TextareaAutosize)`
  && {
    resize: none;
    outline: none;
    font-size: 1.4rem;
    font-weight: 600;
    font-family: 'JetBrains Mono', serif;
    letter-spacing: -0.44px;
    color: #4b5564;

    margin: 0;

    border-radius: 0.8rem;
    background: white;
    padding: 0.8rem 1.7rem;
    border: ${(props: { hasFocus: boolean }) =>
      props.hasFocus
        ? `1px solid #95a2ae`
        : `1px solid rgba(149, 162, 174, 0.48)`};
    box-shadow: 0 9px 13px 0 rgba(177, 217, 203, 0.18);
    transition: border 0.3s ease-out;
  }
`;
