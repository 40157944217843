import React from 'react';

export function MinusSvg() {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 45 45"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect
          id="Rectangle"
          fill="#B6BAC5"
          x="9"
          y="19"
          width="28"
          height="6"
          rx="3"
        />
      </g>
    </svg>
  );
}
