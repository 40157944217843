export function conditional(fallback: any, ...args: any) {
  if (!args || args?.length <= 0 || args.length % 2 !== 0) {
    throw new Error(
      `Conditional must have even number of properties where the first is bool and the second is the returned value`,
    );
  }

  for (let i = 0; i < args.length; i += 2) {
    const cond = args[i];
    const returned = args[i + 1];

    if (cond) {
      return returned;
    }
  }

  return fallback;
}
