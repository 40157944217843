import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { StyledTemplateContext } from '@/features/editor/widgets/custom-widget/style-templating/context';
import { VSpace } from '@/components/spacing';
import { TextInput } from '@/components/text-input';
import { StoreStylesOverrideForm } from '@/features/editor/widgets/custom-widget/style-templating/store-styles-override-form';

export function ModalContent() {
  const {
    invalidName,
    modalCtx,
    deleteStyleId,
    showOverrideModal,
    showUpdateStoreStyleModal,
  } = useContext(StyledTemplateContext);
  if (showUpdateStoreStyleModal) {
    return <StoreStylesOverrideForm />;
  }
  if (deleteStyleId) {
    return <VSpace value={4} />;
  }

  const textRef = useAutoFocusRef();
  const { setName, name, isDefault, isUnChanged } = modalCtx;
  return (
    <Container>
      <VSpace value={showOverrideModal ? 6 : 4} />
      <TextInput
        ref={textRef}
        onChange={(e) => setName(e.target.value)}
        defaultValue={isDefault ? `` : name}
        placeholder={isUnChanged ? undefined : name}
        style={{ width: `95%`, boxShadow: `none` }}
      />
      <Error invalidName={invalidName}>
        *This style name is already in use, choose another name
      </Error>
    </Container>
  );
}

const Error = styled.p<{ invalidName: boolean }>`
  opacity: ${({ invalidName }) => (invalidName ? 1 : 0)};
  user-select: none;
  margin-top: 2rem;
  font-family: 'JetBrains Mono', serif;
  font-size: 1.3rem;
  font-weight: bolder;
  letter-spacing: -0.38px;
  color: red;
`;
const Container = styled.div`
  flex-direction: column;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function useAutoFocusRef() {
  const textRef = useRef<HTMLInputElement>();
  useEffect(() => {
    setTimeout(() => {
      textRef.current?.focus?.();
    }, 2500);
  }, []);
  return textRef;
}
