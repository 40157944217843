import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { SketchPickerStyles } from 'react-color/lib/components/sketch/Sketch';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { useEscapeKey } from '@/features/editor/widgets/custom-widget/inputs/shared/useControlPopover';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';
import {
  EyeDropper,
  useEyeDropProps,
} from '@/features/editor/widgets/custom-widget/inputs/background/color/eyeDropper';

export interface ColorInputProps extends GenericInputProps {
  envKey: string;
  onChange?: (value: string) => void;
  defaultValue?: string;
}

export function ColorInput({
  envKey,
  onChange,
  defaultValue,
  initialValues,
  onValuesChanged,
}: ColorInputProps) {
  const [showPicker, setShowPicker] = useState(false);

  const { undoRedoCount } = useContext(UndoRedoCounterContext);
  const getValue = useCallback(() => initialValues(envKey), [undoRedoCount]);

  const [color, setColor] = useState(defaultValue || getValue());
  useEffect(() => setColor(getValue()), [undoRedoCount]);

  const formatColor = (value) =>
    `rgba(${value?.rgb?.r},${value?.rgb?.g},${value?.rgb?.b},${
      value?.rgb?.a || 1
    })`;

  const onColorChange = (value) => {
    const selected = formatColor(value);
    setColor(selected);
    if (onChange) {
      onChange(selected);
    } else {
      onValuesChanged(envKey, selected);
    }
  };

  const onShowPicker = () => {
    setShowPicker(!showPicker);
  };
  useEscapeKey(() => setShowPicker(false));
  const props = useEyeDropProps();
  useEffect(() => {
    if (props.color != null) {
      setColor(props.color);
      if (onChange) {
        onChange(props.color);
      } else {
        onValuesChanged(envKey, props.color);
      }
    }
  }, [props.color]);

  return (
    <Popover
      isOpen={showPicker}
      positions={[`bottom`, `right`, `top`, `left`]}
      onClickOutside={() => setShowPicker(false)}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="white"
          arrowSize={10}
          arrowStyle={{ opacity: 1 }}
        >
          <PickerWrapper>
            {props.isSupported() && (
              <Dropper>
                <EyeDropper {...props} />
              </Dropper>
            )}
            <SketchPicker
              styles={
                {
                  sliders: { width: props.isSupported() ? `70%` : `100%` },
                } as SketchPickerStyles
              }
              color={color}
              onChangeComplete={onColorChange}
              onChange={onColorChange}
            />
          </PickerWrapper>
        </ArrowContainer>
      )}
    >
      <Wrapper>
        <Inner color={color} onClick={onShowPicker} />
      </Wrapper>
    </Popover>
  );
}
const Dropper = styled.div`
  position: absolute;
  top: 121px;
  left: 179px;
`;
const PickerWrapper = styled.div`
  position: relative;
`;
const Wrapper = styled.div`
  cursor: pointer;
  height: 100%;
  width: 100%;
  background-position: 0px 0px, 8.5px 8.5px;
  background-size: 17px 17px;
  background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.1) 25%,
      transparent 25%,
      transparent 75%,
      rgba(0, 0, 0, 0.1) 75%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.1) 25%,
      white 25%,
      white 75%,
      rgba(0, 0, 0, 0.1) 75%,
      rgba(0, 0, 0, 0.1) 100%
    );
  border-radius: 8px;
  box-shadow: 0 9px 13px 0 rgba(177, 217, 203, 0.18);
  border: 1px solid #dedede;
  display: flex;
`;

const Inner = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background: ${(props: { color: string }) =>
    props.color ? props.color : `black`};
`;
