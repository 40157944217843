import styled from 'styled-components';

export const DefaultTypography = styled.span`
  margin: 0;
  font-size: 1.6rem;
  font-family: 'Inter', serif;
  font-weight: 400;
  text-align: start;
  text-decoration: none;
  border: none;
  background: transparent;
`;

export const Heading1 = styled(DefaultTypography)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.5rem;
    font-weight: bold;
    color: black;
    letter-spacing: -0.7px;
  }
`;

export const Heading2 = styled(DefaultTypography)`
  && {
    font-size: 2.6rem;
    font-weight: 800;
    color: #111111;
    letter-spacing: -0.38px;
  }
`;

export const Heading3 = styled(DefaultTypography)`
  && {
    font-size: 2rem;
    font-weight: 600;
    color: #727889;
    letter-spacing: -0.27px;
  }
`;

export const Heading4 = styled(DefaultTypography)`
  && {
    font-size: 1.8rem;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
    color: #727889;
  }
`;

export const Text = styled(DefaultTypography)`
  && {
    font-size: 1.4rem;
    color: #727889;
    font-weight: 500;
    letter-spacing: -0.19px;
  }
`;

export const Note = styled(DefaultTypography)`
  && {
    font-size: 1.6rem;
    font-weight: 500;
    text-decoration: underline;
    color: #727889;
    letter-spacing: -0.22px;
    user-select: none;
    ${(props) =>
      props.clickable
        ? `
    transition: opacity 0.3s linear;
    cursor: pointer;
    :hover {
      opacity: 0.8
    }
    :active {
      opacity: 1
    }
    `
        : ``}
  }
`;

export const InputText = styled(DefaultTypography)`
  && {
    font-size: 2rem;
    color: #9f9f9f;
    letter-spacing: 0.19px;
    font-weight: normal;
  }
`;
