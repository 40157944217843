import React, { useContext } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Card } from '@/components/card';
import { DeviceType } from '@/utils/definitions';
import { EditorContext } from '@/features/editor/context/editor-context';

export const TEMP_NO_SELECTOR = `head > link`;

export function ChangelogHint({
  device,
  noCta,
  children,
  content,
}: {
  device: DeviceType;
  noCta?: boolean;
  children?: any;
  content?: any;
}) {
  const { inspectorNav, isEditingCheckoutExtensibility } =
    useContext(EditorContext);
  const onNewContent = () => {
    inspectorNav.gotoAppCatalog({
      action: `pick`,
      kind: `appendAfter`,
      selector: TEMP_NO_SELECTOR,
    });
  };

  return (
    <Wrapper device={device}>
      {content || <Joystick />}
      <Title device={device}>
        {children ||
          (isEditingCheckoutExtensibility
            ? `Add elements by clicking on the pink sections or hovering over an existing section to edit`
            : `Hover on a section in order to edit or `)}
        {!noCta && !isEditingCheckoutExtensibility && (
          <Cta onClick={onNewContent} device={device}>
            Add {device === DeviceType.Desktop ? `` : `new`}
          </Cta>
        )}
      </Title>
    </Wrapper>
  );
}

const Wrapper = styled(Card)`
  && {
    min-height: 7rem;
    border-radius: 1.8rem;
    cursor: w-resize;
    background-color: #eff4f6;
    border: 2px solid #eff4f6;
    box-shadow: none;
    padding: ${(props: { device: DeviceType }) =>
      props.device === DeviceType.Desktop ? `1rem 1rem` : `1rem 1rem`};
    display: grid;
    grid-template-columns: ${(props: { device: DeviceType }) =>
      props.device === DeviceType.Desktop ? `4rem 1fr` : `4rem 1fr`};
    grid-column-gap: ${(props: { device: DeviceType }) =>
      props.device === DeviceType.Desktop ? `2rem` : `2rem`};
    align-items: center;
    justify-content: center;
  }
`;

const Title = styled.span`
  color: #8d99ae;
  letter-spacing: -0.47px;
  font-size: ${(props: { device: DeviceType }) =>
    props.device === DeviceType.Desktop ? `1.15rem` : `1.4rem`};
  font-weight: bold;
  line-height: 2.5;
`;

const Cta = styled.span`
  line-height: 1.2;
  cursor: pointer;
  background: #4179ff;
  display: inline-block;
  font-size: 1.3rem;
  color: white;
  border-radius: 3rem;
  padding: ${(props: { device: DeviceType }) =>
    props.device === DeviceType.Desktop ? `0.8rem 1.8rem` : `0.8rem 2.2rem`};
  font-weight: bold;
  margin-left: ${(props: { device: DeviceType }) =>
    props.device === DeviceType.Desktop ? `0.5rem` : `1rem`};
  :hover {
    opacity: 0.8; //TODO
  }

  :active {
    opacity: 0.2; //TODO
  }
`;

export function Joystick() {
  return (
    <StaticImage
      src="./joystick.svg"
      alt="Joystick"
      width={25}
      objectFit="contain"
      placeholder="none"
      loading="eager"
    />
  );
}
