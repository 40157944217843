import React, { FC, useContext, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { EditorContext } from '@/features/editor/context/editor-context';
import { ExperienceStateHook } from '@/features/editor/context/use-experience-state';
import { useFBImagesApi } from '@/features/editor/widgets/custom-widget/inputs/background/image/facebook/fb_api';
import { FacebookTargeting } from '@/components/query-builder/models';
import {
  FBImage,
  ListImagesQuery,
} from '@/features/editor/widgets/custom-widget/inputs/background/image/facebook/models';
import { Spinner } from '@/Spinner';
import { ImageEditorContext } from '@/features/editor/widgets/custom-widget/inputs/background/image/shared/context';
import { Reconnect } from '@/features/editor/widgets/custom-widget/inputs/background/image/facebook/reconnect';
import { DefaultTypography } from '@/components/typography';

function fbTargeting(experienceState: ExperienceStateHook) {
  return experienceState?.currentExperience?.targeting?.facebook;
}

function toQuery(targeting: FacebookTargeting) {
  const ids = Array.from(
    new Set([
      ...(targeting?.campaigns?.map((x) => x.id) || []),
      ...(targeting?.adsets?.map((set) => set.campaign_id) || []),
      ...(targeting?.ads?.map((set) => set.campaign_id) || []),
    ]),
  );
  return {
    adIds: [],
    setIds: [],
    campaignIds: ids,
  } as ListImagesQuery;
}

export const FacebookImages: FC<{ getModalRect: () => DOMRect }> = ({
  getModalRect,
}) => {
  const { experienceState } = useContext(EditorContext);
  const { listImages, loading } = useFBImagesApi(true);
  const targeting = fbTargeting(experienceState);
  const [images, setImages] = useState<Array<FBImage>>([]);
  const [reconnect, setReconnect] = useState(false);
  useEffect(() => {
    (async () => {
      const resp = await listImages(toQuery(targeting));
      if (resp?.notLoggedIn) {
        setReconnect(true);
      } else {
        setImages(resp?.images);
      }
    })();
  }, [reconnect]);
  const { setImage } = useContext(ImageEditorContext);
  const [hoverIdx, setHoverIdx] = useState(-1);

  if (reconnect) {
    return <Reconnect setReconnect={setReconnect} vendor="facebook" />;
  }

  if (loading || images?.length === 0) {
    return (
      <SpinnerWrapper>
        <Spinner size={150} />
      </SpinnerWrapper>
    );
  }
  return (
    <Wrapper h={getModalRect().height}>
      <PhotosGrid id="metaImages">
        <InfiniteScroll
          dataLength={images?.length || 0}
          next={() => null}
          hasMore={false}
          loader={<h4>Loading...</h4>}
          scrollableTarget="metaImages"
        >
          <PhotoGrid>
            {images?.map((image, idx) => (
              <ImageWrapper
                key={image.adName}
                onClick={() => setImage(image.url)}
                onMouseEnter={() => setHoverIdx(idx)}
                onMouseLeave={() => setHoverIdx(-1)}
              >
                <img src={image.url} alt="facebook_ads" />
                {hoverIdx === idx && <AdName>{image.adName}</AdName>}
              </ImageWrapper>
            ))}
          </PhotoGrid>
        </InfiniteScroll>
      </PhotosGrid>
    </Wrapper>
  );
};

interface P {
  h: number;
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${(p: P) => p.h - 150}px 5rem;

  align-items: center;
  justify-content: center;
`;

const PhotosGrid = styled.div`
  height: 100%;
  margin-top: 2rem;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;

  overflow: auto;
`;

const SpinnerWrapper = styled.div`
  div {
    margin-top: 100px;
  }

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.div`
  position: relative;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const PhotoGrid = styled.div`
  height: 100%;
  margin-top: 2rem;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;

  overflow: auto;
`;

const CreditAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(1,0);
  }
  100% {
    opacity: 1;
    transform: scale(1,1);
  }
`;

const AdName = styled(DefaultTypography)`
  && {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: start;
    font-size: 1.2rem;
    color: #eaeef2;
    font-weight: 500;
    letter-spacing: 0.28px;

    padding: 2rem 1rem;

    transform-origin: 100% 100%;
    animation: ${CreditAnimation} ease-out 0.3s;
  }
`;
