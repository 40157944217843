import React from 'react';
import moment, { Moment } from 'moment';
import { MetricKind } from '@/webapi/models';

export async function sleep(
  timeoutRef: React.MutableRefObject<NodeJS.Timeout | undefined>,
  ms: number,
) {
  await new Promise((resolve) => {
    timeoutRef.current = setTimeout(resolve, ms);
  });
  timeoutRef.current = undefined;
}

export function validateEmail(val) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(val);
}

export function dateToUtcInSeconds(date?: Date): number {
  if (!date) {
    const date = new Date();
    return Math.round(date.getTime() / 1000);
  }
  return Math.round(date.getTime() / 1000);
}

export function utcInSecToDate(utc) {
  return new Date(Math.round(Number(utc) * 1000));
}

export function dateToUtcInSec(value: Moment | string | number) {
  return Math.round(moment(value).toDate().getTime() / 1000);
}

export function get24HourTimeString(hours = 0, minutes = 0): string {
  const time = new Date(0, 0, 0, hours, minutes).toLocaleTimeString([], {
    hour: `2-digit`,
    minute: `2-digit`,
    hour12: false,
  });
  return time;
}

export function formatToLocalDate(date: string | Date) {
  if (typeof date === `string`) {
    date = date!.replace(`Z`, ``);
  }
  return new Date(date).toLocaleDateString();
}
export function maybe<T>(f: () => T, def: T | undefined = undefined) {
  try {
    return f();
  } catch {
    return def;
  }
}

export function notProfitMetric() {
  return (m) =>
    !(m === MetricKind.PROFIT || m === MetricKind.PROFIT_PER_SESSION);
}

export function notReturnsMetric() {
  return (m) =>
    !(m === MetricKind.RETURNS || m === MetricKind.RETURNS_PER_SESSION);
}
