import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { useContext, useState } from 'react';
import { AccountContext } from '@/features/account-context';

export function TestStoreDisclaimer() {
  const {
    account: {
      store: { isTestStore },
    },
  } = useContext(AccountContext);

  const [shouldHide, setShouldHide] = useState(false);

  const onClick = () => {
    setShouldHide(true);
  };

  if (!isTestStore) return null;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!shouldHide && (
        <Wrapper>
          Maximize your experience by using a real store instead of a test one
          <Button onClick={onClick}>OK</Button>
        </Wrapper>
      )}
    </>
  );
}

const RevealAnimation = keyframes`
  from {
    opacity: 0;
    transform: scale(1, 0);
  }

  to {
    opacity: 1;
    transform: scale(1, 1);
  }
`;

const Wrapper = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 4rem;
  background: #ffd2ac;
  padding: 0 2rem;

  font-family: Inter, serif;
  font-weight: 400;
  font-size: 1.3rem;
  color: #bd5600;

  animation: ${RevealAnimation} 0.5s ease-in-out;
`;

const Button = styled.button`
  margin-left: 1rem;
  cursor: pointer;
  appearance: none;
  outline: none;
  border-radius: 1rem;
  padding: 0.2rem 1.2rem;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: Inter, serif;

  border: 1.5px solid #ff953c;
  background: transparent;
  color: #ff953c;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.6;
  }
`;
