/* eslint-disable */
import React, { SyntheticEvent, useContext, useState } from 'react';
import styled from 'styled-components';
import { Card } from '@/components/card';
import { SectionTitle } from '../shared/shared-styles';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import {
  ManualProductPicker,
  useManualProductsModalProps,
} from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker';
import {
  ManualProductsPicker,
  newProductsPickerCtx,
} from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/context';
import { AdvancedEditing } from '@/features/editor/widgets/custom-widget/loading-section/conditional-product';
import { DefaultTypography } from '@/components/typography';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import vImage from '../../../../../assets/v_indicator.svg';
import {
  CatalogWidgetProps,
  getRecStrategy,
  LoadingStrategyOption,
  RecommendationType,
  RecStrategy,
} from '@/webapi/use-widget-catalog-api';
import { DeviceType } from '@/utils/definitions';
import {
  AdvancedProductRulingPicker,
  useAdvancedRulingModalProps,
} from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling';
import { StrategyPerSlot } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot';
import { LayeredRuling } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling';
import { getManualProductsSize } from '@/features/editor/widgets/custom-widget/loading-section/util';
import { StrategyPerSlotContext } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/context/context';
import { LayeredRulingContext } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import { getProductIds, useStrategyPerSlot } from '@/features/editor';
import { useLayeredRuling } from '@/features/editor/widgets/shared/useLayeredRuling';

export function LoadingSection({
  recStrategies,
  device,
}: {
  recStrategies: RecStrategy;
  device: DeviceType;
}) {
  const {
    currentSchema,
    updateLoadingCfg,
    updateRecommendationOptions,
    updateRecommendationCondition,
  } = useContext(CustomWidgetContext);
  const {
    hide: hideManual,
    show: showManual,
    fromRef: manualRef,
    props: manualProps,
  } = useManualProductsModalProps();

  const {
    hide: hideRuling,
    show: showRuling,
    props: rulingProps,
    fromRef: rulingRef,
  } = useAdvancedRulingModalProps();

  const productIds = getProductIds(currentSchema);
  const productPickerCtx = newProductsPickerCtx(
    productIds,
    getManualProductsSize(currentSchema, device),
  );
  const {
    strategyPerSlotCtx,
    showStrategyPerSlot,
    setShowStrategyPerSlot,
    isRecsV2Enabled,
  } = useStrategyPerSlot(device, currentSchema);
  const [isConditionalModalVisible, setIsConditionalModalVisible] =
    useState(false);
  const {
    showNewRuling,
    setShowRuling,
    layeredRulingCtx,
    isAdvancedEditingV2Enabled,
  } = useLayeredRuling(device, currentSchema);

  if (!recStrategies) {
    return null;
  }

  return (
    <>
      <div ref={manualRef} />
      <div ref={rulingRef} />
      <SectionTitle>{recStrategies.title}</SectionTitle>
      <ListWrapper>
        <ManualProductsPicker.Provider value={productPickerCtx}>
          <ManualProductPicker
            updateRecommendationOptions={updateRecommendationOptions}
            hide={hideManual}
            {...manualProps}
          />
        </ManualProductsPicker.Provider>
        <StrategyPerSlotContext.Provider value={strategyPerSlotCtx}>
          <StrategyPerSlot
            isOpen={showStrategyPerSlot}
            close={() => setShowStrategyPerSlot(false)}
            fromRef={manualRef}
          />
        </StrategyPerSlotContext.Provider>
        <LayeredRulingContext.Provider value={layeredRulingCtx}>
          <LayeredRuling
            isOpen={showNewRuling}
            fromRef={rulingRef}
            close={() => setShowRuling(false)}
          />
        </LayeredRulingContext.Provider>
        <AdvancedEditing
          fromRef={rulingRef}
          setIsVisible={setIsConditionalModalVisible}
          isVisible={isConditionalModalVisible}
          strategy={getRecStrategy(currentSchema).type}
          updateRecommendationCondition={updateRecommendationCondition}
          initialState={getInitialState(currentSchema)}
        />
        <AdvancedProductRulingPicker hide={hideRuling} {...rulingProps} />

        {recStrategies?.options?.map((item) => {
          const value = getRecStrategy(currentSchema);
          const sameType = item.value.type === value?.type;
          let isSelected = sameType && !value?.default;
          const shouldShowDescription =
            !isSelected ||
            isManualV2(isRecsV2Enabled, item) ||
            isAdvancedRulingV2(isAdvancedEditingV2Enabled, item);
          return (
            <CardBtn
              isSelected={isSelected}
              key={item.label}
              onClick={() => {
                updateLoadingCfg(item);
                if (isRecsV2Enabled) {
                  shouldOpenManualPicker(item) && setShowStrategyPerSlot(true);
                } else {
                  shouldOpenManualPicker(item) && showManual();
                }
                if (isAdvancedEditingV2Enabled) {
                  shouldOpenAdvancedRuling(item) && setShowRuling(true);
                } else {
                  shouldOpenAdvancedRuling(item) && showRuling();
                }
              }}
            >
              <Title isSelected={isSelected}>{item.label}</Title>
              {shouldShowDescription && (
                <Description>{item?.description || `Explanation`}</Description>
              )}
              <CardDescription
                isRecsV2Enabled={isRecsV2Enabled}
                isAdvancedEditingV2Enabled={isAdvancedEditingV2Enabled}
                isSelected={isSelected}
                item={item}
                showCond={() => setIsConditionalModalVisible(true)}
              />
            </CardBtn>
          );
        }) || null}
      </ListWrapper>
    </>
  );
}

export function isManualV2(isRecsV2Enabled: boolean, item) {
  return isRecsV2Enabled && item.value.type === RecommendationType.MANUAL;
}

export function isAdvancedRulingV2(isAdvancedEditingV2Enabled: boolean, item) {
  return (
    isAdvancedEditingV2Enabled &&
    item.value.type === RecommendationType.ADVANCED_RULING
  );
}

export function CardDescription({
  isSelected,
  showCond,
  isRecsV2Enabled,
  isAdvancedEditingV2Enabled,
  item,
}: {
  isSelected: boolean;
  item: any;
  isRecsV2Enabled: boolean;
  isAdvancedEditingV2Enabled: boolean;
  showCond: () => void;
}) {
  if (
    isManualV2(isRecsV2Enabled, item) ||
    isAdvancedRulingV2(isAdvancedEditingV2Enabled, item)
  ) {
    return null;
  }
  return isSelected ? (
    <Description
      onClick={(e: SyntheticEvent) => {
        if (shouldShowCond(item)) {
          e.stopPropagation();
          showCond();
        }
      }}
    >
      Click for <u>more filters</u>
    </Description>
  ) : null;
}

interface P {
  isSelected: boolean;
}

export const CardBtn = styled(Card)`
  && {
    position: relative;
    width: 100%;
    padding: 3rem 2rem;
    text-align: center;
    font-size: 1.38rem;
    font-weight: 500;
    border: ${({ isSelected }: P) =>
      isSelected
        ? `3px solid #3451FA`
        : `0.5px solid rgba(230, 233, 236, 0.55)`} !important;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3),
      0 4px 11px 0 rgba(151, 210, 206, 0.22);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    ${({ isSelected }: P) =>
      !isSelected
        ? ``
        : `::after {
      position: absolute;
      bottom: -1rem;
      right: -1rem;
      content: '';
      background: url(${vImage});
      background-repeat: no-repeat;
      background-size: contain;
      height: 2.5rem;
      width: 2.5rem;
    }`}
  }
`;

export const ListWrapper = styled.div`
  padding: 0 1rem;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
`;

export const Title = styled(DefaultTypography)`
  && {
    font-family: 'JetBrains Mono', serif;
    font-size: 1.5rem;
    font-weight: ${({ isSelected }: P) => (isSelected ? `700` : `500`)};
    letter-spacing: -0.48px;
    text-transform: uppercase;

    margin-bottom: 1rem;

    color: ${({ isSelected }: P) =>
      isSelected ? `#3451FA` : `#272d33`} !important;
  }
`;

export const Description = styled(DefaultTypography)`
  && {
    color: #5b656e;
    font-family: 'JetBrains Mono', serif;
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: -0.32px;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;

export function shouldOpenManualPicker(item: LoadingStrategyOption) {
  return item.value.type === RecommendationType.MANUAL;
}

export function shouldOpenAdvancedRuling(item: LoadingStrategyOption) {
  return item.value.type === RecommendationType.ADVANCED_RULING;
}

export function shouldShowCond(item: LoadingStrategyOption) {
  return item.value.type !== RecommendationType.ADVANCED_RULING;
}

function getInitialState(bp: CatalogWidgetProps) {
  return JSON.parse(
    JSON.stringify(
      bp?.settings?.loading?.loadingEnv?.recommendationOptions?.condition || [],
    ),
  );
}
export { ProductSearchInner } from '@/features/editor/widgets/custom-widget/loading-section/shared/product-search';
