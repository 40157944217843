import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { SelectOptions, SmallSelect } from '@/components/small-select';
import { Flexbox } from '@/components/flex';
import {
  CustomOption,
  OrButton,
  QBCaption,
  QBColon,
  QBRemove,
  QBVSpace,
  shouldShowOrCondition,
  shouldShowUrlParamsCheckbox,
  UrlParamsCheckbox,
} from '@/components/query-builder/shared';
import { QBContext } from '@/components/query-builder/context';
import { getGUID } from '@/utils/browser';
import { QBTimeframeSuffix } from '@/components/query-builder/timeframe-suffix';
import { hash } from '@/utils/cache';
import { QBTextInputWrapper } from '@/components/query-builder/text-value';

export enum MultiValueOps {
  IS = `is`,
  ISNOT = `isnot`,
}

export const multiValueOptions: SelectOptions[] = [
  {
    key: MultiValueOps.IS,
    value: `Is`,
  },
  {
    key: MultiValueOps.ISNOT,
    value: `Is not`,
  },
];

export const multiValueOptionsWithContains: SelectOptions[] = [
  {
    key: MultiValueOps.IS,
    value: `Is`,
  },
  {
    key: MultiValueOps.ISNOT,
    value: `Is not`,
  },
  {
    key: `includes`,
    value: `Contains`,
  },
  {
    key: `notincludes`,
    value: `Not contains`,
  },
];

export function QBMultiValue({
  idx,
  selectMaxWidth,
}: {
  idx: number;
  selectMaxWidth?: number;
}) {
  const {
    queryState,
    appendValue,
    updateValue,
    removeValue,
    removeKey,
    setIncludeQueryParams,
    updateTimeframe,
  } = useContext(QBContext);

  const current = queryState[idx];

  const onOpChanged = (childIdx, selected) => {
    updateValue(idx, childIdx, selected, current?.values?.[childIdx]?.value);
  };

  const onOptionChanged = (childIdx, selected) => {
    updateValue(idx, childIdx, current?.values?.[childIdx]?.op, selected);
  };

  const onAddMore = () => {
    appendValue(
      idx,
      MultiValueOps.IS,
      current?.qbProps?.options?.[0]?.key as string,
    );
  };

  const onRemove = (childIdx: number) => {
    if (current.values.length === 1) {
      removeKey(idx);
    } else {
      removeValue(idx, childIdx);
    }
  };
  const [valueObj, setValueObj] = useState([
    current?.qbProps?.options?.find(
      (o) => o.key === current?.values?.[0]?.value,
    ),
  ]);
  useEffect(() => {
    const result = [];
    current.values.forEach((v, i) => {
      result[i] = current?.qbProps?.options?.find((o) => o.key === v?.value);
    });
    setValueObj(result);
  }, [current?.values]);

  const tooltipRef = useRef(null);
  const [isShowTooltip, setIsShowTooltip] = useState(true);
  const showUrlParamsCheckbox = shouldShowUrlParamsCheckbox(current);
  return (
    <Wrapper>
      {current?.values?.map((_, childIdx) => (
        <React.Fragment key={`multivalue-${childIdx}`}>
          <Row>
            {childIdx > 0 && <LogicalOr>Or</LogicalOr>}
            {!current?.qbProps?.disableOrCond && (
              <SmallSelect
                minWidth={70}
                options={
                  current?.qbProps?.alsoApplyOnQueryParams
                    ? multiValueOptionsWithContains
                    : multiValueOptions
                }
                onSelection={(selected) => onOpChanged(childIdx, selected)}
                defaultValue={current?.values?.[childIdx]?.op}
              />
            )}
            <QBColon />

            <div
              data-tip={valueObj[childIdx]?.value?.toUpperCase() || ``}
              data-for={`select-${hash(
                valueObj[childIdx]?.value?.toUpperCase() || ``,
              )}-${childIdx}-${idx}`}
            >
              {showUrlParamsCheckbox &&
              current?.values?.[childIdx]?.op?.includes(`includes`) ? (
                <QBTextInputWrapper
                  idx={idx}
                  current={current}
                  childIdx={childIdx}
                  onMouseEnter={() => setIsShowTooltip(true)}
                  onChange={(ev) => {
                    onOptionChanged(childIdx, ev.target.value);
                  }}
                />
              ) : (
                <SmallSelect
                  key={getGUID()}
                  components={
                    current?.qbProps?.options?.find((o) => !!o.image) && {
                      Option: CustomOption(
                        current.qbProps.options,
                        current?.values?.[childIdx]?.value,
                      ),
                    }
                  }
                  onMenuOpen={() => {
                    if (tooltipRef?.current?.hidden)
                      tooltipRef.current.hidden = true;
                  }}
                  onMenuClose={() => {
                    if (tooltipRef?.current?.hidden)
                      tooltipRef.current.hidden = false;
                    setIsShowTooltip(false);
                    setTimeout(() => {
                      setIsShowTooltip(true);
                    }, 100);
                  }}
                  addOption={current.qbProps.canCreateOption}
                  options={current.qbProps.options}
                  onSelection={(selected) =>
                    onOptionChanged(childIdx, selected)
                  }
                  defaultValue={current?.values?.[childIdx]?.value}
                  maxWidth={selectMaxWidth}
                />
              )}
            </div>

            <Tooltip
              ref={tooltipRef}
              showTooltip={isShowTooltip}
              selectOptions={valueObj}
              childIdx={childIdx}
              idx={idx}
            />
            <QBRemove
              shouldHide={
                queryState?.length <= 1 && current?.values?.length === 1
              }
              onClick={() => onRemove(childIdx)}
            />
          </Row>
          <QBVSpace />
        </React.Fragment>
      ))}
      <UrlParamsCheckbox
        current={current}
        onSelection={(bool) => setIncludeQueryParams(idx, bool)}
      />

      {queryState[idx].qbProps.hasTimeframe && (
        <QBTimeframeSuffix
          onChange={(v) => updateTimeframe(idx, v)}
          initialValue={queryState[idx].timeframe}
        />
      )}
      {shouldShowOrCondition(current) && (
        <OrButton onClick={onAddMore}>|| Or</OrButton>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(Flexbox)`
  && {
    flex-direction: column;
  }
`;

const Row = styled(Flexbox)`
  && {
    position: relative;
    flex-direction: row;
  }
`;

const LogicalOr = styled(QBCaption)`
  && {
    position: absolute;
    top: calc(50% - 1rem);
    left: -4rem;
  }
`;

function Tooltip({
  childIdx,
  idx,
  ref,
  selectOptions,
  showTooltip,
}: {
  ref: React.MutableRefObject<null>;
  showTooltip: boolean;
  selectOptions: SelectOptions[];
  childIdx: number;
  idx: number;
}) {
  return (
    <div ref={ref}>
      {showTooltip &&
        (selectOptions[childIdx]?.value?.length >= 24 ||
          selectOptions[childIdx]?.image) && (
          <ReactTooltip
            id={`select-${hash(
              selectOptions[childIdx]?.value?.toUpperCase() || ``,
            )}-${childIdx}-${idx}`}
            effect="float"
            place="top"
            backgroundColor="#fff"
            textColor="#000"
            className="text-tooltip"
          >
            {selectOptions[childIdx]?.image && (
              <Image
                src={selectOptions[childIdx]?.image || ``}
                alt={selectOptions[childIdx]?.value?.toUpperCase() || ``}
              />
            )}
            <span>{selectOptions[childIdx]?.value?.toUpperCase() || ``}</span>
          </ReactTooltip>
        )}
    </div>
  );
}

const Image = styled.img`
  object-fit: cover;
  height: 4rem;
  width: 4rem;
`;
