import React from 'react';
import styled from 'styled-components';

export interface StateToggleButtonProps {
  isHover?: boolean;
  onClick?: (e) => void;
}

export function StateToggleButton({
  isHover,
  onClick,
}: StateToggleButtonProps) {
  return (
    <Wrapper onClick={onClick}>
      <Option selected={!isHover}>Regular</Option>
      <Option selected={isHover}>Hover</Option>
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border-radius: 3rem;
  padding: 0.5rem 0;
  font-family: 'JetBrains Mono', serif;
  font-size: 1rem;
  font-weight: 600;
  background-color: #f3f5f7;
  user-select: none;
  cursor: pointer;
  appearance: none;
`;

const Option = styled.span`
  padding: 0.5rem 1.8rem;
  text-transform: uppercase;
  color: ${(props: { selected: boolean }) =>
    props.selected ? `#000000` : `#a6afb8`};
  border: ${(props: { selected: boolean }) =>
    props.selected ? `1px solid rgba(213,217,220,0.34)` : `none`};
  border-radius: 3rem;
  background-color: ${(props: { selected: boolean }) =>
    props.selected ? `#ffffff` : `transparent`};
  box-shadow: ${(props: { selected: boolean }) =>
    props.selected
      ? `0 1px 1px 0 rgba(0,0,0,0.15), 0 12px 14px 0 rgba(151,210,206,0.22), 0 4px 14px 0 rgba(0,0,0,0)`
      : `none`};
`;
