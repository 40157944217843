import * as React from 'react';
import styled from 'styled-components';
import { useContext } from 'react';
import { centered } from '@/components/use-shared-element';
import { Footer } from '@/features/editor/widgets/shared/modals/commons';
import { GradientModal } from '@/components/gradient-modal';
import { useShopifyApi } from '@/webapi/use-shopify-api';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';

const fromStyle = {
  borderRadius: `5rem`,
  backgroundColor: `#DEDEDE`,
  padding: `2rem 0 0 0`,
};
const toStyle = {
  borderRadius: `1rem`,
  backgroundColor: `#FFFFFF`,
  padding: `2rem 0 0 0`,
};

export const EnablePostPurchaseModal: React.FC<any> = ({ fromRef }) => {
  const { isModalVisible, setIsModalVisible } = useContext(PostPurchaseContext);
  const { getPostPurchaseStatus, loading } = useShopifyApi();
  const checkStatus = async () => {
    const isEnabled = await getPostPurchaseStatus(true);
    if (isEnabled) {
      setIsModalVisible(false);
    }
  };

  return (
    <GradientModal
      closeTop="0.5rem"
      closeRight="2.5rem"
      fromStyle={fromStyle}
      toStyle={toStyle}
      isVisible={isModalVisible}
      showBackdrop
      targetPosAndSize={centered(73, 85)}
      fromRef={fromRef}
      overflowTop={{ heightInRem: 8 }}
      overflowBottom={{ heightInRem: 8, turnPointInPercent: 30 }}
      onClose={() => setIsModalVisible(false)}
      header={
        <span style={{ fontSize: `2rem`, color: `#475562` }}>
          Let׳s define Visually as your
          <br /> post purchase upsell on Shopify
        </span>
      }
      footer={
        <Footer
          isDisabled={loading}
          onClick={checkStatus}
          text="Check Status"
        />
      }
    >
      <ContentWrapper>
        <span>
          To ensure that Visually operates optimally, it must be properly set up
          as a post-purchase page app on <br />
          your Shopify store. Unfortunately, it appears that this has not been
          done yet. Please follow the <br />
          instructions provided to properly configure Visually as a
          post-purchase page app
        </span>
      </ContentWrapper>
      <Step>
        <span>1</span> Go to Shopify admin
      </Step>
      <Step>
        <span>2</span> Go to Setting {`>`} Checkout {`>`} Post purchase page
      </Step>
      <Step>
        <span>3</span> Go to Setting {`>`} Checkout {`>`} Post purchase page
      </Step>
    </GradientModal>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6rem 0 4rem 0;
  font-size: 2rem;
  line-height: 3rem;
  font-family: Inter, serif;

  span:first-child {
    font-size: 1.5rem;
    text-align: center;
    color: #8296a4;
    font-weight: 500;
  }
`;

const Step = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  background-color: #f4f4f4;
  padding: 3rem 3rem;
  margin: 2rem 3rem;
  border-radius: 1.5rem;
  width: 95%;
  color: #96989b;
  font-size: 1.4rem;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 2rem 0 0;
    width: 3rem;
    height: 3rem;

    border-radius: 50%;
    background-color: #dbdfe2;
    color: #8c8f92;
    font-family: 'JetBrains Mono', serif;
    font-size: 1.4rem;
    font-weight: 600;
  }
`;
