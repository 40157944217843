import * as React from 'react';
import { useContext, useEffect } from 'react';
import { CheckoutExtensibilityContext } from '@/features/editor/widgets/checkout-extensibility/context';
import {
  CatalogCheckoutApp,
  CatalogCheckoutWidget,
} from '@/webapi/checkout-widget-props';
import { CatalogAppTile } from '@/features/editor/widgets/shared/apps-catalog/app-tile';
import { CatalogAppsGrid } from '@/features/editor/widgets/shared/apps-catalog/apps-list';
import { EditorContext } from '@/features/editor/context/editor-context';
import { CheckoutWidgetChange } from '@/pkg/sdk';

export interface CheckoutInspectorWidgetListProps {
  app: CatalogCheckoutApp;
}

export function CheckoutInspectorWidgetList({
  app,
}: CheckoutInspectorWidgetListProps) {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);

  const { moveToWidgetStyle, newChange, setNewChange } = useContext(
    CheckoutExtensibilityContext,
  );

  function setChange(widget: CatalogCheckoutWidget) {
    setNewChange((draft) => {
      draft.checkoutWidgetManifest = widget;
      draft.checkoutMountPointInfo = newChange.checkoutMountPointInfo;
      const value = draft.block.value as CheckoutWidgetChange;
      value.props = widget.initialState;
      value.mountPoint = newChange.checkoutMountPointInfo.mountPointSelector;
      value.op = newChange.checkoutMountPointInfo.placement;
      value.widgetId = widget.manifest.id;
      value.version = widget.manifest.version;
    });
  }

  function resetChange() {
    setNewChange((draft) => {
      draft.checkoutWidgetManifest = null;
      draft.checkoutMountPointInfo = newChange.checkoutMountPointInfo;
      const value = draft.block.value as CheckoutWidgetChange;
      value.props = null;
      value.mountPoint = newChange.checkoutMountPointInfo.mountPointSelector;
      value.op = newChange.checkoutMountPointInfo.placement;
      value.widgetId = null;
      value.version = null;
    });
  }

  useEffect(() => {
    resetChange();
  }, []);

  const onSelection = (widget: CatalogCheckoutWidget) => {
    setChange(widget);
    moveToWidgetStyle(app, widget);
  };

  const onPreview = (widget: CatalogCheckoutWidget) => {
    setChange(widget);
  };

  const onCancelPreview = () => {
    resetChange();
  };

  return (
    <CatalogAppsGrid device={device}>
      {app.checkoutWidgets.map((widget) => (
        <CatalogAppTile
          key={widget?.manifest?.id}
          app={widget.manifest}
          onClick={() => onSelection(widget)}
          onMouseEnter={() => onPreview(widget)}
          onMouseLeave={onCancelPreview}
          isDisabled={false}
          disabledText=""
        />
      ))}
    </CatalogAppsGrid>
  );
}
