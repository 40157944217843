import * as React from 'react';
import { useContext } from 'react';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';
import { DropdownInputOption } from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { RadioButtons } from '@/features/editor/widgets/custom-widget/inputs/shared/radio-buttons';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';

export function PostPurchaseDiscountInput() {
  const { props, setProps } = useContext(PostPurchaseContext);
  const allowCustomEdit = useFeatureBit(
    FeatureBit.ENABLE_POST_PURCHASE_EXPERIMENTAL_OPTIONS,
  );
  const options: DropdownInputOption[] = [
    { label: `0%`, value: `0` },
    { label: `10%`, value: `0.1` },
    { label: `20%`, value: `0.2` },
    { label: `30%`, value: `0.3` },
    { label: `33%`, value: `0.333333333333333` },
    { label: `40%`, value: `0.4` },
    { label: `50%`, value: `0.5` },
  ];

  const onChange = (offerIdx: number) => (val: string) => {
    setProps((draft) => {
      draft.offers = draft.offers.map((offer, idx) => {
        if (offerIdx === idx) {
          offer.discountPercentage = parseFloat(val);
        }
        return offer;
      });
    });
  };

  return (
    <>
      <RadioButtons
        title="Apply a discount for the first offer"
        defaultValue={`${props?.offers?.[0]?.discountPercentage}`}
        onChange={onChange(0)}
        options={options}
        allowCustomEdit={allowCustomEdit}
        customEditHint="* decimal value between 0-1, e.g. 0.5 for 50% off"
      />
      {props?.settings?.offersCount > 1 && (
        <RadioButtons
          title="Apply a discount for 2nd first offer"
          defaultValue={`${props?.offers?.[1]?.discountPercentage}`}
          onChange={onChange(1)}
          options={options}
          allowCustomEdit={allowCustomEdit}
          customEditHint="* decimal value between 0-1, e.g. 0.5 for 50% off"
        />
      )}
    </>
  );
}
