import * as React from 'react';
import { useContext } from 'react';
import { StylingComponentsGroup } from '@/features/editor/widgets/post-purchase/styling/group';
import { PostPurchaseTextualInput } from '@/features/editor/widgets/post-purchase/inputs/textual';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';

export function PostPurchaseSummaryStyles() {
  const { props, setProps } = useContext(PostPurchaseContext);

  return (
    <>
      <StylingComponentsGroup title="Prompt Style">
        <PostPurchaseTextualInput
          defaultValue={props.style.summary.promptStyle}
          onChange={(p) =>
            setProps((draft) => {
              draft.style.summary.promptStyle = p;
            })
          }
        />
      </StylingComponentsGroup>

      <StylingComponentsGroup title="Line Item Style">
        <PostPurchaseTextualInput
          defaultValue={props.style.summary.lineItemsStyle}
          onChange={(p) =>
            setProps((draft) => {
              draft.style.summary.lineItemsStyle = p;
            })
          }
        />
      </StylingComponentsGroup>

      <StylingComponentsGroup title="Total Style">
        <PostPurchaseTextualInput
          defaultValue={props.style.summary.totalItemsStyle}
          onChange={(p) =>
            setProps((draft) => {
              draft.style.summary.totalItemsStyle = p;
            })
          }
        />
      </StylingComponentsGroup>
    </>
  );
}
