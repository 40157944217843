/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { IoClose } from 'react-icons/io5';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { centered, useSharedElement } from '@/components/use-shared-element';
import {
  SharedElementOverlay,
  SharedElementOverlayProps,
} from '@/components/shared-element-overlay';
import { Slots } from './Slots';
import { ManualProductsPicker } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/context';
import { ProductSearch } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/search';
import { ImagePicker } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/image-picker';
import { Product } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/context/model';
import { RecommendationOptions } from '@/webapi/use-widget-catalog-api';

interface ProductsPickerModal extends SharedElementOverlayProps {
  hide: () => void;
  updateRecommendationOptions: (options: RecommendationOptions) => void;
}

export function ManualProductPicker(props: ProductsPickerModal) {
  const { hide, updateRecommendationOptions, ...rest } = props;
  const {
    openSlot,
    setOpenSlot,
    productWithoutImage,
    unsetProductWithoutImage,
  } = useContext(ManualProductsPicker);

  const onBack = () => {
    setOpenSlot(undefined);
    unsetProductWithoutImage();
  };
  return (
    <SharedElementOverlay {...rest}>
      <Content>
        <Title>
          {openSlot ? (
            <BackBtn type="button" onClick={onBack}>
              <MdOutlineArrowBackIosNew color="#5B656E" size={35} />
            </BackBtn>
          ) : (
            <div />
          )}
          <TitleComp productWithoutImage={productWithoutImage} />
          <CloseBtn type="button" onClick={hide}>
            <IoClose size={35} color="#5B656E" />
          </CloseBtn>
        </Title>
        {openSlot ? (
          productWithoutImage ? (
            <ImagePicker />
          ) : (
            <ProductSearch />
          )
        ) : (
          <Slots hide={hide} updateSelection={updateRecommendationOptions} />
        )}
      </Content>
    </SharedElementOverlay>
  );
}

const BackBtn = styled.button`
  border: none;
  background: none;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  &:active {
    cursor: default;
    opacity: 1;
  }
`;

const Title = styled.div`
  z-index: 1000;
  position: absolute;
  top: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  justify-items: center;
  align-items: center;
  margin-top: 2rem;

  p {
    user-select: none;
    text-align: center;
    font-weight: 600;
    font-size: 2.2rem;
  }
`;

const CloseBtn = styled.button`
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  &:active {
    cursor: default;
    opacity: 1;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  height: 100%;
`;

export function useManualProductsModalProps() {
  const { props, show, hide, fromRef } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `#dedede`,
        opacity: 0,
      },
      extraTo: {
        background: `white`,
        opacity: 1,
      },
    },
    undefined,
    () => centered(75, 100),
  );
  return {
    props,
    show,
    hide,
    fromRef,
  };
}

function TitleComp({ productWithoutImage }: { productWithoutImage: Product }) {
  return (
    <p>
      {productWithoutImage ? (
        <span>
          Select the{` `}
          <strong style={{ textDecoration: `underline` }}>
            {productWithoutImage.mainValue}
          </strong>
          {` `}
          image
        </span>
      ) : (
        `Choose Specific Products`
      )}
    </p>
  );
}
