import React, { useContext } from 'react';
import styled from 'styled-components';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';
import { breakpoints } from '@/components/responsive';

export const INSPECTOR_WRAPPER_ID = `inspector-wrapper`;
export const INSPECTOR_CONTROLS_ID = `inspector-controls`;
export const INSPECTOR_BACK_ID = `inspector-back`;

export function Inspector() {
  const { devicePreview, inspectorNav, inspectorRef, resetWidget } =
    useContext(EditorContext);
  const { device } = devicePreview.editorState;
  const { getCurrentWidget } = inspectorNav;

  return (
    <Wrapper
      key={`widget-${resetWidget}`}
      id={INSPECTOR_WRAPPER_ID}
      device={device}
      ref={inspectorRef}
    >
      {getCurrentWidget()}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  max-width: ${(props: { device: DeviceType; previewLoading: boolean }) =>
    props.device === DeviceType.Desktop ? `100%` : `88rem`};
  overflow-x: hidden;
  overflow-y: hidden;
  padding: ${(props: { device: DeviceType; previewLoading: boolean }) =>
    props.device === DeviceType.Desktop ? `0 3.5rem` : `0 6.5rem`};

  ${breakpoints.down(1400)} {
    padding: ${(props: { device: DeviceType; previewLoading: boolean }) =>
      props.device === DeviceType.Desktop ? `0 0 0 1.2rem` : `0 6.5rem`};
  }
`;
