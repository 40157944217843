import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { SelectOptions, SmallSelect } from '@/components/small-select';
import {
  OrButton,
  QBCaption,
  QBRemove,
  QBColon,
  QBTextInput,
  QBVSpace,
  shouldShowOrCondition,
  UrlParamsCheckbox,
} from '@/components/query-builder/shared';
import { QBContext } from '@/components/query-builder/context';
import { MultiValueOps } from '@/components/query-builder/multi-value';
import { Flexbox } from '@/components/flex';
import { QBTimeframeSuffix } from '@/components/query-builder/timeframe-suffix';
import { QBItemSelection } from '@/components/query-builder/models';
import { hash } from '@/utils/cache';

export enum TextValueOps {
  IS = `is`,
  ISNOT = `isnot`,
  INCLUDES = `includes`,
  NOT_INCLUDES = `notincludes`,
  REGEX = `regex`,
}

export const textValueOptions: SelectOptions[] = [
  {
    key: TextValueOps.IS,
    value: `Is`,
  },
  {
    key: TextValueOps.ISNOT,
    value: `Is not`,
  },
  {
    key: TextValueOps.INCLUDES,
    value: `Includes`,
  },
  {
    key: TextValueOps.NOT_INCLUDES,
    value: `Not includes`,
  },
  {
    key: TextValueOps.REGEX,
    value: `Matches`,
  },
];

export function QBTextInputWrapper({
  childIdx,
  current: { values },
  onChange,
  onMouseEnter,
  idx,
}: {
  current: QBItemSelection;
  childIdx: number;
  onChange: (ev) => void;
  onMouseEnter: () => void;
  idx: number;
}) {
  const ref = useRef<HTMLInputElement>();
  useEffect(() => {
    setTimeout(() => {
      const end = ref.current?.value?.length;
      if (typeof end === `undefined`) return;
      ref.current.setSelectionRange(end, end);
      ref.current.focus();
    }, 1000);
  }, []);
  return (
    <QBTextInput
      onMouseEnter={onMouseEnter}
      data-tip={values?.[childIdx]?.value || ``}
      data-for={`text-${hash(
        values?.[childIdx]?.value || ``,
      )}-${childIdx}-${idx}`}
      ref={ref}
      value={values?.[childIdx]?.value}
      onChange={onChange}
    />
  );
}

export function QBTextValue({
  idx,
  textCondNoMarginTop,
}: {
  idx: number;
  textCondNoMarginTop?: boolean;
}) {
  const {
    appendValue,
    updateValue,
    queryState,
    setIncludeQueryParams,
    removeValue,
    removeKey,
    updateTimeframe,
  } = useContext(QBContext);
  const current = queryState[idx];

  const onOpChanged = (childIdx, selected) => {
    updateValue(idx, childIdx, selected, current?.values?.[childIdx]?.value);
  };

  const onValueChanged = (childIdx, ev) => {
    updateValue(
      idx,
      childIdx,
      current?.values?.[childIdx]?.op,
      ev.target.value,
    );
  };

  const onAddMore = () => {
    appendValue(
      idx,
      current?.qbProps?.defaultTextValueOp || MultiValueOps.IS,
      ``,
    );
  };

  function isOpDisabled(queryState: QBItemSelection[], idx: number) {
    return !!queryState?.[idx]?.qbProps?.disableOp;
  }
  const onRemove = (childIdx: number) => {
    if (current.values.length === 1) {
      removeKey(idx);
    } else {
      removeValue(idx, childIdx);
    }
  };
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  return (
    <Wrapper>
      {current?.values?.map((_, childIdx) => (
        <Row
          key={`text-value-${childIdx}`}
          textCondNoMarginTop={textCondNoMarginTop || childIdx === 0}
        >
          {childIdx > 0 && <LogicalOr>Or</LogicalOr>}
          <SmallSelect
            minWidth={70}
            isDisabled={isOpDisabled(queryState, idx)}
            options={textValueOptions}
            defaultValue={
              current?.values?.[childIdx]?.op ||
              current?.qbProps?.defaultTextValueOp
            }
            onSelection={(selected) => onOpChanged(childIdx, selected)}
          />
          <QBColon />
          <QBTextInputWrapper
            idx={idx}
            current={current}
            childIdx={childIdx}
            onMouseEnter={() => setIsShowTooltip(true)}
            onChange={(ev) => onValueChanged(childIdx, ev)}
          />
          <div>
            {current?.values[childIdx].value &&
              current?.values[childIdx].value.length > 23 && (
                <ReactTooltip
                  id={`text-${hash(
                    current?.values[childIdx].value || ``,
                  )}-${childIdx}-${idx}`}
                  effect="float"
                  place="top"
                  disable={!isShowTooltip}
                  backgroundColor="#fff"
                  textColor="#000"
                  className="text-tooltip"
                >
                  <span>{current?.values[childIdx].value || ``}</span>
                </ReactTooltip>
              )}
          </div>
          <QBRemove
            shouldHide={
              queryState?.length <= 1 && current?.values?.length === 1
            }
            onClick={() => onRemove(childIdx)}
          />
        </Row>
      ))}
      <UrlParamsCheckbox
        mt="1rem"
        current={current}
        onSelection={(bool) => setIncludeQueryParams(idx, bool)}
      />
      <QBVSpace />
      {queryState[idx].qbProps.hasTimeframe && (
        <QBTimeframeSuffix
          onChange={(v) => updateTimeframe(idx, v)}
          initialValue={queryState[idx].timeframe}
        />
      )}
      {shouldShowOrCondition(current) && (
        <OrButton onClick={onAddMore}>|| Or</OrButton>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(Flexbox)`
  && {
    flex-direction: column;
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  margin-top: ${(p: { textCondNoMarginTop?: boolean }) =>
    p.textCondNoMarginTop ? 0 : `1.5rem`};
`;

const LogicalOr = styled(QBCaption)`
  && {
    position: absolute;
    top: calc(50% - 1rem);
    left: -3rem;
  }
`;
