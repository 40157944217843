/* eslint-disable eqeqeq */
import React, { useContext, useMemo } from 'react';
import { EditorContext } from '@/features/editor/context/editor-context';
import { TargetingButton } from '@/features/editor/widgets/shared/targeting-controls/targeting-button';
import { ThemesModal } from '@/features/editor/widgets/shared/modals/audience/themes-modal';
import { Theme } from '@/webapi/models';
import {
  AccountContext,
  AccountContextProps,
  useFeatureBit,
} from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import { Experience, PlacementKind } from '@/webapi/use-experience-api';
import { useThemesModal } from '@/features/editor/widgets/shared/targeting-controls/use-themes-modal';

export function useThemesButton() {
  const ctx = useContext(AccountContext);
  const isEnabled =
    useFeatureBit(FeatureBit.SHOPIFY_THEMES) &&
    useFeatureBit(FeatureBit.APP_EMBED_REVERT) &&
    !useFeatureBit(FeatureBit.SPA_INTEGRATION);

  const {
    resources: { themes, enabledOnMultipleThemes },
    experienceState: { currentExperience },
  } = useContext(EditorContext);

  const isThemesDisabled = useMemo(
    () =>
      !enabledOnMultipleThemes ||
      isEmpty(themes) ||
      !isEnabled ||
      isHydrogen(ctx) ||
      isCheckoutPage(currentExperience),
    [
      themes,
      currentExperience,
      isEnabled,
      enabledOnMultipleThemes,
      ctx?.account?.store?.alias,
    ],
  );

  return { isThemesDisabled };
}

export function ThemesButton({ isDisabled }: { isDisabled?: boolean }) {
  const { themes, fromRef, activeTheme, setIsVisible, onClick, isVisible } =
    useThemesModal();
  if (isEmpty(themes)) {
    return null;
  }

  const { isThemesDisabled } = useThemesButton();

  if (isThemesDisabled) {
    return null;
  }

  return (
    <>
      <ThemesModal
        fromRef={fromRef}
        isVisible={isVisible}
        themes={themes}
        setIsVisible={setIsVisible}
      />
      <TargetingButton
        wrapperRef={fromRef}
        title="THEME"
        subtitle={activeTheme}
        onClick={onClick}
        isDisabled={isDisabled}
      />
    </>
  );
}

function isEmpty(themes: Array<Theme>) {
  return (themes || []).length == 0;
}

export function isHydrogen(ctx: AccountContextProps) {
  return (
    ctx?.account?.store?.alias === `KARMA` ||
    ctx?.account?.store?.alias === `UNDEROUTFIT`
  );
}

function isCheckoutPage(x: Experience) {
  return x?.targeting?.placement?.kind === PlacementKind.CHECKOUT;
}
