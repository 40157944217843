import { DeviceType } from '@/utils/definitions';
import {
  Component,
  Customization,
  CustomizationSpec,
} from '@/webapi/use-widget-catalog-api';

export enum InputType {
  UNKNOWN = `UNKNOWN`,
  SINGLE_SELECT = `SELECT`,
  TEXT_EDIT = `TEXT_EDIT`,
  DATE_PICKER = `DATE_PICKER`,
  TYPOGRAPHY = `TYPOGRAPHY`,
  PADDING = `PADDING`,
  MARGIN = `MARGIN`,
  BOX_SHADOW = `BOX_SHADOW`,
  TEXT_SHADOW = `TEXT_SHADOW`,
  BORDER = `BORDER`,
  SIZE = `SIZE`,
  SINGLE_UNIT = `SINGLE_UNIT`,
  BACKGROUND = `BACKGROUND`,
  LINK = `LINK`,
  CTA = `CTA`,
  ASPECT_RATIO = `ASPECT_RATIO`,
  COLOR = `COLOR`,
  SWITCH = `SWITCH`,
  JS_EDIT = `JS_EDIT`,
  RADIO_BUTTONS = `RADIO_BUTTONS`,
}

export interface InputTheme {
  background: string;
  foreground: string;
  boxShadow: string;
  containerPadding: string;
  textAlign: 'left' | 'center' | 'right';

  menuBackground?: string;
}

export const InputsMinimalTheme: InputTheme = {
  background: `rgba(255,255,255,0)`,
  foreground: `#4B5564`,
  boxShadow: `none`,
  containerPadding: `0.2rem 0.8rem`,
  menuBackground: `#FFFFFF`,
  textAlign: `right`,
};

export const InputsLightTheme: InputTheme = {
  background: `#FFFFFF`,
  foreground: `#4B5564`,
  boxShadow: `0 9px 13px 0 rgba(177,217,203,0.18)`,
  containerPadding: `0.2rem 0.2rem`,
  textAlign: `left`,
};

export const InputsDarkTheme: InputTheme = {
  background: `#F3F6F7`,
  foreground: `#4B5564`,
  boxShadow: `none`,
  containerPadding: `0.7rem 1.5rem`,
  textAlign: `left`,
};

export interface HistoryOpts {
  ignoreHistory?: boolean;
  forceSameVersion?: boolean;
}
export interface GenericInputProps {
  customizationIdx: number;
  customization: Customization;
  componentIdx: number;
  component: Component;
  specIdx: number;
  spec: CustomizationSpec;
  device: DeviceType;
  showInputName?: boolean | undefined;
  initialValues: (key?: string, device?: DeviceType, specIdx?: number) => any;
  onValuesChanged: (
    key: string,
    value: any,
    device?: DeviceType,
    historyOpts?: HistoryOpts,
  ) => void;
  hover?: boolean;
}
