import { MdArrowBackIos } from 'react-icons/md';
import * as React from 'react';
import styled from 'styled-components';
import { BigButton } from '@/components/big-button';

export const BackModalHeader = ({ header, back, noSeparator }: any) => (
  <CustomQueryHeaderWrapper noSeparator={noSeparator}>
    <button type="button" className="left" onClick={back}>
      <MdArrowBackIos size={24} color="#B8BEBF" />
    </button>
    <span>{header}</span>
    <span className={noSeparator ? `` : `sep`} />
  </CustomQueryHeaderWrapper>
);

export const CustomQueryHeaderWrapper = styled.div`
  user-select: none;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  align-items: center;
  justify-content: center;
  justify-items: center;
  grid-gap: 2.2rem;
  margin-top: -2.2rem;

  button {
    position: absolute;
    top: 0;
    left: 3rem;
    outline: none;
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
    :active {
      opacity: 0.6;
    }
  }

  .sep {
    width: 94%;
    height: 2px;
    background: #979797;
    opacity: 0.18;
  }
`;

interface FooterProps {
  onClick: () => void;
  text?: string;
  isDisabled?: boolean;
  bg?: string;
  size?: string;
}

export const Footer = ({
  onClick,
  text,
  isDisabled,
  bg,
  size,
}: FooterProps) => (
  <FooterButton
    disabled={!!isDisabled}
    color="white"
    background={bg || `#0062FF`}
    noTransform
    size={size || `large`}
    onClick={onClick}
  >
    {text || `Select`}
  </FooterButton>
);

const FooterButton = styled(BigButton)`
  && {
    width: 35rem !important;
    font-size: 1.3rem;
  }
`;
