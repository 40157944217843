// @ts-ignore
import {
  CatalogWidgetProps,
  CustomizationSpec,
} from '@/webapi/use-widget-catalog-api';
import { handleVisualSpec } from '@/features/editor/widgets/visual-editor/visual-edit-spec-transpiler';
import { VisualEditChangeBuilder } from '@/features/editor/widgets/visual-editor/visual-edit-change-builder';
import { deepEqual } from '@/utils/types';
import {
  EditorChangeKind,
  EditorDeclarativeBlock,
} from '@/webapi/use-experience-api';
import { VisualEditChange } from '@/pkg/sdk';
import { ExperienceCreationVersion } from '@/webapi/experience-creation-version';

export function transpileVisualEditBlock(
  editorId: string,
  selector: string,
  updatedSpecs: Record<string, CustomizationSpec>,
  initSpecs: Record<string, CustomizationSpec>,
  version: ExperienceCreationVersion,
): EditorDeclarativeBlock {
  const visualChange = getEffectiveVisualEditChange(
    updatedSpecs,
    initSpecs,
    version,
  );

  return {
    editorId,
    editorKind: EditorChangeKind.VISUAL_EDIT,
    editorSelector: selector,
    block: {
      // @ts-ignore
      kind: `visualEdit`,
      selector,
      value: visualChange,
    },
  };
}

export function getEffectiveVisualEditChange(
  updatedSpecs: Record<string, CustomizationSpec>,
  initSpecs: Record<string, CustomizationSpec>,
  version: ExperienceCreationVersion,
): VisualEditChange {
  const before = visualSpecsToVisualEditChange(initSpecs, version);
  const after = visualSpecsToVisualEditChange(updatedSpecs, version);

  if (deepEqual(before, after)) {
    return { changes: [] };
  }

  const changes = after.changes
    .map((afterChange) => {
      const beforeChange = before.changes.find(
        (ch) => ch.selector === afterChange.selector,
      );
      if (!beforeChange) return afterChange;

      const subChanges = afterChange.subChanges
        .map((afterSubChange) => {
          const beforeSubChange = beforeChange.subChanges.find((ch) =>
            deepEqual(ch, afterSubChange),
          );
          return beforeSubChange ? undefined : afterSubChange;
        })
        .filter((ch) => !!ch);

      return { selector: afterChange.selector, subChanges, version };
    })
    .filter((ch) => !!ch);

  return {
    changes,
  };
}

function visualSpecsToVisualEditChange(
  specs: Record<string, CustomizationSpec>,
  version: ExperienceCreationVersion,
): VisualEditChange {
  const builder = new VisualEditChangeBuilder(version);
  Object.entries(specs).forEach(([_, spec]) => {
    handleVisualSpec(spec.key, spec, builder);
  });
  return builder.asVisualEditChange();
}

export function visualPropsToSpecs(
  visualProps: CatalogWidgetProps,
): Record<string, CustomizationSpec> {
  const state = {};
  visualProps.customizations.forEach((cust, custId) =>
    cust.components.forEach((comp, compId) =>
      comp.specs.forEach((spec, specId) => {
        state[formatKey(custId, compId, specId)] = spec;
      }),
    ),
  );
  return state;
}

export const formatKey = (
  custId: number,
  compId: number,
  specId: number,
): string => `${custId}-${compId}-${specId}`;
