import { CatalogWidgetProps } from '@/webapi/use-widget-catalog-api';
import { DeviceType } from '@/utils/definitions';
import { getManualProductsSize } from '@/features/editor/widgets/custom-widget/loading-section/util';
import { SlotState } from '@/features/editor/widgets/custom-widget/loading-section/shared/models';

export function getEmptySlots(
  currentSchema: CatalogWidgetProps,
  device: DeviceType,
): Array<SlotState> {
  return Array.from(
    Array(getManualProductsSize(currentSchema, device)).keys(),
  ).map((n) => ({ num: n }));
}
