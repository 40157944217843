import { useContext } from 'react';
import { AccountContext } from '@/features/account-context';

export const currencies = {
  USD: `$`,
  CAD: `$`,
  EUR: `€`,
  GBP: `£`,
  JPY: `¥`,
  CNY: `¥`,
  ILS: `₪`,
  AFN: `؋`,
  ARS: `$`,
  AWG: `ƒ`,
  AUD: `$`,
  AZN: `ман`,
  BSD: `$`,
  BBD: `$`,
  BYR: `p.`,
  BZD: `BZ$`,
  BMD: `$`,
  BOB: `$b`,
  BAM: `KM`,
  BWP: `P`,
  BGN: `лв`,
  BRL: `R$`,
  BND: `$`,
  KHR: `៛`,
  KYD: `$`,
  CLP: `$`,
  COP: `$`,
  CRC: `₡`,
  HRK: `kn`,
  CUP: `₱`,
  CZK: `Kč`,
  DKK: `kr`,
  DOP: `RD$`,
  XCD: `$`,
  EGP: `£`,
  SVC: `$`,
  EEK: `kr`,
  FKP: `£`,
  FJD: `$`,
  GHC: `¢`,
  GIP: `£`,
  GTQ: `Q`,
  GGP: `£`,
  GYD: `$`,
  HNL: `L`,
  HKD: `$`,
  HUF: `Ft`,
  ISK: `kr`,
  INR: `₹`,
  IDR: `Rp`,
  IRR: `﷼`,
  IMP: `£`,
  JMD: `J$`,
  ALL: `Lek`,
  JEP: `£`,
  KZT: `лв`,
  KPW: `₩`,
  KRW: `₩`,
  KGS: `лв`,
  LAK: `₭`,
  LVL: `Ls`,
  LBP: `£`,
  LRD: `$`,
  LTL: `Lt`,
  MKD: `ден`,
  MYR: `RM`,
  MUR: `₨`,
  MXN: `$`,
  MNT: `₮`,
  MZN: `MT`,
  NAD: `$`,
  NPR: `₨`,
  ANG: `ƒ`,
  NZD: `$`,
  NIO: `C$`,
  NGN: `₦`,
  NOK: `kr`,
  OMR: `﷼`,
  PKR: `₨`,
  PAB: `B/.`,
  PYG: `Gs`,
  PEN: `S/.`,
  PHP: `₱`,
  PLN: `zł`,
  QAR: `﷼`,
  RON: `lei`,
  RUB: `руб`,
  SHP: `£`,
  SAR: `﷼`,
  RSD: `Дин.`,
  SCR: `₨`,
  SGD: `$`,
  SBD: `$`,
  SOS: `S`,
  ZAR: `S`,
  LKR: `₨`,
  SEK: `kr`,
  CHF: `CHF`,
  SRD: `$`,
  SYP: `£`,
  TWD: `NT$`,
  THB: `฿`,
  TTD: `TT$`,
  TRL: `₤`,
  TVD: `$`,
  UAH: `₴`,
  UYU: `$U`,
  UZS: `лв`,
  VEF: `Bs`,
  VND: `₫`,
  YER: `﷼`,
  ZWD: `Z$`,
};

export function currencySymbol(): string {
  const { account } = useContext(AccountContext);
  return currencies?.[account?.store?.currency || `USD`];
}

export function currencyValue(): string {
  const { account } = useContext(AccountContext);
  return account?.store?.currency || `USD`;
}
