import React, { useEffect, useRef } from 'react';
import useAsyncEffect from 'use-async-effect';
import { LoadedEditorResources } from '@/features/editor/use-editor-loader';
import { DevicePreview } from '@/features/editor/device-preview';
import {
  EditorContext,
  EditorContextProps,
  newEditorContext,
} from '@/features/editor/context/editor-context';
import { Inspector } from '@/features/editor/inspector';
import { CustomWidgetStep } from '@/features/editor/widgets/custom-widget/shared/context';
import { PendingChangesMessage } from '@/features/editor/pending-changes-message';
import { EDITOR_ACTIVE_BLOCK_CACHE } from '@/components/hooks/use-cached-auto-save';
import {
  EditorDeviceSection,
  EditorInspectorSection,
  EditorWrapper,
} from '@/features/editor/shared/editor-layout';
import { TestStoreDisclaimer } from '@/features/editor/test-store-disclaimer';
import { EnablePostPurchaseModal } from '@/features/editor/widgets/post-purchase/enable-post-purchase';
import { EditorDeclarativeBlock } from '@/webapi/use-experience-api';

export interface EditorProps {
  resources: LoadedEditorResources;
}

export function Editor({ resources }: EditorProps) {
  const editorContext = newEditorContext(resources);
  const { previewLoading } = editorContext;
  const { device } = editorContext.devicePreview.editorState;
  useRestoreActiveCustomWidget(editorContext);
  const ref = useRef(null);

  return (
    <EditorContext.Provider value={editorContext}>
      <EditorWrapper
        id="editor-wrapper"
        device={device}
        ref={ref}
        previewLoading={previewLoading}
      >
        <EditorDeviceSection>
          <DevicePreview />
        </EditorDeviceSection>
        <EditorInspectorSection>
          <PendingChangesMessage />
          <TestStoreDisclaimer />
          <Inspector />
        </EditorInspectorSection>
        <EnablePostPurchaseModal fromRef={ref} />
      </EditorWrapper>
    </EditorContext.Provider>
  );
}

function useRestoreActiveCustomWidget(editorContext: EditorContextProps) {
  const [pendingChange, setPendingChange] =
    React.useState<EditorDeclarativeBlock>(undefined);

  useEffect(() => {
    if (!editorContext.previewLoading && pendingChange) {
      if (pendingChange.block.kind === `checkoutWidget`) {
        setTimeout(
          () =>
            editorContext.inspectorNav.gotoCheckoutWidgetEdit(pendingChange),
          500,
        );
      }
    }
  }, [editorContext.previewLoading, pendingChange]);

  useAsyncEffect(async () => {
    const activeChange = await EDITOR_ACTIVE_BLOCK_CACHE.get(
      editorContext?.experienceState?.currentExperience?.id,
    );

    if (!activeChange) return;

    if (activeChange.block.kind === `checkoutWidget`) {
      if (activeChange?.checkoutWidgetManifest?.manifest?.id) {
        setPendingChange(activeChange);
      }
    } else {
      await editorContext.inspectorNav.gotoCustomWidget(
        activeChange,
        CustomWidgetStep.CUSTOMIZE,
      );
    }
  }, []);
}
export { getProductIds } from '@/features/editor/widgets/shared/useStrategyPerSlot';
export { useStrategyPerSlot } from '@/features/editor/widgets/shared/useStrategyPerSlot';
