/* eslint-disable */
import { StylesConfig } from 'react-select';

export const selectStyle: StylesConfig<any> = {
  menu: (styles) => ({
    ...styles,
  }),
  control: (styles, { isFocused, menuIsOpen, isDisabled }) => ({
    ...styles,
    border: isDisabled
      ? `none`
      : isFocused || menuIsOpen
      ? `1px solid #aaaaaa`
      : `1px solid #e1e7e4`,
    outline: `none`,
    paddingLeft: `10px`,
    borderRadius: `10px`,
    backgroundColor: isDisabled ? `auto` : styles?.backgroundColor,
    height: `40px`,
    boxShadow: `0 9px 13px 0 rgba(177,217,203,0.18)`,
    ':hover': {
      border: `1px solid #aaaaaa`,
    },
    ':active': {
      border: `1px solid #aaaaaa`,
    },
  }),
  input: (styles) => ({
    ...styles,
    fontSize: `1.2rem`,
    fontFamily: `Inter, serif`,
    fontWeight: 400,
    color: `#4B5564`,
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: `1.2rem`,
    fontFamily: `Inter, serif`,
    fontWeight: 400,
    color: `#4B5564`,
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    fontSize: `1.2rem`,
    fontWeight: 400,
    color: `#4B5564`,
    fontFamily: `Inter, serif`,
    background: isSelected ? `lightgray` : `white`,
    ':hover': {
      background: `grey`,
      color: `white`,
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    color: `black`,
  }),
  indicatorsContainer: (base, { isDisabled }) => ({
    ...base,
    display: isDisabled ? `none` : `block`,
  }),
  indicatorSeparator: () => ({
    display: `none`,
  }),
  valueContainer: (styles) => ({
    ...styles,
    fontSize: `1.2rem`,
    fontFamily: `Inter, serif`,
    fontWeight: 400,
    color: `#4B5564`,
  }),
};
