import { CachePolicies, useFetch } from 'use-http';
import { routes } from '@/webapi/routes';
import {
  FbImagesResp,
  InstagramMedia,
  InstagramMediaResp,
  ListImagesQuery,
} from '@/features/editor/widgets/custom-widget/inputs/background/image/facebook/models';

export interface InstagramResp {
  data: Daum[];
  paging: Paging;
}

export interface Daum {
  id: string;
  comments_count: number;
  like_count: number;
  media_url: string;
  media_type: string;
  thumbnail_url?: string;
  caption: string;
}

export interface Paging {
  cursors: Cursors;
  next: string;
  previous: string;
}

export interface Cursors {
  before: string;
  after: string;
}

export interface InstagramAccount {
  id: string;
  name: string;
}

export function useFBImagesApi(initialLoading = true) {
  const { get, post, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: initialLoading,
    },
  }));

  async function listAdImages(
    imagesQuery: ListImagesQuery,
  ): Promise<FbImagesResp> {
    return post(routes.listFBImages, imagesQuery);
  }

  async function listInstagramImages(
    accountId: string,
  ): Promise<InstagramMediaResp> {
    return post(routes.instagramImages, { accountId });
  }

  async function listInstagramAccounts(): Promise<{
    accounts: Array<InstagramAccount>;
    notLoggedIn: boolean;
  }> {
    return get(routes.lisInstagramAccounts);
  }

  async function nextImages(next: string): Promise<InstagramMediaResp> {
    const promise = (await (await fetch(next)).json()) as InstagramResp;
    const images =
      promise?.data?.map(
        (m) =>
          ({
            mediaType: m.media_type,
            likesCount: m.like_count,
            commentsCount: m.comments_count,
            caption: m.caption,
            id: m.id,
            mediaUrl: m.media_url,
          } as InstagramMedia),
      ) || [];
    return {
      next: promise?.paging?.next || ``,
      notLoggedIn: false,
      images,
    };
  }

  async function encrypt(alias: string): Promise<{ value: string }> {
    return post(routes.encryptAlias, { value: alias });
  }

  return {
    nextImages,
    encrypt,
    loading,
    listImages: listAdImages,
    listInstagramAccounts,
    listInstagramImages,
  };
}
