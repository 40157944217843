import React, { useContext, useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import {
  EditorDeclarativeBlock,
  PlacementKind,
  TargetingDeviceType,
} from '@/webapi/use-experience-api';
import { EditorContext } from '@/features/editor/context/editor-context';
import { SmallButton } from '@/components/small-button';
import { DeviceType } from '@/utils/definitions';
import { getOriginExperienceName } from '@/features/editor/shared/external_experience_widget';

export function ChangeTarget({
  change,
  isSynced,
}: {
  change: EditorDeclarativeBlock;
  isSynced: boolean;
}) {
  const {
    devicePreview: {
      editorState: { device },
    },
    experienceState: { currentExperience, setChangeTargetPlacement },
  } = useContext(EditorContext);

  const [isOpen, setIsOpen] = useState(false);

  const onClick = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    setIsOpen(true);
  };

  const onSelected = (placement: PlacementKind) => {
    setIsOpen(false);
    setChangeTargetPlacement(change.editorId, placement);
  };

  return (
    <>
      <ChangeTargetWrapper device={device} allowEdit={isSynced}>
        <span>on</span>
        <Popover
          onClickOutside={() => setIsOpen(!isOpen)}
          positions={[`bottom`, `left`, `top`, `right`]}
          isOpen={isOpen}
          containerStyle={{ zIndex: `10000` }}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor="white"
              arrowClassName="change-target-arrow"
              arrowSize={10}
            >
              <ChangeTargetPopOver
                onSelection={onSelected}
                device={change?.targetDevice}
                placement={
                  change?.targetPlacement?.kind || PlacementKind.ALL_PAGES
                }
              />
            </ArrowContainer>
          )}
        >
          <SmallButton
            size="medium"
            background="rgba(234, 237, 239, 0.8)"
            noShadow
            fontFamily="Inter"
            color="#000000"
            onClick={onClick}
          >
            {formatChangeTarget(change, currentExperience?.targeting?.device)}
            <StaticImage
              imgStyle={{ display: `block` }}
              src="../../../../assets/down_arrow.svg"
              alt="down arrow"
              placeholder="none"
              loading="eager"
              height={5}
            />
          </SmallButton>
        </Popover>
      </ChangeTargetWrapper>
      {getOriginExperienceName(change) && (
        <Info>
          <BsFillQuestionCircleFill size={15} fill="#8b959d" />
          the main experience is not affected by this change
        </Info>
      )}
    </>
  );
}

const Info = styled.p`
  display: flex;
  align-items: center;
  justify-content: start;
  svg {
    margin-right: 1rem;
  }
  margin: 10px 0 0 0;
  color: #8b959d;
  font-family: 'JetBrains Mono', serif;
  font-weight: 500;
`;

const ChangeTargetWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #8e969d;

  button {
    background: transparent !important;
    text-decoration: none;
    cursor: ${(props: { device: DeviceType; allowEdit: boolean }) =>
      props.allowEdit ? `pointer` : `default`};
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    color: #000000 !important;
    text-transform: initial;
    font-size: ${(props: { device: DeviceType; allowEdit: boolean }) =>
      props.device === DeviceType.Desktop ? `1.2rem` : `1.4rem`};
    margin-left: 0.6rem;
    padding: ${(props: { device: DeviceType; allowEdit: boolean }) =>
      props.device === DeviceType.Desktop ? `0.5rem 0` : `0.6rem 0`};

    .gatsby-image-wrapper {
      margin-left: 0.8rem;
    }
  }
`;

function ChangeTargetPopOver({
  device,
  placement,
  onSelection,
}: {
  device: TargetingDeviceType;
  placement: PlacementKind;
  onSelection: (placement: PlacementKind) => void;
}) {
  const onClick = (ev, placement: PlacementKind) => {
    ev.stopPropagation();
    ev.preventDefault();
    onSelection(placement);
  };

  return (
    <PopoverWrapper>
      {Object.keys(PlacementKind).map((k) => (
        <div
          key={k}
          onClick={(ev) => {
            onClick(ev, PlacementKind[k]);
          }}
          className={placement === PlacementKind[k] ? `vsly-selected` : ``}
        >
          <StaticImage
            placeholder="none"
            height={15}
            src="../../../../assets/blue_v.svg"
            alt="blue checkmark"
          />
          {formatDevice(device)} {` `} {formatPlacement(PlacementKind[k])}
        </div>
      ))}
    </PopoverWrapper>
  );
}

const PopoverWrapper = styled.div`
  display: flex;
  background: white;
  flex-direction: column;
  justify-content: center;

  font-family: Inter, sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  color: #8d99ae;
  border-radius: 1rem;
  box-shadow: 0 8px 10px 0 rgba(105, 122, 231, 0.11),
    0 23px 32px 0 rgba(196, 217, 210, 0.38), 0 2px 10px 0 rgba(0, 0, 0, 0.15);

  & > div {
    cursor: pointer;
    display: grid;
    grid-template-columns: 2rem 1fr;
    grid-gap: 1rem;
    justify-content: flex-start;
    align-items: center;
    min-height: 4.3rem;
    padding: 0 5rem 0 2rem;

    .gatsby-image-wrapper {
      opacity: 0;

      img {
        height: 1.3rem;
        width: 1.3rem;
      }
    }

    :hover {
      opacity: 0.8;
    }
  }

  div.vsly-selected {
    color: #3451fa;

    .gatsby-image-wrapper {
      opacity: 1;
    }
  }

  div:not(:first-child) {
    border-top: 1px solid rgba(151, 151, 151, 0.25);
  }
`;

function formatChangeTarget(
  change: EditorDeclarativeBlock,
  legacyDevice?: TargetingDeviceType,
): string {
  const device = formatDevice(change.targetDevice || legacyDevice);
  const placement = formatPlacement(
    change?.targetPlacement?.kind || PlacementKind.ALL_PAGES,
  );
  return `${device} ${placement}`;
}

function formatDevice(device: TargetingDeviceType): string {
  switch (device) {
    case TargetingDeviceType.MOBILE:
      return `Mobile`;
    case TargetingDeviceType.DESKTOP:
      return `Desktop`;
    default:
      return `All Devices`;
  }
}

function formatPlacement(placement: PlacementKind): string {
  switch (placement) {
    case PlacementKind.ALL_CATEGORIES:
      return `Collection Page`;
    case PlacementKind.ALL_PRODUCTS:
      return `Product Page`;
    case PlacementKind.CHECKOUT:
      return `Checkout Page`;
    case PlacementKind.CART:
      return `Cart Page`;
    case PlacementKind.ALL_PAGES:
      return `All Pages`;
    case PlacementKind.OTHER:
      return `Custom Page`;
    default:
      return `Homepage`;
  }
}
