import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Customization, Groups } from '@/webapi/use-widget-catalog-api';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';

export interface StyleGroupsFilterProps {
  groups: Groups;
  onGroupSelected: (includedSections: string[]) => void;
  customizations: Customization[];
  hiddenCustomizations: string[];
}

export function StyleGroupsFilter({
  groups,
  customizations,
  onGroupSelected,
  hiddenCustomizations,
}: StyleGroupsFilterProps) {
  const { primary, mapping } = groups;
  const { setUIStateByGroup } = useContext(CustomWidgetContext);
  const defaultSections = useMemo(() => {
    const filters = Object.entries(mapping).flatMap(([_, v]) => v);
    return customizations
      .filter((cust) => !filters.includes(cust.key))
      .map((item) => item.key);
  }, [groups]);

  const [selected, setSelected] = useState(primary);

  const onSelection = (key: string, value: string[]) => {
    setUIStateByGroup(key);
    setSelected(key);
    onGroupSelected(value);
  };

  useEffect(() => {
    onSelection(primary, defaultSections);
  }, []);

  const filteredGroups = useMemo(
    () =>
      Object.entries(mapping).filter(
        ([_, v]) =>
          v.filter((value) => hiddenCustomizations.includes(value)).length ===
          0,
      ),
    [groups, hiddenCustomizations],
  );

  const count = filteredGroups.length + 1;

  return (
    <Wrapper count={count} style={{ display: count > 1 ? `grid` : `none` }}>
      <Option
        key={primary}
        isSelected={selected === primary}
        onClick={() => onSelection(primary, defaultSections)}
      >
        {primary}
      </Option>
      {filteredGroups.map(([k, v]) => (
        <Option
          key={k}
          isSelected={selected === k}
          onClick={() => onSelection(k, v)}
        >
          {k}
        </Option>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 3rem;
  width: 100%;
  background-color: #f3f6f7;
  display: grid;
  grid-template-columns: repeat(
    ${(props: { count: number }) => props.count},
    1fr
  );
  padding: 1rem;
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;
  font-family: JetBrains Mono, serif;
  font-size: 1.5rem;
  font-weight: 500;
  border-radius: 1rem;
`;

const Option = styled.div`
  text-transform: uppercase;
  text-align: center;
  border-radius: 1rem;
  padding: 1.5rem 1rem;
  cursor: pointer;
  color: #909ca7;
  ${(props: { isSelected: boolean }) =>
    props.isSelected &&
    `
    font-weight: 600;
    color: #000000;
    background-color: #ffffff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15),
      0 6px 7px 0 rgba(151, 210, 206, 0.22);
  `};
`;
