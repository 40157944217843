import React, { useContext } from 'react';
import styled from 'styled-components';
import { LayeredRulingContext } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import { RuleCond } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule/condition';
import { QBCaption } from '@/components/query-builder/shared';
import { Strategy } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule/strategy/strategy';
import { StrategyPerSlot } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule/strategy-per-slot';

type Props = { id: number };

export function Body({ id }: Props) {
  const ctx = useContext(LayeredRulingContext);
  const rule = ctx.rule(id);
  if (!rule.isOpen) {
    return null;
  }
  return (
    <Wrapper>
      <RuleCond ruleId={id} />
      <Dots>. . .</Dots>
      <Strategy ruleId={id} />
      <StrategyPerSlot ruleId={id} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;
const Dots = styled(QBCaption)`
  position: absolute;
  width: 5px;
  top: 2.8rem;
  line-height: 1.3rem;
`;
