import * as React from 'react';
import styled from 'styled-components';
import { useContext } from 'react';
import { AudienceButton } from '@/features/editor/widgets/shared/targeting-controls/audience-button';
import { PlacementButton } from '@/features/editor/widgets/shared/targeting-controls/placement-button';
import { DeviceButton } from '@/features/editor/widgets/shared/targeting-controls/device-button';
import { TriggerButton } from '@/features/editor/widgets/shared/targeting-controls/trigger-button';
import { EditorContext } from '@/features/editor/context/editor-context';

export interface TargetingControlsGridProps {
  disableAudiencePicker?: boolean;
  disablePlacementPicker?: boolean;
  disableDevicesPicker?: boolean;
  disableTriggerPicker?: boolean;
  disableThemePickerPicker?: boolean;
}

export function TargetingControlsGrid({
  disableAudiencePicker,
  disableTriggerPicker,
  disablePlacementPicker,
  disableDevicesPicker,
}: TargetingControlsGridProps) {
  const { currentExtension } = useContext(EditorContext);

  return (
    <Wrapper>
      <Row>
        <AudienceButton isDisabled={disableAudiencePicker} />
        <PlacementButton isDisabled={disablePlacementPicker} />
      </Row>
      <Row>
        <DeviceButton isDisabled={disableDevicesPicker} />
        <TriggerButton
          isDisabled={disableTriggerPicker || currentExtension !== ``}
        />
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: center;
`;

const Row = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: max-content max-content;
  grid-gap: 2rem;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: center;
`;
