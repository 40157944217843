/* eslint-disable */
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { DefaultTypography } from '@/components/typography';
import { CloseButton } from '@/components/close-button';
import { TabSelect } from '@/components/tab-select';
import { Flexbox } from '@/components/flex';
import { ImageEditorUpload } from '@/features/editor/widgets/custom-widget/inputs/background/image/upload';
import { ImageEditorStock } from '@/features/editor/widgets/custom-widget/inputs/background/image/stock';
import { ImageEditorMyMedia } from '@/features/editor/widgets/custom-widget/inputs/background/image/gallery';
import { ImageEditorMyCatalog } from '@/features/editor/widgets/custom-widget/inputs/background/image/catalog';
import { ImageEditorTab } from '@/features/editor/widgets/custom-widget/inputs/background/image/shared/models';
import {
  ImageEditorContext,
  newImageEditorContext,
} from '@/features/editor/widgets/custom-widget/inputs/background/image/shared/context';
import { ImageEditor } from '@/features/editor/widgets/custom-widget/inputs/background/image/editor';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';
import { BigButton } from '@/components/big-button';
import { HSpace } from '@/components/spacing';
import { useMediaApi } from '@/webapi/use-media-api';
import {
  awaitCondition,
  extractBlob,
  extractBlobFromBackgroundUrl,
} from '@/features/editor/widgets/custom-widget/inputs/shared/utils';
import { Spinner } from '@/Spinner';
import { FacebookImages } from '@/features/editor/widgets/custom-widget/inputs/background/image/facebook/facebook_images';
import { ExperienceStateHook } from '@/features/editor/context/use-experience-state';
import { Compress } from '@/features/editor/widgets/custom-widget/inputs/background/image/compress';
import { ConfirmModal } from '@/components/confirm-modal';
import { centered, useSharedElement } from '@/components/use-shared-element';
import { SharedElementOverlay } from '@/components/shared-element-overlay';
import { FeatureBit } from '@/webapi/use-auth-api';
import { useFeatureBit } from '@/features/account-context';
import { InstagramImages } from '@/features/editor/widgets/custom-widget/inputs/background/image/facebook/instagram_images';

const _tabs = [
  { label: `Add Image`, value: ImageEditorTab.ADD_IMAGE },
  { label: `Free Images`, value: ImageEditorTab.STOCK },
  { label: `Meta Campaigns`, value: ImageEditorTab.META_CAMPAIGNS },
  { label: `My Media`, value: ImageEditorTab.MY_MEDIA },
  { label: `My Catalog`, value: ImageEditorTab.MY_CATALOG },
  { label: `Instagram`, value: ImageEditorTab.INSTAGRAM },
];

function getActiveTabs(
  experienceState: ExperienceStateHook,
  instagramEnabled: boolean,
) {
  return _tabs?.filter((tab) => {
    if (!instagramEnabled && tab.value === ImageEditorTab.INSTAGRAM) {
      return false;
    }
    if (tab.value === ImageEditorTab.META_CAMPAIGNS) {
      return !!experienceState?.currentExperience?.targeting?.facebook
        ?.timeframe?.value;
    }
    return true;
  });
}

function getTabLabels(
  experienceState: ExperienceStateHook,
  instagramEnabled: boolean,
) {
  return getActiveTabs(experienceState, instagramEnabled).map((t) => t.label);
}

export function ImageEditorModal({
  onUpdatePreview,
  aspectRatio,
  image,
  onClose,
  onUpdateImage,
  getModalRect,
  savedImage,
  isSavedRef,
  setSavedImage,
}: {
  aspectRatio?: number | undefined;
  onUpdatePreview?: (blob: string) => void;
  onUpdateImage?: (link: string) => void;
  image: string;
  onClose: () => void;
  getModalRect: () => DOMRect;
  isSavedRef: MutableRefObject<boolean>;
  savedImage: string;
  setSavedImage: Dispatch<SetStateAction<string>>;
}) {
  const {
    experienceState,
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);
  const ctx = newImageEditorContext(image, aspectRatio);
  const { uploadImage } = useMediaApi();
  const { setMode, mode, blobToCompress, setShouldCompress, shouldCompress } =
    ctx;
  const [loading, setLoading] = useState(false);
  const isCompressionEnabled = useFeatureBit(FeatureBit.IMAGE_COMPRESSION);

  const onReplaceImage = () => {
    ctx.setImage(``);
    ctx.setMode(ImageEditorTab.ADD_IMAGE);
    isSavedRef.current = false;
  };
  const imageRef = useRef<string>('');
  const handlePreviewUpdate = (blob: string) => {
    onUpdatePreview(blob);
    if (loading) {
      imageRef.current = extractBlob(blob);
    }
  };

  async function forcePreviewOnSite() {
    // @ts-ignore
    document?.querySelectorAll(`button[title='Preview on site']`)[0]?.click();
    await awaitCondition(() => imageRef.current != '', 100, 100);
  }

  async function doUpload(blob: Blob) {
    const resp = await uploadImage(blob, blob, blob);
    onUpdateImage && onUpdateImage(resp.thumbnail);
    isSavedRef.current = true;
    setSavedImage(resp.thumbnail);
    onClose();
  }

  const onAddImage = async () => {
    setLoading(true);
    try {
      await forcePreviewOnSite();
      const img = extractBlobFromBackgroundUrl(imageRef.current);
      const blob = await fetch(img).then((r) => r.blob());
      const blobSize = blob?.size / (1024 * 1024);
      const moreThan2MB = blobSize > 2;
      if (isCompressionEnabled && moreThan2MB) {
        blobToCompress.current = blob;
        setShouldCompress(true);
        return;
      }
      await doUpload(blob);
    } catch (ex) {
      console.log(`vsly`, `failed to add image with exception:`, ex, imageRef);
    } finally {
      imageRef.current = '';
      setLoading(false);
    }
  };
  const handleClose = () => {
    if (!isSavedRef.current) {
      const blob = extractBlob(savedImage);
      ctx.setImage(``);
      onUpdatePreview(blob);
    }
    onClose();
  };

  const {
    props,
    hide: hideConfirm,
    show: showConfirm,
    fromRef: closeRef,
  } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `#dedede`,
        opacity: `0`,
      },
      extraTo: {
        background: `white`,
        opacity: `1`,
      },
    },
    undefined,
    () => centered(19, 42),
  );

  const instagramEnabled = useFeatureBit(FeatureBit.INSTAGRAM_INTEGRATION);

  const tabLabels = getTabLabels(experienceState, instagramEnabled);
  return (
    <div style={{ position: `relative`, height: '100%' }}>
      <SharedElementOverlay {...props}>
        <ConfirmModal
          neutral
          onDiscard={hideConfirm}
          onConfirm={handleClose}
          title={'Discard Changes?'}
          description={
            "Are you sure you want to discard the changes you've made to the image?"
          }
        />
      </SharedElementOverlay>
      <StyledSpinner loading={loading} size={100} />
      <ImageEditorContext.Provider value={ctx}>
        {mode === ImageEditorTab.EDIT ? (
          <>
            {shouldCompress && (
              <Compress
                onReplaceImage={onReplaceImage}
                forcePreviewOnSite={forcePreviewOnSite}
                doUpload={doUpload}
                onUpdatePreview={handlePreviewUpdate}
              />
            )}
            <ImageEditorWrapper
              style={shouldCompress ? { display: 'none' } : {}}
              height={getModalRect().height}
              loading={loading}
            >
              <Header>
                <Title>Edit Image</Title>
                <CloseButton
                  disabled={loading}
                  wrapperRef={closeRef}
                  onClick={showConfirm}
                />
              </Header>
              <ImageEditor onUpdatePreview={handlePreviewUpdate} />
              <Flexbox direction="row" padding="2rem">
                <BigButton
                  disabled={loading}
                  background="#FFFFFF"
                  color="#8C9BA8"
                  border="1px solid #A6AFB8"
                  noTransform
                  fillWidth
                  noShadow
                  size="medium"
                  onClick={onReplaceImage}
                >
                  Replace Image
                </BigButton>
                <HSpace value={2} />
                <BigButton
                  disabled={loading}
                  background="#334BFF"
                  color="#FFFFFF"
                  noTransform
                  fillWidth
                  noShadow
                  size="medium"
                  onClick={onAddImage}
                >
                  Add Image
                </BigButton>
              </Flexbox>
            </ImageEditorWrapper>
          </>
        ) : (
          <Flexbox
            direction="column"
            justify="center"
            align="center"
            padding="2rem"
            gap="2rem"
          >
            <Header>
              <Title>Select Image</Title>
              <CloseButton onClick={onClose} />
            </Header>
            <Flexbox align="center" justify="center">
              <TabSelect
                defaultValue={
                  getActiveTabs(experienceState, instagramEnabled)[0].label
                }
                tabs={tabLabels}
                tabHeight={device === DeviceType.Desktop ? 4 : 3.2}
                tabWidth={device === DeviceType.Desktop ? 9.5 : 12}
                tabPadding={device === DeviceType.Desktop ? `0 1rem` : `0`}
                containerPadding={0.4}
                tabRadius={0.8}
                tabFont={'Inter, serif'}
                onTabSelected={(idx) => {
                  setMode(
                    getActiveTabs(experienceState, instagramEnabled)[idx].value,
                  );
                }}
              />
            </Flexbox>
            <Content>
              {mode === ImageEditorTab.ADD_IMAGE && (
                <ImageEditorUpload getModalRect={getModalRect} />
              )}
              {mode === ImageEditorTab.ADD_VIDEO && (
                <ImageEditorUpload getModalRect={getModalRect} />
              )}
              {mode === ImageEditorTab.STOCK && (
                <ImageEditorStock getModalRect={getModalRect} />
              )}
              {mode === ImageEditorTab.MY_MEDIA && (
                <ImageEditorMyMedia getModalRect={getModalRect} />
              )}
              {mode === ImageEditorTab.MY_CATALOG && (
                <ImageEditorMyCatalog getModalRect={getModalRect} />
              )}
              {mode === ImageEditorTab.META_CAMPAIGNS && (
                <FacebookImages getModalRect={getModalRect} />
              )}
              {mode === ImageEditorTab.INSTAGRAM && (
                <InstagramImages getModalRect={getModalRect} />
              )}
            </Content>
          </Flexbox>
        )}
      </ImageEditorContext.Provider>
    </div>
  );
}

const Header = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  .gatsby-image-wrapper {
    height: 2rem;
    width: 2rem;
  }
`;

const Title = styled(DefaultTypography)`
  && {
    color: #4b5564;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -0.58px;
    text-align: center;
    width: 100%;
  }
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
`;

interface I {
  height: number;
  loading: boolean;
}

const ImageEditorWrapper = styled.div`
  position: relative;
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  opacity: ${(p: I) => (p.loading ? 0.2 : 1)};
  grid-template-rows: 5rem ${(p: I) => p.height - 180}px 5rem;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
`;

const StyledSpinner = styled(Spinner)`
  z-index: 999999;
  position: absolute;
  display: ${(p: I) => (p.loading ? `block` : `none`)};
  top: 37%;
  left: 43%;
`;
