import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import produce from 'immer';
import {
  StyleTemplate,
  StyleTemplateType,
} from '@/features/editor/widgets/custom-widget/style-templating/models';
import { EditorDeclarativeBlock } from '@/webapi/use-experience-api';
import {
  CatalogWidget,
  CatalogWidgetProps,
} from '@/webapi/use-widget-catalog-api';
import { CustomWidgetContextProps } from '@/features/editor/widgets/custom-widget/shared/context';
import { maybe } from '@/features/details/utils';
import {
  applyStyle,
  useIsSuperSystemUser,
} from '@/features/editor/widgets/custom-widget/style-templating/utils';

import { STORE_STYLE_ID } from '@/features/editor/widgets/custom-widget/shared/store-style-utils';
import { AccountContext } from '@/features/account-context';
import { Role } from '@/utils/definitions';

export function makeCustomSelectOption(
  options: {
    label: string;
    value: StyleTemplate;
  }[],
  applyTempChange: (change: EditorDeclarativeBlock) => void,
  asWidgetChange: (
    origChange: EditorDeclarativeBlock,
    widget: CatalogWidget,
    schema: CatalogWidgetProps,
  ) => EditorDeclarativeBlock,
  widget: CustomWidgetContextProps,
  setDeleteStyleId?: (n: string) => void,
) {
  const selected = useRef(false);
  const account = useContext(AccountContext);
  const isSystemUser = account.account?.store?.role === Role.SYSTEM;

  const CustomOption = ({ innerProps, isDisabled, children }) => {
    const [isHover, setIsHover] = useState(false);
    const isSuperSystem = useIsSuperSystemUser();
    const originalMouseOver = innerProps.onMouseOver;
    const currentStyleId = maybe(
      () =>
        options.find((o) => o.value.id === widget.currentSchema.styleTemplateId)
          .value.id,
    );
    const option = maybe(() => options[innerProps.id.split(`option-`)[1]]);
    const isActiveStyle = maybe(() => option.value.id === currentStyleId);
    innerProps.onMouseOver = () => {
      setIsHover(true);
      originalMouseOver();
      if (option && widget.origChange) {
        selected.current = false;
        const schema = produce(
          widget.currentSchema || widget.currentWidget.blueprint.schema,
          (draft) => {
            draft.customizations = applyStyle(
              widget.currentSchema?.customizations || [],
              option.value,
            );
            if (shouldApplyGeneralSettings(option))
              draft.settings.general = option.value.generalSettings;
          },
        );
        applyTempChange(
          asWidgetChange(widget.origChange, widget.currentWidget, schema),
        );
      }
    };
    const originalOnClick = innerProps.onClick;
    innerProps.onClick = () => {
      selected.current = true;
      originalOnClick();
    };

    innerProps.onMouseLeave = () => {
      setIsHover(false);
    };

    const isCustom = option.value.type === StyleTemplateType.CUSTOM;
    const global = !!option.value.isGlobal;
    let showDeleteBtn =
      isHover &&
      isCustom &&
      !!setDeleteStyleId &&
      currentStyleId !== option.value.id;
    if (
      (showDeleteBtn && global && !isSuperSystem) ||
      option.value.id === STORE_STYLE_ID
    ) {
      showDeleteBtn = false;
    }
    return !isDisabled ? (
      <SelectOption {...innerProps} isActiveStyle={isActiveStyle}>
        {children}
        {isSystemUser &&
          !!setDeleteStyleId &&
          (option.value.id === STORE_STYLE_ID ||
            option.value.id === `default`) && (
            <OptionBtn
              onClick={(e) => {
                setDeleteStyleId(option.value.id);
                e.stopPropagation();
              }}
            >
              EDIT
            </OptionBtn>
          )}
        {showDeleteBtn && (
          <OptionBtn
            onClick={(e) => {
              setDeleteStyleId(option.value.id);
              e.stopPropagation();
            }}
          >
            DELETE
          </OptionBtn>
        )}
      </SelectOption>
    ) : null;
  };

  const revertTempChanges = () => {
    if (!selected.current) {
      const catalogWidget = widget.catalogApp?.widgets?.find?.(
        (w) => w.id === widget.currentWidget.id,
      );
      applyTempChange(
        asWidgetChange(
          widget.origChange,
          widget.currentWidget,
          widget.currentSchema || catalogWidget.blueprint.schema,
        ),
      );
    }
  };
  return { CustomOption, revertTempChanges };
}
const OptionBtn = styled.button`
  font-family: 'JetBrains Mono', serif;
  font-size: 1rem;
  padding: 0.2rem;
  opacity: 0.5;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;
const SelectOption = styled.div<{ isActiveStyle: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  font-family: 'JetBrains Mono', serif;
  font-weight: 500;
  font-size: 1.2rem;
  user-select: none;
  color: ${(p) => (p.isActiveStyle ? `blue` : `#4b5564`)};
  &:hover {
    color: black;
    cursor: pointer;
    background-color: #f3f6f7;
  }
  &:active {
    cursor: default;
  }
`;

function shouldApplyGeneralSettings(option) {
  return (
    option.value.generalSettings?.specs?.length > 0 &&
    option.value.id !== STORE_STYLE_ID
  );
}
