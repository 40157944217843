import { useContext } from 'react';
import * as React from 'react';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';
import {
  DropdownInput,
  DropdownInputOption,
} from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { InputsDarkTheme } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';

export function PostPurchaseOfferCount() {
  const {
    props: {
      settings: { offersCount },
    },
    setProps,
  } = useContext(PostPurchaseContext);

  const options: DropdownInputOption[] = [
    { label: `1 Offer`, value: 1 },
    { label: `2 Offers`, value: 2 },
  ];

  const onChange = (val: DropdownInputOption) => {
    setProps((draft) => {
      draft.settings.offersCount = val.value as number;
    });
  };

  return (
    <DropdownInput
      theme={InputsDarkTheme}
      options={options}
      defaultValue={options?.[offersCount - 1]}
      onChange={onChange}
      optionStyles={{ textTransform: `uppercase` }}
    />
  );
}
