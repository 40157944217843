import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { VSpace } from '@/components/spacing';
import { ChangelogTile } from '@/features/editor/widgets/changelog/tile';
import {
  EditorChangeKind,
  EditorDeclarativeBlock,
  TargetingDeviceType,
  UNNAMED_EXPERIENCE,
} from '@/webapi/use-experience-api';
import {
  ChangelogHint,
  TEMP_NO_SELECTOR,
} from '@/features/editor/widgets/changelog/placeholder';
import { EditorContext } from '@/features/editor/context/editor-context';
import { useCodeEditorLoader } from '@/features/editor/widgets/shared/use-code-editor-loader';
import { useWidgetControls } from '@/features/editor/widgets/shared/use-widget-controls';
import { AnchorOrigin } from '@/features/editor/context/use-device-preview';
import { DeviceType, WidgetType } from '@/utils/definitions';
import { CustomWidgetStep } from '@/features/editor/widgets/custom-widget/shared/context';
import { ExperienceNameOrigin } from '../name';
import { VSLY_AUTO_GENERATE } from '@/features/editor/widgets/visual-editor/legacy/visual-editor-transpiler';
import { CatalogAppSelectorList } from '@/features/editor/widgets/app-catalog';
import {
  toTransformKind,
  useTranspiler,
} from '@/features/editor/context/use-transpiler';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import { EditorRenameButton } from '@/features/editor/widgets/shared/layout/editor-rename-button';
import {
  TARGETING_CONTROLS_SELECTOR,
  TargetingControlsList,
} from '@/features/editor/widgets/shared/targeting-controls/targeting-controls-list';
import { GeneralText } from '@/features/editor/widgets/shared/general-text';
import { OnboardAddNewWidget } from '@/features/editor/widgets/changelog/onboard-add-new-widget';
import { conditional } from '@/utils/conditional';
import { ExperienceSpinner } from '@/components/experience-spinner';
import { CompoundChange } from '@/pkg/sdk';
import { DescriptionEditButton } from '@/features/editor/widgets/shared/layout/descr-rename-button';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import { NoMountPointsModal } from '@/features/editor/widgets/checkout-extensibility/checkout-inspector-no-mount-points';
import { getAudienceStarterQueryParam, setQueryParam } from '@/utils/browser';

export function ChangelogWidget() {
  const isV2Ftue = useFeatureBit(FeatureBit.ENABLE_V2_FTUE);
  const ctx = useContext(EditorContext);

  const {
    resources,
    resyncMutations,
    previewLoading,
    isInErrorStateRef,
    isEditingCheckoutExtensibility,
    deviceNavigation: { reloadWithQueryParam },
    experienceState: { setTargetingDevice, setTargetingAudience },
  } = ctx;
  const { currentExperience, getCurrentVariant, getPageRedirectChange } =
    ctx.experienceState;
  const { gotoCustomWidget, gotoChangelog } = ctx.inspectorNav;
  const {
    anchor,
    leaveAnchor,
    editorState: { device },
  } = ctx.devicePreview;

  const {
    gotoTargeting,
    gotoExperienceName,
    gotoFakeClick,
    gotoCheckoutWidgetEdit,
    gotoPageRedirectWidget,
  } = ctx.inspectorNav;
  const {
    gotoMoveElement,
    gotoGlobalCssEditor,
    gotoGlobalJsEditor,
    gotoNewElementEditor,
    gotoSingleValueEditor,
    gotoCompoundEditor,
    gotoVisualEditor,
  } = useCodeEditorLoader();

  const { WidgetControls, controlsProps } = useWidgetControls(() => {
    if (currentExperience.name === UNNAMED_EXPERIENCE) {
      gotoExperienceName(ExperienceNameOrigin.TARGETING);
    } else {
      gotoTargeting();
    }
  }, `Next`);

  const noPointsRef = useRef(null);
  const [isNoPointsModalVisible, setIsNoPointsModalVisible] = useState(false);

  const { changes } = getCurrentVariant();

  const onEditCompound = (change: EditorDeclarativeBlock) => {
    const block = change.block.value as CompoundChange;
    if (
      block.js.includes(VSLY_AUTO_GENERATE) ||
      block.css.includes(VSLY_AUTO_GENERATE)
    ) {
      gotoVisualEditor(change);
    } else {
      gotoCompoundEditor(
        change,
        countLines((change.block.value as CompoundChange).html) <= 256,
      );
    }
  };

  function handleVisualEdit(change: EditorDeclarativeBlock) {
    gotoVisualEditor(change);
  }

  function handleEditCompound(change: EditorDeclarativeBlock) {
    onEditCompound(change);
  }

  const onRefreshMountPoints = () => {
    reloadWithQueryParam(`vslyBypassCheckoutCache`, `true`);
    gotoChangelog();
  };

  const toggleNoPointsModal = () => {
    setIsNoPointsModalVisible(!isNoPointsModalVisible);
  };

  async function handleNewComponent(change: EditorDeclarativeBlock) {
    if (change.block.kind === `compound`) {
      anchor(change.editorSelector, AnchorOrigin.NEW_ELEMENT);
      gotoNewElementEditor(change.editorId);
    } else if (change.block.kind === `widget`) {
      anchor(change.editorSelector, AnchorOrigin.NEW_ELEMENT);
      await gotoCustomWidget(change, CustomWidgetStep.CUSTOMIZE);
    } else if (change.block.kind === `checkoutWidget`) {
      gotoCheckoutWidgetEdit(change);
    }
  }

  const onTileClick = async (change: EditorDeclarativeBlock) => {
    switch (change.editorKind) {
      case EditorChangeKind.GLOBAL_JS:
        gotoGlobalJsEditor(currentExperience.id, change);
        break;
      case EditorChangeKind.GLOBAL_CSS:
        gotoGlobalCssEditor(currentExperience.id, change);
        break;
      case EditorChangeKind.EDIT_COMPOUND:
        handleEditCompound(change);
        break;
      case EditorChangeKind.VISUAL_EDIT:
        handleVisualEdit(change);
        break;
      case EditorChangeKind.HIDE_COMPONENT:
        if (change.block.kind !== `rmCheckoutWidget`)
          gotoSingleValueEditor(change.editorId);
        break;
      case EditorChangeKind.NEW_COMPONENT:
        await handleNewComponent(change);
        break;
      case EditorChangeKind.MOVE_COMPONENT:
        gotoMoveElement(change, false);
        break;
      case EditorChangeKind.FAKE_CLICK:
        gotoFakeClick(change);
        break;
      default:
    }
  };

  function assignAudienceStarter(audienceStarter: string) {
    if (audienceStarter === `m`) {
      setTargetingDevice(TargetingDeviceType.MOBILE);
    } else if (audienceStarter === `d`) {
      setTargetingDevice(TargetingDeviceType.DESKTOP);
    } else if (audienceStarter === `r`) {
      setTargetingAudience([`RETURNING_VISITORS`], undefined);
    } else if (audienceStarter === `n`) {
      setTargetingAudience([`FIRST_SESSION`], undefined);
    } else if (audienceStarter?.length > 10) {
      const audience = resources.audiences.find(
        (aud) => aud.id === audienceStarter,
      );
      if (
        audience?.targeting?.segments?.length > 0 ||
        audience?.targeting?.other?.length > 0
      ) {
        setTargetingAudience(
          audience.targeting.segments,
          audience.targeting.other,
          audience.targeting.facebook,
          audience.targeting.userDefinedAudienceId,
        );
      }
    }
  }

  useEffect(() => {
    if (!ctx?.previewLoading) {
      setTimeout(leaveAnchor, 500);
      resyncMutations();

      const audienceStarter = getAudienceStarterQueryParam();
      if (audienceStarter) {
        assignAudienceStarter(audienceStarter);
        setQueryParam(`audience`, ``);
      }
    }
  }, [ctx?.previewLoading]);

  const showAppsCatalog =
    (!changes?.length || changes?.length === 0) &&
    !isEditingCheckoutExtensibility;

  const [id] = useState(`_loomi_addon_${new Date().getTime()}`);
  const demoChange = useMemo(
    () =>
      useTranspiler().asNewContentChange(
        id,
        toTransformKind(`appendAfter`),
        TEMP_NO_SELECTOR,
        undefined,
        true,
      ),
    [id],
  );

  useEffect(() => {
    if (!previewLoading) {
      const redirectChange = getPageRedirectChange();
      if (redirectChange) {
        gotoPageRedirectWidget(redirectChange);
        return;
      }
      setTimeout(() => {
        const scroller = document.querySelector(
          `#${TARGETING_CONTROLS_SELECTOR}`,
        );
        scroller?.scrollBy({ left: 1 });
      }, 500);
    }
  }, [previewLoading]);

  return (
    <InspectorWidgetLayout
      title={
        <TitleWrapper>
          <EditorRenameButton />
          <DescriptionEditButton />
        </TitleWrapper>
      }
      progress={0}
      hideBackButton
      showCloseButton
      footer={
        <Wrapper device={device}>
          <WidgetControls {...controlsProps} showHistory />
        </Wrapper>
      }
    >
      <Wrapper device={showAppsCatalog ? DeviceType.Desktop : device}>
        <GeneralText>
          1. What audience and where will the experience run?
        </GeneralText>
        <VSpace value={2} />
        <TargetingControlsList />
        <VSpace value={3} />
        <GeneralText>
          {showAppsCatalog
            ? `2. No changes yet, what would you like to add?`
            : `2. Which changes the experience contains?`}
        </GeneralText>
        <VSpace value={2} />
        {conditional(
          <ChangelogHint
            device={device}
            noCta
            content={<EditorSpinner color="#A9B5C2" size={3} thickness={0.3} />}
          >
            {isEditingCheckoutExtensibility
              ? `Hold on while we scan your checkout; this may take a while to finish...`
              : `Hold on while the editor finishes loading...`}
          </ChangelogHint>,
          showAppsCatalog && !previewLoading,
          <>
            <OnboardAddNewWidget />
            <CatalogAppSelectorList
              id={id}
              newChange={demoChange}
              longTiles={isV2Ftue}
            />
            <VSpace value={3} />
          </>,
          !showAppsCatalog && !previewLoading,
          <TilesWrapper shouldBlur={isInErrorStateRef?.current}>
            {(changes || []).map((change, idx) => (
              <ChangelogTile
                key={`changelog-tile-${change.editorId}-${change.editorKind}`}
                onClick={async () => {
                  await onTileClick(change);
                }}
                ordinal={idx + 1}
                changeType={WidgetType.CODE_EDITOR_DEPRECATED}
                change={change}
                device={device}
              />
            ))}
            <ChangelogHint device={device} />
            {isEditingCheckoutExtensibility && !previewLoading && (
              <CheckoutPrompt device={device}>
                To add new components, you need to create additional extension
                points in Shopify.{` `}
                <u onClick={toggleNoPointsModal}>How do you do it?</u> <br />
                Can&apos;t see newly added extension points?{` `}
                <u onClick={onRefreshMountPoints}>Click to refresh</u>
              </CheckoutPrompt>
            )}
            <VSpace value={3} />
          </TilesWrapper>,
        )}
        <>
          <div ref={noPointsRef} />
          <NoMountPointsModal
            fromRef={noPointsRef}
            isVisible={isNoPointsModalVisible}
            setIsVisible={setIsNoPointsModalVisible}
          />
        </>
      </Wrapper>
    </InspectorWidgetLayout>
  );
}

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditorSpinner = styled(ExperienceSpinner)`
  && {
    margin-left: 1rem;
  }
`;

const Wrapper = styled.div`
  && {
    width: 100%;

    padding-right: ${(props: { device: DeviceType }) =>
      props.device === DeviceType.Desktop ? `0` : `20rem`};
  }
`;

const TilesWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  filter: ${(p: any) => (p?.shouldBlur ? `blur(4px)` : `none`)};
`;

function countLines(str: string): number {
  return (str.match(/\n/g) || ``).length + 1;
}

const CheckoutPrompt = styled.div`
  user-select: none;
  font-size: ${(p) => (p.device === DeviceType.Desktop ? `1rem` : `1.1rem`)};
  font-family: 'JetBrains Mono', sans-serif;
  color: rgb(145, 153, 157);
  font-weight: 600;
  padding: 0 ${(p) => (p.device === DeviceType.Desktop ? `2rem` : `3rem`)};
  line-height: 1.8;

  u {
    cursor: pointer;

    &&:hover {
      color: rgb(92, 97, 99);
    }

    &&:active {
      color: rgb(28, 30, 30);
    }
  }
`;
