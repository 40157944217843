import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export default function Portal({ selector, children }) {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }
  return createPortal(children, document.querySelector(selector));
}
