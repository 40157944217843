import React from 'react';
import styled from 'styled-components';
import {
  GenericInputProps,
  InputsLightTheme,
} from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import {
  DropdownInput,
  DropdownInputOption,
} from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { useDropdownInputUpdater } from '@/features/editor/widgets/custom-widget/inputs/shared/use-dropdown-updater';

const VALUE_KEY = `textDecoration`;

const options: DropdownInputOption[] = [
  { label: `None`, value: `none` },
  { label: `Overline`, value: `overline` },
  { label: `Line Through`, value: `line-through` },
  { label: `Underline`, value: `underline` },
];

export function FontStyleInput({
  customizationIdx,
  customization,
  componentIdx,
  component,
  specIdx,
  spec,
  device,
  initialValues,
  onValuesChanged,
}: GenericInputProps) {
  const { getValue, onChange, undoRedoCount } = useDropdownInputUpdater(
    {
      customizationIdx,
      customization,
      componentIdx,
      component,
      specIdx,
      spec,
      device,
      initialValues,
      onValuesChanged,
    },
    VALUE_KEY,
    options,
  );

  return (
    <Wrapper>
      <DropdownInput
        key={undoRedoCount}
        defaultValue={getValue()}
        options={options}
        theme={InputsLightTheme}
        onChange={onChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;
