import React, { FC, useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  LoadingStrategyOption,
  RecommendationType,
} from '@/webapi/use-widget-catalog-api';
import {
  LayeredRulingContext,
  RuleState,
} from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import { defaultCondition } from '@/features/editor/widgets/custom-widget/loading-section/util/defaultCondition';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';

function shouldShowCond(strategy: LoadingStrategyOption, rule: RuleState) {
  return (
    !isSelected(strategy, rule) || rule.strategy === RecommendationType.MANUAL
  );
}

export const StrategyTile: FC<{
  strategy: LoadingStrategyOption;
  ruleId: number;
}> = ({ ruleId, strategy }) => {
  const ctx = useContext(LayeredRulingContext);
  const widgetContextProps = useContext(CustomWidgetContext);

  const rule = ctx.rule(ruleId);

  const select = () => {
    ctx.change(ruleId, (r) => {
      r.strategy = strategy.value.type;
      if (r.strategy === RecommendationType.MANUAL) {
        if (!r.strategyPerSlot) {
          r.strategyPerSlot = {
            slots: ctx.getManualSlots(),
          };
        }
      } else {
        r.strategyCond = defaultCondition(
          r.strategy,
          widgetContextProps?.currentSchema?.appId,
        );
      }
    });
  };

  const selected = isSelected(strategy, rule);
  const onSelect = !selected ? select : undefined;
  const onOpenCond = selected ? ctx.toggleCondBuilder : undefined;
  const conditions = rule.strategyCond?.length || 0;

  if (ctx.isPostPurchase) {
    return <PosRelative />;
  }
  return (
    <PosRelative>
      <Block onClick={onSelect} isSelected={selected}>
        <Title>{strategy.label.toUpperCase()}</Title>
        {shouldShowCond(strategy, rule) ? (
          <SubTitle>{strategy.description}</SubTitle>
        ) : (
          <SubTitle>
            Click for{` `}
            <span onClick={onOpenCond}>
              More Filters {conditions > 1 ? `(${conditions})` : ``}
            </span>
          </SubTitle>
        )}
      </Block>
      <Ok isSelected={selected} />
    </PosRelative>
  );
};

const PosRelative = styled.div`
  display: flex;
  position: relative;
  padding-right: 2rem;
  padding-bottom: 2rem;
`;
const OkWrapper = styled.div`
  position: absolute;
  top: 12.5rem;
  left: 25rem;
`;
const SubTitle = styled.span`
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #7e8993;
  span {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
    &:active {
      cursor: default;
      opacity: 1;
    }
  }
`;
const Title = styled.span`
  text-transform: capitalize;
  font-size: 1.2rem;
  font-weight: bold;
  color: #475562;
`;
const Block = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-family: 'JetBrains Mono', serif;
  ${(p) =>
    !p.isSelected
      ? `
    &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  &:active {
    cursor: default;
    opacity: 1;
  }
  `
      : ``}

  user-select: none;
  padding: 2rem;
  margin-top: 2rem;
  width: 26rem;
  height: 12rem;
  border-radius: 10px;
  border: 1.5px solid ${(p) => (p.isSelected ? `#3451FA` : `#cbcbcb`)};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15),
    0 14px 16px 0 rgba(151, 210, 206, 0.22), 0 5px 16px 0 rgba(0, 0, 0, 0);
`;

function Ok({ isSelected }: { isSelected: boolean }) {
  if (!isSelected) return null;
  return (
    <OkWrapper>
      <StaticImage
        height={20}
        loading="eager"
        placeholder="none"
        src="../../../../../../../../assets/ok.png"
        alt="minus"
      />
    </OkWrapper>
  );
}

function isSelected(p: LoadingStrategyOption, rule: RuleState) {
  return p.value.type === rule.strategy;
}
