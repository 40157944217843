import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
// @ts-ignore
import { useDebouncedCallback } from 'use-debounce';
import { EditorDeclarativeBlock } from '@/webapi/use-experience-api';
import {
  CatalogWidgetProps,
  CustomizationSpec,
} from '@/webapi/use-widget-catalog-api';
import { DeviceType } from '@/utils/definitions';
import {
  useComplexState,
  useStateWithHistory,
} from '@/utils/use-complex-state';
import { EditorContext } from '@/features/editor/context/editor-context';
import { formatKey } from '@/features/editor/widgets/visual-editor/legacy/legacy-visual-editor';
import { AnchorOrigin } from '@/features/editor/context/use-device-preview';
import {
  getEffectiveVisualEditChange,
  transpileVisualEditBlock,
  visualPropsToSpecs,
} from '@/features/editor/widgets/visual-editor/visual-edit-transpiler';
import { newUndoRedoContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';
import { VisualEditChange } from '@/pkg/sdk';
import { getDeviceBasedEditorId } from '@/webapi/common';

export const VisualEditorContext = React.createContext({} as VisualEditorCtx);

export interface VisualEditorCtx {
  change: EditorDeclarativeBlock;

  initialValue: Record<string, CustomizationSpec>;

  value: Record<string, CustomizationSpec>;

  isDirty: boolean;

  onSave: () => void;

  onHide: () => void;

  setResponsiveValue: (
    custId: number,
    compId: number,
    specId: number,
    key: string,
    value: any,
    _device: DeviceType,
  ) => void;

  getResponsiveValue: (
    custId: number,
    compId: number,
    specId: number,
    key: string,
    _device: DeviceType,
  ) => any;

  canRedo: boolean;
  redo: () => void;
  canUndo: boolean;
  undo: () => void;
  undoRedoContext: { undoRedoCount: number };
  device: DeviceType;
  gotoChangelog: () => void;
  visualProps: CatalogWidgetProps;
}

export function newVisualEditorContext(
  selector: string,
  visualProps: CatalogWidgetProps,
  origChange: EditorDeclarativeBlock,
): VisualEditorCtx {
  const {
    applyTempChange: _applyTempChange,
    experienceState: {
      upsertEditorChange,
      removeEditorChange,
      currentExperience: { experienceCreationVersion },
    },
    inspectorNav: { gotoChangelog },
    devicePreview: {
      anchor,
      leaveAnchor,
      editorState: { device },
    },
    transpiler: { asHideElementBlock },
  } = useContext(EditorContext);

  const applyTempChange = useDebouncedCallback(_applyTempChange, 1000);

  const editorId = useMemo(
    () =>
      getDeviceBasedEditorId(
        origChange?.block?.selector || selector,
        device,
        experienceCreationVersion,
      ),
    [selector, device, experienceCreationVersion],
  );

  const [change, setChange] = useComplexState<EditorDeclarativeBlock>(
    transpileVisualEditBlock(
      editorId,
      origChange?.block?.selector || selector,
      origChange?.visualEditSpecs || visualPropsToSpecs(visualProps),
      origChange?.initialVisualEditChange || visualPropsToSpecs(visualProps),
      experienceCreationVersion,
    ),
  );

  const {
    state: value,
    produceDispatch: setValue,
    canRedo,
    redo,
    canUndo,
    undo,
    undoRedoCount,
  } = useStateWithHistory<Record<string, CustomizationSpec>>(
    change?.visualEditSpecs || visualPropsToSpecs(visualProps),
    `VISUAL_${change.editorSelector}_history`,
  );

  const undoRedoContext = newUndoRedoContext(undoRedoCount);

  const [isDirty, setDirty] = useState(false);

  const onValueChanged = useCallback(() => {
    const { changes } = getEffectiveVisualEditChange(
      value,
      change?.initialVisualEditChange || visualPropsToSpecs(visualProps),
      experienceCreationVersion,
    );

    if (changes.length > 0) {
      setDirty(true);
      setChange((draft) => {
        (draft.block.value as VisualEditChange).changes = changes;
        draft.visualEditSpecs = value;
        draft.initialVisualEditChange =
          origChange?.initialVisualEditChange ||
          visualPropsToSpecs(visualProps);
      });
    }
  }, [value]);

  const debouncedOnValueChanged = useDebouncedCallback(onValueChanged, 50);

  useEffect(() => {
    debouncedOnValueChanged();
  }, [value]);

  useEffect(() => {
    applyTempChange(change);
  }, [change]);

  const getResponsiveValue = (
    custId: number,
    compId: number,
    specId: number,
    key: string,
    _device: DeviceType,
  ): any => {
    const specKey = formatKey(custId, compId, specId);
    let spec: CustomizationSpec;

    if (value?.[specKey]) {
      spec = value[specKey];
    } else {
      spec =
        visualProps.customizations[custId].components[compId].specs[specId];
    }

    return spec.values?.[key];
  };

  const setResponsiveValue = (
    custId: number,
    compId: number,
    specId: number,
    key: string,
    value: any,
    _device: DeviceType,
  ) => {
    setValue((draft) => {
      const specKey = formatKey(custId, compId, specId);
      let spec: CustomizationSpec;
      if (draft?.[specKey]) {
        spec = draft[specKey];
      } else {
        spec = JSON.parse(
          JSON.stringify(
            visualProps.customizations[custId].components[compId].specs[specId],
          ),
        );
      }
      spec.values[key] = value;
      draft[specKey] = spec;
    });
  };

  const onSave = () => {
    upsertEditorChange(change);
    leaveAnchor();
    gotoChangelog();
  };

  const onHide = () => {
    const delChange = asHideElementBlock(change?.block?.selector, device);
    removeEditorChange(change);
    upsertEditorChange(delChange);
    leaveAnchor();
  };

  useEffect(() => {
    anchor(change.editorSelector, AnchorOrigin.NEW_ELEMENT);
  }, []);

  return {
    change,
    initialValue: origChange?.visualEditSpecs,
    value,
    getResponsiveValue,
    setResponsiveValue,
    onSave,
    onHide,
    isDirty,
    canRedo,
    redo,
    canUndo,
    undo,
    undoRedoContext,
    device,
    gotoChangelog,
    visualProps,
  };
}
