import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { nav } from '@/utils/browser';
import { Pages } from '@/webapi/pages';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';
import { useIsPreviewingDesktop } from '@/features/editor/shared/use-device-type';
import { Flexbox } from '@/components/flex';
import { INSPECTOR_BACK_ID } from '@/features/editor/inspector';
import {
  Section,
  SectionTitle,
} from '@/features/editor/widgets/custom-widget/shared/shared-styles';
import { VSpace } from '@/components/spacing';
import {
  QuickEditSection,
  StylingSection,
} from '@/features/editor/widgets/custom-widget/blueprint-customize';
import { PostPurchaseOfferCount } from '@/features/editor/widgets/post-purchase/settings/offer-count';
import { PostPurchaseShowTimer } from '@/features/editor/widgets/post-purchase/settings/timer';
import { PostPurchaseReviews } from '@/features/editor/widgets/post-purchase/settings/reviews';
import { PostPurchaseSummaryMode } from '@/features/editor/widgets/post-purchase/settings/summary-mode';
import { PostPurchaseGallery } from '@/features/editor/widgets/post-purchase/settings/gallery';
import { PostPurchaseDiscountInput } from '@/features/editor/widgets/post-purchase/inputs/discount';
import { PostPurchaseHeaderInput } from '@/features/editor/widgets/post-purchase/inputs/header';
import { PostPurchaseSubHeaderInput } from '@/features/editor/widgets/post-purchase/inputs/sub-header';
import { TimerCountdownInput } from '@/features/editor/widgets/post-purchase/inputs/timer-countdown';
import { GlossyWrapper } from '@/components/glossy-wrapper';
import { BigButton } from '@/components/big-button';
import { PostPurchaseLoadingSection } from '@/features/editor/widgets/post-purchase/loading';
import { EditorRenameButton } from '@/features/editor/widgets/shared/layout/editor-rename-button';
import { AudienceButton } from '@/features/editor/widgets/shared/targeting-controls/audience-button';
import { CustomizeDesignBtn } from '@/features/editor/widgets/custom-widget/style-templating/customize-design-btn';
import { PostPurchaseTaxIncluded } from '@/features/editor/widgets/post-purchase/settings/tax-include';
import { DescriptionEditButton } from '@/features/editor/widgets/shared/layout/descr-rename-button';

export function PostPurchaseCustomize() {
  const { gotoStyle, gotoReview, props, onSave, hasChanges, isReviewEnabled } =
    useContext(PostPurchaseContext);
  const isDesk = useIsPreviewingDesktop();

  return (
    <Wrapper isDesk={isDesk}>
      <InspectorWidgetLayout
        progress={75}
        title={
          <>
            <EditorRenameButton />
            <DescriptionEditButton />
          </>
        }
        backCaption="< back to dashboard"
        onBackClicked={() => nav(Pages.DASHBOARD)}
        footer={
          <FooterSection>
            <BigButton
              disabled={!hasChanges}
              border="2px solid #C8CACB"
              background="white"
              color="#97A0A8"
              noShadow
              noTransform
              size={isDesk ? `medium-thin` : `medium`}
              fillWidth
              onClick={onSave}
            >
              Save
            </BigButton>
            <BigButton
              disabled={!isReviewEnabled}
              color="white"
              background="black"
              noTransform
              fillWidth
              size={isDesk ? `medium-thin` : `medium`}
              onClick={gotoReview}
            >
              Next
            </BigButton>
          </FooterSection>
        }
      >
        <Section>
          <AudienceButton />
          <br />
        </Section>
        <Section>
          <PostPurchaseOfferCount />
          <PostPurchaseShowTimer />
          <PostPurchaseReviews />
          <PostPurchaseSummaryMode />
          <PostPurchaseGallery />
          <PostPurchaseTaxIncluded />
        </Section>
        <VSpace value={3} />
        <SectionTitle>Quick Edit</SectionTitle>
        <StylingSection>
          <QuickEditSection>
            <PostPurchaseDiscountInput />
            <PostPurchaseHeaderInput />
            <PostPurchaseSubHeaderInput />
            {props.settings?.showTimer && <TimerCountdownInput />}
          </QuickEditSection>
        </StylingSection>
        <CustomizeDesignBtn onClick={gotoStyle} />
        <VSpace value={3} />
        {[...Array(props.settings.offersCount).keys()].map((offerId) => (
          <PostPurchaseLoadingSection key={offerId} offerNum={offerId} />
        ))}
        <VSpace value={7} />
      </InspectorWidgetLayout>
    </Wrapper>
  );
}

const Wrapper = styled(Flexbox)`
  && {
    height: 100%;
    width: ${(p: any) => (p.isDesk ? `100%` : `65%`)};

    #${INSPECTOR_BACK_ID} {
      z-index: 31;
    }
  }
`;

const FooterSection = styled(GlossyWrapper)`
  && {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 2rem;
    margin-left: -2rem;
    z-index: 40;
    margin-bottom: 2rem;
  }
`;
