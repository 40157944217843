/* eslint-disable react/destructuring-assignment */
import React, { FC } from 'react';
import styled from 'styled-components';
import { DefaultTypography, Heading4 } from '@/components/typography';
import { Flexbox } from '@/components/flex';

export const SectionTitle = styled(Heading4)`
  && {
    color: #59656f !important;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: -0.48px;
  }
`;

export const Section = styled(Flexbox)`
  width: 100%;
  margin-top: 1.3rem;
  flex-direction: column;

  && > * {
    width: 100%;
  }

  && > :not(:last-child) {
    margin-bottom: 1.3rem;
  }
`;

export const Caption = styled(DefaultTypography)`
  color: #95a2ae;
  font-family: 'JetBrains Mono', serif;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: -0.38px;
`;

export const SingleRowWrapper = styled.div`
  background-color: #ffffff;
  box-shadow: 0 18px 26px 0 rgba(177, 217, 203, 0.18);
  padding: 0.1rem 1.8rem;
  border-radius: 10px;

  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: stretch;
`;

export const MaybeFiltered: FC<any & { isVisible: boolean }> = (props) => {
  if (props.isVisible) {
    return <div {...props}>{props.children}</div>;
  }
  return null;
};
