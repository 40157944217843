import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import {
  LayeredRulingContext,
  RuleState,
} from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import { useConditionalQueryBuilder } from '@/features/editor/widgets/custom-widget/loading-section/util/useQueryBuilder';
import { QueryBuilder } from '@/components/query-builder';
import { VSpace } from '@/components/spacing';
import { RecommendationType } from '@/webapi/use-widget-catalog-api';

export const ConditionBuilder: FC<any> = () => {
  const ctx = useContext(LayeredRulingContext);

  const activeRule = ctx.state.rules.find((r) => r.isOpen);
  if (!ctx.state.showCondBuilder || !activeRule) {
    return null;
  }

  if (activeRule.strategy === RecommendationType.MANUAL) {
    return (
      <Container>
        <VSpace value={3} />
        <SlotCondQueryBuilder activeRule={activeRule} />
        <VSpace value={3} />
      </Container>
    );
  }

  return (
    <Container>
      <VSpace value={3} />
      <RuleStrategyQueryBuilder activeRule={activeRule} />
      <VSpace value={3} />
    </Container>
  );
};

const Container = styled.div`
  min-width: 80rem;
`;

function SlotCondQueryBuilder({ activeRule }: { activeRule: RuleState }) {
  const ctx = useContext(LayeredRulingContext);
  const activeSlot = activeRule?.strategyPerSlot.slots.find(
    (s) => s.num === ctx.state.activeCondSlot,
  );
  const { condition } = useConditionalQueryBuilder(activeSlot.strategy);

  const onChange = (filter) =>
    ctx.changeSlot(activeRule.id, activeSlot.num, (s) => {
      s.filter = filter;
    });

  return (
    <QueryBuilder
      onChange={onChange}
      initialProps={condition}
      initialState={activeSlot.filter}
    />
  );
}

function RuleStrategyQueryBuilder({ activeRule }: { activeRule: RuleState }) {
  const ctx = useContext(LayeredRulingContext);

  const strategyType = activeRule?.strategy;
  const { condition } = useConditionalQueryBuilder(strategyType);
  const onChange = (x) =>
    ctx.change(activeRule.id, (r) => {
      r.strategyCond = x;
    });

  return (
    <QueryBuilder
      onChange={onChange}
      initialProps={condition}
      initialState={activeRule.strategyCond}
    />
  );
}
