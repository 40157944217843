export enum ExperienceCreationVersion {
  LEGACY = 0,
  SPLITTING_VISUAL_EDITS = 1,
  HIDING_OLD_PAGE_URL_TARGETING = 2,
  VISUAL_EDIT_TEXT_NODE_FIX = 3,
}

export function isVisualEditTextNodeFixSupported(
  version: ExperienceCreationVersion,
) {
  return version >= ExperienceCreationVersion.VISUAL_EDIT_TEXT_NODE_FIX;
}

export function isSplittingVisualEditsSupported(
  version: ExperienceCreationVersion,
) {
  return version >= ExperienceCreationVersion.SPLITTING_VISUAL_EDITS;
}

export function isHidingTheOldPageUrlTargetingSupported(
  version: ExperienceCreationVersion,
) {
  return version >= ExperienceCreationVersion.HIDING_OLD_PAGE_URL_TARGETING;
}
