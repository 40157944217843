import { useContext, useEffect, useState } from 'react';
import produce from 'immer';
import {
  Experience,
  UNNAMED_EXPERIENCE,
  useExperienceApi,
} from '@/webapi/use-experience-api';
import { isBrowser, nav } from '@/utils/browser';
import { ExperienceStateHook } from '@/features/editor/context/use-experience-state';
import { Pages } from '@/webapi/pages';
import {
  centered,
  UseSharedElement,
  useSharedElement,
} from '@/components/use-shared-element';
import { InspectorNavigationHook } from '@/features/editor/context/use-inspector-navigation';
import { ExperienceNameOrigin } from '@/features/editor/widgets/name';
import { DeviceType } from '@/utils/definitions';
import {
  EDITOR_LAST_SAVED_EXPERIENCE_CACHE,
  removeExperienceFromCache,
} from '@/components/hooks/use-cached-auto-save';
import { LS_FILTER_KEY, LS_SEARCH_KEY } from '@/utils/consts';
import { AccountContext } from '@/features/account-context';
import { StoreStatsKind } from '@/webapi/use-store-stats';
import { StyleTemplateType } from '@/features/editor/widgets/custom-widget/style-templating/models';
import { CheckoutWidgetChange } from '@/pkg/sdk';

export function useExperienceControls(
  experienceState: ExperienceStateHook,
  inspectorNav: InspectorNavigationHook,
  device: DeviceType,
): UseExperienceControls {
  const { trackIfStoreGoalNotReached } = useContext(AccountContext);
  const { currentExperience: experience, setAfterUpsert } = experienceState;
  const [latest, setLatest] = useState(serialize(experience));
  const [lastSaved, setLastSaved] = useState(undefined);
  const { gotoExperienceName } = inspectorNav;
  const {
    autoSaveStyles,
    upsertExperience,
    deleteExperience,
    publishExperience,
    generatePreviewLink,
    sharePreviewLink,
    loading,
  } = useExperienceApi(device, experience);

  useEffect(() => {
    EDITOR_LAST_SAVED_EXPERIENCE_CACHE?.get(experience.id).then(
      (cachedLastSaved) => {
        if (!cachedLastSaved) {
          const str = serialize(experience);
          EDITOR_LAST_SAVED_EXPERIENCE_CACHE.set(experience.id, {
            value: str,
          }).then();
          setLastSaved(str);
        } else {
          setLastSaved(cachedLastSaved?.value);
        }
      },
    );
  }, []);

  useEffect(() => {
    setLatest(serialize(experience));
  }, [experience]);

  const hasUnsavedChanged = (): boolean => lastSaved !== latest;

  const isNewAndUnsaved = (): boolean => experience.name === UNNAMED_EXPERIENCE;

  const save = async (
    origin: ExperienceNameOrigin = ExperienceNameOrigin.SAVE,
  ) => {
    if (isNewAndUnsaved()) {
      gotoExperienceName(origin);
    } else {
      await forceSave(``, experience.description);
    }
  };

  const forceSave = async (newName?: string, description?: string) => {
    trackIfStoreGoalNotReached(StoreStatsKind.HAS_SAVED_ONE_EXPERIENCE).then();
    const toBeSaved = produce(experience, (draft) => {
      if (newName && newName !== ``) {
        draft.name = newName;
      }
      if (description) {
        draft.description = description;
      }

      draft.variants?.forEach((variant) => {
        variant?.changes?.forEach((change) => {
          if (change.block.kind === `checkoutWidget`) {
            if ((change.block.value as CheckoutWidgetChange)?.props?.products) {
              (change.block.value as CheckoutWidgetChange).props.products = [];
            }
          }
        });
      });
    });
    const newExp = await upsertExperience(toBeSaved);
    const latestExp = setAfterUpsert(newExp);
    const latestExpStr = serialize(latestExp);
    setLatest(latestExpStr);
    setLastSaved(latestExpStr);
    await EDITOR_LAST_SAVED_EXPERIENCE_CACHE.set(experience.id, {
      value: latestExpStr,
    });

    autoSaveStyles(newExp, StyleTemplateType.EDIT_LATEST).then();
  };

  const publish = async (resetStats?: boolean) => {
    sessionStorage.removeItem(LS_SEARCH_KEY);
    sessionStorage.removeItem(LS_FILTER_KEY);
    await save(ExperienceNameOrigin.PUBLISH);
    await publishExperience(experience.id, resetStats);
  };

  const sharePreview = async (
    email: string,
    sharedBy: string,
    quickPreviewUrl = experience?.quickPreviewUrl,
  ) => {
    try {
      await save(ExperienceNameOrigin.PREVIEW);
      await sharePreviewLink({
        email,
        sharedBy,
        options: {
          experienceId: experience.id,
          variantId: experience.variants?.[0]?.id,
          quickPreviewUrl,
        },
      });
    } catch (ex) {
      console.error(ex);
    }
  };
  const preview = async (
    quickPreviewUrl?: string,
    variantOverride?: string,
  ) => {
    const resp = await generatePreviewLink(
      experience.id,
      experience.variants?.[0]?.id,
      quickPreviewUrl || experience?.quickPreviewUrl,
      variantOverride || experience.activeVariantId,
      experience,
    );
    return resp?.link;
  };

  const onCloseEditorPageClicked = () => {
    if (hasUnsavedChanged() || isNewAndUnsaved()) {
      sharedElement.show();
    } else {
      closeAndNavigateToDashboard();
    }
  };

  const closeAndNavigateToDashboard = async () => {
    if (isNewAndUnsaved()) {
      await deleteExperience(experience.id);
    }
    isBrowser() && (await removeExperienceFromCache(experience.id));
    await nav(Pages.DASHBOARD);
  };

  const sharedElement = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `#dedede`,
        opacity: `0`,
      },
      extraTo: {
        background: `white`,
        opacity: `1`,
      },
    },
    undefined,
    () => centered(18, 42),
  );

  return {
    loading,
    hasUnsavedChanged,
    isNewAndUnsaved,
    save,
    preview,
    sharePreview,
    publish,
    onCloseEditorPageClicked,
    closeAndNavigateToDashboard,
    forceSave,
    sharedElement,
  };
}

export interface UseExperienceControls {
  loading: boolean;
  hasUnsavedChanged: () => boolean;
  isNewAndUnsaved: () => boolean;
  save: (origin?: ExperienceNameOrigin) => Promise<void>;
  forceSave: (newName?: string, description?: string) => Promise<void>;
  preview: (url?: string, variant?: string) => Promise<string>;
  sharePreview: (
    email: string,
    sharedBy: string,
    quickPreviewUrl?: string,
  ) => Promise<void>;
  publish: (resetStats?: boolean) => Promise<void>;
  onCloseEditorPageClicked: () => void;
  closeAndNavigateToDashboard: () => void;
  sharedElement: UseSharedElement;
}

function serialize(experience: Experience) {
  return JSON.stringify(experience, removeUntrackedFields);
}

export function removeUntrackedFields(k: string, v: any): any {
  if (k === `quickPreviewPlacement`) return undefined;
  if (k === `quickPreviewDevice`) return undefined;
  return v;
}
