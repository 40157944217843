import { CachePolicies, useFetch } from 'use-http';
import { useContext } from 'react';
import { routes } from '@/webapi/routes';
import { AccountContext } from '@/features/account-context';

export type IsPostPurchaseEnabledResp = {
  isPostPurchaseEnabled: boolean;
};

export type AppBlockStatus = {
  deepLink: string;
  isAppBlockEnabled: boolean;
  legacy_sdk_installed?: boolean;
};

export interface Theme {
  id: number;
  name: string;
  role: string;
  previewable: boolean;
  updated_at: string;
}

export interface AntiFlickerConfig {
  enabled: boolean;
  selectors: string[];
  revealTimeoutMs: number;
  revealDelayMs: number;
  enableAnimation: boolean;
}

export function useShopifyApi(initialLoading?: boolean, storeAlias?: string) {
  const accountCtx = useContext(AccountContext);
  const alias = storeAlias || accountCtx?.account?.store?.alias;
  const { get, post, loading, response } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: initialLoading,
    },
  }));

  async function getPostPurchaseStatus(noCache?: boolean): Promise<boolean> {
    try {
      const promise: IsPostPurchaseEnabledResp = await get(
        `${routes.isPostPurchaseEnabled(alias)}${noCache ? `&check=t` : ``}`,
      );
      return promise.isPostPurchaseEnabled;
    } catch (e) {
      return false;
    }
  }

  async function getAntiFlickerConfig(): Promise<AntiFlickerConfig> {
    return get(`${routes.getAntiFlickerState()}`);
  }

  async function setAntiFlickerConfig(
    config: AntiFlickerConfig,
  ): Promise<AntiFlickerConfig> {
    console.log(`mmm`, config);
    return post(`${routes.setAntiFlickerState()}`, config);
  }

  async function isAppEmbedEnabled(themeId?: number): Promise<AppBlockStatus> {
    try {
      return await get(`${routes.isABlockEnabled(alias, themeId)}`);
    } catch (e) {
      return { isAppBlockEnabled: false, deepLink: `` };
    }
  }

  async function listThemes(): Promise<Array<Theme>> {
    return ((await get(routes.listThemes(alias))) || []) as Array<Theme>;
  }
  async function themesStatus(
    themes: Array<number>,
  ): Promise<Map<number, boolean>> {
    const ret =
      (await get(
        `${routes.themesStatus}?themes=${encodeURIComponent(themes.join())}`,
      )) || {};
    const m = new Map<number, boolean>();
    Object.keys(ret).forEach((k) => {
      m.set(parseInt(k, 10), ret[k]);
    });
    return m;
  }

  async function injectSdk(themeID?: string) {
    return get(routes.injectSDK(themeID));
  }

  async function removeSDK(themeID?: string) {
    return get(routes.removeSDK(themeID));
  }

  async function removeSDKFromThemes(themeIds?: Array<number>) {
    return get(
      `${routes.removeSDK()}?themeIds=${encodeURIComponent(themeIds.join())}`,
    );
  }

  function cleanPreviewCache() {
    return post(routes.clearCache, {});
  }

  return {
    removeSDKFromThemes,
    removeSDK,
    injectSdk,
    themesStatus,
    loading,
    listThemes,
    getPostPurchaseStatus,
    isAppEmbedEnabled,
    response,
    cleanPreviewCache,
    getAntiFlickerConfig,
    setAntiFlickerConfig,
  };
}
