import styled from 'styled-components';
import React, { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TextInput } from '@/components/text-input';
import { ProductsGrid } from '@/components/catalog-search/products-grid';
import { Flexbox } from '@/components/flex';
import { useCatalogSearch } from '@/components/catalog-search/use-catalog-search';
import { Product } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/context/model';

interface P {
  h: number;
}

const Container = styled.div`
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5rem ${({ h }: P) => h - 220}px;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
`;

const SearchTextInput = styled(TextInput)`
  && {
    margin: 0;
    min-width: 25rem;
    width: 85%;
    text-align: start;
    border-radius: 2rem;
    border: 1.5px solid #e8e8ea;
    font-size: 1.4rem;
    font-family: 'JetBrains Mono', serif;
    box-shadow: none;
    padding: 1rem 2rem;
  }
`;

const PhotosGrid = styled.div`
  height: 100%;
  margin-top: 2rem;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;

  overflow: auto;
`;

export const CatalogSearch: FC<{
  columnsCount?: number;
  getModalRect: () => DOMRect;
  onSelected?: (p: Product) => void;
}> = ({ onSelected, getModalRect, columnsCount = 3 }) => {
  const { searchText, setSearchText, products, hasMore, loadMore } =
    useCatalogSearch();
  return (
    <Container h={getModalRect().height}>
      <Flexbox align="center" justify="center">
        <SearchTextInput
          placeholder="Search for products"
          value={searchText}
          onChange={(ev) => {
            setSearchText(ev.target.value);
          }}
        />
      </Flexbox>
      <PhotosGrid id="scrollableCatalog">
        <InfiniteScroll
          dataLength={products?.length || 0}
          next={() => {
            loadMore();
          }}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableCatalog"
        >
          <ProductsGrid
            columnsCount={columnsCount}
            products={products}
            onSelected={onSelected}
          />
        </InfiniteScroll>
      </PhotosGrid>
    </Container>
  );
};
