import produce from 'immer';
import { CatalogApp } from '@/webapi/use-widget-catalog-api';
import { InputType } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { StoreStyles } from '@/features/editor/widgets/custom-widget/style-templating/models';
import { applyStoreStyle } from '@/features/editor/widgets/custom-widget/shared/store-style-utils';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';

export function assignMostPopularFontAndStoreStyles(
  app: CatalogApp,
  mostPopularFont?: string,
  storeStyle?: StoreStyles,
): CatalogApp {
  if (!mostPopularFont) return app;
  const isDefaultStyleEnabled = useFeatureBit(FeatureBit.DEFAULT_STORE_STYLE);
  const out = produce(app, (draft) => {
    draft?.widgets?.forEach((widget, widgetId) => {
      if (
        isDefaultStyleEnabled &&
        !!storeStyle &&
        widget.blueprint?.schema?.customizations?.length > 0
      ) {
        const customizations = applyStoreStyle(
          widget.blueprint?.schema?.customizations,
          storeStyle,
        );
        if (customizations) {
          widget.blueprint.schema.customizations = customizations;
        }
      }

      widget.blueprint?.schema?.customizations?.forEach((cust, custId) => {
        cust.components?.forEach((comp, compId) => {
          comp?.specs?.forEach((spec, specId) => {
            if (spec.type === InputType.TYPOGRAPHY) {
              const newSpec =
                draft.widgets[widgetId].blueprint.schema.customizations[custId]
                  .components[compId].specs[specId];
              newSpec.values[`fontFamily`] = mostPopularFont;
              if (newSpec.values[`desktop`]?.[`fontFamily`]) {
                newSpec.values[`desktop`][`fontFamily`] = mostPopularFont;
              }
              if (newSpec?.values?.desktop && newSpec?.values?.desktop?.hover) {
                newSpec.values.desktop.hover[`fontFamily`] = mostPopularFont;
              }
            }
          });
        });
      });
    });
  });

  return out;
}
