import React from 'react';
import {
  CodeEditInputSpec,
  ImageInputSpec,
  InputComponent,
  InputCustomization,
  InputSpec,
  SingleSelectInputSpec,
  TextEditInputSpec,
} from '@/webapi/checkout-widget-props';
import { CheckoutInputSingleSelect } from '@/features/editor/widgets/checkout-extensibility/inputs/single-select-input';
import { CheckoutInputEditText } from '@/features/editor/widgets/checkout-extensibility/inputs/edit-text-input';
import { ImageUploadInput } from '@/features/editor/widgets/checkout-extensibility/inputs/image-upload-input';
import { CheckoutInputCodeEdit } from '@/features/editor/widgets/checkout-extensibility/inputs/code-edit-input';

interface CheckoutGenericInputProps {
  cust: InputCustomization;
  custId: number;
  comp: InputComponent;
  compId: number;
  spec: InputSpec;
  specId: number;
}

export function CheckoutGenericInput({
  custId,
  cust,
  compId,
  comp,
  spec,
  specId,
}: CheckoutGenericInputProps) {
  return (
    <>
      {spec?.type === `single-select` && (
        <CheckoutInputSingleSelect
          custId={custId}
          cust={cust}
          comp={comp}
          compId={compId}
          specId={specId}
          spec={spec as SingleSelectInputSpec}
        />
      )}
      {spec?.type === `text-edit` && (
        <CheckoutInputEditText
          custId={custId}
          cust={cust}
          comp={comp}
          compId={compId}
          specId={specId}
          spec={spec as TextEditInputSpec}
        />
      )}
      {spec?.type === `image-upload` && (
        <ImageUploadInput
          custId={custId}
          cust={cust}
          comp={comp}
          compId={compId}
          specId={specId}
          spec={spec as ImageInputSpec}
        />
      )}
      {spec?.type === `code-edit` && (
        <CheckoutInputCodeEdit
          custId={custId}
          cust={cust}
          comp={comp}
          compId={compId}
          specId={specId}
          spec={spec as CodeEditInputSpec}
        />
      )}
    </>
  );
}
