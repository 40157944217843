import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  QBItemProp,
  QBItemSelection,
  QBType,
} from '@/components/query-builder/models';
import { QueryBuilder } from '@/components/query-builder';
import { AdvancedRuling } from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/context';
import { ItemSelection } from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/condition/ItemSelection';
import {
  isProductPageRule,
  productPageGroup,
  transformCategories,
  transformProducts,
  transformProductsWithIds,
  transformTags,
  uniqueList,
} from '@/features/editor/widgets/custom-widget/loading-section/util/useQueryBuilder';
import { EditorContext } from '@/features/editor/context/editor-context';
import { PlacementKind } from '@/webapi/use-experience-api';
import { SelectOptions } from '@/components/small-select';
import { NumberValueOps } from '@/components/query-builder/numeric-value';

type Props = { num: number };

export function RuleCondition({ num }: Props) {
  const { condition } = useLegacyDeprecatedQueryBuilder();
  const { getCondition, setCondition, isOneLineCond, inSearchMode } =
    useContext(AdvancedRuling);
  const current = getCondition(num);
  const onQueryBuilderChange = (value: Array<QBItemSelection>) => {
    setCondition(num, value, current.itemSelection);
  };
  const setItemSelection = (value: string) => {
    setCondition(num, current.cond, value);
  };

  const hideItemSelection = isOneLineCond(num) || isProductPageRule(current);
  return (
    <QbWrap hidden={inSearchMode}>
      <div style={{ margin: `2rem 0` }}>
        <QueryBuilder
          whereNotion="IF"
          disableAndBtn
          textCondNoMarginTop
          selectMaxWidth={250}
          onChange={onQueryBuilderChange}
          initialProps={condition}
          initialState={current.cond}
        />
      </div>
      <ItemSelection
        hideItemSelection={hideItemSelection}
        onSelection={setItemSelection}
        current={current}
      />
    </QbWrap>
  );
}

const QbWrap = styled.div`
  display: ${(p: { hidden: boolean }) => (p.hidden ? `none` : `flex`)};
  justify-content: flex-start;
  align-items: start;
  flex-direction: row;
  max-height: 100px;
  margin: 0 2rem;
`;

// DEPRECATED - ONLY OLD WIDGETS WITH LEGACY ADVANCED LOGIC COND
export function useLegacyDeprecatedQueryBuilder(isPostPurchase?: boolean) {
  const {
    resources,
    experienceState: {
      currentExperience: { quickPreviewPlacement },
    },
  } = useContext(EditorContext);
  const uniqProducts = uniqueList(resources.products);
  const products = transformProducts(uniqProducts);
  const productsById = transformProductsWithIds(uniqProducts);
  const categories = transformCategories(resources);
  if (quickPreviewPlacement === PlacementKind.ALL_PRODUCTS) {
    categories.splice(1, 0, {
      key: `product_collections`,
      value: `Viewed Item Collections`,
    } as SelectOptions);
  }

  const condition: QBItemProp[] = [
    {
      envKey: `tag`,
      group: `For Cart / Checkout`,
      kind: QBType.ARRAY_VALUE,
      caption: `Items in the cart with tag`,
      disableOrCond: true,
      options: resources?.tags?.map(
        (tag) =>
          ({
            key: tag,
            value: tag,
          } as SelectOptions),
      ),
    },
    {
      envKey: `category`,
      group: `For Cart / Checkout`,
      kind: QBType.MULTI_VALUE,
      disableOrCond: true,
      caption: `Items in the cart from collection`,
      options: categories,
    },
    {
      envKey: `cartPrice`,
      group: `For Cart / Checkout`,
      kind: QBType.NUMERIC_VALUE,
      caption: `Cart Total`,
      hasCurrencyCode: true,
      disableOrCond: true,
      defaultNumericValueOp: NumberValueOps.GT,
    },
    {
      envKey: `cartSize`,
      group: `For Cart / Checkout`,
      kind: QBType.NUMERIC_VALUE,
      caption: `Cart Size`,
      disableOrCond: true,
      defaultNumericValueOp: NumberValueOps.GT,
    },
    {
      kind: QBType.MULTI_VALUE,
      caption: `Specific item in the cart`,
      options: products,
      envKey: `item`,
      disableOrCond: true,
      group: `For Cart / Checkout`,
    },
    ...(!isPostPurchase
      ? [
          {
            kind: QBType.ARRAY_VALUE,
            caption: `Seen Product`,
            options: productsById,
            envKey: `product_seen`,
            disableOrCond: true,
            group: productPageGroup,
          },
          {
            kind: QBType.ARRAY_VALUE,
            caption: `Product from collection`,
            options: categories,
            envKey: `product_from_collection`,
            disableOrCond: true,
            group: productPageGroup,
          },
          {
            kind: QBType.ARRAY_VALUE,
            caption: `Product with tag`,
            options: transformTags(resources) || [],
            envKey: `product_with_tag`,
            disableOrCond: true,
            group: productPageGroup,
          },
        ]
      : []),
  ];

  return { condition };
}
