import React, { useContext } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import styled from 'styled-components';
import { StrategyPerSlotContext } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/context/context';

export function CondHeader() {
  const ctx = useContext(StrategyPerSlotContext);
  return (
    <CondHeaderStyled>
      <Back type="button" onClick={() => ctx.setProductCondSlotOpen(undefined)}>
        <IoIosArrowBack color="grey" size={40} />
      </Back>
      <p>More Filters</p>
    </CondHeaderStyled>
  );
}

const CondHeaderStyled = styled.div`
  position: relative;
  width: 100%;

  p {
    font-size: 3rem;
    margin: -2.5rem 0 1rem 0;
  }
`;
const Back = styled.button`
  &:hover {
    cursor: pointer;
    opacity: 0.3;
  }
  &:active {
    cursor: pointer;
    opacity: 0.5;
  }
  opacity: 0.5;
  left: 20px;
  position: absolute;
  top: -30px;
  color: #d2d4d7;
`;
