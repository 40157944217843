import { RecommendationType } from '@/webapi/use-widget-catalog-api';
import { QBItemSelection, QBType } from '@/components/query-builder/models';
import { NumberValueOps } from '@/components/query-builder/numeric-value';

export function defaultCondition(s?: RecommendationType, appId?: string) {
  const isUpsell = appId === `upsells`;
  if (s === RecommendationType.ADVANCED_RULING) {
    return [];
  }
  if (s === RecommendationType.PAST_PURCHASES) {
    return [
      {
        qbProps: {
          kind: QBType.ARRAY_VALUE,
          caption: `PURCHASED ITEM`,
          hasTimeframe: true,
          disableOrCond: true,
          options: [
            {
              key: `True`,
              value: `True`,
            },
          ],
          envKey: `PAST_PURCHASES`,
        },
        values: [
          {
            op: `is`,
            value: `True`,
          },
        ],
        timeframe: {
          value: 7,
          unit: `DAYS`,
        },
      },
      {
        qbProps: {
          envKey: `inventory`,
          kind: QBType.NUMERIC_VALUE,
          caption: `Inventory`,
          disableOrCond: true,
          defaultNumericValue: 0,
          defaultNumericValueOp: NumberValueOps.GT,
        },
        values: [
          {
            op: `gt`,
            value: `0`,
          },
        ],
      },
    ] as Array<QBItemSelection>;
  }
  if (s === RecommendationType.CART_ITEMS) {
    return [
      {
        qbProps: {
          kind: QBType.ARRAY_VALUE,
          caption: `ITEM ADDED TO CART`,
          hasTimeframe: true,
          disableOrCond: true,
          options: [
            {
              key: `True`,
              value: `True`,
            },
          ],
          envKey: RecommendationType.CART_ITEMS,
        },
        values: [
          {
            op: `is`,
            value: `True`,
          },
        ],
        timeframe: {
          value: 30,
          unit: `DAYS`,
        },
      },
      {
        qbProps: {
          kind: QBType.ARRAY_VALUE,
          caption: `ITEM IS STILL IN THE CART`,
          hasTimeframe: false,
          disableOrCond: true,
          options: [
            {
              key: `True`,
              value: `True`,
            },
            {
              key: `False`,
              value: `False`,
            },
          ],
          envKey: RecommendationType.CART_ITEMS,
        },
        values: [
          {
            op: `is`,
            value: `True`,
          },
        ],
      },
      {
        qbProps: {
          envKey: `inventory`,
          kind: QBType.NUMERIC_VALUE,
          caption: `Inventory`,
          disableOrCond: true,
          defaultNumericValue: 0,
          defaultNumericValueOp: NumberValueOps.GT,
        },
        values: [
          {
            op: `gt`,
            value: `0`,
          },
        ],
      },
    ] as Array<QBItemSelection>;
  }
  return [
    {
      qbProps: {
        envKey: `inventory`,
        kind: QBType.NUMERIC_VALUE,
        caption: `Inventory`,
        disableOrCond: true,
        defaultNumericValue: 0,
        defaultNumericValueOp: NumberValueOps.GT,
      },
      values: [
        {
          op: `gt`,
          value: `0`,
        },
      ],
    },
    ...(isUpsell
      ? [
          {
            qbProps: {
              envKey: `includeCartItems`,
              kind: QBType.MULTI_VALUE,
              caption: `Include Cart Items`,
              disableOrCond: true,
              disableOp: true,
              options: [
                { key: `no`, value: `no` },
                { key: `yes`, value: `yes` },
              ],
            },
            values: [
              {
                op: `is`,
                value: `no`,
              },
            ],
          },
        ]
      : []),
  ];
}
