import React from 'react';
import { centered, useSharedElement } from '@/components/use-shared-element';
import {
  SharedElementOverlay,
  SharedElementOverlayProps,
} from '@/components/shared-element-overlay';
import { RulesList } from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/RulesList';

export function useAdvancedRulingModalProps() {
  const { props, show, hide, fromRef } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `#dedede`,
        opacity: 0,
      },
      extraTo: {
        background: `white`,
        opacity: 1,
      },
    },
    undefined,
    () => centered(75, 115),
  );
  return {
    props,
    show,
    hide,
    fromRef,
  };
}

interface Props extends SharedElementOverlayProps {
  hide: () => void;
}

export function AdvancedProductRulingPicker(props: Props) {
  const { hide, ...rest } = props;

  return (
    <SharedElementOverlay {...rest}>
      <RulesList hide={hide} />
    </SharedElementOverlay>
  );
}
