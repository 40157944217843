import { useContext, useState } from 'react';
import { EditorContext } from '@/features/editor/context/editor-context';
import {
  KlavioEvent,
  KlavioStep,
  Trigger,
  TriggerKind,
} from '@/webapi/use-experience-api';
import { centered } from '@/components/use-shared-element';
import { conditional } from '@/utils/conditional';

export enum TriggerView {
  'SELECT',
  'KLAVIO',
  'JAVASCRIPT_FUNCTION',
}

export function useTriggersModal(setIsVisible: (value: boolean) => void) {
  const {
    experienceState: { currentExperience, setTargetingTrigger },
  } = useContext(EditorContext);

  const toRect = centered(70, 80);
  const { trigger } = currentExperience;
  const [selected, setSelected] = useState(trigger);
  const [view, setView] = useState(
    conditional(
      TriggerView.SELECT,
      trigger?.kind === TriggerKind.KLAVIO_EVENT,
      TriggerView.KLAVIO,
      trigger?.kind === TriggerKind.JAVASCRIPT_FUNCTION,
      TriggerView.JAVASCRIPT_FUNCTION,
    ),
  );
  const [event, setEvent] = useState(
    trigger?.event?.meta?.type || KlavioEvent.SUBMIT,
  );
  const [step, setStep] = useState(
    trigger?.event?.meta?.step || KlavioStep.EMAIL_SMS,
  );

  const onSelection = (value: boolean, trigger: Trigger) => {
    if (trigger.kind === TriggerKind.KLAVIO_EVENT) {
      setView(TriggerView.KLAVIO);
    } else if (trigger.kind === TriggerKind.JAVASCRIPT_FUNCTION) {
      setView(TriggerView.JAVASCRIPT_FUNCTION);
    }
    if (!value) {
      setSelected(trigger);
    }
  };

  const onSave = () => {
    if (view === TriggerView.KLAVIO) {
      setTargetingTrigger({
        kind: TriggerKind.KLAVIO_EVENT,
        event: {
          name: `klaviyoForms`,
          meta: {
            type: event,
            step,
          },
          matchCode: getStepCode(step, event),
        },
      });
    } else {
      setTargetingTrigger(selected);
    }

    setIsVisible(false);
  };

  const onTimeoutChanged = (ev) => {
    setSelected((old) => ({
      ...old,
      timeoutMillis: Number.parseInt(ev.target.value, 10) * 1000,
    }));
  };

  const onElementSelectorChanged = (ev) => {
    setSelected((old) => ({
      ...old,
      selector: ev.target.value,
    }));
  };

  const onEventOnceChanged = (state: boolean) => {
    setSelected((old) => ({
      ...old,
      elementEvent: {
        ...old.elementEvent,
        once: !state,
      },
    }));
  };

  const onCodeChanged = (code: string) => {
    setSelected((old) => ({
      ...old,
      code,
    }));
  };

  return {
    selected,
    onSelection,
    onSave,
    onTimeoutChanged,
    onElementSelectorChanged,
    onCodeChanged,
    toRect,
    view,
    event,
    setEvent,
    step,
    setStep,
    setView,
    onEventOnceChanged,
  };
}

function getStepCode(step: KlavioStep, event: KlavioEvent) {
  let code = ``;
  if (event === KlavioEvent.STEP_SUBMIT) {
    switch (step) {
      case KlavioStep.EMAIL:
        code = `&&!!e.metaData.$email`;
        break;
      case KlavioStep.EMAIL_SMS:
        code = `&&!!e.metaData.$phone_number&&!!e.metaData.$email`;
        break;
      case KlavioStep.SMS:
        code = `&&!!e.metaData.$phone_number`;
        break;
      default:
        code = ``;
    }
  }
  return `e=arguments[0].detail;return e.type==="${event}"${code};`;
}
