import { useMemo } from 'react';
import { CheckoutExtensibilityCtx } from '@/features/editor/widgets/checkout-extensibility/context';
import { CustomWidgetContextProps } from '@/features/editor/widgets/custom-widget/shared/context';
import {
  CatalogWidgetProps,
  RecommendationType,
} from '@/webapi/use-widget-catalog-api';
import { CHECKOUT_SUPPORTED_UPSELL_STRATEGIES } from '@/features/editor/widgets/checkout-extensibility/loading/checkout-supported-strategies';

export function defaultRecsStrategy() {
  return (
    CHECKOUT_SUPPORTED_UPSELL_STRATEGIES?.find(
      (s) => s?.value?.type === RecommendationType.MOST_POPULAR,
    ) || CHECKOUT_SUPPORTED_UPSELL_STRATEGIES?.[0]
  );
}

export function newCustomWidgetMockContext(
  ctx: CheckoutExtensibilityCtx,
): CustomWidgetContextProps {
  const { currentProps } = ctx;

  const currentSchema = useMemo<CatalogWidgetProps>(
    () => ({
      appId: `upsells`,
      customizations: [],
      settings: {
        quickEdit: [],
        general: {
          title: ``,
          key: `general`,
          specs: [
            {
              key: `recsCount`,
              type: `select`,
              value: { value: 4, label: `4 Products` },
              metadata: {},
            },
          ],
        },
        loading: {
          value: currentProps?.loadingValue || defaultRecsStrategy(),
          loadingEnv: currentProps?.loadingEnv,
          options: CHECKOUT_SUPPORTED_UPSELL_STRATEGIES,
          title: ``,
        },
      },
    }),
    [currentProps],
  );

  return {
    currentSchema,
    updateRecommendationOptions: ctx.updateRecommendationOptions,
    updateRecommendationCondition: ctx.updateRecommendationCondition,
    updateAdvancedRulingOptions: ctx.updateAdvancedRulingOptions,
    updateLoadingCfg: ctx.updateLoadingCfg,
  } as CustomWidgetContextProps;
}
