import React, { useContext, useEffect } from 'react';
import { LoadingStrategyOption } from '@/webapi/use-widget-catalog-api';
import { LayeredRulingContext } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';

export function useAutoScroll(
  options: LoadingStrategyOption[],
  scrollRef: React.MutableRefObject<any>,
) {
  const ctx = useContext(LayeredRulingContext);
  const rule = ctx.state.rules.find((r) => r.isOpen);

  useEffect(() => {
    if (ctx.isModalOpen && !!rule) {
      if (
        !ctx.isProductSearchOpen ||
        !ctx.isManualImageSelectionOpen ||
        !ctx.state.activeCondSlot
      ) {
        const strategyIdx = options.findIndex(
          (s) => s.value.type === rule.strategy,
        );
        if (strategyIdx > 2) {
          setTimeout(() => {
            scrollRef?.current?.scrollBy({
              left: 265 * strategyIdx,
              top: 0,
              behavior: `smooth`,
            });
            ctx.autoScrollDelayMs.current = 100;
          }, ctx.autoScrollDelayMs.current);
        }
      }
    }
  }, [
    ctx.isProductSearchOpen,
    ctx.isManualImageSelectionOpen,
    ctx.state.showCondBuilder,
    ctx.isModalOpen,
    rule?.isOpen,
  ]);
}
