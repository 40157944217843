import React, { useContext } from 'react';
import { StrategyPerSlotContext } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/context/context';
import { LoadingStrategyOption } from '@/webapi/use-widget-catalog-api';
import { Slot } from '@/features/editor/widgets/custom-widget/loading-section/shared/slot';

export function SlotsList() {
  const ctx = useContext(StrategyPerSlotContext);
  if (
    ctx.isProductSearchOpen ||
    ctx.isProductCondOpen ||
    ctx.isManualImageSelectionOpen
  ) {
    return null;
  }
  return (
    <>
      {ctx.state.slots.map((slot) => {
        const onProductSelect = () => ctx.setProductSearchSlotOpen(slot.num);
        const onStrategySelect = (value: LoadingStrategyOption) => () =>
          ctx.selectStrategyForSlot(value.value.type, slot.num);
        const onDelete = () => ctx.clearSlot(slot.num);
        const onMoreFilters = () => ctx.setProductCondSlotOpen(slot.num);
        return (
          <Slot
            key={slot.num}
            slot={slot}
            onProductSelect={onProductSelect}
            onStrategySelect={onStrategySelect}
            onDelete={onDelete}
            onMoreFilters={onMoreFilters}
          />
        );
      })}
    </>
  );
}
