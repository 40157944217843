import { useState } from 'react';
import { DeviceType } from '@/utils/definitions';
import { CatalogWidgetProps } from '@/webapi/use-widget-catalog-api';
import { newStrategyPerSlotCtx } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/context/context';
import { SlotState } from '@/features/editor/widgets/custom-widget/loading-section/shared/models';

export function useStrategyPerSlot(
  device: DeviceType,
  currentSchema: CatalogWidgetProps,
  productsOnly?: boolean,
  initialState?: Array<SlotState>,
) {
  const strategyPerSlotCtx = newStrategyPerSlotCtx(
    device,
    productsOnly,
    initialState,
  );
  const [showStrategyPerSlot, setShowStrategyPerSlot] = useState(false);
  const hasLegacyManualProduct = getProductIds(currentSchema)?.length > 0;
  const isRecsV2Enabled = !hasLegacyManualProduct;
  return {
    strategyPerSlotCtx,
    showStrategyPerSlot,
    setShowStrategyPerSlot,
    isRecsV2Enabled,
  };
}

export function getProductIds(currentParams: CatalogWidgetProps) {
  const recs =
    currentParams.settings.loading?.loadingEnv?.recommendationOptions;
  return recs?.productIds || recs?.manualProducts || [];
}
