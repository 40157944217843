import { centered } from '@/components/use-shared-element';

export const gradientProps = {
  closeTop: `0.5rem`,
  closeRight: `2.5rem`,
  fromStyle: {
    opacity: 0,
    borderRadius: `5rem`,
    backgroundColor: `#DEDEDE`,
    padding: `2rem 0 0 0`,
  },
  showBackdrop: true,
  toStyle: {
    background: `white`,
    opacity: 1,
    borderRadius: `1rem`,
    backgroundColor: `#FFFFFF`,
    padding: `2rem 0 0 0`,
  },
  targetPosAndSize: centered(94, 125),
  stickyEnabled: true,
  overflowTop: { heightInRem: 14 },
  overflowBottom: { heightInRem: 8, turnPointInPercent: 30 },
};
