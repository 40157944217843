import React, { useContext } from 'react';
import styled from 'styled-components';
import { Flexbox } from '@/components/flex';
import { NumberInput } from '@/features/editor/widgets/custom-widget/inputs/shared/number-input';
import { Caption } from '@/features/editor/widgets/custom-widget/shared/shared-styles';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';

export function Control({ envKey, caption, onChange, initialValues }) {
  const { undoRedoCount } = useContext(UndoRedoCounterContext);

  const defaultValue = initialValues(envKey);
  return (
    <Flexbox direction="row" align="center" justify="center" gap="0.3rem">
      <ControlCaption>{caption}</ControlCaption>
      <NumberInput
        key={undoRedoCount}
        minimal
        suffix="px"
        min={-2000}
        max={2000}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </Flexbox>
  );
}

const ControlCaption = styled(Caption)`
  && {
    font-size: 1.2rem;
  }
`;
