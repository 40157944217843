import { MetricKind } from '@/webapi/models';

export interface MetricValue {
  name: MetricKind;
  value: number;
}

export interface DailySummary {
  day: string; // date
  metrics: MetricValue[];
}

export interface StoreSummary {
  metrics?: DailySummary[];
}

export enum DateRange {
  TODAY = `TODAY`,
  YESTERDAY = `YESTERDAY`,
  LAST_7_DAYS = `LAST_7_DAYS`,
  LAST_30_DAYS = `LAST_30_DAYS`,
  LAST_YEAR = `LAST_YEAR`,
  ALL_TIME = `ALL_TIME`,
  CUSTOM = `CUSTOM`,
  UNKNOWN = `UNKNOWN`,
}

export interface Summary {
  useCaseId: string;
  metrics: MetricValue[];
}

export interface ExperiencesSummary {
  summary: Summary[];
  total: number;
}
