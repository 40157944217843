import styled from 'styled-components';

export const OverflowBottom = styled.div`
  height: ${(props) => (props.height ? props.height : `5rem`)};
  width: 100%;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: ${(props) => (props.bottom ? props.bottom : `-5px`)};
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(${(props) => (props.background ? props.background : `255,255,255`)}, 0)
      0%,
    rgba(
        ${(props) => (props.background ? props.background : `255,255,255`)},
        0.8
      )
      ${(props) => (props.turnPoint ? props.turnPoint : `60%`)},
    rgba(${(props) => (props.background ? props.background : `255,255,255`)}, 1)
      100%
  );
  z-index: 28;
  pointer-events: none;
`;

export const OverflowTop = styled.div`
  height: ${(props) => (props.height ? props.height : `5rem`)};
  transition: height 0.6s ease;
  width: 100%;
  padding: 0;
  margin: 0;
  position: absolute;
  top: ${(props) => (props.top ? props.top : `-5px`)};
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(${(props) => (props.background ? props.background : `255,255,255`)}, 1)
      0%,
    rgba(
        ${(props) => (props.background ? props.background : `255,255,255`)},
        ${(props) => (props?.stickyEnabled ? 0.9 : 0.8)}
      )
      ${(props) => (props.turnPoint ? props.turnPoint : `60%`)},
    rgba(${(props) => (props.background ? props.background : `255,255,255`)}, 0)
      100%
  );
  z-index: 28;
  pointer-events: none;
`;

export const OverflowRight = styled.div`
  height: 100%;
  transition: height 0.6s ease;
  width: ${(props) => (props.width ? props.width : `5rem`)};
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${(props) => (props.right ? props.right : `-5px`)};
  background: linear-gradient(
    270deg,
    rgba(${(props) => (props.background ? props.background : `255,255,255`)}, 1)
      0%,
    rgba(
        ${(props) => (props.background ? props.background : `255,255,255`)},
        ${(props) => (props?.stickyEnabled ? 0.9 : 0.8)}
      )
      ${(props) => (props.turnPoint ? props.turnPoint : `60%`)},
    rgba(${(props) => (props.background ? props.background : `255,255,255`)}, 0)
      100%
  );
  z-index: 28;
  pointer-events: none;
`;

export const OverflowLeft = styled.div`
  height: 100%;
  transition: height 0.6s ease;
  width: ${(props) => (props.width ? props.width : `5rem`)};
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => (props.left ? props.left : `-5px`)};
  background: linear-gradient(
    90deg,
    rgba(${(props) => (props.background ? props.background : `255,255,255`)}, 1)
      0%,
    rgba(
        ${(props) => (props.background ? props.background : `255,255,255`)},
        ${(props) => (props?.stickyEnabled ? 0.9 : 0.8)}
      )
      ${(props) => (props.turnPoint ? props.turnPoint : `60%`)},
    rgba(${(props) => (props.background ? props.background : `255,255,255`)}, 0)
      100%
  );
  z-index: 28;
  pointer-events: none;
`;

export const FloatingHeader = styled.div`
  position: absolute;
  width: 100%;
`;
