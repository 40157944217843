import React, { useRef, useState } from 'react';

export function useDetachedState<T>(
  initialValue: T,
): [T, (T) => void, React.MutableRefObject<T>] {
  const [state, _setState] = useState<T>(initialValue);
  const stateRef = useRef<T>(state);

  function setState(newState: T): void {
    stateRef.current = newState;
    _setState(newState);
  }

  return [state, setState, stateRef];
}
