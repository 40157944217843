import React, { useState } from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { CloseButton } from '@/components/close-button';
import { BigButton } from '@/components/big-button';
import { TextInput } from '@/components/text-input';
import { DeviceType } from '@/utils/definitions';

export interface InputModalProps {
  title: string;
  description: string | any;
  initialValue?: string;
  placeholder?: string;
  yesCaption?: string;
  noCaption?: string;
  textArea?: boolean;

  onConfirm?: (value: string) => void;
  onDiscard?: () => void;
}

export function InputModal({
  title,
  description,
  initialValue,
  yesCaption,
  noCaption,
  onConfirm,
  onDiscard,
  placeholder,
  textArea,
}: InputModalProps) {
  const yes = yesCaption || `Yes`;
  const no = noCaption || `No`;

  const [text, setText] = useState(initialValue || ``);

  const onTextChange = (ev) => {
    setText(ev.target.value);
  };

  const onConfirmClick = () => {
    onConfirm && onConfirm(text);
  };

  return (
    <Wrapper textArea={textArea}>
      <StyledClose onClick={onDiscard} />
      <Title>{title}</Title>
      <Desc>{description}</Desc>
      {textArea ? (
        <TextArea
          device={DeviceType.Desktop}
          value={text}
          onChange={onTextChange}
          minRows={10}
          maxRows={10}
          placeholder="Experience description"
        />
      ) : (
        <TextInput
          defaultValue={text}
          placeholder={placeholder}
          onChange={onTextChange}
        />
      )}
      <Footer>
        <BigButton
          onClick={onConfirmClick}
          border="2px solid black"
          noTransform
          size="large"
          fillWidth
        >
          {yes}
        </BigButton>
        <BigButton
          border="2px solid #C8CACB"
          background="white"
          color="#97A0A8"
          noShadow
          noTransform
          size="large"
          fillWidth
          onClick={onDiscard}
        >
          {no}
        </BigButton>
      </Footer>
    </Wrapper>
  );
}

const TextArea = styled<{ device: DeviceType }>(TextareaAutosize)`
  && {
    appearance: none;
    -webkit-appearance: none;
    pointer-events: auto;
    outline: none;
    border: 1.5px solid #c7c8cf;
    border-radius: 10px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
    text-align: left;
    font-family: Inter, serif;
    color: #5b656e;
    overflow-y: scroll;

    &&::-webkit-outer-spin-button,
    &&::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &&[type='number'] {
      -moz-appearance: textfield;
    }

    resize: none;
    letter-spacing: -0.44px;

    margin: 0;
    width: ${({ device }) => (device === DeviceType.Desktop ? `100%` : `60%`)};
    padding: ${({ device }) =>
      device === DeviceType.Desktop ? `1.2rem` : `1.8rem`};
    font-size: ${({ device }) =>
      device === DeviceType.Desktop ? `1.4rem` : `1.6rem`};
    font-weight: 500;
  }
`;

const Wrapper = styled.div<{ textArea: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2rem 1fr ${({ textArea }) => (textArea ? 20 : 3)}rem 5rem;
  grid-row-gap: 2.5rem;
  color: black;
  padding: 2rem;

  font-family: Inter, serif;
  text-align: center;

  input {
    width: 80%;
    justify-self: center;
    box-shadow: none;
    font-size: 1.4rem;
    font-family: 'JetBrains Mono', serif;
    border-color: #dedede;
  }
`;

const Title = styled.span`
  font-size: 2rem;
  font-weight: bold;
`;

const Desc = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #aaaaaa;
`;

const Footer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
`;

const StyledClose = styled(CloseButton)`
  && {
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;
    justify-self: end;
    .gatsby-image-wrapper {
      height: 1rem;
      width: 1rem;
    }
  }
`;
