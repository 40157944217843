import { CatalogApp } from '@/webapi/use-widget-catalog-api';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import codeBlockImage from './code_block.svg';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import fakeClickImage from './fake_click.svg';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import customComponentImage from './custom_component.svg';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import globalCssImage from './global_css.svg';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import globalJsImage from './global_js.svg';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import customExperienceImage from './custom.svg';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import postPurchaseImage from './post_purchase.svg';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pageRedirectImage from './redirect.svg';

export const CodeBlockApp: CatalogApp = {
  id: `code-block`,
  name: `Code Blocks`,
  hint: `Add custom HTML/CSS/Javascript anywhere`,
  image: codeBlockImage,
  widgets: [
    {
      id: `custom`,
      name: `Custom Component`,
      hint: `Create your own custom componet using html/css/js`,
      image: customComponentImage,
      blueprint: undefined,
    },
    {
      id: `css`,
      name: `Global CSS`,
      hint: `Add a global css file`,
      image: globalCssImage,
      blueprint: undefined,
    },
    {
      id: `js`,
      name: `Global Javascript`,
      hint: `Add a global javascript file`,
      image: globalJsImage,
      blueprint: undefined,
    },
  ],
};

export const FakeClickApp: CatalogApp = {
  id: `fake-click`,
  name: `Fake Click`,
  hint: `Add powerful scenarios  by recording and automating your interactions`,
  image: fakeClickImage,
};

export const CustomExperienceApp: CatalogApp = {
  id: `custom-experience`,
  name: `Custom Experience`,
  hint: `Edit or add multiple elements on multiple pages, the sky is the limit`,
  image: customExperienceImage,
};

export const PostPurchaseApp: CatalogApp = {
  id: `post-purchase`,
  name: `Add Post Purchase Page`,
  hint: `Add special offers right after the checkout step`,
  image: postPurchaseImage,
};

export const PageRedirectApp: CatalogApp = {
  id: `page-redirect`,
  name: `Redirect`,
  hint: `Redirect users to different URLs, compare performance and optimize conversions`,
  image: pageRedirectImage,
};
