import React from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

export interface PostPurchaseTextualAreaInputProps {
  defaultValue: string;
  onChange: (p: string) => void;
}

export function PostPurchaseTextualAreaInput({
  defaultValue,
  onChange,
}: PostPurchaseTextualAreaInputProps) {
  const onTextChanged = (ev) => {
    onChange(ev.target.value);
  };

  return (
    <Wrapper>
      <TextArea defaultValue={defaultValue} onChange={onTextChanged} />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const TextArea = styled(TextareaAutosize)`
  && {
    resize: none;
    outline: none;
    border: 1px solid #c7cdd2;
    border-radius: 12px;
    background-color: #ffffff;
    font-size: 1.3rem;
    font-weight: 600;
    font-family: 'JetBrains Mono', serif;
    letter-spacing: -0.44px;
    color: #4b5564;

    margin: 0;

    padding: 1rem 2rem;
    line-height: 2;

    width: 100%;
    min-height: 15rem;
  }
`;
