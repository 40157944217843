import React, { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Switch from 'react-ios-switch';
import { toast } from 'react-toastify';
import { Caption } from '../../shared/shared-styles';
import { Line } from '@/features/editor/widgets/custom-widget/inputs/shared/card-wrapper';
import { DropdownInput } from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { InputsMinimalTheme } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import {
  AddButton,
  ImageTile,
} from '@/features/editor/widgets/custom-widget/inputs/background/image/shared/image-tile';
import { InputModal } from '@/components/input-modal';
import { SharedElementOverlay } from '@/components/shared-element-overlay';
import { PosterImageOverlay } from '@/features/editor/widgets/custom-widget/inputs/background/video/poster-image-overlay';
import {
  fitOptions,
  posOptions,
  VideoInputContext,
} from '@/features/editor/widgets/custom-widget/inputs/background/video/context';
import { uploadPosterImage } from '@/features/editor/widgets/custom-widget/inputs/background/video/utils';
import { useMediaApi } from '@/webapi/use-media-api';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';

export function BackgroundVideoHeader({ videoRef }) {
  const {
    videoModalProps,
    source,
    setSource,
    showVideoModal,
    hideVideoModal,
    setPoster,
    poster,
  } = useContext(VideoInputContext);
  const { shouldOpenModalOnInit } = useContext(CustomWidgetContext);

  const { uploadImage } = useMediaApi();

  const onVideoSelected = async (value: string) => {
    if (value && value.includes) {
      const isValidFormat =
        value.includes(`.mp4`) ||
        value.includes(`.ogg`) ||
        value.includes(`.webm`);

      if (isValidFormat) {
        setPoster(undefined);
        setSource(value);
        hideVideoModal();
      } else {
        toast(
          `The video link format isn't supported, we accept the following formats: mp4, ogg, webm`,
          {
            theme: `colored`,
            type: `error`,
            className: css({ fontFamily: `JetBrains Mono, Serif` }),
          },
        );
      }
    }
  };

  useEffect(() => {
    videoRef?.current?.addEventListener?.(`loadeddata`, onVideoLoaded, false);
    return () => {
      videoRef?.current?.removeEventListener?.(
        `loadeddata`,
        onVideoLoaded,
        false,
      );
    };
  }, []);

  const onVideoLoaded = async () => {
    const resp = await uploadPosterImage(videoRef, uploadImage);
    if (resp && !poster) {
      setPoster(resp.thumbnail);
    }
  };

  useEffect(() => {
    if (shouldOpenModalOnInit?.()) showVideoModal();
  }, []);
  return (
    <div>
      <SharedElementOverlay {...videoModalProps}>
        <InputModal
          title="Link a video"
          description="Paste a link to the video you want to show"
          yesCaption="Update"
          noCaption="Cancel"
          initialValue={source}
          onConfirm={onVideoSelected}
          onDiscard={hideVideoModal}
        />
      </SharedElementOverlay>
      <VideoTile mref={videoRef} src={source} onClick={showVideoModal} />
    </div>
  );
}

export function BackgroundVideoControls({ videoRef }) {
  const {
    posterModalProps,
    showPosterModal,
    hidePosterModal,
    poster,
    setPoster,
    position,
    setPosition,
    objectFit,
    setObjectFit,
    autoPlay,
    setAutoPlay,
    loop,
    setLoop,
    mute,
    setMute,
    controls,
    setControls,
    posterImageRef,
  } = useContext(VideoInputContext);
  const { undoRedoCount } = useContext(UndoRedoCounterContext);

  return (
    <div>
      <SharedElementOverlay {...posterModalProps}>
        <PosterImageOverlay
          videoRef={videoRef}
          onPosterImage={setPoster}
          hide={hidePosterModal}
        />
      </SharedElementOverlay>
      <Section>
        <Caption>Fit</Caption>
        <AlignRight>
          <DropdownInput
            key={`${undoRedoCount}1`}
            theme={InputsMinimalTheme}
            options={fitOptions}
            defaultValue={
              fitOptions.filter((opt) => opt.value === objectFit)?.[0]
            }
            onChange={(sel) => setObjectFit(sel.value)}
          />
        </AlignRight>
      </Section>
      <Section>
        <Caption>Position</Caption>
        <AlignRight>
          <DropdownInput
            key={`${undoRedoCount}2`}
            theme={InputsMinimalTheme}
            options={posOptions}
            defaultValue={
              posOptions.filter((opt) => opt.value === position)?.[0]
            }
            onChange={(sel) => setPosition(sel.value)}
          />
        </AlignRight>
      </Section>
      <Section>
        <Caption>Opening Image</Caption>
        <AlignRight ref={posterImageRef}>
          <ImageTile src={poster} onClick={showPosterModal} />
        </AlignRight>
      </Section>
      <Section>
        <Caption>Auto Play</Caption>
        <SmallSwitch checked={autoPlay} onChange={(val) => setAutoPlay(val)} />
      </Section>
      <Section>
        <Caption>Media Controls</Caption>
        <SmallSwitch checked={controls} onChange={(val) => setControls(val)} />
      </Section>
      <Section>
        <Caption>Mute Audio</Caption>
        <SmallSwitch checked={mute} onChange={(val) => setMute(val)} />
      </Section>
      <Section>
        <Caption>Loop</Caption>
        <SmallSwitch checked={loop} onChange={(val) => setLoop(val)} />
      </Section>
    </div>
  );
}

const AlignRight = styled.div`
  justify-self: flex-end;
  width: 60%;

  && > * {
    align-items: flex-end;
  }

  img {
    max-height: 4rem;
    max-width: 4rem;
    margin-right: 0.6rem;
  }
`;

const SmallSwitch = styled(Switch)`
  && {
    justify-self: flex-end;
    transform: scale(0.7);
  }
`;

const Section = styled(Line)`
  && {
    padding: 0.7rem 0;
  }
`;

export function VideoTile({
  mref,
  src,
  onClick,
}: {
  mref: any;
  src?: string;
  onClick?: (event) => void;
}) {
  let isPlaceholder = false;
  if (!src || src === `` || src.includes(`placeholder.mp4`)) {
    isPlaceholder = true;
  }

  return (
    <VideoTileWrapper onClick={onClick}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <AddButton style={{ display: isPlaceholder ? `block` : `none` }}>
        Add
      </AddButton>
      <video
        ref={mref}
        style={{ display: isPlaceholder ? `none` : `block` }}
        muted
        src={src}
        crossOrigin="Anonymous"
      />
    </VideoTileWrapper>
  );
}

const VideoTileWrapper = styled.div`
  position: relative;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  video {
    border-radius: 1rem;
    height: 3.5rem;
    width: 4.5rem;
    object-fit: cover;
  }
`;
