// eslint-disable-next-line import/no-extraneous-dependencies
import prettier from 'prettier';
// eslint-disable-next-line import/no-extraneous-dependencies
import htmlPrettier from 'prettier/parser-html';
// eslint-disable-next-line import/no-extraneous-dependencies
import cssPrettier from 'prettier/parser-postcss';
// eslint-disable-next-line import/no-extraneous-dependencies
import jsPrettier from 'prettier/parser-babel';
import { isString } from '@/utils/types';
import {
  CompoundBlock,
  EditorChangeKind,
  EditorDeclarativeBlock,
} from '@/webapi/use-experience-api';

export const HTML = `HTML`;
export const CSS = `CSS`;
export const JS = `JS`;

const IIFE_PREFIX = `(function () {`;
const IIFE_SUFFIX = `})();`;

export function hasHtmlDiff(change: EditorDeclarativeBlock): boolean {
  const oldHtml = prettier.format(change.initialHtml || ``, {
    parser: `html`,
    plugins: [htmlPrettier],
    bracketSameLine: false,
    jsxBracketSameLine: false,
  });
  const newHtml = prettier.format((change.block.value as CompoundBlock)?.html, {
    parser: `html`,
    plugins: [htmlPrettier],
    bracketSameLine: false,
    jsxBracketSameLine: false,
  });
  return oldHtml !== newHtml;
}

export function extractCodeFromChange(
  change: EditorDeclarativeBlock,
  prettify?: boolean,
): {
  html: string;
  css: string;
  js: string;
} {
  if (
    [EditorChangeKind.EDIT_COMPOUND, EditorChangeKind.NEW_COMPONENT].includes(
      change.editorKind,
    )
  ) {
    return extractCompoundCode(change, prettify);
  }
  if (
    [EditorChangeKind.GLOBAL_CSS, EditorChangeKind.HIDE_COMPONENT].includes(
      change.editorKind,
    )
  ) {
    return { html: ``, css: extractCss(change, prettify), js: `` };
  }

  if (EditorChangeKind.GLOBAL_JS === change.editorKind) {
    return { html: ``, css: ``, js: extractJs(change, prettify) };
  }

  return { html: ``, css: ``, js: `` };
}

function extractCompoundCode(
  change: EditorDeclarativeBlock,
  prettify: boolean,
): {
  html: string;
  css: string;
  js: string;
} {
  const html = extractHtml(change, prettify);
  const css = extractCss(change, prettify);
  const js = extractJs(change, prettify);

  return { html, css, js };
}

function extractHtml(
  change: EditorDeclarativeBlock,
  prettify: boolean,
): string {
  const html = isString(change.block.value)
    ? (change.block.value as string)
    : (change.block.value as CompoundBlock).html;

  if (prettify) {
    try {
      const prettierHtml = prettier.format(html, {
        parser: `html`,
        plugins: [htmlPrettier],
        bracketSameLine: false,
        jsxBracketSameLine: false,
      });
      return prettierHtml;
    } catch (ex) {
      console.error(`vsly`, ex);
    }
  }
  return html;
}

function extractCss(change: EditorDeclarativeBlock, prettify: boolean): string {
  const css = isString(change.block.value)
    ? (change.block.value as string)
    : (change.block.value as CompoundBlock).css;

  if (prettify) {
    try {
      const prettierCss = prettier.format(css, {
        parser: `css`,
        plugins: [cssPrettier],
      });
      return prettierCss;
    } catch (ex) {
      console.error(`vsly`, ex);
    }
  }
  return css;
}

function extractJs(change: EditorDeclarativeBlock, prettify: boolean): string {
  const js = isString(change.block.value)
    ? (change.block.value as string)
    : (change.block.value as CompoundBlock).js;

  if (prettify) {
    try {
      const prettierJs = prettier.format(iifeUnwrap(js), {
        parser: `babel`,
        plugins: [jsPrettier],
      });
      return prettierJs;
    } catch (ex) {
      console.error(`vsly`, ex);
    }
  }
  return iifeUnwrap(js);
}

export function iifeWrap(value: string): string {
  if (!isIifeCode(value)) {
    return `${IIFE_PREFIX}\n${value}\n${IIFE_SUFFIX}`;
  }
  return value;
}

export function iifeUnwrap(value: string): string {
  if (isIifeCode(value)) {
    return value.substring(
      IIFE_PREFIX.length + 1,
      value.length - IIFE_SUFFIX.length - 1,
    );
  }
  return value;
}

export function isIifeCode(value: string): boolean {
  const out = value?.startsWith(IIFE_PREFIX);
  return out;
}
