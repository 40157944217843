import React, { useContext, useEffect, useState } from 'react';
import {
  EditorChangeKind,
  EditorDeclarativeBlock,
} from '@/webapi/use-experience-api';
import { useComplexState } from '@/utils/use-complex-state';
import { EditorContext } from '@/features/editor/context/editor-context';
import { AutomationMessage } from '@/features/editor/context/use-device-preview';
import { DeviceType } from '@/utils/definitions';
import { createNewElementMessageFromOrigChange } from '@/features/editor/context/use-transpiler';
import { AutomationChange, AutomationStep } from '@/pkg/sdk';

export const FakeClickContext = React.createContext({} as FakeClickCtx);

export type FakeClickState = `paused` | `recording`;

export interface FakeClickCtx {
  isNew: boolean;
  currentState: FakeClickState;
  pause: () => void;
  record: () => void;
  play: () => void;
  restart: () => void;

  steps: AutomationStep[];
  appendStep: (step: AutomationStep) => void;
  removeStep: (step: AutomationStep) => void;

  device: DeviceType;
  onBack: () => void;
}

export function newFakeClickContext(
  initChange: EditorDeclarativeBlock,
): FakeClickCtx {
  const {
    devicePreview: {
      editorState: { device },
      automateIdle,
      automateRecord,
      automatePlay,
      syncAutomations,
    },
    inspectorNav: { gotoAppCatalog, gotoChangelog },
  } = useContext(EditorContext);

  const [currentState, setCurrentState] = useState<FakeClickState>(`paused`);
  const [steps, setSteps] = useComplexState(
    (initChange?.block?.value as AutomationChange)?.steps || [],
  );

  const isNew = initChange?.editorKind !== EditorChangeKind.FAKE_CLICK;

  useEffect(() => {
    if (currentState === `paused`) {
      setTimeout(() => syncAutomations(steps), 300);
      setTimeout(() => syncAutomations(steps), 500);
      setTimeout(() => syncAutomations(steps), 800);
    } else {
      syncAutomations(steps);
    }
  }, [steps, currentState]);

  useEffect(() => {
    if (currentState === `recording`) {
      automateRecord();
    } else {
      automateIdle();
    }
  }, [currentState]);

  const pause = () => {
    setCurrentState(`paused`);
  };

  const record = () => {
    setCurrentState(`recording`);
  };

  const restart = () => {
    setSteps((draft) => {
      draft.splice(0, draft.length);
    });
    record();
  };

  const play = () => {
    automatePlay(steps);
  };

  const onBack = () => {
    if (!isNew) {
      gotoChangelog();
      return;
    }

    gotoAppCatalog(createNewElementMessageFromOrigChange(initChange));
  };

  const appendStep = (step: AutomationStep) => {
    setSteps((draft) => {
      draft.push({ ...step, order: steps.length });
    });
  };

  const removeStep = (step: AutomationStep) => {
    setSteps((draft) => {
      const idx = draft.findIndex((v) => step.order === v.order);
      if (idx >= 0) {
        draft.splice(idx, 1);
      }
      for (let i = 0; i < draft.length; i += 1) {
        draft[i].order = i;
      }
    });
  };

  useEffect(() => {
    const handler = (ev) => {
      const msg = ev.data as AutomationMessage;
      if (msg.action === `newAutomationStep`) {
        appendStep(msg.step);
      }
    };

    window.addEventListener(`message`, handler);
    return () => {
      window.removeEventListener(`message`, handler);
    };
  }, [steps]);

  return {
    isNew,
    currentState,
    pause,
    record,
    play,
    restart,

    steps,
    appendStep,
    removeStep,

    onBack,
    device,
  };
}
