import React, { useContext } from 'react';
import styled from 'styled-components';
import { IoIosArrowBack } from 'react-icons/io';
import { LayeredRulingContext } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import { SearchHeader } from '@/features/editor/widgets/custom-widget/loading-section/shared/search-header';
import { ManualImageSelectHeaderInner } from '@/features/editor/widgets/custom-widget/loading-section/shared/manual-image-select';

export function Header() {
  const ctx = useContext(LayeredRulingContext);
  if (ctx.isManualImageSelectionOpen) {
    return (
      <ManualImageSelectHeaderInner
        onBack={() => ctx.setManualImageSelectionOpen(undefined)}
      />
    );
  }
  if (ctx.isProductSearchOpen) {
    return (
      <SearchHeader
        onBack={() => ctx.setProductSearchSlotOpen(undefined)}
        value={ctx.searchState.searchText}
        onChange={(e) => {
          ctx.setSearchText(e.target.value);
        }}
      />
    );
  }
  if (ctx.state.showCondBuilder) {
    return (
      <MoreFiltersWrapper>
        <Back type="button" onClick={ctx.toggleCondBuilder}>
          <IoIosArrowBack color="grey" size={40} />
        </Back>
        <p>More Filters</p>
      </MoreFiltersWrapper>
    );
  }
  return (
    <Wrapper>
      <p>Advanced Ruling</p>
      <span>
        On this screen, you can blend various strategies based on different
        conditions.
      </span>
    </Wrapper>
  );
}

const Back = styled.button`
  &:hover {
    cursor: pointer;
    opacity: 0.3;
  }
  &:active {
    cursor: pointer;
    opacity: 0.5;
  }
  opacity: 0.5;
  left: -4rem;
  position: absolute;
  top: -2.8rem;
  color: #efefef;
`;

const MoreFiltersWrapper = styled.div`
  position: relative;
  width: 90%;
  user-select: none;
  padding-bottom: 1rem;
  p {
    font-size: 3rem;
    margin: -3rem 0 1rem 0;
  }
`;

const Wrapper = styled.div`
  p {
    font-size: 3rem;
    margin: 0 0 1rem 0;
  }
  span {
    font-size: 1.6rem;
    font-weight: normal;
  }
`;
