import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { LayeredRulingContext } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import { HSpace, VSpace } from '@/components/spacing';
import { BigButton } from '@/components/big-button';
import { DelSvg } from '@/features/editor/widgets/custom-widget/loading-section/shared/icons/delSvg';
import { DownSvg } from '@/features/editor/widgets/custom-widget/loading-section/shared/icons/downSvg';
import { MinusSvg } from '@/features/editor/widgets/custom-widget/loading-section/shared/icons/minusSvg';
import { PlusSvg } from '@/features/editor/widgets/custom-widget/loading-section/shared/icons/plusSvg';
import { UpSvg } from '@/features/editor/widgets/custom-widget/loading-section/shared/icons/upSvg';

export function Actions({ open, ruleId }: { ruleId: number; open: boolean }) {
  const ctx = useContext(LayeredRulingContext);
  const toggleOpen = () => ctx.toggleOpen(ruleId);

  const [isOpen, setIsOpen] = useState(false);

  const onDelete = () => {
    setIsOpen(!isOpen);
  };
  const onUp = (e: any) => {
    e.stopPropagation();
    ctx.up(ruleId);
  };
  const onDown = (e: any) => {
    e.stopPropagation();
    ctx.down(ruleId);
  };

  return (
    <Center>
      <IconButton
        onClickCapture={onDown}
        disabled={ruleId === ctx.state.rules.length - 1}
      >
        <DownSvg />
      </IconButton>
      <IconButton onClickCapture={onUp} disabled={ruleId === 0}>
        <UpSvg />
      </IconButton>
      <Popover
        onClickOutside={() => setIsOpen(false)}
        clickOutsideCapture
        positions={[`left`]}
        isOpen={isOpen}
        containerStyle={{ zIndex: `1500` }}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="white"
            arrowClassName="change-target-arrow"
            arrowSize={0}
          >
            <AreYouSureWrapper>
              <VSpace />
              <AreYouSure>
                Are you sure you want to <br /> delete this rule?
              </AreYouSure>
              <BtnsWrap>
                <BigButton
                  onClick={() => ctx.remove(ruleId)}
                  border="2px solid #C8CACB"
                  background="white"
                  color="#97A0A8"
                  noShadow
                  noTransform
                  size="small"
                  fillWidth
                >
                  YES
                </BigButton>
                <HSpace />
                <BigButton
                  onClick={() => setIsOpen(false)}
                  border="2px solid #C8CACB"
                  noTransform
                  noShadow
                  background="white"
                  color="#97A0A8"
                  size="small"
                  fillWidth
                >
                  NO
                </BigButton>
              </BtnsWrap>
            </AreYouSureWrapper>
          </ArrowContainer>
        )}
      >
        <IconButton
          onClickCapture={onDelete}
          disabled={ctx.state.rules.length === 1}
        >
          <DelSvg />
        </IconButton>
      </Popover>

      <IconButton onClickCapture={toggleOpen}>
        {open ? <MinusSvg /> : <PlusSvg />}
      </IconButton>
    </Center>
  );
}
const Center = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AreYouSure = styled.p`
  font-size: 1.5rem;
  text-align: center;
  font-family: 'JetBrains Mono', serif;
  font-weight: 500;
  color: #475562;
`;
const AreYouSureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 20px;
  padding: 0 2rem 2rem 2rem;
  border: 1px solid #cbcbcb;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25),
    0 1px 34px 0 rgba(151, 210, 206, 0.32), 0 1px 14px 0 rgba(0, 0, 0, 0.1);
`;

const BtnsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const IconButton = styled.button`
  margin: 0 0.5rem;
  ${(p) => (p.disabled ? `opacity: 0.5;` : `cursor: pointer;`)}
  border-radius: 50%;


    ${(p) =>
      p.disabled
        ? ``
        : `
  svg {
    &:hover {
    rect {
      fill: black;
    }
      g {
        opacity: 1;

      }
    }

    &:active {
      rect {
      fill: #b6bac5;
    }
      g {
        opacity: 0.5;

      }
    }
  }
        `}
  }
`;
