import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { GradientModal } from '@/components/gradient-modal';
import { Footer } from '@/features/editor/widgets/shared/modals/commons';

import {
  AudienceContext,
  AudienceModalContext,
  newAudienceModalCtx,
  View,
} from '@/features/editor/widgets/shared/modals/audience/audience-context';
import {
  FacebookAudienceContext,
  FBView,
  newFacebookAudienceContext,
} from '@/features/editor/widgets/shared/modals/audience/facebook-audience/context';
import {
  AudienceModalBody,
  AudienceModalHeader,
} from '@/features/editor/widgets/shared/modals/audience/modal-content';
import {
  getCTAText,
  getOnAction,
} from '@/features/editor/widgets/shared/modals/audience/modal-cta';
import { AccountContext } from '@/features/account-context';
import {
  AdSearchContext,
  AdSearchCtx,
  newAdSearchContext,
} from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/context';

export interface AudienceModalProps {
  fromRef: React.MutableRefObject<HTMLElement>;
  isVisible: boolean;
  setIsVisible: (state: boolean) => void;
}

export function AudienceModal({
  fromRef,
  isVisible,
  setIsVisible,
}: AudienceModalProps) {
  const accountCtx = useContext(AccountContext);
  const fbCtx = newFacebookAudienceContext(isVisible);
  const adSearchContext = newAdSearchContext(fbCtx.targeting, () =>
    fbCtx.setStep(FBView.CONNECT),
  );
  const modalCtx = newAudienceModalCtx(
    setIsVisible,
    fbCtx.reInit,
    cleanupFbState(fbCtx, adSearchContext),
  );

  const { toRect } = modalCtx;

  const handleSave = getOnAction(modalCtx, fbCtx, accountCtx);
  const ctaText = getCTAText(modalCtx, fbCtx);
  const onClose = () => {
    modalCtx.onCloseCleanup();
    setIsVisible(false);
  };
  const isSaveDisabled = isSaveBtnDisabled(modalCtx, fbCtx);
  return (
    <AudienceContext.Provider value={modalCtx}>
      <FacebookAudienceContext.Provider value={fbCtx}>
        <AdSearchContext.Provider value={adSearchContext}>
          <GradientModal
            closeTop="0.5rem"
            closeRight="2.5rem"
            fromStyle={{
              borderRadius: `5rem`,
              backgroundColor: `#DEDEDE`,
              padding: `2rem 0 0 0`,
            }}
            toStyle={{
              borderRadius: `1rem`,
              backgroundColor: `#FFFFFF`,
              padding: `2rem 0 0 0`,
            }}
            isVisible={isVisible}
            showBackdrop
            targetPosAndSize={toRect}
            fromRef={fromRef}
            overflowTop={{ heightInRem: 8 }}
            overflowBottom={{ heightInRem: 8, turnPointInPercent: 30 }}
            onClose={onClose}
            header={<AudienceModalHeader />}
            footer={
              <Footer
                onClick={handleSave}
                text={modalCtx.loading ? `...` : ctaText}
                isDisabled={isSaveDisabled}
              />
            }
          >
            <Container>
              <AudienceModalBody />
            </Container>
          </GradientModal>
        </AdSearchContext.Provider>
      </FacebookAudienceContext.Provider>
    </AudienceContext.Provider>
  );
}

const Container = styled.div`
  margin: 2rem 1.5rem;
  text-transform: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  text-align: center;
  color: #9ba7b3;
  letter-spacing: -0.38px;
  font-family: Inter, serif;
  font-weight: 500;
  font-size: 1.4rem;
`;

function cleanupFbState(fbCtx: any, adSearchContext: AdSearchCtx) {
  return () => {
    fbCtx.targeting.current = {
      campaigns: [],
      ads: [],
      adsets: [],
    };
    adSearchContext.setState((draft) => {
      draft?.campaigns?.forEach((c) => {
        c.isSelected = false;
        c.adSets?.forEach((set) => {
          set.isSelected = false;
          set?.ads?.forEach((ad) => {
            ad.isSelected = false;
          });
        });
      });
    });
  };
}
function isSaveBtnDisabled(modalCtx: AudienceModalContext, fbCtx: any) {
  return (
    (modalCtx.view === View.AUDIENCE_NAME &&
      !modalCtx.userDefinedAudienceName) ||
    fbCtx.validationLoading ||
    modalCtx.loading
  );
}
