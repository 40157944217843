import { Product } from '@/webapi/use-catalog-api';
import { CatalogApp, CustomizationSpec } from '@/webapi/use-widget-catalog-api';
import { InputType } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { parseDim } from '@/features/editor/widgets/custom-widget/inputs/layout/size-input';

const MAGIC_PRODUCT_TILE_RATIO = `$product_tile`;

export function calcAndAssignProductImageAspectRatio(
  products: Product[],
  appsCatalog,
) {
  const productTileAspectRatio = popularAspectRatio(products);
  assignProductTilesSizes(appsCatalog, productTileAspectRatio);
  return productTileAspectRatio;
}

function assignProductTilesSizes(
  appsCatalog: CatalogApp[],
  aspectRatio: number,
) {
  appsCatalog?.forEach((_, appIdx) =>
    appsCatalog[appIdx].widgets.forEach((_, widgetIdx) => {
      appsCatalog[appIdx].widgets[
        widgetIdx
      ].blueprint.schema.customizations.forEach((_, custIdx) => {
        appsCatalog[appIdx].widgets[widgetIdx].blueprint.schema.customizations[
          custIdx
        ].components.forEach((_, compIdx) => {
          appsCatalog[appIdx].widgets[
            widgetIdx
          ].blueprint.schema.customizations[custIdx].components[
            compIdx
          ].specs.forEach((spec, specIdx) => {
            if (spec.type === InputType.SIZE) {
              appsCatalog[appIdx].widgets[
                widgetIdx
              ].blueprint.schema.customizations[custIdx].components[
                compIdx
              ].specs[specIdx] = assignSize(spec, aspectRatio);
            }
            if (spec.type === InputType.ASPECT_RATIO) {
              appsCatalog[appIdx].widgets[
                widgetIdx
              ].blueprint.schema.customizations[custIdx].components[
                compIdx
              ].specs[specIdx].values[`value`] = aspectRatio;
            }
          });
        });
      });
    }),
  );
}

function assignSize(spec: CustomizationSpec, ratio: number): CustomizationSpec {
  let initHeight = spec.values?.[`height`];
  let initWidth = spec.values?.[`width`];
  const heightUnit = spec.values?.[`heightUnit`];
  const widthUnit = spec.values?.[`widthUnit`];

  if (initHeight === MAGIC_PRODUCT_TILE_RATIO) {
    initHeight = `${Math.round(
      parseDim(initWidth, widthUnit) / ratio,
    )}${heightUnit}`;
  } else if (initWidth === MAGIC_PRODUCT_TILE_RATIO) {
    initWidth = `${Math.round(
      parseDim(initHeight, heightUnit) * ratio,
    )}${widthUnit}`;
  } else {
    return spec;
  }

  spec.values[`height`] = initHeight;
  spec.values[`width`] = initWidth;

  let initHeightDesktop = spec.values?.[`desktop`]?.[`height`];
  let initWidthDesktop = spec.values?.[`desktop`]?.[`width`];
  if (initWidthDesktop && initWidthDesktop) {
    const desktopHeightUnit =
      spec.values?.[`desktop`]?.[`heightUnit`] || spec.values?.[`heightUnit`];
    const desktopWidthUnit =
      spec.values?.[`desktop`]?.[`widthUnit`] || spec.values?.[`widthUnit`];

    if (initHeightDesktop === MAGIC_PRODUCT_TILE_RATIO) {
      initHeightDesktop = `${Math.round(
        parseDim(initWidthDesktop, desktopWidthUnit) / ratio,
      )}${desktopHeightUnit}`;
    } else if (initWidthDesktop === MAGIC_PRODUCT_TILE_RATIO) {
      initWidthDesktop = `${Math.round(
        parseDim(initHeightDesktop, desktopHeightUnit) * ratio,
      )}${desktopWidthUnit}`;
    }

    spec.values[`desktop`][`height`] = initHeightDesktop;
    spec.values[`desktop`][`width`] = initWidthDesktop;
  }

  return spec;
}

function popularAspectRatio(products: Product[]): number {
  try {
    const ratioCounts = products
      .sort((a, b) => a.rank - b.rank)
      .slice(0, 200)
      .reduce((result, current) => {
        const ratio = (current.image.width / current.image.height).toFixed(2);
        if (!result[ratio]) {
          result[ratio] = 1;
        } else {
          result[ratio] += 1;
        }
        return result;
      }, {});

    const sortedRatios = Object.entries(ratioCounts)
      .map(([ratio, rank]) => [parseFloat(ratio), rank as number])
      .sort(([_1, a], [_2, b]) => b - a);

    return sortedRatios[0][0];
  } catch (e) {
    console.error(`failed to calc aspect ratio from products  ${products}`, e);
    return 1;
  }
}
