import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { ManualProductsPicker } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/context';
import { BigButton } from '@/components/big-button';
import { OverflowBottom } from '@/components/overflow';

const Container = styled.div`
  margin: ${(p: { noTopOverFlow?: boolean }) =>
      p?.noTopOverFlow ? 0 : `12rem`}
    3rem 0 3rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto-fill, 15px);
  grid-row-gap: 20em;
  grid-column-gap: 10em;
  height: 605px;
  overflow-y: scroll;
  scrollbar-width: none;
`;

const ProductImage = styled.button`
  position: relative;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  &:active {
    opacity: 1;
    cursor: default;
  }

  border-radius: 22px;
  width: 150px;
  height: 150px;
`;

const FloatingSection = styled.div`
  backdrop-filter: opacity(0.2);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

const SaveBtn = styled(BigButton)`
  width: 300px;
  margin: 0 auto;
`;

export const ImagePicker: FC<{ noTopOverFlow?: boolean }> = ({
  noTopOverFlow,
}) => {
  const { productWithoutImage, chooseProduct } =
    useContext(ManualProductsPicker);
  const [selected, setSelected] = useState<string | undefined>();

  return (
    <Container noTopOverFlow={noTopOverFlow}>
      {productWithoutImage?.images?.map((x) => (
        <ProductImage onClick={() => setSelected(x.src)}>
          <ImgWrap isSelected={x.src === selected}>
            <img key={x.src} src={x.src} alt={x.src} />
          </ImgWrap>
          <CheckMarkWrap isSelected={x.src === selected}>
            <StaticImage
              objectFit="contain"
              placeholder="none"
              height={40}
              src="../../../../../../../assets/v_indicator.svg"
              alt="blue checkmark"
            />
          </CheckMarkWrap>
        </ProductImage>
      ))}
      <ProductImage key="a1" />
      <ProductImage key="a2" />
      <ProductImage key="a3" />
      <ProductImage key="a4" />

      <FloatingSection>
        <SaveBtn
          onClick={() => {
            if (typeof productWithoutImage !== `undefined`) {
              productWithoutImage.missingVariantImageMapping = false;
              productWithoutImage.imageOverride = true;
              productWithoutImage.image.src = selected;
              chooseProduct(productWithoutImage);
            }
          }}
          disabled={!selected}
          type="button"
          border="2px solid black"
          noTransform
          size="xl"
          fillWidth
        >
          Save
        </SaveBtn>
      </FloatingSection>
      <OverflowBottom height="8rem" />
    </Container>
  );
};

const ImgWrap = styled.div`
  img {
    border: 4px solid
      ${(x: { isSelected: boolean }) =>
        x.isSelected ? `#3451fb` : `transparent`};
    border-radius: 22px;

    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;
const CheckMarkWrap = styled.div`
  img {
    border: none;
  }

  .gatsby-image-wrapper {
    overflow: visible;
  }

  display: ${(p: { isSelected: boolean }) => (p.isSelected ? `auto` : `none`)};
  position: absolute;
  border-radius: 50%;
  left: 82%;
  bottom: -5px;
  width: 40px;
  height: 40px;
`;
