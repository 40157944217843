import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { BackModalHeader } from '@/features/editor/widgets/shared/modals/commons';
import {
  FacebookAudienceContext,
  FBView,
} from '@/features/editor/widgets/shared/modals/audience/facebook-audience/context';
import { AdAccountSelect } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-account-select';
import { FBConnect } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/fb-connect';
import { AdSearch } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search';
import { Success } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/success';
import { AudienceValidation } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/validation';

const Container = styled.div`
  margin: 2rem 2rem;
`;

export const FacebookHeader: FC<{ back: () => void }> = ({ back }) => {
  const fbCtx = useContext(FacebookAudienceContext);
  const titles = new Map<FBView, string>([
    [FBView.AD_ACCOUNT_SELECT, `Please select the relevant ad account`],
    [FBView.CONNECT, `Connect to your Facebook Ads Account`],
    [FBView.SEARCH, `Select Facebook or Instagram campaign`],
    [FBView.SUCCESS, `All Set!`],
    [
      FBView.VALIDATION,
      `Please add URL parameters to selected campaign on Ads manager`,
    ],
  ]);
  let onBack: () => void;
  switch (fbCtx.step) {
    case FBView.SUCCESS:
      onBack = () => fbCtx.setStep(FBView.SEARCH);
      break;
    case FBView.VALIDATION:
      onBack = () => fbCtx.setStep(FBView.SEARCH);
      break;
    default:
      onBack = back;
  }
  return (
    <BackModalHeader
      back={onBack}
      header={titles.get(fbCtx.step)}
      noSeparator={fbCtx.step === FBView.SEARCH}
    />
  );
};

export const FacebookContainer: FC = () => {
  const { step } = useContext(FacebookAudienceContext);

  switch (step) {
    case FBView.AD_ACCOUNT_SELECT: {
      return <AdAccountSelect />;
    }
    case FBView.CONNECT: {
      return <FBConnect />;
    }
    case FBView.SEARCH: {
      return <AdSearch />;
    }
    case FBView.SUCCESS: {
      return <Success />;
    }
    case FBView.VALIDATION: {
      return <AudienceValidation />;
    }
    default:
  }

  return (
    <Container>
      <p>{step}</p>
    </Container>
  );
};
