import React, { useEffect, useState } from 'react';
import produce, { original } from 'immer';
import { useLocalStorage } from 'react-use';
import {
  ColorMode,
  LinearGradientColor,
  RadialGradientColor,
  SolidColor,
} from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/models';
import {
  DEFAULT_LINEAR_COLOR,
  DEFAULT_RADIAL_COLOR,
  DEFAULT_SOLID_COLOR,
  generateStyleStringFromColor,
  getDefinedValue,
  parseLinearColor,
  parseRadialColor,
  parseSolidColor,
  presetLinearColors,
  presetSolidColors,
  rgbToHsv,
} from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/utils';
import { CustomizationSpec } from '@/webapi/use-widget-catalog-api';

const SOLID_COLORS_KEY = `lmi_solid_colors`;
const LINEAR_COLORS_KEY = `lmi_linear_colors`;
const RADIAL_COLORS_KEY = `lmi_radial_colors`;

export const BackgroundColorContext = React.createContext(
  {} as BackgroundColorContextProps,
);

export function newBackgroundColorContextProps(
  initialMode: ColorMode,
  initialValue: string,
  spec?: CustomizationSpec,
): BackgroundColorContextProps {
  const [mode, setMode] = useState(initialMode);

  const [solidColor, setSolidColor] = useState(
    mode === ColorMode.SOLID
      ? parseSolidColor(initialValue)
      : DEFAULT_SOLID_COLOR,
  );

  const [linearColor, setLinearColor] = useState(
    mode === ColorMode.LINEAR
      ? parseLinearColor(initialValue)
      : DEFAULT_LINEAR_COLOR,
  );

  const [radialColor, setRadialColor] = useState(
    mode === ColorMode.RADIAL
      ? parseRadialColor(initialValue)
      : DEFAULT_RADIAL_COLOR,
  );

  const [solidFavourites, setSolidFavourites] = useLocalStorage<SolidColor[]>(
    SOLID_COLORS_KEY,
    [],
  );

  const [linearFavourites, setLinearFavourites] = useLocalStorage<
    LinearGradientColor[]
  >(LINEAR_COLORS_KEY, []);

  const [radialFavourites, setRadialFavourites] = useLocalStorage<
    RadialGradientColor[]
  >(RADIAL_COLORS_KEY, []);

  const updateSolidColor = (color: SolidColor, edited = false) => {
    const updated = produce(solidColor, (draft) => {
      draft.red = getDefinedValue(color.red, draft?.red);
      draft.green = getDefinedValue(color.green, draft?.green);
      draft.blue = getDefinedValue(color.blue, draft?.blue);
      const firstTimeEdit = edited && spec?.initialState && draft?.alpha === 0;
      draft.alpha = getDefinedValue(
        firstTimeEdit ? 1 : color.alpha,
        draft?.alpha,
      );

      const hsv = rgbToHsv(draft?.red, draft?.green, draft?.blue);
      draft.hue = color?.hue || getDefinedValue(hsv.hue, draft?.hue);
      draft.saturation = getDefinedValue(hsv.saturation, draft?.saturation);
      draft.value = getDefinedValue(hsv.value, draft?.value);
    });
    setSolidColor(updated);
  };

  const updateLinearColor = (color: LinearGradientColor) => {
    const updated = produce(linearColor, (draft) => {
      draft.angle = color.angle;
      draft.points = color.points.map((p, idx) => ({
        ...mutateSolid(p, original(draft).points[idx]),
        left: p.left,
      }));
    });
    setLinearColor(updated);
  };

  const updateRadialColor = (color: RadialGradientColor) => {
    const updated = produce(radialColor, (draft) => {
      draft.points = color.points.map((p, idx) => ({
        ...mutateSolid(p, draft.points[idx]),
        left: p.left,
      }));
    });
    setRadialColor(updated);
  };

  useEffect(() => {
    if (mode === ColorMode.SOLID) updateSolidColor(solidColor);
    else if (mode === ColorMode.LINEAR) updateLinearColor(linearColor);
    else if (mode === ColorMode.RADIAL) updateRadialColor(radialColor);
  }, [mode]);

  const setDefaultFavSolidColors = () => {
    const updated = produce(solidFavourites, (draft) => {
      presetSolidColors.forEach((val) => {
        const color = parseSolidColor(val);
        const styleStr = generateStyleStringFromColor(color);
        const alreadyExists = draft.filter(
          (c) => generateStyleStringFromColor(c) === styleStr,
        );
        if (alreadyExists.length === 0) {
          draft.push({ ...color });
        }
      });
    });
    setSolidFavourites(updated);
  };

  const setDefaultFavLinearColors = () => {
    const updated = produce(linearFavourites, (draft) => {
      presetLinearColors.forEach((color) => {
        const styleStr = generateStyleStringFromColor(color);
        const alreadyExists = draft.filter(
          (c) => generateStyleStringFromColor(c) === styleStr,
        );
        if (alreadyExists.length === 0) {
          draft.push({ ...color });
        }
      });
    });
    setLinearFavourites(updated);
  };

  useEffect(() => {
    setDefaultFavSolidColors();
    setDefaultFavLinearColors();
  }, []);

  return {
    mode,
    setMode,

    solidColor,
    updateSolidColor,
    linearColor,
    updateLinearColor,
    radialColor,
    updateRadialColor,

    solidFavourites,
    setSolidFavourites,
    linearFavourites,
    setLinearFavourites,
    radialFavourites,
    setRadialFavourites,
  };
}

export interface BackgroundColorContextProps {
  mode: ColorMode;
  setMode: (mode: ColorMode) => void;

  solidColor: SolidColor;
  updateSolidColor: (color: SolidColor, edited?: boolean) => void;
  linearColor: LinearGradientColor;
  updateLinearColor: (color: LinearGradientColor) => void;
  radialColor: RadialGradientColor;
  updateRadialColor: (color: RadialGradientColor) => void;

  solidFavourites: SolidColor[];
  setSolidFavourites: (color: SolidColor[]) => void;
  linearFavourites: LinearGradientColor[];
  setLinearFavourites: (color: LinearGradientColor[]) => void;
  radialFavourites: RadialGradientColor[];
  setRadialFavourites: (color: RadialGradientColor[]) => void;
}

function mutateSolid(color: SolidColor, draft: SolidColor): SolidColor {
  const out = {} as SolidColor;
  out.red = getDefinedValue(color.red, draft?.red);
  out.green = getDefinedValue(color.green, draft?.green);
  out.blue = getDefinedValue(color.blue, draft?.blue);
  out.alpha = getDefinedValue(color.alpha, draft?.alpha);

  const hsv = rgbToHsv(draft?.red, draft?.green, draft?.blue);
  out.hue = getDefinedValue(hsv.hue, draft?.hue);
  out.saturation = getDefinedValue(hsv.saturation, draft?.saturation);
  out.value = getDefinedValue(hsv.value, draft?.value);
  return out;
}
