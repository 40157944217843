import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { DefaultTypography } from '@/components/typography';
import { CloseButton } from '@/components/close-button';
import { BigButton } from '@/components/big-button';
import { HSpace } from '@/components/spacing';
import { Flexbox } from '@/components/flex';
import { uploadPosterImage } from '@/features/editor/widgets/custom-widget/inputs/background/video/utils';
import { useMediaApi } from '@/webapi/use-media-api';

export function PosterImageOverlay({ videoRef, onPosterImage, hide }) {
  const posterVidRef = useRef(null);
  const { uploadImage } = useMediaApi();
  const [loading, setLoading] = useState(false);

  const posterSelected = async () => {
    setLoading(true);
    const resp = await uploadPosterImage(posterVidRef, uploadImage);
    setLoading(false);
    onPosterImage(resp.thumbnail);
    hide();
  };

  return (
    <Wrapper style={{ opacity: loading ? `0.3` : `1` }}>
      <Header>
        <Title>Choose an opening image</Title>
        <CloseButton onClick={hide} />
      </Header>
      <Explain>
        Navigate the video until you find the shot you want to use as an opening
        image
      </Explain>
      <Video
        ref={posterVidRef}
        controls
        muted
        crossOrigin="Anonymous"
        src={videoRef?.current.src}
      />
      <Flexbox direction="row" padding="2rem">
        <BigButton
          disabled={loading}
          background="#FFFFFF"
          color="#8C9BA8"
          border="1px solid #A6AFB8"
          noTransform
          fillWidth
          noShadow
          size="medium"
          onClick={hide}
        >
          Cancel
        </BigButton>
        <HSpace value={2} />
        <BigButton
          disabled={loading}
          background="#334BFF"
          color="#FFFFFF"
          noTransform
          fillWidth
          noShadow
          size="medium"
          onClick={posterSelected}
        >
          Add opening image
        </BigButton>
      </Flexbox>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 2rem;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  grid-template-rows: 3rem 3rem calc(100% - 3rem - 3rem - 8rem) 8rem;
  align-items: center;
  justify-content: center;

  transition: opacity 0.5s ease-in-out;
`;

const Header = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  .gatsby-image-wrapper {
    height: 2rem;
    width: 2rem;
  }
`;

const Title = styled(DefaultTypography)`
  && {
    color: #4b5564;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -0.58px;
    text-align: center;
    width: 100%;
  }
`;

const Explain = styled(DefaultTypography)`
  && {
    color: #4b5564;
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: -0.58px;
    text-align: center;
    width: 100%;
  }
`;

const Video = styled.video`
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center center;
  border-radius: 0.8rem;
`;
