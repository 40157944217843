import { useCallback, useContext, useEffect, useRef } from 'react';
import { Moment } from 'moment';
import { DeviceType } from '@/utils/definitions';
import { HistoryOpts } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';
import { useDetachedState } from '@/components/hooks/use-detached-state';
import { formatDate } from '@/features/editor/shared/format-date';

export function useDatePickerInput(
  initialValues: (key?: string, device?: DeviceType, specIdx?: number) => any,
  onValuesChanged: (
    key: string,
    value: any,
    device?: DeviceType,
    historyOpts?: HistoryOpts,
  ) => void,
) {
  const { undoRedoCount } = useContext(UndoRedoCounterContext);
  const defaultValue = useCallback(
    () => initialValues(`value`, DeviceType.Mobile),
    [undoRedoCount],
  );

  const [value, setValue, valueRef] = useDetachedState<string>(defaultValue());
  const inputRef = useRef(null);
  useEffect(() => {
    const v = defaultValue();
    setValue(v);
    if (inputRef.current) {
      inputRef.current.value = v;
    }
  }, [undoRedoCount]);

  const onChange = (ev: Moment) => {
    setValue(formatDate(ev.toDate()));
  };

  useEffect(() => {
    onValuesChanged(`value`, valueRef.current, DeviceType.Mobile);
  }, [value]);

  return {
    value: new Date(value).valueOf() / 1000,
    inputRef,
    onChange,
  };
}
