import React, { useContext } from 'react';
import styled from 'styled-components';
import { BigButton } from '@/components/big-button';
import { AdvancedRuling } from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/context';

export function SaveRuleBtn({ onClick }: { onClick: () => void }) {
  const { cantSave, inSearchMode } = useContext(AdvancedRuling);
  return (
    <FloatingSection>
      {!inSearchMode && (
        <SaveBtn>
          <BigButton
            disabled={cantSave()}
            onClick={onClick}
            type="button"
            border="2px solid black"
            noTransform
            size="xl"
            fillWidth
          >
            Save
          </BigButton>
        </SaveBtn>
      )}
    </FloatingSection>
  );
}

const SaveBtn = styled.div`
  width: 300px;
  margin: 0 auto 2rem auto;
`;

const FloatingSection = styled.div`
  backdrop-filter: opacity(0.2);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;
