import styled, { keyframes } from 'styled-components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IoTrashOutline } from 'react-icons/io5';
import { IoIosCheckmark, IoIosClose } from 'react-icons/io';
import { PopConfirm } from '@/components/pop-confirm';

export type ChipMode =
  | `clickable`
  | `selectable`
  | `selectable-removable`
  | `editable`;

export interface ChipProps {
  payload?: any;

  isSelected?: boolean;
  mode?: ChipMode;

  onClick?: () => void;
  onChange?: (selected: boolean) => void;
  onRemove?: (payload: any) => void;
  onEdit?: (newText: string, payload?: any) => void;
  clearAfterEdit?: boolean;

  children?: any;
}

export function Chip({
  payload,
  isSelected,
  onChange,
  children,
  onClick,
  mode,
  onRemove,
  onEdit,
  clearAfterEdit,
}: ChipProps) {
  const [editValue, setEditValue] = useState(``);
  const [editing, setEditing] = useState(false);
  const [selected, setSelected] = useState(isSelected);
  const [prevSelection, setPrevSelection] = useState(isSelected);
  const [isHover, setIsHover] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const onWrapperClicked = () => {
    if (!editing) {
      onChipClicked();
    }
  };

  const onChipClicked = () => {
    if ([`selectable`, `selectable-removable`].includes(mode)) {
      setSelected(!selected);
    } else if (mode === `clickable`) {
      onClick && onClick();
    } else if (mode === `editable`) {
      setEditing(true);
      setTimeout(() => inputRef.current?.focus(), 250);
    }
  };

  useEffect(() => {
    if (onChange && prevSelection !== selected) {
      onChange(selected);
      setPrevSelection(selected);
    }
  }, [selected, prevSelection]);

  const shouldShowDelete = useMemo(
    () => isHover && !selected && mode === `selectable-removable`,
    [isHover, selected, mode],
  );

  const shouldShowEdit = useMemo(
    () => mode === `editable` && !!editing,
    [mode, editing],
  );

  const onDeleteLabel = async (_: any) => {
    onRemove && onRemove(payload);
    setTimeout(() => onMouseLeave(), 400);
  };

  const onMouseEnter = () => setIsHover(true);

  const onMouseLeave = () => setIsHover(false);

  const onApproveEdit = () => {
    onEdit && onEdit(editValue, payload);
    if (clearAfterEdit) setEditValue(``);
    setEditing(false);
  };

  const onRevertEdit = () => {
    setEditing(false);
  };

  const onInputChange = (ev) => {
    setEditValue(ev.target.value);
  };

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === `Enter`) {
      onApproveEdit();
    }
  };

  return (
    <Wrapper
      onClick={onWrapperClicked}
      className={`${selected ? `selected` : ``} ${mode}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div onClick={onChipClicked}>
        {mode === `editable` && (
          <TransparentInput
            style={{
              position: editing ? `relative` : `absolute`,
              width: editing ? `auto` : `0`,
              opacity: editing ? `1` : `0`,
              pointerEvents: editing ? `auto` : `none`,
            }}
            onKeyDown={onInputKeyDown}
            ref={inputRef}
            onChange={onInputChange}
            value={editValue}
          />
        )}
        <span
          style={{
            display: `flex`,
            height: editing ? `0` : `auto`,
            width: editing ? `0` : `auto`,
            opacity: editing ? `0` : `1`,
          }}
        >
          {editValue || children}
        </span>
      </div>
      {shouldShowDelete && (
        <ControlsWrapper>
          <div className="delete">
            <PopConfirm
              onConfirm={onDeleteLabel}
              onDiscard={onMouseLeave}
              text="Are you sure you want to remove this label? Doing so may impact other experiences"
            >
              <IoTrashOutline />
            </PopConfirm>
          </div>
        </ControlsWrapper>
      )}
      {shouldShowEdit && (
        <ControlsWrapper>
          <div className="approve btn" onClick={onApproveEdit}>
            <IoIosCheckmark size={18} />
          </div>
          <div className="revert btn" onClick={onRevertEdit}>
            <IoIosClose size={18} />
          </div>
        </ControlsWrapper>
      )}
    </Wrapper>
  );
}

const TransparentInput = styled.input`
  appearance: none;
  outline: none;
  border: none;
  border-radius: 0;
  background: none;

  height: 1.7rem;
  max-width: 12rem;

  color: inherit;
  font-size: inherit;
  font-family: inherit;
  text-transform: inherit;

  animation: expand 0.3s ease-in-out;

  @keyframes expand {
    from {
      width: 3rem;
    }
    to {
      width: 12rem;
    }
  }
`;

const BasicChipWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  height: fit-content;
  width: fit-content;
  padding: 0.8rem 2.3rem;

  background: rgba(216, 216, 216, 0.3);
  border-radius: 2rem;

  font-size: 1.3rem;
  letter-spacing: -0.2px;
  font-family: Inter, serif;
  text-transform: uppercase;
  color: rgb(0, 0, 0, 0.55);

  user-select: none;
  cursor: pointer;

  transition: color 0.2s ease-in-out, background 0.2s ease-in-out;

  &&.selected {
    background: black;
    color: white;
  }

  &&.editable {
    color: black;
  }

  &&.selectable:hover,
  &&.clickable:hover {
    opacity: 0.8;
  }

  &&.selectable:active,
  &&.clickable:active {
    opacity: 0.6;
  }
`;

const Wrapper = styled(BasicChipWrapper)`
  && {
  }
`;

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.4;
  }
`;

const ControlsWrapper = styled.div<{ shouldShowDelete: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 0.2s ease-in-out;
  gap: 0.5rem;

  .delete {
    margin-top: 0.2rem;
    cursor: context-menu;
    opacity: 0.4;
    position: absolute;
    right: 1rem;
    animation: ${FadeIn} 0.2s ease-in-out;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
  }

  .approve {
    margin-left: 0.5rem;
    background: #2cd893;
  }

  .revert {
    margin-right: -1rem;
    background: #cedcd6;
  }
`;
