import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import produce from 'immer';
import { ImageTile } from '@/features/editor/widgets/custom-widget/inputs/background/image/shared/image-tile';
import { Caption } from '@/features/editor/widgets/custom-widget/shared/shared-styles';
import {
  DropdownInput,
  DropdownInputOption,
} from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import {
  GenericInputProps,
  InputsMinimalTheme,
} from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { Line } from '@/features/editor/widgets/custom-widget/inputs/shared/card-wrapper';
import { EditorContext } from '@/features/editor/context/editor-context';
import { useSharedElement } from '@/components/use-shared-element';
import { ImageEditorModal } from '@/features/editor/widgets/custom-widget/inputs/background/image';
import { DeviceType } from '@/utils/definitions';
import { BACKDROP_ZINDEX } from '@/components/shared-element-overlay';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';

interface BackgroundImageHeaderProps extends GenericInputProps {
  openImageTooltip: MutableRefObject<boolean>;
  revertToActive: () => void;
}

export function BackgroundImageHeader({
  initialValues,
  onValuesChanged,
  device: specDevice,
  revertToActive,
  hover,
}: BackgroundImageHeaderProps) {
  const {
    inspectorRef,
    deviceRef,
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);
  const {
    newChange,
    measurements,
    imgMeasurementForHover,
    undoRedoCount,
    shouldOpenModalOnInit,
  } = useContext(CustomWidgetContext);

  const imageRef = useRef<HTMLElement>(null);

  const getModalRect = () =>
    ({
      width: inspectorRef?.current.getBoundingClientRect().width,
      left: inspectorRef?.current.getBoundingClientRect().left,
      height: deviceRef?.current?.getBoundingClientRect().height,
      top: deviceRef?.current?.getBoundingClientRect().top,
    } as DOMRect);
  const { SharedElementOverlay, props, show, hide } = useSharedElement(
    {
      onCloseCb: () => !isSavedRef.current && revertToActive(),
      widthMultiplier: device === DeviceType.Desktop ? 1 : 0.8,
      showBackdrop: true,
      extraFrom: {
        background: `#dedede`,
        opacity: `0`,
      },
      extraTo: {
        background: `white`,
        opacity: `1`,
      },
    },
    imageRef,
    getModalRect,
  );

  const getImage = useCallback(
    () => initialValues(`value`, device),
    [initialValues, onValuesChanged, undoRedoCount],
  );

  const [savedImage, setSavedImage] = useState(getImage());

  const isSavedRef = useRef(false);

  const getAspectRatio = useCallback(() => {
    if (hover) {
      const rect = imgMeasurementForHover?.[newChange.editorId];
      return rect ? rect.width / rect.height : undefined;
    }
    if (measurements) {
      const rect = measurements?.[newChange.editorId];
      return rect ? rect.width / rect.height : undefined;
    }
    return undefined;
  }, [measurements, imgMeasurementForHover]);

  const onUpdateImagePreview = (blob: string) => {
    onValuesChanged(`value`, `url("${blob}")`, specDevice, {
      ignoreHistory: true,
    });
  };

  const onUpdateImage = (link: string) => {
    onValuesChanged(`value`, `url("${link}")`, specDevice);
  };

  useEffect(() => {
    if (deviceRef?.current?.style) {
      deviceRef.current.style.zIndex = `${BACKDROP_ZINDEX + 100}`;
    }

    return () => {
      if (deviceRef?.current?.style) {
        deviceRef.current.style.zIndex = `0`;
      }
    };
  }, [deviceRef]);

  useEffect(() => {
    if (shouldOpenModalOnInit && shouldOpenModalOnInit()) show();
  }, []);

  return (
    <HeaderWrapper ref={imageRef}>
      <ImageTile onClick={show} src={getImage()} />
      <SharedElementOverlay {...props} onBackdropClick={() => undefined}>
        <ImageEditorModal
          isSavedRef={isSavedRef}
          savedImage={savedImage}
          setSavedImage={setSavedImage}
          image={getImage()}
          onUpdatePreview={onUpdateImagePreview}
          onUpdateImage={onUpdateImage}
          aspectRatio={getAspectRatio()}
          onClose={hide}
          getModalRect={getModalRect}
        />
      </SharedElementOverlay>
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-rows: 3rem;
  grid-template-columns: 4rem;
  align-items: center;
  justify-content: flex-end;
`;

const fitOptions: DropdownInputOption[] = [
  { label: `None`, value: `auto` },
  { label: `Contain`, value: `contain` },
  { label: `Cover`, value: `cover` },
];

const posOptions: DropdownInputOption[] = [
  { label: `Center`, value: `center center` },
  { label: `Center Top`, value: `center top` },
  { label: `Center Bottom`, value: `center bottom` },
  { label: `Left Top`, value: `left top` },
  { label: `Left Center`, value: `left center` },
  { label: `Left Bottom`, value: `left bottom` },
  { label: `Right Top`, value: `right top` },
  { label: `Right Center`, value: `right center` },
  { label: `Right Bottom`, value: `right bottom` },
];

const objectFitKey = `size`;
const positionKey = `position`;

export function BackgroundImageControls({
  device,
  initialValues,
  onValuesChanged,
  isActive,
}) {
  const { undoRedoCount } = useContext(UndoRedoCounterContext);
  const getImage = useCallback(
    () => initialValues(`image`, device) || {},
    [undoRedoCount],
  );

  const defaultObjectFit = getImage()[objectFitKey] || fitOptions[2].value;
  const defaultPosition = getImage()[positionKey] || posOptions[0].value;

  const [objectFit, setObjectFit] = useState(defaultObjectFit);
  const [position, setPosition] = useState(defaultPosition);
  useEffect(() => {
    setObjectFit(getImage()[objectFitKey] || fitOptions[2].value);
    setPosition(getImage()[positionKey] || posOptions[0].value);
  }, [undoRedoCount]);

  useEffect(() => {
    const value = getImage();
    const updated = produce(value, (draft) => {
      draft[objectFitKey] = objectFit;
      draft[positionKey] = position;
      return draft;
    });

    if (isActive) {
      onValuesChanged(`image`, updated, device);
    }
  }, [objectFit, position]);

  return (
    <div>
      <Line>
        <Caption>Fit</Caption>
        <DropdownInput
          key={`${undoRedoCount}1`}
          theme={InputsMinimalTheme}
          options={fitOptions}
          defaultValue={
            fitOptions.filter((opt) => opt.value === objectFit)?.[0]
          }
          onChange={(sel) => setObjectFit(sel.value)}
        />
      </Line>
      <Line>
        <Caption>Position</Caption>
        <DropdownInput
          key={`${undoRedoCount}2`}
          theme={InputsMinimalTheme}
          options={posOptions}
          defaultValue={posOptions.filter((opt) => opt.value === position)?.[0]}
          onChange={(sel) => setPosition(sel.value)}
        />
      </Line>
    </div>
  );
}
