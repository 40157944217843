import { CachePolicies, useFetch } from 'use-http';
import { routes } from '@/webapi/routes';
import { ProductsResponse } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/context/model';

export const UPSELLS_APP_ID = `upsells`;

export function useCatalogApi() {
  const { post, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: true,
    },
  }));

  const listEntireCatalog = (page: number, pageSize: number) =>
    post(routes.listCatalog(), {
      page,
      pageSize,
    }) as Promise<CatalogResponse>;

  const listProductsByText = (
    page: number,
    size: number,
    options: { query: string },
  ) =>
    post(routes.listRecommendationProducts(), {
      page,
      size,
      query: options?.query || ``,
    } as any) as Promise<ProductsResponse>;

  const listProductsByIds = (productIds: Array<string>) =>
    post(routes.listRecommendationProducts(), {
      page: 0,
      size: 25,
      productIds,
    } as any) as Promise<ProductsResponse>;

  const listProducts = (searchText: string, page?: number) =>
    post(routes.listRecommendationProducts(), {
      page: page || 0,
      size: 25,
      query: searchText,
    } as any) as Promise<ProductsResponse>;

  return {
    loading,
    listEntireCatalog,
    listProductsByText,
    listProductsByIds,
    listProducts,
  };
}

export interface CatalogResponse {
  products: Array<Product>;
  collections: Array<Collection>;
  productTags: Array<string>;
  customerTags: Array<string>;
  reviewsEnabled?: boolean;
  currencies: Array<string>;
  productCount: number;
  mainAttribute: string;
  mainAttributeOptions: Array<string>;
}

export interface Collection {
  id: number;
  handle: string;
  title: string;
}

export interface Product {
  id: string;
  collections: string[];
  handle: string;
  product_type: string;
  title: string;
  image: {
    src: string;
    height: number;
    width: number;
  };
  tags: string[];
  rank: number;
  options: ProductOption[];
  variantIds: number[];
}

export interface ProductOption {
  name: string;
  values: string[];
}
