import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { ImageEditorTab } from '@/features/editor/widgets/custom-widget/inputs/background/image/shared/models';
import { extractBlobFromBackgroundUrl } from '@/features/editor/widgets/custom-widget/inputs/shared/utils';

export const ImageEditorContext = React.createContext(
  {} as ImageEditorContextProps,
);

export function newImageEditorContext(
  origImage: string,
  aspectRatio?: number | undefined,
): ImageEditorContextProps {
  const [mode, setMode] = useState(ImageEditorTab.ADD_IMAGE);
  const blobToCompress = useRef<Blob>();
  const [shouldCompress, setShouldCompress] = useState(false);
  const [image, setImage] = useState<string | undefined>(
    origImage === `` || isPlaceholder(origImage)
      ? undefined
      : extractBlobFromBackgroundUrl(origImage),
  );

  useEffect(() => {
    if (image && !isPlaceholder(image)) {
      setMode(ImageEditorTab.EDIT);
    }

    if (!image && mode === ImageEditorTab.EDIT) {
      setMode(ImageEditorTab.ADD_IMAGE);
    }
  }, [image]);

  return {
    blobToCompress,
    shouldCompress,
    setShouldCompress,
    mode,
    setMode,
    image,
    setImage,
    aspectRatio,
  };
}

export interface ImageEditorContextProps {
  blobToCompress: MutableRefObject<Blob>;
  shouldCompress: boolean;
  setShouldCompress: (b: boolean) => void;
  mode: ImageEditorTab;
  setMode: (tab: ImageEditorTab) => void;
  image: string | undefined;
  setImage: (image: string) => void;
  aspectRatio: number | undefined;
}

export function isPlaceholder(origImage: string) {
  return (
    origImage.endsWith(`placeholder.svg)`) ||
    origImage.endsWith(`placeholder.svg`) ||
    origImage.endsWith(`placeholder.jpeg)`) ||
    origImage.endsWith(`placeholder.jpeg`)
  );
}
