import React, { useContext, useRef, useState } from 'react';
import { TargetingButton } from '@/features/editor/widgets/shared/targeting-controls/targeting-button';
import { EditorContext } from '@/features/editor/context/editor-context';
import { formatEnum } from '@/utils/types';
import { PlacementModal } from '@/features/editor/widgets/shared/modals/placement-modal';

export function PlacementButton({ isDisabled }: { isDisabled?: boolean }) {
  const {
    experienceState: { currentExperience },
  } = useContext(EditorContext);
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const onClick = () => {
    setIsVisible(true);
  };

  return (
    <>
      <PlacementModal
        fromRef={ref}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
      <TargetingButton
        wrapperRef={ref}
        title="Pages"
        subtitle={`${formatEnum(currentExperience.targeting.placement.kind)}`}
        onClick={onClick}
        isDisabled={isDisabled}
      />
    </>
  );
}
