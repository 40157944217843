import styled from 'styled-components';
import { DefaultTypography } from '@/components/typography';

export const GeneralText = styled(DefaultTypography)`
  && {
    user-select: none;
    font-size: 1.4rem;
    color: #777e81;
    letter-spacing: -0.6px;
    font-weight: normal;
  }
`;
