import {
  ColorMode,
  LinearGradientColor,
  RadialGradientColor,
  SolidColor,
} from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/models';
import { parseGradient } from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/gradient-parser';

export const DEFAULT_SOLID_COLOR: SolidColor = {
  red: 178,
  green: 88,
  blue: 211,
  alpha: 1,
  hue: 284,
  saturation: 58,
  value: 83,
};
export const DEFAULT_LINEAR_COLOR: LinearGradientColor = {
  points: [
    { red: 255, green: 0, blue: 0, alpha: 1, left: 0 },
    { red: 0, green: 255, blue: 0, alpha: 1, left: 100 },
  ],
  angle: 90,
};
export const DEFAULT_RADIAL_COLOR: RadialGradientColor = {
  points: [
    { red: 255, green: 0, blue: 0, alpha: 1, left: 0 },
    { red: 0, green: 255, blue: 0, alpha: 1, left: 100 },
  ],
};

export const presetSolidColors = [
  `#F44336`,
  `#E91E63`,
  `#673AB7`,
  `#3F51B5`,
  `#03A9F4`,
  `#009688`,
  `#4CAF50`,
  `#CDDC39`,
  `#FFC107`,
  `#607D8B`,
  `#CFD8DC`,
];

export const presetLinearColors: LinearGradientColor[] = [
  {
    points: [
      { red: 247, green: 121, blue: 125, alpha: 1, left: 0 },
      { red: 251, green: 215, blue: 134, alpha: 1, left: 50 },
      { red: 198, green: 255, blue: 221, alpha: 1, left: 100 },
    ],
    angle: 90,
  },
  {
    points: [
      { red: 246, green: 79, blue: 89, alpha: 1, left: 0 },
      { red: 196, green: 113, blue: 237, alpha: 1, left: 50 },
      { red: 18, green: 194, blue: 233, alpha: 1, left: 100 },
    ],
    angle: 90,
  },
  {
    points: [
      { red: 185, green: 29, blue: 115, alpha: 1, left: 0 },
      { red: 249, green: 83, blue: 198, alpha: 1, left: 100 },
    ],
    angle: 90,
  },
  {
    points: [
      { red: 245, green: 175, blue: 25, alpha: 1, left: 0 },
      { red: 241, green: 39, blue: 17, alpha: 1, left: 100 },
    ],
    angle: 90,
  },
  {
    points: [
      { red: 120, green: 255, blue: 214, alpha: 1, left: 0 },
      { red: 168, green: 255, blue: 120, alpha: 1, left: 100 },
    ],
    angle: 90,
  },
  {
    points: [
      { red: 63, green: 43, blue: 150, alpha: 1, left: 0 },
      { red: 168, green: 192, blue: 255, alpha: 1, left: 100 },
    ],
    angle: 90,
  },
  {
    points: [
      { red: 253, green: 187, blue: 45, alpha: 1, left: 0 },
      { red: 178, green: 31, blue: 31, alpha: 1, left: 50 },
      { red: 26, green: 42, blue: 108, alpha: 1, left: 100 },
    ],
    angle: 90,
  },
  {
    points: [
      { red: 41, green: 255, blue: 198, alpha: 1, left: 0 },
      { red: 32, green: 227, blue: 178, alpha: 1, left: 50 },
      { red: 12, green: 235, blue: 235, alpha: 1, left: 100 },
    ],
    angle: 90,
  },
  {
    points: [
      { red: 255, green: 210, blue: 0, alpha: 1, left: 0 },
      { red: 247, green: 151, blue: 30, alpha: 1, left: 100 },
    ],
    angle: 90,
  },
  {
    points: [
      { red: 255, green: 106, blue: 0, alpha: 1, left: 0 },
      { red: 238, green: 9, blue: 121, alpha: 1, left: 100 },
    ],
    angle: 90,
  },
];

function isValidRGBValue(value: number) {
  return (
    typeof value === `number` &&
    Number.isNaN(value) === false &&
    value >= 0 &&
    value <= 255
  );
}

export function sanitizeRGB(red, green, blue, alpha?): SolidColor {
  if (isValidRGBValue(red) && isValidRGBValue(green) && isValidRGBValue(blue)) {
    const color = {
      // eslint-disable-next-line no-bitwise
      red: red || 0,
      // eslint-disable-next-line no-bitwise
      green: green || 0,
      // eslint-disable-next-line no-bitwise
      blue: blue || 0,
    } as SolidColor;

    if (isValidRGBValue(alpha) === true) {
      // eslint-disable-next-line no-bitwise
      color[`alpha`] = alpha || 1;
    }

    return color;
  }
  return undefined;
}

export default function getRgbByHue(hue: number): SolidColor {
  let C = 1;
  const H = hue / 60;
  let X = C * (1 - Math.abs((H % 2) - 1));
  const m = 0;
  const precision = 255;
  let r = 0;
  let g = 0;
  let b = 0;

  // eslint-disable-next-line no-bitwise
  C = ((C + m) * precision) | 0;
  // eslint-disable-next-line no-bitwise
  X = ((X + m) * precision) | 0;

  if (H >= 0 && H < 1) {
    // eslint-disable-next-line no-bitwise
    r = C | 0;
    // eslint-disable-next-line no-bitwise
    g = X | 0;
    // eslint-disable-next-line no-bitwise
    b = m | 0;
  }
  if (H >= 1 && H < 2) {
    // eslint-disable-next-line no-bitwise
    r = X | 0;
    // eslint-disable-next-line no-bitwise
    g = C | 0;
    // eslint-disable-next-line no-bitwise
    b = m | 0;
  }
  if (H >= 2 && H < 3) {
    // eslint-disable-next-line no-bitwise
    r = m | 0;
    // eslint-disable-next-line no-bitwise
    g = C | 0;
    // eslint-disable-next-line no-bitwise
    b = X | 0;
  }
  if (H >= 3 && H < 4) {
    // eslint-disable-next-line no-bitwise
    r = m | 0;
    // eslint-disable-next-line no-bitwise
    g = X | 0;
    // eslint-disable-next-line no-bitwise
    b = C | 0;
  }
  if (H >= 4 && H < 5) {
    // eslint-disable-next-line no-bitwise
    r = X | 0;
    // eslint-disable-next-line no-bitwise
    g = m | 0;
    // eslint-disable-next-line no-bitwise
    b = C | 0;
  }
  if (H >= 5 && H <= 6) {
    // eslint-disable-next-line no-bitwise
    r = C | 0;
    // eslint-disable-next-line no-bitwise
    g = m | 0;
    // eslint-disable-next-line no-bitwise
    b = X | 0;
  }

  return {
    red: r,
    green: g,
    blue: b,
  };
}

export function rgbToHsv(red: number, green: number, blue: number): SolidColor {
  let rr;
  let gg;
  let bb;
  let h;
  let s;

  const rabs = red / 255;
  const gabs = green / 255;
  const babs = blue / 255;
  const v = Math.max(rabs, gabs, babs);
  const diff = v - Math.min(rabs, gabs, babs);
  const diffc = (c) => (v - c) / 6 / diff + 1 / 2;
  if (diff === 0) {
    h = 0;
    s = 0;
  } else {
    s = diff / v;
    rr = diffc(rabs);
    gg = diffc(gabs);
    bb = diffc(babs);

    if (rabs === v) {
      h = bb - gg;
    } else if (gabs === v) {
      h = 1 / 3 + rr - bb;
    } else if (babs === v) {
      h = 2 / 3 + gg - rr;
    }
    if (h < 0) {
      h += 1;
    } else if (h > 1) {
      h -= 1;
    }
  }

  return {
    red,
    blue,
    green,
    hue: Math.round(h * 360),
    saturation: Math.round(s * 100),
    value: Math.round(v * 100),
  };
}

export function hsvToRgb(
  hue: number,
  saturation: number,
  value: number,
): SolidColor {
  value /= 100;
  const sat = saturation / 100;
  let C = sat * value;
  const H = hue / 60;
  let X = C * (1 - Math.abs((H % 2) - 1));
  let m = value - C;
  const precision = 255;

  // eslint-disable-next-line no-bitwise
  C = ((C + m) * precision) | 0;
  // eslint-disable-next-line no-bitwise
  X = ((X + m) * precision) | 0;
  // eslint-disable-next-line no-bitwise
  m = (m * precision) | 0;

  if (H >= 1 && H < 2) {
    return sanitizeRGB(X, C, m);
  }
  if (H >= 2 && H < 3) {
    return sanitizeRGB(m, C, X);
  }
  if (H >= 3 && H < 4) {
    return sanitizeRGB(m, X, C);
  }
  if (H >= 4 && H < 5) {
    return sanitizeRGB(X, m, C);
  }
  if (H >= 5 && H <= 6) {
    return sanitizeRGB(C, m, X);
  }

  return sanitizeRGB(C, X, m);
}

export function rgbToHex(red: number, green: number, blue: number): string {
  let r16 = red.toString(16);
  let g16 = green.toString(16);
  let b16 = blue.toString(16);

  if (red < 16) r16 = `0${r16}`;
  if (green < 16) g16 = `0${g16}`;
  if (blue < 16) b16 = `0${b16}`;

  return r16 + g16 + b16;
}

export function getHue(
  offsetX: number,
  width: number,
  saturation: number,
  value: number,
): SolidColor {
  // eslint-disable-next-line no-bitwise
  let hue = ((360 * offsetX) / width) | 0;

  // eslint-disable-next-line no-nested-ternary
  hue = hue < 0 ? 0 : hue > 360 ? 360 : hue;

  return {
    ...hsvToRgb(hue, saturation, value),
    hue,
  };
}

const hexRegexp =
  /(^#{0,1}[0-9A-F]{6}$)|(^#{0,1}[0-9A-F]{3}$)|(^#{0,1}[0-9A-F]{8}$)/i;

const regexp = /([0-9A-F])([0-9A-F])([0-9A-F])/i;

export function hexToRgb(value: string): SolidColor {
  const valid = hexRegexp.test(value);

  if (valid) {
    if (value[0] === `#`) value = value.slice(1, value.length);

    if (value.length === 3) value = value.replace(regexp, `$1$1$2$2$3$3`);

    const red = parseInt(value.substr(0, 2), 16);
    const green = parseInt(value.substr(2, 2), 16);
    const blue = parseInt(value.substr(4, 2), 16);
    const alpha = parseInt(value.substr(6, 2), 16) / 255;

    const color = sanitizeRGB(
      !Number.isNaN(red) ? red : 0,
      !Number.isNaN(green) ? green : 0,
      !Number.isNaN(blue) ? blue : 0,
      !Number.isNaN(alpha) ? alpha : 1,
    );
    const hsv = rgbToHsv(red, green, blue);

    return {
      valid,
      ...color,
      ...hsv,
    };
  }

  return undefined;
}

export function changePicker(x, y, height, width, hue): SolidColor {
  if (x > width) x = width;
  if (y > height) y = height;
  if (x < 0) x = 0;
  if (y < 0) y = 0;
  // eslint-disable-next-line no-bitwise
  const value = (100 - (y * 100) / height) | 0;
  // eslint-disable-next-line no-bitwise
  const saturation = ((x * 100) / width) | 0;
  return {
    ...hsvToRgb(hue, saturation, value),
    saturation,
    value,
  };
}

export function generateSolidStyle({
  red,
  green,
  blue,
  alpha,
}: SolidColor): string {
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

export function generateLinearGradientStyle({
  points,
  angle,
}: LinearGradientColor): string {
  let style = `linear-gradient(${angle}deg,`;
  const sortedPoints = points.slice();

  sortedPoints.sort((a, b) => a.left - b.left);

  sortedPoints.forEach((point, index) => {
    style += `rgba(${point.red}, ${point.green}, ${point.blue}, ${point.alpha}) ${point.left}%`;

    if (index !== sortedPoints.length - 1) {
      style += `,`;
    }
  });

  style += `)`;
  return style;
}

export function generateRadialGradientStyle({
  points,
}: RadialGradientColor): string {
  let style = `radial-gradient(`;
  const sortedPoints = points.slice();

  sortedPoints.sort((a, b) => a.left - b.left);

  sortedPoints.forEach((point, index) => {
    style += `rgba(${point.red}, ${point.green}, ${point.blue}, ${point.alpha}) ${point.left}%`;

    if (index !== sortedPoints.length - 1) {
      style += `,`;
    }
  });

  style += `)`;
  return style;
}

export function generateStyleStringFromColor(
  color: SolidColor | LinearGradientColor | RadialGradientColor,
): string {
  if (color) {
    if (`red` in color) return generateSolidStyle(color);
    if (`angle` in color) return generateLinearGradientStyle(color);
    if (`points` in color) return generateRadialGradientStyle(color);
  }
  return `#ffffff`;
}

export function getDefinedValue(newValue, oldValue) {
  return !newValue && newValue !== 0 ? oldValue : newValue;
}

export function parseSolidColor(str: string): SolidColor {
  try {
    if (str.includes(`NaN`)) {
      return {
        red: 0,
        blue: 0,
        green: 0,
        alpha: 1,
        saturation: 0,
        hue: 0,
        value: 0,
      } as SolidColor;
    }
    const temp = str.trim();
    if (temp.startsWith(`#`)) {
      return hexToRgb(temp);
    }

    const rgbaParts = temp.replace(/[^\d,.]/g, ``).split(`,`);
    const color: SolidColor = {
      red: parseInt(rgbaParts[0], 10),
      green: parseInt(rgbaParts[1], 10),
      blue: parseInt(rgbaParts[2], 10),
    };

    if (rgbaParts.length === 4) {
      color.alpha = parseFloat(rgbaParts[3]);
    }

    return color;
  } catch (ex) {
    console.error(`vsly`, ex);
    return {
      red: 10,
      green: 0,
      blue: 0,
      alpha: 0,
    };
  }
}

export function parseLinearColor(str: string): LinearGradientColor {
  try {
    const value = parseGradient(str);
    return {
      angle: parseInt(value?.[0]?.orientation?.value, 10) || 0,
      points: value?.[0].colorStops.map((stop) => ({
        red: parseInt(stop?.value?.[0], 10) || 0,
        green: parseInt(stop?.value?.[1], 10) || 0,
        blue: parseInt(stop?.value?.[2], 10) || 0,
        alpha: parseFloat(stop?.value?.[3]) || 1,
        left: parseInt(stop?.length?.value, 10) || 0,
      })),
    } as LinearGradientColor;
  } catch (ex) {
    return DEFAULT_LINEAR_COLOR;
  }
}

export function parseRadialColor(str: string): RadialGradientColor {
  try {
    const value = parseGradient(str);
    return {
      points: value?.[0].colorStops.map((stop) => ({
        red: parseInt(stop?.value?.[0], 10) || 0,
        green: parseInt(stop?.value?.[1], 10) || 0,
        blue: parseInt(stop?.value?.[2], 10) || 0,
        alpha: parseFloat(stop?.value?.[3]) || 1,
        left: parseInt(stop?.length?.value, 10) || 0,
      })),
    } as RadialGradientColor;
  } catch (ex) {
    return DEFAULT_RADIAL_COLOR;
  }
}

export function parseModeFromColor(colorStr: string): ColorMode {
  if (colorStr.startsWith(`linear-gradient`)) return ColorMode.LINEAR;
  if (colorStr.startsWith(`radial-gradient`)) return ColorMode.RADIAL;
  return ColorMode.SOLID;
}

export function isColor(str: string): boolean {
  if (!str) return false;
  if (str?.startsWith) {
    if (str?.startsWith(`#`) && str?.length <= 7) return true;
    if (str?.startsWith(`rgb`)) return true;
    if (str?.startsWith(`linear-gradient(`)) return true;
    return str?.startsWith(`radial-gradient(`);
  }
  return false;
}
