import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { QueryBuilder } from '@/components/query-builder';
import { LayeredRulingContext } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import { useQueryBuilder } from '@/features/editor/widgets/custom-widget/loading-section/util/useQueryBuilder';
import { ItemSelection } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule/condition/ItemSelection';
import { QBItemSelection } from '@/components/query-builder/models';
import { maybe } from '@/features/details/utils';
import { hasVariantSelectionSelection } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule/condition/utils';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';

type Props = { ruleId: number };

export const RuleCond: FC<Props> = ({ ruleId }) => {
  const selectMaxWidth = 250;
  const ctx = useContext(LayeredRulingContext);
  const rule = ctx.rule(ruleId);
  const { condition } = useQueryBuilder(ctx.isPostPurchase);
  const isEnabled = useFeatureBit(FeatureBit.VARIANT_ATTRIBUTES);
  const [init, setInit] = useState(false);

  const onChange = (items: Array<QBItemSelection>) => {
    init &&
      ctx.change(ruleId, (r) => {
        r.ruleCond.items = items;
        if (hasItemSelection(items)) {
          r.ruleCond.itemSelection = `some_items`;
        } else if (hasVariantSelectionSelection(items) && isEnabled) {
          r.ruleCond.itemSelection = `all_variants`;
        } else {
          r.ruleCond.itemSelection = ``;
        }
      });
  };

  useEffect(() => {
    setTimeout(() => {
      if (
        rule.ruleCond.itemSelection === `` &&
        rule.ruleCond.items.length === 0
      ) {
        ctx.change(ruleId, (r) => {
          r.ruleCond.itemSelection = `some_items`;
        });
      }
    }, 150);
    setInit(true);
  }, []);

  const andOrEnabled = useFeatureBit(FeatureBit.LAYERED_RULING_AND_OR);

  return (
    <Wrapper>
      <QueryBuilder
        whereNotion="IF"
        disableAndBtn={!andOrEnabled}
        selectMaxWidth={selectMaxWidth}
        onChange={onChange}
        initialProps={condition}
        initialState={rule.ruleCond.items}
      />
      <ItemSelection
        key={`k${maybe(() => rule.ruleCond.itemSelection, ``)}`}
        ruleId={ruleId}
      />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
`;

function hasItemSelection(items: Array<QBItemSelection>) {
  return [`tag`, `category`].includes(maybe(() => items[0].qbProps.envKey));
}
