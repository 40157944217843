import { Targeting } from '@/webapi/use-experience-api';
import { MetricValue } from '@/features/dashboard/reports/models';

export enum AudienceType {
  PRE_DEFINED = `PRE_DEFINED`,
  CUSTOMER_TAGS = `CUSTOMER_TAGS`,
  CUSTOM_AUDIENCE_TYPE = `CUSTOM_AUDIENCE_TYPE`,
}

export type Audience = {
  id: string;
  experienceId?: string;
  name: string;
  connectedExperiences?: number;
  description: string;
  targeting: Targeting;
  type: AudienceType;
  metrics: Array<MetricValue>;
  experiences: Array<{ id: string; name: string; status: string }>;
};
