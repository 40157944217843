import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { useDebouncedCallback } from 'use-debounce';
import {
  GenericInputProps,
  HistoryOpts,
} from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { Flexbox } from '@/components/flex';
import { DeviceType } from '@/utils/definitions';
import { Caption } from '@/features/editor/widgets/custom-widget/shared/shared-styles';
import { useDetachedState } from '@/components/hooks/use-detached-state';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';
import { AltTextTooltip } from '@/features/editor/widgets/custom-widget/inputs/typography/edit-icon';
import { useTextAltApi } from '@/features/editor/shared/use-alt-text';
import {
  CustomWidgetContext,
  CustomWidgetContextProps,
} from '@/features/editor/widgets/custom-widget/shared/context';
import { VSpace } from '@/components/spacing';
import { maybe } from '@/features/details/utils';
import { CustomizationSpec } from '@/webapi/use-widget-catalog-api';
import { RichTextEditor } from '@/features/editor/widgets/custom-widget/inputs/typography/rich-text';

export const INPUT_TEXT_EDIT = `input-text-edit`;

function InnerTextInput({
  defaultValue,
  hasFocus,
  name,
  onBlur,
  onChange,
  onFocus,
  ref,
}: {
  hasFocus: boolean;
  name: string;
  ref: React.MutableRefObject<null>;
  onFocus: () => void;
  onBlur: () => void;
  defaultValue: string;
  onChange: (ev) => void;
}) {
  return (
    <>
      <Prefix hasFocus={hasFocus}>{name}</Prefix>
      <Separate hasFocus={hasFocus} />
      <StyledInput
        ref={ref}
        onFocus={onFocus}
        onBlur={onBlur}
        defaultValue={defaultValue}
        onChange={onChange}
        maxRows={5}
        minRows={1}
        cacheMeasurements
      />
    </>
  );
}

export function TextEditInput(props: GenericInputProps) {
  const {
    spec,
    initialValues,
    onValuesChanged,
    customization,
    customizationIdx,
    device,
  } = props;
  const isRichText = maybe(() => spec?.values?.isRichText);
  const {
    hasFocus,
    value,
    inputRef,
    onChange,
    onFocus,
    onBlur,
    loading,
    handleAiAltText,
    id,
    keyHash,
  } = useTextInput(initialValues, onValuesChanged, spec.name, isRichText);

  const idx = useSocialProof(spec, onChange, value);
  const idToolbar = useMemo(
    () => `toolbar-${customization?.key || `header`}-${customizationIdx || 0}`,
    [],
  );
  return (
    <>
      {isRichText ? (
        <RichTextEditor
          key={`${spec.key}inputRichText${id}${keyHash}`}
          spec={spec}
          defaultValue={value}
          ref={inputRef}
          loading={loading}
          idToolbar={idToolbar}
          handleAiAltText={handleAiAltText}
          onChange={onChange}
          device={device}
          onValuesChanged={onValuesChanged}
          customization={customization}
        />
      ) : (
        <Wrapper
          key={`${spec.key}inputw${id}${idx}`}
          hasFocus={hasFocus}
          className={INPUT_TEXT_EDIT}
          isLongName={spec?.name?.length > 6}
        >
          <InnerTextInput
            key={`${spec.key}input${id}${keyHash}`}
            hasFocus={hasFocus}
            name={spec.name}
            ref={inputRef}
            onFocus={onFocus}
            onBlur={onBlur}
            defaultValue={value}
            onChange={onChange}
          />

          {!spec?.noAi && (
            <AltTextTooltip loading={loading} onClick={handleAiAltText} />
          )}
        </Wrapper>
      )}
      {spec.explain ? (
        <>
          <VSpace value={2} />
          <Explain>* {spec.explain}</Explain>
          <VSpace value={0.5} />
        </>
      ) : null}
    </>
  );
}

interface P {
  isLongName?: boolean;
}

const Explain = styled.span`
  letter-spacing: -0.38px;
  line-height: 2rem;
  font-family: 'JetBrains Mono', serif;
  font-size: 1.25rem;
  color: #96a3ae;
  font-weight: 600;
`;
const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props: P) =>
      props?.isLongName ? `auto` : `2.5rem`} 4.5rem 1fr 3rem;
  grid-auto-rows: min-content;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  background: white;
  padding: 0.8rem 1.7rem;
  border: ${(props: { hasFocus: boolean }) =>
    props.hasFocus ? `1px solid #95a2ae` : `1px solid #fff`};
  box-shadow: 0 9px 13px 0 rgba(177, 217, 203, 0.18);

  transition: border 0.3s ease-out;
`;

const Prefix = styled(Caption)`
  && {
    text-transform: capitalize;
  }
`;

const Separator = styled.div`
  width: 1px;
  height: 100%;

  background: rgba(75, 85, 100, 0.21);
`;

const StyledInput = styled(TextareaAutosize)`
  && {
    resize: none;
    outline: none;
    border: none;
    background: transparent;
    font-size: 1.4rem;
    font-weight: 600;
    font-family: 'JetBrains Mono', serif;
    letter-spacing: -0.44px;
    color: #4b5564;

    margin: 0;
  }
`;

function Separate({ hasFocus }: { hasFocus: boolean }) {
  return (
    <Flexbox
      style={{ height: `100%`, width: `100%` }}
      justify="center"
      align="center"
    >
      {hasFocus && <Separator />}
    </Flexbox>
  );
}

function getRecsType() {
  const customWidgetContext = useContext(CustomWidgetContext);
  let recType =
    customWidgetContext?.currentSchema?.settings?.loading?.value?.value?.type
      ?.toLowerCase()
      ?.replaceAll(`_`, ` `) || ``;
  if (recType === `manual` || recType === `advanced ruling`) {
    recType = `specially selected`;
  }
  if (recType === `cart items`) {
    recType = `cart`;
  }
  return recType;
}

function makeQuery(specName: string, value: string, recType?: string) {
  const isHeader = specName.toLowerCase() === `header`;
  const isSubHeader = specName.toLowerCase() === `sub-header`;
  const isAtcText = specName.toLowerCase() === `add`;
  const isLoadingMsg = specName.toLowerCase() === `loading`;
  const isSuccessMsg = specName.toLowerCase() === `success`;
  const isErrorMsg = specName.toLowerCase() === `error`;
  const isCountSuffix = specName.toLowerCase() === `count suffix`;
  const isProgressMsg = specName.toLowerCase().includes(`progress`);
  const isAchievedMsg = specName.toLowerCase().includes(`achieved`);
  const isInitialMsg = specName.toLowerCase().includes(`initial`);

  if (isHeader) {
    if (recType !== ``) {
      return `rephrase '${value}' as a product recommendation title, for ${recType} products. on an ecommerce website`;
    }
    return `rephrase '${value}' as a title. on an ecommerce website`;
  }
  if (isSubHeader) {
    if (recType !== ``) {
      return `rephrase '${value}' as a product recommendation sub-title, for ${recType} products. on an ecommerce website`;
    }
    return `rephrase '${value}' as a sub-title. on an ecommerce website`;
  }
  if (isAtcText) {
    return `rephrase '${value}' as a add to cart button text. on an ecommerce website`;
  }
  if (isLoadingMsg) {
    return `rephrase '${value}' as a loader text. on an ecommerce website`;
  }
  if (isSuccessMsg) {
    return `rephrase '${value}' as a success message. on an ecommerce website`;
  }
  if (isErrorMsg) {
    return `rephrase '${value}' as a error message. on an ecommerce website`;
  }
  if (isCountSuffix) {
    return `rephrase '${value}' as a one word elements count suffix. on an ecommerce website`;
  }
  if (isProgressMsg) {
    return `rephrase '${value}' as a in-progress message. on an ecommerce website`;
  }
  if (isAchievedMsg) {
    return `rephrase '${value}' as a goal achieved message. on an ecommerce website`;
  }
  if (isInitialMsg) {
    return `rephrase '${value}' as a initial step message. on an ecommerce website`;
  }
  return `rephrase '${value}' as description on an ecommerce website`;
}

function useTextInput(
  initialValues: (key?: string, device?: DeviceType, specIdx?: number) => any,
  onValuesChanged: (
    key: string,
    value: any,
    device?: DeviceType,
    historyOpts?: HistoryOpts,
  ) => void,
  specName: string,
  isRichText: any,
) {
  const [hasFocus, setHasFocus] = useState(false);
  const { undoRedoCount } = useContext(UndoRedoCounterContext);
  const [id, setId] = useState(0);
  const defaultValue = useCallback(
    () => initialValues(`value`, DeviceType.Mobile),
    [undoRedoCount],
  );

  const [value, setValue, valueRef] = useDetachedState<string>(defaultValue());
  const inputRef = useRef(null);
  const [keyHash, setKeyHash] = useState(``);
  useEffect(() => {
    const v = defaultValue();
    setValue(v);
    setKeyHash(`${undoRedoCount}${v}`);
    if (inputRef.current) {
      inputRef.current.value = v;
    }
  }, [undoRedoCount]);

  const onChange = (ev: any) => {
    if (ev && !ev.target?.value && isRichText) setValue(ev);
    else if (ev?.target?.value !== undefined) {
      setValue(ev.target.value);
    }
  };

  useEffect(() => {
    onValuesChanged(`value`, valueRef.current, DeviceType.Mobile);
  }, [value]);

  const onFocus = () => {
    setHasFocus(true);
  };

  const onBlur = () => {
    setHasFocus(false);
  };

  const { getAltText, loading } = useTextAltApi();

  const recType = getRecsType();
  const handleAiAltText = async () => {
    if (loading) {
      return;
    }

    const {
      options: [alt],
    } = await getAltText(makeQuery(specName, value, recType), 1);
    onChange({ target: { value: alt } });
    setId(id + 1);
  };

  useDebouncedCallback(handleAiAltText, 1500);
  return {
    keyHash,
    hasFocus,
    value,
    inputRef,
    onChange,
    onFocus,
    onBlur,
    loading,
    handleAiAltText,
    id,
  };
}

function useSocialProof(
  spec: CustomizationSpec,
  onChange: (ev: any) => void,
  value: string,
) {
  const ctx = useContext(CustomWidgetContext);
  const buy = isSocialProofBuy(ctx);

  const isSocialProof = isSocialProofText(ctx, spec);
  const [idx, setIdx] = useState(0);
  useEffect(() => {
    if (isSocialProof) {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        if (buy) {
          onChange({ target: { value: value.replace(`viewed`, `bought`) } });
          setIdx(idx + 1);
        } else {
          onChange({ target: { value: value.replace(`bought`, `viewed`) } });
          setIdx(idx + 1);
        }
      }, 500);
    }
  }, [isSocialProof, buy]);
  return idx;
}

function isSocialProofText(
  ctx: CustomWidgetContextProps,
  spec: CustomizationSpec,
) {
  return maybe(
    () =>
      ctx.currentSchema.blueprintName === `Social Proof` && spec.key === `text`,
  );
}

function isSocialProofBuy(ctx: CustomWidgetContextProps) {
  return maybe(
    () =>
      ctx.currentSchema.settings.general.specs.find((s) => s.key === `action`)
        ?.value?.value === `buy`,
  );
}
