import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { animated, config, useSpring } from '@react-spring/web';
import { useDebouncedWindowDims } from '@/utils/browser';

export const BACKDROP_ZINDEX = 150;
export const MORE_THEN_BACKDROP_ZINDEX = BACKDROP_ZINDEX + 10;

export interface SharedElementOverlayProps {
  isVisible: boolean;
  fromElement: MutableRefObject<HTMLElement>;
  toPosition: MutableRefObject<HTMLElement>;
  children?: JSX.Element;
  showBackdrop?: boolean;
  heightMultiplier?: number;
  widthMultiplier?: number;
  extraFrom?: Record<string, any>;
  extraTo?: Record<string, any>;
  onBackdropClick?: (ev) => void;
  minWidth?: number;
}

export function SharedElementOverlay({
  isVisible,
  fromElement,
  toPosition,
  showBackdrop,
  children,
  heightMultiplier,
  widthMultiplier,
  extraFrom,
  extraTo,
  onBackdropClick,
  minWidth,
}: SharedElementOverlayProps) {
  const eFrom = extraFrom || {};
  const eTo = extraTo || {};
  const [secondPhase, setSecondPhase] = useState(false);
  const contentRef = useRef(null);
  const backdropFadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: config.gentle,
  });

  const [moveToTarget, moveToTargetRef] = useSpring(() => ({
    opacity: 1,
    height: 0,
    width: 0,
    top: 0,
    left: 0,
    config: config.stiff,
  }));

  const [contentAnim, contentAnimRef] = useSpring(() => ({
    height: `0%`,
    width: `100%`,
    opacity: 0,
    display: `none`,
    config: config.stiff,
  }));

  const [extraAnim, extraAnimRef] = useSpring(() => ({
    ...eFrom,
    config: config.gentle,
  }));

  const { width: ww, height: wh } = useDebouncedWindowDims();

  const clampToWindow = useCallback(
    (rect: DOMRect): DOMRect => {
      const height = Math.min(rect.height, wh - 60);

      return {
        ...rect,
        height,
        top: height < rect.height ? 30 : rect.top,
      } as DOMRect;
    },
    [ww, wh],
  );

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    if (fromElement?.current && toPosition?.current) {
      const fromRect = fromElement?.current?.getBoundingClientRect();
      const refIsElement = !!toPosition.current?.nodeName;
      const toRect = refIsElement
        ? toPosition.current.getBoundingClientRect()
        : clampToWindow(toPosition.current.getBoundingClientRect());
      const shared = document.querySelector(`#shared-elem`) as HTMLElement;
      if (shared) {
        fromElement?.current?.classList.forEach((cls) =>
          shared?.classList.add(cls),
        );
        shared.style.height = `100%`;
        shared.style.width = `100%`;
        shared.style.padding = `0`;
        shared.style.margin = `0`;
        shared.style.display = `flex`;
      }
      extraAnimRef.start({ from: eFrom, to: eTo });
      const width = minWidth || toRect.width * (widthMultiplier || 1);

      const toLeft =
        width + toRect.left > window.innerWidth
          ? window.innerWidth - width - 100
          : toRect.left;

      const height = toRect.height * (heightMultiplier || 1);

      const toTop =
        height + toRect.top > window.innerHeight
          ? window.innerHeight - height - 100
          : toRect.top;

      moveToTargetRef.start({
        from: {
          opacity: 1,
          height: fromRect?.height,
          width: fromRect?.width,
          top: fromRect?.top,
          left: fromRect?.left,
        },
        to: {
          opacity: 1,
          height,
          width,
          top: toTop,
          left: toLeft,
        },
        onResolve: () => {
          setSecondPhase(true);
        },
      });
    }
  }, [fromElement, toPosition, isVisible, ww, wh]);

  useEffect(() => {
    if (secondPhase) {
      contentAnimRef.start({ display: `block` });
      contentAnimRef.start({
        height: `100%`,
        opacity: 1,
        display: `block`,
      });
    }
  }, [secondPhase]);

  const onBackdrop = (ev) => {
    onBackdropClick && onBackdropClick(ev);
  };
  return isVisible ? (
    <>
      {showBackdrop ? (
        <Backdrop
          onMouseDown={(ev) => ev.stopPropagation()}
          onClick={onBackdrop}
          style={backdropFadeIn}
        />
      ) : null}
      <Wrapper style={moveToTarget}>
        <animated.div
          style={{ overflow: `hidden`, maxWidth: `unset`, ...extraAnim }}
          id="shared-elem"
          ref={contentRef}
          onMouseLeave={(ev) => ev.stopPropagation()}
          onMouseEnter={(ev) => ev.stopPropagation()}
          onMouseMove={(ev) => ev.stopPropagation()}
          onMouseDown={(ev) => ev.stopPropagation()}
          onClick={(ev) => ev.stopPropagation()}
        >
          <animated.div style={{ overflow: `hidden`, ...contentAnim }}>
            {children}
          </animated.div>
        </animated.div>
      </Wrapper>
    </>
  ) : null;
}

const Wrapper = styled(animated.div)`
  && {
    position: fixed;
    overflow: hidden;
    z-index: ${MORE_THEN_BACKDROP_ZINDEX + 1000};

    border: none;
    background: none;
    border-radius: 1.5rem;

    #shared-elem:hover {
      opacity: 1;
      cursor: default;
    }
  }
`;

const Backdrop = styled(animated.div)`
  position: fixed;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(238, 238, 238, 0.76);
  backdrop-filter: blur(40px);
  z-index: ${BACKDROP_ZINDEX};
`;
