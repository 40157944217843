import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TextInput } from '@/components/text-input';
import { DefaultTypography } from '@/components/typography';
import { StockImage, useMediaApi } from '@/webapi/use-media-api';
import { StockPhotoTile } from '@/features/editor/widgets/custom-widget/inputs/background/image/stock/photo-tile';
import { ImageEditorContext } from '@/features/editor/widgets/custom-widget/inputs/background/image/shared/context';

export function ImageEditorStock({
  getModalRect,
}: {
  getModalRect: () => DOMRect;
}) {
  const { setImage } = useContext(ImageEditorContext);
  const { listStockPhotos } = useMediaApi();

  const [searchText, setSearchText] = useState(``);
  const [pics, setPics] = useState<StockImage[]>([]);
  const [page, setPage] = useState(0);

  const onSelection = (p: StockImage) => {
    setImage(p.regularLink);
  };
  const pageSize = 10;
  useEffect(() => {
    (async () => {
      setPics((await listStockPhotos(page, pageSize, { searchText })).images);
    })();
  }, [searchText]);

  const loadMore = async () => {
    setPage(page + 1);
    const nextPics = (await listStockPhotos(page, pageSize, { searchText }))
      .images;
    setPics(pics.concat(nextPics));
  };

  return (
    <Wrapper h={getModalRect().height}>
      <HeaderWrapper>
        <StaticImage
          src="../../../../../../../../assets/search.svg"
          alt="search"
          placeholder="none"
          loading="eager"
          height={20}
        />
        <SearchInput
          placeholder="Search for photos"
          defaultValue={searchText}
          onKeyDown={(ev) =>
            ev.key === `Enter` && setSearchText(ev.target.value)
          }
        />
        <UnsplashCredit>
          Free stock photos by <b>Unsplash</b>
        </UnsplashCredit>
      </HeaderWrapper>
      <PhotoGrid id="scrollableStockImages">
        <InfiniteScroll
          dataLength={pics?.length || 0}
          next={() => {
            loadMore().finally();
          }}
          hasMore
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableStockImages"
        >
          <PhotoColumn>
            {pics?.map((img, idx) => (
              <StockPhotoTile
                onClick={onSelection}
                key={`${img.id}${idx}`}
                photo={img}
              />
            ))}
          </PhotoColumn>
        </InfiniteScroll>
      </PhotoGrid>
    </Wrapper>
  );
}

interface P {
  h: number;
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5rem ${(p: P) => p.h - 200}px;

  align-items: center;
  justify-content: center;
`;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr 1fr;
  align-items: center;
  justify-content: center;

  .gatsby-image-wrapper {
    height: 2rem;
    width: 2rem;
    filter: contrast(1%);
  }
`;

const SearchInput = styled(TextInput)`
  && {
    background: transparent;
    border: none;
    box-shadow: none;
    font-size: 1.4rem;
    color: #475562;
    font-weight: 500;

    &&::placeholder {
      opacity: 0.8;
      font-size: 1.4rem;
    }
  }
`;

const UnsplashCredit = styled(DefaultTypography)`
  && {
    color: #475562;
    font-family: Inter, serif;
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: 0.29px;
    text-align: end;
  }
`;

const PhotoGrid = styled.div`
  height: 100%;
  margin-top: 2rem;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;

  overflow: auto;
`;

const PhotoColumn = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;
