import * as React from 'react';
import { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { CheckoutExtensibilityContext } from '@/features/editor/widgets/checkout-extensibility/context';
import {
  CustomizeSection,
  Group,
  QuickEditSection,
  StylingSection,
} from '@/features/editor/widgets/custom-widget/blueprint-customize';
import { SectionTitle } from '@/features/editor/widgets/custom-widget/shared/shared-styles';
import { CustomizeDesignBtn } from '@/features/editor/widgets/custom-widget/style-templating/customize-design-btn';
import { CheckoutGenericInput } from '@/features/editor/widgets/checkout-extensibility/inputs/generic-input';
import { groupBy } from '@/utils/arrays';
import { StylingComponentsGroup } from '@/features/editor/widgets/post-purchase/styling/group';
import { VSpace } from '@/components/spacing';
import { CheckoutRecsLoadingSection } from '@/features/editor/widgets/checkout-extensibility/loading/checkout-recs-loading-section';
import { GroupDisableButton } from '@/features/editor/widgets/custom-widget/inputs/shared/group-disable-button';

export function CheckoutInspectorWidgetStyle() {
  const {
    moveToWidgetStyleAdvanced,
    currentApp: app,
    currentWidget: widget,
    undoRedoCount,
  } = useContext(CheckoutExtensibilityContext);

  const onAdvStyling = () => {
    moveToWidgetStyleAdvanced(app, widget);
  };

  return (
    <div style={{ maxWidth: `56rem` }}>
      <CustomizeSection direction="column">
        <Group>
          <SectionTitle>Quick Edit</SectionTitle>
          <StylingSection>
            <QuickEditSection>
              <QuickSectionGroupSection key={undoRedoCount} />
            </QuickEditSection>
          </StylingSection>
          <CustomizeDesignBtn onClick={onAdvStyling} />
        </Group>
        {widget?.manifest?.showStrategies && (
          <>
            <VSpace value={3} />
            <Group>
              <CheckoutRecsLoadingSection />
              <VSpace value={4} />
            </Group>
          </>
        )}
      </CustomizeSection>
    </div>
  );
}

export function QuickSectionGroupSection() {
  const {
    currentWidget: widget,
    getPartsByPath,
    currentProps,
    getCustomizationStatus,
    toggleCustomizationStatus,
  } = useContext(CheckoutExtensibilityContext);

  const groups = useMemo(() => {
    if (!currentProps || !widget) {
      return null;
    }

    const inputs = widget.manifest.quickEdit.map((qe) => ({
      path: qe,
      ...getPartsByPath(qe),
    }));

    return Object.entries(groupBy(inputs, (item) => item.cust.title));
  }, [widget, currentProps]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <GroupWrapper>
      {!!groups &&
        groups.map(([title, input]) => (
          <StylingComponentsGroup key={title} title={title}>
            {input?.[0]?.cust?.allowDisable && (
              <GroupDisableButton
                className="disable-section"
                isDisabled={getCustomizationStatus(input?.[0]?.cust?.key)}
                onClick={() => toggleCustomizationStatus(input?.[0]?.cust?.key)}
              />
            )}
            {input.map((input) => (
              <CheckoutGenericInput
                key={input.spec.key}
                cust={input.cust}
                custId={input.custId}
                comp={input.comp}
                compId={input.compId}
                spec={input.spec}
                specId={input.specId}
              />
            ))}
          </StylingComponentsGroup>
        ))}
    </GroupWrapper>
  );
}

const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .input-background {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  .input-background > div {
    border-bottom: unset !important;
  }

  .group-wrapper {
    position: relative;
    padding: 0 !important;
  }

  .group-content {
    padding-left: 1rem;
  }

  .disable-section {
    position: absolute;
    top: -2rem;
    right: 1.5rem;
  }
`;
