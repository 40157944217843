import React, { useContext } from 'react';
import styled from 'styled-components';
import { BackButton } from '@/components/back-button';
import { INSPECTOR_BACK_ID } from '@/features/editor/inspector';
import { DeviceType } from '@/utils/definitions';
import { CloseButton } from '@/components/close-button';
import { breakpoints } from '@/components/responsive';
import { EditorContext } from '@/features/editor/context/editor-context';
import { ConfirmModal } from '@/components/confirm-modal';

export function BackCloseHeader({
  backCaption,
  isBackDisabled,
  onBack,
  device,
  hideBack,
  hideClose,
}: {
  backCaption?: string;
  isBackDisabled?: boolean;
  onBack: () => void;
  device: DeviceType;
  hideBack?: boolean;
  hideClose?: boolean;
}) {
  const { experienceControls } = useContext(EditorContext);

  const {
    sharedElement: { SharedElementOverlay, props, fromRef, hide },
    onCloseEditorPageClicked,
    closeAndNavigateToDashboard,
  } = experienceControls;

  const onClose = () => {
    onCloseEditorPageClicked();
  };

  const onConfirmClose = () => {
    closeAndNavigateToDashboard();
  };

  return (
    <Header device={device}>
      <SharedElementOverlay {...props}>
        <ConfirmModal
          neutral
          onDiscard={hide}
          onConfirm={onConfirmClose}
          title="You have unsaved changes"
          description="Are you sure you want to discard the changes?"
          yesCaption="Discard Changes"
          noCaption="Continue Editing"
        />
      </SharedElementOverlay>
      {!hideBack && (
        <BackButton
          id={INSPECTOR_BACK_ID}
          size="xs"
          disabled={isBackDisabled || false}
          onClick={onBack}
        >
          {backCaption || ``}
        </BackButton>
      )}
      {!hideClose && (
        <CloseButton
          wrapperRef={fromRef}
          id="inspector-close"
          onClick={onClose}
          device={device}
        />
      )}
    </Header>
  );
}

const Header = styled.div`
  position: relative;
  display: block;
  width: 100%;
  min-height: 6rem;

  #inspector-back {
    position: absolute;
    top: 3.1rem;
    left: 0;
  }

  #inspector-close {
    position: absolute;
    top: 3rem;
    right: 0;
    ${breakpoints.up(`xl`)} {
      right: 2.5rem;
    }
  }
`;
