import React, { Dispatch, SetStateAction, useContext, useRef } from 'react';

import { mutateFn, useComplexState } from '@/utils/use-complex-state';
import { QBItemSelection } from '@/components/query-builder/models';
import {
  CatalogWidgetProps,
  RecommendationType,
} from '@/webapi/use-widget-catalog-api';
import {
  Product,
  SlotState,
} from '@/features/editor/widgets/custom-widget/loading-section/shared/models';
import { DeviceType } from '@/utils/definitions';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import { getEmptySlots } from '@/features/editor/widgets/custom-widget/loading-section/shared/util/getEmptySlots';
import { searchCtx } from '@/features/editor/widgets/custom-widget/loading-section/shared/search-ctx';
import { maybe } from '@/features/details/utils';

type StrategyPerSlot = {
  slots: Array<SlotState>;
};

export type RuleState = {
  id: number;
  hash: string;
  isOpen: boolean;
  ruleCond: ConditionState;
  strategy?: RecommendationType;
  strategyCond?: Array<QBItemSelection>;
  strategyPerSlot?: StrategyPerSlot;
};

export type ConditionState = {
  items: Array<QBItemSelection>;
  itemSelection: 'some_items' | 'all_items' | `` | 'all_variants' | string;
};

export interface LayeredRuling {
  state: LayeredRulingState;
  change: (ruleId: number, action: (r: RuleState) => void) => void;
  rule: (ruleId: number) => RuleState;
  toggleCondBuilder: () => void;
  addRule: () => void;
  remove: (id: number) => void;
  up: (id: number) => void;
  down: (id: number) => void;
  getManualSlots: () => SlotState[];
  changeState: (fn: mutateFn<LayeredRulingState>) => void;
  toggleOpen: (ruleId: number) => void;
  changeSlot: (
    ruleId: number,
    slotId: number,
    action: (s: SlotState) => void,
  ) => void;
  productSearchSlotOpen: number;
  setProductSearchSlotOpen: Dispatch<SetStateAction<number>>;
  isProductSearchOpen: boolean;
  setSearchText: (t: string) => void;
  loadMore: () => Promise<void>;
  searchLoading: boolean;
  searchState: {
    products: any[];
    page: number;
    searchText: string;
    hasMore: boolean;
  };

  manualImageSelectionOpen?: Product;
  setManualImageSelectionOpen: (x: Product | undefined) => void;
  isManualImageSelectionOpen: boolean;
  isModalOpen: boolean;
  autoScrollDelayMs: React.MutableRefObject<number>;
  scrollRef: React.MutableRefObject<HTMLElement>;
  isPostPurchase?: boolean;
}

export interface LayeredRulingState {
  showCondBuilder: boolean;
  showManualImageSelect: boolean;
  activeCondSlot?: number | undefined;
  rules: Array<RuleState>;
}

export const LayeredRulingContext = React.createContext({} as LayeredRuling);

export function newLayeredRulingCtx(
  device: DeviceType,
  isModalOpen: boolean,
  autoScrollDelayMs: React.MutableRefObject<number>,
  isPostPurchase?: boolean,
): LayeredRuling {
  const scrollRef = useRef<HTMLElement>();
  const { currentSchema } = useContext(CustomWidgetContext);
  const getManualSlots = () => getEmptySlots(currentSchema, device);
  const [state, changeState] = useComplexState<LayeredRulingState>(
    getInitialSate(currentSchema, isPostPurchase),
  );

  function change(ruleId: number, action: (r: RuleState) => void): void {
    changeState((draft) => {
      draft.rules.forEach((r) => {
        if (r.id === ruleId) {
          action(r);
        }
      });
    });
  }

  function changeSlot(
    ruleId: number,
    slotId: number,
    action: (r: SlotState) => void,
  ): void {
    changeState((draft) => {
      draft.rules.forEach((r) => {
        if (r.id === ruleId) {
          r.strategyPerSlot.slots.forEach((s) => {
            if (s.num === slotId) {
              action(s);
            }
          });
        }
      });
    });
  }

  function rule(ruleId: number) {
    return state.rules.find((r) => r.id === ruleId);
  }

  function toggleCondBuilder() {
    changeState((draft) => {
      draft.showCondBuilder = !draft.showCondBuilder;
    });
  }

  function addRule() {
    changeState((draft) => {
      const newId = Math.max(...draft.rules.map((r) => r.id)) + 1;
      draft.rules.forEach((r) => {
        r.isOpen = false;
      });

      draft.rules = [
        ...draft.rules,
        isPostPurchase
          ? {
              id: newId,
              hash: makeHash(),
              isOpen: true,
              strategy: RecommendationType.MANUAL,
              strategyPerSlot: { slots: [{ num: 0 }] },
              ruleCond: { items: [], itemSelection: `` },
            }
          : {
              id: newId,
              hash: makeHash(),
              isOpen: true,
              ruleCond: { items: [], itemSelection: `` },
            },
      ];
    });
  }
  function remove(id: number) {
    if (state.rules.length === 1) return;

    changeState((draft) => {
      draft.rules = draft.rules.filter((r) => r.id !== id);
      draft.rules.forEach((r, i) => {
        r.id = i;
      });
    });
  }
  function up(id: number) {
    if (id === 0) return;
    changeState((draft) => {
      // [0,1,3, 2 ,4,5,6]
      draft.rules[id - 1].id += 1;
      draft.rules[id].id -= 1;
      draft.rules = draft.rules.sort((a, b) => a.id - b.id);
    });
  }
  function down(id: number) {
    if (id === state.rules.length - 1) return;
    changeState((draft) => {
      // [0,1,2, 4 ,3,5,6]
      draft.rules[id].id += 1;
      draft.rules[id + 1].id -= 1;
      draft.rules = draft.rules.sort((a, b) => a.id - b.id);
    });
  }

  const toggleOpen = (ruleId: number) =>
    changeState((draft) => {
      draft.rules.forEach((r) => {
        if (r.id === ruleId) {
          r.isOpen = !r.isOpen;
        } else {
          r.isOpen = false;
        }
      });
    });

  const {
    productSearchSlotOpen,
    setProductSearchSlotOpen,
    isProductSearchOpen,
    searchState,
    setSearchText,
    loading: searchLoading,
    loadMore,
    manualImageSelectionOpen,
    setManualImageSelectionOpen,
    isManualImageSelectionOpen,
  } = searchCtx();

  return {
    manualImageSelectionOpen,
    setManualImageSelectionOpen,
    isManualImageSelectionOpen,
    productSearchSlotOpen,
    setProductSearchSlotOpen,
    isProductSearchOpen,
    setSearchText,
    loadMore,
    searchLoading,
    searchState,
    up,
    down,
    state,
    rule,
    change,
    toggleCondBuilder,
    addRule,
    remove,
    getManualSlots,
    changeSlot,
    changeState,
    toggleOpen,
    isModalOpen,
    autoScrollDelayMs,
    scrollRef,
    isPostPurchase,
  };
}

function makeHash() {
  return (Math.random() + 1).toString(36).substring(7);
}

function getInitialSate(
  currentSchema: CatalogWidgetProps,
  isPostPurchase: boolean,
) {
  return {
    showCondBuilder: false,
    showManualImageSelect: false,
    rules: maybe(
      () =>
        currentSchema.settings.loading.loadingEnv.recommendationOptions
          .layeredRuling,
    ) || [
      {
        id: 0,
        ...(isPostPurchase
          ? {
              strategy: RecommendationType.MANUAL,
              strategyPerSlot: { slots: [{ num: 0 }] },
            }
          : {}),
        hash: makeHash(),
        isOpen: true,
        ruleCond: { items: [], itemSelection: `` },
      },
    ],
  };
}
