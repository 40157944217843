import styled from 'styled-components';
import React, { useState } from 'react';
import { DefaultTypography } from '@/components/typography';

export function StylingComponentsGroup({ title, children }) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Wrapper className="group-wrapper">
      <HeaderSection
        onClick={() => setIsOpen(!isOpen)}
        className="group-header"
      >
        <Text>{title}</Text>
        <Text>{isOpen ? `-` : `+`}</Text>
      </HeaderSection>
      {isOpen && (
        <ContentWrapper className="group-content">{children}</ContentWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: #f3f6f7;
  padding: 1.5rem 2rem;
  border-radius: 10px;

  user-select: none;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const Text = styled(DefaultTypography)`
  color: #95a2ae;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: -0.39px;
  text-transform: uppercase;
`;

const ContentWrapper = styled.div`
  && > * {
    margin-top: 1.5rem;
  }
`;
