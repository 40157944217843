import React, { useContext } from 'react';
import styled from 'styled-components';
import { ElementMessage } from '@/features/editor/context/use-device-preview';
import { DeviceType } from '@/utils/definitions';
import { INSPECTOR_BACK_ID } from '@/features/editor/inspector';
import { EditorContext } from '@/features/editor/context/editor-context';
import { BigButton } from '@/components/big-button';
import { Hint } from '@/features/editor/widgets/shared/hint';
import {
  formatKey,
  LegacyVisualEditor,
} from '@/features/editor/widgets/visual-editor/legacy/legacy-visual-editor';
import { useLegacyVisualEditor } from '@/features/editor/widgets/visual-editor/legacy/use-legacy-visual-editor';
import { EditorDeclarativeBlock } from '@/webapi/use-experience-api';
import { UndoRedoBtns } from '@/components/undo-redo-btns';
import { useStateWithHistory } from '@/utils/use-complex-state';
import { GlossyWrapper } from '@/components/glossy-wrapper';
import { CatalogWidgetProps } from '@/webapi/use-widget-catalog-api';
import {
  newUndoRedoContext,
  UndoRedoCounterContext,
} from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';

export interface VisualEditorWidgetProps extends ElementMessage {
  change: EditorDeclarativeBlock;
}

function toInitialState(visualProps: CatalogWidgetProps) {
  const state = {};
  visualProps.customizations.forEach((cust, custId) =>
    cust.components.forEach((comp, compId) =>
      comp.specs.forEach((spec, specId) => {
        state[formatKey(custId, compId, specId)] = spec;
      }),
    ),
  );
  return state;
}

export function VisualEditorLegacyWidget({
  selector,
  visualProps,
  html,
  change,
}: VisualEditorWidgetProps) {
  const {
    devicePreview: {
      editorState: { device },
    },
    inspectorNav: { gotoChangelog },
  } = useContext(EditorContext);

  const { onVisualCodeChange, onSave, onHide, isDirty } = useLegacyVisualEditor(
    selector,
    visualProps,
    html,
  );

  const {
    state: visualChanges,
    produceDispatch: setVisualChanges,
    canRedo,
    redo,
    canUndo,
    undo,
    undoRedoCount,
  } = useStateWithHistory(
    toInitialState(visualProps),
    `VISUAL_${selector}_history`,
  );
  const undoRedoContext = newUndoRedoContext(undoRedoCount);
  return (
    <InspectorWidgetLayout
      title="Visual Editor"
      progress={0}
      backCaption="Back to changes"
      onBackClicked={gotoChangelog}
      footer={
        <Footer device={device}>
          <UndoRedoBtns
            undo={undo}
            redo={redo}
            canUndo={canUndo}
            canRedo={canRedo}
            debounceInterval={1500}
          />

          <BigButton
            border="2px solid #C8CACB"
            background="white"
            color="#97A0A8"
            noShadow
            noTransform
            size={device === DeviceType.Desktop ? `medium-thin` : `medium`}
            fillWidth
            onClick={onHide}
          >
            Remove
          </BigButton>

          <BigButton
            disabled={!isDirty}
            onClick={onSave}
            border="2px solid black"
            noTransform
            size={device === DeviceType.Desktop ? `medium-thin` : `medium`}
            fillWidth
          >
            Save
          </BigButton>
        </Footer>
      }
    >
      <Wrapper device={device}>
        <Hint id="code-hint">{selector}</Hint>
        <VisualWrapper device={device}>
          <UndoRedoCounterContext.Provider value={undoRedoContext}>
            <LegacyVisualEditor
              initSpecs={change?.visualEditSpecs}
              visualChanges={visualChanges}
              setVisualChanges={setVisualChanges}
              visualProps={visualProps}
              onChange={onVisualCodeChange}
            />
          </UndoRedoCounterContext.Provider>
        </VisualWrapper>
      </Wrapper>
    </InspectorWidgetLayout>
  );
}

const Wrapper = styled.div`
  width: ${(props: { device: DeviceType; fb: boolean }) =>
    props.device === DeviceType.Desktop ? `100%` : `60%`};
  position: relative;
  background: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem 1fr;
  grid-row-gap: 2.5rem;

  #code-hint {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  #${INSPECTOR_BACK_ID} {
    z-index: 31;
  }
`;

const Footer = styled(GlossyWrapper)`
  && {
    left: -0.5rem;
    width: ${(props: { device: DeviceType }) =>
      props.device === DeviceType.Desktop ? `100%` : `60%`};
    display: grid;
    position: absolute;
    bottom: 2rem;
    grid-template-columns: 0.5fr 1fr 2fr;
    grid-gap: 2rem;
    z-index: 30;
  }
`;

const VisualWrapper = styled.div`
  height: 100%;
`;
