import { CachePolicies, useFetch } from 'use-http';
import {
  AdAccount,
  AdAccountList,
} from '@/features/integrations/facebook/models';
import { routes } from '@/webapi/routes';
import {
  CampaignList,
  FBAdSet,
  FBCampaign,
  FBQuery,
} from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/models';

export function useFacebookApi(initialLoading = true) {
  const { post, get, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: initialLoading,
    },
  }));

  async function listAdAccounts(): Promise<AdAccountList> {
    return get(routes.listFbAdAccounts);
  }

  async function selectAdAccount(account: AdAccount): Promise<AdAccountList> {
    return post(routes.selectAdAccount, account);
  }

  async function listCampaigns(query: FBQuery): Promise<CampaignList> {
    return post(routes.listFbCampaigns, query);
  }

  async function moreAds(value = ``): Promise<FBAdSet> {
    return post(routes.loadMoreFbAds, { value });
  }

  async function moreAdSets(value = ``): Promise<FBCampaign> {
    return post(routes.loadMoreFbAdSets, { value });
  }

  async function moreCampaigns(value = ``): Promise<CampaignList> {
    return post(routes.loadMoreFbCampaigns, { value });
  }

  async function verify(
    query: FBQuery,
  ): Promise<{ invalid: boolean; notLoggedIn?: boolean }> {
    return post(routes.fbVerify, query);
  }

  async function encrypt(alias: string): Promise<{ value: string }> {
    return post(routes.encryptAlias, { value: alias });
  }

  return {
    loading,
    listAdAccounts,
    listCampaigns,
    selectAdAccount,
    encrypt,
    moreAds,
    moreAdSets,
    moreCampaigns,
    verify,
  };
}
