import React, { useContext } from 'react';
import { ManualImageSelectInner } from '@/features/editor/widgets/custom-widget/loading-section/shared/manual-image-select';
import { LayeredRulingContext } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import { VSpace } from '@/components/spacing';

export function ManualImageSelect() {
  const ctx = useContext(LayeredRulingContext);
  const activeRule = ctx.state.rules.find((r) => r.isOpen);
  const images = ctx.manualImageSelectionOpen?.images || [];
  const onSelect = (src: string) => () => {
    ctx.changeSlot(activeRule.id, ctx.productSearchSlotOpen, (s) => {
      s.manualProduct = {
        ...ctx.manualImageSelectionOpen,
        missingVariantImageMapping: false,
        imageOverride: src,
        image: { src },
      };
    });
    ctx.setManualImageSelectionOpen(undefined);
    ctx.setProductSearchSlotOpen(undefined);
  };

  if (!ctx.isManualImageSelectionOpen || !activeRule) {
    return null;
  }

  return (
    <>
      <VSpace value={5} />
      <ManualImageSelectInner images={images} onSelect={onSelect} />
    </>
  );
}
