/* eslint-disable no-nested-ternary */
import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { IoChevronBackOutline } from 'react-icons/io5';
import { Slots } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/Slots';
import { ManualProductsPicker } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/context';
import { ImagePicker } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/image-picker';
import { ProductSearch } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/search';
import { AdvancedRuling } from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/context';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ProductsSelection: FC = () => {
  const {
    openSlot,
    setOpenSlot,
    productWithoutImage,
    unsetProductWithoutImage,
  } = useContext(ManualProductsPicker);
  const onBack = () => {
    setOpenSlot(undefined);
    unsetProductWithoutImage();
  };
  const { toggleSearchMode, toggleImageSelectMode } =
    useContext(AdvancedRuling);
  useEffect(() => {
    toggleSearchMode(!!openSlot);
  }, [openSlot]);

  useEffect(() => {
    toggleImageSelectMode(!!productWithoutImage);
  }, [productWithoutImage]);
  return (
    <Container>
      {openSlot && (
        <BackBtn onClick={onBack} type="button">
          <IoChevronBackOutline size={30} />
        </BackBtn>
      )}
      {openSlot ? (
        productWithoutImage ? (
          <ImagePicker noTopOverFlow />
        ) : (
          <MinHeight>
            <ProductSearch noTopOverFlow noTotalSummary />
          </MinHeight>
        )
      ) : (
        <MaxHeight>
          <Slots
            hide={() => 1}
            updateSelection={() => 1}
            noTopOverFlow
            noSaveBtn
          />
        </MaxHeight>
      )}
    </Container>
  );
};

const MaxHeight = styled.div`
  max-height: 450px;
  overflow-y: scroll;
`;

const MinHeight = styled.div`
  min-height: 450px;
  width: 100%;
`;

const BackBtn = styled.button`
  z-index: 1000;
  position: absolute;
  left: 20px;
  top: 38px;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  &:active {
    cursor: default;
    opacity: 1;
  }
`;
