import * as React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { useContext, useEffect, useRef } from 'react';
import { TruncatedText } from '@/features/editor/widgets/fake-click/shared';
import { Card } from '@/components/card';
import { FakeClickContext } from '@/features/editor/widgets/fake-click/context';
import { AutomationStep } from '@/pkg/sdk';

export function FakeClickStep({ step }: { step: AutomationStep }) {
  const { removeStep } = useContext(FakeClickContext);
  const ref = useRef(null);
  useEffect(() => {
    if (ref) {
      ref.current.scrollIntoView({ smooth: true });
    }
  }, [ref]);

  const onDelete = () => {
    removeStep(step);
  };

  return (
    <CardWrapper ref={ref}>
      <OrderBadge>{step.order + 1}</OrderBadge>
      {step.kind === `click` && (
        <>
          <ClickIcon />
          <span>
            Clicked on <TruncatedText text={step.selector} limit={20} />
          </span>
        </>
      )}
      {step.kind === `type` && (
        <>
          <TypeIcon />
          <span>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Typed "<TruncatedText text={step.data} limit={8} />" on{` `}
            <TruncatedText text={step.selector} limit={8} />
          </span>
        </>
      )}
      {step.kind === `scroll` && (
        <>
          <ScrollIcon />
          <span>
            Scrolled to <TruncatedText text={step.selector} limit={20} />
          </span>
        </>
      )}
      <TrashWrapper onClick={onDelete}>
        <TrashIcon />
      </TrashWrapper>
    </CardWrapper>
  );
}

const CardWrapper = styled(Card)`
  && {
    position: relative;
    width: 100%;
    padding: 1.5rem;
    border-radius: 1.7rem;

    display: grid;
    grid-template-columns: 1.8rem 1fr 3.5rem;
    grid-gap: 2rem;
    align-items: center;

    text-align: start !important;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: -0.47px;
    font-size: 1.2rem;
  }
`;

const OrderBadge = styled.span`
  position: absolute;
  top: -0.7rem;
  left: -0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 2.3rem;
  width: 2.3rem;
  text-align: center;
  background-color: #fdff81;
  color: #ff00f2;
  font-size: 1.3rem;
  font-weight: 600;
  font-family: 'JetBrains Mono', serif;
  letter-spacing: -0.43px;
  border-radius: 50%;
  animation: fadein 0.2s linear;

  @keyframes fadein {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const TrashWrapper = styled.span`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eff2f5;
  border-radius: 50%;
  color: #bfc9d2;
  height: 3.5rem;
  width: 3.5rem;
  justify-self: end;
`;

const TrashIcon = () => (
  <StaticImage
    formats={[`png`, `webp`]}
    placeholder="none"
    loading="eager"
    objectFit="contain"
    objectPosition="center"
    width={16}
    src="../../../../assets/fake_click_delete.svg"
    alt="Fake Click"
  />
);

const ClickIcon = () => (
  <StaticImage
    formats={[`png`, `webp`]}
    placeholder="none"
    loading="eager"
    objectFit="contain"
    objectPosition="center"
    width={50}
    src="../../../../assets/fake_click_click.svg"
    alt="Fake Click"
  />
);

const TypeIcon = () => (
  <StaticImage
    formats={[`png`, `webp`]}
    placeholder="none"
    loading="eager"
    objectFit="contain"
    objectPosition="center"
    width={50}
    src="../../../../assets/fake_click_type.svg"
    alt="Fake Type"
  />
);

const ScrollIcon = () => (
  <StaticImage
    formats={[`png`, `webp`]}
    placeholder="none"
    loading="eager"
    objectFit="contain"
    objectPosition="center"
    width={50}
    src="../../../../assets/fake_click_scroll.svg"
    alt="Fake Scroll"
  />
);
