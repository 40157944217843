import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { NumberInput } from '@/features/editor/widgets/custom-widget/inputs/shared/number-input';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';

const VALUE_KEY = `fontSize`;

interface FontSizeInputProps extends GenericInputProps {
  setFontSize: (value: string) => void;
}

export function FontSizeInput({
  initialValues,
  onValuesChanged,
  setFontSize,
}: FontSizeInputProps) {
  const { undoRedoCount } = useContext(UndoRedoCounterContext);

  const getValue = useCallback(() => initialValues(VALUE_KEY), [undoRedoCount]);

  const [value, setValue] = useState(getValue());
  useEffect(() => {
    setValue(getValue());
    setFontSize(value);
  }, [undoRedoCount]);

  const onChange = (value) => {
    setValue(value);
    setFontSize(value);
  };

  useEffect(() => {
    onValuesChanged(VALUE_KEY, value);
  }, [value]);

  return (
    <Wrapper>
      <NumberInput
        min={1}
        max={128}
        suffix="px"
        onChange={onChange}
        defaultValue={value}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;
