import React from 'react';
import styled from 'styled-components';
import { DeviceType } from '@/utils/definitions';
import { INSPECTOR_BACK_ID } from '@/features/editor/inspector';
import { Heading2 } from '@/components/typography';
import { Flexbox } from '@/components/flex';
import { Hint } from '@/features/editor/widgets/shared/hint';

export const UpperHeader = ({ selector, title }) => (
  <Flexbox direction="column" align="flex-start" justify="center" gap="1rem">
    <CodeEditorTitle>{title}</CodeEditorTitle>
    <Hint id="code-hint">{selector}</Hint>
  </Flexbox>
);

export const CodeEditorWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem 1fr 5rem;
  grid-row-gap: 2.5rem;
  padding-bottom: 2rem;
  padding-right: ${(props: { fullscreen: boolean; device: DeviceType }) =>
    props.device === DeviceType.Desktop ? `0` : `3rem`};

  #code-hint {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  #${INSPECTOR_BACK_ID} {
    z-index: 31;
  }

  ${(props: { fullscreen: boolean; device: DeviceType }) =>
    props.fullscreen
      ? `
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 200;
  padding: 0 2rem;
  margin: 0;
  grid-template-rows: 0rem 1fr 8rem;
  `.trim()
      : `position: unset;`}
`;

export const CodeEditorTitle = styled(Heading2)`
  && {
    font-size: 2.5em;
  }
`;

export const CodeEditorIconButton = styled.div`
  cursor: pointer;
`;

export const CodeEditorScrollContainer = styled.div`
  position: relative;
  overflow-y: scroll;
  max-height: 100%;
  display: grid;
  grid-template-rows: 10rem 1fr;
  grid-row-gap: 2.5rem;
`;

export const CodeEditorMonacoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #d4dfe4;
  border-radius: 1rem;
  padding: 1rem;
  background: #f6f8f9;
`;

export const CodeEditorFooter = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 5rem 20rem;
  justify-content: space-between;
  align-items: center;
`;
