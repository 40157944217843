import { useContext } from 'react';
import { EditorContext } from '@/features/editor/context/editor-context';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import { filterRecommendations } from '@/features/editor/widgets/custom-widget/loading-section/recs-per-placement';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';

export function getAvailableStrategies() {
  const {
    experienceState: { currentExperience },
  } = useContext(EditorContext);
  const { currentSchema } = useContext(CustomWidgetContext);
  const isNewRecAlgsEnabled = useFeatureBit(FeatureBit.NEW_REC_ALGS);

  return filterRecommendations(
    currentExperience?.quickPreviewPlacement,
    currentSchema?.settings?.loading,
    isNewRecAlgsEnabled,
  );
}
