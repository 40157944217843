/* eslint-disable prefer-promise-reject-errors */
import { Component, CustomizationSpec } from '@/webapi/use-widget-catalog-api';
import { InputType } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';

export function extractBlobFromBackgroundUrl(src: string): string {
  if (!src.includes(`://`)) {
    return ``;
  }
  return src?.toString()?.replaceAll(`url("`, ``)?.replaceAll(`")`, ``);
}

export function extractBlob(savedImage: string): string {
  return savedImage
    .toString()
    ?.replaceAll(`url(`, ``)
    ?.replaceAll(`)`, ``)
    .replaceAll(`"`, ``);
}

export function awaitCondition(
  condition: () => boolean,
  timeout = 500,
  retries = 4,
) {
  return new Promise((resolve, reject) => {
    if (condition()) {
      resolve(0);
      return;
    }
    if (retries === 0) {
      reject(`await failed`);
      return;
    }
    setTimeout(() => {
      awaitCondition(condition, timeout, retries - 1)
        .then(resolve)
        .catch(reject);
    }, timeout);
  });
}

export function isComponentHasHoverStateSpec(component: Component): boolean {
  return (
    component?.specs?.find(
      (spec) => spec?.values?.desktop?.hover !== undefined,
    ) !== undefined
  );
}

export function isSpecHasHoverState(spec: CustomizationSpec): boolean {
  return spec?.values?.desktop && spec?.values?.desktop?.hover;
}

export function hideSpecInHoverMode(spec: CustomizationSpec): boolean {
  return spec?.type === InputType.CTA || spec?.type === InputType.LINK;
}
