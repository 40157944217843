import { useCallback, useEffect, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { useDebouncedCallback } from 'use-debounce';
import { useComplexState } from '@/utils/use-complex-state';
import { useCatalogApi } from '@/webapi/use-catalog-api';
import { Product } from '@/features/editor/widgets/custom-widget/loading-section/shared/models';

export function searchCtx() {
  const [productSearchSlotOpen, setProductSearchSlotOpen] = useState<
    number | undefined
  >();
  const isProductSearchOpen = typeof productSearchSlotOpen !== `undefined`;
  const [searchState, changeSearchState] = useComplexState({
    products: [],
    page: 0,
    searchText: ``,
    hasMore: true,
  });
  const { listProducts, loading } = useCatalogApi();
  const [delayedLoading, setDelayedLoading] = useState(loading);
  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setDelayedLoading(false); // prevents flickering if response is too fast
      }, 500);
    }
    setDelayedLoading(true);
  }, [loading]);

  const loadMore = async () => {
    const next =
      (await listProducts(searchState.searchText, searchState.page + 1))
        ?.products || null;
    if (next) {
      changeSearchState((draft) => {
        draft.page += 1;
        draft.products = [...draft.products, ...next];
        draft.hasMore = true;
      });
    }
  };

  useAsyncEffect(async (isActive) => {
    const response = await listProducts(``);
    if (isActive && response?.products?.length > 0) {
      changeSearchState((draft) => {
        draft.products = response.products;
      });
    }
  }, []);

  const updateText = useCallback(
    (value) => {
      const productsResponsePromise = listProducts(searchState.searchText);
      changeSearchState((draft) => {
        draft.searchText = value;
        draft.page = 0;
        draft.products = [];
      });
      (async () => {
        const response = await productsResponsePromise;
        changeSearchState((draft) => {
          draft.products = response?.products || [];
        });
      })();
    },
    [searchState.searchText],
  );

  const debouncedSearch = useDebouncedCallback(updateText, 500);

  useEffect(() => {
    debouncedSearch(searchState.searchText);
  }, [searchState.searchText]);

  const setSearchText = (x: string) => {
    changeSearchState((draft) => {
      draft.searchText = x;
      draft.page = 0;
    });
  };

  const [manualImageSelectionOpen, setManualImageSelectionOpen] = useState<
    Product | undefined
  >();
  const isManualImageSelectionOpen =
    typeof manualImageSelectionOpen !== `undefined`;

  return {
    manualImageSelectionOpen,
    setManualImageSelectionOpen,
    isManualImageSelectionOpen,
    setSearchText,
    productSearchSlotOpen,
    setProductSearchSlotOpen,
    isProductSearchOpen,
    searchState,
    changeSearchState,
    loading: delayedLoading,
    loadMore,
  };
}
