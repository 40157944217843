import React, { useContext } from 'react';
import styled from 'styled-components';
import { ColorAlphaPicker } from './components/color-alpha-picker';
import { ColorHuePicker } from './components/color-hue-picker';
import { ColorPreview } from './components/color-preview';
import { ColorSaturationPicker } from './components/color-sat-picker';
import {
  ColorPadding,
  Divider,
} from '@/features/editor/widgets/custom-widget/inputs/background/color/components/commons';
import { ColorFavoritesPicker } from '@/features/editor/widgets/custom-widget/inputs/background/color/components/fav-picker';
import { BackgroundColorContext } from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/context';
import {
  EyeDropper,
  useEyeDropProps,
} from '@/features/editor/widgets/custom-widget/inputs/background/color/eyeDropper';

export function SolidColorTab() {
  const props = useEyeDropProps();
  const { solidColor, solidFavourites, setSolidFavourites, updateSolidColor } =
    useContext(BackgroundColorContext);
  return (
    <Wrapper>
      <ControlsSection>
        <ColorSaturationPicker />
        <EyeDropper {...props} />
        <ControlsWrapper>
          <ColorPreview />
          <ColorHuePicker eyeDropColor={props.color} />
          <ColorAlphaPicker />
        </ControlsWrapper>
      </ControlsSection>
      <Divider />
      <ColorPadding>
        <ColorFavoritesPicker
          currentColor={solidColor}
          favorites={solidFavourites}
          setFavourites={setSolidFavourites}
          updateColor={updateSolidColor}
        />
      </ColorPadding>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1px 11rem;
`;

const ControlsSection = styled(ColorPadding)`
  && {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 30px 3fr;
    justify-content: center;
    grid-gap: 0.5rem;
  }
`;

const ControlsWrapper = styled.div`
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
`;
