import styled from 'styled-components';

export const GlossyWrapper = styled.div`
  width: 100%;
  background: rgba(233, 233, 233, 0.4);
  padding: 1.5rem;
  border-radius: 1.5rem;
  backdrop-filter: blur(4px);
  z-index: 30;
`;
