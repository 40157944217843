import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { TextInput } from '@/components/text-input';
import { ManualProductsPicker } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/context';
import { ProductsGrid } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/search/products-grid';

const SeatchTextInput = styled(TextInput)`
  && {
    margin-top: 3rem;
    min-width: 25rem;
    width: 85%;
    text-align: start;
    border-radius: 2rem;
    border: 1.5px solid #e8e8ea;
    font-size: 1.4rem;
    font-family: 'JetBrains Mono', serif;
    box-shadow: none;
    padding: 1.2rem 2rem;
  }
`;

const Container = styled.div`
  width: 100%;
  margin: ${(p: { noTopOverFlow?: boolean }) => (p?.noTopOverFlow ? 0 : `8rem`)}
    2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ProductSearch: FC<{
  noTopOverFlow?: boolean;
  noTotalSummary?: boolean;
}> = ({ noTopOverFlow, noTotalSummary }) => {
  const { searchText, setSearchText, products, loading } =
    useContext(ManualProductsPicker);
  return (
    <Container noTopOverFlow={noTopOverFlow}>
      <SeatchTextInput
        placeholder="search"
        value={searchText}
        onChange={(ev) => {
          setSearchText(ev.target.value);
        }}
      />
      <ProductsGrid
        products={products}
        loading={loading}
        noTotalSummary={noTotalSummary}
      />
    </Container>
  );
};
