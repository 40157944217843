export enum ImageEditorTab {
  EDIT,
  ADD_IMAGE,
  ADD_VIDEO,
  STOCK,
  MY_MEDIA,
  MY_CATALOG,
  META_CAMPAIGNS,
  INSTAGRAM,
}
