import React, { useCallback, useContext, useEffect, useState } from 'react';
import { animated, useChain, useSpring, useSpringRef } from '@react-spring/web';
import styled from 'styled-components';
import {
  GenericInputProps,
  InputsMinimalTheme,
} from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { SingleNumberInput } from '@/features/editor/widgets/custom-widget/inputs/shared/single-number-input';
import {
  CardWrapper,
  Line,
} from '@/features/editor/widgets/custom-widget/inputs/shared/card-wrapper';
import { CheckboxInput } from '@/features/editor/widgets/custom-widget/inputs/shared/checkbox-input';
import { ColorInput } from '@/features/editor/widgets/custom-widget/inputs/shared/color-input';
import { Caption } from '@/features/editor/widgets/custom-widget/shared/shared-styles';
import { NumberInput } from '@/features/editor/widgets/custom-widget/inputs/shared/number-input';
import {
  DropdownInput,
  DropdownInputOption,
} from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { DeviceType } from '@/utils/definitions';
import { VSpace } from '@/components/spacing';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';

const options: DropdownInputOption[] = [
  { label: `Solid`, value: `solid` },
  { label: `Dotted`, value: `dotted` },
  { label: `Dashed`, value: `dashed` },
  { label: `Double`, value: `double` },
  { label: `Groove`, value: `groove` },
  { label: `Ridge`, value: `ridge` },
  { label: `Inside`, value: `inset` },
  { label: `Outside`, value: `outset` },
  { label: `None`, value: `none` },
];

export function BorderInput({
  customizationIdx,
  customization,
  componentIdx,
  component,
  specIdx,
  spec,
  device,
  initialValues,
  onValuesChanged,
}: GenericInputProps) {
  const { undoRedoCount } = useContext(UndoRedoCounterContext);
  const getEnabled = useCallback(
    () => initialValues(`enabled`),
    [undoRedoCount],
  );

  const getValue = useCallback(
    () => initialValues(`border`, DeviceType.Mobile),
    [undoRedoCount],
  );

  const [isBorderEnabled, setIsBorderEnabled] = useState(getEnabled());

  const [w, s, c] = parse(getValue());
  const [width, setWidth] = useState(w);
  const [style, setStyle] = useState(s);
  const [color, setColor] = useState(c);

  useEffect(() => {
    const [w, s, c] = parse(getValue());
    if (isBorderEnabled !== getEnabled()) setIsBorderEnabled(getEnabled());
    if (width !== w) setWidth(w);
    if (styled !== s) setStyle(s);
    if (color !== c) setColor(c);
  }, [undoRedoCount]);

  useEffect(() => {
    const value = format(width, style, color);
    onValuesChanged(`border`, value);
  }, [width, style, color]);

  useEffect(() => {
    onValuesChanged(`enabled`, isBorderEnabled);
  }, [isBorderEnabled]);

  const checkmarkAnimRef = useSpringRef();
  const checkmarkAnim = useSpring({
    from: { opacity: 1 },
    to: { opacity: 0 },
    reverse: !isBorderEnabled,
    ref: checkmarkAnimRef,
  });

  const cardAnimRef = useSpringRef();
  const cardAnim = useSpring({
    from: { opacity: 0, transform: `scale(1,0.3)` },
    to: { opacity: 1, transform: `scale(1,1)` },
    reverse: !isBorderEnabled,
    ref: cardAnimRef,
  });

  useChain([cardAnimRef, checkmarkAnimRef], [0, 0.5]);

  return (
    <>
      <SingleNumberInput
        unit="px"
        caption="Border Radius"
        envKey="borderRadius"
        customizationIdx={customizationIdx}
        customization={customization}
        componentIdx={componentIdx}
        component={component}
        specIdx={specIdx}
        spec={spec}
        device={device}
        initialValues={initialValues}
        onValuesChanged={onValuesChanged}
      />
      <VSpace value={1.5} />
      <div style={{ width: `100%`, position: `relative` }}>
        <animated.div
          style={{
            ...cardAnim,
            width: `100%`,
            transformOrigin: `0 0`,
            position: !isBorderEnabled ? `absolute` : `relative`,
          }}
        >
          <CardWrapper
            title={
              <CardHeader
                isEnabled={isBorderEnabled}
                setIsEnabled={setIsBorderEnabled}
                color={color}
                setColor={setColor}
                setWidth={setWidth}
                width={width}
                customizationIdx={customizationIdx}
                customization={customization}
                componentIdx={componentIdx}
                component={component}
                specIdx={specIdx}
                spec={spec}
                device={device}
              />
            }
          >
            <Line>
              <Caption>Border Style</Caption>
              <DropdownInput
                key={undoRedoCount}
                theme={InputsMinimalTheme}
                options={options}
                defaultValue={options.filter((opt) => opt.value === style)?.[0]}
                onChange={(sel) => setStyle(sel.value)}
              />
            </Line>
          </CardWrapper>
        </animated.div>
        <animated.div
          style={{
            ...checkmarkAnim,
            position: isBorderEnabled ? `absolute` : `relative`,
            display: isBorderEnabled ? `none` : `block`,
            width: `100%`,
          }}
        >
          <CheckboxInput
            defaultValue={isBorderEnabled}
            onChange={(value) => setIsBorderEnabled(value)}
          >
            Add Border
          </CheckboxInput>
        </animated.div>
      </div>
    </>
  );
}

function CardHeader({
  isEnabled,
  customizationIdx,
  componentIdx,
  specIdx,
  device,
  setIsEnabled,
  color,
  setColor,
  width,
  setWidth,
  customization,
  component,
  spec,
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  const noop = (key: string, value: any, device?: DeviceType) => {};
  const initValues = (_key?: string, _device?: DeviceType, _specIdx?: number) =>
    color;
  return (
    <CardHeaderWrapper>
      <CheckboxInput
        defaultValue={isEnabled}
        onChange={(value) => setIsEnabled(value)}
      >
        Border
      </CheckboxInput>
      <NumberInput
        minimal
        min={0}
        max={100}
        suffix="px"
        defaultValue={width}
        onChange={(width) => setWidth(width)}
      />
      <ColorWrapper>
        <ColorInput
          envKey="value"
          defaultValue={color}
          customizationIdx={customizationIdx}
          customization={customization}
          componentIdx={componentIdx}
          component={component}
          specIdx={specIdx}
          spec={spec}
          device={device}
          onChange={(value) => setColor(value)}
          initialValues={initValues}
          onValuesChanged={noop}
        />
      </ColorWrapper>
    </CardHeaderWrapper>
  );
}

const CardHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 10rem 5rem;
  grid-auto-rows: 2rem;
  justify-content: center;
  align-items: center;
`;

const ColorWrapper = styled.div`
  height: 3.5rem;
  width: 5rem;
`;

function parse(borderStr: string): string[] {
  if (borderStr) {
    return borderStr.replaceAll(`, `, `,`).split(` `);
  }
  return [`0px`, `solid`, `rgba(0,0,0,1)`];
}

function format(width: string, style: string, color: string) {
  return `${width} ${style} ${color}`;
}
