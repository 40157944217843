/* eslint-disable guard-for-in */
import { useEffect, useRef } from 'react';
import { hash, LocalStorageCache, Cache, IndexedDbCache } from '@/utils/cache';
import {
  EditorDeclarativeBlock,
  Experience,
} from '@/webapi/use-experience-api';
import { removeUntrackedFields } from '@/features/editor/context/use-experience-controls';
import { getAliasQueryParam } from '@/utils/browser';
import { PostPurchaseProps } from '@/features/editor/widgets/post-purchase/props';
import {
  ACTIVE_BLOCK_TABLE,
  changesHistoryKeysTable,
  changesHistoryTable,
  EXPERIENCE_CACHE_TABLE,
  getDatabaseTable,
  LAST_SAVED_EXPERIENCE_TABLE,
  POST_PURCHASE_TABLE,
} from '@/db';
import { HistoryKeys } from '@/db/schemas';

const LAST_SAVED_EXPERIENCE_CACHE_KEY = `VSLY_LAST_SAVED_EXPERIENCE`;
const EXPERIENCE_CACHE_KEY = `VSLY_EXPERIENCE`;
const ACTIVE_BLOCK_CACHE_KEY = `VSLY_ACTIVE_BLOCK`;
const POST_PURCHASE_CACHE_KEY = `VSLY_POST_PURCHASE`;

export const EDITOR_EXPERIENCE_CACHE = new IndexedDbCache<Experience>(
  EXPERIENCE_CACHE_TABLE,
  6 * 3600 * 1000,
);

export const EDITOR_LAST_SAVED_EXPERIENCE_CACHE = new LocalStorageCache<{
  value: string;
}>(LAST_SAVED_EXPERIENCE_CACHE_KEY, 6 * 3600 * 1000, {
  serializer: (obj) => JSON.stringify(obj, removeUntrackedFields),
});

export const EDITOR_ACTIVE_BLOCK_CACHE =
  new IndexedDbCache<EditorDeclarativeBlock>(
    ACTIVE_BLOCK_TABLE,
    2 * 3600 * 1000,
  );

export const EDITOR_POST_PURCHASE_CACHE = new IndexedDbCache<PostPurchaseProps>(
  POST_PURCHASE_TABLE,
  6 * 3600 * 1000,
);

export async function removeExperienceFromCache(id: string) {
  await EDITOR_EXPERIENCE_CACHE.remove(id);
  await EDITOR_LAST_SAVED_EXPERIENCE_CACHE.remove(id);
}

const RECENT_HISTORY_KEYS = `RECENT_HISTORY_KEYS`;

async function recoverRecentHistoryKeys() {
  const value = await changesHistoryKeysTable().get(
    getAliasQueryParam() || `default`,
  );

  return value?.value || [];
}

export async function persistKeysForCleanup(key: string) {
  const keys: Set<string> = new Set(await recoverRecentHistoryKeys());
  keys.add(key);
  await changesHistoryKeysTable().put({
    key: getAliasQueryParam() || `default`,
    value: keys,
  } as HistoryKeys);
}

export function deleteAllExperiencesInCache() {
  changesHistoryTable().clear();
  getDatabaseTable(EXPERIENCE_CACHE_TABLE).clear();
  getDatabaseTable(ACTIVE_BLOCK_TABLE).clear();
  getDatabaseTable(POST_PURCHASE_TABLE).clear();
  getDatabaseTable(LAST_SAVED_EXPERIENCE_TABLE).clear();
  changesHistoryKeysTable().clear();
  const alias = getAliasQueryParam();
  // eslint-disable-next-line no-restricted-syntax
  for (const key in localStorage) {
    if (
      key.startsWith(RECENT_HISTORY_KEYS) ||
      key.startsWith(EXPERIENCE_CACHE_KEY) ||
      key.startsWith(LAST_SAVED_EXPERIENCE_CACHE_KEY) ||
      key.startsWith(ACTIVE_BLOCK_CACHE_KEY) ||
      key.startsWith(`${alias}_${EXPERIENCE_CACHE_KEY}`) ||
      key.startsWith(`${alias}_${LAST_SAVED_EXPERIENCE_CACHE_KEY}`) ||
      key.startsWith(`${alias}_${ACTIVE_BLOCK_CACHE_KEY}`) ||
      key.startsWith(`${alias}_${POST_PURCHASE_CACHE_KEY}`) ||
      key.startsWith(`_${EXPERIENCE_CACHE_KEY}`) ||
      key.startsWith(`_${LAST_SAVED_EXPERIENCE_CACHE_KEY}`) ||
      key.startsWith(`_${ACTIVE_BLOCK_CACHE_KEY}`)
    ) {
      localStorage.removeItem(key);
    }
  }
}

export function useCachedAutoSave<T>(
  objectToMonitor: T,
  key: string,
  cache: Cache<T>,
  intervalMillis = 1000,
) {
  const hashRef = useRef(hashObject(objectToMonitor));
  const valueRef = useRef(objectToMonitor);
  let intervalKey;

  const onPersist = () => {
    const currentHash = hashObject(valueRef?.current);
    if (currentHash !== hashRef?.current || !cache.exists(key)) {
      hashRef.current = currentHash;
      cache.set(key, valueRef?.current);
    }
  };

  useEffect(() => {
    valueRef.current = objectToMonitor;
  }, [objectToMonitor]);

  useEffect(() => {
    intervalKey = setInterval(onPersist, intervalMillis);
    return () => {
      clearInterval(intervalKey);
    };
  }, []);
}

export function hashObject(obj: any): string {
  return hash(JSON.stringify(obj));
}
