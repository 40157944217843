import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { EditorContext } from '@/features/editor/context/editor-context';
import { TextInput } from '@/components/text-input';
import { DeviceType } from '@/utils/definitions';
import { BigButton } from '@/components/big-button';
import { VSpace } from '@/components/spacing';
import { UNNAMED_EXPERIENCE } from '@/webapi/use-experience-api';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import { RichTextDescription } from '@/features/shared/experience-info-modal/rich-text-description';

export enum ExperienceNameOrigin {
  SAVE = 0,
  PREVIEW = 1,
  PUBLISH = 2,
  TARGETING = 3,
}

export function ExperienceNameWidget({
  origin,
}: {
  origin: ExperienceNameOrigin;
}) {
  const { experienceState, devicePreview, inspectorNav, experienceControls } =
    useContext(EditorContext);
  const {
    editorState: { device },
  } = devicePreview;
  const textRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState(experienceState.currentExperience.name);
  const [description, setDescription] = useState(
    experienceState.currentExperience.description,
  );

  const onNext = async () => {
    experienceState.setExperienceName(name, description);
    if (origin === ExperienceNameOrigin.SAVE) {
      await experienceControls.forceSave(name, description);
      inspectorNav.gotoChangelog();
    } else if (origin === ExperienceNameOrigin.PREVIEW) {
      await experienceControls.preview(undefined, undefined);
      inspectorNav.gotoChangelog();
    } else if (origin === ExperienceNameOrigin.PUBLISH) {
      await experienceControls.publish();
      experienceControls.closeAndNavigateToDashboard();
    } else if (origin === ExperienceNameOrigin.TARGETING) {
      inspectorNav.gotoTargeting();
    }
  };

  const onNameChange = (ev) => {
    setName(ev.target.value);
  };

  const isNameValid = useCallback(
    () => name !== UNNAMED_EXPERIENCE && name !== ``,
    [name],
  );

  useEffect(() => {
    if (textRef) {
      textRef.current.focus();
      setTimeout(() => {
        textRef.current.focus();
      }, 2000);
    }
  }, [textRef]);

  return (
    <InspectorWidgetLayout
      title="Name your experience"
      progress={0}
      backCaption="< Back to changes"
      onBackClicked={inspectorNav.gotoChangelog}
    >
      <VSpace value={3} />
      <Label>Experience Name</Label>
      <VSpace value={1} />
      <NameInput
        ref={textRef}
        device={device}
        placeholder={name}
        defaultValue={isNameValid() ? name : undefined}
        onChange={onNameChange}
      />
      <VSpace value={3} />
      <Label>Experience description (optional)</Label>
      <VSpace value={1} />
      <Frame device={device}>
        <RichTextDescription
          defaultValue={description}
          onChange={setDescription}
        />
      </Frame>
      <VSpace value={3} />
      <BigButton
        disabled={!isNameValid()}
        border="2px solid black"
        noTransform
        size={device === DeviceType.Desktop ? `medium` : `large`}
        fillWidth={device === DeviceType.Desktop}
        onClick={onNext}
      >
        Next
      </BigButton>
    </InspectorWidgetLayout>
  );
}

const Label = styled.span`
  color: #b9c0c7;
  font-size: 1.4rem;
  font-family: Inter, serif;
  font-weight: 500;
`;

const Frame = styled.div`
  border: 1px solid rgb(221, 219, 219);
  border-radius: 1.5rem;
  padding: 1.3rem;
  max-width: ${(props: { device: DeviceType }) =>
    props.device === DeviceType.Desktop ? `100%` : `60%`};
`;

const NameInput = styled(TextInput)`
  && {
    border: 1px solid rgb(221, 219, 219);
    box-shadow: none;
    margin: 0;
    width: ${(props: { device: DeviceType }) =>
      props.device === DeviceType.Desktop ? `100%` : `60%`};
    padding: ${(props: { device: DeviceType }) =>
      props.device === DeviceType.Desktop ? `1.2rem` : `1.8rem`};
    font-size: ${(props: { device: DeviceType }) =>
      props.device === DeviceType.Desktop ? `1.4rem` : `1.6rem`};
    font-weight: 500;
  }
`;
