import * as React from 'react';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import { EditorContext } from '@/features/editor/context/editor-context';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import { DeviceType } from '@/utils/definitions';
import { INSPECTOR_BACK_ID } from '@/features/editor/inspector';
import { CustomizationsSection } from '@/features/editor/widgets/custom-widget/blueprint-style';
import {
  ContentSection,
  HeaderSection,
  Text,
} from '@/features/editor/widgets/custom-widget/inputs/shared/customization-group';
import { Flexbox } from '@/components/flex';
import { PostPurchaseTimerStyles } from '@/features/editor/widgets/post-purchase/styling/timer';
import { PostPurchasePricesStyles } from '@/features/editor/widgets/post-purchase/styling/prices';
import { PostPurchaseSummaryStyles } from '@/features/editor/widgets/post-purchase/styling/summary';
import { PostPurchaseHeaderStyles } from '@/features/editor/widgets/post-purchase/styling/header';
import { PostPurchaseSubHeaderStyles } from '@/features/editor/widgets/post-purchase/styling/sub-header';
import { PostPurchaseButtonStyles } from '@/features/editor/widgets/post-purchase/styling/buttons';
import { GlossyWrapper } from '@/components/glossy-wrapper';
import { BigButton } from '@/components/big-button';
import { PostPurchaseProductCallout } from '@/features/editor/widgets/post-purchase/styling/product-callout';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import { PostPurchaseDisclaimer } from '@/features/editor/widgets/post-purchase/styling/disclaimer';

export function PostPurchaseStyleStep() {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);

  const { props, gotoCustomize } = useContext(PostPurchaseContext);
  const isExperimentalOptionsEnabled = useFeatureBit(
    FeatureBit.ENABLE_POST_PURCHASE_EXPERIMENTAL_OPTIONS,
    false,
  );

  return (
    <Wrapper device={device}>
      <InspectorWidgetLayout
        title="Detailed Styling"
        progress={80}
        progressWidthPercentMobile={55}
        backCaption="< Back to customization"
        onBackClicked={gotoCustomize}
        footer={
          <FooterSection>
            <BigButton
              color="white"
              background="black"
              noTransform
              fillWidth
              size={device === DeviceType.Desktop ? `medium-thin` : `medium`}
              onClick={gotoCustomize}
            >
              Apply
            </BigButton>
          </FooterSection>
        }
      >
        <CustomizationsSection>
          {props?.settings?.showTimer && (
            <Section title="Timer">
              <PostPurchaseTimerStyles />
            </Section>
          )}
          <Section title="Header">
            <PostPurchaseHeaderStyles />
          </Section>
          <Section title="Sub Header">
            <PostPurchaseSubHeaderStyles />
          </Section>
          <Section title="Prices">
            <PostPurchasePricesStyles />
          </Section>
          {isExperimentalOptionsEnabled === true && (
            <Section title="Product Callout">
              <PostPurchaseProductCallout />
            </Section>
          )}
          <Section title="Summary">
            <PostPurchaseSummaryStyles />
          </Section>
          {isExperimentalOptionsEnabled === true && (
            <Section title="Disclaimer">
              <PostPurchaseDisclaimer />
            </Section>
          )}
          <Section title="Buttons">
            <PostPurchaseButtonStyles />
          </Section>
        </CustomizationsSection>
      </InspectorWidgetLayout>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: ${(props: { device: DeviceType }) =>
    props.device === DeviceType.Desktop ? `100%` : `65%`};

  #${INSPECTOR_BACK_ID} {
    z-index: 31;
  }
`;

const Section = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div style={{ userSelect: `none` }}>
      <HeaderSection onClick={() => setIsOpen(!isOpen)}>
        <Text>{title}</Text>
        <Flexbox direction="row" gap="1rem">
          <Text>{isOpen ? `-` : `+`}</Text>
        </Flexbox>
      </HeaderSection>
      {isOpen && <ContentSection isOpen={isOpen}>{children}</ContentSection>}
    </div>
  );
};

const FooterSection = styled(GlossyWrapper)`
  && {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    margin-left: -2rem;
    z-index: 40;
    margin-bottom: 2rem;
  }
`;
