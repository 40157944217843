import {
  CatalogWidgetProps,
  Component,
  Customization,
  CustomizationSpec,
  QuickEditGroup,
} from '@/webapi/use-widget-catalog-api';

export function extractQuickEditItemsFromGroups(
  schema: CatalogWidgetProps,
  group: QuickEditGroup,
): Record<string, QuickEditContext[]> {
  return group.quickEdit
    .map((qe) => findQuickEditSpec(schema, qe.selector))
    .reduce((acc, obj) => {
      const { key } = obj.customization;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
}

export function extractQuickEditItems(
  params: CatalogWidgetProps,
): Record<string, QuickEditContext[]> {
  return params.settings.quickEdit
    .map((qe) => findQuickEditSpec(params, qe.selector))
    .reduce((acc, obj) => {
      const key = obj?.customization?.key;
      if (key) {
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
      }
      return acc;
    }, {});
}

export function findQuickEditSpec(
  params: CatalogWidgetProps,
  selector: string,
): QuickEditContext | undefined {
  const parts = selector.split(`.`);

  const custIdx = params.customizations.findIndex(
    (cust) => cust.key === parts[0],
  );

  if (custIdx > -1) {
    const compIdx = params.customizations[custIdx].components.findIndex(
      (comp) => comp.key === parts[1],
    );

    if (compIdx > -1) {
      const specIdx = params.customizations[custIdx].components[
        compIdx
      ].specs.findIndex((spec) => spec.key === parts[2]);

      return {
        customizationIdx: custIdx,
        customization: params.customizations[custIdx],
        componentIdx: compIdx,
        component: params.customizations[custIdx].components[compIdx],
        specIdx,
        spec: params.customizations[custIdx].components[compIdx].specs[specIdx],
      };
    }
  }
  return undefined;
}

export interface QuickEditContext {
  customizationIdx: number;
  customization: Customization;
  componentIdx: number;
  component: Component;
  specIdx: number;
  spec: CustomizationSpec;
}
