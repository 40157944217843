import * as React from 'react';
import { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import styled, { createGlobalStyle } from 'styled-components';
import { BigButton } from '@/components/big-button';
import { truncate } from '@/utils/types';
import { hash } from '@/utils/cache';

export const FakeClickCta = ({ disabled, onClick, children, br, fw }: any) => (
  <BigButton
    disabled={disabled}
    color="white"
    background="#334BFF"
    noTransform
    noShadow
    size="large"
    onClick={onClick}
    fullWidth={!!fw}
    br={br}
  >
    {children}
  </BigButton>
);

export function TruncatedText({
  text,
  limit = 30,
}: {
  text: string;
  limit?: number;
}) {
  const id = useMemo(() => `truncated-${hash(text)}`, [text]);
  const truncatedText = useMemo(() => truncate(text, limit), [text, limit]);
  const isTruncated = useMemo(
    () => text.length > truncatedText.length,
    [text, truncatedText],
  );

  return (
    <>
      <TruncatedTextWrapper data-tip data-for={id}>
        {truncatedText}
      </TruncatedTextWrapper>
      <TruncatedTextTooltipWrapper />
      {isTruncated && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <ReactTooltip
          id={id}
          place="top"
          backgroundColor="#fff"
          textColor="#000"
          className="truncate-tooltip"
        >
          <span>{text}</span>
        </ReactTooltip>
      )}
    </>
  );
}

const TruncatedTextWrapper = styled.span``;

const TruncatedTextTooltipWrapper = createGlobalStyle`
  .truncate-tooltip {
    box-shadow: 0 4px 5px 0 rgba(105, 122, 231, 0.11),
    0 12px 16px 0 rgba(196, 217, 210, 0.38), 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    border-radius: 1rem!important;
    font-family: "JetBrains Mono",serif;
  }
`;
