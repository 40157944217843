import React, { useContext } from 'react';
import styled from 'styled-components';
import { StyledTemplateContext } from '@/features/editor/widgets/custom-widget/style-templating/context';
import { HSpace, VSpace } from '@/components/spacing';
import { BigButton } from '@/components/big-button';
import { useIsSuperSystemUser } from '@/features/editor/widgets/custom-widget/style-templating/utils';

export function ModalFooter() {
  const {
    modalCtx,
    showOverrideModal,
    deleteStyleId,
    rmStyle,
    renameClicked,
    showUpdateStoreStyleModal,
    resetStylesOverride,
    overrideStoreStyle,
  } = useContext(StyledTemplateContext);
  const {
    onCancelClick,
    onRenameClick,
    isRenameDisabled,
    onOverrideClick,
    onDuplicateClick,
  } = modalCtx;
  const isSuperSystemUser = useIsSuperSystemUser();
  if (showUpdateStoreStyleModal) {
    return (
      <Flex>
        <BtnsWrapper>
          <HSpace value={2} />
          <BigButton
            onClick={async () => {
              await overrideStoreStyle();
              onCancelClick();
            }}
            border="2px solid black"
            noShadow={false}
            noTransform
            size="large"
            fillWidth
          >
            Update
          </BigButton>
          <HSpace value={2} />
          <BigButton
            onClick={() => {
              resetStylesOverride();
              onCancelClick();
            }}
            border="2px solid #C8CACB"
            background="white"
            color="#97A0A8"
            noShadow
            noTransform
            size="large"
            fillWidth
          >
            Cancel
          </BigButton>
          <HSpace value={2} />
        </BtnsWrapper>
      </Flex>
    );
  }
  if (deleteStyleId) {
    return (
      <Flex>
        <BtnsWrapper>
          <HSpace value={2} />
          <BigButton
            onClick={() => rmStyle(deleteStyleId)}
            border="2px solid black"
            noShadow={false}
            noTransform
            size="large"
            fillWidth
          >
            Delete
          </BigButton>
          <HSpace value={2} />
          <BigButton
            onClick={onCancelClick}
            border="2px solid #C8CACB"
            background="white"
            color="#97A0A8"
            noShadow
            noTransform
            size="large"
            fillWidth
          >
            Cancel
          </BigButton>
          <HSpace value={2} />
        </BtnsWrapper>
      </Flex>
    );
  }

  if (showOverrideModal) {
    return (
      <Flex style={{ width: `100%` }}>
        <BtnsWrapper>
          <HSpace value={2} />
          <BigButton
            onClick={onOverrideClick}
            border="2px solid black"
            noShadow={false}
            noTransform
            size="large"
            fillWidth
          >
            Override
          </BigButton>
          <HSpace value={2} />
          <BigButton
            disabled={isRenameDisabled}
            onClick={onDuplicateClick}
            border="2px solid #C8CACB"
            background="white"
            color="#97A0A8"
            noShadow
            noTransform
            size="large"
            fillWidth
          >
            Duplicate
          </BigButton>
          <HSpace value={2} />
        </BtnsWrapper>
      </Flex>
    );
  }
  const shouldShowSuperSaveBtn = isSuperSystemUser && !renameClicked;
  return (
    <Flex style={{ width: `100%` }}>
      <BtnsWrapper>
        <HSpace value={2} />
        <BigButton
          disabled={isRenameDisabled}
          onClick={onRenameClick}
          border="2px solid black"
          noShadow={false}
          noTransform
          size="large"
          fillWidth
        >
          {renameClicked ? `Rename` : `Save Style`}
        </BigButton>
        <HSpace value={2} />
        <BigButton
          onClick={onCancelClick}
          border="2px solid #C8CACB"
          background="white"
          color="#97A0A8"
          noShadow
          noTransform
          size="large"
          fillWidth
        >
          Cancel
        </BigButton>
        <HSpace value={2} />
      </BtnsWrapper>
      {shouldShowSuperSaveBtn && (
        <div style={{ width: `93%` }}>
          <VSpace value={2} />
          <BigButton
            size="large"
            disabled={isRenameDisabled}
            border="2px solid black"
            noShadow={false}
            noTransform
            fillWidth
            onClick={() => onRenameClick(isSuperSystemUser)}
          >
            Save for all
          </BigButton>
        </div>
      )}
    </Flex>
  );
}

const Flex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const BtnsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0 1rem;
`;
