import React from 'react';
import styled from 'styled-components';
import { IoIosArrowBack } from 'react-icons/io';

type Props = {
  images: any[];
  onSelect: (stc: string) => () => void;
};

export function ManualImageSelectInner({ images, onSelect }: Props) {
  return (
    <Grid>
      {images.map(({ src }) => (
        <Box onClick={onSelect(src)}>
          <img src={src} alt="pic" />
        </Box>
      ))}
    </Grid>
  );
}

const Box = styled.div`
  width: 240px;
  border: 1px solid #969696;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09), 0 1px 4px 0 rgba(7, 6, 6, 0.04);
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  &:active {
    cursor: default;
    opacity: 1;
    box-shadow: none;
  }
  img {
    width: 100%;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
`;

export function ManualImageSelectHeaderInner({
  onBack,
}: {
  onBack: () => void;
}) {
  return (
    <HeaderStyled>
      <Back type="button" onClick={onBack}>
        <IoIosArrowBack color="grey" size={40} />
      </Back>
      <p>Please select the preferred product image</p>
    </HeaderStyled>
  );
}

const HeaderStyled = styled.div`
  position: relative;
  width: 100%;

  p {
    font-size: 3rem;
    margin: 1rem 0 1rem 0;
  }
`;
const Back = styled.button`
  &:hover {
    cursor: pointer;
    opacity: 0.3;
  }
  &:active {
    cursor: pointer;
    opacity: 0.5;
  }
  opacity: 0.5;
  left: 20px;
  position: absolute;
  top: -30px;
  color: #d2d4d7;
`;
