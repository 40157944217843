/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { AdSets } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/ad-sets';
import {
  AdSearchContext,
  AdSearchState,
} from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/context';
import {
  clone,
  Details,
  ExpandBtn,
  LoadMoreBtn,
} from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/shared';
import { FacebookAudienceContext } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/context';
import { FacebookTargeting } from '@/components/query-builder/models';

export function Campaigns() {
  const { setState, state, loadMoreAdsets } = useContext(AdSearchContext);
  const { targeting } = useContext(FacebookAudienceContext);
  const [loading, setLoading] = useState(false);
  return (
    <>
      {state.campaigns?.map((campaign, idx) => {
        const onExpand = () =>
          setState((draft) => {
            draft.campaigns[idx].isCollapsed =
              !draft.campaigns[idx].isCollapsed;
          });
        const { isSelected } = state.campaigns[idx];
        const onSelect = (isSelected) => {
          handleCampaignTargeting(state, idx, targeting, isSelected);
          setState((draft) => {
            draft.campaigns[idx].isSelected = isSelected;
            draft.campaigns[idx]?.adSets?.forEach((adset) => {
              adset.isSelected = isSelected;
              adset?.ads?.forEach((ad) => {
                ad.isSelected = isSelected;
              });
            });
          });
        };
        const status = campaign?.status;
        const name = campaign?.name;
        return (
          <CampaignTile key={campaign.id}>
            <CampaignHeader>
              <Details
                defaultValue={isSelected}
                onChange={onSelect}
                status={status}
                name={name}
                onExpand={onExpand}
              />
              <ExpandBtn
                onClick={onExpand}
                isCollapsed={campaign.isCollapsed}
              />
            </CampaignHeader>
            {!campaign?.isCollapsed && (
              <>
                <AdSets
                  adSets={campaign?.adSets}
                  campaignIdx={idx}
                  campaignId={campaign.id}
                />
                {campaign.next && (
                  <LoadMoreBtn
                    mb="2rem"
                    loading={loading}
                    name="adsets"
                    onClick={async () => {
                      setLoading(true);
                      await loadMoreAdsets(campaign.next, campaign.id);
                      setLoading(false);
                    }}
                  />
                )}
              </>
            )}
          </CampaignTile>
        );
      })}
    </>
  );
}

const CampaignHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 1.4rem;
  color: #576470;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 5px 32px 0 rgba(182, 207, 205, 0.28);
  width: 100%;
  padding: 1rem 2rem;
  border-radius: 12px;
  border: 1px solid #eaecf1;
  background-color: #ffffff;
`;

export const CampaignTile = styled.div`
  margin-top: 1rem;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 12px;
`;

function handleCampaignTargeting(
  state: AdSearchState,
  idx: number,
  targeting: React.MutableRefObject<FacebookTargeting>,
  isSelected,
) {
  const campaign = state.campaigns[idx];
  const existsInTargeting = !!targeting.current.campaigns?.find(
    (c) => campaign.id === c.id,
  );
  if (isSelected && !existsInTargeting) {
    const campaigns = [
      ...targeting.current.campaigns,
      clone({
        name: campaign.name,
        id: campaign.id,
      }),
    ];
    targeting.current = { ...targeting.current, campaigns };
  } else if (!isSelected) {
    const campaigns = targeting.current.campaigns.filter(
      (c) => c.id !== campaign.id,
    );
    const adsets = targeting.current.adsets.filter(
      (c) => c.campaign_id !== campaign.id,
    );
    const ads = targeting.current.ads.filter(
      (c) => c.campaign_id !== campaign.id,
    );
    targeting.current = { ...targeting.current, campaigns, adsets, ads };
  }
}
