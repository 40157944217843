import styled from 'styled-components';
import { ComponentSize } from '@/styles/sizes';

export const BackButton = styled.button`
  appearance: none;
  outline: none;
  text-decoration: none;
  border: none;

  padding: 1rem;

  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: -0.35px;

  color: ${(props: BackButtonProps) => (props.color ? props.color : `#7D94A4`)};

  background: transparent;

  text-transform: ${(props: BackButtonProps) =>
    props.noTransform ? `none` : `uppercase`};

  cursor: ${(props: BackButtonProps) =>
    props.disabled ? `not-allowed` : `pointer`};

  pointer-events: ${(props: BackButtonProps) =>
    props.disabled ? `none` : `all`};

  opacity: ${(props: BackButtonProps) => (props.disabled ? 0.4 : 1)};

  :hover {
    //TODO
    opacity: ${(props: BackButtonProps) => (props.disabled ? 1 : 0.8)};
  }

  :active {
    opacity: 0.2; //TODO
  }
`;

export interface BackButtonProps {
  size?: ComponentSize;
  color?: string;
  noTransform?: boolean;
  disabled?: boolean;
}
