import styled from 'styled-components';

export const Flexbox = styled.div`
  display: flex;
  flex-direction: ${({ direction }: FlexboxProps) => direction || `column`};
  flex: ${({ flex }: FlexboxProps) => flex || `unset`};
  align-items: ${({ align }: FlexboxProps) => align || `flex-start`};
  justify-content: ${({ justify }: FlexboxProps) => justify || `flex-start`};
  ${({ self }: FlexboxProps) => (self ? `align-self: ${self};` : ``)}
  ${({ width }: FlexboxProps) => (width ? `width: ${width};` : ``)}
  ${({ height }: FlexboxProps) => (height ? `height: ${height};` : ``)}
  ${({ padding }: FlexboxProps) => (padding ? `padding: ${padding};` : ``)}
  ${(props: FlexboxProps) => (props.gap ? gap(props) : ``)}
  ${({ overflow }: FlexboxProps) => (overflow ? `overflow: ${overflow}` : ``)};
`;

const gap = (props: FlexboxProps): string => {
  if ([`column`, `column-reverse`].includes(props.direction)) {
    return `
    && > :not(:last-child) {
      margin-bottom: ${props.gap};
    `;
  }
  return `
    && > :not(:last-child) {
      margin-right: ${props.gap};
    `;
};

export interface FlexboxProps {
  overflow?: `hidden` | `auto` | `scroll`;
  direction?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  flex?: number;
  width?: string;
  height?: string;
  padding: string;
  align?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around';
  self?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around';
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around';
  gap?: string;
}

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;
