import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DropzoneRootProps, useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { useSessionStorage } from 'react-use';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';
import { HSpace, VSpace } from '@/components/spacing';
import { TextInput } from '@/components/text-input';
import { Flexbox } from '@/components/flex';
import { ImageEditorContext } from '@/features/editor/widgets/custom-widget/inputs/background/image/shared/context';
import { Card } from '@/components/card';
import { AccountContext, useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import { useImageGen } from '@/features/editor/widgets/custom-widget/inputs/background/image/upload/useImageGen';
import { Spinner } from '@/Spinner';
import { Tooltip } from '@/components/tooltip';

const baseStyle = {
  padding: `5rem 1rem`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `center`,
  alignItems: `center`,
  borderWidth: 2,
  borderRadius: 2,
  borderColor: `#B9D1E3`,
  borderStyle: `dashed`,
  backgroundColor: `#F5FDFF`,
  color: `#BDBDBD`,
  outline: `none`,
  transition: `border .24s ease-in-out`,
};

const focusedStyle = {
  borderColor: `#AAC9E1`,
};

const acceptStyle = {
  borderColor: `#ECFFF1`,
  backgroundColor: `#ECFFF1`,
};

const rejectStyle = {
  borderColor: `#FFECEC`,
  backgroundColor: `#FF3A3A`,
};

export function ImageEditorUpload({
  getModalRect,
}: {
  getModalRect: () => DOMRect;
}) {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);

  const { setImage } = useContext(ImageEditorContext);
  const [imageLink, setImageLink] = useState(``);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: `image/*` });

  const style: DropzoneRootProps = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  useEffect(() => {
    if (acceptedFiles?.length >= 1) {
      const file = acceptedFiles[0];
      const url = URL.createObjectURL(file);
      setImage(url);
    }
  }, [acceptedFiles]);

  const [valid, setValid] = useState(true);
  const onImageLinkUpload = async (imageSrc?: string) => {
    const link = imageLink || imageSrc;
    if (link !== ``) {
      const isValid = await testImage(link);
      if (isValid) {
        setImage(link);
        setValid(true);
      } else {
        setValid(false);
      }
    }
  };

  const aiEnabled = useFeatureBit(FeatureBit.AI_IMAGE);

  return (
    <Wrapper device={device} height={getModalRect()?.height}>
      <span />
      <Content>
        <CardWrapper nohover>
          <StyledDropzone {...getRootProps({ style })}>
            <DropzoneIcon />
            <input {...getInputProps()} />
            <VSpace value={2} />
            <p>Drag and drop a file or</p>
            <VSpace value={1} />
            <p>
              <u>click to upload</u>
            </p>
          </StyledDropzone>
        </CardWrapper>
        <VSpace value={3} />
        <LinkUpload
          valid={valid}
          defaultValue={imageLink}
          onChange={(ev) => setImageLink(ev?.target?.value)}
          onClick={onImageLinkUpload}
        />
        {aiEnabled && <AiImageGen onImageLinkUpload={onImageLinkUpload} />}
      </Content>
      <span />
    </Wrapper>
  );
}

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 79px;
`;

const AiImagesList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
`;
const AIImageBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 256px;
    height: 256px;
    border-radius: 10px;
  }

  :hover {
    cursor: pointer;
    opacity: 0.5;
  }

  :active {
    cursor: default;
    opacity: 1;
  }
`;

const CardWrapper = styled(Card)`
  && {
    width: 100%;

    :hover {
      cursor: default;
    }
  }
`;
const ErrText = styled.span`
  margin: 0.5rem 0;
  font-family: Inter, serif;
  font-weight: 400;
  font-size: 1.3rem;
  color: red;
`;

function testImage(url) {
  return new Promise<boolean>((resolve) => {
    const timeout = 2000;
    let timer;
    const img = new Image();
    // eslint-disable-next-line no-multi-assign
    img.onerror = img.onabort = function () {
      clearTimeout(timer);
      resolve(false);
    };
    img.onload = function () {
      clearTimeout(timer);
      resolve(true);
    };
    timer = setTimeout(() => {
      img.src = `//!!!!/test.jpg`;
      // eslint-disable-next-line prefer-promise-reject-errors
      resolve(false);
    }, timeout);
    img.src = url;
  });
}

interface P {
  device: DeviceType;
  height: number;
}

const Wrapper = styled.div`
  display: grid;
  height: ${(p: P) => p.height - 200}px;
  overflow-y: scroll;
  align-items: center;
  justify-content: center;
  grid-template-columns: ${(props: { device: DeviceType }) =>
    props.device === DeviceType.Desktop
      ? `4rem 1fr 4rem`
      : `0.2fr 4fr 0.2fr`}; ;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledDropzone = styled.div`
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:active {
    cursor: default;
    opacity: 1;
  }

  width: 100%;

  p {
    margin: 0;
    color: #9fb5ca;
    font-family: Inter, serif;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.32px;
  }
`;

const Text = styled.p`
  margin: 0;
  text-align: center;
  color: #60686e;
  font-family: Inter, serif;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.32px;
`;

const ImageInputText = styled(TextInput)`
  && {
    width: 100%;
    margin: 0;
    text-align: start;
    font-size: 1.4rem;
    font-weight: 400;
    font-family: Inter, serif;
    padding: 1rem 2rem;
    border: 1px solid rgba(134, 152, 159, 0.52);
    border-radius: 1rem;
    background-color: #ffffff;
    box-shadow: 0 9px 13px 0 rgba(177, 217, 203, 0.18);
  }
`;

const UploadButton = styled.button`
  cursor: pointer;
  border: 1px solid #f0f0f0;
  outline: none;
  appearance: none;

  background: #f0f0f0;
  border-radius: 1rem;

  font-size: 1.4rem;
  font-weight: bold;

  padding: 1rem;

  :disabled {
    pointer-events: none;
    color: #b6c1cb;
    opacity: 0.6;
  }

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.6;
  }
`;

function DropzoneIcon() {
  return (
    <StyledIcon>
      <StaticImage
        src="../../../../../../../../assets/dropzone_icon.svg"
        alt="dropzone"
        placeholder="none"
        loading="eager"
        objectFit="contain"
        height={100}
      />
    </StyledIcon>
  );
}

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .gatsby-image-wrapper {
    height: 6.5rem;
  }
`;

function AiImageGen({
  onImageLinkUpload,
}: {
  onImageLinkUpload: (src) => void;
}) {
  const account = useContext(AccountContext);
  const alias = account?.account?.store?.alias || ``;

  const [aiPrompt, setAiPrompt] = useSessionStorage(`${alias}_aiPrompt`, ``);
  const [aiImageUrls, setAiImageUrls] = useSessionStorage<Array<string>>(
    `${alias}_aiImageUrls`,
    [],
  );
  const [aiLimitReached, setAiLimitReached] = useState(false);
  const [aiError, setAiError] = useState(false);

  const { getImage, loading: aiLoading } = useImageGen();

  const onAiGen = async (append?: boolean) => {
    if (aiPrompt !== ``) {
      const resp = await getImage(aiPrompt);
      if (!resp.limitReached && !resp.error) {
        setAiImageUrls(append ? [...aiImageUrls, ...resp.urls] : resp.urls);
      }
      if (resp.limitReached) {
        setAiLimitReached(resp.limitReached);
      }
      if (resp.error) {
        setAiError(true);
      }
    }
  };
  const hasAiImages = aiImageUrls.length > 0;

  if (aiError) {
    return (
      <>
        <VSpace value={2} />
        <CardWrapper nohover>
          <ImageInputText
            placeholder="type something"
            defaultValue={aiPrompt}
            onChange={(ev) => setAiPrompt(ev?.target?.value)}
            disabled
          />
          <VSpace value={2.8} />
          <Text>Oops... something went wrong.</Text>
          <VSpace value={2.8} />
          <UploadButton disabled={false} onClick={() => setAiError(false)}>
            Ok
          </UploadButton>
        </CardWrapper>
      </>
    );
  }

  if (aiLimitReached) {
    return (
      <>
        <VSpace value={4} />
        <CardWrapper nohover>
          <ImageInputText
            placeholder="Type something"
            defaultValue={aiPrompt}
            onChange={(ev) => setAiPrompt(ev?.target?.value)}
            disabled
          />
          <VSpace value={2} />
          <Text>
            Unfortunately, {youve()} reached your usage limit. if you require
            further assistance, please send a message through the chat.
          </Text>
        </CardWrapper>
      </>
    );
  }
  return (
    <>
      <VSpace value={2} />
      <CardWrapper nohover>
        {!aiLoading && !hasAiImages && (
          <FlexRow>
            <Text style={{ margin: `1rem 0` }}>
              Or create amazing images effortlessly with{` `}
              <strong>Dall-E</strong>.
              <br />
              Simply describe your idea and let the AI do the rest.
            </Text>
            <Tooltip
              zIndex={999999999999999}
              title="Prompt Suggestions"
              text="The generative models support a variety of visual styles, such as: digital art, traditional art, oil painting of, in style of Picasso, in steampunk style, in style of Murakami, abstract art of Additionally, you can try prompts to define the fidelity of an image, such as: high quality,  photorealistic, 4k, texture. For more realistic images use less discriptive text, for example:'a rabbit under a christmas tree'"
            />
            <VSpace value={2} />
          </FlexRow>
        )}
        <Flexbox width="100%" direction="row" align="center" justify="stretch">
          <ImageInputText
            placeholder="Type something"
            defaultValue={aiPrompt}
            onChange={(ev) => setAiPrompt(ev?.target?.value)}
            disabled={aiLoading}
          />
          <HSpace value={2} />

          <UploadButton
            disabled={aiPrompt.length <= 3 || aiLoading}
            onClick={() => onAiGen(false)}
          >
            {aiLoading ? (
              <SpinnerContainer>
                <Spinner size={20} mt="0" relative />
              </SpinnerContainer>
            ) : (
              `Generate`
            )}
          </UploadButton>
        </Flexbox>
        {aiLoading && (
          <>
            <VSpace value={2} />
            <ProgressBar />
          </>
        )}
        {hasAiImages && !aiLoading && (
          <>
            <VSpace value={2} />
            <AiImagesList>
              {aiImageUrls.map((src) => (
                <AIImageBox
                  key={src}
                  onClick={async () => {
                    await onImageLinkUpload(decodeURI(src));
                  }}
                >
                  <img src={src} alt="aigen" />
                </AIImageBox>
              ))}
            </AiImagesList>
            <VSpace value={2} />
            <div style={{ maxWidth: `50%`, margin: `0 auto` }}>
              <UploadButton
                disabled={aiImageUrls.length > 3}
                onClick={() => onAiGen(true)}
              >
                Generate More
              </UploadButton>
            </div>
            <VSpace value={0.5} />
          </>
        )}
      </CardWrapper>
    </>
  );
}

const ProgressBar = ({
  bgColor,
  progressColor,
  duration,
}: {
  bgColor?: string;
  progressColor?: string;
  duration?: number;
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress < 100 ? prevProgress + 1 : 100,
      );
    }, ((duration || 60) * 1000) / 100);

    return () => clearInterval(interval);
  }, [duration]);

  const containerStyle = {
    backgroundColor: bgColor || `rgba(216, 216, 216, 0.48)`,
    borderRadius: `5px`,
    height: `5px`,
    width: `100%`,
    overflow: `hidden`,
  };

  const progressStyle = {
    backgroundColor: progressColor || `#6e6b6b`,
    borderRadius: `5px`,
    height: `100%`,
    width: `${progress}%`,
    transition: `width 0.3s ease-in-out`,
  };

  return (
    <div style={containerStyle}>
      <div style={progressStyle} />
    </div>
  );
};

const FlexRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 2.1rem 0;
`;

function youve() {
  return `you've`;
}

function LinkUpload({
  defaultValue,
  onChange,
  onClick,
  valid,
}: {
  valid: boolean;
  defaultValue: string;
  onChange: (ev) => void;
  onClick: (imageSrc?: string) => Promise<void>;
}) {
  return (
    <CardWrapper nohover>
      <Text style={{ margin: `1.4rem 0 2.8rem 0` }}>
        Or add a link to the image
      </Text>
      {!valid && <ErrText>invalid image link</ErrText>}
      <Flexbox width="100%" direction="row" align="center" justify="stretch">
        <ImageInputText
          placeholder="Paste your image link here"
          defaultValue={defaultValue}
          onChange={onChange}
        />
        <HSpace value={2} />
        <UploadButton disabled={defaultValue.length <= 10} onClick={onClick}>
          Upload
        </UploadButton>
      </Flexbox>
      <VSpace value={2} />
    </CardWrapper>
  );
}
