import React, { MutableRefObject, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { centered } from '@/components/use-shared-element';
import { GradientModal } from '@/components/gradient-modal';
import { VSpace } from '@/components/spacing';
import Portal from '@/components/portal';
import { CloseButton } from '@/components/close-button';

export interface ExperienceImagesModalProps {
  fromRef: MutableRefObject<any>;
  visible: boolean;
  setVisible: (s: boolean) => void;
  beforeImages: string[];
  afterImages: string[];
}

export function ExperienceImagesModal({
  fromRef,
  visible,
  setVisible,
  beforeImages,
  afterImages,
}: ExperienceImagesModalProps) {
  const modalCfg = getModalConfig(fromRef);

  const [isImageSliderOpen, setIsImageSliderOpen] = useState(false);
  const [currentSliderImage, setCurrentSliderImage] = useState<string | null>(
    null,
  );

  const combinedImages = useMemo(
    () => [...(beforeImages || []), ...(afterImages || [])],
    [beforeImages, afterImages],
  );

  const onImageLoad = (ev: any) => {
    const img = ev.target;
    if (img.naturalWidth > img.naturalHeight) {
      img.classList.add(`landscape`);
    }
  };

  const imageType = useMemo(() => {
    const isBefore = beforeImages?.includes(currentSliderImage);
    return isBefore ? `Before` : `After`;
  }, [currentSliderImage]);

  const onImageClicked = (img: string) => {
    setIsImageSliderOpen(true);
    setCurrentSliderImage(img);
  };

  const closeImageSlider = () => {
    setIsImageSliderOpen(false);
    setCurrentSliderImage(null);
  };

  const onNextImage = () => {
    const currentIdx = combinedImages.findIndex(
      (img) => img === currentSliderImage,
    );
    const nextIdx = (currentIdx + 1) % combinedImages.length;
    setCurrentSliderImage(combinedImages[nextIdx]);
  };

  const onPrevImage = () => {
    const currentIdx = combinedImages.findIndex(
      (img) => img === currentSliderImage,
    );
    const prevIdx =
      (currentIdx - 1 + combinedImages.length) % combinedImages.length;
    setCurrentSliderImage(combinedImages[prevIdx]);
  };

  return (
    <GradientModal
      {...modalCfg}
      isVisible={visible}
      onClose={() => setVisible(false)}
      header=""
      footer={null}
    >
      {isImageSliderOpen && (
        <Portal selector="body">
          <ImageSliderWrapper>
            <CloseButtonWrapper>
              <CloseButton onClick={closeImageSlider} />
            </CloseButtonWrapper>
            <PrevWrapper>
              <FaChevronLeft onClick={onPrevImage} />
            </PrevWrapper>
            <div className="img-container">
              <Badge>{imageType}</Badge>
              <img src={currentSliderImage} alt={currentSliderImage} />
            </div>
            <NextWrapper>
              <FaChevronRight onClick={onNextImage} />
            </NextWrapper>
          </ImageSliderWrapper>
        </Portal>
      )}
      <Wrapper>
        <Column>
          Before
          <VSpace value={2} />
          <ImageGrid>
            {beforeImages?.map((img) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <img
                src={img}
                key={img}
                alt={img}
                onLoad={onImageLoad}
                onClick={() => onImageClicked(img)}
              />
            ))}
          </ImageGrid>
        </Column>
        <Column>
          After
          <VSpace value={2} />
          <ImageGrid>
            {afterImages?.map((img) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <img
                src={img}
                key={img}
                alt={img}
                onLoad={onImageLoad}
                onClick={() => onImageClicked(img)}
              />
            ))}
          </ImageGrid>
        </Column>
      </Wrapper>
    </GradientModal>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8rem;
`;

const Column = styled.div`
  width: 100%;
  max-width: 100%;
  display: block;
  font-size: 1.6rem;
  color: #474747;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  img {
    max-width: 100%;
    min-width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
    cursor: zoom-in;
    transition: opacity 0.2s ease;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  }

  img:hover {
    opacity: 0.8;
  }

  img.landscape {
    grid-column: span 2;
  }
`;

const ImageSliderWrapper = styled.div`
  position: fixed;
  inset: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  z-index: 1000000000000000000;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  && * {
    user-select: none;
  }

  .img-container {
    padding: 0;
    margin: 0;
    position: relative;
    height: auto;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 90vh;
    max-width: 90vw;
  }

  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 90vh;
    max-width: 90vw;
  }
`;

const Badge = styled.span`
  position: absolute;
  font-size: 1.4rem;
  background: #000000;
  top: 2rem;
  left: 2rem;
  z-index: 1;
  color: #ffffff;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 2rem;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 3rem;
  right: 3rem;
`;

const PrevWrapper = styled.div`
  position: absolute;
  left: 4rem;
  font-size: 4rem;
  color: #6c6c6c;
  filter: drop-shadow(0 0 0.8rem rgba(0, 0, 0, 0.2));
  cursor: pointer;

  &&:hover {
    opacity: 0.8;
  }

  &&:active {
    opacity: 0.6;
  }
`;

const NextWrapper = styled.div`
  position: absolute;
  right: 4rem;
  font-size: 4rem;
  color: #6c6c6c;
  filter: drop-shadow(0 0 0.8rem rgba(0, 0, 0, 0.2));
  cursor: pointer;

  &&:hover {
    opacity: 0.8;
  }

  &&:active {
    opacity: 0.6;
  }
`;

function getModalConfig(fromRef: React.MutableRefObject<any>) {
  const targetPosAndSize = centered(68, 110);

  return {
    targetPosAndSize,
    closeTop: `0.5rem`,
    closeRight: `2.5rem`,
    fromStyle: {
      borderRadius: `5rem`,
      backgroundColor: `#DEDEDE`,
      padding: `2rem 0 0 0`,
    },
    toStyle: {
      borderRadius: `1rem`,
      backgroundColor: `#FFFFFF`,
      padding: `2rem 0 0 0`,
    },
    showBackdrop: true,
    fromRef,
    overflowTop: { heightInRem: 6 },
    overflowBottom: { heightInRem: 8, turnPointInPercent: 30 },
  };
}
