import React from 'react';
import styled from 'styled-components';

export const CARD_WRAPPER_CONTENT = `card-wrapper-content`;

export function CardWrapper({
  title,
  children,
  hideChildren,
  className,
}: {
  title: any;
  children: any;
  hideChildren?: boolean;
  className?: string;
}) {
  return (
    <Wrapper className={className || ``}>
      <Header>{title}</Header>
      {!hideChildren && (
        <Content className={CARD_WRAPPER_CONTENT}>{children}</Content>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 18px 26px 0 rgba(177, 217, 203, 0.18);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5rem 1fr;
  align-items: center;
  justify-content: stretch;

  transform-origin: 0 0;
`;

const Header = styled.div`
  border-bottom: 1px solid rgba(192, 205, 216, 0.4);
  padding: 1.5rem 2rem 1.5rem 1rem;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  padding: 1rem 0rem 1rem 1.5rem;
`;

export const Line = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
`;
