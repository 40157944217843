import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { StorePage } from '@/features/editor/context/use-device-navigation';
import { EditorContext } from '@/features/editor/context/editor-context';
import { urlNoPreviewThemeId } from '@/utils/url';
import { cleanThemePreview } from '@/features/editor/preview-navigation/quick-preview-navigation-list';

export const QuickPreviewNavigationContext = React.createContext(
  {} as QuickPreviewNavigationCtx,
);

export interface QuickPreviewNavigationCtx {
  url: string;
  search: string;
  isCustomUrl: boolean;
  urlsToBeDisplayed: StorePage[];
  allRelated: StorePage[];
  onTextChanged: (ev) => void;
  onUrlSelected: (value: StorePage) => void;
  onSave: () => void;
  scrollRef: React.MutableRefObject<HTMLElement>;
}

function isEmpty(search: string) {
  return !search || search === ` `;
}

export function newQuickPreviewNavigationContext(
  onSaveCallback?: () => void,
): QuickPreviewNavigationCtx {
  const {
    deviceNavigation: { getRelatedUrls, previewUrl, navigateTo },
  } = useContext(EditorContext);
  const scrollRef = useRef<HTMLElement>(null);
  const [url, setUrl] = useState(previewUrl);
  const [search, setSearch] = useState(``);
  const allRelated = useMemo(() => getRelatedUrls()[0], []);
  const suggestedUrls = useMemo(() => getRelatedUrls()[1], []);

  const sortPages = (data: any, search?: string) => {
    const visitedCountStr = window.localStorage.getItem(
      `vsly_visited_alias_pages`,
    );
    if (!visitedCountStr) return data;
    const visitedCount = JSON.parse(visitedCountStr);
    const updateData = [...data];

    Object.keys(visitedCount).forEach((url: string) => {
      url = cleanThemePreview(url);
      if (!suggestedUrls.find((page) => page.url === url)) {
        const relatedItem = allRelated.find((item) => item.url === url);
        if (
          relatedItem &&
          (isEmpty(search) || relatedItem.caption?.includes(search))
        ) {
          updateData.push(relatedItem);
        } else if (
          !relatedItem &&
          (isEmpty(search) || url.toLowerCase().includes(search))
        ) {
          updateData.push({
            url,
            caption: url,
            kind: `CUSTOM`,
            rank: 999,
          });
        }
      }
    });

    const newData = updateData.map((item: any) => {
      item.visited_count = visitedCount[item.url] || 0;
      return item;
    });

    return newData
      .sort((a: any, b: any) => b.visited_count - a.visited_count)
      .slice(0, 100);
  };

  const notRelated = (url) =>
    allRelated.filter(
      (entry) => urlNoPreviewThemeId(url) === urlNoPreviewThemeId(entry.url),
    ).length === 0;

  const [isCustomUrl, setIsCustomUrl] = useState(notRelated(url));

  const urlsToBeDisplayed = useMemo(() => {
    if (search === ``) {
      return sortPages(suggestedUrls);
    }

    return sortPages(
      allRelated
        .filter((page) =>
          page.caption.toLowerCase().includes(search.toLowerCase()),
        )
        .slice(0, 100),
      search.toLowerCase(),
    );
  }, [search]);

  useEffect(() => {
    const indexOfCurrent = urlsToBeDisplayed.findIndex(
      (page) => page.url === previewUrl,
    );
    if (indexOfCurrent > 0) {
      setTimeout(() => {
        const unit =
          (scrollRef?.current?.scrollHeight || 0) / urlsToBeDisplayed.length;

        scrollRef.current?.scrollTo({
          top: unit * indexOfCurrent,
          behavior: `smooth`,
        });
      }, 180);
    } else if (isCustomUrl) {
      setSearch(url);
    }
  }, []);

  useEffect(() => {
    const isCustom =
      urlsToBeDisplayed.length === 0 && search.startsWith(`http`);
    const initiallyCustomUrl = notRelated(url) && search === ``;
    setIsCustomUrl(false);
    if (search === ``) {
      setIsCustomUrl(notRelated(url));
      setUrl(previewUrl);
      setSearch(``);
    } else if (initiallyCustomUrl) {
      setSearch(url);
    } else if (isCustom) {
      setIsCustomUrl(true);
      setUrl(search);
      setTimeout(() => {
        scrollRef.current?.scrollTo({
          top: scrollRef?.current?.scrollHeight,
          behavior: `smooth`,
        });
      }, 100);
    }
  }, [search]);

  const onTextChanged = useDebouncedCallback((ev) => {
    setSearch(ev.target.value);
  }, 300);

  const onUrlSelected = (value: StorePage) => {
    if (value) {
      setUrl(value.url);
      setIsCustomUrl(false);
    }
  };

  const onSave = () => {
    navigateTo(url);
    onSaveCallback && onSaveCallback();
  };

  return {
    url,
    search,
    isCustomUrl,
    urlsToBeDisplayed,
    onTextChanged,
    onUrlSelected,
    onSave,
    scrollRef,
    allRelated,
  };
}
