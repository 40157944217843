import styled from 'styled-components';
import { ComponentSize, fontSizeOf, paddingOf } from '@/styles/sizes';

export const BigButton = styled.button`
  && {
    appearance: none;
    outline: none;
    text-decoration: none;
    border: ${(props: BBP) => (props.border ? props.border : `none`)};
    cursor: ${({ disabled }: BBP) => (disabled ? `default` : `pointer`)};

    width: ${(props: BBP) => (props.fillWidth ? `100%` : `auto`)};

    padding: ${(props: BBP) => paddingOf(props.size)};
    border-radius: ${(p: BBP) => p.br || `1.2rem`};

    font-size: ${(props: BBP) => fontSizeOf(props.size)};
    font-weight: 600;

    box-shadow: ${(props: BBP) =>
      props.noShadow ? `none` : `0 3px 8px 0 rgba(98,54,255,0.23);`};

    color: ${(props: BBP) => (props.color ? props.color : `#FFFFFF`)};

    background: ${(props: BBP) =>
      props.background ? props.background : `#000000`};

    text-transform: ${(props: BBP) =>
      props.noTransform ? `none` : `uppercase`};

    font-family: ${(props: BBP) =>
      props.fontFamily ? props.fontFamily : `inherit`};

    z-index: ${(p: BBP) => (typeof p.zIdx === undefined ? 40 : p.zIdx)};

    :hover {
      opacity: ${({ disabled }: BBP) => (disabled ? `0.3` : `0.8`)};
    }

    :active {
      opacity: ${({ disabled }: BBP) => (disabled ? `0.3` : `0.2`)};
    }
    opacity: ${({ disabled }: BBP) => (disabled ? `0.3` : `1`)};

    transition: opacity 0.1s ease-in;

    user-select: none;
  }
`;

export interface BBP {
  zIdx?: number;
  size?: ComponentSize;
  color?: string;
  background?: string;
  br?: string;
  noTransform?: boolean;
  noShadow?: boolean;
  fillWidth?: boolean;
  fontFamily?: string;
  border?: string;
  disabled?: boolean;
}
