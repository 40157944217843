import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { QBItemSelection } from '@/components/query-builder/models';
import { SelectOptions, SmallSelect } from '@/components/small-select';
import { QBCaption } from '@/components/query-builder/shared';

export function ItemSelection({
  current: { itemSelection },
  onSelection,
  hideItemSelection,
}: {
  hideItemSelection: boolean;
  onSelection: (value: string) => void;
  current: { cond: Array<QBItemSelection>; itemSelection: string };
}) {
  if (hideItemSelection) {
    return null;
  }
  return <IfSelection onSelection={onSelection} selected={itemSelection} />;
}

function IfSelection({
  onSelection,
  selected,
}: {
  onSelection: Dispatch<SetStateAction<string>>;
  selected: string;
}) {
  return (
    <Row>
      <For>For</For>
      <SmallSelect
        maxWidth={300}
        defaultValue={selected || `some_items`}
        onSelection={onSelection}
        options={
          [
            {
              key: `all_items`,
              value: `All Items in the cart`,
            },
            {
              key: `some_items`,
              value: `Some Items in the cart`,
            },
          ] as Array<SelectOptions>
        }
      />
    </Row>
  );
}

const Row = styled.div`
  margin: 21px 20px 0 0;
  display: flex;
`;

const For = styled(QBCaption)`
  && {
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;
