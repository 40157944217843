import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { EditorContext } from '@/features/editor/context/editor-context';
import { Theme } from '@/webapi/models';
import { Experience } from '@/webapi/use-experience-api';
import { isHydrogen } from '@/features/editor/widgets/shared/targeting-controls/themes-button';
import { AccountContext } from '@/features/account-context';

export function useThemesModal() {
  const accountCtx = useContext(AccountContext);
  const {
    currentExtension,
    resources: { themes, enabledOnMultipleThemes: enabledOnMultiple },
    experienceState: { currentExperience },
  } = useContext(EditorContext);

  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [activeTheme, setActiveTheme] = useState(
    getDefaultThemeName(themes, currentExperience),
  );

  const onClick = () => {
    setIsVisible(true);
  };

  useEffect(() => {
    if (!currentExperience.themeId) {
      setActiveTheme(`main`);
    } else {
      const activeTheme = themes.find(
        (t) => t.id === currentExperience.themeId,
      );
      if (activeTheme?.role === `main`) {
        setActiveTheme(`main`);
      } else if (activeTheme?.name) {
        setActiveTheme(activeTheme.name);
      }
    }
  }, [themes.length, currentExperience.themeId]);

  const enabledOnMultipleThemes = useMemo(
    () =>
      enabledOnMultiple && !isHydrogen(accountCtx) && currentExtension === ``,
    [enabledOnMultiple],
  );

  return {
    onClick,
    activeTheme,
    fromRef: ref,
    isVisible,
    setIsVisible,
    themes: sorted(themes),
    enabledOnMultipleThemes,
  };
}

function getDefaultThemeName(
  themes: Array<Theme>,
  currentExperience: Experience,
) {
  // eslint-disable-next-line eqeqeq
  const theme = themes?.find((t) => t.id == currentExperience.themeId);
  if (theme?.role === `main`) {
    return `main`;
  }
  return theme?.name || `main`;
}

function sorted(themes: Array<Theme>) {
  function customSort(a, b) {
    if (a.id === b.role) {
      return -1;
    }
    if (b.id === b.role) {
      return 1;
    }
    return new Date(a.updated_at).valueOf() - new Date(b.updated_at).valueOf(); // Sort by date for other elements
  }
  return themes.sort(customSort).reverse();
}
