import React, { useContext, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import { EditorDeclarativeBlock } from '@/webapi/use-experience-api';
import { EditorContext } from '@/features/editor/context/editor-context';
import { ContentLocation } from '@/features/editor/widgets/shared/location-picker/use-location-picker';
import { LocationPicker } from '@/features/editor/widgets/shared/location-picker';
import { AllMutationKind, MoveChange } from '@/pkg/sdk';

export interface MoveElementWidgetProps {
  originalChange: EditorDeclarativeBlock;
  isNewChange?: boolean;
}

export function MoveElementWidget({
  originalChange,
  isNewChange,
}: MoveElementWidgetProps) {
  const {
    cleanLastPickedElement,
    inspectorNav: { gotoChangelog },
    experienceState: { removeEditorChange, upsertEditorChange },
  } = useContext(EditorContext);

  const [location, setLocation] = useState<ContentLocation | undefined>(
    undefined,
  );

  const onLocationChanged = (selector: string, placement: AllMutationKind) => {
    setLocation({ selector, placement });
  };

  const onBack = () => {
    if (isNewChange) {
      removeEditorChange(originalChange);
    } else {
      upsertEditorChange(originalChange);
    }
    cleanLastPickedElement();
    gotoChangelog();
  };

  const onContinue = () => {
    setTimeout(() => {
      cleanLastPickedElement();
    }, 500);
    gotoChangelog();
  };

  const modifiedChange = useMemo(() => {
    if (location) {
      return produce(originalChange, (draft) => {
        (draft.block.value as MoveChange).destSelector = location.selector;
        draft.block.value[`htmlKind`] = location.placement;
      });
    }
    return originalChange;
  }, [location]);

  useEffect(() => {
    upsertEditorChange(modifiedChange);
  }, [modifiedChange]);

  return (
    <LocationPicker
      title="Choose a new position for your element"
      description="To move the selected section, navigate to the desired location on the left. Once you`ve found the spot, click the pink plus button to place it there."
      onContinue={onContinue}
      onBack={onBack}
      backCaption="< Back to changes"
      onLocationChanged={onLocationChanged}
    />
  );
}
