import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import produce from 'immer';
import { MdAdd } from 'react-icons/md';
import { StaticImage } from 'gatsby-plugin-image';
import { Card } from '@/components/card';
import { DefaultTypography } from '@/components/typography';
import { Flexbox } from '@/components/flex';
import { VSpace } from '@/components/spacing';
import { ExperienceVariant, YesNo } from '@/webapi/use-experience-api';
import {
  EditorContext,
  EditorContextProps,
} from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';
import { formatEnum } from '@/utils/types';
import { Tooltip } from '@/components/tooltip';
import { getAllowedPrimaryGoals } from '@/webapi/models';
import { GoalsModal } from '@/features/editor/widgets/shared/modals/goals-modal';
import { MAX_SELECTED_GOALS } from '@/features/editor/widgets/shared/modals/use-goals-modal';
import { CircularCheckmark } from '@/components/circular-checkmark';
import { GradientLayoutContext } from '@/components/gradient-layout';
import { AddVariantBtn } from '@/features/editor/widgets/targeting/multivariant';
import {
  AllocationText,
  AllocationWrapper,
  formatAllocation,
  useControlAllocation,
} from '@/features/editor/widgets/targeting/shared';
import { VariantName } from '@/features/editor/widgets/targeting/variant-name';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';

export interface AllocationCardProps {
  shouldScroll: boolean;
  hideGa?: boolean;
  hideGoals?: boolean;
}

export function AllocationCard({
  shouldScroll,
  hideGa,
  hideGoals,
}: AllocationCardProps) {
  const {
    experienceState,
    devicePreview,
    resources: { gaIntegrationEnabled },
  } = useContext(EditorContext);
  const { currentExperience, toggleGaEvent, setPrimaryGoal } = experienceState;
  const {
    editorState: { device },
  } = devicePreview;
  const { variants } = currentExperience;
  const fromRef = useRef(null);

  const [isGoalsVisible, setIsGoalsVisible] = useState(false);

  const onGoalsClick = () => {
    setIsGoalsVisible(true);
  };

  const { scrollToEnd } = useContext(GradientLayoutContext);

  useEffect(() => {
    if (shouldScroll) {
      scrollToEnd();
    }
  }, [shouldScroll]);

  useEffect(() => {
    const removedPrimaryGoal = !currentExperience.goals?.includes(
      currentExperience?.primaryGoal,
    );
    if (removedPrimaryGoal) {
      setPrimaryGoal(currentExperience?.goals?.[0]);
    }
    if (!currentExperience.primaryGoal) {
      setPrimaryGoal(currentExperience?.goals?.[0]);
    }
  }, [currentExperience.goals]);
  return (
    <>
      <GoalsModal
        fromRef={fromRef}
        isVisible={isGoalsVisible}
        setIsVisible={setIsGoalsVisible}
      />
      <Wrapper device={device}>
        <Header device={device} />
        <VSpace value={2} />
        <ControlGroup device={device} />
        {variants?.map((variant) => (
          <div key={variant.id}>
            <VSpace value={2} />
            <Variation device={device} variant={variant} />
          </div>
        ))}
        <AddVariantBtn />
        {!hideGoals && (
          <>
            <VSpace value={2} />
            <Flexbox overflow="scroll">
              <HeaderText device={device}>
                Set Goals
                <Tooltip
                  text="Choose which metrics you want to see in A/B validation reports"
                  title="Set Goals"
                />
              </HeaderText>
              <VSpace value={2} />
              <GoalsWrapper ref={fromRef} onClick={onGoalsClick}>
                {currentExperience.goals.map((item) => (
                  <GoalChip key={item} device={device} focused>
                    {formatEnum(item, true)}
                  </GoalChip>
                ))}
                {currentExperience.goals.length < MAX_SELECTED_GOALS && (
                  <GoalChipAddMore>
                    <MdAdd size={20} />
                  </GoalChipAddMore>
                )}
              </GoalsWrapper>
            </Flexbox>
            {currentExperience.goals.length > 1 && (
              <Flexbox overflow="scroll">
                <VSpace value={2} />
                <HeaderText device={device}>
                  Set Primary Goal
                  <Tooltip
                    text="Choose which metric you want to emphasize in A/B validation reports"
                    title="Set Primary Goal"
                  />
                </HeaderText>
                <VSpace value={2} />
                <GoalsWrapper>
                  {getAllowedPrimaryGoals(currentExperience.goals)?.map(
                    (item) => (
                      <PrimaryGoalChip
                        key={item}
                        device={device}
                        focused={item === currentExperience?.primaryGoal}
                        onClick={() => setPrimaryGoal(item)}
                      >
                        {formatEnum(item, true)}
                      </PrimaryGoalChip>
                    ),
                  )}
                </GoalsWrapper>
              </Flexbox>
            )}
          </>
        )}

        {gaIntegrationEnabled && !hideGa && (
          <>
            <VSpace value={3} />
            <CircularCheckmark
              selected={currentExperience.targeting.sendGAEvents === YesNo.YES}
              caption="Send events to google analytics"
              onChange={toggleGaEvent}
            />
          </>
        )}
      </Wrapper>
    </>
  );
}

function Header({ device }) {
  return (
    <Flexbox width="100%" direction="row" justify="space-between">
      <HeaderText device={device}>Set Variants</HeaderText>
      <HeaderText device={device} style={{ marginRight: `1.3rem` }}>
        Allocation
      </HeaderText>
    </Flexbox>
  );
}

export function ControlGroup({
  device,
  chances,
  bg,
}: {
  device: any;
  bg?: string;
  chances?: Array<number>;
}) {
  const controlAllocation = useControlAllocation(chances);
  return (
    <AllocationWrapper device={device} bg={bg}>
      <ControlText device={device}>Original</ControlText>
      <ControlInput
        readOnly
        device={device}
        value={controlAllocation}
        bg={bg}
      />
    </AllocationWrapper>
  );
}

type VariationProps = {
  variant: ExperienceVariant;
  device: DeviceType;
  _changeAllocation?: (variant: ExperienceVariant) => void;
  publishedChance?: number;
  _variants?: ExperienceVariant[];
  disableNameEdit?: boolean;
  bg?: string;
};

export function Variation({
  variant,
  device,
  _changeAllocation,
  publishedChance,
  _variants,
  disableNameEdit,
  bg,
}: VariationProps) {
  const edtCtx = useContext(EditorContext);
  const variants =
    _variants || edtCtx?.experienceState?.currentExperience?.variants;
  const changeAllocation =
    _changeAllocation || edtCtx?.experienceState?.changeAllocation;

  const onTextChanged = (ev) => {
    const maxChance = getMaxChance(variants, variant);
    let value = parseAllocation(ev.target.value);
    if (value > maxChance) {
      value = maxChance;
    } else if (value < 0) {
      value = 0;
    }
    const updated = produce(variant, (draft) => {
      draft.chance = value;
      if (typeof _changeAllocation !== `undefined`) {
        draft.publishedChance = value;
      }
    });
    ev.target.value = formatAllocation(value);
    changeAllocation(updated);
  };
  const isActive = isActiveVariant(edtCtx, variant);
  return (
    <AllocationWrapper device={device} isActive={isActive} bg={bg}>
      <VariantName
        device={device}
        defaultValue={variant.name}
        variantId={variant.id}
        _variants={_variants}
        isActive={isActive}
        bg={bg}
        disableNameEdit={disableNameEdit}
      />
      <OkIcon isActive={isActive} />
      <AllocationInput
        device={device}
        onFocus={(e) => e.target.select()}
        onChange={onTextChanged}
        defaultValue={formatAllocation(
          publishedChance || (variant.chance ? variant.chance : 0),
        )}
      />
    </AllocationWrapper>
  );
}

function parseAllocation(percent: string): number {
  try {
    return parseInt(percent.replaceAll(`%`, ``), 10);
  } catch (ignored) {
    return 0;
  }
}

const OkWrapper = styled.div`
  width: 20px;
  position: absolute;
  left: 99%;
  top: 78%;
`;

const Wrapper = styled(Card)`
  && {
    width: ${({ device }: { device: DeviceType }) =>
      device === DeviceType.Desktop ? `100%` : `100%`};
    padding: ${({ device }: { device: DeviceType }) =>
      device === DeviceType.Desktop ? `2rem` : `3rem`};

    cursor: default;

    :hover {
      background: white;
    }

    :active {
      background: white;
    }
  }
`;

const HeaderText = styled(DefaultTypography)`
  && {
    display: flex;
    color: black;
    font-size: ${({ device }: { device: DeviceType }) =>
      device === DeviceType.Desktop ? `1.3rem` : `1.3rem`};
    font-weight: 600;
  }
`;

const ControlText = styled(AllocationText)`
  && {
    font-weight: 600;
    color: #a6afb8;
  }
`;

const AllocationInput = styled.input`
  pointer-events: auto;
  outline: none;
  height: ${({ device }: { device: DeviceType }) =>
    device === DeviceType.Desktop ? `4.5rem` : `4.5rem`};
  width: ${({ device }: { device: DeviceType }) =>
    device === DeviceType.Desktop ? `7rem` : `7rem`};
  border: 1.5px solid #c7c8cf;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Inter, serif;
  font-weight: 600;
  color: #5b656e;
  font-size: ${({ device }: { device: DeviceType }) =>
    device === DeviceType.Desktop ? `1.5rem` : `1.5rem`};
`;

const ControlInput = styled(AllocationInput)`
  && {
    font-weight: 600;
    color: #b7c2c9;
    pointer-events: none;
    box-shadow: none;
    border: 1px solid ${(p) => (p.bg ? `#cdd3d9` : `transparent`)};
  }
`;

export const GoalsWrapper = styled.ul`
  && {
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;

    max-width: 56.8rem;
    list-style: none;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    && > *:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
`;

type P = { device: DeviceType; focused?: boolean };

export const GoalChip = styled.li`
  && {
    cursor: pointer;
    width: auto;
    opacity: ${({ focused }: P) => (focused ? `1` : `0.5`)};
    padding: 0.8rem 1.2rem;
    border: 1.5px solid #f2f4f6;
    border-radius: 1.8rem;
    user-select: none;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: Inter, serif;
    color: #58606c;
    background: #f2f4f6;
    transition: opacity 0.2s ease-out;

    :hover {
      opacity: 0.8;
    }

    :active {
      opacity: 0.6;
    }
  }
`;

export const PrimaryGoalChip = styled(GoalChip)`
  && {
    border: 1.5px solid ${({ focused }: P) => (focused ? `#0086FF` : `#f2f4f6`)};
    color: ${({ focused }: P) => (focused ? `#0086FF` : `#58606c`)};
  }
`;

const GoalChipAddMore = styled(GoalChip)`
  && {
    padding: 0;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3.3rem;
    min-width: 3.3rem;
    background: #e3e9ee;
    color: #adb6be;
    border-radius: 50%;
  }
`;

function isActiveVariant(
  edtCtx: EditorContextProps,
  variant: ExperienceVariant,
) {
  const isMultiVariantEnabled = useFeatureBit(FeatureBit.MULTIVARIANT);
  return (
    isMultiVariantEnabled &&
    edtCtx?.experienceState?.currentExperience?.activeVariantId ===
      variant.id &&
    edtCtx?.experienceState?.currentExperience?.variants?.length > 1
  );
}

function getMaxChance(
  variants: ExperienceVariant[],
  variant: ExperienceVariant,
) {
  if (variants.length > 1) {
    return 100;
  }
  return (
    100 -
    variants
      .filter((v) => v.id !== variant.id)
      .reduce((p, c) => p + c.chance, 0)
  );
}

function OkIcon({ isActive }: { isActive: boolean }) {
  return isActive ? (
    <OkWrapper>
      <StaticImage
        src="../../../../assets/approve-blue.svg"
        alt="edit"
        formats={[`webp`, `png`]}
        placeholder="none"
        loading="eager"
        height={20}
      />
    </OkWrapper>
  ) : null;
}
