import React, { useContext } from 'react';
import styled from 'styled-components';
import { StyledTemplateContext } from '@/features/editor/widgets/custom-widget/style-templating/context';

export function ModalHeader() {
  const {
    showOverrideModal,
    deleteStyleId,
    renameClicked,
    showUpdateStoreStyleModal,
  } = useContext(StyledTemplateContext);
  if (showUpdateStoreStyleModal) {
    return (
      <Header>
        Update Store Style
        <br />
        <SubTitle>Are you sure?</SubTitle>
      </Header>
    );
  }

  if (deleteStyleId) {
    return (
      <Header>
        Delete Style
        <br />
        <SubTitle>Are you sure?</SubTitle>
      </Header>
    );
  }
  if (showOverrideModal) {
    return (
      <Header>
        Duplicate or Override Style ?
        <br />
        <SubTitle>
          Click ‘Override’ to save changes or change the name and
          <br /> click ‘Duplicate’ to create a new style
        </SubTitle>
      </Header>
    );
  }
  return (
    <Header>
      {renameClicked ? `Rename Style?` : `Save Your Style?`}
      <br />
      <SubTitle>Name your style</SubTitle>
    </Header>
  );
}

const Header = styled.p`
  margin-top: -1em;
  font-size: 2.5rem;
  font-family: Inter, serif;
`;
const SubTitle = styled.div`
  margin-top: 2rem;
  font-size: 1.6rem;
  font-family: Inter, serif;
  color: #a9a1a1;
  font-weight: 400;
`;
