import Dexie, { Table } from 'dexie';
import { History, HistoryKeys } from '@/db/schemas';

export const CHANGES_HISTORY_TABLE = `changesHistory`;
export const CHANGES_HISTORY_KEYS_TABLE = `changesHistoryKeys`;
export const LAST_SAVED_EXPERIENCE_TABLE = `lastSavedExperience`;
export const EXPERIENCE_CACHE_TABLE = `experienceCache`;
export const ACTIVE_BLOCK_TABLE = `widgetCache`;
export const POST_PURCHASE_TABLE = `postPurchaseCache`;

// Notice that changing schemas/index require to upgrade the DB instead of just
// changing the data model
// https://dexie.org/docs/Version/Version.upgrade()
const visuallyDatabase = new Dexie(`visually-io-database`);
visuallyDatabase.version(1).stores({
  changesHistory: `key`,
  changesHistoryKeys: `key`,
  lastSavedExperience: `key`,
  experienceCache: `key`,
  widgetCache: `key`,
  postPurchaseCache: `key`,
});

export const changesHistoryTable = () =>
  visuallyDatabase.table(CHANGES_HISTORY_TABLE) as Table<History>;

export const changesHistoryKeysTable = () =>
  visuallyDatabase.table(CHANGES_HISTORY_KEYS_TABLE) as Table<HistoryKeys>;

export const getDatabaseTable = <T>(tableName: string) =>
  visuallyDatabase.table(tableName) as Table<T>;
