import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useCatalogApi } from '@/webapi/use-catalog-api';
import { Product } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/context/model';

export function useCatalogSearch(): UseCatalogSearch {
  const [query, setQuery] = useState<undefined | string>();
  const [searchText, setSearchText] = useState<undefined | string>();
  const { listProducts, listProductsByText } = useCatalogApi();

  const [products, setProducts] = useState<Array<Product>>([]);
  const [hasMore, setHasMore] = useState(false);
  const pageSize = 25;
  const [page, setPage] = useState(0);

  const debouncedSearch = useDebouncedCallback(setQuery, 500);

  useEffect(() => {
    debouncedSearch(searchText);
  }, [searchText]);

  useEffect(() => {
    (async () => {
      setProducts([]);
      const nextProducts = await listProductsByText(0, pageSize, {
        query,
      });
      if (nextProducts?.products) {
        setProducts(nextProducts.products);
        setHasMore(nextProducts.products.length >= pageSize);
        setPage(0);
      } else {
        setHasMore(false);
      }
    })();
  }, [query]);

  useEffect(() => {
    (async () => {
      const response = await listProducts(``);
      if (response?.products) {
        setProducts(response.products);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    })();
  }, []);

  async function loadMore() {
    const nextProducts = await listProductsByText(page + 1, pageSize, {
      query,
    });
    if (nextProducts.products) {
      setProducts(products.concat(nextProducts.products));
      setPage(page + 1);
    } else {
      setHasMore(false);
    }
  }

  return {
    hasMore,
    searchText,
    setSearchText: (x: string) => {
      setSearchText(x);
    },
    products,
    loadMore,
  };
}

export interface UseCatalogSearch {
  hasMore: boolean;
  searchText: string | undefined;
  setSearchText: (x: string) => void;
  products: Array<Product>;
  loadMore: () => void;
}
