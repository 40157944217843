import styled from 'styled-components';

interface P {
  mt?: string;
  size?: string;
  border?: string;
  relative?: boolean;
}

export const Spinner = styled.div<P>`
  ${({ relative }) => (relative ? `` : `position: absolute;`)}
  z-index: 9999;
  margin-top: ${({ mt }) => mt || `20px`};
  width: ${({ size }) => size || 50}px;
  height: ${({ size }) => size || 50}px;
  border: ${({ border }) => border || 3}px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: ${({ color }) => color || `grey`};
  animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
