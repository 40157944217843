import { CachePolicies, useFetch } from 'use-http';
import { useEffect, useState } from 'react';
import { routes } from '@/webapi/routes';

export type Subscription = {
  alias: string;
  status: string;
  createdAt: string;
  billingPeriodEnd: string;
  trialEnds: string;
  trialDays: number;
};

export function useShopifySubscriptionDetails(alias: string) {
  const { get, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: true,
    },
  }));
  const [s, setS] = useState<Subscription | undefined>();

  function getSubscription(alias: string) {
    return get(routes.getSubscription(alias));
  }

  useEffect(() => {
    if (alias)
      (async () => {
        const subscription: Subscription = await getSubscription(alias);
        setS(subscription);
      })();
  }, [alias]);

  return {
    loading,
    subscription: s,
  };
}
