import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { VSpace } from '@/components/spacing';
import { FacebookAudienceContext } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/context';

const Container = styled.div`
  margin: 2rem 2rem;
  font-family: 'JetBrains Mono', serif;
  span {
    font-size: 1.6rem;
  }
`;

export const FBConnect: FC = () => {
  const { reInit } = useContext(FacebookAudienceContext);
  useEffect(() => {
    const bc = new BroadcastChannel(`meta_auth`);
    bc.onmessage = reInit;
    return () => bc.close();
  }, []);
  return (
    <Container>
      <VSpace value={5} />
      <StaticImage
        src="./link.png"
        alt="connect"
        placeholder="none"
        loading="eager"
        height={120}
      />
      <VSpace value={7} />
      <span>
        To ensure proper synchronization with your Facebook campaigns, please
        connect your business account before proceeding.
      </span>
    </Container>
  );
};
