import * as React from 'react';
import styled from 'styled-components';
import { INSPECTOR_BACK_ID } from '@/features/editor/inspector';
import { BackButton } from '@/components/back-button';

export interface BackEditorButtonProps {
  caption: string;
  disabled?: boolean;
  onClick?: () => void;
}

export function BackEditorButton({
  caption,
  onClick,
  disabled,
}: BackEditorButtonProps) {
  return (
    <Wrapper>
      <BackButton
        id={INSPECTOR_BACK_ID}
        size="xs"
        color="#A1ABB9"
        disabled={disabled || false}
        onClick={onClick}
      >
        {caption || ``}
      </BackButton>
    </Wrapper>
  );
}

const Wrapper = styled.div``;
