import * as React from 'react';
import styled from 'styled-components';
import { useMemo, useRef } from 'react';
import { ImImage } from 'react-icons/im';
import { HiOutlinePencil } from 'react-icons/hi2';
import ReactTooltip from 'react-tooltip';
import {
  Experience,
  ExperienceInfoResponse,
} from '@/webapi/use-experience-api';
import { toPlainText } from '@/utils/rich-text-utils';
import { ExperienceInfoModal } from '@/features/shared/experience-info-modal';
import { ExperienceImagesModal } from '@/features/shared/experience-images-modal';
import Portal from '@/components/portal';
import { ExperienceDescriptionModal } from '@/features/shared/experience-description-modal';

export interface ExperienceInfoShortLineProps {
  currentExperience: Experience;
  nonePlaceholder?: any;
  onExperienceInfoChanged?: (info: ExperienceInfoResponse) => void;
  hideEditButton?: boolean;
  maxWidth?: string;
}

export function ExperienceInfoShortLine({
  currentExperience,
  nonePlaceholder,
  onExperienceInfoChanged,
  hideEditButton,
  maxWidth,
}: ExperienceInfoShortLineProps) {
  const infoRef = useRef(null);

  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);
  const [isImagesModalOpen, setIsImagesModalOpen] = React.useState(false);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] =
    React.useState(false);

  const showInfoModal = () => {
    setIsInfoModalOpen(true);
  };

  const hasDescription = useMemo(
    () => !!toPlainText(currentExperience?.description),
    [currentExperience],
  );

  const hasImages = useMemo(
    () =>
      currentExperience.beforeImages?.length > 0 ||
      currentExperience.afterImages?.length > 0,
    [currentExperience],
  );

  const plainTextDesc = useMemo(
    () => toPlainText(currentExperience.description),
    [currentExperience],
  );

  const richTextDesc = useMemo(() => {
    try {
      return decodeURIComponent(currentExperience.description);
    } catch (ex) {
      return currentExperience.description;
    }
  }, [currentExperience]);

  const onWrapperClicked = (ev) => {
    if (!hasDescription) {
      ev.stopPropagation();
      showInfoModal();
    }
  };

  const onEditClicked = (ev) => {
    ev.stopPropagation();
    showInfoModal();
  };

  const onImageClicked = (ev) => {
    ev.stopPropagation();
    setIsImagesModalOpen(true);
  };

  const onDescriptionClicked = (ev) => {
    if (hasDescription) {
      ev.stopPropagation();
      setIsDescriptionModalOpen(true);
    }
  };

  return (
    <>
      <Portal selector="body">
        {isImagesModalOpen && (
          <ExperienceImagesModal
            fromRef={infoRef}
            visible={isImagesModalOpen}
            setVisible={setIsImagesModalOpen}
            beforeImages={currentExperience.beforeImages}
            afterImages={currentExperience.afterImages}
          />
        )}
        {isInfoModalOpen && (
          <ExperienceInfoModal
            fromRef={infoRef}
            visible={isInfoModalOpen}
            setVisible={setIsInfoModalOpen}
            experienceId={currentExperience?.id}
            onDataChanged={onExperienceInfoChanged}
          />
        )}
        {isDescriptionModalOpen && (
          <ExperienceDescriptionModal
            fromRef={infoRef}
            visible={isDescriptionModalOpen}
            setVisible={setIsDescriptionModalOpen}
            description={currentExperience?.description}
          />
        )}
      </Portal>
      {hasDescription && (
        <Tooltip id={`desc-${currentExperience.id}`}>
          <span
            style={{ textAlign: `start` }}
            dangerouslySetInnerHTML={{
              __html: richTextDesc,
            }}
          />
        </Tooltip>
      )}
      {hasImages && (
        <Tooltip id={`img-${currentExperience.id}`}>
          Click to view images
        </Tooltip>
      )}
      <Tooltip id={`edit-${currentExperience.id}`}>
        Click to edit experience details
      </Tooltip>
      <Wrapper
        maxWidth={maxWidth}
        className="exp-short-line-wrapper"
        ref={infoRef}
        onClick={onWrapperClicked}
      >
        <Description
          data-tip
          data-for={`desc-${currentExperience.id}`}
          style={{ cursor: hasDescription ? `default` : `pointer` }}
          onClick={onDescriptionClicked}
        >
          {hasDescription ? plainTextDesc : nonePlaceholder}
        </Description>
        {hasImages && (
          <ImagesIcon
            data-tip
            data-for={`img-${currentExperience.id}`}
            onClick={onImageClicked}
          >
            <ImImage />
          </ImagesIcon>
        )}
        <EditIcon
          data-tip
          data-for={`edit-${currentExperience.id}`}
          onClick={onEditClicked}
        >
          {(hasImages || hasDescription) && (
            <HiOutlinePencil style={{ opacity: hideEditButton ? `0` : `1` }} />
          )}
        </EditIcon>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  max-width: ${(p) => p.maxWidth || `40rem`};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1.5rem;

  color: #93989b;
  font-size: 1.3rem;
  font-weight: 400;
`;

const Description = styled.span`
  cursor: context-menu;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  display: block;
  text-align: start;

  transition: color 0.2s ease;
  &&:hover {
    color: black;
  }
`;

const ImagesIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: zoom-in;
  font-size: 1.4rem;

  transition: color 0.2s ease;
  &&:hover {
    color: black;
  }
`;

const EditIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: context-menu;
  font-size: 1.4rem;

  transition: color 0.2s ease;
  &&:hover {
    color: black;
  }
`;

function Tooltip({ id, children }: { id: string; children?: any }) {
  return (
    <ReactTooltip
      id={id}
      place="bottom"
      backgroundColor="#fff"
      textColor="#000"
      className="exp-short-line-tooltip"
    >
      {children}
    </ReactTooltip>
  );
}
