import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ImageEditorContext } from '@/features/editor/widgets/custom-widget/inputs/background/image/shared/context';
import { GalleryPhoto, useMediaApi } from '@/webapi/use-media-api';
import { ImageEntry } from '@/features/editor/widgets/custom-widget/inputs/background/image/gallery/ImageEntry';

const pageSize = 10;

export function ImageEditorMyMedia({
  getModalRect,
}: {
  getModalRect: () => DOMRect;
}) {
  const { setImage } = useContext(ImageEditorContext);
  const { listGalleryPhotos } = useMediaApi();
  const [photos, setPhotos] = useState<GalleryPhoto[]>([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    (async () => {
      const resp = await listGalleryPhotos(page, pageSize);
      setPhotos(resp?.photos || []);
      setNoResults(!resp?.photos);
      const next = await listGalleryPhotos(page + 1, pageSize);
      if (!next?.photos) {
        setHasMore(false);
      }
    })();
  }, []);

  const onSelection = (p: GalleryPhoto) => {
    setImage(p.thumbnailLink);
  };

  const loadMore = async () => {
    setPage(page + 1);
    const nextPics = (await listGalleryPhotos(page, pageSize))?.photos || null;

    if (nextPics) {
      setPhotos(photos.concat(nextPics));
    } else {
      setHasMore(false);
    }
  };

  const ref = useRef(null);

  return (
    <Wrapper h={getModalRect().height} ref={ref}>
      <PhotosGrid id="scrollableMedia">
        {noResults && <h3>No results</h3>}
        <InfiniteScroll
          dataLength={photos?.length || 0}
          next={() => {
            loadMore().finally();
          }}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableMedia"
        >
          <PhotoGrid>
            {photos?.map((p) => (
              <ImageEntry
                key={p.id}
                onRemove={() =>
                  setPhotos(
                    photos.map((photo) =>
                      photo.id === p.id ? { ...photo, removed: true } : photo,
                    ),
                  )
                }
                fromRef={ref}
                onClick={() => {
                  onSelection(p);
                }}
                photo={p}
              />
            ))}
          </PhotoGrid>
        </InfiniteScroll>
      </PhotosGrid>
    </Wrapper>
  );
}

const PhotosGrid = styled.div`
  height: 100%;
  margin-top: 2rem;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;

  overflow: auto;
`;

interface P {
  h: number;
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: ${(p: P) => p.h - 150}px 5rem;
  justify-content: center;
  align-items: center;
`;

const PhotoGrid = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 200px;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
`;
