import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { IoIosClose, IoIosCheckmark } from 'react-icons/io';
import TextareaAutosize from 'react-textarea-autosize';
import { PopConfirm } from '@/components/pop-confirm';

export interface InlineTextEditProps {
  defaultValue?: string;
  onChange?: (value: string) => Promise<void>;
  confirmText?: string;
  noConfirm?: boolean;
  disableEdit?: boolean;

  color?: string;
  fontSize?: string;
  fontWeight?: string;
  fontFamily?: string;

  minLines?: number;
  maxLines?: number;

  nonEditPrefix?: string;
  nonEditSuffix?: string;

  resetOnEditWhenMatch?: string;
}

export function InlineTextEdit({
  defaultValue,
  onChange,
  disableEdit,
  color,
  fontSize,
  fontWeight,
  fontFamily,
  confirmText,
  noConfirm,
  minLines,
  maxLines,
  nonEditPrefix,
  nonEditSuffix,
  resetOnEditWhenMatch,
}: InlineTextEditProps) {
  const [initialValue, setInitialValue] = useState(defaultValue);
  const [value, setValue] = useState(defaultValue);
  const [inEditMode, setInEditMode] = useState(false);

  const inputRef = useRef(null);
  const popConfirmRef = useRef(null);

  const onInputChanged = (ev) => {
    setValue(ev.target.value);
  };

  const enterEditMode = () => {
    setInEditMode(true);
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current.focus();
        if (value === resetOnEditWhenMatch) {
          setValue(``);
        }
        inputRef.current.selectionStart = inputRef.current.value.length;
      }
    }, 10);
  };

  const leaveEditMode = (ev?: any) => {
    if (ev) {
      ev.stopPropagation();
    }
    setValue(initialValue);
    setInEditMode(false);
  };

  const onAcceptChange = async (_: any) => {
    onChange && (await onChange(value));
    setInEditMode(false);
    setInitialValue(value);
    return Promise.resolve();
  };

  const handleKeyDown = async (event) => {
    if (event.key === `Enter`) {
      event.preventDefault();
      if (noConfirm) {
        await onAcceptChange(event);
      } else if (popConfirmRef?.current?.click) {
        popConfirmRef.current.click();
      }
    }

    if (event.key === `Escape`) {
      event.preventDefault();
      leaveEditMode();
    }
  };

  return (
    <Wrapper
      className="inline-text-edit-wrapper"
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontFamily={fontFamily}
      onClick={enterEditMode}
      style={{
        pointerEvents: disableEdit ? `none` : `auto`,
      }}
    >
      {!inEditMode && (
        <span className="inline-text-edit-shared">
          {nonEditPrefix}
          {value}
          {nonEditSuffix}
        </span>
      )}
      {inEditMode && (
        <TextareaAutosize
          minRows={minLines || 1}
          maxRows={maxLines || 3}
          ref={inputRef}
          className="inline-text-edit-shared"
          placeholder={undefined}
          style={{
            width: `${Math.max(value.length + 1, 4)}ch`,
            pointerEvents: inEditMode ? `auto` : `none`,
          }}
          onKeyDown={handleKeyDown}
          value={value}
          onChange={onInputChanged}
        />
      )}
      {inEditMode && (
        <ControlsWrapper fontSize={fontSize}>
          {noConfirm && (
            <span className="accept" onClick={onAcceptChange}>
              <IoIosCheckmark size={fontSize || `1rem`} />
            </span>
          )}
          {!noConfirm && (
            <PopConfirm onConfirm={onAcceptChange} text={confirmText}>
              <span ref={popConfirmRef} className="accept">
                <IoIosCheckmark size={fontSize || `1rem`} />
              </span>
            </PopConfirm>
          )}

          <span className="discard" onClick={leaveEditMode}>
            <IoIosClose size={fontSize || `1rem`} />
          </span>
        </ControlsWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<InlineTextEditProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .inline-text-edit-shared {
    cursor: context-menu;
    outline: none;
    border: none;
    background: transparent;
    color: ${(p: InlineTextEditProps) => p.color || `inherit`};
    font-size: ${(p: InlineTextEditProps) => p.fontSize || `inherit`};
    font-weight: ${(p: InlineTextEditProps) => p.fontWeight || `inherit`};
    font-family: ${(p: InlineTextEditProps) => p.fontFamily || `inherit`};
    padding: 0;
    margin: 0;
    transition: width 0.2s ease;
    resize: none;
  }
`;

const ControlsWrapper = styled.div`
  height: 100%;
  width: auto;
  margin-left: 1rem;
  font-size: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;

  span {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    border-radius: 50%;
    height: ${(p: InlineTextEditProps) => p.fontSize || `1.5rem`};
    width: ${(p: InlineTextEditProps) => p.fontSize || `1.5rem`};

    transition: opacity 0.2s linear;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }

  span.accept {
    background: #2cd893;
  }

  span.discard {
    background: #cedcd6;
  }
`;
