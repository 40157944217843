import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import produce from 'immer';
import { DropdownInputOption } from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { DeviceType } from '@/utils/definitions';
import { centered, useSharedElement } from '@/components/use-shared-element';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';

let replaceVideoShown = false;
export function setIsVideoReplaceWasShown(val: boolean) {
  replaceVideoShown = val;
}

export const fitOptions: DropdownInputOption[] = [
  { label: `None`, value: `auto` },
  { label: `Contain`, value: `contain` },
  { label: `Cover`, value: `cover` },
];

export const posOptions: DropdownInputOption[] = [
  { label: `Center`, value: `center center` },
  { label: `Center Top`, value: `center top` },
  { label: `Center Bottom`, value: `center bottom` },
  { label: `Left Top`, value: `left top` },
  { label: `Left Center`, value: `left center` },
  { label: `Left Bottom`, value: `left bottom` },
  { label: `Right Top`, value: `right top` },
  { label: `Right Center`, value: `right center` },
  { label: `Right Bottom`, value: `right bottom` },
];

export const objectFitKey = `fit`;
export const positionKey = `position`;
export const posterKey = `posterImage`;
export const autoPlayKey = `autoPlay`;
export const controlsKey = `controls`;
export const muteKey = `muted`;
export const loopKey = `loop`;

export function getVideoPlaceholder(device: DeviceType): string {
  return device === DeviceType.Desktop
    ? `https://sdk.loomi-prod.xyz/widgets/video_d_placeholder.mp4`
    : `https://sdk.loomi-prod.xyz/widgets/video_m_placeholder.mp4`;
}

export interface VideoInputCtx {
  source: string;
  setSource: (str: string) => void;
  objectFit: string;
  setObjectFit: (str: string) => void;
  position: string;
  setPosition: (str: string) => void;
  poster: string;
  setPoster: (str: string) => void;
  autoPlay: boolean;
  setAutoPlay: (val: boolean) => void;
  controls: boolean;
  setControls: (val: boolean) => void;
  mute: boolean;
  setMute: (val: boolean) => void;
  loop: boolean;
  setLoop: (val: boolean) => void;
  videoModalProps: any;
  showVideoModal: () => void;
  hideVideoModal: () => void;
  posterModalProps: any;
  showPosterModal: () => void;
  hidePosterModal: () => void;
  posterImageRef: any;
}

export const VideoInputContext = React.createContext({} as VideoInputCtx);

export function newVideoInputContext(
  { device, initialValues, onValuesChanged, videoRef },
  backgroundMode: string,
): VideoInputCtx {
  const isActive = useMemo(() => backgroundMode === `video`, [backgroundMode]);
  const getVideoSource = () => {
    const val = initialValues(`value`, device) as string | undefined;
    if (!val || val?.startsWith(`url(`)) {
      return getVideoPlaceholder(device);
    }
    return val;
  };
  const [source, setSource] = useState(getVideoSource());
  const { undoRedoCount } = useContext(UndoRedoCounterContext);

  const getVideoProps = useCallback(
    () => initialValues(`video`, device) || {},
    [undoRedoCount, device],
  );
  const defaultObjectFit = getVideoProps()[objectFitKey] || fitOptions[2].value;
  const defaultPosition = getVideoProps()[positionKey] || posOptions[0].value;
  const defaultPoster = getVideoProps()[posterKey] || undefined;
  const defaultAutoPlay = getVideoProps()?.[autoPlayKey];
  const defaultControls = getVideoProps()?.[controlsKey];
  const defaultMute = getVideoProps()?.[muteKey];
  const defaultLoop = getVideoProps()?.[loopKey];

  const [objectFit, setObjectFit] = useState(defaultObjectFit);
  const [position, setPosition] = useState(defaultPosition);
  const [poster, setPoster] = useState(defaultPoster);

  const [autoPlay, setAutoPlay] = useState(fallback(defaultAutoPlay, true));
  const [controls, setControls] = useState(fallback(defaultControls, false));
  const [mute, setMute] = useState(fallback(defaultMute, true));
  const [loop, setLoop] = useState(fallback(defaultLoop, true));

  useEffect(() => {
    if (!replaceVideoShown && source.includes(`placeholder.mp4`)) {
      showVideoModal();
      setIsVideoReplaceWasShown(true);
    }
  }, [source, device]);

  useEffect(() => {
    const value = getVideoProps();
    const updated = produce(value, (draft) => {
      draft[objectFitKey] = objectFit;
      draft[positionKey] = position;
      draft[autoPlayKey] = autoPlay;
      draft[controlsKey] = controls;
      draft[muteKey] = mute;
      draft[loopKey] = loop;
      draft[posterKey] = poster;
    });

    if (isActive) {
      onValuesChanged(`video`, updated, device);
      onValuesChanged(`value`, source, device);
    }
  }, [
    source,
    objectFit,
    position,
    autoPlay,
    controls,
    mute,
    loop,
    poster,
    isActive,
    device,
  ]);

  useEffect(() => {
    if (isActive) {
      setAutoPlay(getVideoProps()?.[autoPlayKey]);
      setControls(getVideoProps()?.[controlsKey]);
      setMute(getVideoProps()?.[muteKey]);
      setLoop(getVideoProps()?.[loopKey]);
      setObjectFit(getVideoProps()?.[objectFitKey]);
      setPosition(getVideoProps()?.[positionKey]);
      setPoster(getVideoProps()?.[posterKey]);
    }
  }, [undoRedoCount]);

  const {
    props: videoModalProps,
    show: showVideoModal,
    hide: hideVideoModal,
  } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `#dedede`,
        opacity: `0`,
      },
      extraTo: {
        background: `white`,
        opacity: `1`,
      },
    },
    videoRef,
    () => centered(23, 42),
  );

  const {
    props: posterModalProps,
    show: showPosterModal,
    fromRef: posterImageRef,
    hide: hidePosterModal,
  } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `#dedede`,
        opacity: `0`,
      },
      extraTo: {
        background: `white`,
        opacity: `1`,
      },
    },
    undefined,
    () => centered(55, 80),
  );

  return {
    source,
    setSource,
    objectFit,
    setObjectFit,
    position,
    setPosition,
    poster,
    setPoster,
    autoPlay,
    setAutoPlay,
    controls,
    setControls,
    mute,
    setMute,
    loop,
    setLoop,
    videoModalProps,
    showVideoModal,
    hideVideoModal,
    posterModalProps,
    showPosterModal,
    hidePosterModal,
    posterImageRef,
  };
}

const fallback = <T>(...value: T[]): T | undefined =>
  value?.filter((v) => v !== undefined)?.[0];
