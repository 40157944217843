import * as React from 'react';
import { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { EditorContext } from '@/features/editor/context/editor-context';
import { ExperienceInfoResponse } from '@/webapi/use-experience-api';
import { AccountContext } from '@/features/account-context';
import { StoreStatsKind } from '@/webapi/use-store-stats';
import { ExperienceInfoShortLine } from '@/features/shared/experience-info-short-line';
import { VSpace } from '@/components/spacing';
import { DeviceType } from '@/utils/definitions';
import { LabelsStrip } from '@/features/shared/labels-strip';

export function DescriptionEditButton() {
  const { isStoreGoalReached } = useContext(AccountContext);
  const {
    experienceState: { currentExperience, setExperienceInfo },
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);

  const hasSavedOneExp = useMemo(
    () => isStoreGoalReached(StoreStatsKind.HAS_SAVED_ONE_EXPERIENCE),
    [currentExperience.description],
  );

  const placeholderText = useMemo(() => {
    if (!hasSavedOneExp) {
      return `Once done, decide how to allocate traffic for A/B testing`;
    }
    return `+ Add more info`;
  }, [currentExperience.description, hasSavedOneExp]);

  const onExperienceInfoChanged = (info: ExperienceInfoResponse) => {
    setExperienceInfo(info);
  };

  return (
    <Wrapper
      device={device}
      className={
        !hasSavedOneExp && !currentExperience?.description
          ? `disable-interactions`
          : ``
      }
    >
      <VSpace value={1.5} />
      <ExperienceInfoShortLine
        currentExperience={currentExperience}
        nonePlaceholder={placeholderText}
        onExperienceInfoChanged={onExperienceInfoChanged}
      />
      {currentExperience?.labels?.length > 0 && (
        <>
          <VSpace value={1.5} />
          <LabelsStrip labels={currentExperience.labels} />
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: ${(p) => (p.device === DeviceType.Desktop ? `100%` : `60%`)};

  &&.disable-interactions {
    .exp-short-line-wrapper {
      pointer-events: none;
    }
  }

  div[class*='labels-strip'] {
    font-weight: 400;
  }
`;
