import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { BigButton } from '@/components/big-button';

export const WelcomeModalHintText = styled.span`
  width: 100%;
  display: block;
  font-family: Inter, serif !important;
  font-size: 1.5rem !important;
  font-weight: normal !important;
  color: #5b656e !important;
  text-align: center !important;
  line-height: 1.8;
  padding: 0 3rem;
`;

export const RoundButton = styled(BigButton)`
  && {
    text-transform: none;
    border-radius: 3rem;
    font-size: 1.5rem;
    padding: 1.5rem 5rem;
  }
`;

export function AppEmbedInstructions() {
  return (
    <>
      <WelcomeModalHintText>
        To enable Visually.io, click <b>&apos;Activate&apos;</b>, and then click
        {` `}
        <b>&apos;Save&apos;</b> on the following screen in Shopify. The app
        toggle will be automatically set to &apos;on&apos;.
      </WelcomeModalHintText>
      <StaticImage
        alt="shopify app embed hint"
        src="./app_embed_hint.png"
        loading="eager"
        placeholder="none"
        objectFit="contain"
        width={800}
      />
    </>
  );
}
