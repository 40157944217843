import { useContext, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import { EditorContext } from '@/features/editor/context/editor-context';
import { ScheduleType } from '@/webapi/use-experience-api';
import { dateToUtcInSeconds, maybe } from '@/features/details/utils';
import {
  AccountContext,
  AccountContextProps,
} from '@/features/account-context';
import { TimeZones } from '@/features/editor/widgets/targeting/schedule-time-picker';

const DAY_IN_SECONDS = 86400;

export function useScheduleExperience({ type }: { type: ScheduleType }) {
  const { experienceState } = useContext(EditorContext);
  const account = useContext(AccountContext);
  const { currentExperience: experience, scheduleExperience } = experienceState;
  const { schedule } = experience;
  const [utcStart, setStartDate] = useState(
    schedule?.utcStart || dateToUtcInSeconds(),
  );
  const [utcEnd, setEndDate] = useState(
    schedule?.utcEnd || dateToUtcInSeconds() + DAY_IN_SECONDS,
  );
  const [timeZone, setTimeZone] = useState(
    schedule?.timeZone || getDefaultTZ(account),
  );
  const [weekDay, setWeekDay] = useState(schedule?.weekDay || `SUNDAY`);
  const [startHour, setStartHour] = useState(schedule?.startHour || 12);
  const [startMinute, setStartMinute] = useState(schedule?.startMinute || 0);
  const [endHour, setEndHour] = useState(schedule?.endHour || 20);
  const [endMinute, setEndMinute] = useState(schedule?.endMinute || 0);

  const startTime = useMemo(() => {
    const date = new Date();
    date.setHours(startHour);
    date.setMinutes(startMinute);
    return date;
  }, [startHour, startMinute]);

  const endTime = useMemo(() => {
    const date = new Date();
    date.setHours(endHour);
    date.setMinutes(endMinute);
    return date;
  }, [endHour, endMinute]);

  const onSelectScheduleTimes = () => {
    const updated = produce(experience, (draft) => {
      draft.schedule = {
        ...schedule,
        ...{
          type,
          timeZone,
          utcStart,
          utcEnd,
          weekDay,
          startHour,
          startMinute,
          startSecond: 0,
          endHour,
          endMinute,
          endSecond: 0,
        },
      };
    });
    scheduleExperience(updated.schedule);
  };

  useEffect(() => {
    if (utcStart >= utcEnd) {
      setEndDate(Number(utcStart) + DAY_IN_SECONDS);
    }
  }, [utcStart]);

  useEffect(() => {
    onSelectScheduleTimes();
  }, [utcStart, utcEnd, startTime, endTime, weekDay, timeZone, type]);

  function onStartTimeChange(date: Date): void {
    setStartHour(date.getHours());
    setStartMinute(date.getMinutes());
  }
  function onEndTimeChange(date: Date): void {
    setEndHour(date.getHours());
    setEndMinute(date.getMinutes());
  }

  return {
    utcStart,
    utcEnd,
    timeZone,
    weekDay,
    setStartDate,
    setEndDate,
    setTimeZone,
    setWeekDay,
    startTime,
    endTime,
    onStartTimeChange,
    onEndTimeChange,
  };
}

const getDefaultTZ = (account: AccountContextProps): any =>
  maybe(
    () =>
      TimeZones.find(
        (t) =>
          t.value.offset === getTimezoneOffset(account.account.store.timezone),
      ),
    TimeZones.find((t) => t.value.name === `America/New_York`),
  );
function getTimezoneOffset(str) {
  const match = str.match(/\(GMT([+-]\d{2}):(\d{2})\)/);
  if (!match) return null;

  const hours = parseInt(match[1], 10);
  const minutes = parseInt(match[2], 10);
  return hours + (minutes / 60) * Math.sign(hours);
}
