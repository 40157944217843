import React, { useContext, useRef, useState } from 'react';
import { TargetingButton } from '@/features/editor/widgets/shared/targeting-controls/targeting-button';
import { EditorContext } from '@/features/editor/context/editor-context';
import { Trigger, TriggerKind } from '@/webapi/use-experience-api';
import { truncate } from '@/utils/types';
import { TriggersModal } from '@/features/editor/widgets/shared/modals/trigger/triggers-modal';

export function TriggerButton({ isDisabled }: { isDisabled?: boolean }) {
  const {
    experienceState: { currentExperience },
  } = useContext(EditorContext);
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const onClick = () => {
    setIsVisible(true);
  };

  return (
    <>
      <TriggersModal
        fromRef={ref}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
      <TargetingButton
        wrapperRef={ref}
        title="Trigger"
        subtitle={`${formatTrigger(currentExperience.trigger)}`}
        onClick={onClick}
        isDisabled={isDisabled}
      />
    </>
  );
}

function formatTrigger(trigger: Trigger): string {
  if (trigger.kind === TriggerKind.PAGE_LOAD) {
    return `Page Load`;
  }
  if (trigger.kind === TriggerKind.EXIT_INTENT) {
    return `Exit Intent`;
  }
  if (trigger.kind === TriggerKind.ELEMENT_APPEARING) {
    return `When ${truncate(trigger.selector, 50)} appears`;
  }
  if (trigger.kind === TriggerKind.TIMEOUT) {
    return `After ${trigger.timeoutMillis / 1000} Seconds`;
  }
  if (trigger.kind === TriggerKind.INACTIVITY) {
    return `Inactivity ${trigger.timeoutMillis / 1000} Seconds`;
  }
  if (trigger.kind === TriggerKind.KLAVIO_EVENT) {
    return `Klaviyo Event`;
  }
  if (trigger.kind === TriggerKind.ELEMENT_CLICKED) {
    return `Clicking on ${truncate(trigger.selector, 50)}`;
  }
  if (trigger.kind === TriggerKind.ELEMENT_HOVER) {
    return `Hovering on ${truncate(trigger.selector, 50)}`;
  }
  if (trigger.kind === TriggerKind.JAVASCRIPT_FUNCTION) {
    return `Custom Code`;
  }

  return `Undefined`;
}
