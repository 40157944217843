import { useContext, useState } from 'react';
import { EditorContext } from '@/features/editor/context/editor-context';
import { TargetingDeviceType } from '@/webapi/use-experience-api';
import { centered } from '@/components/use-shared-element';
import { AccountContext } from '@/features/account-context';

export function useDevicesModal(setIsVisible: (value: boolean) => void) {
  const {
    account: {
      store: { alias },
    },
  } = useContext(AccountContext);
  const { experienceState } = useContext(EditorContext);

  const { currentExperience, setTargetingDevice } = experienceState;
  const { targeting } = currentExperience;

  const toRect = centered(50, 70);

  const [selected, setSelected] = useState(targeting.device);

  const onSelection = (value: boolean, device: TargetingDeviceType) => {
    if (!value) {
      setSelected(device);
    }
  };

  const onSave = () => {
    setTargetingDevice(selected);
    setIsVisible(false);
  };

  return {
    selected,
    onSelection,
    onSave,
    toRect,
    alias,
  };
}
