/* eslint-disable @typescript-eslint/ban-ts-comment,no-plusplus */
import React, { useEffect, useRef, useState } from 'react';
import { mutateFn, useComplexState } from '@/utils/use-complex-state';
import {
  CampaignList,
  FBAd,
  FBAdSet,
  FBCampaign,
  FBQuery,
} from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/models';
import { useFacebookApi } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/api';
import {
  clone,
  getFBQuery,
} from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/shared';
import { FacebookTargeting, isEmpty } from '@/components/query-builder/models';

export interface AdSearchCtx {
  state: AdSearchState;
  setState: (cb: (draft: AdSearchState) => void) => void;
  searchLoading: boolean;
  loadMoreCampaigns: () => Promise<any>;
  loadMoreAds: (
    next: string,
    adsetId: string,
    campaignId: string,
  ) => Promise<any>;
  loadMoreAdsets: (next: string, campaignId: string) => Promise<any>;
}

export const AdSearchContext = React.createContext({} as AdSearchCtx);

export interface AdSearchState {
  query: string;
  campaigns: FBCampaign[];
  nextCampaigns: string;
}

function getSelectedCampaignIds(state: AdSearchState) {
  return state.campaigns?.filter((x) => x.isSelected)?.map((x) => x.id) || [];
}

function getSelectedAdSetIds(state: AdSearchState) {
  return (
    state.campaigns?.flatMap((x) =>
      x.adSets?.filter((x) => x.isSelected)?.map((x) => x.id),
    ) || []
  );
}

function getSelectedAdIds(state: AdSearchState) {
  return (
    state.campaigns?.flatMap((x) =>
      x.adSets?.flatMap((x) =>
        (x.ads || [])?.filter((x) => x.isSelected)?.map((x) => x.id),
      ),
    ) || []
  );
}

function campaignsToKeep(state: AdSearchState) {
  return state.campaigns
    .map((c) => {
      if (
        c.isSelected ||
        !!c?.adSets?.find((ads) => ads.isSelected) ||
        !!c?.adSets?.find((ads) => !!ads.ads?.find((ad) => ad.isSelected))
      ) {
        return c.id;
      }
      return undefined;
    })
    .filter((x) => !!x);
}

function findCampaign(
  targeting: React.MutableRefObject<FacebookTargeting>,
  campaignId: string,
) {
  return targeting.current?.campaigns?.find((c) => c.id === campaignId);
}

function findAdSet(
  targeting: React.MutableRefObject<FacebookTargeting>,
  adSetId: string,
) {
  return targeting.current?.adsets?.find((c) => c.id === adSetId);
}

function newCampaignsNotSelectedByUser(
  campaignList: CampaignList,
  draft: AdSearchState,
) {
  return (
    campaignList.campaigns?.filter(
      (c) => !draft.campaigns.map((c) => c.id)?.includes(c.id),
    ) || []
  );
}

function isAdExcluded(
  targeting: React.MutableRefObject<FacebookTargeting>,
  ad: FBAd,
) {
  return targeting.current?.ads?.find((a) => a?.id === ad?.id)?.isExclude;
}

export function newAdSearchContext(
  targeting: React.MutableRefObject<FacebookTargeting>,
  onNotLoggedIn: () => void,
): AdSearchCtx {
  const [state, setState] = useComplexState({
    campaigns: [],
    query: ``,
  } as AdSearchState);
  const initialized = useRef(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const queryRef = useRef(``);
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    setSearchLoading(true);
    if (!initialized.current && !isEmpty(targeting.current)) {
      onInitialization(
        listCampaigns,
        targeting.current,
        setState,
        setSearchLoading,
        initialized,
        onNotLoggedIn,
      );
    } else {
      queryRef.current = state.query;
      timeoutRef.current = setTimeout(
        onUserSearch(
          timeoutRef,
          queryRef,
          state,
          listCampaigns,
          setState,
          setSearchLoading,
          onNotLoggedIn,
        ),
        700,
      );
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [state.query]);

  const { listCampaigns, moreCampaigns, moreAdSets, moreAds } =
    useFacebookApi();

  async function loadMoreAds(
    next: string,
    adsetId: string,
    campaignId: string,
  ) {
    if (next) {
      const additionalAds = await moreAds(next);
      if (additionalAds.ads.length > 0)
        setState((draft) => {
          const targetedCampaign = findCampaign(targeting, campaignId);
          const targetedAdSet = findAdSet(targeting, adsetId);
          if (!!targetedCampaign || !!targetedAdSet) {
            additionalAds.ads.forEach((ad) => {
              ad.isSelected =
                !isAdExcluded(targeting, ad) && !targetedAdSet?.isExclude;
            });
          }
          draft.campaigns?.forEach((c) => {
            if (c.id === campaignId) {
              c.adSets.forEach((ads) => {
                if (ads.id === adsetId) {
                  ads.ads.push(...additionalAds.ads);
                  ads.next = additionalAds.next;
                }
              });
            }
          });
        });
    }
  }

  async function loadMoreAdsets(next: string, campaignId: string) {
    if (next) {
      const additionalAdsets = await moreAdSets(next);
      if (additionalAdsets.adSets.length > 0)
        setState((draft) => {
          draft.campaigns?.forEach((c) => {
            if (c.id === campaignId) {
              if (findCampaign(targeting, campaignId)) {
                additionalAdsets.adSets.forEach((adset) => {
                  const adSetExcluded = findAdSet(
                    targeting,
                    adset.id,
                  )?.isExclude;
                  adset.isSelected = !adSetExcluded;
                  adset?.ads?.forEach((ad) => {
                    ad.isSelected =
                      !isAdExcluded(targeting, ad) && !adSetExcluded;
                  });
                });
              }
              c.adSets.push(...additionalAdsets.adSets);
              c.next = additionalAdsets.next;
            }
          });
        });
    }
  }

  async function loadMoreCampaigns() {
    if (state.nextCampaigns) {
      const campaignList = await moreCampaigns(state.nextCampaigns);
      if (campaignList.campaigns.length > 0)
        setState((draft) => {
          draft.campaigns.push(
            ...newCampaignsNotSelectedByUser(campaignList, draft),
          );
          draft.nextCampaigns = campaignList.next;
        });
    }
  }

  return {
    state,
    setState,
    searchLoading,
    loadMoreCampaigns,
    loadMoreAds,
    loadMoreAdsets,
  };
}

function onUserSearch(
  timeoutRef: React.MutableRefObject<NodeJS.Timeout | undefined>,
  queryRef: React.MutableRefObject<string>,
  state: AdSearchState,
  listCampaigns: (query: FBQuery) => Promise<CampaignList>,
  setState: (fn: mutateFn<AdSearchState>) => void,
  setSearchLoading: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void,
  onNotLoggedIn: () => void,
) {
  return async () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (
      queryRef.current === state.query &&
      (state.query === `` || state.query.length >= 3)
    ) {
      // user not typing, call search api
      const response = await listCampaigns({ value: state.query });
      if (response?.notLoggedIn) {
        onNotLoggedIn();
        return;
      }
      const emptySearch = queryRef.current === ``;
      campaignsToKeep(state).forEach((id) => {
        const missing = !response?.campaigns?.map((c) => c.id)?.includes(id);
        if (missing) {
          state.campaigns
            ?.filter((x) => x.id === id)
            ?.forEach((c) => response?.campaigns?.push(clone(c)));
        }
      });
      response?.campaigns?.forEach((campaign) => {
        campaign.isCollapsed = emptySearch;
        campaign.isSelected = getSelectedCampaignIds(state).includes(
          campaign.id,
        );
        campaign.adSets?.forEach((adSet) => {
          adSet.isSelected = getSelectedAdSetIds(state).includes(adSet.id);
          adSet.isCollapsed = emptySearch;
          adSet.ads?.forEach((ad) => {
            ad.isSelected = getSelectedAdIds(state).includes(ad.id);
          });
        });
      });

      setState((draft) => {
        draft.campaigns = response?.campaigns || [];
        draft.nextCampaigns = response?.next;
      });
    }
    setSearchLoading(false);
  };
}

function onInitialization(
  listCampaigns: (query: FBQuery) => Promise<CampaignList>,
  targeting: FacebookTargeting,
  setState: (fn: mutateFn<AdSearchState>) => void,
  setSearchLoading: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void,
  initialized: React.MutableRefObject<boolean>,
  onNotLoggedIn: () => void,
) {
  (async () => {
    const response = await listCampaigns(getFBQuery(targeting));
    if (response?.notLoggedIn) {
      onNotLoggedIn();
      return;
    }
    response?.campaigns?.forEach((campaign) => {
      campaign.isSelected = isCampaignInitiallySelected(targeting, campaign);
      campaign.isCollapsed = true;
      campaign.adSets?.forEach((adSet) => {
        adSet.isSelected = isAdSetInitiallySelected(campaign, targeting, adSet);
        adSet.isCollapsed = true;
        adSet.ads?.forEach((ad) => {
          ad.isSelected = isAdInitiallySelected(campaign, adSet, targeting, ad);
        });
      });
    });
    setState((draft) => {
      draft.campaigns = response?.campaigns || [];
      draft.nextCampaigns = response?.next;
    });
    setSearchLoading(false);
    initialized.current = true;
  })();
}

function isCampaignInitiallySelected(
  targeting: FacebookTargeting,
  campaign: FBCampaign,
) {
  return (
    !!targeting.campaigns.find((c) => c.id === campaign.id) ||
    !!targeting?.adsets?.find(
      (x) => x.campaign_id === campaign.id && !x?.isExclude,
    ) ||
    !!targeting?.ads?.find((x) => x.campaign_id === campaign.id && !x.isExclude)
  );
}

function isAdSetInitiallySelected(
  campaign: FBCampaign,
  targeting: FacebookTargeting,
  adSet: FBAdSet,
) {
  const targetedAdSet = targeting.adsets.find((ads) => ads.id === adSet.id);
  return (
    (campaign.isSelected ||
      !!targeting.ads.find((ads) => ads.adSet_id === adSet.id) ||
      !!targetedAdSet) &&
    !targetedAdSet?.isExclude
  );
}

function isAdInitiallySelected(
  campaign: FBCampaign,
  adSet: FBAdSet,
  targeting: FacebookTargeting,
  ad: FBAd,
) {
  const targetedAd = targeting.ads.find((a) => ad.id === a.id);
  const targetedAdSet = targeting.adsets.find((x) => x.id === adSet?.id);
  return (
    (campaign.isSelected || adSet.isSelected || !!targetedAd) &&
    !targetedAd?.isExclude &&
    !targetedAdSet?.isExclude
  );
}
