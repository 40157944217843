import { useEffect, useRef } from 'react';

const useAutoFocus = ({
  setAutoFocus,
  timeout,
}: {
  setAutoFocus: boolean;
  timeout?: number;
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (setAutoFocus) {
      setTimeout(() => ref?.current?.focus(), timeout || 1000);
    }
  }, [setAutoFocus]);

  return { ref };
};

export default useAutoFocus;
