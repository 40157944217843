import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import produce from 'immer';
import {
  ColorMode,
  GradientPoint,
  LinearGradientColor,
  RadialGradientColor,
} from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/models';
import { generateStyleStringFromColor } from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/utils';
import { BackgroundColorContext } from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/context';
import { ColorPadding } from '@/features/editor/widgets/custom-widget/inputs/background/color/components/commons';
import { ColorTextInput } from '@/features/editor/widgets/custom-widget/inputs/background/color/components/color-text-input';
import { getGUID } from '@/utils/browser';

export function GradientSlider() {
  const {
    mode,
    solidColor,
    linearColor,
    radialColor,
    updateSolidColor,
    updateLinearColor,
    updateRadialColor,
  } = useContext(BackgroundColorContext);
  const [width, setWidth] = useState(0);
  const [selectedPointIndex, setSelectedPointIndex] = useState(0);
  const [newPointLeft, setNewPointLeft] = useState(-1);
  const [newPointOpacity, setNewPointOpacity] = useState(0);
  const [draggingPicker, setDraggingPicker] = useState<number | undefined>(
    undefined,
  );

  const sliderRef = useRef<HTMLElement>(null);

  const currentGradient = useMemo(() => {
    if (mode === ColorMode.LINEAR) return linearColor;
    return radialColor;
  }, [mode, linearColor, radialColor]);

  const updateCurrentGradient = useCallback(
    (color: LinearGradientColor | RadialGradientColor) => {
      if (mode === ColorMode.LINEAR)
        updateLinearColor(color as LinearGradientColor);
      else updateRadialColor(color as RadialGradientColor);
    },
    [mode, linearColor, radialColor, solidColor],
  );

  const getBackground = useCallback(() => {
    if (mode === ColorMode.LINEAR) {
      return generateSliderBackground(linearColor);
    }
    return generateSliderBackground(radialColor);
  }, [linearColor, radialColor]);

  useEffect(() => {
    if (sliderRef.current) {
      setWidth(sliderRef.current.clientWidth);
    }
  }, [sliderRef, setWidth]);

  useEffect(() => {
    updateSolidColor(currentGradient.points[selectedPointIndex]);
  }, [selectedPointIndex]);

  useEffect(() => {
    const updated = produce(currentGradient, (draft) => {
      const { left } = draft.points[selectedPointIndex];
      draft.points[selectedPointIndex] = { left, ...solidColor };
      return draft;
    });
    updateCurrentGradient(updated);
  }, [solidColor]);

  const onMouseMove = (ev) => {
    if (draggingPicker) {
      setNewPointOpacity(0);
      const offset =
        ev.pageX - sliderRef.current.getBoundingClientRect().left - 10;
      const left = Math.round((offset / width) * 100);

      const updated = produce(currentGradient, (draft) => {
        draft.points[draggingPicker].left = left;
        return draft;
      });

      updateCurrentGradient(updated);
      return;
    }

    const elem = document.elementFromPoint(ev.pageX, ev.pageY);
    if (!elem || elem.getAttribute(`kind`)) {
      setNewPointOpacity(0);
    } else {
      const offset = ev.pageX - sliderRef.current.getBoundingClientRect().left;
      setNewPointLeft(offset);
      setNewPointOpacity(1);
    }
  };

  const onMouseDown = (ev) => {
    const elem = document.elementFromPoint(ev.pageX, ev.pageY);
    const pickerId = elem.getAttribute(`kind`);
    if (!elem || pickerId) {
      const idx = parseInt(pickerId, 10);
      setSelectedPointIndex(idx);
      setDraggingPicker(idx);
    } else {
      setDraggingPicker(undefined);
      const offset =
        ev.pageX - sliderRef.current.getBoundingClientRect().left - 10;
      const left = Math.round((offset / width) * 100);

      const updated = produce(currentGradient, (draft) => {
        draft.points.push({ left, ...solidColor });
        return draft;
      });
      setNewPointOpacity(0);
      updateCurrentGradient(updated);
      setSelectedPointIndex(updated.points.length - 1);
    }
  };

  const onMouseLeave = () => {
    setNewPointOpacity(0);
    setDraggingPicker(undefined);
  };

  const onMouseUp = () => {
    setDraggingPicker(undefined);
  };

  const onDoubleClick = (point: GradientPoint, idx: number) => {
    const updated = produce(currentGradient, (draft) => {
      draft.points.splice(idx, 1);
      return draft;
    });
    updateCurrentGradient(updated);
  };

  const onAngleChanged = (value: string) => {
    const updated = produce(currentGradient, (draft) => {
      let angle = parseInt(value, 10);
      angle = Number.isNaN(angle) ? 0 : angle;
      if (angle >= 360) {
        angle = 0;
      }
      (draft as LinearGradientColor).angle = angle;
      return draft;
    });
    updateCurrentGradient(updated);
  };

  return (
    <Wrapper mode={mode}>
      <Slider
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        ref={sliderRef}
        background={getBackground()}
      >
        {currentGradient.points.map((point, idx) => (
          <Picker
            onDoubleClick={() => onDoubleClick(point, idx)}
            kind={`${idx}`}
            key={getGUID()}
            selected={idx === selectedPointIndex}
            style={{
              left: `${Math.min(point.left * (width / 100), width - 18)}px`,
            }}
          />
        ))}
        <NewPointPicker
          key="hover"
          style={{
            left: `${Math.min(newPointLeft - 20, width - 18)}px`,
            opacity: newPointOpacity,
          }}
        >
          +
        </NewPointPicker>
      </Slider>
      {mode === ColorMode.LINEAR && (
        <ColorTextInput
          label="ANGLE"
          value={`${(currentGradient as LinearGradientColor).angle}`}
          onChange={onAngleChanged}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled(ColorPadding)`
  && {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: ${(props: { mode: ColorMode }) =>
      props.mode === ColorMode.LINEAR ? `1fr 4rem` : `1fr`};
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
    grid-gap: 4rem;
  }
`;

const Slider = styled.div`
  width: 100%;
  padding: 1.3rem 0;
  border-radius: 5px;
  background: ${(props: { background: string }) => props.background};
  position: relative;
`;

const Picker = styled.div`
  cursor: pointer;
  position: absolute;
  top: -0.4rem;
  height: calc(100% + 0.8rem);
  width: 1.8rem;
  background: transparent;
  border: 0.4rem solid #fff;
  border-radius: 8px;
  box-shadow: ${(props: { selected: boolean }) =>
    props.selected
      ? `0 1px 1px 0 rgba(255,160,0,0.86), 0 6px 14px 0 #FFAB00, 0 4px 14px 0 rgba(0,0,0,0)`
      : `0 1px 1px 0 rgba(0, 0, 0, 0.46),
    0 6px 14px 0 rgba(71, 111, 108, 0.69), 0 4px 14px 0 rgba(0, 0, 0, 0)`};
`;

const NewPointPicker = styled.div`
  cursor: pointer;
  position: absolute;
  top: -0.4rem;
  height: calc(100% + 0.8rem);
  width: 4rem;
  background: transparent;
  border: 0.4rem solid #fff;
  border-radius: 8px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.46),
    0 6px 14px 0 rgba(71, 111, 108, 0.69), 0 4px 14px 0 rgba(0, 0, 0, 0);
  transition: opacity 0.2s ease-out;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  color: white;
  pointer-events: none;
`;

function generateSliderBackground(
  color: LinearGradientColor | RadialGradientColor,
) {
  const back: LinearGradientColor = { points: [...color.points], angle: 90 };
  return generateStyleStringFromColor(back);
}
