/* eslint-disable no-plusplus */
import React, { useContext, useEffect, useState } from 'react';
import produce from 'immer';
import { useStyleTemplatingApi } from '@/features/editor/widgets/custom-widget/style-templating/useStyleTemplatingApi';
import {
  applyStyle,
  findInitialSelection,
  getCurrProps,
  newId,
  SelectOption,
  useIsSuperSystemUser,
} from '@/features/editor/widgets/custom-widget/style-templating/utils';
import {
  StoreStyles,
  StyleTemplate,
  StyleTemplateType as T,
} from '@/features/editor/widgets/custom-widget/style-templating/models';
import {
  CustomWidgetContext,
  CustomWidgetContextProps,
} from '@/features/editor/widgets/custom-widget/shared/context';
import { useDetachedState } from '@/components/hooks/use-detached-state';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';
import { CatalogApp } from '@/webapi/use-widget-catalog-api';

import {
  applyStoreStyle,
  defaultStyle,
  STORE_STYLE_ID,
} from '@/features/editor/widgets/custom-widget/shared/store-style-utils';
import { mutateFn } from '@/utils/use-complex-state';
import { useStoreStylesOverride } from '@/features/editor/widgets/custom-widget/style-templating/store-styles-override-form';

export interface StyleTemplateContext {
  selection: StyleTemplate;
  onSelection: (v: SelectOption) => void;
  loading: boolean;
  visible: boolean;
  hash: string;
  onSave: () => void;
  invalidName: boolean;
  renameClicked: boolean;
  showUpdateStoreStyleModal: boolean;
  storeStyleOverride: StoreStyles;
  resetStylesOverride: () => void;
  overrideStoreStyle: () => void;
  setStoreStyleOverride: (fn: mutateFn<StoreStyles>) => void;
  showOverrideModal: boolean;
  setVisible: (b: boolean) => void;
  options: Array<{ label: string; value: StyleTemplate }>;
  onRenameBtnClick: () => void;
  modalCtx: {
    name: string;
    setName: React.Dispatch<React.SetStateAction<string>>;
    isUnChanged: boolean;
    isDefault: boolean;
    onModalClose: () => void;
    onRenameClick: (saveForAll?: boolean) => void;
    onCancelClick: () => void;
    onOverrideClick: () => void;
    onDuplicateClick: () => void;
    isRenameDisabled: boolean;
  };
  deleteStyleId: string;
  rmStyle: (id: string) => Promise<void>;
  setDeleteStyleId: (n: string) => void;
  deleteStyleIdRef: React.MutableRefObject<string>;
}
export const StyledTemplateContext = React.createContext(
  {} as StyleTemplateContext,
);

export function newStyleTemplateWidgetContext(): StyleTemplateContext {
  const { saveStyles, loading, deleteStyle } = useStyleTemplatingApi();
  const widget = useContext(CustomWidgetContext);
  const {
    devicePreview: {
      editorState: { device },
    },
    resources: { appsCatalog, storeStyle },
  } = useContext(EditorContext);

  const [options, setOptions] = useState(
    getInitialStyles(widget, device, appsCatalog),
  );

  function updateSelection(
    selectOptions: SelectOption[],
    activeSelection: SelectOption,
    activeTemplateId: string,
  ) {
    let list = [...options, ...selectOptions];
    if (activeSelection) {
      const rest = list.filter((x) => x.value.id !== activeTemplateId);
      list = [activeSelection, ...rest];
      setSelection(activeSelection.value);
    }
    setOptions(uniq(list));
    setHash(newId());
  }

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [deleteStyleId, setDeleteStyleId, deleteStyleIdRef] =
    useDetachedState(``);
  useEffect(() => {
    if (deleteStyleId) {
      setIsModalVisible(true);
    }
  }, [deleteStyleId]);
  const [hash, setHash] = useState(`initial`);
  const initialState = findInitialSelection(options, widget);
  const [selection, setSelection] = useState<StyleTemplate>(initialState);
  useEffect(() => {
    if (
      !!widget.currentSchema.styleTemplateId &&
      widget.currentSchema.styleTemplateId !== selection.id
    ) {
      const nexStyle = options.find(
        (o) => o.value.id === widget.currentSchema.styleTemplateId,
      )?.value;
      nexStyle && setSelection(nexStyle);
    }
  }, [widget.currentSchema.styleTemplateId]);

  const currProps = getCurrProps(widget);

  const [saveClicked, setSaveClicked] = useState(false);
  const [renameClicked, setRenameClicked] = useState(false);

  const onSave = async () => {
    setSaveClicked(true);
    setIsModalVisible(true);
  };

  const onSelectSelection = (v: SelectOption) => {
    setSelection(v.value);
    widget.setStyleTemplate(
      applyStyle(widget.currentSchema.customizations, v.value),
      v.value.generalSettings,
      v.value.id,
    );
  };

  const onRenameBtnClick = () => {
    setRenameClicked(true);
    setIsModalVisible(true);
  };

  const { name, setName, isUnChanged, isDefault } = useNameChange(selection);

  const onModalClose = () => {
    setDeleteStyleId(``);
    setIsModalVisible(false);
  };
  async function update(style: StyleTemplate, saveForAll?: boolean) {
    if (isEmpty(style)) {
      return;
    }
    const updated = produce(style, (draft) => {
      draft.props.customizations = applyStyle(
        draft.props.customizations,
        style,
      );
      draft.isGlobal = !!saveForAll && isSuperSystem;
      if (widget.currentSchema?.settings?.general?.specs?.length > 0) {
        draft.generalSettings = widget.currentSchema.settings.general;
      }
    });
    if (isEmpty(updated)) {
      return;
    }
    const resp = await saveStyles({ ...updated });
    if (resp?.isDuplicateName) {
      setInvalidName(true);
      return;
    }
    widget.setStyleTemplate(
      applyStyle(widget.currentSchema.customizations, style),
      widget.currentSchema.settings.general,
      style.id,
    );
    updateSelection(options, asOption(updated), updated.id);
    setIsModalVisible(false);
  }

  const onRenameClick = async (saveForAll?: boolean) => {
    const shouldCloneGlobalTemplate = !!selection.isGlobal && !isSuperSystem;
    if (selection.type === T.CUSTOM && !shouldCloneGlobalTemplate) {
      await update({ ...selection, name }, saveForAll);
    } else {
      await update(
        {
          ...selection,
          id: newId(),
          type: T.CUSTOM,
          name,
          props: currProps,
        },
        saveForAll,
      );
    }
  };
  const onCancelClick = () => {
    setIsModalVisible(false);
  };

  const onOverrideClick = async () => {
    const saveForAll = !!selection.isGlobal && isSuperSystem;
    await update({ ...selection, name, props: currProps }, saveForAll);
  };
  const onDuplicateClick = async () => {
    const saveForAll = !!selection.isGlobal && isSuperSystem;
    await update(
      { ...selection, id: newId(), name, props: currProps },
      saveForAll,
    );
  };

  const isRenameDisabled = !name || isUnChanged;
  const modalCtx = {
    name,
    setName,
    isUnChanged,
    isDefault,
    onModalClose,
    onRenameClick,
    onCancelClick,
    onOverrideClick,
    onDuplicateClick,
    isRenameDisabled,
  };

  useEffect(() => {
    if (!isModalVisible) {
      setSaveClicked(false);
      setInvalidName(false);
      setRenameClicked(false);
      setDeleteStyleId(``);
    }
  }, [isModalVisible]);
  const isSuperSystem = useIsSuperSystemUser();
  const showOverrideModal =
    saveClicked &&
    selection.type === T.CUSTOM &&
    (!selection.isGlobal || (!!selection.isGlobal && isSuperSystem));

  const rmStyle = async (id: string) => {
    await deleteStyle(id);
    setOptions(options.filter((op) => op.value.id !== id));
    setHash(newId());
    onCancelClick();
  };

  useEffect(() => {
    const value = options.find(
      (o) => o.value.id === widget.currentSchema?.styleTemplateId,
    )?.value;
    if (value) {
      setSelection(value);
      setHash(newId());
    }
  }, [widget.currentSchema?.styleTemplateId]);

  const {
    showUpdateStoreStyleModal,
    storeStyleOverride,
    setStoreStyleOverride,
    resetStylesOverride,
    overrideStoreStyle,
  } = useStoreStylesOverride(deleteStyleId, storeStyle);

  const overrideStoreStyleAndChangeCurrentStyleTemplate = async () => {
    const updatedStoreStyle: StoreStyles = await overrideStoreStyle();
    const idx = options.findIndex((x) => x.value.id === STORE_STYLE_ID);
    options[idx].value.props.customizations = applyStoreStyle(
      options[idx].value.props.customizations,
      updatedStoreStyle,
    );
    setOptions(options);
    const { value } = options[idx];
    setSelection(value);
    widget.setStyleTemplate(
      applyStyle(widget.currentSchema.customizations, value),
      widget.currentSchema?.settings?.general,
      value.id,
    );
  };

  return {
    overrideStoreStyle: overrideStoreStyleAndChangeCurrentStyleTemplate,
    resetStylesOverride,
    storeStyleOverride,
    setStoreStyleOverride,
    showUpdateStoreStyleModal,
    renameClicked,
    rmStyle,
    deleteStyleId,
    setDeleteStyleId,
    deleteStyleIdRef,
    showOverrideModal,
    invalidName,
    hash,
    visible: isModalVisible,
    onSave,
    selection,
    loading,
    onSelection: onSelectSelection,
    setVisible: setIsModalVisible,
    options,
    onRenameBtnClick,
    modalCtx,
  };
}

function uniq(list: Array<{ label: string; value: StyleTemplate }>) {
  return list.filter((l, idx, all) => {
    for (let i = 0; i < idx; i++) {
      if (all[i].value.id === l.value.id) {
        return false;
      }
    }
    return true;
  });
}

function useNameChange(selection: StyleTemplate) {
  const [name, setName] = useState(selection.name);

  useEffect(() => {
    setName(selection.name);
  }, [selection.name]);

  const isUnChanged = name === selection.name;
  const isDefault = name === `Default`;
  return { name, setName, isUnChanged, isDefault };
}

function getInitialStyles(
  widget: CustomWidgetContextProps,
  device: DeviceType,
  appsCatalog: CatalogApp[],
) {
  return [
    {
      label: `Default`,
      value: defaultStyle(
        widget.currentSchema.appId,
        widget.currentSchema.blueprintName,
        device,
        appsCatalog,
      ),
    },
    ...(widget?.styleTemplates || []).map((o) => ({ label: o.name, value: o })),
  ];
}

function asOption(style: StyleTemplate) {
  return { label: style.name, value: style };
}

function isEmpty(style: StyleTemplate) {
  return (
    !style?.props?.customizations || style?.props?.customizations?.length === 0
  );
}
