/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';
import { BigButton } from '@/components/big-button';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';
import { VSpace } from '@/components/spacing';
import { Flexbox } from '@/components/flex';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import { DropdownInput } from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { InputsDarkTheme } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import {
  MaybeFiltered,
  Section,
  SectionTitle,
} from '@/features/editor/widgets/custom-widget/shared/shared-styles';
import { LoadingSection } from '@/features/editor/widgets/custom-widget/loading-section';

import { extractQuickEditItems } from '@/features/editor/widgets/custom-widget/shared/quick-edit-helper';
import { QuickEditItem } from '@/features/editor/widgets/custom-widget/inputs/shared/quick-edit-item';
import { filterRecommendations } from '@/features/editor/widgets/custom-widget/loading-section/recs-per-placement';
import { INSPECTOR_BACK_ID } from '@/features/editor/inspector';
import { INPUT_BACKGROUND_CLASS } from '@/features/editor/widgets/custom-widget/inputs/background';
import { INPUT_LINK_CLASS } from '@/features/editor/widgets/custom-widget/inputs/shared/link';
import { CARD_WRAPPER_CONTENT } from '@/features/editor/widgets/custom-widget/inputs/shared/card-wrapper';
import { getRecStrategy, Spec } from '@/webapi/use-widget-catalog-api';
import { ReviewsModal } from '@/features/integrations/reviews-modal';
import { UndoRedoBtns } from '@/components/undo-redo-btns';
import { GlossyWrapper } from '@/components/glossy-wrapper';
import {
  newUndoRedoContext,
  UndoRedoCounterContext,
} from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import {
  BlueprintOnboarding,
  useBlueprintOnboarding,
} from '@/features/editor/widgets/custom-widget/onboard';
import { QuickEditGroups } from '@/features/editor/widgets/custom-widget/shared/quick-edit-groups';
import { currencyValue } from '@/utils/currencies';
import { CustomizeDesignBtn } from '@/features/editor/widgets/custom-widget/style-templating/customize-design-btn';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';

export function BlueprintCustomize({ updateFlow }: { updateFlow?: boolean }) {
  const {
    devicePreview: {
      leaveAnchor,
      editorState: { device },
    },
    experienceState: { upsertEditorChange, currentExperience },
    inspectorNav: { gotoChangelog },
    transpiler: { asWidgetChange },
    resources: { reviews },
    revertTempChange,
  } = useContext(EditorContext);

  const [isReviewsVisible, setIsReviewsVisible] = useState(false);

  const {
    gotoStyle,
    newChange,
    currentWidget,
    evaluateRule,
    setGeneralCustomization,
    currentSchema,
    updateLoadingCfg,
    gotoSelect,
    undo,
    redo,
    canUndo,
    canRedo,
    undoRedoCount,
    styleTemplateChange,
  } = useContext(CustomWidgetContext);

  const {
    settings: { general, loading },
  } = currentSchema;
  const isNewRecAlgsEnabled = useFeatureBit(FeatureBit.NEW_REC_ALGS);
  const recStrategies = filterRecommendations(
    currentExperience?.quickPreviewPlacement,
    loading,
    isNewRecAlgsEnabled,
  );

  const hasQuickEditGroups =
    currentSchema?.settings?.quickEditGroups !== undefined;

  const quickEdit = useMemo(
    () => Object.entries(extractQuickEditItems(currentSchema)),
    [currentSchema],
  );

  const onEditStyle = () => {
    gotoStyle(currentWidget);
  };

  function validateSchema() {
    let hasValidImage = true;
    currentSchema.customizations.forEach((cust) => {
      cust.components.forEach((comp) => {
        comp.specs.forEach((spec) => {
          const isImage = spec?.values?.[`mode`] === `image`;
          const value = spec?.values?.[`value`];
          const isBlob = value?.includes ? value?.includes(`blob`) : false;
          if (isImage && isBlob) {
            hasValidImage = false;
            toast(
              `There is a problem with your image, please re-upload it and try again`,
              {
                theme: `colored`,
                type: `error`,
                className: css({
                  fontFamily: `JetBrains Mono, Serif`,
                  fontWight: `700`,
                }),
              },
            );
          }
        });
      });
    });
    return hasValidImage;
  }

  const onSave = async () => {
    const isValid = validateSchema();

    if (isValid) {
      const finalChange = asWidgetChange(
        newChange,
        currentWidget,
        currentSchema,
      );
      upsertEditorChange(finalChange);
      leaveAnchor();
      gotoChangelog();
    }
  };

  const onBack = () => {
    if (updateFlow) {
      gotoChangelog();
    } else {
      revertTempChange();
      gotoSelect();
    }
  };

  useEffect(() => {
    if (currentSchema?.settings?.loading?.value) {
      updateLoadingCfg(currentSchema?.settings?.loading?.value);
    }
  }, []);

  const getGeneralValue = useCallback(
    (specIdx: number) => {
      const spec = general?.specs?.[specIdx];

      const val =
        device === DeviceType.Mobile
          ? spec.value
          : spec.value?.desktop || spec.value;
      if (
        spec.key === `currency` &&
        val?.value === `default free shipping goal`
      ) {
        const options = spec.metadata.options as Array<{
          value: string;
          label: string;
        }>;
        return {
          val:
            options.find((o) => o.value === currencyValue()) ||
            ({
              value: `USD`,
              label: `Goal Currency - $ USD`,
            } as any),
          isDefaultOverride: true,
        };
      }
      return { val, isDefaultOverride: false };
    },
    [device, undoRedoCount, styleTemplateChange],
  );

  const wrapperRef = useRef(null);
  const onGeneralSettingsClicked = (ev: any, spec: Spec) => {
    if (spec.key === `reviewsPlacement` && !reviews.reviewsEnabled) {
      ev.stopPropagation();
      ev.preventDefault();
      setIsReviewsVisible(true);
    }
  };

  const hiddenSpecs = useMemo(() => {
    if (!evaluateRule) return [];
    return general?.specs
      ?.filter((spec) => evaluateRule(spec?.hideWhen))
      ?.map((spec) => spec.key);
  }, [general?.specs, device]);

  const isDefault = getRecStrategy(currentSchema)?.default;
  const undoRedoContext = newUndoRedoContext(undoRedoCount);

  const onboardStep2Ref = useRef(undefined);
  const onboardStep3Ref = useRef(undefined);
  const { shouldRunOnboarding } = useBlueprintOnboarding(newChange.editorId);

  return (
    <UndoRedoCounterContext.Provider value={undoRedoContext}>
      <Wrapper ref={wrapperRef} device={device}>
        <ReviewsModal
          fromRef={wrapperRef}
          isVisible={isReviewsVisible}
          setIsVisible={setIsReviewsVisible}
        />
        <InspectorWidgetLayout
          progress={80}
          progressWidthPercentMobile={55}
          title={`3. All great, let's customize`}
          backCaption={
            updateFlow ? `< Back to experience` : `< Back to selection`
          }
          onBackClicked={onBack}
          footer={
            <FooterSection>
              <UndoRedoBtns
                redo={redo}
                undo={undo}
                canUndo={canUndo}
                canRedo={canRedo}
              />
              <BigButton
                disabled={isDefault}
                color="white"
                background="black"
                noTransform
                fillWidth
                size={device === DeviceType.Desktop ? `medium-thin` : `medium`}
                onClick={onSave}
              >
                {updateFlow ? `Update` : `Save`}
              </BigButton>
            </FooterSection>
          }
        >
          <CustomizeSection direction="column">
            {!!general && (
              <>
                <Group>
                  {shouldRunOnboarding && (
                    <BlueprintOnboarding
                      zIndex={10}
                      maxHeight="30rem"
                      delay={100}
                      title="General Customization"
                      hint="In this section, you can modify the general settings"
                    />
                  )}
                  <SectionTitle>General Customization</SectionTitle>
                  <Section>
                    {general.specs.map((spec, specIdx) => {
                      const visible =
                        !spec.hidden && !hiddenSpecs?.includes(spec.key);
                      const { val: generalValue, isDefaultOverride } =
                        getGeneralValue(specIdx);
                      if (isDefaultOverride) {
                        setTimeout(() => {
                          setGeneralCustomization(specIdx, generalValue);
                        }, 100);
                      }
                      return (
                        <MaybeFiltered
                          isVisible={visible}
                          onClick={(ev) => onGeneralSettingsClicked(ev, spec)}
                        >
                          <DropdownInput
                            key={`${spec.key}-${device}-${undoRedoCount}-${styleTemplateChange}`}
                            theme={InputsDarkTheme}
                            options={spec.metadata[`options`]}
                            defaultValue={generalValue}
                            onChange={(val) =>
                              setGeneralCustomization(specIdx, val)
                            }
                            optionStyles={{ textTransform: `uppercase` }}
                          />
                        </MaybeFiltered>
                      );
                    })}
                  </Section>
                </Group>
                <VSpace value={3} />
              </>
            )}

            <Group ref={onboardStep2Ref}>
              {shouldRunOnboarding && (
                <BlueprintOnboarding
                  maxHeight="20rem"
                  zIndex={9}
                  delay={general ? 3000 : 100}
                  title="Quick Edit"
                  hint="In this section, you can modify the look & feel"
                />
              )}
              <SectionTitle>Quick Edit</SectionTitle>
              <StylingSection>
                <QuickEditSection key={`qe${styleTemplateChange}`}>
                  {quickEdit.map(([k, v]) => (
                    <QuickEditItem key={k} quickEdit={v} device={device} />
                  ))}
                  {hasQuickEditGroups && (
                    <QuickEditGroups
                      groups={currentSchema?.settings?.quickEditGroups}
                    />
                  )}
                </QuickEditSection>
              </StylingSection>
              <CustomizeDesignBtn onClick={onEditStyle} />
            </Group>

            {!!recStrategies?.options && (
              <>
                <VSpace value={3} />
                <Group ref={onboardStep3Ref}>
                  {shouldRunOnboarding && (
                    <BlueprintOnboarding
                      zIndex={8}
                      maxHeight="15rem"
                      delay={general ? 6000 : 3000}
                      title="Which products do you want to add?"
                      hint="In this section, you can choose which products do you want to show"
                    />
                  )}
                  <LoadingSection
                    recStrategies={recStrategies}
                    device={device}
                  />
                </Group>
              </>
            )}
            <VSpace value={3} />
          </CustomizeSection>
        </InspectorWidgetLayout>
      </Wrapper>
    </UndoRedoCounterContext.Provider>
  );
}

export const Group = styled.section`
  width: 100%;
  position: relative;
  overflow-y: visible;
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: ${(props: { device: DeviceType }) =>
    props.device === DeviceType.Desktop ? `100%` : `65%`};

  display: flex;
  flex-direction: column;
`;

export const CustomizeSection = styled(Flexbox)`
  && {
    overflow-y: scroll;
    scrollbar-width: none;

    #${INSPECTOR_BACK_ID} {
      z-index: 31;
    }
  }
`;

const FooterSection = styled(GlossyWrapper)`
  && {
    display: grid;
    grid-template-columns: 0.5fr 2fr;

    grid-gap: 2rem;
    z-index: 50;
    margin-left: -2rem;
    margin-bottom: 2rem;
  }
`;

export const StylingSection = styled(Section)`
  && {
    padding: 1rem 2rem 2rem 2rem;
    background: #f3f6f7;
    border-radius: 10px;

    display: flex;
    align-items: center;
  }
`;

export const QuickEditSection = styled(Section)`
  && {
    && > :not(:last-child) {
      margin-bottom: 2rem !important;
    }

    .${INPUT_BACKGROUND_CLASS} {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .${CARD_WRAPPER_CONTENT} {
        display: none;
      }
    }

    .${INPUT_BACKGROUND_CLASS} + .${INPUT_LINK_CLASS} {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-top: 0;
    }
  }
`;
