import React, { useState } from 'react';
import {
  DateParam,
  QueryParamConfig,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params';
import { DateRange } from '@/features/dashboard/reports/models';
import { isBrowser } from '@/utils/browser';

const LOCAL_STORAGE_DATE_RANGE_KEY = `vsly_reporting_date_range`;
const LOCAL_STORAGE_START_DATE_KEY = `vsly_reporting_start_date`;
const LOCAL_STORAGE_END_DATE_KEY = `vsly_reporting_end_date`;
export const DateRangeParam = withDefault(
  StringParam,
  defaultDateRange(),
) as QueryParamConfig<string, DateRange>;
export const StartDateParameter = withDefault(DateParam, defaultStartDate());
export const EndDateParameter = withDefault(DateParam, defaultEndDate());

export function newDatePickerCtx(): DatePickerContext {
  const [dateRange, setDateRange] = useQueryParam(`rn`, DateRangeParam);
  const [startDate, setStartDate] = useQueryParam(`s`, StartDateParameter);
  const [endDate, setEndDate] = useQueryParam(`e`, EndDateParameter);
  const [ranges, setRanges] = useState([
    {
      startDate,
      endDate,
      key: `rollup`,
    },
  ]);
  const persistStartDate = (x: Date) => {
    setStartDate(x);
    localStorage?.setItem(LOCAL_STORAGE_END_DATE_KEY, x.toISOString());
  };
  const persistEndDate = (x: Date) => {
    setEndDate(x);
    localStorage?.setItem(LOCAL_STORAGE_END_DATE_KEY, x.toISOString());
  };
  const persistDateRange = (x: DateRange) => {
    setDateRange(x);
    localStorage?.setItem(LOCAL_STORAGE_DATE_RANGE_KEY, x);
  };

  return {
    dateRange: dateRange as DateRange,
    setDateRange: persistDateRange,
    startDate,
    endDate,
    setStartDate: persistStartDate,
    setEndDate: persistEndDate,
    ranges,
    setRanges,
  };
}

function defaultDateRange(): DateRange {
  if (!isBrowser()) {
    return DateRange.LAST_30_DAYS;
  }
  return (window?.localStorage?.getItem(LOCAL_STORAGE_DATE_RANGE_KEY) ||
    DateRange.LAST_30_DAYS) as DateRange;
}

function defaultStartDate() {
  if (!isBrowser()) {
    return new Date();
  }
  const lsStartDate = window?.localStorage?.getItem(
    LOCAL_STORAGE_START_DATE_KEY,
  );
  return lsStartDate ? new Date(lsStartDate) : new Date();
}

function defaultEndDate() {
  if (!isBrowser()) {
    return new Date();
  }
  const lsStartDate = window?.localStorage?.getItem(LOCAL_STORAGE_END_DATE_KEY);
  return lsStartDate ? new Date(lsStartDate) : new Date();
}

export interface DatePickerContext {
  dateRange: DateRange;
  setDateRange: (x: DateRange) => any;
  startDate: Date;
  endDate: Date;
  setStartDate: (x: Date) => any;
  setEndDate: (x: Date) => any;
  ranges: { startDate: Date; endDate: Date; key: string }[];
  setRanges: React.Dispatch<
    React.SetStateAction<{ startDate: Date; endDate: Date; key: string }[]>
  >;
}
export const DataPickerContext = React.createContext({} as DatePickerContext);
