import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';
import Portal from '@/components/portal';
import { awaitCondition } from '@/utils/sync';
import { routes } from '@/webapi/routes';
import { DemoVideoLegend } from '@/features/new-landing-page/components/demo-video-legend';
import { breakpoints } from '@/components/responsive';

export interface VideoPlayerOverlayProps {
  seek?: number;
  onBackdropClicked?: () => void;
}

export function VideoPlayerOverlay({
  seek,
  onBackdropClicked,
}: VideoPlayerOverlayProps) {
  const ref = useRef<HTMLIFrameElement | null>(null);
  const [videoElem, setVideoElem] = useState<HTMLVideoElement | undefined>();

  useEffect(() => {
    awaitCondition(
      () => !!ref?.current?.contentDocument?.querySelector(`video`),
      500,
      20,
    ).then(() => {
      const video = ref?.current?.contentDocument?.querySelector(`video`);
      if (video) {
        setVideoElem(video);
        if (seek) {
          video.volume = 0.5;
          video.currentTime = seek;
          video.play();
        }
      }
    });
  }, [ref]);

  const onWrapperClick = (ev) => {
    ev.stopPropagation();
  };

  return (
    <Portal selector="body">
      <Backdrop onClick={onBackdropClicked}>
        <IoMdClose className="close" onClick={onBackdropClicked} />
        <Wrapper onClick={onWrapperClick}>
          <iframe
            ref={ref}
            id="demo-video-iframe"
            title="Visually Product Demo"
            src={routes.cleanQuickPreview(
              `https://iframe.mediadelivery.net/embed/169819/a05f5e18-0be2-4225-96bb-789b36dd47b2?autoplay=false&loop=false&muted=false&preload=true`,
            )}
            loading="lazy"
            allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
          />
          <LegendWrapper>
            <DemoVideoLegend videoElem={videoElem} />
          </LegendWrapper>
        </Wrapper>
      </Backdrop>
    </Portal>
  );
}

const Backdrop = styled.div`
  height: 100vh;
  width: 100vw;
  inset: 0;
  position: fixed;
  display: flex;
  background-color: rgba(200, 200, 200, 0.4);
  backdrop-filter: blur(14px);
  justify-content: center;
  align-items: center;
  z-index: 10000000000;
  cursor: pointer;

  .close {
    position: absolute;
    top: 2.5rem;
    right: 3.5rem;
    font-size: 3.5rem;
    color: rgb(105, 117, 126);
    transition: transform 0.1s linear, color 0.1s linear;
  }

  .close:hover {
    color: black;
    transform: scale(1.1);
  }

  .close:active {
    transform: scale(0.9);
  }
`;

const LegendWrapper = styled.div`
  min-width: 5vw;
  padding: 2rem 0;

  ${breakpoints.up(`xlr`)} {
    padding: 4rem 0;
  }

  .demo-video-legend {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }

  .demo-video-legend-moment {
    color: rgb(105, 117, 126);
    font-size: 1.2rem !important;

    ${breakpoints.up(`xlr`)} {
      font-size: 1.4rem !important;
    }
  }

  .demo-video-legend-moment span:hover {
    color: black;
  }

  .moment.selected {
    filter: none;
    color: black;
  }

  .chapter.selected {
    filter: none;
    font-weight: 600 !important;
    color: black;
  }
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 5rem;
  align-content: center;
  justify-items: center;
  justify-content: center;

  iframe {
    height: 80vh;
    width: 100%;
    appearance: none;
    border: none;
    outline: none;
    box-shadow: none;
    background-size: cover;
    border-radius: 3.5rem;
    overflow: hidden;
  }
`;
