import { createGlobalStyle } from 'styled-components';
import { breakpoints } from '@/components/responsive';

export const GlobalStyles = createGlobalStyle`
  html {
    font-size: 62.5%;
  }

  button {
    background: none;
    border: none;
  }
  html, body, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
  }

  * {
    box-sizing: border-box;
  }

  .full-width {
    display: grid;
    align-items: center;
    width: 100%;
  }

  .only-desktop {
    ${breakpoints.down(`md`)} {
      display: none !important;
    }
  }

  .only-xxl-desktop {
    ${breakpoints.down(`xxl`)} {
      display: none !important;
    }
  }

  .only-xl-desktop {
    ${breakpoints.down(`xlr`)} {
      display: none !important;
    }
  }

  .only-mobile {
    ${breakpoints.up(`md`)} {
      display: none !important;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  *::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .uplift-tooltip {
    background: transparent!important;
    margin-left: -3rem!important;
  }

  .exp-desc-tooltip {
    display: grid !important;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    grid-gap: 0.5rem;
    flex-direction: column;
    box-shadow: 0 4px 5px 0 rgba(105, 122, 231, 0.11),
    0 12px 16px 0 rgba(196, 217, 210, 0.38), 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    border-radius: 1rem !important;
    line-height: 1.5;
    font-family: Inter, serif;
    opacity: 0.95 !important;
    padding: 2rem !important;
    font-weight: 400 !important;
    max-width: 50rem;
  }

  .exp-short-line-tooltip {
    display: grid !important;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    grid-gap: 0.5rem;
    flex-direction: column;
    box-shadow: 0 4px 5px 0 rgba(105, 122, 231, 0.11),
    0 12px 16px 0 rgba(196, 217, 210, 0.38), 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    border-radius: 1rem !important;
    line-height: 1.5;
    font-family: Inter, serif;
    opacity: 1 !important;
    padding: 1rem !important;
    font-weight: 400 !important;
    max-width: 50rem;
  }

  .exp-short-line-tooltip p {
    line-height: 1.5;
    margin: 0;
    padding: 0;
  }

  .disabled-tooltip {
    display: grid !important;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    grid-gap: 0.5rem;
    flex-direction: column;
    box-shadow: 0 4px 5px 0 rgba(105, 122, 231, 0.11),
    0 12px 16px 0 rgba(196, 217, 210, 0.38), 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    border-radius: 1rem !important;
    font-family: 'JetBrains Mono', serif;
    opacity: 1 !important;
    padding: 1.2rem !important;
  }

.text-tooltip {
  display: grid!important;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  grid-gap: 0.5rem;
  flex-direction: column;
  box-shadow: 0 4px 5px 0 rgba(105, 122, 231, 0.11),
    0 12px 16px 0 rgba(196, 217, 210, 0.38), 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  border-radius: 1rem!important;
  font-family: "JetBrains Mono",serif;
  opacity: 1!important;
  padding: 1.2rem!important;

}
`;
