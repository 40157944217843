import React, { useContext } from 'react';
import { ProductSearchInner } from '@/features/editor/widgets/custom-widget/loading-section';
import { LayeredRulingContext } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import { VSpace } from '@/components/spacing';

export function Search() {
  const ctx = useContext(LayeredRulingContext);
  const activeRule = ctx.state.rules.find((r) => r.isOpen);
  const onProductSelect = (p, slotId) => {
    ctx.setProductSearchSlotOpen(undefined);
    ctx.changeSlot(activeRule.id, slotId, (s) => {
      s.manualProduct = p;
    });
  };

  const onManualImageSelection = (p) => {
    ctx.setManualImageSelectionOpen(p);
  };

  if (
    !ctx.isProductSearchOpen ||
    !activeRule ||
    ctx.isManualImageSelectionOpen
  ) {
    return null;
  }
  return (
    <>
      <VSpace value={4} />
      <ProductSearchInner
        searchLoading={ctx.searchLoading}
        searchTotal={ctx.searchState.products.length}
        productSearchSlotOpen={ctx.productSearchSlotOpen}
        next={ctx.loadMore}
        hasMore={ctx.searchState.hasMore}
        products={ctx.searchState.products}
        setManualImageSelectionOpen={onManualImageSelection}
        selectProductForSlot={onProductSelect}
      />
    </>
  );
}

export { ManualImageSelect } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule/search/manualImageSelect';
