import styled from 'styled-components';
import Datetime from 'react-datetime';
import React, { FC } from 'react';
import { utcInSecToDate } from '@/features/details/utils';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { useDatePickerInput } from '@/features/editor/widgets/custom-widget/inputs/date-picker/useDatePicker';

export const DatePicker: FC<GenericInputProps> = ({
  initialValues,
  onValuesChanged,
  spec,
}) => {
  const { value, onChange } = useDatePickerInput(
    initialValues,
    onValuesChanged,
  );
  return (
    <DateTimePicker key={`dPicker${spec.key}`}>
      <Label>{spec.name}</Label>
      <Datetime
        value={utcInSecToDate(value)}
        onChange={(v) => onChange(v as any)}
        dateFormat="MMMM D, YYYY"
        isValidDate={(_) => true}
        closeOnSelect
      />
    </DateTimePicker>
  );
};

export const Label = styled.span`
  color: #95a2ae;
  font-family: 'JetBrains Mono', serif;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: -0.38px;
  left: 5%;
  position: absolute;
`;

export const DateTimePicker = styled.div`
  position: relative;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;

  height: 4.7rem;
  margin: 0 0;
  padding: 0 2rem;
  border: 1px solid #eaecf1;
  border-radius: 1.2rem;
  background-color: #ffffff;
  box-shadow: 0 18px 26px 0 rgba(177, 217, 203, 0.18);
  pointer-events: auto;
  cursor: pointer;
  .rdt {
    position: absolute;
    height: 4.7rem;
    padding: 0 2rem;
    width: 100%;
    input {
      width: 100%;
      left: -4px;
      position: absolute;
      height: 4.7rem;
      padding: 0 2rem 0 calc(25% + 1rem);
      border: none;
      z-index: 0;
      background: transparent;
      cursor: pointer;
      color: #333333;
      font-size: 1.3rem;
      font-weight: 500;
      text-transform: uppercase;
      font-family: JetBrains Mono, serif;
      outline: none;
      &:focus-visible {
        border: none;
      }
    }
    .rdtPicker {
      * {
        font-size: 1.2rem !important;
      }
      bottom: 100%;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 15%),
        0 4px 11px 0 rgb(151 210 206 / 22%);
      .rdtTime {
        .rdtSwitch {
          cursor: pointer;
          ::before {
            content: '';
            display: inline-block;
            height: 4px;
            width: 4px;
            border: solid #89959c;
            border-width: 3px 0 0 3px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            border-radius: 1px;
            position: absolute;
            left: 2rem;
            top: 1.6rem;
          }
        }
      }
      td.rdtNew {
        color: unset !important;
      }
    }
    .rdtPicker td.rdtActive {
      background-color: #0086ff;
    }
  }
`;
