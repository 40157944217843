import * as React from 'react';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import { CustomizationsSection } from '@/features/editor/widgets/custom-widget/blueprint-style';
import { StylingComponentsGroup } from '@/features/editor/widgets/post-purchase/styling/group';
import {
  InputComponent,
  InputCustomization,
  InputSpec,
} from '@/webapi/checkout-widget-props';
import { CheckoutExtensibilityContext } from '@/features/editor/widgets/checkout-extensibility/context';
import {
  ContentSection,
  HeaderSection,
  Text,
} from '@/features/editor/widgets/custom-widget/inputs/shared/customization-group';
import { Flexbox } from '@/components/flex';
import { CheckoutGenericInput } from '@/features/editor/widgets/checkout-extensibility/inputs/generic-input';
import { GroupDisableButton } from '@/features/editor/widgets/custom-widget/inputs/shared/group-disable-button';

export function CheckoutInspectorWidgetStyleAdvanced() {
  const {
    currentWidget: widget,
    getCustomizationStatus,
    toggleCustomizationStatus,
  } = useContext(CheckoutExtensibilityContext);

  return (
    <div style={{ maxWidth: `56rem` }}>
      <CustomizationsSection>
        {widget.manifest.customizations.map((cust, custId) => (
          <Section key={cust.key} title={cust.title}>
            {cust?.allowDisable && (
              <GroupDisableButton
                className="disable-section"
                isDisabled={getCustomizationStatus(cust?.key)}
                onClick={() => toggleCustomizationStatus(cust?.key)}
              />
            )}
            <CheckoutWidgetCustomization cust={cust} custId={custId} />
          </Section>
        ))}
      </CustomizationsSection>
    </div>
  );
}

interface CheckoutWidgetCustomizationProps {
  cust: InputCustomization;
  custId: number;
}

function CheckoutWidgetCustomization({
  cust,
  custId,
}: CheckoutWidgetCustomizationProps) {
  return (
    <>
      {cust.components.map((comp, compId) => (
        <StylingComponentsGroup key={comp.key} title={comp.title}>
          <CheckoutWidgetComponent
            cust={cust}
            custId={custId}
            comp={comp}
            compId={compId}
          />
        </StylingComponentsGroup>
      ))}
    </>
  );
}

interface CheckoutWidgetComponentProps {
  cust: InputCustomization;
  custId: number;
  comp: InputComponent;
  compId: number;
}

function CheckoutWidgetComponent({
  cust,
  custId,
  comp,
  compId,
}: CheckoutWidgetComponentProps) {
  return (
    <ComponentWrapper>
      {comp.specs.map((spec, specId) => (
        <CheckoutWidgetSpec
          key={spec.key}
          cust={cust}
          custId={custId}
          comp={comp}
          compId={compId}
          spec={spec}
          specId={specId}
        />
      ))}
    </ComponentWrapper>
  );
}

interface CheckoutWidgetSpecProps {
  cust: InputCustomization;
  custId: number;
  comp: InputComponent;
  compId: number;
  spec: InputSpec;
  specId: number;
}

function CheckoutWidgetSpec({
  cust,
  custId,
  comp,
  compId,
  spec,
  specId,
}: CheckoutWidgetSpecProps) {
  return (
    <CheckoutGenericInput
      cust={cust}
      custId={custId}
      comp={comp}
      compId={compId}
      spec={spec}
      specId={specId}
    />
  );
}

const Section = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <SectionWrapper>
      <HeaderSection onClick={() => setIsOpen(!isOpen)}>
        <Text>{title}</Text>
        <Flexbox direction="row" gap="1rem">
          <Text>{isOpen ? `-` : `+`}</Text>
        </Flexbox>
      </HeaderSection>
      {isOpen && <ContentSection isOpen={isOpen}>{children}</ContentSection>}
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div`
  user-select: none;
  position: relative;

  &&:first-of-type {
    .disable-section {
      top: 0.2rem !important;
    }
  }

  .disable-section {
    margin: 0 !important;
    position: absolute;
    top: 2.3rem;
    right: 4rem;
  }
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
