import styled from 'styled-components';

export interface GroupDisableButtonProps {
  isDisabled?: boolean;
}

export const GroupDisableButton = styled.button`
  :before {
    content: ${(props: GroupDisableButtonProps) =>
      props.isDisabled ? `'Enable'` : `'Disable'`};
  }
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
  appearance: none;
  background: ${(props: GroupDisableButtonProps) =>
    props.isDisabled ? `#3451fa` : `#ffffff`};
  color: ${(props: GroupDisableButtonProps) =>
    props.isDisabled ? `#ffffff` : `#a6a5a5`};
  border-radius: 2rem;
  padding: 0.3rem 0.8rem;
  font-family: 'JetBrains Mono', serif;
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid rgba(213, 217, 220, 0.34);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15),
    0 6px 7px 0 rgba(151, 210, 206, 0.22);

  :hover {
    filter: brightness(80%);
  }

  :active {
    filter: brightness(70%);
  }
`;
