import { DateRange } from '@/features/dashboard/reports/models';

export function calcStartAndEndDates(
  startDate: Date,
  endDate: Date,
  dateRange: DateRange,
) {
  let start = startDate;
  let end = endDate;
  switch (dateRange) {
    case DateRange.CUSTOM:
      break;
    case DateRange.LAST_30_DAYS:
      {
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        start = thirtyDaysAgo;
        end = undefined;
      }
      break;
    case DateRange.LAST_7_DAYS:
      {
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 7);
        start = lastWeek;
        end = undefined;
      }
      break;
    case DateRange.TODAY:
      start = new Date();
      end = undefined;
      break;
    case DateRange.YESTERDAY:
      {
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 1);
        start = lastWeek;
        end = new Date();
      }
      break;
    case DateRange.LAST_YEAR:
      {
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 365);
        start = lastWeek;
        end = undefined;
      }
      break;
    case DateRange.ALL_TIME:
      {
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 365 * 2);
        start = lastWeek;
        end = undefined;
      }
      break;
    default:
  }
  return { start, end };
}
