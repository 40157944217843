import React, { MutableRefObject } from 'react';
import styled from 'styled-components';
import { centered } from '@/components/use-shared-element';
import { GradientModal } from '@/components/gradient-modal';

export interface ExperienceDescriptionModalProps {
  fromRef: MutableRefObject<any>;
  visible: boolean;
  setVisible: (s: boolean) => void;
  description: string;
}

export function ExperienceDescriptionModal({
  fromRef,
  visible,
  setVisible,
  description,
}: ExperienceDescriptionModalProps) {
  const modalCfg = getModalConfig(fromRef);

  return (
    <GradientModal
      {...modalCfg}
      isVisible={visible}
      onClose={() => setVisible(false)}
      header=""
      footer={null}
    >
      <Wrapper
        dangerouslySetInnerHTML={{ __html: decodeURIComponent(description) }}
      />
    </GradientModal>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

function getModalConfig(fromRef: React.MutableRefObject<any>) {
  const targetPosAndSize = centered(50, 60);

  return {
    targetPosAndSize,
    closeTop: `0.5rem`,
    closeRight: `2.5rem`,
    fromStyle: {
      borderRadius: `5rem`,
      backgroundColor: `#DEDEDE`,
      padding: `2rem 0 0 0`,
    },
    toStyle: {
      borderRadius: `1rem`,
      backgroundColor: `#FFFFFF`,
      padding: `2rem 0 0 0`,
    },
    showBackdrop: true,
    fromRef,
    overflowTop: { heightInRem: 6 },
    overflowBottom: { heightInRem: 8, turnPointInPercent: 30 },
  };
}
