import React, { useContext } from 'react';
import styled from 'styled-components';
import { QBType } from '@/components/query-builder/models';
import { SelectOptions, SmallSelect } from '@/components/small-select';
import { QBMultiValue } from '@/components/query-builder/multi-value';
import { QBTextValue } from '@/components/query-builder/text-value';
import { QBNumericValue } from '@/components/query-builder/numeric-value';
import { QBHSpace, QBTypography } from '@/components/query-builder/shared';
import { QBContext } from '@/components/query-builder/context';
import { QBArrayValue } from '@/components/query-builder/array-value';
import { RecommendationType } from '@/webapi/use-widget-catalog-api';

type Props = {
  idx: number;
  options: SelectOptions[];
  textCondNoMarginTop?: boolean;
  selectMaxWidth?: number;
};

export function QBRootValue({
  idx,
  options,
  textCondNoMarginTop,
  selectMaxWidth,
}: Props) {
  const { queryState, changeKey, findProps } = useContext(QBContext);

  const getComponent = () => {
    const kind = queryState?.[idx]?.qbProps?.kind;
    switch (kind) {
      case QBType.MULTI_VALUE:
        return <QBMultiValue idx={idx} selectMaxWidth={selectMaxWidth} />;
      case QBType.ARRAY_VALUE:
        return <QBArrayValue idx={idx} />;
      case QBType.TEXT_VALUE:
        return (
          <QBTextValue idx={idx} textCondNoMarginTop={textCondNoMarginTop} />
        );
      case QBType.NUMERIC_VALUE:
        return <QBNumericValue idx={idx} />;
      default:
        return <div />;
    }
  };

  const onSelection = (selected: string) => {
    changeKey(idx, findProps(selected));
  };

  const qbProps = queryState?.[idx]?.qbProps;
  let minWidth;
  let isSelectDisabled = false;
  if (qbProps?.envKey === RecommendationType.PAST_PURCHASES) {
    isSelectDisabled = idx === 0;
  } else if (qbProps?.envKey === RecommendationType.CART_ITEMS) {
    isSelectDisabled = idx === 0 || idx === 1;
    if (idx === 1) {
      minWidth = 250;
    }
  }
  return (
    <Wrapper>
      <SmallSelect
        isDisabled={isSelectDisabled}
        options={options}
        minWidth={minWidth}
        onSelection={onSelection}
        defaultValue={qbProps?.caption}
      />
      <QBHSpace />
      {getComponent()}
    </Wrapper>
  );
}

const Wrapper = styled(QBTypography)`
  && {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-width: 435px;
  }
`;
