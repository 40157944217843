import React from 'react';
import styled from 'styled-components';
import { Product } from '@/features/editor/widgets/custom-widget/loading-section/shared/models';
import { HSpace } from '@/components/spacing';
import { DeleteBtn } from '@/features/editor/widgets/custom-widget/loading-section/shared/delete-btn';
import { MoreFilters } from '@/features/editor/widgets/custom-widget/loading-section/shared/more-filters';
import { InventoryQty } from '@/features/editor/widgets/custom-widget/loading-section/shared/inventory-qty';
import { currencySymbol } from '@/utils/currencies';

type Props = {
  onClick?: () => void;
  p: Product;
  slotNum?: number;
  onDelete?: () => void;
  onMoreFilters?: () => void;
  filterLen?: number;
};

export function ProductTile({
  onClick,
  slotNum,
  p: {
    compareAtPrice,
    handle,
    image,
    mainValue,
    price,
    title,
    inventoryQuantity,
  },
  onMoreFilters,
  filterLen,
  onDelete,
}: Props) {
  const isClickable = typeof onClick !== `undefined`;
  const symbol = currencySymbol();
  return (
    <SearchResultItem onClick={onClick} isClickable={isClickable}>
      <DeleteBtn slotNum={slotNum} onDelete={onDelete} />
      <ImageBox>
        <img src={image.src} alt={handle} />
      </ImageBox>
      <HSpace value={2} />
      <ProductDetails>
        <ProductTitle>
          {title} <HSpace />
          <InventoryQty inventoryQuantity={inventoryQuantity} />
        </ProductTitle>
        <Color>{mainValue}</Color>
        <Price>
          <span>
            {symbol}
            {price || 0}
          </span>
          {compareAtPrice > 0 && (
            <span>
              {symbol}
              {compareAtPrice}
            </span>
          )}
        </Price>
        <MoreFilters
          isVisible={!isClickable}
          filterLen={filterLen}
          onMoreFilters={onMoreFilters}
        />
      </ProductDetails>
    </SearchResultItem>
  );
}
const Price = styled.div`
  margin-top: 1rem;
  display: flex;
  span:first-child {
    margin-right: 1rem;
    color: red;
  }
  span:nth-child(2) {
    color: grey;
    text-decoration: line-through;
  }
`;
const Color = styled.span`
  margin-top: 0.5rem;
  font-weight: 800;
`;
const ProductTitle = styled.span`
  text-transform: uppercase;

  font-weight: 500;
  color: #4b545e;
  font-size: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  max-width: 20rem;
  max-height: 3rem;
  min-height: 1.4rem;
  overflow: hidden;
`;
const ImageBox = styled.div`
  width: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    border-radius: 10px;
    width: 100%;
  }
`;
const ProductDetails = styled.div`
  font-family: 'JetBrains Mono', serif;
  display: flex;
  flex-direction: column;
`;
export const SearchResultItem = styled.div`
  position: relative;
  ${(p: any) =>
    p.isClickable
      ? `
    &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  &:active {
    cursor: default;
    opacity: 1;
    box-shadow: none;
  }
  `
      : ``}

  display: flex;
  align-items: center;
  padding: 2rem;
  border-radius: 10px;
  height: 14rem;
  min-width: 33rem;
  background-color: #fafafa;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15),
    0 7px 8px 0 rgba(151, 210, 206, 0.22), 0 3px 8px 0 rgba(0, 0, 0, 0);
  border: 1px solid  ${(p: any) => (p.isClickable ? `#d5d9dc` : `#a6afb8`)}} ;
`;
