import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  GenericInputProps,
  InputsMinimalTheme,
} from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { Caption } from '@/features/editor/widgets/custom-widget/shared/shared-styles';
import { CardWrapper } from '@/features/editor/widgets/custom-widget/inputs/shared/card-wrapper';
import {
  DropdownInput,
  DropdownInputOption,
} from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { BackgroundColorHeader } from '@/features/editor/widgets/custom-widget/inputs/background/background-color';
import {
  BackgroundImageControls,
  BackgroundImageHeader,
} from '@/features/editor/widgets/custom-widget/inputs/background/background-image';
import {
  CustomWidgetContext,
  CustomWidgetContextProps,
} from '@/features/editor/widgets/custom-widget/shared/context';
import { isPlaceholder } from '@/features/editor/widgets/custom-widget/inputs/background/image/shared/context';
import {
  BackgroundVideoControls,
  BackgroundVideoHeader,
} from '@/features/editor/widgets/custom-widget/inputs/background/background-video';
import {
  newVideoInputContext,
  VideoInputContext,
} from '@/features/editor/widgets/custom-widget/inputs/background/video/context';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';
import { isColor } from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/utils';

export const INPUT_BACKGROUND_CLASS = `input-background`;

const modeKey = `mode`;
const valueKey = `value`;
const allowVideoKey = `allowVideo`;

const defaultOptions: DropdownInputOption[] = [
  { label: `None`, value: `none` },
  { label: `Color`, value: `color` },
  { label: `Image`, value: `image` },
];
const videoOption = { label: `Video`, value: `video` };

function getActiveValues(getMode: () => any, getValue: () => any) {
  let mode = getMode();
  const value = getValue();
  if (value === `none`) {
    mode = `none`;
  } else if (!(value as string)?.startsWith(`url`) && mode !== `video`) {
    mode = `color`;
  }
  return { mode, value };
}

export function BackgroundInput({
  customizationIdx,
  customization,
  componentIdx,
  component,
  specIdx,
  spec,
  device,
  showInputName,
  initialValues,
  onValuesChanged,
  hover,
  noModeSelection,
}: GenericInputProps & { noModeSelection?: boolean }) {
  const props = {
    customizationIdx,
    customization,
    componentIdx,
    component,
    specIdx,
    spec,
    device,
    initialValues,
    onValuesChanged,
    hover,
  };

  const { undoRedoCount } = useContext(UndoRedoCounterContext);

  const allowVideo = useCallback(
    () => initialValues(allowVideoKey) as boolean,
    [undoRedoCount],
  );

  const options = useMemo(() => {
    if (allowVideo() === true) {
      return [...defaultOptions, videoOption];
    }
    return defaultOptions;
  }, [undoRedoCount]);

  const getMode = () => initialValues(modeKey);
  const getValue = () => initialValues(valueKey);
  const { mode, value } = getActiveValues(getMode, getValue);
  const [backgroundMode, setBackgroundMode] = useState(mode);
  useEffect(() => {
    const { mode } = getActiveValues(getMode, getValue);
    if (mode !== backgroundMode) {
      setBackgroundMode(mode);
    }
  }, [undoRedoCount]);
  const ctx = useContext(CustomWidgetContext);
  const openColorTooltip = useRef(false);
  const openImageTooltip = useRef<boolean>(
    shouldOpenOnMount(ctx, backgroundMode, value),
  );

  const defaultColor = isColor(value) ? value : `rgba(255, 0, 0, 0)`;

  useEffect(() => {
    if (backgroundMode === `none`) {
      onValuesChanged(`value`, `none`);
    }

    if (backgroundMode === `color`) {
      onValuesChanged(`value`, defaultColor);
    }
  }, [backgroundMode]);

  const revertToActive = () => {
    setBackgroundMode(mode);
    onValuesChanged(modeKey, backgroundMode, undefined, {
      forceSameVersion: true,
    });
    onValuesChanged(`value`, value, undefined, {
      forceSameVersion: true,
    });
    openColorTooltip.current = false;
    openImageTooltip.current = false;
  };

  const handleModeChange = ({ value }) => {
    if (value === `color` && backgroundMode !== `color`) {
      openColorTooltip.current = true;
    } else if (value === `image` && backgroundMode !== `image`) {
      openImageTooltip.current = true;
    }
    onValuesChanged(modeKey, value, undefined, {
      ignoreHistory: openImageTooltip.current,
    });
    setBackgroundMode(value);
  };

  const videoRef = useRef(null);
  const videoInputCtx = newVideoInputContext(
    {
      device,
      initialValues,
      onValuesChanged,
      videoRef,
    },
    backgroundMode,
  );

  return (
    <VideoInputContext.Provider value={videoInputCtx}>
      <CardWrapper
        className={INPUT_BACKGROUND_CLASS}
        hideChildren={
          [`color`, `none`].includes(backgroundMode) || noModeSelection
        }
        title={
          <HeaderWrapper noModeSelection={noModeSelection}>
            <Caption>
              {showInputName && spec?.name ? spec?.name : `Background`}
            </Caption>
            {backgroundMode === `none` && <span />}
            {backgroundMode === `color` && (
              <BackgroundColorHeader
                key={`color-${backgroundMode}-${undoRedoCount}`}
                {...props}
                defaultColor={defaultColor}
                isActive={backgroundMode === `color`}
                openColorTooltip={openColorTooltip}
                revertToActive={revertToActive}
              />
            )}
            {backgroundMode === `image` && (
              <BackgroundImageHeader
                key={`image-${backgroundMode}-${undoRedoCount}`}
                {...props}
                openImageTooltip={openImageTooltip}
                revertToActive={revertToActive}
              />
            )}
            {backgroundMode === `video` && (
              <BackgroundVideoHeader
                key={`video-${backgroundMode}-${undoRedoCount}`}
                videoRef={videoRef}
                {...props}
              />
            )}
            {!noModeSelection && (
              <DropdownInput
                key={`input-${backgroundMode}-${undoRedoCount}`}
                theme={InputsMinimalTheme}
                options={options}
                defaultValue={options.find(
                  ({ value }) => value === backgroundMode,
                )}
                onChange={handleModeChange}
              />
            )}
          </HeaderWrapper>
        }
      >
        {backgroundMode === `image` && (
          <BackgroundImageControls
            {...props}
            initialValues={initialValues}
            isActive={backgroundMode === `image`}
            onValuesChanged={onValuesChanged}
          />
        )}
        <div style={{ display: backgroundMode === `video` ? `block` : `none` }}>
          <BackgroundVideoControls {...props} videoRef={videoRef} />
        </div>
      </CardWrapper>
    </VideoInputContext.Provider>
  );
}

const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: ${(p) =>
    p.noModeSelection === true ? `1fr 2fr` : `1fr 2fr 10rem`};
  justify-content: center;
  align-items: center;
  grid-auto-rows: 2rem;
`;

function shouldOpenOnMount(
  ctx: CustomWidgetContextProps,
  backgroundMode,
  value,
) {
  return (
    ctx?.currentWidget?.name === `Image` &&
    backgroundMode === `image` &&
    isPlaceholder(value)
  );
}
