// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { routes } from '@/webapi/routes';
import {
  AccountContext,
  createAccountContext,
  isPrivatePath,
  useFetchUserContext,
} from '@/features/account-context';
import { breakpoints } from '@/components/responsive';
import { UnsupportedDevice } from '@/components/Unsupported';
import { AppEmbedModal } from '@/features/welcome-quiz/enable-app-embed/app-embed-modal';

export function Layout({
  children,
  location,
}: {
  children: any;
  location: { pathname: string };
}) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          stage
          proxy
        }
      }
    }
  `);
  const { stage, proxy } = data.site.siteMetadata;
  routes.withEnv(stage, proxy);
  const accountCtx = createAccountContext();
  useFetchUserContext(accountCtx, location?.pathname);
  const someRef = React.useRef(null);
  const isPrivate = isPrivatePath(location?.pathname);
  const hasAccount =
    accountCtx?.account?.isLoggedIn &&
    accountCtx?.account?.store?.alias &&
    !accountCtx?.loading;

  return (
    <AccountContext.Provider value={accountCtx}>
      {accountCtx.isAppEmbedModalVisible && (
        <>
          <div ref={someRef} />
          <AppEmbedModal
            isVisible={accountCtx.isAppEmbedModalVisible}
            fromRef={someRef}
            setIsVisible={accountCtx.setIsAppEmbedModalVisible}
            header="Enable App Embed"
          />
        </>
      )}
      <InnerLayout pathname={location.pathname}>
        {isPrivate && hasAccount ? (
          children
        ) : (
          <div style={{ background: `rgb(248, 249, 251)` }} />
        )}
        {!isPrivate && children}
      </InnerLayout>
    </AccountContext.Provider>
  );
}

const InnerLayout: FC<{
  pathname: string;
}> = ({ children, pathname }) => {
  const isSupportedOnMobile =
    [
      ``,
      `/`,
      `/public/fb/auth/cb`,
      `/public/fb/auth/cb/`,
      `/public/insta/auth/cb/`,
      `/public/insta/auth/cb`,
      `/public/privacy-policy`,
      `/public/privacy-policy/`,
      `/public/pricing/`,
      `/public/pricing`,
      `/public/comparison/dy`,
      `/public/comparison/dy/`,
      `/public/comparison/shoplift`,
      `/public/comparison/shoplift/`,
      `/public/comparison/rebuy`,
      `/public/comparison/rebuy/`,
      `/public/comparison/intelligems`,
      `/public/comparison/intelligems/`,
      `/public/talk-to-expert`,
      `/public/talk-to-expert/`,
      `/public/partners`,
      `/public/partners/`,
      `/pricing`,
      `/pricing/`,
      `/product-demo/`,
      `/product-demo`,
      `/public/affiliates/`,
      `/public/affiliates`,
      `/public/customers`,
      `/public/customers/`,
    ].includes(pathname) || pathname.includes(`/public/case-studies/`);
  return isSupportedOnMobile ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <>
      <HideOnMobile>{children}</HideOnMobile>
      <UnsupportedDevice pathname={pathname} />
    </>
  );
};

const HideOnMobile = styled.div`
  height: 100%;
  width: 100%;

  ${breakpoints.down(`md`)} {
    display: none;
  }
`;
