import * as React from 'react';
import { CardButton, TextualCardLayout } from '@/components/card-button';

export const LineItem = ({
  children,
  selected,
  onClicked,
  height,
  disabled,
}: {
  children: any;
  selected: boolean;
  onClicked: (ev: any, selected: boolean) => void;
  height?: string;
  disabled?: boolean;
}) => {
  if (!height) {
    height = `10rem`;
  }
  return (
    <CardButton
      isDisabled={disabled}
      height={height}
      borderRadius="1.5rem"
      onClicked={onClicked}
      selected={selected}
      canBeSelected
    >
      <TextualCardLayout
        verticalGap="0rem"
        padding="0 1.5rem"
        primaryColor={selected ? `#003CED` : `#576470`}
        accentColor={selected ? `#003CED` : `#758390`}
        primaryFontSize="1.4rem"
        accentFontSize="1.1rem"
        accentFontFamily="Inter"
        accentFontWeight="500"
      >
        {children}
      </TextualCardLayout>
    </CardButton>
  );
};
