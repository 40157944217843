/* eslint-disable prefer-destructuring */
import React, { useCallback, useEffect } from 'react';
import produce from 'immer';
import {
  QBItemProp,
  QBItemProps,
  QBItemSelection,
  QBType,
  TimeframeUnit,
} from '@/components/query-builder/models';
import { SelectOptions } from '@/components/small-select';
import { MultiValueOps } from '@/components/query-builder/multi-value';
import { TextValueOps } from '@/components/query-builder/text-value';
import { NumberValueOps } from '@/components/query-builder/numeric-value';
import { ArrayValueOps } from '@/components/query-builder/array-value';
import { useComplexState } from '@/utils/use-complex-state';
import {
  UseObservableRef,
  useObservableRef,
} from '@/components/hooks/use-observeable-ref';
import { RecommendationType } from '@/webapi/use-widget-catalog-api';
import { currencyValue } from '@/utils/currencies';

export const QBContext = React.createContext({} as QBContextProps);

export function createQBContext(
  initialState: QBItemSelection[],
  initialProps: QBItemProps,
  onChange?: (value: QBItemSelection[]) => void,
): QBContextProps {
  const onboardingRef = useObservableRef(null);

  const [queryState, setQueryState] = useComplexState<QBItemSelection[]>(
    mergeQueryOptions(initialState, initialProps),
  );

  const updateTimeframe = (
    idx: number,
    timeframe?: { value: number; unit: TimeframeUnit },
  ) => {
    setQueryState((clone) => {
      clone[idx].timeframe = timeframe;
    });
  };

  const setCurrencyAutoApply = (idx: number, bool: boolean) => {
    setQueryState((clone) => {
      clone[idx].autoApplyCurrency = bool;
    });
  };

  const setIncludeQueryParams = (idx: number, bool: boolean) => {
    setQueryState((clone) => {
      clone[idx].includeQueryParams = bool;
    });
  };

  const updateValue = (
    idx: number,
    subIdx: number,
    op: string,
    value: string,
    currency?: string,
  ) => {
    setQueryState((clone) => {
      clone[idx].values[subIdx].op = op;
      clone[idx].values[subIdx].value = value;
      clone[idx].values[subIdx].currency = currency;
    });
  };

  const appendValue = (
    idx: number,
    op: string,
    value: string,
    currency?: string,
  ) => {
    setQueryState((clone) => {
      clone?.[idx]?.values?.push({ op, value, currency });
    });
  };

  const removeValue = (idx: number, childIdx: number) => {
    setQueryState((clone) => {
      clone[idx].values.splice(childIdx, 1);
    });
  };

  const appendKey = (userActivated?: boolean) => {
    const idx = queryState.length;
    setQueryState((clone) => {
      let initialProp = initialProps[0];
      if (
        userActivated &&
        initialProp?.envKey === RecommendationType.PAST_PURCHASES
      ) {
        initialProp = initialProps[1];
      } else if (
        userActivated &&
        initialProp?.envKey === RecommendationType.CART_ITEMS
      ) {
        initialProp = initialProps[2];
      }
      clone.push({
        qbProps: initialProp,
        values: [defaultValues(initialProp)],
      });
    });
    return idx;
  };

  const changeKey = (idx: number, selection: QBItemSelection) => {
    setQueryState((clone) => {
      clone[idx] = selection;
    });
  };

  const removeKey = (idx: number) => {
    setQueryState((clone) => {
      clone.splice(idx, 1);
    });
  };

  const firstOptions = useCallback(
    () =>
      initialProps.map((v) => ({
        key: v.caption,
        value: v.caption,
        group: v.group,
      })),
    [],
  );

  const findProps = (option: string): QBItemSelection => {
    const found = initialProps.filter((p) => p.caption === option);
    if (found && found.length > 0) {
      return { qbProps: found[0], values: [defaultValues(found[0])] };
    }
    return undefined;
  };
  const defaultCurrency = currencyValue();
  const defaultValues = (
    prop: QBItemProp,
  ): { op: string; value: string; currency?: string } => {
    if (prop?.kind === QBType.MULTI_VALUE) {
      return { op: MultiValueOps.IS, value: prop?.options?.[0]?.key as string };
    }
    if (prop?.kind === QBType.TEXT_VALUE) {
      return { op: prop.defaultTextValueOp || TextValueOps.IS, value: `` };
    }
    if (prop?.kind === QBType.NUMERIC_VALUE) {
      return {
        op: prop.defaultNumericValueOp || NumberValueOps.GTE,
        value: `0`,
        ...(prop.hasCurrencyCode ? { currency: defaultCurrency } : {}),
      };
    }
    if (prop?.kind === QBType.ARRAY_VALUE) {
      return {
        op: prop.defaultIsNot ? ArrayValueOps.ISNOT : ArrayValueOps.IS,
        value: prop?.options?.[0]?.key as string,
      };
    }
    return { op: ``, value: `` };
  };

  useEffect(() => {
    if (queryState?.length === 0) {
      if (initialProps?.[0]?.envKey === RecommendationType.CART_ITEMS) {
        setQueryState((clone) => {
          clone.push({
            qbProps: initialProps[0],
            values: [defaultValues(initialProps[0])],
          });
          clone.push({
            qbProps: initialProps[1],
            values: [defaultValues(initialProps[1])],
          });
        });
      } else {
        appendKey();
      }
    }
    onChange && onChange(queryState);
  }, [queryState]);

  return {
    setIncludeQueryParams,
    setCurrencyAutoApply,
    updateTimeframe,
    updateValue,
    appendValue,
    removeValue,
    appendKey,
    changeKey,
    removeKey,
    findProps,
    queryState,
    firstOptions,
    onboardingRef,
  };
}

export interface QBContextProps {
  onboardingRef: UseObservableRef<HTMLElement>;
  setCurrencyAutoApply: (idx: number, bool: boolean) => void;
  setIncludeQueryParams: (idx: number, bool: boolean) => void;
  updateTimeframe: (
    idx: number,
    timeframe?: { value: number; unit: TimeframeUnit },
  ) => void;
  updateValue: (
    idx: number,
    subIdx: number,
    op: string,
    value: string,
    currency?: string,
  ) => void;
  appendValue: (
    idx: number,
    op: string,
    value: string,
    currency?: string,
  ) => void;
  removeValue: (idx: number, childIdx: number) => void;

  appendKey: (userActivated?: boolean) => number;
  changeKey: (idx: number, selection: QBItemSelection) => void;
  removeKey: (idx: number) => void;

  findProps: (option: string) => QBItemSelection;
  queryState: QBItemSelection[];

  firstOptions: () => SelectOptions[];
}

function mergeQueryOptions(
  initialState: QBItemSelection[],
  initialProps: QBItemProps,
): QBItemSelection[] {
  return produce(initialState, (draft) => {
    draft.map((item) => {
      const originalProp = initialProps.filter(
        (prop) => prop?.caption === item?.qbProps?.caption,
      );

      if (originalProp && originalProp.length > 0) {
        item.qbProps = originalProp?.[0];
      }
      return item;
    });
  });
}
