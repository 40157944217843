import React, { useContext, useEffect, useState } from 'react';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import { DeviceType } from '@/utils/definitions';
import {
  Product,
  SlotState,
} from '@/features/editor/widgets/custom-widget/loading-section/shared/models';
import { mutateFn, useComplexState } from '@/utils/use-complex-state';
import {
  CatalogWidgetProps,
  RecommendationOptions,
  RecommendationType,
} from '@/webapi/use-widget-catalog-api';
import { QBItemSelection } from '@/components/query-builder/models';
import { defaultCondition } from '@/features/editor/widgets/custom-widget/loading-section/util/defaultCondition';
import { getEmptySlots } from '@/features/editor/widgets/custom-widget/loading-section/shared/util/getEmptySlots';
import { hashSlot } from '@/features/editor/widgets/custom-widget/loading-section/shared/util/hashSlot';
import { searchCtx } from '@/features/editor/widgets/custom-widget/loading-section/shared/search-ctx';

export interface StrategyPerSlotCtx {
  state: State;
  selectProductForSlot: (p: Product, num: number) => void;
  clearSlot: (num: number) => void;

  productCondSlotOpen: number | undefined;
  setProductCondSlotOpen: (x: number | undefined) => any;
  isProductCondOpen: boolean;
  setSlotCond: (cond: Array<QBItemSelection>) => void;
  getCurrentCond: () => Array<QBItemSelection>;
  selectStrategyForSlot: (s: RecommendationType, num: number) => void;
  getOpenCondSlot: () => SlotState;

  manualImageSelectionOpen: Product | undefined;
  setManualImageSelectionOpen: (x: Product | undefined) => void;
  isManualImageSelectionOpen: boolean;

  productSearchSlotOpen: number;
  setProductSearchSlotOpen: (n: number) => void;
  isProductSearchOpen: boolean;

  searchText: string;
  setSearchText: (x: string) => void;
  searchLoading: boolean;
  searchResult: Product[];
  searchTotal: number;
  searchLoadMore: () => void;
  searchHasMore: boolean;
  isPostPurchase: boolean;
  updateRecommendationOptions?: (options: RecommendationOptions) => void;
}

export const StrategyPerSlotContext = React.createContext(
  {} as StrategyPerSlotCtx,
);

type State = {
  slots: Array<SlotState>;
};

export function newStrategyPerSlotCtx(
  device: DeviceType,
  isPostPurchase: boolean,
  initial?: Array<SlotState>,
) {
  const { currentSchema } = useContext(CustomWidgetContext);
  const initialState = initial || getInitialState(device);
  const [state, changeState] = useComplexState<State>({
    slots: initialState,
  });
  useWatchSlotsSize(changeState, currentSchema, device);

  const selectProductForSlot = (p: Product, num: number) => {
    changeState((draft) => {
      draft.slots.forEach((slot) => {
        if (slot.num === num) {
          slot.manualProduct = p;
          delete slot.strategy;
          if (!slot.filter) {
            slot.filter = defaultCondition(undefined, currentSchema?.appId);
            slot.hash = hashSlot(slot);
          }
        }
      });
    });
    setProductSearchSlotOpen(undefined);
  };

  const selectStrategyForSlot = (s: RecommendationType, num: number) => {
    changeState((draft) => {
      draft.slots.forEach((slot) => {
        if (slot.num === num) {
          slot.strategy = s;
          delete slot.manualProduct;
          if (!slot.filter) {
            slot.filter = defaultCondition(s, currentSchema?.appId);
            slot.hash = hashSlot(slot);
          }
        }
      });
    });
  };

  const clearSlot = (num: number) => {
    changeState((draft) => {
      draft.slots.forEach((slot) => {
        if (slot.num === num) {
          delete slot.manualProduct;
          delete slot.strategy;
          delete slot.filter;
        }
      });
    });
  };

  const {
    productCondSlotOpen,
    setProductCondSlotOpen,
    isProductCondOpen,
    setSlotCond,
    getCurrentCond,
    getOpenCondSlot,
  } = slotCondition(changeState, state);

  const {
    productSearchSlotOpen,
    setProductSearchSlotOpen,
    isProductSearchOpen,
    searchState,
    setSearchText,
    loading: searchLoading,
    loadMore,
    manualImageSelectionOpen,
    setManualImageSelectionOpen,
    isManualImageSelectionOpen,
  } = searchCtx();

  return {
    manualImageSelectionOpen,
    setManualImageSelectionOpen,
    isManualImageSelectionOpen,
    getOpenCondSlot,
    selectStrategyForSlot,
    getCurrentCond,
    setSlotCond,
    isProductCondOpen,
    productCondSlotOpen,
    setProductCondSlotOpen,
    selectProductForSlot,
    state,
    clearSlot,
    productSearchSlotOpen,
    setProductSearchSlotOpen,
    isProductSearchOpen,

    setSearchText,
    searchLoading,
    searchText: searchState?.searchText,
    searchResult: searchState.products,
    searchTotal: searchState.products.length,
    searchLoadMore: loadMore,
    searchHasMore: searchState.hasMore,
    isPostPurchase,
  } as StrategyPerSlotCtx;
}

function getInitialState(device: DeviceType) {
  const { currentSchema } = useContext(CustomWidgetContext);
  const savedState = currentSchema.settings?.loading?.loadingEnv
    ?.recommendationOptions?.strategyPerSlot as Array<SlotState>;
  return getEmptySlots(currentSchema, device).map(
    (s) => savedState?.find((x) => x.num === s.num) || s,
  );
}

function useWatchSlotsSize(
  changeState: (fn: mutateFn<State>) => void,
  currentSchema: CatalogWidgetProps,
  device: DeviceType,
) {
  useEffect(() => {
    changeState((draft) => {
      if (getEmptySlots(currentSchema, device).length !== draft.slots.length)
        draft.slots = getEmptySlots(currentSchema, device).map(
          (empty) =>
            draft.slots.find((existing) => existing.num === empty.num) || empty,
        );
    });
  }, [getEmptySlots(currentSchema, device).length]);
}

function slotCondition(
  changeState: (fn: mutateFn<State>) => void,
  state: State,
) {
  const [productCondSlotOpen, setProductCondSlotOpen] = useState<
    number | undefined
  >();
  const isProductCondOpen = typeof productCondSlotOpen !== `undefined`;
  const setSlotCond = (cond: Array<QBItemSelection>) => {
    changeState((draft) => {
      draft.slots.forEach((slot) => {
        if (slot.num === productCondSlotOpen) {
          slot.filter = cond;
          slot.hash = hashSlot(slot);
        }
      });
    });
  };
  const getCurrentCond = () =>
    state.slots.find((s) => s.num === productCondSlotOpen)?.filter;

  const getOpenCondSlot = () =>
    state.slots.find((s) => s.num === productCondSlotOpen);
  return {
    productCondSlotOpen,
    setProductCondSlotOpen,
    isProductCondOpen,
    setSlotCond,
    getCurrentCond,
    getOpenCondSlot,
  };
}
