import { DropzoneRootProps, useDropzone } from 'react-dropzone';
import { useEffect, useMemo, useState } from 'react';
import { useMediaApi } from '@/webapi/use-media-api';
import { extractBlob } from '@/features/editor/widgets/custom-widget/inputs/shared/utils';

const baseStyle = {
  transition: `transform 0.3s ease-in-out`,
};

const focusedStyle = {};

const acceptStyle = {
  backgroundColor: `#ECFFF1`,
  transform: `scale(2)`,
};

const rejectStyle = {
  borderColor: `#FFECEC`,
  backgroundColor: `#f86565`,
  transform: `scale(2)`,
};

export function useImageThumbnailGallery(
  initialValue: string[],
  maxImages: number,
  uploadedImage: string,
  setUploadedImage: (localUrl: string) => void,
  onImageListChanged?: (list: string[]) => void,
) {
  const imageCount = useMemo(() => initialValue?.length || 0, [initialValue]);
  const maxImagesToShow = maxImages || 5;
  const [prevImageList, setPrevImageList] = useState(initialValue);
  const [imageList, setImageList] = useState(initialValue);

  const [loading, setLoading] = useState(false);
  const [processCounter, setProcessCount] = useState(0);

  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [sliderImage, setSliderImage] = useState(``);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: `image/*` });

  const { uploadGenericImage } = useMediaApi();

  const style: DropzoneRootProps = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  useEffect(() => {
    setProcessCount(0);
  }, [uploadedImage]);

  useEffect(() => {
    if (acceptedFiles?.length >= 1) {
      const file = acceptedFiles[0];
      const url = URL.createObjectURL(file);
      setUploadedImage(url);
    }
  }, [acceptedFiles]);

  const onCancelUpload = () => {
    setUploadedImage(``);
  };

  const onUploadBlob = async (blobUrl: string) => {
    if (processCounter > 0) {
      setLoading(true);
      const blob = await getUploadBlob(blobUrl);
      const resp = await uploadGenericImage(blob);
      setImageList((prev) => [...prev, resp.url]);
      setUploadedImage(``);
      setLoading(false);
    }
    setProcessCount((prev) => prev + 1);
  };

  const onImageClicked = (img: string) => {
    setSliderImage(img);
    setIsSliderOpen(true);
  };

  const onImageDelete = async (img: string) => {
    if (imageList) {
      setImageList((prev) => prev.filter((i) => i !== img));
    }
  };

  const onThumbnailClicked = (img: string) => {
    setSliderImage(img);
  };

  useEffect(() => {
    if (!arraysEqual(imageList, prevImageList) && onImageListChanged) {
      setPrevImageList(imageList);
      onImageListChanged(imageList);
    }
  }, [imageList]);

  return {
    imageList,
    imageCount,
    maxImagesToShow,
    onUploadBlob,
    onCancelUpload,
    image: uploadedImage,
    getRootProps,
    getInputProps,
    style,
    loading,
    onImageClicked,
    onImageDelete,
    onThumbnailClicked,
    isSliderOpen,
    setIsSliderOpen,
    sliderImage,
  };
}

async function getUploadBlob(localUrl: string): Promise<Blob> {
  const temp = extractBlob(localUrl);
  return fetch(temp).then((r) => r.blob());
}

function arraysEqual(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}
