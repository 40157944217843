import React from 'react';
import { SlotState } from '@/features/editor/widgets/custom-widget/loading-section/shared/models';
import {
  LoadingStrategyOption,
  RecommendationType,
} from '@/webapi/use-widget-catalog-api';
import { ProductTile } from '@/features/editor/widgets/custom-widget/loading-section/shared/product-tile';
import { StrategyTile } from '@/features/editor/widgets/custom-widget/loading-section/shared/strategy-tile';
import { EmptySlot } from '@/features/editor/widgets/custom-widget/loading-section/shared/empty-slot';

type Props = {
  slot: SlotState;
  onProductSelect: () => void;
  onStrategySelect: (value: LoadingStrategyOption) => () => void;
  onDelete: () => void;
  onMoreFilters: () => void;
};

export function Slot({
  slot,
  onMoreFilters,
  onStrategySelect,
  onProductSelect,
  onDelete,
}: Props) {
  const filterLen = slot?.filter?.length;
  if (slot.manualProduct) {
    return (
      <ProductTile
        p={slot.manualProduct}
        slotNum={slot.num}
        filterLen={filterLen}
        onDelete={onDelete}
        onMoreFilters={onMoreFilters}
      />
    );
  }
  if (slot.strategy && slot.strategy !== RecommendationType.MANUAL) {
    return (
      <StrategyTile
        strategy={slot.strategy}
        slotNum={slot.num}
        onDelete={onDelete}
        onMoreFilters={onMoreFilters}
        filterLen={filterLen}
      />
    );
  }
  return (
    <EmptySlot
      num={slot.num}
      onProductSelect={onProductSelect}
      onStrategySelect={onStrategySelect}
    />
  );
}
