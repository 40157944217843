import * as React from 'react';
import { useContext } from 'react';
import { Segment } from '@/webapi/use-experience-api';
import { VSpace } from '@/components/spacing';
import { LineItem } from '@/features/editor/widgets/shared/modals/audience/audience-shared';
import { AudienceContext } from '@/features/editor/widgets/shared/modals/audience/audience-context';

export function FacebookLineItem({ disabled }) {
  const { selected: segments, onFacebook } = useContext(AudienceContext);
  const predicate = (s) => s === Segment.FACEBOOK;

  return (
    <LineItem
      key="facebook"
      disabled={disabled}
      selected={!!segments.find(predicate)}
      onClicked={onFacebook}
    >
      <span>Facebook / Instagram Campaign</span>
      <VSpace />
      <span>
        Continue the campaign experience on the website to create a consistent
        and cohesive journey for your visitors
      </span>
    </LineItem>
  );
}
