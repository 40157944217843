import { CustomizationSpec } from '@/webapi/use-widget-catalog-api';
import { VisualEditChangeBuilder } from '@/features/editor/widgets/visual-editor/visual-edit-change-builder';
import { InputType } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';

const TRANSPARENT_IMAGE = `data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7`;

export function handleVisualSpec(
  selector: string,
  spec: CustomizationSpec,
  builder: VisualEditChangeBuilder,
) {
  if (spec.type === InputType.TEXT_EDIT) {
    builder.changeText(
      selector,
      spec.values[`value`],
      spec?.values?.[`textNodeIdx`],
    );
  } else if (spec.type === InputType.CTA) {
    builder.changeText(selector, spec.values[`content`][0][`content`]);
    builder.changeAttribute(
      selector,
      `href`,
      spec.values[`content`][0][`href`],
    );
  } else if (spec.type === InputType.LINK) {
    builder.changeAttribute(selector, `href`, spec.values[`value`]);
  } else if (spec.type === InputType.SIZE) {
    builder.changeStyle(selector, `height`, spec.values[`height`]);
    builder.changeStyle(selector, `width`, spec.values[`width`]);
    if (spec.values[`parent`]) {
      const parentSelector = spec.values[`parent`];
      builder.changeStyle(
        `${parentSelector}::before`,
        `height`,
        spec.values[`height`],
      );
    }
  } else if (spec.type === InputType.TYPOGRAPHY) {
    builder.changeStyle(selector, `color`, spec.values[`color`]);
    builder.changeStyle(selector, `font-size`, spec.values[`fontSize`]);
    builder.changeStyle(selector, `font-weight`, spec.values[`fontWeight`]);
    builder.changeStyle(selector, `font-family`, spec.values[`fontFamily`]);
    builder.changeStyle(
      selector,
      `text-decoration`,
      spec.values[`textDecoration`],
    );

    if (spec.values[`textAlignSelector`]) {
      builder.changeStyle(
        spec.values[`textAlignSelector`],
        `text-align`,
        spec.values[`textAlign`],
      );
    } else {
      builder.changeStyle(selector, `text-align`, spec.values[`textAlign`]);
    }
  } else if (spec.type === InputType.BACKGROUND) {
    if (spec.values[`mode`] === `video`) {
      builder.changeVideo(selector, spec.values[`value`]);
    } else {
      if ((spec.values[`nodeType`] as string)?.includes(`src`)) {
        handleImageElement(selector, spec, builder);
      }
      if ((spec.values[`nodeType`] as string)?.includes(`background`)) {
        handleImageBackground(selector, spec, builder);
      } else {
        builder.changeStyle(selector, `background-color`, spec.values[`value`]);
      }
    }
  } else if (spec.type === InputType.MARGIN) {
    builder.changeStyle(selector, `margin`, spec.values[`value`]);
  } else if (spec.type === InputType.TEXT_SHADOW && spec.values[`enabled`]) {
    builder.changeStyle(selector, `text-shadow`, spec.values[`value`]);
  } else if (spec.type === InputType.BOX_SHADOW && spec.values[`enabled`]) {
    builder.changeStyle(selector, `box-shadow`, spec.values[`value`]);
  } else if (spec.type === InputType.BORDER) {
    builder.changeStyle(selector, `border-radius`, spec.values[`borderRadius`]);
    if (spec.values[`parent`]) {
      builder.changeStyle(
        spec.values[`parent`],
        `border-radius`,
        spec.values[`borderRadius`],
      );
    }
    if (spec.values[`enabled`]) {
      builder.changeStyle(selector, `border`, spec.values[`border`]);
    }
  }
}

function handleImageBackground(
  selector: string,
  spec: CustomizationSpec,
  builder: VisualEditChangeBuilder,
) {
  if (spec.values[`mode`] === `image`) {
    const src = spec.values[`value`] as string;
    builder.changeStyle(selector, `background-image`, src);
  } else if (spec.values[`mode`] === `color`) {
    builder.changeStyle(selector, `background-image`, `none`);
    const value = spec.values[`value`];
    builder.changeStyle(selector, `background-color`, value);
  } else {
    builder.changeStyle(selector, `background-image`, `none`);
    builder.changeStyle(selector, `background`, `none`);
  }
}

function handleImageElement(
  selector: string,
  spec: CustomizationSpec,
  builder: VisualEditChangeBuilder,
) {
  if (spec.values[`mode`] === `image`) {
    const src = (spec.values[`value`] as string)
      .replaceAll(`url("`, ``)
      .replaceAll(`")`, ``);
    builder.changeImage(selector, src);
  } else if (spec.values[`mode`] === `color`) {
    builder.changeImage(TRANSPARENT_IMAGE, selector);
    const value = spec.values[`value`];
    builder.changeStyle(selector, `background-color`, value);
  } else {
    builder.changeImage(selector, TRANSPARENT_IMAGE);
  }
}
