import React from 'react';
import Select, { StylesConfig } from 'react-select';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import { useScheduleExperience } from '@/features/editor/widgets/targeting/use-schedule-experience';
import { ScheduleType } from '@/webapi/use-experience-api';
import { dateToUtcInSec, utcInSecToDate } from '@/features/details/utils';
import { useIsPreviewingDesktop } from '@/features/editor/shared/use-device-type';

export const TimeZones = [
  {
    value: { offset: 14, name: `Pacific/Kiritimati` },
    label: `GMT+14:00 (Line Islands: Kiritibati)`,
  },
  {
    value: { offset: 13, name: `Pacific/Kanton` },
    label: `GMT+13:00 (Rawaki Islands: Enderbury Kiribati)`,
  },
  {
    value: { offset: 12, name: `Pacific/Tarawa` },
    label: `GMT+12:00 (Wellington, Fiji)`,
  },
  {
    value: { offset: 11.5, name: `Pacific/Kosrae` },
    label: `GMT+11:30 (Norfolk Island)`,
  },
  {
    value: { offset: 11, name: `Pacific/Pohnpei` },
    label: `GMT+11:00 (Magadan, Solomon Islands, New Caledonia)`,
  },
  {
    value: { offset: 10.5, name: `Australia/Lord_Howe` },
    label: `GMT+10:30 (Lord Howe Island)`,
  },
  {
    value: { offset: 10, name: `Australia/Sydney` },
    label: `GMT+10:00 (Eastern Australia, Guam, Vladivostok)`,
  },
  {
    value: { offset: 9.5, name: `Australia/Darwin` },
    label: `GMT+9:30 (Adelaide, Darwin)`,
  },
  {
    value: { offset: 9, name: `Asia/Tokyo` },
    label: `GMT+9:00 (Tokyo, Seoul, Osaka, Sapporo, Yakutsk)`,
  },
  {
    value: { offset: 8, name: `Asia/Hong_Kong` },
    label: `GMT+8:00 (Beijing, Perth, Singapore, Hong Kong)`,
  },
  {
    value: { offset: 7, name: `Asia/Bangkok` },
    label: `GMT+7:00 (Bangkok, Hanoi, Jakarta)`,
  },
  {
    value: { offset: 6.5, name: `Indian/` },
    label: `GMT+6:30 (Cocos Islands)`,
  },
  {
    value: { offset: 6, name: `Asia/Dhaka` },
    label: `GMT+6:00 (Almaty, Dhaka, Colombo)`,
  },
  {
    value: { offset: 5.75, name: `Asia/Kathmandu` },
    label: `GMT+5:45 (Kathmandu)`,
  },
  {
    value: { offset: 5.5, name: `Asia/Colombo` },
    label: `GMT+5:30 (Bombay, Calcutta, Madras, New Delhi)`,
  },
  {
    value: { offset: 5, name: `Asia/Karachi` },
    label: `GMT+5:00 (Ekaterinburg, Islamabad, Karachi, Tashkent)`,
  },
  {
    value: { offset: 4.5, name: `Asia/Kabul` },
    label: `GMT+4:30 (Kabul)`,
  },
  {
    value: { offset: 4, name: `Europe/Moscow` },
    label: `GMT+4:00 (Moscow, Abu Dhabi)`,
  },
  {
    value: { offset: 3.5, name: `Asia/Tehran` },
    label: `GMT+3:30 (Tehran)`,
  },
  {
    value: { offset: 3, name: `Asia/Kuwait` },
    label: `GMT+3:00 (Kuwait, Nairobi, Riyadh)`,
  },
  {
    value: { offset: 2, name: `Asia/Jerusalem` },
    label: `GMT+2:00 (Athens, Helsinki, Jerusalem)`,
  },
  {
    value: { offset: 1, name: `Europe/Berlin` },
    label: `GMT+1:00 (Paris, Berlin, Amsterdam, Madrid, Rome)`,
  },
  {
    value: { offset: 0, name: `Europe/London` },
    label: `GMT (London, Dublin, Lisbon)`,
  },
  {
    value: { offset: -1, name: `Atlantic/Azores` },
    label: `GMT-1:00 (Azores)`,
  },
  {
    value: { offset: -2, name: `Atlantic/South_Georgia` },
    label: `GMT-2:00 (Mid-Atlantic)`,
  },
  {
    value: { offset: -3, name: `America/Argentina/Buenos_Aires` },
    label: `GMT-3:00 (Brazil, Buenos Aires, Georgetown)`,
  },
  {
    value: { offset: -3.5, name: `` },
    label: `GMT-3:30 (Newfoundland)`,
  },
  {
    value: { offset: -4, name: `America/Caracas` },
    label: `GMT-4:00 (Caracas, La Paz)`,
  },
  {
    value: { offset: -5, name: `America/New_York` },
    label: `GMT-5:00 (New York, Bogota, Lima)`,
  },
  {
    value: { offset: -6, name: `` },
    label: `GMT-6:00 (Mexico City, Saskatchewan)`,
  },
  {
    value: { offset: -7, name: `America/Mexico_City` },
    label: `GMT-7:00 (Mountain Time)`,
  },
  {
    value: { offset: -8, name: `America/Los_Angeles` },
    label: `GMT-8:00 (Los Angeles)`,
  },
  {
    value: { offset: -9, name: `America/Adak` },
    label: `GMT-9:00 (Alaska)`,
  },
  {
    value: { offset: -10, name: `Pacific/Honolulu` },
    label: `GMT-10:00 (Hawaii)`,
  },
  {
    value: { offset: -11, name: `Pacific/Pago_Pago` },
    label: `GMT-11:00 (Midway Island, Samoa)`,
  },
  {
    value: { offset: -12, name: `Pacific/Kwajalein` },
    label: `GMT-12:00 (Eniwetok, Kwajalein)`,
  },
];
const Days = [
  {
    value: `SUNDAY`,
    label: `SUNDAY`,
  },
  {
    value: `MONDAY`,
    label: `MONDAY`,
  },
  {
    value: `TUESDAY`,
    label: `TUESDAY`,
  },
  {
    value: `WEDNESDAY`,
    label: `WEDNESDAY`,
  },
  {
    value: `THURSDAY`,
    label: `THURSDAY`,
  },
  {
    value: `FRIDAY`,
    label: `FRIDAY`,
  },
  {
    value: `SATURDAY`,
    label: `SATURDAY`,
  },
];

export function DatesAndTimesPicker({ type }: { type: ScheduleType }) {
  const {
    utcStart,
    utcEnd,
    setStartDate,
    setEndDate,
    startTime,
    endTime,
    onStartTimeChange,
    onEndTimeChange,
    weekDay,
    setWeekDay,
    timeZone,
    setTimeZone,
  } = useScheduleExperience({ type });

  return (
    <>
      {type === ScheduleType.SPECIFIC_DATE && (
        <SpecificDate
          utcStart={utcStart}
          utcEnd={utcEnd}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          timeZone={timeZone}
          setTimeZone={setTimeZone}
        />
      )}
      {type === ScheduleType.EVERY_DAY_AT_A_CERTAIN_TIME && (
        <EveryDayAtACertainTime
          endTime={endTime}
          onEndTimeChange={onEndTimeChange}
          onStartTimeChange={onStartTimeChange}
          startTime={startTime}
          timeZone={timeZone}
          setTimeZone={setTimeZone}
        />
      )}
      {type === ScheduleType.EVERY_WEEK_ON_A_CERTAIN_DAY && (
        <EveryWeekAtACertainDay
          endTime={endTime}
          onEndTimeChange={onEndTimeChange}
          onStartTimeChange={onStartTimeChange}
          startTime={startTime}
          weekDay={weekDay}
          setWeekDay={setWeekDay}
          timeZone={timeZone}
          setTimeZone={setTimeZone}
        />
      )}
    </>
  );
}

export function SpecificDate({
  utcStart,
  utcEnd,
  setStartDate,
  setEndDate,
  timeZone,
  setTimeZone,
}) {
  function validStartDate(current) {
    const yesterday = moment().subtract(1, `day`);
    return current.isAfter(yesterday);
  }

  function validEndDate(current) {
    const yesterday = moment().subtract(1, `day`).add(2, `minute`);
    return current.isAfter(yesterday);
  }

  return (
    <>
      <DateTimePicker>
        <PickerLabel>START</PickerLabel>
        {/* @ts-ignore */}
        <Datetime
          value={utcInSecToDate(utcStart)}
          utc
          onChange={(value) => setStartDate(dateToUtcInSec(value))}
          dateFormat="MMMM D, YYYY"
          isValidDate={(currentDate) => validStartDate(currentDate)}
          closeOnSelect
        />
        <EditIcon />
      </DateTimePicker>
      <DateTimePicker>
        <PickerLabel>END</PickerLabel>
        {/* @ts-ignore */}
        <Datetime
          value={utcInSecToDate(utcEnd)}
          utc
          onChange={(value) => setEndDate(dateToUtcInSec(value))}
          dateFormat="MMMM D, YYYY"
          isValidDate={(currentDate) => validEndDate(currentDate)}
          closeOnSelect
        />
        <EditIcon />
      </DateTimePicker>
      <TimeZoneSelect timeZone={timeZone} setTimeZone={setTimeZone} />
    </>
  );
}

export function EveryDayAtACertainTime({
  startTime,
  endTime,
  onStartTimeChange,
  onEndTimeChange,
  timeZone,
  setTimeZone,
}) {
  return (
    <>
      <TimePicker
        label="START"
        selected={startTime}
        onChange={onStartTimeChange}
      />
      <TimePicker label="END" selected={endTime} onChange={onEndTimeChange} />
      <TimeZoneSelect timeZone={timeZone} setTimeZone={setTimeZone} />
    </>
  );
}

export function EveryWeekAtACertainDay({
  startTime,
  endTime,
  onStartTimeChange,
  onEndTimeChange,
  weekDay,
  setWeekDay,
  timeZone,
  setTimeZone,
}) {
  return (
    <>
      <DaySelect weekDay={weekDay} setWeekDay={setWeekDay} />
      <TimePicker
        label="START"
        selected={startTime}
        onChange={onStartTimeChange}
      />
      <TimePicker label="END" selected={endTime} onChange={onEndTimeChange} />
      <TimeZoneSelect timeZone={timeZone} setTimeZone={setTimeZone} />
    </>
  );
}

function TimePicker({
  label,
  selected,
  onChange,
}: {
  label: string;
  selected: any;
  onChange: (date: Date) => void;
}) {
  return (
    <DateTimePicker>
      <PickerLabel>{label}</PickerLabel>
      {/* @ts-ignore */}
      <Datetime
        initialValue={moment(selected)}
        onChange={(date) => onChange(moment(date).toDate())}
        dateFormat={false}
      />
      <EditIcon />
    </DateTimePicker>
  );
}

function DaySelect({ weekDay, setWeekDay }) {
  return (
    <CustomDropDown>
      <PickerTimeZoneLabel>DAY</PickerTimeZoneLabel>
      <Select
        defaultValue={{
          value: weekDay,
          label: weekDay,
        }}
        menuPlacement="top"
        options={Days}
        onChange={(val: any) => setWeekDay(val.value)}
        styles={ScheduleSelectStyle()}
      />
      <DownArrow />
    </CustomDropDown>
  );
}

function TimeZoneSelect({ timeZone, setTimeZone }) {
  const isDesktop = useIsPreviewingDesktop();

  return (
    <CustomDropDown>
      <PickerTimeZoneLabel>TIME ZONE</PickerTimeZoneLabel>
      <Select
        defaultValue={timeZone}
        menuPlacement="top"
        options={TimeZones}
        onChange={(val: any) =>
          setTimeZone({
            label: val.label,
            offset: val.value.offset,
            name: val.value.name,
          })
        }
        styles={ScheduleSelectStyle(isDesktop)}
      />
      <DownArrow />
    </CustomDropDown>
  );
}

function EditIcon() {
  return (
    <StyledIcon>
      <StaticImage
        src="../../../../assets/pencil-edit.svg"
        alt="edit"
        placeholder="none"
        height={15}
        width={15}
        objectFit="contain"
      />
    </StyledIcon>
  );
}

function DownArrow() {
  return (
    <StyledIcon style={{ position: `absolute`, right: `2rem` }}>
      <StaticImage
        src="../../../../assets/arrow-down.svg"
        alt="down"
        width={15}
        objectFit="contain"
        placeholder="none"
      />
    </StyledIcon>
  );
}

const CustomDropDown = styled.div`
  align-items: center;
  display: grid;
  position: relative;
  height: 4.7rem;
  margin: 1rem 0;
  padding: 0rem 2rem;
  border: 1px solid #eaecf1;
  border-radius: 1.2rem;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 15%), 0 6px 7px 0 rgb(151 210 206 / 22%);
  pointer-events: auto;
  cursor: pointer;
`;

const PickerLabel = styled.span`
  color: #97a4b0;
  font-size: 1.1rem;
  font-weight: bold;
`;

const PickerTimeZoneLabel = styled.span`
  color: #97a4b0;
  font-size: 1.1rem;
  font-weight: bold;
  position: absolute;
  left: 2rem;
`;

const DateTimePicker = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  position: relative;
  height: 4.7rem;
  margin: 1rem 0;
  padding: 0rem 2rem;
  border: 1px solid #eaecf1;
  border-radius: 1.2rem;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 15%), 0 6px 7px 0 rgb(151 210 206 / 22%);
  pointer-events: auto;
  cursor: pointer;
  .rdt {
    position: absolute;
    height: 4.7rem;
    padding: 0rem 2rem;
    width: 100%;
    input {
      width: 100%;
      left: 0;
      position: absolute;
      height: 4.7rem;
      padding: 0rem 2rem 0 calc(25% + 1rem);
      border: none;
      z-index: 2;
      background: transparent;
      cursor: pointer;
      font-size: 1.2rem;
      font-family: JetBrains Mono;
      outline: none;
      &:focus-visible {
        border: none;
      }
    }
    .rdtPicker {
      * {
        font-size: 1.2rem !important;
      }
      bottom: 100%;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 15%),
        0 4px 11px 0 rgb(151 210 206 / 22%);
      .rdtTime {
        .rdtSwitch {
          cursor: pointer;
          ::before {
            content: '';
            display: inline-block;
            height: 4px;
            width: 4px;
            border: solid #89959c;
            border-width: 3px 0 0 3px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            border-radius: 1px;
            position: absolute;
            left: 2rem;
            top: 1.6rem;
          }
        }
      }
      td.rdtNew {
        color: unset !important;
      }
    }
    .rdtPicker td.rdtActive {
      background-color: #0086ff;
    }
  }
`;

const StyledIcon = styled.div`
  opacity: 0.3;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 2rem;
  .gatsby_image_wrapper {
    img {
      object-fit: cover;
    }
  }
`;

const ScheduleSelectStyle = (isDesktop?: boolean) =>
  ({
    control: (styles) => ({
      ...styles,
      background: `transparent`,
      outline: `none`,
      border: `none`,
      boxShadow: `none`,
      textAlign: `left`,
      cursor: `pointer`,
      padding: `0 0 0 calc(20% + 3.3rem)`,
      textTransform: `capitalize`,
    }),
    singleValue: () => ({
      color: `#58606c`,
      fontSize: `1.2rem`,
      textOverflow: `ellipsis`,
      overflow: `hidden`,
      whiteSpace: `nowrap`,
      maxWidth: `95%`,
    }),
    menu: (styles) => ({
      ...styles,
      margin: `0`,
      borderTop: `1px solid #dedede`,
      borderLeft: `1px solid #dedede`,
      borderRight: `1px solid #dedede`,
      borderBottom: `1px solid #dedede`,
      outline: `none`,
      padding: `0.7rem 0.7rem`,
      boxShadow: `none`,
      zIndex: 9999,
    }),
    option: (styles, { isSelected }) => ({
      color: isSelected ? `#3451fa` : `#58606c`,
      fontWeight: isSelected ? `700` : `500`,
      fontSize: `1.2rem`,
      padding: `1.2rem 1rem`,
      fontFamily: `JetBrains Mono`,
      textTransform: `capitalize`,
    }),
    indicatorsContainer: () => ({
      display: `none`,
    }),
    valueContainer: () => ({
      padding: `0`,
      alignItems: `center`,
      display: `flex`,
      maxWidth: `75%`,
      '@media only screen and (max-width: 1400px)': {
        maxWidth: `${isDesktop ? `50%` : `75%`}`,
      },
    }),
    container: (styles) => ({
      ...styles,
      width: `calc(100% + 4rem)`,
      position: `relative`,
      left: `-2rem`,
      fontSize: `1.3rem`,
      fontWeight: 500,
      fontFamily: `JetBrains Mono`,
      textTransform: `uppercase`,
      letterSpacing: `-0.44px`,
      cursor: `pointer`,
    }),
  } as StylesConfig);
