import { PlacementKind, PlacementKind as K } from '@/webapi/use-experience-api';
import {
  LoadingStrategyOption,
  RecommendationType as T,
  RecStrategy,
} from '@/webapi/use-widget-catalog-api';

export function filterRecommendations(
  placementKind: PlacementKind | undefined,
  recStrategies: RecStrategy,
  isNewRecAlgsEnabled: boolean,
) {
  const options = recStrategies?.options?.filter((op) => {
    if ([K.HOMEPAGE, K.ALL_CATEGORIES].find(eqPlacement(placementKind))) {
      const strategies = [
        T.MANUAL,
        T.MOST_POPULAR,
        T.NEW_ARRIVALS,
        T.CART_ITEMS,
        T.PERSONALIZED,
        T.RECENTLY_VIEWED,
        T.PURCHASED_TOGETHER,
        T.VIEWD_TOGETHER,
        T.MOST_POPULAR,
        T.PERSONALIZED,
        T.ADVANCED_RULING,
        T.PAST_PURCHASES,
      ];
      if (isNewRecAlgsEnabled) {
        strategies.push(T.VIEWED_WITH_RECENTLY_VIEWED);
        strategies.push(T.PURCHASED_WITH_RECENTLY_PURCHASED);
      }
      return !!strategies.find(eqReq(op));
    }

    if ([K.ALL_PAGES, K.OTHER].find(eqPlacement(placementKind))) {
      const strategies = [
        T.MANUAL,
        T.MOST_POPULAR,
        T.NEW_ARRIVALS,
        T.MOST_POPULAR,
        T.CART_ITEMS,
        T.PERSONALIZED,
        T.ADVANCED_RULING,
        T.PAST_PURCHASES,
      ];
      if (isNewRecAlgsEnabled) {
        strategies.push(T.VIEWED_WITH_RECENTLY_VIEWED);
        strategies.push(T.PURCHASED_WITH_RECENTLY_PURCHASED);
      }
      return !!strategies.find(eqReq(op));
    }
    if (placementKind === K.ALL_PRODUCTS) {
      const strategies = [
        T.MANUAL,
        T.MOST_POPULAR,
        T.NEW_ARRIVALS,
        T.CART_ITEMS,
        T.VIEWD_TOGETHER,
        T.RECENTLY_VIEWED,
        T.PURCHASED_TOGETHER,
        T.PERSONALIZED,
        T.ADVANCED_RULING,
        T.PAST_PURCHASES,
      ];
      if (isNewRecAlgsEnabled) {
        strategies.push(T.VIEWED_WITH_RECENTLY_VIEWED);
        strategies.push(T.PURCHASED_WITH_RECENTLY_PURCHASED);
      }
      return !!strategies.find(eqReq(op));
    }
    if ([K.CART].find(eqPlacement(placementKind))) {
      const strategies = [
        T.MANUAL,
        T.RECENTLY_VIEWED,
        T.PURCHASED_TOGETHER,
        T.VIEWD_TOGETHER,
        T.MOST_POPULAR,
        T.PERSONALIZED,
        T.ADVANCED_RULING,
        T.PAST_PURCHASES,
      ];
      if (isNewRecAlgsEnabled) {
        strategies.push(T.VIEWED_WITH_RECENTLY_VIEWED);
        strategies.push(T.PURCHASED_WITH_RECENTLY_PURCHASED);
      }
      return !!strategies.find(eqReq(op));
    }

    if ([K.CHECKOUT].find(eqPlacement(placementKind))) {
      const strategies = [
        T.MANUAL,
        T.MOST_POPULAR,
        T.NEW_ARRIVALS,
        T.PERSONALIZED,
        T.RECENTLY_VIEWED,
        T.PURCHASED_TOGETHER,
        T.VIEWD_TOGETHER,
        T.ADVANCED_RULING,
        T.PAST_PURCHASES,
      ];
      return !!strategies.find(eqReq(op));
    }
    return false;
  });
  return { ...recStrategies, options };
}

function eqReq(op: LoadingStrategyOption) {
  return (x) => x === op.value.type;
}

function eqPlacement(placementKind: K) {
  return (x) => x === placementKind;
}
