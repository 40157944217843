import { EditorDeclarativeBlock } from '@/webapi/use-experience-api';
import { WidgetChange } from '@/pkg/sdk';

export const ORIGINAL_EXP_NAME = `originalExperienceName`;
export const ORIGINAL_EXP_ID = `originalExperienceId`;

export function getOriginExperienceName(change: EditorDeclarativeBlock) {
  // For Legacy experiences that saved the original exp name in the aliases array
  if (change.block?.[`aliases`]?.[ORIGINAL_EXP_NAME]) {
    return change.block?.[`aliases`]?.[ORIGINAL_EXP_NAME];
  }
  return (change.block.value as WidgetChange)?.env?.[
    ORIGINAL_EXP_NAME
  ] as string;
}

export function getOriginExperienceId(change: EditorDeclarativeBlock) {
  return (change.block.value as WidgetChange)?.env?.[ORIGINAL_EXP_ID] as string;
}
