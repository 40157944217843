import * as React from 'react';
import styled from 'styled-components';
import {
  MAX_SELECTED_GOALS,
  useGoalsModal,
} from '@/features/editor/widgets/shared/modals/use-goals-modal';
import { Footer } from '@/features/editor/widgets/shared/modals/commons';
import { GradientModal } from '@/components/gradient-modal';
import { CardButton, TextualCardLayout } from '@/components/card-button';
import { MetricKind } from '@/webapi/models';

export interface GoalsModalProps {
  fromRef: React.MutableRefObject<HTMLElement>;
  isVisible: boolean;
  setIsVisible: (state: boolean) => void;
}

export function GoalsModal({
  fromRef,
  isVisible,
  setIsVisible,
}: GoalsModalProps) {
  const { selected, onSelection, onSave, toRect, allGoals } =
    useGoalsModal(setIsVisible);

  return (
    <GradientModal
      closeTop="0.5rem"
      closeRight="2.5rem"
      fromStyle={{
        all: `unset`,
        borderRadius: `5rem`,
        backgroundColor: `#DEDEDE`,
        padding: `2rem 0 0 0`,
      }}
      toStyle={{
        all: `unset`,
        borderRadius: `1rem`,
        backgroundColor: `#FFFFFF`,
        padding: `2rem 0 0 0`,
      }}
      isVisible={isVisible}
      showBackdrop
      targetPosAndSize={toRect}
      fromRef={fromRef}
      overflowTop={{ heightInRem: 8 }}
      overflowBottom={{ heightInRem: 8, turnPointInPercent: 30 }}
      onClose={() => setIsVisible(false)}
      header={<span>Please Select Up to {MAX_SELECTED_GOALS} Goals</span>}
      footer={<Footer onClick={onSave} />}
    >
      <Container>
        <Grid>
          {allGoals.map((goal) => (
            <LineItem
              key={goal?.kind}
              selected={selected?.includes(goal?.kind)}
              onSelection={onSelection}
              goalKind={goal.kind}
            >
              <span>{goal.name}</span>
              <span>{goal.description}</span>
            </LineItem>
          ))}
        </Grid>
      </Container>
    </GradientModal>
  );
}

const Container = styled.div`
  margin: 2rem 1.5rem;
  text-transform: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  text-align: center;
  color: #9ba7b3;
  letter-spacing: -0.38px;
  font-family: Inter, serif;
  font-weight: 500;
  font-size: 1.4rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-gap: 2.5rem;
`;

const LineItem = ({
  children,
  goalKind,
  selected,
  onSelection,
}: {
  goalKind: MetricKind;
  children: any;
  selected: boolean;
  onSelection: (value: boolean, goalKind: MetricKind) => void;
}) => (
  <CardButton
    height="11rem"
    borderRadius="1.5rem"
    onClicked={(_, value) => onSelection(value, goalKind)}
    selected={selected}
    canBeSelected
  >
    <TextualCardLayout
      verticalGap="0.5rem"
      padding="0 1.5rem"
      primaryColor={selected ? `#003CED` : `#576470`}
      accentColor={selected ? `#003CED` : `#758390`}
      primaryFontSize="1.4rem"
      accentFontSize="1.1rem"
      accentFontFamily="Inter"
      accentFontWeight="500"
    >
      {children}
    </TextualCardLayout>
  </CardButton>
);
