import { useContext, useMemo } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';
import { StylingComponentsGroup } from '@/features/editor/widgets/post-purchase/styling/group';
import { PostPurchaseTextualInput } from '@/features/editor/widgets/post-purchase/inputs/textual';
import { PostPurchaseTextualAreaInput } from '@/features/editor/widgets/post-purchase/inputs/textual-area';
import { QuickEditTitle } from '@/features/editor/widgets/custom-widget/inputs/shared/radio-buttons';

export function PostPurchaseProductCallout() {
  const { props, setProps } = useContext(PostPurchaseContext);
  const offerCount = useMemo(() => props.settings.offersCount, [props]);
  return (
    <>
      <StylingComponentsGroup title="Callout Content">
        <TextEditSection>
          <QuickEditTitle small>1st Offer Callout</QuickEditTitle>
          <PostPurchaseTextualAreaInput
            defaultValue={props.content.productCallout1}
            onChange={(p) =>
              setProps((draft) => {
                draft.content.productCallout1 = p;
              })
            }
          />
        </TextEditSection>

        {offerCount > 1 && (
          <TextEditSection>
            <QuickEditTitle small>2nd Offer Callout</QuickEditTitle>
            <PostPurchaseTextualAreaInput
              defaultValue={props.content.productCallout2}
              onChange={(p) =>
                setProps((draft) => {
                  draft.content.productCallout2 = p;
                })
              }
            />
          </TextEditSection>
        )}
      </StylingComponentsGroup>

      <StylingComponentsGroup title="Callout Style">
        <PostPurchaseTextualInput
          showAlignment
          defaultValue={props?.style?.callout}
          onChange={(p) =>
            setProps((draft) => {
              draft.style.callout = p;
            })
          }
        />
      </StylingComponentsGroup>
    </>
  );
}

const TextEditSection = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 1rem;
`;
