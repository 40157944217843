import React, { useContext } from 'react';
import styled from 'styled-components';
import { RuleState } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import { maybe } from '@/features/details/utils';
import { currencies } from '@/utils/currencies';
import { VSpace } from '@/components/spacing';
import { QBItemSelection } from '@/components/query-builder/models';
import { RecommendationType } from '@/webapi/use-widget-catalog-api';
import { EditorContext } from '@/features/editor/context/editor-context';
import { hashObject } from '@/components/hooks/use-cached-auto-save';

function getSlots(rule: RuleState) {
  return rule?.strategyPerSlot?.slots
    ?.map((s) => {
      if (!s.strategy) {
        return undefined;
      }
      if (s.strategy === RecommendationType.MANUAL) {
        return s.manualProduct?.title;
      }
      return s.strategy?.replace?.(`_`, ` `) || ``;
    })
    .filter((x) => typeof x !== `undefined`);
}

function ProductsListDescription({ rule }: { rule: RuleState }) {
  if (!rule.strategyPerSlot) {
    return null;
  }
  return (
    <>
      <VSpace />
      <ProductListWrap>{getSlots(rule).join(`, `)}</ProductListWrap>
    </>
  );
}

function ForMessage({
  itemSelection,
}: {
  itemSelection: 'some_items' | 'all_items' | 'all_variants' | string;
}) {
  if (!itemSelection) {
    return null;
  }
  return (
    <>
      {` `}FOR{` `}
      <Strong>
        {` `}
        {itemSelection.replace(`_`, ` `)} IN THE CART{` `}
      </Strong>
    </>
  );
}

function ItemAsText({
  item,
  isFirst,
}: {
  item: QBItemSelection;
  isFirst: boolean;
}) {
  const { resources } = useContext(EditorContext);
  const isCartPrice = item.qbProps.envKey === `cartPrice`;
  const isProductSeen = item.qbProps.envKey === `product_seen`;

  function getCurrency(v: { op: string; value: string; currency?: string }) {
    return maybe(() => `${currencies[v.currency || `USD`]}` || `$`, `$`);
  }

  function psik(idx: number) {
    return idx < item.values.length - 1 ? `,` : ``;
  }
  const prefix = (
    <>
      {isFirst ? <span style={{ marginRight: `1rem` }}>IF</span> : ``}
      {!isFirst ? <span style={{ marginRight: `1rem` }}>AND </span> : ``}
    </>
  );
  if (isCartPrice) {
    return (
      <FlexP>
        {prefix}
        <Strong style={{ marginRight: `1rem` }}>{item.qbProps.caption}</Strong>
        <Ellipsis>
          {item.values.map((v, idx) => (
            <span>{` ${v.op} ${getCurrency(v)}${v.value}${psik(idx)} `}</span>
          ))}
        </Ellipsis>
      </FlexP>
    );
  }

  function getTitle(value: any) {
    return resources.products?.find((p) => p.id === value)?.title;
  }

  return (
    <FlexP>
      {prefix}
      <Strong style={{ marginRight: `1rem` }}>{item.qbProps.caption}</Strong>
      <Ellipsis>
        {item.values.map((v, idx) => (
          <span>{` ${v.op} ${isProductSeen ? getTitle(v.value) : v.value}${psik(
            idx,
          )} `}</span>
        ))}
      </Ellipsis>
    </FlexP>
  );
}
const FlexP = styled.p`
  display: flex;
`;
const Ellipsis = styled.span`
  text-align: center;
  vertical-align: center;
  display: inline-block;
  max-width: 630px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export function Title({ rule }: { rule: RuleState }) {
  if (rule.isOpen) {
    return <>{getRuleName(rule.id)}</>;
  }
  if (!rule.isOpen && !rule.strategy) {
    return <>NO RULE HAS BEEN SET</>;
  }
  const item = maybe(() => rule.ruleCond.items[0]);
  if (!item) {
    return <>{getRuleName(rule.id)}</>;
  }

  return (
    <Collapsed>
      <span>
        {rule.ruleCond.items.map((item, idx) => (
          <ItemAsText key={hashObject(item)} item={item} isFirst={idx === 0} />
        ))}
      </span>
      <span>
        <ForMessage itemSelection={rule.ruleCond.itemSelection} />
        THEN SHOW{` `}
        <Strong>{getThenMessage(rule)}</Strong>
      </span>
      <ProductsListDescription rule={rule} />
    </Collapsed>
  );
}

const Strong = styled.span`
  color: #576470;
  text-transform: uppercase;
`;

const ProductListWrap = styled.span`
  color: #576470;
  text-transform: uppercase;
  max-width: 90rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Collapsed = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'JetBrains Mono', serif;
`;

function getStrategyName(rule: RuleState) {
  if (rule.strategy === `MANUAL`) {
    return `VARY STRATEGY/PRODUCT PER SLOT`;
  }

  return rule.strategy.replace(`_`, ` `);
}

function getThenMessage(rule: RuleState) {
  if (!rule.strategyCond) {
    return (
      <>
        {` `}
        {getStrategyName(rule)}
        {` `}
      </>
    );
  }
  const strategyCondLen = rule.strategyCond.length;
  return (
    <>
      {` `}
      {getStrategyName(rule)}
      {` `}
      {strategyCondLen > 1 ? `(${strategyCondLen} filters applied)` : ``}
    </>
  );
}

export function getRuleName(number) {
  if (number > 20) {
    return `Rule ${number}`;
  }

  const rules = [
    `First Rule`,
    `Second Rule`,
    `Third Rule`,
    `Fourth Rule`,
    `Fifth Rule`,
    `Sixth Rule`,
    `Seventh Rule`,
    `Eighth Rule`,
    `Ninth Rule`,
    `Tenth Rule`,
    `Eleventh Rule`,
    `Twelfth Rule`,
    `Thirteenth Rule`,
    `Fourteenth Rule`,
    `Fifteenth Rule`,
    `Sixteenth Rule`,
    `Seventeenth Rule`,
    `Eighteenth Rule`,
    `Nineteenth Rule`,
    `Twentieth Rule`,
  ];

  return rules[number];
}
