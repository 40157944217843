import React, { useContext } from 'react';
import styled from 'styled-components';
import { Slot } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/Slot';
import { AddAnother } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/AddAnother';
import { BigButton } from '@/components/big-button';
import { ManualProductsPicker } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/context';
import {
  ManualProduct,
  RecommendationOptions,
} from '@/webapi/use-widget-catalog-api';
import { OverflowBottom, OverflowTop } from '@/components/overflow';

type Props = {
  hide: () => void;
  updateSelection: (options: RecommendationOptions) => void;
  noTopOverFlow?: boolean;
  noSaveBtn?: boolean;
};

export function Slots({
  hide,
  updateSelection,
  noTopOverFlow,
  noSaveBtn,
}: Props) {
  const { slots, setSlots } = useContext(ManualProductsPicker);
  const addSlot = () => {
    const next = slots.concat([{ id: slots.length + 1 }]);
    setSlots(next);
  };
  const onSave = () => {
    updateSelection({
      manualProducts: slots
        .filter((x) => !!x.product)
        .map(({ product }) => {
          const ret = {
            productId: `${product?.id}`,
            mainValue: `${product?.mainValue || ``}`,
          } as ManualProduct;
          if (product?.imageOverride) {
            ret.imageOverride = product?.image?.src;
          }
          return ret;
        }),
    } as unknown as RecommendationOptions);
    hide();
  };
  return (
    <Wrapper>
      {!noTopOverFlow && <OverflowTop height="18rem" />}
      <SlotsGrid noTopOverFlow={noTopOverFlow}>
        {slots.map((x) => (
          <Slot key={x.id} data={x} />
        ))}
        <AddAnother addSlot={addSlot} />
      </SlotsGrid>
      {!noSaveBtn && (
        <FloatingSection>
          <SaveBtn
            onClick={onSave}
            type="button"
            border="2px solid black"
            noTransform
            size="xl"
            fillWidth
          >
            Save
          </SaveBtn>
        </FloatingSection>
      )}
      <OverflowBottom height="8rem" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  max-height: 75rem;
  position: relative;
`;

const FloatingSection = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2rem;
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 10000;
`;

const SaveBtn = styled(BigButton)`
  width: 300px;
  margin: 0 auto;
`;

const SlotsGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto-fill, 188.5px);
  grid-row-gap: 3em;
  grid-column-gap: 3em;
  max-width: 830px;
  max-height: 75rem;
  height: 75rem;
  padding-top: ${(p: { noTopOverFlow?: boolean }) =>
    p.noTopOverFlow ? 0 : `13rem`};
  padding-bottom: 25rem;
  overflow-y: scroll;
  scrollbar-width: none;
`;
