import * as React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import vImage from '@/assets/v_indicator.svg';

export interface CardButtonProps extends CardButtonOptions {
  onClicked?: (ev?: any, selected?: boolean) => void;
  children?: any;
}

export interface CardButtonOptions {
  isDisabled?: boolean;
  isLoading?: boolean;

  canBeSelected?: boolean;
  selected?: boolean;

  padding?: string;
  borderColor?: string;
  borderRadius?: string;
  boxShadow?: string;
  background?: string;
  hoverBackground?: string;

  selectedColor?: string;
  selectedBadgeSize?: string;

  height?: string;
  width?: string;

  wrapperRef?: React.MutableRefObject<HTMLElement>;
}

export function CardButton(props: CardButtonProps) {
  const {
    selected,
    children,
    onClicked,
    canBeSelected,
    isDisabled,
    isLoading,
    selectedBadgeSize,
    wrapperRef,
  } = props;
  const opts = props as CardButtonProps;

  const onClicked2 = (ev: any) => {
    onClicked && onClicked(ev, selected);
  };

  return (
    <Wrapper ref={wrapperRef} onClick={onClicked2} {...opts}>
      {children}
      {selected && canBeSelected && !isDisabled && (
        <SelectedBadge selectedBadgeSize={selectedBadgeSize} />
      )}
      {isLoading && !isDisabled && <LoadingState />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;

  height: ${(p: CardButtonOptions) => p?.height || `100%`};
  width: ${(p: CardButtonOptions) => p?.width || `100%`};
  padding: ${(p: CardButtonOptions) => p?.padding || `2rem`};
  border: 1px solid
    ${(p: CardButtonOptions) =>
      p?.canBeSelected && !p?.isDisabled && p?.selected
        ? p?.selectedColor || `#003CED`
        : p?.borderColor || `#eaecf1`};

  border-radius: ${(p: CardButtonOptions) => p?.borderRadius || `1.8rem`};
  background-color: ${(p: CardButtonOptions) => p?.background || `white`};
  box-shadow: ${(p: CardButtonOptions) =>
    p?.boxShadow ||
    `0 1px 1px 0 rgba(0, 0, 0, 0.15),
    0 6px 7px 0 rgba(151, 210, 206, 0.22)`};

  filter: ${(p: CardButtonOptions) =>
    p?.isDisabled ? `grayscale(90%)` : `none`};
  opacity: ${(p: CardButtonOptions) => (p?.isDisabled ? `0.5` : `1`)};

  pointer-events: ${(p: CardButtonOptions) =>
    p?.canBeSelected && !p?.isDisabled ? `auto` : `none`};

  cursor: ${(p: CardButtonOptions) =>
    p?.canBeSelected && !p?.isDisabled ? `pointer` : `default`};

  // Mimic outline for the sake of safari that doesn't use the border radius on outline:
  :before {
    content: '';
    position: absolute;
    pointer-events: none;
    inset: -2px;
    border: 3px solid
      ${(p: CardButtonOptions) =>
        p?.canBeSelected && !p?.isDisabled && p?.selected
          ? p?.selectedColor || `#003CED`
          : `transparent`};
    border-radius: ${(p: CardButtonOptions) => p?.borderRadius || `1.8rem`};
  }

  * {
    user-select: none;
  }

  :hover {
    background-color: ${(p: CardButtonOptions) =>
      p?.hoverBackground || `#fcfbfb`};
  }

  :active {
    opacity: 0.8;
  }

  transition: background-color 500ms ease-out, opacity 500ms ease-in;
`;

const LoadingState = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  backdrop-filter: blur(4px);
  z-index: 10000;

  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    rgba(218, 216, 216, 0.29);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 3s lmi-shine ease-in-out infinite;

  @keyframes lmi-shine {
    to {
      background-position-x: -20%;
    }
  }
`;

const SelectedBadge = styled.span`
  position: absolute;
  bottom: -1.2rem;
  right: -1.2rem;
  content: '';
  background: url(${vImage});
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 1rem;
  height: ${(p: { selectedBadgeSize?: string }) =>
    p?.selectedBadgeSize || `3rem`};
  width: ${(p: { selectedBadgeSize?: string }) =>
    p?.selectedBadgeSize || `3rem`};
`;

export interface CardLayoutProps {
  padding?: string;

  height?: string;
  width?: string;

  verticalGap?: string;
  verticalDistribution?: string;
  alignItems?: string;
  justifyContent?: string;

  primaryColor?: string;
  primaryFontFamily?: string;
  primaryFontSize?: string;
  primaryFontWeight?: string;
  accentColor?: string;
  accentFontFamily?: string;
  accentFontSize?: string;
  accentFontWeight?: string;

  textAlign?: string;
}

export interface MixedCardLayoutProps
  extends CardLayoutProps,
    MixedCardLayoutOptions {
  content?: any;
  children?: any;
}

export interface MixedCardLayoutOptions {
  horizontalGap?: string;
  horizontalDistribution?: string;
}

export function MixedCardLayout(props: MixedCardLayoutProps) {
  const { verticalGap } = props;
  const { content, children, ...opts } = props;
  return (
    <MixedCardLayoutWrapper className="mixed-card-layout" {...opts}>
      <div>{content}</div>
      <TextualCardLayout
        {...opts}
        padding="0"
        height="auto"
        verticalGap={verticalGap || `2rem`}
        alignItems="center"
        justifyContent="flex-start"
        verticalDistribution="max-content max-content"
      >
        {children}
      </TextualCardLayout>
    </MixedCardLayoutWrapper>
  );
}

export const TextualCardLayout = styled.div`
  height: ${(p: CardLayoutProps) => p?.height || `100%`};
  width: ${(p: CardLayoutProps) => p?.width || `100%`};
  line-height: 1.4rem;
  padding: ${(p: CardLayoutProps) => p?.padding || `1rem 2rem`};
  display: grid;
  grid-gap: ${(p: CardLayoutProps) => p?.verticalGap || `1rem`};
  grid-template-rows: ${(p: CardLayoutProps) =>
    p?.verticalDistribution || `auto auto`};
  align-items: ${(p: CardLayoutProps) => p?.alignItems || `center`};
  align-content: ${(p: CardLayoutProps) => p?.alignItems || `center`};
  justify-content: ${(p: CardLayoutProps) => p?.justifyContent || `flex-start`};
  justify-items: ${(p: CardLayoutProps) => p?.justifyContent || `flex-start`};
  text-align: ${(p: CardLayoutProps) => p?.textAlign || `start`};
  align-self: center;

  && > :first-child {
    font-family: ${(p: CardLayoutProps) =>
        `'${p?.primaryFontFamily || `Inter`}'`},
      serif;
    font-size: ${(p: CardLayoutProps) => p?.primaryFontSize || `1.8rem`};
    font-weight: ${(p: CardLayoutProps) => p?.primaryFontWeight || `bold`};
    color: ${(p: CardLayoutProps) => p?.primaryColor || `#576470`};
  }

  && > :not(:first-child) {
    font-family: ${(p: CardLayoutProps) =>
        `'${p?.accentFontFamily || `Inter`}'`},
      serif;
    font-size: ${(p: CardLayoutProps) => p?.accentFontSize || `1.4rem`};
    font-weight: ${(p: CardLayoutProps) => p?.accentFontWeight || `500`};
    color: ${(p: CardLayoutProps) => p?.accentColor || `#758390`};
  }

  transition: color 500ms ease-out;

  && > * {
    transition: color 500ms ease-out;
  }
`;

const MixedCardLayoutWrapper = styled.div`
  user-select: none;
  height: 100%;
  width: 100%;
  display: grid;
  grid-gap: ${(p: MixedCardLayoutOptions) => p?.horizontalGap || `3rem`};
  grid-template-columns: ${(p: MixedCardLayoutOptions) =>
    p?.horizontalDistribution || `10rem 1fr`};
  align-items: ${(p: CardLayoutProps) => p?.alignItems || `center`};
  justify-content: ${(p: CardLayoutProps) => p?.justifyContent || `flex-start`};
  justify-items: ${(p: CardLayoutProps) => p?.justifyContent || `flex-start`};

  transition: color 500ms ease-out;

  && > * {
    transition: color 500ms ease-out;
  }
`;
