import * as React from 'react';
import styled from 'styled-components';
import { Footer } from '@/features/editor/widgets/shared/modals/commons';
import { GradientModal } from '@/components/gradient-modal';
import { useDevicesModal } from '@/features/editor/widgets/shared/modals/use-devices-modal';
import { TargetingDeviceType } from '@/webapi/use-experience-api';
import { CardButton, MixedCardLayout } from '@/components/card-button';
import { PageImage } from '@/components/page-image/page-image';

export interface DevicesModalProps {
  fromRef: React.MutableRefObject<HTMLElement>;
  isVisible: boolean;
  setIsVisible: (state: boolean) => void;
}

export function DevicesModal({
  fromRef,
  isVisible,
  setIsVisible,
}: DevicesModalProps) {
  const { onSave, onSelection, selected, toRect, alias } =
    useDevicesModal(setIsVisible);

  return (
    <GradientModal
      closeTop="0.5rem"
      closeRight="2.5rem"
      fromStyle={{
        borderRadius: `5rem`,
        backgroundColor: `#DEDEDE`,
        padding: `2rem 0 0 0`,
      }}
      toStyle={{
        borderRadius: `1rem`,
        backgroundColor: `#FFFFFF`,
        padding: `2rem 0 0 0`,
      }}
      isVisible={isVisible}
      showBackdrop
      targetPosAndSize={toRect}
      fromRef={fromRef}
      overflowTop={{ heightInRem: 8 }}
      overflowBottom={{ heightInRem: 8, turnPointInPercent: 30 }}
      onClose={() => setIsVisible(false)}
      header={
        <span>
          On which devices do you want <br /> your experience to be displayed?
        </span>
      }
      footer={<Footer onClick={onSave} />}
    >
      <Container>
        <Grid>
          <LineItem
            name="Mobile Only"
            description="Only mobile devices will get your experience"
            alias={alias}
            device={TargetingDeviceType.MOBILE}
            selected={selected}
            onSelection={onSelection}
          />

          <LineItem
            name="Desktop Only"
            description="Only desktop devices will get your experience"
            alias={alias}
            device={TargetingDeviceType.DESKTOP}
            selected={selected}
            onSelection={onSelection}
          />

          <LineItem
            name="All Devices"
            description="Any kind of device will get your experience"
            alias={alias}
            device={TargetingDeviceType.ALL_DEVICES}
            selected={selected}
            onSelection={onSelection}
          />
        </Grid>
      </Container>
    </GradientModal>
  );
}

const LineItem = ({
  name,
  description,
  alias,
  device,
  selected,
  onSelection,
}: {
  alias: string;
  name: string;
  description: string;
  device: TargetingDeviceType;
  selected: TargetingDeviceType;
  onSelection: (value: boolean, device: TargetingDeviceType) => void;
}) => {
  const isSelected = selected === device;
  return (
    <CardButton
      key={device}
      selected={isSelected}
      height="12.2rem"
      borderRadius="1.5rem"
      canBeSelected
      padding="0.9rem 1rem"
      onClicked={(_, value) => onSelection(value, device)}
    >
      <MixedCardLayout
        verticalGap="1rem"
        horizontalDistribution="6rem 1fr"
        padding="0 1.5rem"
        primaryColor={isSelected ? `#003CED` : `#576470`}
        accentColor={isSelected ? `#003CED` : `#758390`}
        primaryFontSize="1.4rem"
        accentFontSize="1.1rem"
        accentFontFamily="Inter"
        accentFontWeight="500"
        content={<PageImage alias={alias} page="home" device={device} />}
      >
        <span>{name}</span>
        <span>{description}</span>
      </MixedCardLayout>
    </CardButton>
  );
};

export const Container = styled.div`
  margin: 2rem 1.5rem;
  text-transform: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  text-align: center;
  color: #9ba7b3;
  letter-spacing: -0.38px;
  font-family: Inter, serif;
  font-weight: 500;
  font-size: 1.4rem;
`;

const Grid = styled.div`
  margin-top: 2.8rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-gap: 2.5rem;
`;
