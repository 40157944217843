import * as React from 'react';
import styled from 'styled-components';
import { useContext, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import {
  newPostPurchaseContext,
  PostPurchaseContext,
  PostPurchaseStep,
} from '@/features/editor/widgets/post-purchase/context';
import { conditional } from '@/utils/conditional';
import { PostPurchaseCustomize } from '@/features/editor/widgets/post-purchase/customize-step';
import { PostPurchaseStyleStep } from '@/features/editor/widgets/post-purchase/style-step';
import { ExperienceSpinner } from '@/components/experience-spinner';
import { PostPurchaseReviewStep } from '@/features/editor/widgets/post-purchase/review-step';
import { EDITOR_POST_PURCHASE_CACHE } from '@/components/hooks/use-cached-auto-save';
import { EditorContext } from '@/features/editor/context/editor-context';
import { PostPurchaseProps } from '@/features/editor/widgets/post-purchase/props';

export function PostPurchaseWidget() {
  const {
    experienceState: { currentExperience },
  } = useContext(EditorContext);

  const [cache, setCache] = useState<PostPurchaseProps | undefined | null>(
    null,
  );

  useAsyncEffect(async () => {
    const cachedValue = await EDITOR_POST_PURCHASE_CACHE.get(
      currentExperience.id,
    );

    setCache(cachedValue);
  }, []);

  if (cache === null) {
    return <div />;
  }

  return <PostPurchaseWidgetLoaded cachedValue={cache} />;
}

function PostPurchaseWidgetLoaded({
  cachedValue,
}: {
  cachedValue: PostPurchaseProps | undefined;
}) {
  const ctx = newPostPurchaseContext(cachedValue);
  const { props, currentStep } = ctx;

  return (
    <PostPurchaseContext.Provider value={ctx}>
      {conditional(
        <Loader />,
        props?.offers?.length > 0 && currentStep === PostPurchaseStep.CUSTOMIZE,
        <PostPurchaseCustomize />,
        props?.offers?.length > 0 && currentStep === PostPurchaseStep.STYLE,
        <PostPurchaseStyleStep />,
        props?.offers?.length > 0 && currentStep === PostPurchaseStep.REVIEW,
        <PostPurchaseReviewStep />,
      )}
    </PostPurchaseContext.Provider>
  );
}

export function Loader() {
  return (
    <LoaderWrapper>
      <ExperienceSpinner color="#A9B5C2" size={4} thickness={0.5} />
    </LoaderWrapper>
  );
}

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  width: 100%;

  && > * {
    align-self: center;
    justify-self: center;
  }
`;
