import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { animated, useSpring, useSpringRef } from '@react-spring/web';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';

const VALUE_KEY = `textAlign`;

export function TextAlignInput({
  initialValues,
  onValuesChanged,
}: GenericInputProps) {
  const { undoRedoCount } = useContext(UndoRedoCounterContext);
  const getValue = useCallback(() => initialValues(VALUE_KEY), [undoRedoCount]);
  const initialized = useRef(false);

  const [value, setValue] = useState(getValue());
  useEffect(() => setValue(getValue()), [undoRedoCount]);

  const moveAnimRef = useSpringRef();
  const moveAnim = useSpring({
    from: { leftPos: 9.5 },
    to: { leftPos: 9.5 },
    ref: moveAnimRef,
  });

  const interpolation = (leftPos: number): string => `calc(${leftPos}% - 1rem)`;

  const valueToPosition = () => {
    if (value === `center`) moveAnimRef.start({ leftPos: 41 });
    else if (value === `right`) moveAnimRef({ leftPos: 72 });
    else moveAnimRef({ leftPos: 9.5 });
  };

  const onChange = (val) => {
    setValue(val);
    valueToPosition();
  };

  useEffect(() => {
    if (value === `center`) moveAnimRef({ leftPos: 41, immediate: true });
    else if (value === `right`) moveAnimRef({ leftPos: 72, immediate: true });
    else moveAnimRef({ leftPos: 9.5, immediate: true });
    initialized.current = true;
  }, []);

  useEffect(() => {
    if (initialized.current) valueToPosition();
    onValuesChanged(VALUE_KEY, value);
  }, [value]);

  return (
    <Wrapper>
      <SelectedBlock style={{ left: moveAnim.leftPos.to(interpolation) }} />
      <Block onClick={() => onChange(`left`)}>
        <AlignLeftIcon />
      </Block>
      <Block onClick={() => onChange(`center`)}>
        <AlignCenterIcon />
      </Block>
      <Block onClick={() => onChange(`right`)}>
        <AlignRightIcon />
      </Block>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  background: rgba(146, 162, 175, 0.12);
  height: 100%;
  width: 100%;

  padding: 0 1rem;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  overflow: hidden;
  z-index: 0;
`;

const SelectedBlock = styled(animated.div)`
  && {
    position: absolute;
    background: white;
    height: 80%;
    border-radius: 10px;
    z-index: -2;
    box-shadow: 0 9px 13px 0 rgba(177, 217, 203, 0.18);

    width: calc(33% - 2rem);
  }
`;

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;

  cursor: pointer;

  .gatsby-image-wrapper {
    height: 1.7rem;
  }
`;

const AlignLeftIcon = () => (
  <StaticImage
    src="../../../../../../assets/align_left.svg"
    alt="align text left"
    height={30}
    placeholder="none"
    loading="eager"
    objectFit="contain"
  />
);

const AlignCenterIcon = () => (
  <StaticImage
    src="../../../../../../assets/align_center.svg"
    alt="align text center"
    height={30}
    placeholder="none"
    loading="eager"
    objectFit="contain"
  />
);

const AlignRightIcon = () => (
  <StaticImage
    src="../../../../../../assets/align_right.svg"
    alt="align text right"
    height={30}
    placeholder="none"
    loading="eager"
    objectFit="contain"
  />
);
