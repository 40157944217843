import React, { MutableRefObject } from 'react';
import styled from 'styled-components';
import { RiDeleteBinLine } from 'react-icons/ri';
import { GalleryPhoto, useMediaApi } from '@/webapi/use-media-api';
import { ConfirmModal } from '@/components/confirm-modal';
import { centered, useSharedElement } from '@/components/use-shared-element';
import { IconButton } from '@/features/editor/widgets/custom-widget/loading-section/shared/iconButton';

export function ImageEntry({
  onClick,
  onRemove,
  fromRef,
  photo: { id, thumbnailLink, checksum, removed },
}: {
  onClick: () => void;
  onRemove: () => void;
  fromRef: MutableRefObject<any>;
  photo: GalleryPhoto;
}) {
  if (removed) {
    return null;
  }
  const { SharedElementOverlay, props, show, hide } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `transparent`,
        opacity: `0`,
      },
      extraTo: {
        background: `white`,
        opacity: `1`,
      },
    },
    fromRef,
    () => centered(23, 42),
  );
  const { softDeleteImage } = useMediaApi();

  const onDel = async () => {
    await softDeleteImage(checksum, id);
    onRemove();
    hide();
  };
  return (
    <Container>
      <Image onClick={onClick} src={thumbnailLink} alt={id} />
      <SharedElementOverlay {...props}>
        <ConfirmModal
          neutral
          onDiscard={hide}
          onConfirm={onDel}
          title="Delete Image"
          description="Are you sure you want to delete this image?"
        />
      </SharedElementOverlay>
      <IconButton onClick={show}>
        <RiDeleteBinLine size={16} />
      </IconButton>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  :hover {
    svg {
      display: block;
    }
  }

  svg {
    display: none;
    border-radius: 5px;
    background-color: white;
    position: absolute;
    top: 7px;
    right: 7px;

    :hover {
      cursor: pointer;
      box-shadow: 0 5px 7px 0 rgba(26, 47, 116, 0.22);
      opacity: 0.7;
    }

    :active {
      cursor: default;
      opacity: 1;
    }
  }
`;

const Image = styled.img`
  cursor: pointer;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
`;
