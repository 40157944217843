import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import NumericInput from 'react-numeric-input';
import { StaticImage } from 'gatsby-plugin-image';

export interface NumericInputProps {
  min: number;
  max: number;
  defaultValue?: string;
  onChange?: (val: string) => void;
  suffix?: string;
  minimal?: boolean;
  disabled?: boolean;
  step?: number;
}

let downInterval: any = 0;
let upInterval: any = 0;

function getInputTextLen(value: number): string {
  const len = `${value}`.length;
  switch (len) {
    case 1:
      return `14px`;
    case 2:
      return `14px`;
    case 3:
      return `12px`;
    case 4:
      return `12px`;
    default:
      return `10px`;
  }
}

export function NumberInput({
  min,
  max,
  defaultValue,
  onChange,
  suffix,
  minimal,
  disabled,
  step = 1,
}: NumericInputProps) {
  const [value, _setValue] = useState(
    parseFloat(defaultValue?.replace(suffix || ``, ``) || `1`),
  );
  const valueRef = useRef(value);
  const setValue = (value) => {
    valueRef.current = value;
    _setValue(value);
  };

  const [isMouseDownDownArrow, setIsMouseDownDownArrow] = useState(false);
  const [isMouseDownUpArrow, setIsMouseDownUpArrow] = useState(false);

  const format = (val: number) => val + (suffix || ``);

  useEffect(() => {
    if (isMouseDownDownArrow) {
      clearInterval(downInterval);
      downInterval = setInterval(() => {
        const val = Math.max(min, valueRef.current - step);
        setValue(val);
      }, 200);
    } else {
      clearInterval(downInterval);
      onChange(`${valueRef.current}${suffix}`);
    }
    return () => clearInterval(downInterval);
  }, [isMouseDownDownArrow]);

  useEffect(() => {
    if (isMouseDownUpArrow) {
      clearInterval(upInterval);
      upInterval = setInterval(() => {
        const val = Math.max(min, valueRef.current + step);
        setValue(val);
      }, 200);
    } else {
      clearInterval(upInterval);
      onChange(`${valueRef.current}${suffix}`);
    }
    return () => clearInterval(upInterval);
  }, [isMouseDownUpArrow]);

  const onIncrease = () => {
    if (value === max) {
      return;
    }
    const val = Math.min(max, value + step).toFixed(2);
    setValue(val);
    onChange(`${val}${suffix}`);
  };

  const onDecrease = () => {
    if (value === min) {
      return;
    }
    const val = Math.max(min, value - step).toFixed(2);
    setValue(val);
    onChange(`${val}${suffix}`);
  };

  useEffect(() => {
    setValue(parseFloat(defaultValue?.replace(suffix || ``, ``) || `1`));
  }, [defaultValue]);
  const [open, setOpen] = useState(false);
  return (
    <Wrapper minimal={minimal} fs={getInputTextLen(value)}>
      <NumericInput
        onClick={() => setOpen(!open)}
        min={min}
        max={max}
        disabled={!!disabled}
        value={value}
        onChange={(v) => onChange(`${v}${suffix}`)}
        format={format}
        pattern="[0-9]+"
        noStyle
        step={step}
      />
      <ArrowsWrapper>
        <UpArrow
          onMouseDown={() => !disabled && setIsMouseDownUpArrow(true)}
          onMouseUp={() => !disabled && setIsMouseDownUpArrow(false)}
          onClick={onIncrease}
        />
        <DownArrow
          onMouseDown={() => !disabled && setIsMouseDownDownArrow(true)}
          onMouseUp={() => !disabled && setIsMouseDownDownArrow(false)}
          onClick={onDecrease}
        />
      </ArrowsWrapper>
    </Wrapper>
  );
}

type P = { minimal: boolean; fs: string };
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 4.5rem 1rem;
  align-items: center;
  justify-content: center;

  background: ${({ minimal }: P) => (minimal ? `transparent` : `white`)};
  padding: 1rem 0.7rem 1rem 0;
  border-radius: 10px;
  box-shadow: ${({ minimal }: P) =>
    minimal ? `none` : `0 9px 13px 0 rgba(177, 217, 203, 0.18)`};

  max-width: 8rem;

  input {
    width: 100%;
    background: none;
    outline: none;
    border: none;
    font-size: ${(p: P) => p.fs || `14px`};
    font-weight: 500;
    color: #4b5564;
    text-align: center;
  }
`;

const ArrowsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  && > :first-child {
    margin-bottom: 5px;
  }

  .gatsby-image-wrapper {
    height: 8px;
    width: 10px;
    opacity: 0.5;
  }
`;

const DownArrow = ({ onClick, onMouseDown, onMouseUp }) => (
  <StaticImage
    src="../../../../../../assets/down_arrow.svg"
    alt="down"
    height={10}
    objectFit="contain"
    placeholder="none"
    loading="eager"
    onClick={onClick}
    onMouseLeave={onMouseUp}
    onMouseUp={onMouseUp}
    onMouseDown={onMouseDown}
  />
);

const UpArrow = ({ onClick, onMouseDown, onMouseUp }) => (
  <StaticImage
    src="../../../../../../assets/down_arrow.svg"
    alt="up"
    objectFit="contain"
    height={10}
    placeholder="none"
    loading="eager"
    imgStyle={{ transform: `rotate(180deg)` }}
    onClick={onClick}
    onMouseLeave={onMouseUp}
    onMouseUp={onMouseUp}
    onMouseDown={onMouseDown}
  />
);
