/* eslint-disable no-nested-ternary,@typescript-eslint/ban-ts-comment */
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm, UseFormRegisterReturn } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IoIosArrowBack } from 'react-icons/io';
import { StaticImage } from 'gatsby-plugin-image';
import { TabSelect } from '@/components/tab-select';
import { SearchInput } from '@/components/text-input';
import { BigButton } from '@/components/big-button';
import {
  AccountContext,
  AccountContextProps,
} from '@/features/account-context';
import { openLinkInNewTab } from '@/utils/browser';
import { maybe } from '@/features/details/utils';
import { CONTROL, DELETED } from '@/features/details/shared';
import { ExperienceVariant } from '@/webapi/use-experience-api';
import { EditorContext } from '@/features/editor/context/editor-context';

const Container = styled.form`
  border-radius: 30px;
  background-color: white;
  padding: 2rem;
  border: solid 0.6px rgba(151, 151, 151, 0.1);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09), 0 1px 4px 0 rgba(7, 6, 6, 0.04);
`;

interface Props {
  onPreview: (baseUrl?: string, variantId?: string) => Promise<string>;
  onSharePreview: (
    email: string,
    sharedBy: string,
    quickPreviewUrl?: string,
  ) => Promise<void | unknown>;
  quickPreviewUrl: string;
  togglePopover: () => void;
  variants: Array<ExperienceVariant>;
}

export const PreviewPopoverContent: FC<Props> = ({
  quickPreviewUrl,
  onPreview,
  onSharePreview,
  togglePopover,
  variants,
}) => {
  const accountCtx = useContext(AccountContext);
  const [isForMe, setIsForMe] = useState(true);
  const [loading, setLoading] = useState(false);
  const onTabSelect = useCallback(
    (x: number, name: string) => setIsForMe(name === `FOR ME`),
    [],
  );

  const { register, handleSubmit, getValues } = useForm();
  const [previewUrl, setPreviewUrl] = useState(``);
  const editor = useContext(EditorContext);
  const [variantId, setVariantId] = useState(() =>
    maybe(
      () => editor.experienceState.currentExperience.activeVariantId,
      maybe(() => variants[0].id),
    ),
  );

  useEffect(() => {
    const baseUrl = getValues(`baseUrl`);
    (async () => {
      if (baseUrl) setPreviewUrl(await onPreview(baseUrl, variantId));
    })();
  }, [getValues(`baseUrl`), variantId]);

  const [variantSelect, setSelectVariant] = useState(false);
  const isMultiVariation = maybe(
    () =>
      variants.filter((v) => v.name !== CONTROL && v.name !== DELETED).length >
      1,
  );

  const onSubmit = async (data: { baseUrl: string; email: string }) => {
    setLoading(true);
    const lnk = await onPreview(data.baseUrl, variantId);
    setLoading(false);
    if (isForMe) {
      openLinkInNewTab(lnk, `vsly_preview`, togglePopover);
    } else {
      setLoading(true);
      await onSharePreview(
        data.email,
        accountCtx?.account?.store?.firstName || ``,
        lnk,
      );
      setLoading(false);
      toast(`Preview link copied!`, {
        theme: `colored`,
        type: `success`,
      });
      togglePopover();
    }
  };

  const onCopyToClipboard = async () => {
    await navigator.clipboard.writeText(previewUrl);
    toast(`success`, {
      theme: `colored`,
      type: `success`,
    });
    togglePopover();
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <SelectMultiVariant
        variantSelect={variantSelect}
        variantId={variantId}
        setVariantId={setVariantId}
        onClick={() => setSelectVariant(false)}
        variants={variants}
      />
      <Preview
        variantSelect={variantSelect}
        onTabSelected={onTabSelect}
        forMe={isForMe}
        onClick={() => setSelectVariant(true)}
        isMultiVariation={isMultiVariation}
        experienceVariant={variants?.find((v) => v.id === variantId)}
        onClick1={onCopyToClipboard}
        useFormRegisterReturn={register(`baseUrl`, { required: true })}
        quickPreviewUrl={quickPreviewUrl}
        accountCtx={accountCtx}
        useFormRegisterReturn1={register(`email`, { required: !isForMe })}
        disabled={loading}
      />
    </Container>
  );
};

const CopyLink = styled.p`
  margin-top: 20px;
  text-decoration: underline;
  text-align: center;
  width: 100%;
  font-size: 14px;
  user-select: none;
  font-family: 'JetBrains Mono', serif;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &:active {
    cursor: default;
    opacity: 1;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin: 3rem 0 0 0;
`;

const HeaderContainer = styled.div``;

interface HeaderProps {
  onTabSelected: (x: number, name: string) => void;
}

const Header: FC<HeaderProps> = ({ onTabSelected }) => (
  <HeaderContainer>
    <TabSelect
      onTabSelected={onTabSelected}
      tabHeight={4}
      tabWidth={13}
      tabs={[`FOR ME`, `SHARE`]}
      defaultValue="FOR ME"
    />
  </HeaderContainer>
);

const SubTitle = styled.p`
  text-align: center;
  color: #99a4ae;
  width: 100%;
  font-size: 14px;
  user-select: none;
  font-family: 'JetBrains Mono', serif;
  font-weight: 400;
  letter-spacing: 0.11px;
  margin: 25px 0;
  span {
    span {
      font-weight: 600;
      text-decoration: underline;
      &:hover {
        opacity: 0.5;
        cursor: pointer;
      }
      &:active {
        opacity: 1;
        cursor: default;
      }
    }
  }
`;

const SelectVariationHeader = styled.div`
  span {
    display: flex;
    align-items: center;
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
    &:active {
      opacity: 1;
      cursor: default;
    }
  }

  color: #748391;
  font-family: 'Inter', serif;
  font-weight: 600;
  font-size: 1.6rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #979797;
  user-select: none;
`;

const SelectVariant = styled.div`
  span:first-child {
    margin-right: 1rem;
    display: flex;
    align-items: center;
  }
  display: flex;
  align-items: center;
  color: #576572;
  font-family: 'Inter', serif;
  font-weight: 600;
  font-size: 1.6rem;
  padding-top: 1.5rem;
  padding-left: 1rem;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  &:active {
    opacity: 1;
    cursor: default;
  }
`;

function SelectMultiVariant({
  variants,
  onClick,
  variantSelect,
  setVariantId,
  variantId,
}: {
  variantSelect: boolean;
  onClick: () => void;
  variantId: string;
  setVariantId: (v: string) => void;
  variants: ExperienceVariant[];
}) {
  return (
    <div
      style={{
        minHeight: `230px`,
        display: !variantSelect ? `none` : `block`,
      }}
    >
      <SelectVariationHeader onClick={onClick}>
        <span>
          <IoIosArrowBack color="#C2CCD7" size={30} /> Select Variation for
          Preview
        </span>
      </SelectVariationHeader>
      {variants
        ?.filter((v) => v.name !== DELETED && v.name !== CONTROL)
        .map((v) => (
          <SelectVariant onClick={() => setVariantId(v.id)}>
            {v.id === variantId && (
              <span>
                <StaticImage
                  loading="eager"
                  formats={[`webp`, `png`]}
                  height={20}
                  placeholder="none"
                  src="../../../../assets/vi.svg"
                  alt="Integrations"
                />
              </span>
            )}
            <span>{v?.name || ``}</span>
          </SelectVariant>
        ))}
    </div>
  );
}

function Preview({
  accountCtx,
  disabled,
  experienceVariant,
  forMe,
  isMultiVariation,
  onClick,
  onClick1,
  onTabSelected,
  quickPreviewUrl,
  useFormRegisterReturn,
  useFormRegisterReturn1,
  variantSelect,
}: {
  variantSelect: boolean;
  onTabSelected: (x: number, name: string) => void;
  forMe: boolean;
  onClick: () => void;
  isMultiVariation: boolean;
  experienceVariant?: ExperienceVariant;
  onClick1: () => Promise<void>;
  useFormRegisterReturn: UseFormRegisterReturn;
  quickPreviewUrl: string;
  accountCtx: AccountContextProps;
  useFormRegisterReturn1: UseFormRegisterReturn;
  disabled: boolean;
}) {
  return (
    <div
      style={{
        display: variantSelect ? `none` : `block`,
      }}
    >
      <Header onTabSelected={onTabSelected} />
      {forMe ? (
        <>
          <CopyLink onClick={onClick1}>Copy Link</CopyLink>
          <SubTitle>
            <span>
              Preview{` `}
              {isMultiVariation && (
                <span onClick={onClick}>{experienceVariant?.name}</span>
              )}
              {` `}
              on page
            </span>
          </SubTitle>
        </>
      ) : (
        <>
          <CopyLink onClick={onClick1}>Copy Link</CopyLink>
          <SubTitle>Share a link with</SubTitle>
        </>
      )}
      <SearchInput
        padding="1rem 3.2rem"
        fontSize="1.4rem"
        color="#99a4ae"
        style={{ display: !forMe && `none` }}
        key="forme"
        name="baseUrl"
        {...useFormRegisterReturn}
        defaultValue={quickPreviewUrl || accountCtx.account.store.domain}
      />

      <SearchInput
        padding="1rem 3.2rem"
        fontSize="1.4rem"
        color="#99a4ae"
        style={{ display: forMe && `none` }}
        key="share"
        placeholder="jhon@email.com"
        type="email"
        name="email"
        {...useFormRegisterReturn1}
      />
      <ButtonContainer>
        <BigButton
          disabled={disabled}
          color="white"
          background="#3451fa"
          noTransform
          fillWidth
          type="submit"
          size="medium"
        >
          {disabled ? `...` : forMe ? `Preview` : `Share`}
        </BigButton>
      </ButtonContainer>
    </div>
  );
}
