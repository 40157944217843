import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { Caption, SingleRowWrapper } from '../../shared/shared-styles';
import { NumberInput } from '@/features/editor/widgets/custom-widget/inputs/shared/number-input';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';

export interface SingleNumberInputProps extends GenericInputProps {
  caption: string;
  envKey: string;
  unit: string;
}

export function SingleNumberInput({
  caption,
  envKey,
  unit,
  initialValues,
  onValuesChanged,
}: SingleNumberInputProps) {
  const { undoRedoCount } = useContext(UndoRedoCounterContext);

  const getValue = useCallback(() => initialValues(envKey), [undoRedoCount]);

  const [value, setValue] = useState(getValue());
  useEffect(() => setValue(getValue()), [undoRedoCount]);

  const onChange = (value) => {
    setValue(value);
  };

  useEffect(() => {
    onValuesChanged(envKey, value);
  }, [value]);

  return (
    <Wrapper>
      <Caption>{caption}</Caption>
      <NumberInput
        minimal
        min={-2000}
        max={2000}
        suffix={unit}
        defaultValue={value}
        onChange={onChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled(SingleRowWrapper)`
  grid-template-columns: 1fr 6rem;
  input {
    font-family: 'JetBrains Mono', serif;
  }
`;
