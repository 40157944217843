/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Actions } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule/header/actions';
import { LayeredRulingContext } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import { Title } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule/header/title';

export function Header({ id }: { id: number }) {
  const ctx = useContext(LayeredRulingContext);
  const rule = ctx.rule(id);
  const onToggleOpen = () => ctx.toggleOpen(id);
  return (
    <RuleHeader ruleId={id} isOpen={rule.isOpen}>
      <RuleNumber id={`rule${id}`} onClick={onToggleOpen}>
        <ShaBang>#</ShaBang>
        <Title rule={rule} />
      </RuleNumber>
      <Actions ruleId={id} open={rule.isOpen} />
    </RuleHeader>
  );
}
const RuleNumber = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 88%;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  &:active {
    opacity: 1;
    cursor: default;
  }
`;
const ShaBang = styled.span`
  margin-right: 3rem;
`;

const RuleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  ${(p) =>
    p.isOpen
      ? ``
      : `
      &:hover {
        #rule${p.ruleId} {
           opacity: 0.5;
        }
      }
      &:active {
        #rule${p.ruleId} {
           opacity: 1;
        }
      }

      cursor:pointer;
  `}

  color: #83909d;
  font-family: 'JetBrains Mono', serif;
  font-size: 1.4rem;
  font-weight: bold;
  padding-bottom: ${(p) => (p.isOpen ? 1.6 : 0)}rem;
  border-bottom: 2px solid ${(p) => (p.isOpen ? `#efeff0` : `transparent`)};
`;
