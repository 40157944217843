import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import {
  Caption,
  SingleRowWrapper,
} from '@/features/editor/widgets/custom-widget/shared/shared-styles';
import { BackgroundColorHeader } from '@/features/editor/widgets/custom-widget/inputs/background/background-color';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';

export function ColorSelectorInput(props: GenericInputProps) {
  const { initialValues, spec } = props;
  const revertToActive = () => true;
  const openColorTooltip = useRef(false);
  const defaultColor = initialValues(`value`);
  const { undoRedoCount } = useContext(UndoRedoCounterContext);

  return (
    <Wrapper>
      <Caption>{spec?.name ? spec.name : `Color`}</Caption>
      <BackgroundColorHeader
        key={undoRedoCount}
        isActive
        defaultColor={defaultColor}
        openColorTooltip={openColorTooltip}
        revertToActive={revertToActive}
        {...props}
      />
    </Wrapper>
  );
}

const Wrapper = styled(SingleRowWrapper)`
  && {
    grid-template-columns: 1fr 10rem;
    padding: 1rem 2rem;
  }
`;
