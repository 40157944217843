import * as React from 'react';
import { useContext, useMemo } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { QueryBuilder } from '@/components/query-builder';
import { CardButton, MixedCardLayout } from '@/components/card-button';
import { AudienceContext } from '@/features/editor/widgets/shared/modals/audience/audience-context';
import { EditorContext } from '@/features/editor/context/editor-context';
import { QBType } from '@/components/query-builder/models';

export function CustomQB() {
  const {
    experienceState: {
      currentExperience: { isPostPurchase },
    },
  } = useContext(EditorContext);

  const { onQueryBuilderChange, audienceProps, customSegment } =
    useContext(AudienceContext);

  const props = useMemo(() => {
    if (isPostPurchase) {
      const qbItemProps = audienceProps.filter(
        (prop) =>
          [
            `Cart Items Count`,
            `Cart Amount`,
            `Cart Total`,
            `Cart Item`,
          ].includes(prop.caption) || prop.group === `Geo`,
      );
      qbItemProps.push({
        kind: QBType.NUMERIC_VALUE,
        caption: `Number of subscription items in the cart`,
        group: `Behaviour`,
        envKey: `Subscriptions $op $value`,
      });
      return qbItemProps;
    }
    return audienceProps;
  }, [isPostPurchase, audienceProps]);

  return (
    <>
      <span>
        Use our query builder to tailor the target audience to the experience
      </span>
      <QueryBuilder
        onChange={onQueryBuilderChange}
        initialProps={props}
        initialState={customSegment || []}
      />
    </>
  );
}

export function CustomAudienceBtn({
  onClicked,
}: {
  onClicked: (isSelected: boolean) => void;
}) {
  return (
    <CardButton
      height="10rem"
      borderRadius="1.5rem"
      canBeSelected
      onClicked={onClicked}
    >
      <MixedCardLayout
        horizontalDistribution="5rem 1fr"
        padding="0 1.5rem"
        primaryColor="#576470"
        accentColor="#758390"
        primaryFontSize="1.4rem"
        accentFontSize="1.2rem"
        accentFontFamily="Inter"
        content={
          <StaticImage
            formats={[`png`]}
            quality={100}
            width={36}
            src="custom_audience.svg"
            alt="Custom Audience"
            loading="eager"
            placeholder="none"
          />
        }
      >
        <span>Custom Audience</span>
        <span>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Build your own audience in-case the one you want isn't within the list
          below
        </span>
      </MixedCardLayout>
    </CardButton>
  );
}
