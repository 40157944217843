import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';

export interface SwitchInputOption {
  label: string;
  value: any;
}

export function SwitchInput({
  initialValues,
  onValuesChanged,
}: GenericInputProps) {
  const { undoRedoCount } = useContext(UndoRedoCounterContext);
  const opts = useMemo(
    () => initialValues(`options`),
    [],
  ) as SwitchInputOption[];
  const getValue = useCallback(() => initialValues(`value`), [undoRedoCount]);

  const [value, setValue] = useState(getValue());

  useEffect(() => {
    onValuesChanged(`value`, value);
  }, [value]);

  return (
    <OptionSwitchWrapper>
      {opts.map((opt) => (
        <OptionSwitch
          className={opt.value === value ? `active` : ``}
          onClick={() => setValue(opt.value || ``)}
          dangerouslySetInnerHTML={{ __html: opt.label }}
        />
      ))}
    </OptionSwitchWrapper>
  );
}

const OptionSwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0.75rem;
  border-radius: 10px;
  background: #e9eaeb;
`;
const OptionSwitch = styled.button`
  pointer-events: auto;
  cursor: pointer;
  font-family: 'Inter', serif;
  font-weight: 550;
  letter-spacing: -0.2px;
  padding: 1.5rem;
  font-size: 12px;
  width: 50%;
  &&.active {
    pointer-events: none;
    background: white;
    box-shadow: 0 9px 13px 0 rgba(177, 217, 203, 0.18);
    border-radius: 10px;
  }
  && > s {
    color: #888;
  }
`;
