import React, { useContext } from 'react';
import { StrategyPerSlotContext } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/context/context';
import { SearchHeader as SearchHeaderInner } from '@/features/editor/widgets/custom-widget/loading-section/shared/search-header';

export function SearchHeader() {
  const ctx = useContext(StrategyPerSlotContext);

  const onBack = () => ctx.setProductSearchSlotOpen(undefined);
  const { searchText, setSearchText } = ctx;
  return (
    <SearchHeaderInner
      onBack={onBack}
      value={searchText}
      onChange={(ev) => {
        setSearchText(ev.target.value);
      }}
    />
  );
}
