/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Select, { CSSObjectWithLabel, StylesConfig } from 'react-select';
import { useDebouncedCallback } from 'use-debounce';
import styled from 'styled-components';
import {
  GenericInputProps,
  InputsLightTheme,
  InputTheme,
} from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { useEscapeKey } from '@/features/editor/widgets/custom-widget/inputs/shared/useControlPopover';
import { Caption, SingleRowWrapper } from '../../shared/shared-styles';
import { useDropdownInputUpdater } from '@/features/editor/widgets/custom-widget/inputs/shared/use-dropdown-updater';

const AUTO_CLOSE_TIMEOUT = 10 * 1000;

export function DropdownRowInput(
  props: GenericInputProps & { caption: string },
) {
  const { caption, initialValues } = props;
  const opts = useMemo(
    () => initialValues(`options`),
    [],
  ) as DropdownInputOption[];
  const { getValue, onChange, undoRedoCount } = useDropdownInputUpdater(
    props,
    `value`,
    opts,
  );

  return (
    <DropdownInputWrapper>
      <Caption>{caption}</Caption>
      <DropdownInput
        key={undoRedoCount}
        theme={InputsLightTheme}
        options={opts}
        onChange={onChange}
        defaultValue={getValue()}
      />
    </DropdownInputWrapper>
  );
}

const DropdownInputWrapper = styled(SingleRowWrapper)`
  && {
    grid-template-columns: minmax(80px, auto) minmax(150px, auto);
    padding: 0.7rem 2rem;

    div > div > div {
      box-shadow: none;
      text-align: right;
    }
  }
`;

export interface DropdownInputOption {
  label: string;
  value: any;
}

export interface DropdownInputProps {
  options: DropdownInputOption[];
  defaultValue?: DropdownInputOption;
  onChange?: (value: DropdownInputOption) => void;
  theme?: InputTheme;
  isMulti?: boolean;
  optionStyles?: CSSObjectWithLabel;
}

export function DropdownInput({
  options,
  defaultValue,
  onChange,
  theme,
  isMulti,
  optionStyles,
}: DropdownInputProps) {
  theme = theme || InputsLightTheme;
  optionStyles = optionStyles || {};
  const lastOpen = useRef<number | null>(null);
  const interval = useRef(null);
  const [counter, setCounter] = useState(0);
  const setNow = () => {
    setCounter(counter + 1);
    lastOpen.current = now();
  };
  const debouncedSetNow = useDebouncedCallback(setNow, 500);
  const [isOpen, setIsOpen] = useState(false);
  const close = () => {
    setIsOpen(false);
  };

  function now() {
    return new Date().valueOf();
  }

  const resetNow = () => {
    lastOpen.current = null;
  };

  useEffect(() => {
    document.addEventListener(`scroll`, debouncedSetNow, true);
    return () => document.removeEventListener(`scroll`, debouncedSetNow);
  }, []);

  useEffect(() => {
    function tryClose() {
      if (now() - lastOpen.current > AUTO_CLOSE_TIMEOUT) {
        close();
      }
    }

    interval.current && clearTimeout(interval.current);
    interval.current = setTimeout(tryClose, AUTO_CLOSE_TIMEOUT);
    return () => clearTimeout(interval.current);
  }, [counter]);

  useEscapeKey(close);
  const selectRef = useRef(null);
  const [placement, setPlacement] = useState<'top' | 'bottom'>(`bottom`);

  const onSelectClick = () => {
    const boundingClientRect = (
      selectRef.current as HTMLElement
    ).getBoundingClientRect();
    setPlacement(selectPlacement(boundingClientRect, options as Array<any>));
    setIsOpen(!isOpen);
  };

  return (
    <div onClick={onSelectClick} ref={selectRef}>
      <Select
        menuPlacement={placement}
        menuIsOpen={isOpen}
        onMenuOpen={setNow}
        onMenuClose={resetNow}
        menuPortalTarget={document.body}
        menuShouldBlockScroll
        isMulti={isMulti || false}
        isSearchable={false}
        options={options}
        defaultValue={defaultValue}
        styles={dropDownStyles(theme, optionStyles, placement)}
        onChange={onChange}
      />
    </div>
  );
}

function selectPlacement(rect: DOMRect, opts: Array<number>) {
  return window.innerHeight -
    ((rect?.top || 0) + 150 + 44 * Math.min(opts.length, 7)) >
    0
    ? `bottom`
    : `top`;
}

export const dropDownStyles = (
  theme: InputTheme,
  optionStyles: CSSObjectWithLabel,
  placement: 'top' | 'bottom',
) =>
  ({
    control: (styles, { menuIsOpen }) => ({
      ...styles,
      background: theme.background,
      border: `none`,
      outline: `none`,
      padding: theme.containerPadding,
      borderRadius:
        placement === `top` && menuIsOpen
          ? `0 0 10px 10px `
          : placement === `bottom` && menuIsOpen
          ? `10px 10px 0 0`
          : `10px`,
      borderLeft: menuIsOpen ? `1px solid #dedede` : `1px solid #fff`,
      borderRight: menuIsOpen ? `1px solid #dedede` : `1px solid #fff`,
      borderTop: menuIsOpen ? `1px solid #dedede` : `1px solid #fff`,
      borderBottom:
        placement === `top` && menuIsOpen ? `1px solid #dedede` : `none`,
      boxShadow: theme.boxShadow,
      textAlign: theme.textAlign,
      ':hover': {
        borderLeft: menuIsOpen ? `1px solid #dedede` : `1px solid #fff`,
        borderRight: menuIsOpen ? `1px solid #dedede` : `1px solid #fff`,
        borderTop: menuIsOpen ? `1px solid #dedede` : `1px solid #fff`,
      },
    }),
    input: (styles) => ({
      ...styles,
    }),
    placeholder: (styles) => ({
      ...styles,
      textTransform: `uppercase`,
      color: theme.foreground,
    }),
    menu: (styles) => ({
      ...styles,
      margin: `0`,
      borderTop: `1px solid #dedede`,
      borderLeft: `1px solid #dedede`,
      borderRight: `1px solid #dedede`,
      borderBottom: `1px solid #dedede`,
      borderRadius: placement === `top` ? `10px 10px 0 0` : `0 0 10px 10px`,
      outline: `none`,
      backgroundColor: theme.menuBackground || theme.background,
      padding: `0.7rem 0.7rem`,
      boxShadow: `none`,
      zIndex: 9999,
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      backgroundColor: theme.background,
      color: isSelected ? `#3451fa` : theme.foreground,
      fontWeight: isSelected ? `800` : `500`,
      fontSize: `1.4rem`,
      padding: `1.2rem 1rem`,
      fontFamily: `JetBrains Mono`,
      ...optionStyles,
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      padding: 0,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: 0,
    }),
    indicatorSeparator: () => ({
      display: `none`,
    }),
    valueContainer: (styles) => ({
      ...styles,
    }),
    container: (styles) => ({
      ...styles,
      width: `100%`,
      color: theme.foreground,
      fontSize: `1.3rem`,
      fontWeight: 500,
      fontFamily: `JetBrains Mono`,
      textTransform: `uppercase`,
      letterSpacing: `-0.44px`,
    }),
  } as StylesConfig);
