import {
  EditorDeclarativeBlock,
  ExperienceVariant,
} from '@/webapi/use-experience-api';
import { hash } from '@/utils/cache';
import { hashObject } from '@/components/hooks/use-cached-auto-save';
import { routes } from '@/webapi/routes';

export function getScreenshotFromChange(
  storeAlias: string,
  experienceId: string,
  variant: ExperienceVariant,
  change: EditorDeclarativeBlock,
) {
  const path = `${experienceId}/${variant.id}`;
  const name = hash(change?.editorId);
  const changeHash = hashObject(change);
  const domain =
    routes.getEnv() === 2 ? `sdk.loomi-prod.xyz` : `sdk.loomi-stg.xyz`;
  const changeUrl = `https://${domain}/changes/${storeAlias}/${path}/change_${name}.png?v=${changeHash}ts=${new Date().getTime()}`;
  const mountUrl = `https://${domain}/changes/${storeAlias}/${path}/mount_${name}.png?v=${changeHash}ts=${new Date().getTime()}`;
  return {
    path,
    name,
    changeUrl,
    mountUrl,
  };
}

export interface ChangeScreenshot {
  path: string;
  name: string;
  changeUrl: string;
  mountUrl: string;
}
