import { useCallback, useContext, useState } from 'react';
import { EditorContext } from '@/features/editor/context/editor-context';
import { useCodeEditorLoader } from '@/features/editor/widgets/shared/use-code-editor-loader';
import { TEMP_NO_SELECTOR } from '@/features/editor/widgets/changelog/placeholder';
import {
  CompoundBlock,
  EditorChangeKind,
  EditorDeclarativeBlock,
  TargetingDeviceType,
} from '@/webapi/use-experience-api';
import { createNewElementMessageFromOrigChange } from '@/features/editor/context/use-transpiler';
import { CatalogWidget } from '@/webapi/use-widget-catalog-api';
import { AllMutationKind } from '@/pkg/sdk';
import { AnchorOrigin } from '@/features/editor/context/use-device-preview';

export interface CodeTypeSelectProps {
  origChange: EditorDeclarativeBlock;
}

export function useCodeBlocksSelect({ origChange }: CodeTypeSelectProps) {
  const {
    experienceState: { currentExperience, currentVariantIdx },
    inspectorNav: { gotoAppCatalog, gotoCompoundEditor },
    devicePreview: { anchor },
    applyTempChange,
  } = useContext(EditorContext);

  const { gotoGlobalCssEditor, gotoGlobalJsEditor } = useCodeEditorLoader();

  const [id] = useState(`_loomi_addon_${new Date().getTime()}`);
  const [showPlacementPicker, setShowPlacementPicker] = useState(false);

  const onCodeBlock = useCallback(() => {
    if (origChange) {
      if (origChange?.block?.selector === TEMP_NO_SELECTOR) {
        setShowPlacementPicker(true);
      } else {
        continueToCompoundEditor();
      }
    }
  }, [id, origChange]);

  const continueToCompoundEditor = useCallback(() => {
    (
      origChange.block.value as CompoundBlock
    ).html = `<div id="${id}">Change Me!</div>`;
    (origChange.block.value as CompoundBlock).css = ``;
    applyTempChange(origChange);
    gotoCompoundEditor(origChange, true);
  }, [id, origChange]);

  const onBack = () => {
    gotoAppCatalog(createNewElementMessageFromOrigChange(origChange));
  };

  const onCodeBlockSelected = (w: CatalogWidget) => {
    if (w.id === `custom`) {
      onCodeBlock();
    } else if (w.id === `css`) {
      const allDevices = currentExperience?.variants?.[
        currentVariantIdx
      ]?.changes?.find(
        (change) =>
          change.editorKind === EditorChangeKind.GLOBAL_CSS &&
          change.targetDevice === TargetingDeviceType.ALL_DEVICES,
      );
      gotoGlobalCssEditor(currentExperience?.id, allDevices);
    } else if (w.id === `js`) {
      const allDevices = currentExperience?.variants?.[
        currentVariantIdx
      ]?.changes?.find(
        (change) =>
          change.editorKind === EditorChangeKind.GLOBAL_JS &&
          change.targetDevice === TargetingDeviceType.ALL_DEVICES,
      );
      gotoGlobalJsEditor(currentExperience?.id, allDevices);
    }
  };

  const onSelectorPicked = (selector: string, kind: AllMutationKind) => {
    origChange.block.selector = selector;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (origChange.block.value?.htmlKind)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      origChange.block.value.htmlKind = kind;
    setTimeout(() => {
      anchor(selector, AnchorOrigin.NEW_ELEMENT);
    }, 250);
    continueToCompoundEditor();
  };

  const onBackFromPicker = () => {
    const newChange = JSON.parse(
      JSON.stringify(origChange),
    ) as EditorDeclarativeBlock;
    newChange.block.selector = TEMP_NO_SELECTOR;
    setShowPlacementPicker(false);
  };

  return {
    onCodeBlock,
    onCodeBlockSelected,
    onSelectorPicked,
    onBackFromPicker,
    showPlacementPicker,
    continueToCompoundEditor,
    onBack,
    id,
  };
}
