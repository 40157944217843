import React, { FC } from 'react';
import styled from 'styled-components';
import { Header } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule/header/header';
import { Body } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule/body';

type Props = {
  id: number;
};

export const Rule: FC<Props> = ({ id }) => (
  <Wrapper first={id === 0}>
    <Header id={id} />
    <Body id={id} />
  </Wrapper>
);

const Wrapper = styled.div`
  width: 115rem;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25),
    0 1px 34px 0 rgba(151, 210, 206, 0.32), 0 1px 14px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 2rem 3rem;
  background-color: #ffffff;
  margin-top: ${(p) => (p.first ? 0 : 2)}rem;
`;
