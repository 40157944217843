import { Res } from 'use-http';
import { v4 } from 'uuid';
import * as MimeTypes from './mime-types.json';
import { isSplittingVisualEditsSupported } from '@/webapi/experience-creation-version';

export async function handleResult(
  response: Res<any>,
  error: Error,
): Promise<any> {
  if (error) {
    return Promise.reject(error);
  }
  try {
    const resp = await response.json();
    return Promise.resolve(resp);
  } catch (ex) {
    return Promise.reject(ex);
  }
}

export async function handleJsonResult(
  response: any,
  error: Error,
): Promise<any> {
  if (error) {
    return Promise.reject(error);
  }
  try {
    const resp = await response;
    return Promise.resolve(resp);
  } catch (ex) {
    return Promise.reject(ex);
  }
}

export function mimeTypeToExtension(mimeType: string) {
  return MimeTypes?.[mimeType]?.extensions?.[0];
}

export function generateUUID() {
  return v4();
}

export function getDeviceBasedEditorId(
  selector: string,
  device: string,
  version?: number,
): string {
  if (isSplittingVisualEditsSupported(version)) {
    if (selector.endsWith(`-${device}`)) {
      // https://loomi-labs.atlassian.net/browse/LVP-2256
      // editr id kept growing
      // -mobile
      // -mobile-mobile ....
      return selector;
    }
    return `${selector}-${device}`;
  }
  return selector;
}
