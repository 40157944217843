import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import styled from 'styled-components';
import { TextInput } from '@/components/text-input';
import useAutoFocus from '@/components/hooks/useAutoFocus';

export function SearchHeader({
  onChange,
  onBack,
  value,
}: {
  onBack: () => void;
  value: string;
  onChange: (ev: { target: { value: string } }) => void;
}) {
  const { ref } = useAutoFocus({ setAutoFocus: true, timeout: 300 });
  return (
    <SearchHeaderStyled>
      <Back type="button" onClick={onBack}>
        <IoIosArrowBack color="grey" size={40} />
      </Back>
      <p>Choose a product</p>
      <SearchTextInput
        ref={ref}
        placeholder="Search for product"
        value={value}
        onChange={onChange}
      />
    </SearchHeaderStyled>
  );
}

const SearchTextInput = styled(TextInput)`
  && {
    margin-top: 3rem;
    min-width: 25rem;
    width: 105rem;
    text-align: start;
    border-radius: 10px;
    border: 1.5px solid #979797;
    font-size: 1.4rem;
    font-family: 'JetBrains Mono', serif;
    box-shadow: none;
    padding: 1.2rem 2rem;
  }
`;
const SearchHeaderStyled = styled.div`
  position: relative;
  width: 100%;

  p {
    font-size: 3rem;
    margin: -2.5rem 0 0 0;
  }
`;
const Back = styled.button`
  &:hover {
    cursor: pointer;
    opacity: 0.3;
  }
  &:active {
    cursor: pointer;
    opacity: 0.5;
  }
  opacity: 0.5;
  left: 20px;
  position: absolute;
  top: -30px;
  color: #d2d4d7;
`;
