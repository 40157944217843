import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';
import { CustomizationGroup } from '@/features/editor/widgets/custom-widget/inputs/shared/customization-group';
import { VSpace } from '@/components/spacing';
import { BigButton } from '@/components/big-button';
import { INSPECTOR_BACK_ID } from '@/features/editor/inspector';
import { StyleGroupsFilter } from '@/features/editor/widgets/custom-widget/shared/style-groups-filter';
import { Customization } from '@/webapi/use-widget-catalog-api';
import { MaybeFiltered } from './shared/shared-styles';
import { UndoRedoBtns } from '@/components/undo-redo-btns';
import { GlossyWrapper } from '@/components/glossy-wrapper';
import {
  newUndoRedoContext,
  UndoRedoCounterContext,
} from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import { useCustomizationName } from '@/features/editor/use-customization-name';
import { StyleTemplateSelect } from '@/features/editor/widgets/custom-widget/style-templating';

export function BlueprintStyle() {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);
  const {
    currentWidget,
    styleTemplateChange,
    gotoCustomize,
    currentSchema,
    getResponsiveValue,
    setResponsiveValue,
    updateCustomizationStatus,
    evaluateRule,
    canRedo,
    canUndo,
    undo,
    redo,
    undoRedoCount,
  } = useContext(CustomWidgetContext);

  const [groupsFilter, setGroupsFilter] = useState([]);
  const groupsEnabled = useMemo(
    () => currentWidget?.blueprint?.schema?.groups,
    [currentWidget],
  );

  const hiddenCustomizations = useMemo(
    () =>
      currentSchema?.customizations
        ?.filter((cust) => evaluateRule(cust?.hideWhen))
        .map((cust) => cust.key),
    [currentSchema?.settings?.general, device],
  );

  const isCustomizationVisible = (cust: Customization) => {
    if (hiddenCustomizations.includes(cust.key)) {
      return false;
    }

    return groupsEnabled ? groupsFilter.includes(cust.key) : true;
  };

  const { headerText, stickyEnabled, setStickyEnabled, setHeaderText } =
    useCustomizationName();

  const undoRedoContext = newUndoRedoContext(undoRedoCount);
  return (
    <UndoRedoCounterContext.Provider value={undoRedoContext}>
      <Wrapper device={device}>
        <InspectorWidgetLayout
          title="Detailed Styling"
          progress={80}
          progressWidthPercentMobile={55}
          backCaption="< Back to customization"
          stickyHeader={headerText}
          stickyEnabled={stickyEnabled}
          onBackClicked={() => gotoCustomize(currentWidget)}
          footer={
            <FooterSection>
              <UndoRedoBtns
                undo={undo}
                redo={redo}
                canUndo={canUndo}
                canRedo={canRedo}
              />
              <BigButton
                color="white"
                background="black"
                noTransform
                fillWidth
                size={device === DeviceType.Desktop ? `medium-thin` : `medium`}
                onClick={() => {
                  gotoCustomize(currentWidget);
                }}
              >
                Apply
              </BigButton>
            </FooterSection>
          }
        >
          <StyleTemplateSelect />
          {groupsEnabled && (
            <StyleGroupsFilter
              groups={currentWidget?.blueprint?.schema?.groups}
              customizations={currentWidget?.blueprint?.schema?.customizations}
              onGroupSelected={(groups) => setGroupsFilter(groups)}
              hiddenCustomizations={hiddenCustomizations}
            />
          )}
          <CustomizationsSection
            key={`customization_section${styleTemplateChange}`}
          >
            {currentSchema.customizations.map((cust, idx) => (
              <MaybeFiltered
                key={`${device}-${cust.key}`}
                isVisible={isCustomizationVisible(cust)}
              >
                <CustomizationGroup
                  custIdx={idx}
                  customization={cust}
                  onDisabledStatusChanged={updateCustomizationStatus}
                  getResponsiveValue={getResponsiveValue}
                  setResponsiveValue={setResponsiveValue}
                  stickyHeader={headerText}
                  stickyHeaderEnabled={stickyEnabled}
                  setStickyHeader={setHeaderText}
                  setStickyEnabled={setStickyEnabled}
                />
              </MaybeFiltered>
            ))}
          </CustomizationsSection>
          <VSpace value={3} />
        </InspectorWidgetLayout>
      </Wrapper>
    </UndoRedoCounterContext.Provider>
  );
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: ${(props: { device: DeviceType }) =>
    props.device === DeviceType.Desktop ? `100%` : `65%`};

  #${INSPECTOR_BACK_ID} {
    z-index: 31;
  }
`;

export const CustomizationsSection = styled.div`
  && > :not(:first-child) {
    margin-top: 2.5rem;
    border-top: 1px solid rgba(218, 223, 226, 0.6);
    padding: 2rem 0;
  }
`;

const FooterSection = styled(GlossyWrapper)`
  && {
    display: grid;

    grid-template-columns: 0.5fr 2fr;
    grid-gap: 2rem;
    margin-left: -2rem;
    z-index: 40;
    margin-bottom: 2rem;
  }
`;
