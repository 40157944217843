/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { maybe } from '@/features/details/utils';
import { PREVIEW_THEME_ID } from '@/utils/types';

export function urlNoPreviewThemeId(item: string) {
  return maybe(() => {
    const x = new URL(item);
    x.searchParams.delete(PREVIEW_THEME_ID);
    return x.toString();
  }, item)!;
}
