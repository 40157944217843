import { useEffect, useState } from 'react';
import { useComplexState } from '@/utils/use-complex-state';

export type PaginatedResult = Partial<{ total?: number }>;

export type PaginatedCall<T> = (
  page: number,
  size: number,
  options?: any,
) => Promise<PaginatedResult & T>;

interface Deps {
  page?: number;
  extraArgs?: any;
  refresh?: boolean;
}

function isDepsReady(deps: Deps, hasArgs: boolean) {
  if (deps.page !== undefined) {
    if (hasArgs) {
      return deps.extraArgs !== undefined;
    }
    return true;
  }
  return false;
}

export function usePagination<T>(
  call: PaginatedCall<T>,
  pageSize: number,
  initialArgs?: any,
) {
  const [current, setCurrent] = useState<T>(null);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [maxPages, setMaxPages] = useState(0);
  const [extraArgs, setExtraArgs] = useState(initialArgs);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [deps, setDeps] = useComplexState<Deps>({} as Deps);
  const [lastDeps, setLastDeps] = useState<Deps>({} as Deps);

  useEffect(() => {
    setDeps((draft) => {
      if (page !== undefined) {
        draft.page = page;
      }

      if (extraArgs !== undefined) {
        draft.extraArgs = extraArgs;
      }

      if (refresh !== undefined) {
        draft.refresh = refresh;
      }
    });
  }, [page, extraArgs, refresh]);

  useEffect(() => {
    if (!isDepsReady(deps, initialArgs !== undefined)) {
      return;
    }
    if (JSON.stringify(deps) === JSON.stringify(lastDeps)) {
      return;
    }

    setLastDeps(deps);
    setLoading(true);
    call(deps.page, pageSize, { ...deps.extraArgs })
      .then((result) => {
        setCurrent(result);
        setTotal(result.total);
        setMaxPages(result.total / pageSize);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error(`pagination failed with err ${e}`);
        return [];
      });
  }, [deps]);

  const next = () => {
    if (maxPages < page + 1) {
      setPage(page + 1);
    }
  };

  const back = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const setArgs = (args: any) => {
    setExtraArgs({ ...args });
    setPage(0);
    setLoading(true);
  };

  return {
    loading,
    page,
    setPage,
    next,
    back,
    current,
    total,
    maxPages,
    setArgs,
    setCurrent,
    setTotal,
    refreshList: () => setRefresh(!refresh),
  };
}
