import React from 'react';
import Select, { StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { groupBy } from '@/utils/arrays';

export interface SelectOptions {
  key: string | number;
  value: string;
  group?: string;
  image?: string;
}

type Props = {
  options: SelectOptions[];
  defaultValue?: string;
  onSelection?: (v: string) => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  maxWidth?: number;
  minWidth?: number;
  isDisabled?: boolean;
  addOption?: boolean;
  components?: any;
};

export function SmallSelect({
  options,
  onSelection,
  defaultValue,
  maxWidth,
  minWidth,
  isDisabled,
  onMenuOpen,
  onMenuClose,
  addOption,
  components,
}: Props) {
  if (!options) {
    return null;
  }

  function onlyUnique(value, index, self) {
    return self?.map((x) => x?.key)?.indexOf(value?.key) === index;
  }

  let selectOptions: any = (options?.filter(onlyUnique) || []).map((x) => ({
    value: x.key as any,
    label: getCaptionLabel(x.value),
    group: x?.group,
  }));

  // @ts-ignore
  const grouped = groupBy(selectOptions, (item) => item.group);
  if (Object.keys(grouped).length > 1) {
    selectOptions = Object.entries(
      // @ts-ignore
      groupBy(selectOptions, (item) => item.group),
    ).map(([k, v]) => ({
      label: k,
      options: v,
    }));
  }

  const defaultLabel =
    options?.find((o) => o.key === defaultValue)?.value || defaultValue;
  const calculatedMinWidth = calcMinWidth(options, maxWidth);

  if (addOption) {
    return (
      <CreatableSelect
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        isDisabled={isDisabled}
        menuPosition="fixed"
        menuPlacement="bottom"
        formatCreateLabel={(inputValue) => inputValue}
        styles={selectStyles(maxWidth, minWidth || calculatedMinWidth)}
        defaultValue={{
          value: defaultValue as any,
          label: getCaptionLabel(defaultLabel),
        }}
        onChange={(x: any) => {
          onSelection && onSelection(x.value);
        }}
        options={selectOptions}
      />
    );
  }
  return (
    <Select
      className="vsly-small-select"
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      isDisabled={isDisabled}
      menuPosition="fixed"
      components={components}
      menuPlacement="bottom"
      styles={selectStyles(maxWidth, minWidth || calculatedMinWidth)}
      defaultValue={{
        value: defaultValue as any,
        label: getCaptionLabel(defaultLabel),
      }}
      onChange={(x: any) => {
        onSelection && onSelection(x.value);
      }}
      options={selectOptions}
    />
  );
}

function calcMinWidth(options: SelectOptions[], maxWidth: number) {
  const minimumWidth =
    Math.max(...options.map((x) => x?.value?.toString()?.length || 1)) * 11;
  if (maxWidth) {
    return Math.min(maxWidth, minimumWidth);
  }
  return Math.min(280, minimumWidth);
}

const renamedCaptions = new Map([
  [`Categories`, `Collection Page`],
  [`Category`, `Collection`],
  [`Products`, `Product Page`],
]);

export function getCaptionLabel(caption: string): string {
  if (renamedCaptions.has(caption)) {
    return renamedCaptions.get(caption);
  }
  return caption;
}

function selectStyles(maxWidth: number, minWidth?: number) {
  return {
    menuPortal: (styles) => ({
      ...styles,
      zIndex: `9999999999999999`,
      border: `none`,
    }),
    menu: (styles) => ({
      ...styles,
      minWidth: `150px`,
      borderRadius: `1.5rem`,
      padding: `1rem`,
      border: `none`,
      boxShadow: `0 1px 1px 0 rgba(0,0,0,0.22), 0 5px 32px 0 rgba(182,207,205,0.47)`,
    }),
    menuList: (styles) => ({
      ...styles,
      border: `none`,
    }),
    group: (styles) => ({
      ...styles,
      color: `#9BA7B3`,
      fontSize: `1.6rem`,
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      fontSize: `1.2rem`,
      fontWeight: isSelected ? `bolder` : `bold`,
      textAlign: `start`,
      textTransform: `uppercase`,
      fontFamily: `JetBrains Mono,serif`,
      color: isSelected ? `#0023FF` : `#3E4B57`,
      background: `transparent`,
      marginLeft: `1rem`,
      cursor: `pointer`,
      ':hover': {
        color: isSelected ? `#0023FF` : `#0980ee`,
      },
      ':active': {
        background: `none`,
      },
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: `none`,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: `0 0 0 0`,
      width: `1.4rem`,
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      padding: `0`,
      marginLeft: `1rem`,
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: `0 0 0px 0`,
    }),
    singleValue: (styles) => ({
      ...styles,
      padding: `0`,
      color: `#656E72`,
      fontFamily: `JetBrains Mono,serif`,
      textTransform: `uppercase`,
      fontWeight: `bold`,
      fontSize: `1.2rem`,
    }),
    control: (styles) => ({
      ...styles,
      cursor: `pointer`,
      borderRadius: `2rem`,
      border: `1.5px solid #DCD7D7`,
      '&:hover': {
        border: `1.5px solid #DCD7D7`,
      },
      boxShadow: `0 !important`,
      padding: `0 1rem 0 2rem`,
      maxWidth: maxWidth ? `${maxWidth}px` : `18rem`,
      minWidth: minWidth ? `${minWidth}px` : `6rem`,
      maxHeight: `3.4rem`,
      minHeight: `3.4rem`,
    }),
    input: (styles) => ({
      ...styles,
      padding: `0`,
    }),
    placeholder: (styles) => ({
      ...styles,
    }),
  } as StylesConfig;
}
