import * as React from 'react';
import { useContext, useMemo } from 'react';
import { EditorContext } from '@/features/editor/context/editor-context';
import {
  ExperienceStatus,
  UNNAMED_EXPERIENCE,
} from '@/webapi/use-experience-api';
import { AccountContext } from '@/features/account-context';
import { StoreStatsKind } from '@/webapi/use-store-stats';
import { InlineTextEdit } from '@/components/inline-text-edit';
import { VSpace } from '@/components/spacing';

export interface EditorRenameButtonProps {
  nonEditPrefix?: string;
  nonEditSuffix?: string;
}

export function EditorRenameButton({
  nonEditPrefix,
  nonEditSuffix,
}: EditorRenameButtonProps) {
  const { isStoreGoalReached } = useContext(AccountContext);
  const {
    experienceState: { currentExperience, setExperienceName },
  } = useContext(EditorContext);

  const isUnnamed = useMemo(
    () =>
      currentExperience.name === UNNAMED_EXPERIENCE ||
      currentExperience.name === ``,
    [currentExperience.name],
  );

  const experienceTitle = useMemo(() => {
    if (isUnnamed) {
      if (!isStoreGoalReached(StoreStatsKind.HAS_SAVED_ONE_EXPERIENCE)) {
        return `Welcome, Let's create your first experience`;
      }
      return `Experience Name`;
    }
    return currentExperience.name;
  }, [currentExperience.name, isUnnamed]);

  const onChange = (text: string) => {
    setExperienceName(text);
    return Promise.resolve();
  };

  return (
    <>
      <VSpace value={0.2} />
      <InlineTextEdit
        noConfirm={currentExperience?.status === ExperienceStatus.DRAFT}
        confirmText="Are you sure you want to change the experience name? Reports will be updated based on the new name"
        disableEdit={
          !isStoreGoalReached(StoreStatsKind.HAS_SAVED_ONE_EXPERIENCE)
        }
        defaultValue={experienceTitle}
        fontSize="2.5rem"
        fontWeight="800"
        onChange={onChange}
        nonEditPrefix={nonEditPrefix}
        nonEditSuffix={nonEditSuffix}
        resetOnEditWhenMatch="Experience Name"
      />
    </>
  );
}
