import InfiniteScroll from 'react-infinite-scroll-component';
import React from 'react';
import styled from 'styled-components';
import { Product } from '@/features/editor/widgets/custom-widget/loading-section/shared/models';
import { Spinner } from '@/Spinner';
import { ProductTile } from '@/features/editor/widgets/custom-widget/loading-section/shared/product-tile';
import { VSpace } from '@/components/spacing';

export function ProductSearchInner({
  hasMore,
  next,
  products,
  searchLoading,
  searchTotal,
  setManualImageSelectionOpen,
  selectProductForSlot,
  productSearchSlotOpen,
}: {
  searchLoading: boolean;
  searchTotal: number;
  productSearchSlotOpen: number;
  next: () => void;
  hasMore: boolean;
  products: Product[];
  setManualImageSelectionOpen: (p: Product, slotId: number) => void;
  selectProductForSlot: (p: Product, slotId: number) => void;
}) {
  const onClick = (p: Product) => () => {
    if (p.missingVariantImageMapping) {
      setManualImageSelectionOpen(p, productSearchSlotOpen);
    } else {
      selectProductForSlot(p, productSearchSlotOpen);
    }
  };

  return (
    <Box className="product-search-box">
      {searchLoading && (
        <SpinnerWrapper>
          <Spinner size={40} />
        </SpinnerWrapper>
      )}
      <InfiniteScroll
        dataLength={searchTotal || 0}
        next={next}
        hasMore={hasMore}
        loader={null}
        height="75rem"
        style={{ minWidth: `100%`, width: `100%`, paddingBottom: `8rem` }}
      >
        <Grid>
          {products?.map((p: Product) => (
            <ProductTile
              key={`${p.id}${p.mainValue}`}
              onClick={onClick(p)}
              p={p}
            />
          ))}
          <VSpace value={40} />
        </Grid>
      </InfiniteScroll>
    </Box>
  );
}

const Box = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 2rem;
  height: 100%;
  width: 100%;
`;

const SpinnerWrapper = styled.div`
  margin-top: -2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  pointer-events: none;
  position: absolute;
`;
