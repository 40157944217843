import React, { useContext } from 'react';
import styled from 'styled-components';
import { QueryBuilder } from '@/components/query-builder';
import { StrategyPerSlotContext } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/context/context';
import { RecommendationType } from '@/webapi/use-widget-catalog-api';
import { useConditionalQueryBuilder } from '@/features/editor/widgets/custom-widget/loading-section/util/useQueryBuilder';
import { VSpace } from '@/components/spacing';

export function ProductFilters() {
  const ctx = useContext(StrategyPerSlotContext);
  const { condition } = useConditionalQueryBuilder(
    ctx?.getOpenCondSlot()?.strategy || RecommendationType.MANUAL,
  );
  if (!ctx.isProductCondOpen) {
    return null;
  }
  return (
    <Container>
      <QueryBuilder
        onChange={(x) => ctx.setSlotCond(x)}
        initialProps={condition}
        initialState={ctx.getCurrentCond()}
      />
      <VSpace value={3} />
    </Container>
  );
}

const Container = styled.div`
  min-width: 80rem;
`;
