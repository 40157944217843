import * as React from 'react';
import styled from 'styled-components';
import { useContext, useEffect, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  CatalogWidgetProps,
  CustomizationSpec,
} from '@/webapi/use-widget-catalog-api';
import { CustomizationGroup } from '@/features/editor/widgets/custom-widget/inputs/shared/customization-group';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';
import { mutateFn } from '@/utils/use-complex-state';
import { InputType } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { asVisualPropsChange } from '@/features/editor/widgets/visual-editor/legacy/visual-editor-transpiler';

export interface LegacyVisualEditorProps {
  visualChanges: Record<string, CustomizationSpec>;
  setVisualChanges: (fn: mutateFn<Record<string, CustomizationSpec>>) => void;
  visualProps: CatalogWidgetProps;
  initSpecs?: Record<string, CustomizationSpec>;

  onChange(
    js: string,
    css: string,
    changedSpecs: Record<string, CustomizationSpec>,
  );
}

export function LegacyVisualEditor({
  visualProps,
  onChange,
  visualChanges,
  setVisualChanges,
  initSpecs,
}: LegacyVisualEditorProps) {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);

  const getResponsiveValue = (
    custId: number,
    compId: number,
    specId: number,
    key: string,
    _device: DeviceType,
  ): any => {
    const specKey = formatKey(custId, compId, specId);
    let spec: CustomizationSpec;

    if (visualChanges?.[specKey]) {
      spec = visualChanges[specKey];
    } else {
      spec =
        visualProps.customizations[custId].components[compId].specs[specId];
    }

    return spec.values?.[key];
  };

  const setResponsiveValue = (
    custId: number,
    compId: number,
    specId: number,
    key: string,
    value: any,
    _device: DeviceType,
  ) => {
    setVisualChanges((draft) => {
      const specKey = formatKey(custId, compId, specId);
      let spec: CustomizationSpec;
      if (draft?.[specKey]) {
        spec = draft[specKey];
      } else {
        spec = JSON.parse(
          JSON.stringify(
            visualProps.customizations[custId].components[compId].specs[specId],
          ),
        );
      }
      spec.values[key] = value;
      draft[specKey] = spec;
    });
  };

  const debouncedOnChange = useDebouncedCallback(onChange, 50);

  const hasChange = useMemo(() => {
    const left = JSON.stringify(visualChanges);
    const right = JSON.stringify(
      getInitialSpecForChangeDetection(visualProps, initSpecs),
    );
    return left !== right;
  }, [visualChanges]);

  useEffect(() => {
    if (hasChange && Object.entries(visualChanges)?.length > 0) {
      const transpiled = asVisualPropsChange(visualChanges);
      debouncedOnChange(transpiled.js, transpiled.css, visualChanges);
    }
  }, [visualChanges]);

  return (
    <Wrapper>
      {visualProps?.customizations.map((cust, idx) => (
        <CustomizationGroup
          key={`${device}-${cust.key}`}
          custIdx={idx}
          customization={cust}
          onDisabledStatusChanged={undefined}
          getResponsiveValue={getResponsiveValue}
          setResponsiveValue={setResponsiveValue}
        />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  && > * {
    margin-bottom: 3rem;
  }
`;

export const formatKey = (
  custId: number,
  compId: number,
  specId: number,
): string => `${custId}-${compId}-${specId}`;

const getInitialSpecForChangeDetection = (
  visualProps: CatalogWidgetProps,
  initSpecs?: Record<string, CustomizationSpec>,
): Record<string, CustomizationSpec> => {
  const out = { ...initSpecs } || {};

  visualProps.customizations.forEach((cust, custId) => {
    cust.components.forEach((comp, compId) => {
      comp.specs.forEach((spec, specId) => {
        const specKey = formatKey(custId, compId, specId);
        if (
          !out?.[specKey] &&
          [
            InputType.BORDER.toString(),
            InputType.BOX_SHADOW.toString(),
            InputType.TEXT_SHADOW.toString(),
          ].includes(spec.type)
        ) {
          out[specKey] = spec;
        }
      });
    });
  });

  return out;
};
