import { useContext } from 'react';
import * as React from 'react';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';
import {
  DropdownInput,
  DropdownInputOption,
} from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { InputsDarkTheme } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';

export function PostPurchaseReviews() {
  const {
    props: {
      settings: { ratingType, ratingPlacement },
    },
    setProps,
  } = useContext(PostPurchaseContext);

  const placementOptions: DropdownInputOption[] = [
    { label: `Without Product Ratings`, value: `none` },
    { label: `With Product Ratings Above Image`, value: `above` },
    { label: `With Product Ratings Below Image`, value: `below` },
  ];

  const typeOptions: DropdownInputOption[] = [
    { label: `Without Product Rating Count`, value: `stars` },
    { label: `With Product Rating Count`, value: `stars-count` },
  ];

  const onPlacementChanged = (val: DropdownInputOption) => {
    setProps((draft) => {
      draft.settings.ratingPlacement = val.value;
    });
  };

  const onTypeChanged = (val: DropdownInputOption) => {
    setProps((draft) => {
      draft.settings.ratingType = val.value;
    });
  };

  const placementDefaultValue = () => {
    switch (ratingPlacement) {
      case `none`:
        return placementOptions[0];
      case `above`:
        return placementOptions[1];
      case `below`:
        return placementOptions[2];
      default:
        return placementOptions[0];
    }
  };

  const typeDefaultValue = () => {
    switch (ratingType) {
      case `stars`:
        return typeOptions[0];
      case `stars-count`:
        return typeOptions[1];
      default:
        return typeOptions[0];
    }
  };

  return (
    <>
      <DropdownInput
        theme={InputsDarkTheme}
        options={placementOptions}
        defaultValue={placementDefaultValue()}
        onChange={onPlacementChanged}
        optionStyles={{ textTransform: `uppercase` }}
      />
      {ratingPlacement && ratingPlacement !== `none` && (
        <DropdownInput
          theme={InputsDarkTheme}
          options={typeOptions}
          defaultValue={typeDefaultValue()}
          onChange={onTypeChanged}
          optionStyles={{ textTransform: `uppercase` }}
        />
      )}
    </>
  );
}
