import { useCallback, useMemo } from 'react';
import { CatalogWidgetProps } from '@/webapi/use-widget-catalog-api';
import { CustomWidgetStep } from '@/features/editor/widgets/custom-widget/shared/context';
import {
  mergeDevicesOnGeneral,
  parseGeneralSettings,
} from '@/features/editor/widgets/custom-widget/env';
import { DeviceType } from '@/utils/definitions';

export function useRuleEvaluator(
  currentSchema: CatalogWidgetProps,
  currentStep: CustomWidgetStep,
  device: DeviceType,
) {
  const general = useMemo(() => {
    if (!currentSchema?.settings?.general) return [];
    let general = parseGeneralSettings(currentSchema.settings.general.specs);
    if (device === DeviceType.Desktop) {
      general = mergeDevicesOnGeneral(general);
    }
    return general;
  }, [currentSchema?.settings?.general, device]);

  const context = useMemo(() => {
    switch (currentStep) {
      case CustomWidgetStep.SELECT:
        return `select`;
      case CustomWidgetStep.CUSTOMIZE:
        return `quick`;
      case CustomWidgetStep.STYLE:
        return `extended`;
      default:
        return `select`;
    }
  }, [currentStep, device]);

  const evaluateRule = useCallback(
    (expr: string | undefined): boolean => {
      if (!expr) return false;

      // eslint-disable-next-line no-new-func
      const fn = new Function(
        `
      var general = arguments[0];
      var context = arguments[1];
      return (${expr})
     `,
      );
      return fn(general, context) as boolean;
    },
    [general, context],
  );

  return { evaluateRule };
}
