import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { NumberInput } from '@/features/editor/widgets/custom-widget/inputs/shared/number-input';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';

const VALUE_KEY = `letterSpacing`;

export function LetterSpacingInput({
  initialValues,
  onValuesChanged,
}: GenericInputProps) {
  const { undoRedoCount } = useContext(UndoRedoCounterContext);
  const getValue = useCallback(() => initialValues(VALUE_KEY) || `0`, []);

  const [value, setValue] = useState(getValue());
  useEffect(() => setValue(getValue()), [undoRedoCount]);

  const onChange = (value) => {
    setValue(value);
  };

  useEffect(() => {
    onValuesChanged(VALUE_KEY, value);
  }, [value]);

  return (
    <Wrapper>
      <NumberInput
        min={-100}
        max={100}
        suffix="px"
        onChange={onChange}
        defaultValue={value}
        step={0.1}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;
