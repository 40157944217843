import React, { FC } from 'react';
import styled from 'styled-components';
import { BigButton } from '@/components/big-button';

const AreYouSureContainer = styled.div`
  padding: 2rem;
  border-radius: 20px;
  border: 1.7px solid #bfcdd1;
  box-shadow: 0 22px 154px 0 rgba(0, 0, 0, 0.17),
    0 18px 46px 0 rgba(177, 217, 203, 0.18), 0 8px 24px 0 rgba(0, 0, 0, 0.12);
  background: white;
  p {
    text-align: center;
    font-family: 'JetBrains Mono', serif;
    font-size: 1.5rem;
    font-weight: 500;
    user-select: none;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      margin: 0 0.5rem;
    }
  }
`;

interface AreYouSureProps {
  close: () => void;
  onYes: () => void;
  text?: string;
}

export const AreYouSure: FC<AreYouSureProps> = ({ close, onYes, text }) => (
  <AreYouSureContainer>
    <p>{text || `Are you sure ?`}</p>
    <div>
      <BigButton
        onClick={onYes}
        border="2px solid black"
        noTransform
        size="small"
        fillWidth
      >
        Yes
      </BigButton>
      <BigButton
        border="2px solid #C8CACB"
        background="white"
        color="#97A0A8"
        noShadow
        noTransform
        size="small"
        fillWidth
        onClick={close}
      >
        No
      </BigButton>
    </div>
  </AreYouSureContainer>
);
