import { createContext, useContext, useEffect, useState } from 'react';
import { StoreStatsKind } from '@/webapi/use-store-stats';
import { AccountContext, useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import { nav } from '@/utils/browser';
import { editorRoute } from '@/webapi/pages';
import { markNavToEditorOnFirstUseAsDone } from '@/utils/should-nav-to-editor-on-first-use';

export interface WelcomeModalCtx {
  currentStep: WelcomeModalStep;
  setCurrentStep: (step: WelcomeModalStep) => void;
  progress: number;

  footer: any;
  setFooter: (footer: any) => void;
  isVisible: boolean;
  setIsVisible: (visible: boolean) => void;
  onCompleteQuiz: () => void;
  isGoalReached: (stat: StoreStatsKind) => boolean;
  trackIfGoalNotReached: (
    stat: StoreStatsKind,
    ...dedupKeys: string[]
  ) => Promise<boolean>;
}

export const WelcomeModalContext = createContext({} as WelcomeModalCtx);

export function newWelcomeModalContext(): WelcomeModalCtx {
  const isV2Ftue = useFeatureBit(FeatureBit.ENABLE_V2_FTUE);
  const {
    isStoreGoalReached: isGoalReached,
    trackIfStoreGoalNotReached: trackIfGoalNotReached,
  } = useContext(AccountContext);

  const [currentStep, setCurrentStep] = useState(WelcomeModalStep.INTRO);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [progress, setProgress] = useState(20);

  const [footer, setFooter] = useState<any>(undefined);
  const [isVisible, setIsVisible] = useState(true);

  const onCompleteQuiz = async () => {
    await trackIfGoalNotReached(StoreStatsKind.IS_WELCOME_QUIZ_DONE);
    setIsVisible(false);

    if (isV2Ftue) {
      markNavToEditorOnFirstUseAsDone();
      await nav(editorRoute());
    }
  };

  useEffect(() => {
    if (currentStep === WelcomeModalStep.INTRO) {
      setProgress(20);
    } else if (currentStep === WelcomeModalStep.EMAIL_SETUP) {
      setProgress(40);
    } else if (currentStep === WelcomeModalStep.ENABLE_APP_EMBED) {
      trackIfGoalNotReached(StoreStatsKind.IS_WELCOME_EMAIL_INVITE_DONE);
      setProgress(60);
    } else if (currentStep === WelcomeModalStep.BOOK_DEMO) {
      trackIfGoalNotReached(StoreStatsKind.IS_WELCOME_EMAIL_INVITE_DONE);
      trackIfGoalNotReached(StoreStatsKind.IS_WELCOME_APP_EMBED_DONE);
      setProgress(80);
    } else {
      setProgress(100);
    }
  }, [currentStep]);

  return {
    currentStep,
    setCurrentStep,
    progress,
    footer,
    setFooter,
    isVisible,
    setIsVisible,
    onCompleteQuiz,
    trackIfGoalNotReached,
    isGoalReached,
  };
}

export enum WelcomeModalStep {
  INTRO = 0,
  EMAIL_SETUP = 1,
  ENABLE_APP_EMBED = 2,
  BOOK_DEMO = 3,
}
