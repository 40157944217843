/* eslint-disable no-promise-executor-return */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  CompoundBlock,
  EditorDeclarativeBlock,
} from '@/webapi/use-experience-api';
import { EditorContext } from '@/features/editor/context/editor-context';
import { CatalogWidget } from '@/webapi/use-widget-catalog-api';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import { CatalogAppTile } from '@/features/editor/widgets/shared/apps-catalog/app-tile';
import { wait } from '@/utils/browser';
import { setIsVideoReplaceWasShown } from '@/features/editor/widgets/custom-widget/inputs/background/video/context';
import { CatalogAppsGrid } from '@/features/editor/widgets/shared/apps-catalog/apps-list';
import { createNewElementMessageFromOrigChange } from '@/features/editor/context/use-transpiler';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import { PageKind } from '@/features/editor/context/use-device-navigation';
import { groupBy } from '@/utils/arrays';
import { AppsCatalogGridDivider } from '@/features/editor/widgets/shared/apps-catalog/divider';

export function BlueprintSelect() {
  const {
    deviceNavigation: { urlToStorePage, previewUrl },
    experienceState: { currentExperience },
    devicePreview: {
      editorState: { device },
    },
    inspectorNav: { gotoAppCatalog },
    transpiler: { asWidgetChange },
  } = useContext(EditorContext);
  setIsVideoReplaceWasShown(false);
  const didHover = useRef(false);

  const {
    gotoCustomize,
    setNewChange,
    newChange: change,
    catalogApp,
  } = useContext(CustomWidgetContext);

  const onBlueprintSelected = async (widget: CatalogWidget) => {
    const schema = getBlueprintStyledSchema(widget);

    if (!didHover.current) {
      setNewChange(asWidgetChange(change, widget, schema));
      wait(200).then(() => gotoCustomize(widget));
    } else {
      gotoCustomize(
        {
          ...widget,
          blueprint: { ...widget.blueprint, schema },
        },
        true,
      );
    }
  };

  const [origChange] = useState({ ...change });

  const onShowBlueprintPreview = async (widget: CatalogWidget) => {
    didHover.current = true;
    const schema = getBlueprintStyledSchema(widget);
    setNewChange(asWidgetChange(change, widget, schema));
  };

  const onShowDefaultPlaceholder = () => {
    setNewChange(defaultPlaceholder(origChange));
  };

  useEffect(() => {
    onShowDefaultPlaceholder();
  }, []);

  const visibleWidgets = useMemo(
    () => catalogApp?.widgets.filter((v) => !v?.isHidden),
    [
      catalogApp?.widgets,
      currentExperience?.targeting?.placement?.kind,
      previewUrl,
    ],
  );

  const visibleWidgetsByGroups = useMemo<Record<string, CatalogWidget[]>>(
    () => groupBy(visibleWidgets, (widget) => widget.group),
    [visibleWidgets],
  );

  const allGroups = useMemo(
    () =>
      Object.keys(visibleWidgetsByGroups).sort((a, b) => {
        if (a === `undefined`) return -1;
        if (b === `undefined`) return 1;
        return a.localeCompare(b);
      }),
    [visibleWidgetsByGroups],
  );

  const isDisabledWidget = (widget: CatalogWidget): boolean => {
    if (isSocialProofWidget(widget) || isLowStockWidget(widget)) {
      return urlToStorePage(previewUrl).kind !== PageKind.PRODUCT;
    }
    return false;
  };

  const getDisabledText = (widget: CatalogWidget): string => {
    if (isSocialProofWidget(widget) || isLowStockWidget(widget)) {
      return `This widget is only available on product pages`;
    }
    return ``;
  };

  const onBack = () => {
    gotoAppCatalog(createNewElementMessageFromOrigChange(origChange));
  };

  return (
    <InspectorWidgetLayout
      progress={50}
      title="2. Choose a template"
      backCaption="< Back to widgets"
      onBackClicked={onBack}
    >
      <Wrapper>
        {allGroups.map((group) => (
          <Wrapper>
            {group !== `undefined` && (
              <AppsCatalogGridDivider key={group} longTiles={false}>
                {group}
              </AppsCatalogGridDivider>
            )}
            <CatalogAppsGrid device={device}>
              {visibleWidgetsByGroups[group].map((widget) => (
                <CatalogAppTile
                  key={widget?.id}
                  app={widget}
                  onClick={() => onBlueprintSelected(widget)}
                  onMouseEnter={() => onShowBlueprintPreview(widget)}
                  onMouseLeave={onShowDefaultPlaceholder}
                  isDisabled={isDisabledWidget(widget)}
                  disabledText={getDisabledText(widget)}
                />
              ))}
            </CatalogAppsGrid>
          </Wrapper>
        ))}
      </Wrapper>
    </InspectorWidgetLayout>
  );
}

export function defaultPlaceholder(
  origChange: EditorDeclarativeBlock,
): EditorDeclarativeBlock {
  const value = origChange.block.value as CompoundBlock;
  value.html = `
<section id="${origChange.editorId}" class="__loomi_custom_widget_placeholder">
    Hover on one of the options to get a quick preview of the widget
</section>`;

  value.css = `
    .__loomi_custom_widget_placeholder {
        font-size: 1.2rem;
        color: #97A2AD;
        text-align: center;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 15rem;
        width: 100%;
        padding: 0 2rem;
        margin: 1rem 0;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.15), 0px 4px 11px 0px rgba(151, 210, 206, 0.22);
      }`;

  value.js = ``;
  origChange.block.value = value;
  return origChange;
}

function isSocialProofWidget(v: CatalogWidget) {
  return (
    v.id === `8736e8d02d0a41499c2aeec40e93a434` ||
    v.id === `8736e8d02d0a41499c2aeec40e93a43412`
  );
}
function isLowStockWidget(v: CatalogWidget) {
  return (
    v.id === `ad8543a0fafe469c85901ed711dd0052` ||
    v.id === `ad8543a0fafe469c85901ed711dd0052333`
  );
}

function getBlueprintStyledSchema(widget: CatalogWidget) {
  return {
    ...widget.blueprint.schema,
    customizations: widget.blueprint.schema.customizations,
  };
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
