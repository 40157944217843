import { useContext, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import produce from 'immer';
import { toast } from 'react-toastify';
import { css } from 'styled-components';
import { EditorContext } from '@/features/editor/context/editor-context';
import { useComplexState } from '@/utils/use-complex-state';
import {
  CompoundBlock,
  EditorChangeKind,
  EditorDeclarativeBlock,
} from '@/webapi/use-experience-api';
import {
  CSS,
  extractCodeFromChange,
  hasHtmlDiff,
  HTML,
  iifeWrap,
  JS,
} from '@/features/editor/widgets/code-editors/utils';
import { isString } from '@/utils/types';
import { AnchorOrigin } from '@/features/editor/context/use-device-preview';
import { TEMP_NO_SELECTOR } from '@/features/editor/widgets/changelog/placeholder';
import { CompoundChange, HtmlMutationKind } from '@/pkg/sdk';

export function useCodeEditor(
  initialChange: EditorDeclarativeBlock,
  isHtmlEditable: boolean,
) {
  const {
    applyTempChange: _applyTempChange,
    experienceState: { upsertEditorChange, removeEditorChange },
    inspectorNav: { gotoChangelog },
    devicePreview: {
      anchor,
      leaveAnchor,
      editorState: { device },
    },
    transpiler: { asHideElementBlock },
  } = useContext(EditorContext);

  const applyTempChange = useDebouncedCallback(_applyTempChange, 1000);
  const [isDirty, setDirty] = useState(false);
  const [change, setChange] = useComplexState(initialChange);

  const getCurrentEditor = () => {
    if (
      change.editorKind === EditorChangeKind.GLOBAL_CSS ||
      change.editorKind === EditorChangeKind.HIDE_COMPONENT
    ) {
      return CSS;
    }
    if (change.editorKind === EditorChangeKind.GLOBAL_JS) {
      return JS;
    }
    return HTML;
  };

  const [currentEditor, setCurrentEditor] = useState(getCurrentEditor());

  const [showPlacementPicker, setShowPlacementPicker] = useState(
    TEMP_NO_SELECTOR === initialChange?.block?.selector,
  );

  const onLocationChanged = (selector: string, placement: HtmlMutationKind) => {
    setChange((draft) => {
      draft.block.selector = selector;
      (draft.block.value as CompoundChange).htmlKind = placement;
    });
  };

  const prettyCode = useMemo(() => extractCodeFromChange(change, true), []);

  const [htmlCode, setHtmlCode] = useState(prettyCode.html);
  const [cssCode, setCssCode] = useState(prettyCode.css);
  const [jsCode, setJsCode] = useState(prettyCode.js);

  const onHtmlCodeChanged = (value: string) => {
    setDirty(true);
    setHtmlCode(value);
    setChange((draft) => {
      if (isHtmlEditable) {
        (draft.block.value as CompoundBlock).html = value;
      } else {
        (draft.block.value as CompoundBlock).html = ``;
      }
    });
  };

  const onCssCodeChanged = (value: string) => {
    setDirty(true);
    setCssCode(value);
    setChange((draft) => {
      if (isString(draft.block.value)) {
        (draft.block.value as string) = value;
      } else {
        (draft.block.value as CompoundBlock).css = value;
      }
    });
  };

  const onJsCodeChanged = (value: string) => {
    setDirty(true);
    setJsCode(value);
    setChange((draft) => {
      if (isString(draft.block.value)) {
        (draft.block.value as string) = iifeWrap(value);
      } else {
        (draft.block.value as CompoundBlock).js = iifeWrap(value);
      }
    });
  };

  const onHide = () => {
    const delChange = asHideElementBlock(change.block.selector, device);
    removeEditorChange(change);
    upsertEditorChange(delChange);
    leaveAnchor();
  };

  const onSave = () => {
    const updated = produce(change, (draft) => {
      try {
        const currentHtml = (draft.block.value as CompoundBlock)?.html;
        if (
          currentHtml &&
          !hasHtmlDiff(draft) &&
          change.editorKind !== EditorChangeKind.NEW_COMPONENT
        ) {
          (draft.block.value as CompoundBlock).html = ``;
        }
      } catch (ex) {
        toast(
          `Oops! There are some errors in your HTML code. Please review the code and fix the errors before proceeding.`,
          {
            theme: `colored`,
            type: `error`,
            className: css({ fontFamily: `JetBrains Mono, Serif` }),
          },
        );
        throw ex;
      }
    });
    upsertEditorChange(updated);
    leaveAnchor();
    gotoChangelog();
  };

  useEffect(() => {
    anchor(change.editorSelector, AnchorOrigin.NEW_ELEMENT);
    if ([undefined, ``].includes(initialChange?.initialHtml)) {
      setChange((draft) => {
        draft.initialHtml = extractCodeFromChange(change, true).html;
      });
    }
  }, []);

  useEffect(() => {
    applyTempChange(change);
  }, [change]);

  return {
    showPlacementPicker,
    setShowPlacementPicker,
    onLocationChanged,
    currentEditor,
    setCurrentEditor,
    onSave,
    onHide,
    onHtmlCodeChanged,
    onCssCodeChanged,
    onJsCodeChanged,
    isDirty,
    htmlCode,
    cssCode,
    jsCode,
  };
}
