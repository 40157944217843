import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { DeviceType } from '@/utils/definitions';
import { HistoryOpts } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { MaybeFiltered } from '@/features/editor/widgets/custom-widget/shared/shared-styles';
import { GenericInput } from '@/features/editor/widgets/custom-widget/inputs';
import { ComponentGroupProps } from '@/features/editor/widgets/custom-widget/inputs/shared/components-group';
import { EditorContext } from '@/features/editor/context/editor-context';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import {
  hideSpecInHoverMode,
  isSpecHasHoverState,
} from '@/features/editor/widgets/custom-widget/inputs/shared/utils';

export function GroupContentSection({
  custIdx,
  compIdx,
  customization,
  setResponsiveValue,
  getResponsiveValue,
  isOpen,
  component,
  hoverMode,
}: ComponentGroupProps) {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);
  const { evaluateRule } = useContext(CustomWidgetContext);

  const hiddenSpecs = useMemo(() => {
    if (!evaluateRule) return [];
    return component?.specs
      ?.filter((spec) => evaluateRule(spec?.hideWhen))
      ?.map((spec) => spec.key);
  }, [component, device]);

  function getInitialValues(idx) {
    return (key?: string, d?: DeviceType, specIdx?: number): any =>
      getResponsiveValue(
        custIdx,
        compIdx,
        specIdx || idx,
        key,
        d || device,
        hoverMode,
      );
  }

  function setValues(idx) {
    return (
      key: string,
      value: any,
      d?: DeviceType,
      historyOpts?: HistoryOpts,
    ): void => {
      setResponsiveValue(
        custIdx,
        compIdx,
        idx,
        key,
        value,
        d || device,
        !!historyOpts?.ignoreHistory,
        !!historyOpts?.forceSameVersion,
        hoverMode,
      );
    };
  }

  const showSpec = (spec): boolean => {
    if (hoverMode)
      return isSpecHasHoverState(spec) && !hideSpecInHoverMode(spec);
    return true;
  };

  return (
    <Wrapper isOpen={isOpen} key={`vsly-${hoverMode}`}>
      {component?.specs?.map((spec, idx) => (
        <MaybeFiltered
          key={`${spec.key}-${spec.type}-${spec.name}-${custIdx}-${compIdx}-${idx}`}
          isVisible={!hiddenSpecs?.includes(spec.key)}
        >
          {showSpec(spec) && (
            <GenericInput
              customizationIdx={custIdx}
              customization={customization}
              componentIdx={compIdx}
              component={component}
              specIdx={idx}
              spec={spec}
              device={device}
              initialValues={getInitialValues(idx)}
              onValuesChanged={setValues(idx)}
              hover={hoverMode}
            />
          )}
        </MaybeFiltered>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: ${(props: { isOpen: boolean }) => (props.isOpen ? `flex` : `none`)};
  flex-direction: column;
  && > * {
    margin-top: 1.5rem;
  }
`;
