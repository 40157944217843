import * as React from 'react';
import styled from 'styled-components';
import { EditorDeclarativeBlock } from '@/webapi/use-experience-api';
import { UndoRedoBtns } from '@/components/undo-redo-btns';
import { BigButton } from '@/components/big-button';
import { DeviceType } from '@/utils/definitions';
import { Hint } from '@/features/editor/widgets/shared/hint';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import { INSPECTOR_BACK_ID } from '@/features/editor/inspector';
import { GlossyWrapper } from '@/components/glossy-wrapper';
import { CatalogWidgetProps } from '@/webapi/use-widget-catalog-api';
import {
  newVisualEditorContext,
  VisualEditorContext,
} from '@/features/editor/widgets/visual-editor/context';
import { VisualEditor } from '@/features/editor/widgets/visual-editor/visual-editor';
import {
  TextAlternatives,
  TextAltFooter,
} from '@/features/editor/widgets/visual-editor/text-alternatives';
import { useCustomizationName } from '@/features/editor/use-customization-name';

export interface VisualEditorWidgetProps {
  selector: string;
  change: EditorDeclarativeBlock;
  visualProps: CatalogWidgetProps;
}

export function VisualEditorWidget({
  selector,
  change,
  visualProps,
}: VisualEditorWidgetProps) {
  const ctx = newVisualEditorContext(selector, visualProps, change);
  const {
    gotoChangelog,
    undo,
    redo,
    canRedo,
    canUndo,
    device,
    onHide,
    isDirty,
    onSave,
    undoRedoContext,
  } = ctx;
  const { headerText, stickyEnabled, setStickyEnabled, setHeaderText } =
    useCustomizationName();
  const isTextAlternative = visualProps?.suggestAltTet;
  if (isTextAlternative) {
    return (
      <VisualEditorContext.Provider value={ctx}>
        <InspectorWidgetLayout
          title={`2. Let's look for an alternative text`}
          progress={70}
          backCaption="< Back to changes"
          onBackClicked={gotoChangelog}
          footer={
            <TextAltFooter device={device}>
              <BigButton
                color="white"
                background="#0062FF"
                noTransform
                size="large"
                disabled={false}
                onClick={onSave}
                border="none"
                fillWidth
              >
                Change
              </BigButton>
            </TextAltFooter>
          }
        >
          <Wrapper device={DeviceType.Desktop}>
            <VisualWrapper>
              <TextAlternatives />
            </VisualWrapper>
          </Wrapper>
        </InspectorWidgetLayout>
      </VisualEditorContext.Provider>
    );
  }
  return (
    <VisualEditorContext.Provider value={ctx}>
      <InspectorWidgetLayout
        title="Visual Editor"
        progress={0}
        backCaption="< Back to changes"
        onBackClicked={gotoChangelog}
        stickyHeader={headerText}
        stickyEnabled={stickyEnabled}
        footer={
          <Footer device={device}>
            <UndoRedoBtns
              undo={undo}
              redo={redo}
              canUndo={canUndo}
              canRedo={canRedo}
              debounceInterval={1500}
            />

            <BigButton
              border="2px solid #C8CACB"
              background="white"
              color="#97A0A8"
              noShadow
              noTransform
              size={device === DeviceType.Desktop ? `medium-thin` : `medium`}
              fillWidth
              onClick={onHide}
            >
              Remove
            </BigButton>

            <BigButton
              disabled={!isDirty}
              onClick={onSave}
              border="2px solid black"
              noTransform
              size={device === DeviceType.Desktop ? `medium-thin` : `medium`}
              fillWidth
            >
              Save
            </BigButton>
          </Footer>
        }
      >
        <Wrapper device={device}>
          <Hint id="code-hint">{change?.block?.selector || selector}</Hint>
          <VisualWrapper device={device}>
            <UndoRedoCounterContext.Provider value={undoRedoContext}>
              <VisualEditor
                stickyHeader={headerText}
                stickyHeaderEnabled={stickyEnabled}
                setStickyHeader={setHeaderText}
                setStickyEnabled={setStickyEnabled}
              />
            </UndoRedoCounterContext.Provider>
          </VisualWrapper>
        </Wrapper>
      </InspectorWidgetLayout>
    </VisualEditorContext.Provider>
  );
}

const Wrapper = styled.div`
  width: ${(props: { device: DeviceType; fb: boolean }) =>
    props.device === DeviceType.Desktop ? `100%` : `60%`};
  position: relative;
  background: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem 1fr;
  grid-row-gap: 2.5rem;

  #code-hint {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  #${INSPECTOR_BACK_ID} {
    z-index: 31;
  }
`;

const Footer = styled(GlossyWrapper)`
  && {
    left: -0.5rem;
    width: ${(props: { device: DeviceType }) =>
      props.device === DeviceType.Desktop ? `100%` : `60%`};
    display: grid;
    position: absolute;
    bottom: 2rem;
    grid-template-columns: 0.5fr 1fr 2fr;
    grid-gap: 2rem;
    z-index: 30;
  }
`;

const VisualWrapper = styled.div`
  height: 100%;
`;
