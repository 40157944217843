/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import styled from 'styled-components';
import { BsCheck } from 'react-icons/bs';
import { StaticImage } from 'gatsby-plugin-image';

export function ShopifyEnableCheckout() {
  return (
    <Wrapper>
      <Description>
        If you're a Shopify Plus store, you may need to enable your
        checkout.liquid <br /> layout before certain Visually blocks can be
        added.
      </Description>
      <Hint>How to Enable?</Hint>

      <Step>
        <Ordinal>1</Ordinal>
        <span>
          Navigate to your Online <br /> Store {`>`} Themes page
        </span>
        <Step1Image />
      </Step>

      <Step>
        <Ordinal>2</Ordinal>
        <span>
          Click on the menu icon of your active <br /> theme and click "Edit
          Code"
        </span>
        <Step2Image />
      </Step>

      <Step>
        <Ordinal>3</Ordinal>
        <span>
          Click "Add a new layout" located under the <br /> "Layout" folder
        </span>
        <Step3Image />
      </Step>

      <Step>
        <Ordinal>4</Ordinal>
        <span>
          On the drop down list, select "checkout" <br /> and click "Create
          layout"
        </span>
        <Step4Image />
      </Step>

      <Step>
        <Ordinal>5</Ordinal>
        <span>
          The new layout should be created successfully, <br /> and you should
          now see it in the list
        </span>
        <Step5Image />
      </Step>

      <FinalStep>
        <FinalOrdinal>
          <BsCheck size={30} />
        </FinalOrdinal>
        <span>
          Once you have completed all of these steps <br />
          the "Checkout Page" targeting should be enabled within 15 minutes.
        </span>
      </FinalStep>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  line-height: 1.5;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
`;

const Description = styled.div`
  color: #475562;
  font-weight: bold;
  letter-spacing: -0.51px;
  font-size: 1.8rem;
`;

const Hint = styled.span`
  font-family: 'JetBrains Mono', serif;
  color: #939699;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: -0.44px;
  font-size: 1.6rem;
`;

const Step = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1.5fr;
  align-items: center;
  grid-gap: 2rem;
  background: rgba(216, 216, 216, 0.28);
  border-radius: 1.5rem;
  padding: 2rem;
  color: #939699;
  font-weight: 500;
  font-size: 1.4rem;
  text-align: start;
`;

const Ordinal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #dbdfe2;
  padding: 1rem;
  border-radius: 50%;
  color: #939699;
  font-family: 'JetBrains Mono', serif;
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
  height: 3.5rem;
  width: 3.5rem;
`;

const FinalStep = styled(Step)`
  && {
    grid-template-columns: auto 1fr;
    background: rgba(155, 227, 161, 0.28);
    color: #79997c;
  }
`;

const FinalOrdinal = styled(Ordinal)`
  && {
    background: #c4e9c8;
    color: #79997c;
  }
`;

const Step1Image = () => (
  <ImageWrapper>
    <StaticImage
      width={320}
      src="./1.png"
      alt="step 1"
      loading="eager"
      placeholder="none"
    />
  </ImageWrapper>
);

const Step2Image = () => (
  <ImageWrapper>
    <StaticImage
      width={260}
      src="./2.png"
      alt="step 2"
      loading="eager"
      placeholder="none"
    />
  </ImageWrapper>
);

const Step3Image = () => (
  <ImageWrapper>
    <StaticImage
      width={280}
      src="./3.png"
      alt="step 3"
      loading="eager"
      placeholder="none"
    />
  </ImageWrapper>
);

const Step4Image = () => (
  <ImageWrapper>
    <StaticImage
      width={380}
      src="./4.png"
      alt="step 4"
      loading="eager"
      placeholder="none"
    />
  </ImageWrapper>
);

const Step5Image = () => (
  <ImageWrapper>
    <StaticImage
      width={200}
      src="./5.png"
      alt="step 5"
      loading="eager"
      placeholder="none"
    />
  </ImageWrapper>
);

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
