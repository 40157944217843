import styled from 'styled-components';

export const Card = styled.div`
  padding: 2rem;
  background: white;
  border: solid 1px #eaeef0;
  border-radius: 1rem;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.15),
    0 4px 11px 0 rgba(151, 210, 206, 0.22);

  display: flex;
  flex-direction: column;

  cursor: pointer;
  font-family: 'JetBrains Mono', serif;
  transition: background-color ease-out 0.3s;
  user-select: none;

  ${(props: { nohover?: boolean }) =>
    props.nohover
      ? ``
      : `
      :hover {
        background: rgb(254,254,254);
      }

      :active {
        background: rgb(250,250,250);
      }`}
`;
