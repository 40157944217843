import useEyeDropper from 'use-eye-dropper';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BsEyedropper } from 'react-icons/bs';

export function useEyeDropProps() {
  const { open, isSupported } = useEyeDropper();
  const [color, setColor] = useState<string | null>(null);
  const [error, setError] = useState<any>();
  return { open, isSupported, color, setColor, error, setError };
}

export function EyeDropper({
  open,
  isSupported,
  setColor,
  error,
  setError,
}: any) {
  useEffect(() => {
    if (error) {
      console.error(`eyeDropper error 3`, error);
    }
  }, []);

  const pickColor = useCallback(() => {
    const openPicker = async () => {
      try {
        const color = await open();
        setColor(color.sRGBHex);
      } catch (e) {
        console.log(e);
        if (!e.canceled) setError(e);
      }
    };
    openPicker();
  }, [open]);

  useEffect(() => {
    // @ts-ignore
    window.isEyedropperSupported = isSupported;
  }, []);
  if (!isSupported()) {
    return <Button />;
  }
  return (
    <Button type="button" onClick={pickColor}>
      <BsEyedropper />
    </Button>
  );
}

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  svg {
    margin-top: 46px;
  }
`;
