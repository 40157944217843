import { TiMinus, TiPlus } from 'react-icons/ti';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { AdvancedRuling } from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/context';
import { DeleteBtn } from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/rule/DeleteBtn';
import { IconButton } from '@/features/editor/widgets/custom-widget/loading-section/shared/iconButton';

export function RuleHeader({ num }: { num: number }) {
  const { toggleOpen, isOpen, ruleIdx } = useContext(AdvancedRuling);
  const open = isOpen(num);
  const onClick = () => open && toggleOpen(num);
  const idx = ruleIdx(num);
  return (
    <HeaderContainer isOpen={open}>
      <p>Rule #{idx}</p>
      <FlexRow>
        <DeleteBtn num={num} />
        <IconButton right="-5px" onClick={onClick}>
          {open ? <TiMinus size={20} /> : <TiPlus size={20} />}
        </IconButton>
      </FlexRow>
    </HeaderContainer>
  );
}

const FlexRow = styled.div`
  display: flex;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 1rem;
  margin: 1rem;

  p {
    user-select: none;
    margin: 0;
  }

  font-size: 1.7rem;
  color: #97a7b6;

  border-bottom: ${(p: { isOpen: boolean }) =>
    p.isOpen ? `1.7px solid rgba(151, 167, 182, 0.7);` : `none`};
  font-weight: 700;
  font-family: 'Inter', serif;
`;
