import { CachePolicies, useFetch } from 'use-http';
import { routes } from '@/webapi/routes';

export type AiResponse = {
  urls: string[];
  limitReached: boolean;
  error: boolean;
};

export function useImageGen() {
  const { post, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
    },
  }));

  function getImage(params: string): Promise<AiResponse> {
    return post(routes.imageAiGen, { params });
  }
  return { getImage, loading };
}
