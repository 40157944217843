import { DeviceType } from '@/utils/definitions';

export function shouldHideSpec(
  hideable: { hideOn?: DeviceType[] },
  device: DeviceType,
): boolean {
  if (hideable?.hideOn) {
    return hideable?.hideOn?.includes(device);
  }
  return false;
}

export function getSpecHiddenStyles(
  hideable: { hideOn?: DeviceType[] },
  device: DeviceType,
): any {
  if (shouldHideSpec(hideable, device)) return { display: `none` };
  return {};
}
