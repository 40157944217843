import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { animated, useSpring } from '@react-spring/web';
import { Caption } from '../../shared/shared-styles';

export interface CheckboxInputProps {
  defaultValue: boolean;
  children?: any;
  onChange?: (value: boolean) => void;
}

export function CheckboxInput({
  defaultValue,
  children,
  onChange,
}: CheckboxInputProps) {
  const [isSelected, setIsSelected] = useState<boolean>(defaultValue);

  const checkAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    reverse: !isSelected,
  });

  useEffect(() => {
    setIsSelected(defaultValue);
  }, [defaultValue]);

  const onToggle = () => {
    onChange && onChange(!isSelected);
    setIsSelected(!isSelected);
  };

  return (
    <Wrapper onClick={onToggle}>
      <CheckboxWrapper>
        <CheckmarkIcon spring={checkAnim} />
      </CheckboxWrapper>
      {children && <Caption>{children}</Caption>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  z-index: 32;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  grid-template-rows: 1.5rem;
  grid-gap: 1rem;
  align-items: center;
`;

const CheckboxWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  border: 1px solid #b2bdc5;
  border-radius: 4px;
  background-color: #ffffff;

  padding: 0.2rem;
`;

const CheckmarkIcon = ({ spring }) => (
  <CheckmarkWrapper
    style={{
      ...spring,
      position: `absolute`,
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      height: `100%`,
      width: `100%`,
    }}
  >
    <StaticImage
      objectFit="contain"
      placeholder="none"
      height={10}
      src="../../../../../../assets/blue_v.svg"
      alt="blue checkmark"
    />
  </CheckmarkWrapper>
);

const CheckmarkWrapper = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;

  .gatsby-image-wrapper {
    height: 80%;
    width: 80%;
  }
`;
