import * as React from 'react';
import styled from 'styled-components';
import { useSharedElement } from '@/components/use-shared-element';
import { SharedElementOverlay } from '@/components/shared-element-overlay';
import { CloseButton } from '@/components/close-button';
import {
  GradientLayout,
  GradientLayoutProps,
} from '@/components/gradient-layout';

export interface GradientModalProps extends GradientLayoutProps {
  showBackdrop: boolean;
  targetPosAndSize: DOMRect;
  fromStyle?: Record<string, any>;
  toStyle?: Record<string, any>;
  fromRef: React.MutableRefObject<HTMLElement>;
  scrollRef?: React.MutableRefObject<HTMLElement>;

  isVisible?: boolean;
  hideCloseButton?: boolean;
  disableCloseOnBackdropClick?: boolean;

  closeTop?: string;
  closeRight?: string;

  onClose: (() => void) | ((_: any) => Promise<void>);
  noScroll?: boolean;
}

export function GradientModal(props: GradientModalProps) {
  const {
    targetPosAndSize,
    showBackdrop,
    fromStyle,
    toStyle,
    fromRef,
    isVisible,
    hideCloseButton,
    header,
    children,
    footer,
    closeTop,
    closeRight,
    onClose,
    disableCloseOnBackdropClick,
  } = props;

  const { props: modalProps } = useSharedElement(
    {
      showBackdrop,
      extraFrom: fromStyle,
      extraTo: toStyle,
    },
    fromRef,
    () => targetPosAndSize,
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isVisible && (
        <SharedElementOverlay
          {...modalProps}
          onBackdropClick={(ev) =>
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            disableCloseOnBackdropClick === true ? () => {} : onClose(ev)
          }
          isVisible={isVisible}
        >
          <GradientLayout
            {...props}
            header={header}
            footer={footer}
            preHeader={
              !hideCloseButton && (
                <CloseWrapper
                  top={closeTop}
                  right={closeRight}
                  onClick={onClose}
                >
                  <CloseButton color="#666F7C" />
                </CloseWrapper>
              )
            }
          >
            {children}
          </GradientLayout>
        </SharedElementOverlay>
      )}
    </>
  );
}

interface CW {
  top?: string;
  right?: string;
}

const CloseWrapper = styled.div`
  position: absolute;
  top: ${(p: CW) => p?.top || `1.5rem`};
  right: ${(p: CW) => p?.right || `1.5rem`};
  z-index: 100;
  opacity: 0.37;

  && > * {
    height: 3rem;
    width: 3rem;
  }
`;
