import styled from 'styled-components';

export const IconButton = styled.button`
  margin: 0 0.5rem;
  ${(p) => (p.disabled ? `opacity: 0.5;` : `cursor: pointer;`)}
  border-radius: 50%;
  svg {
    fill: #878d92;
  }
  ${(p) =>
    p.disabled
      ? ``
      : `
      &:hover {
    opacity: 0.5;
  }
  &:active {
    opacity: 1;
  }`}
`;
