import { useState } from 'react';

export function useCustomizationName() {
  const [stickyEnabled, setStickyEnabled] = useState(false);
  const [headerText, setHeaderText] = useState(null);

  return {
    stickyEnabled,
    headerText,
    setStickyEnabled,
    setHeaderText,
  };
}
