import * as React from 'react';
import styled from 'styled-components';
import { useContext } from 'react';
import { IoChevronForward } from 'react-icons/io5';
import { CardButton } from '@/components/card-button';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DefaultTypography } from '@/components/typography';
import { useIsPreviewingDesktop } from '@/features/editor/shared/use-device-type';

export interface ChangesCardProps {
  onChangesClicked?: () => void;
}

export function ChangesCard({ onChangesClicked }: ChangesCardProps) {
  const {
    inspectorNav: { gotoChangelog },
    experienceState: { getCurrentVariant },
  } = useContext(EditorContext);

  const isDesktop = useIsPreviewingDesktop();

  const changes = getCurrentVariant()?.changes?.length || 1;

  const onClick = () => {
    if (onChangesClicked) {
      onChangesClicked();
    } else {
      gotoChangelog();
    }
  };

  return (
    <CardButton
      onClicked={onClick}
      canBeSelected
      height="4.7rem"
      width="fit-content"
      padding="0rem 2rem"
      borderRadius="1.2rem"
    >
      <Layout>
        <Changes isDesktop={isDesktop}>{changes} Changes were made</Changes>
        <Continue>Continue editing</Continue>
        <IoChevronForward size={15} />
      </Layout>
    </CardButton>
  );
}

const Layout = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
`;

const Changes = styled(DefaultTypography)`
  && {
    font-family: 'JetBrains Mono', serif;
    color: black !important;
    font-size: ${(p: { isDesktop: boolean }) =>
      p.isDesktop ? `1.2rem` : `1.3rem`};
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const Continue = styled(DefaultTypography)`
  && {
    font-family: 'JetBrains Mono', serif;
    color: #777e81;
    font-size: 1rem;
    font-weight: bold;
    padding-left: 2rem;
  }
`;
