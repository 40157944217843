import React from 'react';
import styled from 'styled-components';
import { TextInput } from '@/components/text-input';

export interface ColorTextInputProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
}

export function ColorTextInput({
  label,
  value,
  onChange,
}: ColorTextInputProps) {
  return (
    <Wrapper>
      <StyledInput value={value} onChange={(ev) => onChange(ev.target.value)} />
      <Label>{label}</Label>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledInput = styled(TextInput)`
  && {
    padding: 1rem 0;
    height: 100%;
    width: 100%;
    font-size: 1rem;
    color: rgb(32, 41, 105);
    font-weight: 800;
    box-shadow: none;
    border-width: 1px;
    border-radius: 5px;
    text-align: center;
  }
`;

const Label = styled.span`
  margin-top: 0.4rem;
  color: rgb(193, 196, 203);
  font-family: 'JetBrains Mono', serif;
  font-weight: 700;
  font-size: 0.9rem;
  letter-spacing: -0.43px;
`;
