import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { centered } from '@/components/use-shared-element';
import { EditorContext } from '@/features/editor/context/editor-context';
import { BigButton } from '@/components/big-button';
import { ReviewsIntegrations } from '@/features/integrations/reviews-integrations';
import { GradientModal } from '@/components/gradient-modal';
import { ReviewProvider, ReviewsData } from '@/webapi/use-store-settings-api';

export interface ReviewsModalProps {
  fromRef: React.MutableRefObject<HTMLElement>;
  isVisible: boolean;
  setIsVisible: (state: boolean) => void;
}

export function ReviewsModal({
  fromRef,
  isVisible,
  setIsVisible,
}: ReviewsModalProps) {
  const {
    resources: { reviews },
    setResources,
  } = useContext(EditorContext);

  const toRect = centered(70, 90);

  const onReviewsIntegrationChanged = (p: ReviewProvider) => {
    setResources((draft) => {
      draft.reviews.reviewsEnabled = true;
      draft.reviews.hasReviewProviders = true;
      draft.reviews.reviewsProviders.providers.find(
        (provider) => provider.name === p.name,
      ).isSelected = true;
    });
  };

  return (
    <GradientModal
      isVisible={isVisible}
      showBackdrop
      targetPosAndSize={toRect}
      fromRef={fromRef}
      overflowTop={{ heightInRem: 7 }}
      onClose={() => setIsVisible(false)}
      header="Which reviews provider would you like to use?"
      footer={<Footer reviews={reviews} setIsVisible={setIsVisible} />}
    >
      <ReviewsGrid>
        <ReviewsIntegrations
          onIntegrationChanged={onReviewsIntegrationChanged}
          mode="outline"
        />
      </ReviewsGrid>
    </GradientModal>
  );
}

const ReviewsGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.5rem;
  padding: 2rem;
`;

interface FooterProps {
  reviews: ReviewsData;
  setIsVisible: (state: boolean) => void;
}

const Footer = ({ reviews, setIsVisible }: FooterProps) => (
  <FooterButton
    disabled={!reviews.reviewsEnabled}
    color={!reviews.reviewsEnabled ? `#757575` : `white`}
    background={!reviews.reviewsEnabled ? `#E7E4E4` : `#0062FF`}
    noTransform
    size="large"
    onClick={() => setIsVisible(false)}
  >
    {!reviews.reviewsEnabled ? `Please select provider` : `Continue`}
  </FooterButton>
);

const FooterButton = styled(BigButton)`
  && {
    width: 35rem !important;
  }
`;
