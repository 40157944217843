import styled from 'styled-components';
import React from 'react';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { useImageThumbnailGallery } from '@/features/shared/experience-info-modal/use-image-thumbnail-gallery';
import {
  ImageEditorContext,
  newImageEditorContext,
} from '@/features/editor/widgets/custom-widget/inputs/background/image/shared/context';
import { ImageEditor } from '@/features/editor/widgets/custom-widget/inputs/background/image/editor';
import Portal from '@/components/portal';
import { CloseButton } from '@/components/close-button';
import { PopConfirm } from '@/components/pop-confirm';
import { BackdropSpinner } from '@/components/backdrop-spinner';

export interface ImageThumbnailGalleryProps {
  initialValue?: string[];
  maxImages?: number;
  allowUpload?: boolean;
  onImageListChanged?: (list: string[]) => void;
}

export function ImageThumbnailGallery({
  onImageListChanged,
  initialValue,
  maxImages,
  allowUpload,
}: ImageThumbnailGalleryProps) {
  const ctx = newImageEditorContext(``);
  const { image: uploadedImage, setImage: setUploadedImage } = ctx;

  const {
    loading,
    getRootProps,
    getInputProps,
    style,
    onCancelUpload,
    onUploadBlob,
    imageList,
    imageCount,
    maxImagesToShow,
    isSliderOpen,
    setIsSliderOpen,
    sliderImage,
    onThumbnailClicked,
    onImageClicked,
    onImageDelete,
  } = useImageThumbnailGallery(
    initialValue,
    maxImages,
    uploadedImage,
    setUploadedImage,
    onImageListChanged,
  );

  return (
    <ImageEditorContext.Provider value={ctx}>
      <Wrapper>
        {!!uploadedImage && (
          <Portal selector="#shared-elem">
            <OverlayWrapper>
              <BackdropSpinner loading={loading} />
              <DiscardUploadButton onClick={onCancelUpload}>
                Cancel
              </DiscardUploadButton>
              <ImageEditor buttonText="Upload" onUpdatePreview={onUploadBlob} />
            </OverlayWrapper>
          </Portal>
        )}
        {isSliderOpen && (
          <Portal selector="#shared-elem">
            <OverlayWrapper>
              <SliderWrapper>
                <CloseWrapper>
                  <CloseButton onClick={() => setIsSliderOpen(false)} />
                </CloseWrapper>
                <BigImage src={sliderImage} alt="" />
                <ThumbnailsWrapper>
                  {imageList?.map((img) => (
                    <Image
                      key={img}
                      onClick={() => onThumbnailClicked(img)}
                      src={img}
                    />
                  ))}
                </ThumbnailsWrapper>
              </SliderWrapper>
            </OverlayWrapper>
          </Portal>
        )}
        {allowUpload && imageCount < maxImagesToShow && (
          <StyledDropzone {...getRootProps({ style })}>
            <input {...getInputProps()} />
          </StyledDropzone>
        )}
        {imageList?.map((img) => (
          <ThumbnailWrapper key={img}>
            <div className="delete-image">
              <PopConfirm
                payload={img}
                onConfirm={onImageDelete}
                text="Are you sure you want to delete this image?"
              >
                <RiDeleteBin5Line />
              </PopConfirm>
            </div>
            <Image onClick={() => onImageClicked(img)} src={img} />
          </ThumbnailWrapper>
        ))}
      </Wrapper>
    </ImageEditorContext.Provider>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  width: 100%;
  height: 100%;
  padding: 1rem 0;

  && > * {
    height: 6rem;
    width: 6rem;
  }
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &&:hover {
    .delete-image {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .delete-image {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out, pointer-events 0.2s ease-in-out;
    transition-delay: 0.5s;
    cursor: context-menu;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0.1rem;
    right: 0.1rem;
    font-size: 1.8rem;
    color: black;
    padding: 0.2rem;
    background: rgb(0, 0, 0, 0.1);
    border-radius: 0.2rem 0.9rem 0.2rem 0.2rem;
  }
`;

const Image = styled.img`
  cursor: zoom-in;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
  border: 1px solid #979797;
  background: white;
`;

const OverlayWrapper = styled.div`
  z-index: 100000000000;
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
`;

const SliderWrapper = styled.div`
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const ThumbnailsWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  background: rgb(0, 0, 0, 0.05);
  border-radius: 2rem;

  && > * {
    height: 6rem;
    width: 6rem;
  }
`;

const BigImage = styled.img`
  height: 90%;
  width: auto;
  object-fit: contain;
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
  z-index: 100;
  opacity: 0.37;

  && > * {
    height: 3rem;
    width: 3rem;
  }
`;

const DiscardUploadButton = styled.button`
  position: absolute;
  appearance: none;
  outline: none;
  border: 1px solid gray;
  top: 1.18rem;
  right: 9rem;
  z-index: 10;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  font-size: 1.3rem;
  cursor: pointer;

  &&:hover {
    opacity: 0.8;
  }

  &&:active {
    opacity: 0.6;
  }
`;

const StyledDropzone = styled.div`
  background: #f3f3f3;
  border-radius: 1rem;
  position: relative;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &&:hover {
    opacity: 0.8;
  }

  &&:active {
    opacity: 0.6;
  }

  &&:after {
    top: -2px;
    position: absolute;
    height: 100%;
    width: 100%;
    content: '+';
    font-size: 2.5rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #b4bbbe;
  }
`;
