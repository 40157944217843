import { CatalogApp } from '@/webapi/use-widget-catalog-api';
import { InputType } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { formatDate } from '@/features/editor/shared/format-date';

const timerId = [
  `3120b0aef951499c8b1d3b345345d75919`,
  `3120b0aef951499399wa2b345345d75919`,
];

export function assignTimerDates(appsCatalog: CatalogApp[]): CatalogApp[] {
  const startDate = formatDate(new Date(Date.now() - 48 * 60 * 60 * 1000));
  const endDate = formatDate(new Date(Date.now() + 72 * 60 * 60 * 1000));

  appsCatalog.forEach((_, appIdx) =>
    appsCatalog[appIdx].widgets.forEach((_, widgetIdx) => {
      appsCatalog[appIdx].widgets[
        widgetIdx
      ].blueprint.schema.customizations.forEach((_, custIdx) => {
        appsCatalog[appIdx].widgets[widgetIdx].blueprint.schema.customizations[
          custIdx
        ].components.forEach((_, compIdx) => {
          appsCatalog[appIdx].widgets[
            widgetIdx
          ].blueprint.schema.customizations[custIdx].components[
            compIdx
          ].specs.forEach((spec, specIdx) => {
            if (
              (spec.type === InputType.TEXT_EDIT ||
                spec.type === InputType.DATE_PICKER) &&
              spec.key === `start` &&
              timerId.includes(appsCatalog[appIdx].widgets[widgetIdx].id)
            ) {
              appsCatalog[appIdx].widgets[
                widgetIdx
              ].blueprint.schema.customizations[custIdx].components[
                compIdx
              ].specs[specIdx].values[`value`] = startDate;
            }

            if (
              (spec.type === InputType.TEXT_EDIT ||
                spec.type === InputType.DATE_PICKER) &&
              spec.key === `end` &&
              timerId.includes(appsCatalog[appIdx].widgets[widgetIdx].id)
            ) {
              appsCatalog[appIdx].widgets[
                widgetIdx
              ].blueprint.schema.customizations[custIdx].components[
                compIdx
              ].specs[specIdx].values[`value`] = endDate;
            }
          });
        });
      });
    }),
  );
  return appsCatalog;
}
