import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import { VSpace } from '@/components/spacing';
import {
  AllocationText,
  AllocationWrapper,
} from '@/features/editor/widgets/targeting/shared';
import { centered } from '@/components/use-shared-element';
import { BigButton } from '@/components/big-button';
import { EditorContext } from '@/features/editor/context/editor-context';
import { ExperienceVariant } from '@/webapi/use-experience-api';
import { GradientModal } from '@/components/gradient-modal';
import { VariantName } from '@/features/editor/widgets/targeting/variant-name';
import { DeviceType } from '@/utils/definitions';

function getHeader(showSelectDupStrategy: boolean) {
  return !showSelectDupStrategy ? (
    <div>
      How do you want to begin creating <br /> the new variation?
    </div>
  ) : (
    <div>
      Variation created successfully!
      <br />
      What&apos;s next?
    </div>
  );
}

export const AddVariantBtn: FC<{ foo?: string }> = () => {
  const isMultivariantEnabled = useFeatureBit(FeatureBit.MULTIVARIANT);
  const editor = useContext(EditorContext);

  const { variants, isPostPurchase } = editor.experienceState.currentExperience;

  if (!isMultivariantEnabled || isPostPurchase || variants.length >= 6) {
    return null;
  }
  const ref = React.createRef<HTMLDivElement>();
  const [isVisible, setIsVisible] = useState(false);
  const [showSelectDupStrategy, setShowSelectDupStrategy] = useState<boolean>();
  const gradientProps = getGradientProps(isVisible, variants, ref);
  return (
    <div ref={ref}>
      <VSpace value={2} />
      <GradientModal
        {...gradientProps}
        onClose={() => {
          setIsVisible(false);
          setShowSelectDupStrategy(undefined);
        }}
        header={getHeader(showSelectDupStrategy)}
        footer={null}
      >
        <AddVariantModalContent
          showSelectDupStrategy={showSelectDupStrategy}
          setShowSelectDupStrategy={setShowSelectDupStrategy}
          hide={() => setIsVisible(false)}
          experienceVariants={variants}
        />
      </GradientModal>

      <Wrapper onClick={() => setIsVisible(true)}>
        <AllocationWrapper>
          <Text>Create another variant</Text>
        </AllocationWrapper>
      </Wrapper>
    </div>
  );
};

export function AddVariantModalContent({
  experienceVariants,
  hide,
  setShowSelectDupStrategy,
  showSelectDupStrategy,
}: {
  hide: () => void;
  experienceVariants: ExperienceVariant[];
  showSelectDupStrategy?: boolean;
  setShowSelectDupStrategy: (b: boolean) => void;
}) {
  const editor = useContext(EditorContext);

  const { variants } = editor.experienceState.currentExperience;
  const isMultiVariant = variants.length > 1;

  const [variantId, setVariantId] = useState(``);
  const name = variants?.find((v) => v.id === variantId)?.name || ``;

  const onCreateFromScratch = () => {
    const { id: newId } = editor.experienceState.addEmptyVariant();
    setShowSelectDupStrategy(true);
    setVariantId(newId);
  };

  const onDuplicateVariant = (id: string) => {
    const { id: newId } = editor.experienceState.cloneVariant(id);
    setVariantId(newId);
    setShowSelectDupStrategy(true);
  };

  if (showSelectDupStrategy) {
    return (
      <ContentPartner>
        <EditNameWrapper>
          <VariantName
            defaultValue={name}
            variantId={variantId}
            isActive
            disableRemove
            device={DeviceType.Desktop}
          />
        </EditNameWrapper>
        <BigButton
          onClick={() => {
            setShowSelectDupStrategy(false);
            hide();
            editor.inspectorNav.gotoChangelog();
          }}
          border="2px solid #C8CACB"
          background="white"
          color="#97A0A8"
          noShadow
          noTransform
          size="large"
          fillWidth
        >
          Go and Edit the New Variation
        </BigButton>
        <VSpace value={2} />
        <BigButton
          onClick={() => {
            setShowSelectDupStrategy(false);
            hide();
          }}
          border="2px solid #C8CACB"
          background="white"
          color="#97A0A8"
          noShadow
          noTransform
          size="large"
          fillWidth
        >
          Back to Review Screen
        </BigButton>
      </ContentPartner>
    );
  }

  return (
    <ContentPartner>
      <VSpace value={4} />
      <BigButton
        onClick={onCreateFromScratch}
        border="2px solid #C8CACB"
        background="white"
        color="#97A0A8"
        noShadow
        noTransform
        size="large"
        fillWidth
      >
        Start from scratch with no changes
      </BigButton>
      {experienceVariants.map((v) => (
        <>
          <VSpace key={`spc${v.id}`} value={2} />
          <BigButton
            key={`byn${v.id}`}
            onClick={() => onDuplicateVariant(v.id)}
            border="2px solid #C8CACB"
            background="white"
            color="#97A0A8"
            noShadow
            noTransform
            size="large"
            fillWidth
          >
            {isMultiVariant
              ? `Duplicate ${v.name}`
              : `Duplicate existing variation`}
          </BigButton>
        </>
      ))}
    </ContentPartner>
  );
}
const EditNameWrapper = styled.div`
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Wrapper = styled.div`
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
  :active {
    cursor: default;
    opacity: 1;
  }
`;

const ContentPartner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
  font-family: Inter, serif;
`;

const Text = styled(AllocationText)`
  && {
    text-decoration: underline;
  }
`;

function getGradientProps(
  isVisible: boolean,
  variants: ExperienceVariant[],
  ref: React.RefObject<HTMLDivElement>,
) {
  return {
    closeTop: `0.5rem`,
    closeRight: `2.5rem`,
    fromStyle: {
      borderRadius: `5rem`,
      backgroundColor: `#DEDEDE`,
      padding: `2rem 0 0 0`,
    },
    toStyle: {
      borderRadius: `1rem`,
      backgroundColor: `#FFFFFF`,
      padding: `2rem 0 0 0`,
    },
    isVisible,
    showBackdrop: true,
    targetPosAndSize: centered(
      Math.max(
        35 + (variants.length - 1 > 0 ? variants.length - 1 : 0) * 5.5,
        40,
      ),
      55,
    ),
    fromRef: ref,
    overflowTop: { heightInRem: 8, turnPointInPercent: 30 },
    overflowBottom: { heightInRem: 8, turnPointInPercent: 30 },
  };
}
