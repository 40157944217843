import React, { useContext, useMemo } from 'react';
import { EditorContext } from '@/features/editor/context/editor-context';
import { CatalogApp } from '@/webapi/use-widget-catalog-api';
import { CatalogAppsGrid } from '@/features/editor/widgets/shared/apps-catalog/apps-list';
import { DeviceType } from '@/utils/definitions';
import { CatalogAppTile } from '@/features/editor/widgets/shared/apps-catalog/app-tile';
import { CheckoutExtensibilityContext } from '@/features/editor/widgets/checkout-extensibility/context';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';

export function CheckoutInspectorAppsList() {
  const {
    checkoutApps,
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);
  const { moveToWidgetListing } = useContext(CheckoutExtensibilityContext);
  const isTestWidgetsEnabled = useFeatureBit(
    FeatureBit.SHOW_TEST_WIDGETS,
    false,
  );

  const onSelection = (app: CatalogApp) => {
    moveToWidgetListing(app);
  };

  const apps = useMemo(
    () =>
      checkoutApps?.filter((app) => !app.isHidden || isTestWidgetsEnabled) ||
      [],
    [checkoutApps],
  );

  return (
    <CatalogAppsGrid isDesk={device === DeviceType.Desktop}>
      {apps.map((app) => (
        <CatalogAppTile key={app.id} app={app} onClick={onSelection} />
      ))}
    </CatalogAppsGrid>
  );
}
