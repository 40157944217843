import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { KlavioEvent, KlavioStep } from '@/webapi/use-experience-api';
import { LineItem } from '@/features/editor/widgets/shared/modals/audience/audience-shared';
import { VSpace } from '@/components/spacing';
import { CircularCheckmark } from '@/components/circular-checkmark';

type Props = {
  event: KlavioEvent;
  setEvent: (e: KlavioEvent) => void;
  step: KlavioStep;
  setStep: (s: KlavioStep) => void;
};

const Container = styled.div`
  padding: 5px;
`;

export const KlavioEventSelect: FC<Props> = ({
  event,
  setEvent,
  step,
  setStep,
}) => {
  useEffect(() => {
    document.querySelectorAll(`#vslyGradientScroller`).forEach((scroller) => {
      scroller.scrollTo(0, 0);
    });
  }, []);

  return (
    <Container>
      <VSpace value={2} />
      <LineItem
        selected={event === KlavioEvent.SUBMIT}
        onClicked={() => setEvent(KlavioEvent.SUBMIT)}
      >
        <span>Submit</span>
        <span>
          This event fires when a visitor submits the main conversion action of
          a form (e.g., the first email or SMS subscription action), and will
          only fire once per form
        </span>
      </LineItem>
      <VSpace value={2} />
      <LineItem
        selected={event === KlavioEvent.STEP_SUBMIT}
        onClicked={() => setEvent(KlavioEvent.STEP_SUBMIT)}
        height="20rem"
      >
        <span>Step Submit</span>
        <span>
          This event fires when each step is submitted, and can fire multiple
          times per form. For forms with email and SMS subscription
        </span>
        <div>
          <VSpace />
          <CircularCheckmark
            onChange={() => setStep(KlavioStep.EMAIL_SMS)}
            selected={step === KlavioStep.EMAIL_SMS}
            caption="Email & SMS collected"
          />
          <VSpace />
          <CircularCheckmark
            onChange={() => setStep(KlavioStep.EMAIL)}
            selected={step === KlavioStep.EMAIL}
            caption="Email only collected"
          />
          <VSpace />
          <CircularCheckmark
            onChange={() => setStep(KlavioStep.SMS)}
            selected={step === KlavioStep.SMS}
            caption="SMS only collected"
          />
        </div>
      </LineItem>
      <VSpace value={2} />
      <LineItem
        selected={event === KlavioEvent.CLOSE}
        onClicked={() => setEvent(KlavioEvent.CLOSE)}
      >
        <span>Close</span>
        <span>This event fires when a visitor closes a form</span>
      </LineItem>
      <VSpace value={2} />
      <LineItem
        selected={event === KlavioEvent.OPEN}
        onClicked={() => setEvent(KlavioEvent.OPEN)}
      >
        <span>Open</span>
        <span>
          This event fires when a popup or flyout form initially displays on a
          page
        </span>
      </LineItem>
      <VSpace value={2} />
      <LineItem
        selected={event === KlavioEvent.OPEN_EMBED}
        onClicked={() => setEvent(KlavioEvent.OPEN_EMBED)}
      >
        <span>Embed Open</span>
        <span>
          This event fires when an embedded form loads on a page close
        </span>
      </LineItem>
      <VSpace value={2} />
      <LineItem
        selected={event === KlavioEvent.REDIRECT_TO_URL}
        onClicked={() => setEvent(KlavioEvent.REDIRECT_TO_URL)}
      >
        <span>Redirected To Url</span>
        <span>
          This event fires when a visitor clicks a button on a form that is set
          to redirect them to a URL
        </span>
      </LineItem>
      <VSpace value={2} />
    </Container>
  );
};
