import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import {
  GenericInputProps,
  InputsLightTheme,
} from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import {
  DropdownInput,
  DropdownInputOption,
} from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { useDropdownInputUpdater } from '@/features/editor/widgets/custom-widget/inputs/shared/use-dropdown-updater';
import { EditorContext } from '@/features/editor/context/editor-context';

const VALUE_KEY = `fontFamily`;

const options: DropdownInputOption[] = [
  { label: `Roboto`, value: `Roboto` },
  { label: `Open Sans`, value: `"Open Sans"` },
  { label: `Fredoka`, value: `Fredoka` },
  { label: `Smooch Sans`, value: `"Smooch Sans"` },
  { label: `Rubik`, value: `Rubik` },
  { label: `Lato`, value: `Lato` },
  { label: `Poppins`, value: `Poppins` },
  { label: `Oswald`, value: `Oswald` },
  { label: `League Spartan`, value: `"League Spartan"` },
  { label: `Noto Sans`, value: `"Noto Sans"` },
  { label: `Raleway`, value: `Raleway` },
  { label: `Merriweather`, value: `Merriweather` },
  { label: `Playfair Display`, value: `"Playfair Display"` },
  { label: `Inter`, value: `Inter` },
  { label: `Jetbrains Mono`, value: `Jetbrains Mono` },
];

export function FontFamilyInput({
  customizationIdx,
  customization,
  componentIdx,
  component,
  specIdx,
  spec,
  device,
  initialValues,
  onValuesChanged,
}: GenericInputProps) {
  const {
    devicePreview: {
      editorState: { installedFonts },
    },
  } = useContext(EditorContext);

  const opts = useMemo(
    () => [
      ...installedFonts.map((font) => ({ label: font, value: font })),
      ...options,
    ],
    [installedFonts],
  );

  const { getValue, onChange, undoRedoCount } = useDropdownInputUpdater(
    {
      customizationIdx,
      customization,
      componentIdx,
      component,
      specIdx,
      spec,
      device,
      initialValues,
      onValuesChanged,
    },
    VALUE_KEY,
    opts,
  );

  return (
    <Wrapper>
      <DropdownInput
        key={undoRedoCount}
        options={opts}
        theme={InputsLightTheme}
        defaultValue={getValue()}
        onChange={onChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;
