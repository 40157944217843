import React, { useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import { VSpace } from '@/components/spacing';
import {
  LoadingStrategyOption,
  RecommendationType,
} from '@/webapi/use-widget-catalog-api';
import { getAvailableStrategies } from '@/features/editor/widgets/custom-widget/loading-section/util';

type Props = {
  onSelect: (value: LoadingStrategyOption) => () => void;
};

export function StrategySelection({ onSelect }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const strategies = getStrategies();
  return (
    <Popover
      onClickOutside={() => setIsOpen(false)}
      clickOutsideCapture
      positions={[`right`]}
      isOpen={isOpen}
      containerStyle={{ zIndex: `99999` }}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="white"
          arrowClassName="change-target-arrow"
          arrowSize={10}
        >
          <Strategies>
            <Title>Strategy Select</Title>
            <VSpace />
            {strategies?.map((value) => (
              <Strategy onClick={onSelect(value)}>{value.label}</Strategy>
            ))}
          </Strategies>
        </ArrowContainer>
      )}
    >
      <button type="button" onClick={() => setIsOpen(!isOpen)}>
        strategy
      </button>
    </Popover>
  );
}

const Strategy = styled.span`
  margin: 0.9rem 0 0 0;
  text-transform: capitalize;
  color: #5b656e;
  font-weight: 600;
  user-select: none;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  &:active {
    cursor: default;
    opacity: 1;
  }
`;
const Title = styled.span`
  color: #a6afb8;
  font-weight: 800;
`;
const Strategies = styled.div`
  font-family: 'JetBrains Mono', serif;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  padding: 2rem;
  border-radius: 10px;
  border: 1px solid rgba(151, 151, 151, 0.1);
  background-color: #fafafa;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.18),
    0 14px 16px 0 rgba(177, 217, 203, 0.18), 0 5px 16px 0 rgba(0, 0, 0, 0.05);
`;

function getStrategies() {
  const list = getAvailableStrategies();
  return (
    list?.options?.filter?.(
      (s) =>
        ![
          RecommendationType.MANUAL,
          RecommendationType.ADVANCED_RULING,
        ].includes(s.value.type),
    ) || []
  );
}
