import styled from 'styled-components';
import { IoClose } from 'react-icons/io5';
import React from 'react';

type Props = { onClick: () => void };

export function RulesListHeader({ onClick }: Props) {
  return (
    <Title>
      <div />
      <p>Advanced Editing</p>
      <CloseBtn type="button" onClick={onClick}>
        <IoClose size={35} color="#5B656E" />
      </CloseBtn>
    </Title>
  );
}

const Title = styled.div`
  margin-top: -7rem;

  width: 100%;
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  justify-items: center;
  align-items: center;

  p {
    margin: 0;
    user-select: none;
    text-align: center;
    font-weight: 600;
    font-size: 2.2rem;
  }
`;

const CloseBtn = styled.button`
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  &:active {
    cursor: default;
    opacity: 1;
  }
`;
