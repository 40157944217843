import React, { FC, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { GradientModal } from '@/components/gradient-modal';
import { Header } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/header';
import { ModalFooter } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/footer';
import { gradientProps } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/misc/gradientProps';
import { ConditionBuilder } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule/strategy/condition';
import { Rules } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rules';
import { LayeredRulingContext } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';

import {
  ManualImageSelect,
  Search,
} from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule/search';

type Props = {
  isOpen: boolean;
  close: () => void;
  fromRef: React.MutableRefObject<any>;
};

function useAutoScroll() {
  const ctx = useContext(LayeredRulingContext);

  const activeRule = ctx.state.rules.find((r) => r.isOpen);
  useEffect(() => {
    setTimeout(() => {
      if (
        ctx.isModalOpen &&
        !!activeRule &&
        !(ctx.isProductSearchOpen || ctx.isManualImageSelectionOpen)
      ) {
        const top = activeRule.id * 132;
        ctx.scrollRef?.current?.scrollBy?.({
          top,
          left: 0,
          behavior: `smooth`,
        });
      }
    }, ctx.autoScrollDelayMs.current);
  }, [
    ctx.isProductSearchOpen,
    ctx.isManualImageSelectionOpen,
    ctx.state.showCondBuilder,
    ctx.isModalOpen,
    activeRule?.isOpen,
  ]);
  return ctx.scrollRef;
}

export const LayeredRuling: FC<Props> = ({ isOpen, fromRef, close }) => {
  const scrollRef = useAutoScroll();
  const innerScrollRef = useRef(null);
  const ctx = useContext(LayeredRulingContext);

  useEffect(() => {
    if (innerScrollRef?.current?.scrollTo && ctx.isProductSearchOpen) {
      innerScrollRef.current.scrollTo(0, 0);
    }
  }, [innerScrollRef, ctx.isProductSearchOpen]);

  return (
    <GradientModal
      {...gradientProps}
      isVisible={isOpen}
      fromRef={fromRef}
      onClose={close}
      scrollRef={scrollRef}
      noScroll={ctx.isProductSearchOpen && !ctx.isManualImageSelectionOpen}
      header={<Header />}
      footer={<ModalFooter close={close} />}
    >
      <Container ref={innerScrollRef} shouldScroll={!ctx.isProductSearchOpen}>
        <Box shouldScroll={!ctx.isProductSearchOpen}>
          <ConditionBuilder />
          <Search />
          <ManualImageSelect />
          <Rules />
        </Box>
      </Container>
    </GradientModal>
  );
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${(p) => (p.shouldScroll ? `8rem` : `2rem`)};

  .product-search-box {
    margin-left: 2rem;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: ${(p) => (p.shouldScroll ? `scroll` : `hidden`)};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
