import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { BackgroundColorContext } from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/context';
import useMouseEvents from '../shared/use-mouse-events';

export function ColorAlphaPicker() {
  const { solidColor, updateSolidColor } = useContext(BackgroundColorContext);

  const alphaMaskRef = useRef<HTMLElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (alphaMaskRef.current) {
      setWidth(alphaMaskRef.current.clientWidth);
    }
  }, [setWidth]);

  const mouseDownHandler = useCallback(
    (event) => {
      const elementX = event.currentTarget.getBoundingClientRect().x;
      const startX = event.pageX;
      const positionX = startX - elementX;

      updateSolidColor({ alpha: getAlpha(positionX, width) });
      return {
        startX,
        positionX,
      };
    },
    [width, updateSolidColor],
  );

  const changeObjectPositions = useCallback(
    (event, { startX, positionX }) => {
      const moveX = event.pageX - startX;
      positionX += moveX;

      const alpha = getAlpha(positionX, width);

      return {
        positions: {
          positionX,
          startX: event.pageX,
        },
        alpha,
      };
    },
    [width],
  );

  const mouseMoveHandler = useCallback(
    (event, { startX, positionX }) => {
      const { positions, alpha } = changeObjectPositions(event, {
        startX,
        positionX,
      });

      updateSolidColor({ alpha });

      return positions;
    },
    [changeObjectPositions, updateSolidColor],
  );

  const mouseUpHandler = useCallback(
    (event, { startX, positionX }) => {
      const { positions, alpha } = changeObjectPositions(event, {
        startX,
        positionX,
      });

      updateSolidColor({ alpha });

      return positions;
    },
    [changeObjectPositions, updateSolidColor],
  );

  const mouseEvents = useMouseEvents(
    mouseDownHandler,
    mouseMoveHandler,
    mouseUpHandler,
  );

  const onMouseDown = (event) => {
    mouseEvents(event);
  };

  // eslint-disable-next-line no-bitwise
  const offsetLeft = ((solidColor.alpha * width) | 0) - 6;

  const pointerStyle = {
    left: `${offsetLeft}px`,
  };

  return (
    <Wrapper onMouseDown={onMouseDown}>
      <div
        style={{
          background: `linear-gradient(to right, rgba(0, 0, 0, 0), rgb(${solidColor.red}, ${solidColor.green}, ${solidColor.blue}))`,
          position: `absolute`,
          top: `0`,
          left: `0`,
          right: `0`,
          bottom: `0`,
          borderRadius: `5px`,
        }}
      />
      <AlphaMask ref={alphaMaskRef}>
        <Picker style={pointerStyle} />
      </AlphaMask>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background-image: linear-gradient(
      to right,
      rgba(192, 192, 192, 0.75),
      rgba(192, 192, 192, 0.75)
    ),
    linear-gradient(to right, black 50%, white 50%),
    linear-gradient(to bottom, black 50%, white 50%);
  background-blend-mode: normal, difference, normal;
  background-size: 2em 2em;
  padding: 1.3rem 0;
  border-radius: 5px;
`;

const AlphaMask = styled.div`
  width: 100%;
  height: 100%;
`;

const Picker = styled.div`
  position: absolute;
  top: -0.4rem;
  height: calc(100% + 0.8rem);
  width: 1.1rem;
  background: transparent;
  border: 0.4rem solid #fff;
  border-radius: 8px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.46),
    0 6px 14px 0 rgba(71, 111, 108, 0.69), 0 4px 14px 0 rgba(0, 0, 0, 0);
`;

export function getAlpha(value, width) {
  value = Number((value / width).toFixed(2));

  // eslint-disable-next-line no-nested-ternary
  return value > 1 ? 1 : value < 0 ? 0 : value;
}
