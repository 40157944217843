import styled from 'styled-components';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  CodeEditInputSpec,
  InputComponent,
  InputCustomization,
} from '@/webapi/checkout-widget-props';
import { QuickEditTitle } from '@/features/editor/widgets/custom-widget/inputs/shared/radio-buttons';
import { MonacoEditor } from '@/features/editor/widgets/code-editors/monaco-editor';
import { CodeEditorMonacoWrapper } from '@/features/editor/widgets/code-editors/shared';
import { CheckoutExtensibilityContext } from '@/features/editor/widgets/checkout-extensibility/context';

export interface CheckoutInputCodeEditProps {
  cust: InputCustomization;
  custId: number;
  comp: InputComponent;
  compId: number;
  spec: CodeEditInputSpec;
  specId: number;
}

export function CheckoutInputCodeEdit({
  cust,
  custId,
  comp,
  compId,
  spec,
  specId,
}: CheckoutInputCodeEditProps) {
  const { getValueByPath, currentProps, setCurrentProps } = useContext(
    CheckoutExtensibilityContext,
  );

  const [isExpanded, setIsExpanded] = useState(false);

  const value = useMemo(
    () => getValueByPath(custId, compId, specId).value,
    [currentProps],
  );

  const onChange = useCallback(
    (ev: any) => {
      setCurrentProps((draft) => {
        draft[cust.key][comp.key][spec.key] = ev;
      });
    },
    [currentProps, setCurrentProps],
  );

  const onToggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <Wrapper isExpanded={isExpanded}>
      <QuickEditTitle small>
        {spec.title} (
        <span className="code-expand-button" onClick={onToggleExpand}>
          {isExpanded ? ` Minimize ` : ` Maximize `}
        </span>
        )
      </QuickEditTitle>
      <div className="code-editor">
        <CodeEditorMonacoWrapper>
          <MonacoEditor
            lang="javascript"
            code={value}
            onCodeChange={onChange}
            readOnly={false}
          />
        </CodeEditorMonacoWrapper>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  position: ${(p) => (p.isExpanded ? `fixed` : `relative`)};
  padding: ${(p) => (p.isExpanded ? `2rem` : `0`)};
  background: ${(p) => (p.isExpanded ? `white` : `unset`)};
  margin: ${(p) => (p.isExpanded ? `0!important` : `unset`)};
  height: ${(p) => (p.isExpanded ? `100vh` : `unset`)};
  width: ${(p) => (p.isExpanded ? `100vw` : `unset`)};
  z-index: ${(p) => (p.isExpanded ? `100` : `unset`)};
  inset: 0;

  .code-editor {
    height: ${(p) => (p.isExpanded ? `94vh` : `40rem`)};
  }

  .code-expand-button {
    cursor: pointer;
    color: #6d6f71;
  }

  .code-expand-button:hover {
    text-decoration: underline;
  }
`;
