import { CachePolicies, useFetch } from 'use-http';
import { routes } from '@/webapi/routes';
import {
  StyleTemplate,
  StyleTemplates,
} from '@/features/editor/widgets/custom-widget/style-templating/models';

export function useStyleTemplatingApi() {
  const { post, loading, cache } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: false,
    },
  }));

  const listStylesById = (blueprintName: string, appId: string) =>
    post(routes.listStyles(), {
      widgetName: blueprintName,
      appId,
    }) as Promise<StyleTemplates>;

  const saveStyles = async (st: StyleTemplate) => {
    const response: {
      isDuplicateName: true;
    } = await post(routes.saveStyles(), st);
    cache.clear();
    return response;
  };

  const deleteStyle = async (id: string) => {
    await post(routes.deleteStyle(), { value: id });
    cache.clear();
  };

  return {
    deleteStyle,
    listStylesById,
    saveStyles,
    loading,
  };
}
