import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { StockImage } from '@/webapi/use-media-api';
import { DefaultTypography } from '@/components/typography';

export function StockPhotoTile({
  photo,
  onClick,
}: {
  photo: StockImage;
  onClick?: (photo: StockImage) => void;
}) {
  const [isHover, setIsHover] = useState(false);
  return (
    <Wrapper
      onClick={() => {
        onClick && onClick(photo);
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <img src={photo.regularLink} alt={photo.description} />
      {isHover && (
        <PhotographerCredit>
          Photo by{` `}
          <a target="_blank" href={photo.photographerLink} rel="noreferrer">
            {photo.photographerName}
          </a>
          {` `}
          on{` `}
          <a target="_blank" href="https://www.unsplash.com" rel="noreferrer">
            Unsplash
          </a>
        </PhotographerCredit>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const CreditAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(1,0);
  }
  100% {
    opacity: 1;
    transform: scale(1,1);
  }
`;

const PhotographerCredit = styled(DefaultTypography)`
  && {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: start;
    font-size: 1.2rem;
    color: #eaeef2;
    font-weight: 500;
    letter-spacing: 0.28px;

    padding: 2rem 1rem;

    transform-origin: 100% 100%;
    animation: ${CreditAnimation} ease-out 0.3s;

    a {
      color: inherit;
    }
  }
`;
