/* eslint-disable no-nested-ternary,react/jsx-no-useless-fragment */
import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { FacebookAudienceContext } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/context';
import { IntegrationTile } from '@/features/integrations/integration-tile';
import { useFacebookApi } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/api';
import { Spinner } from '@/Spinner';

const Container = styled.div`
  margin: 0.5rem 2rem;
  height: 90px;
`;

const AdAccount = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const Letter = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 1rem;
  font-size: 3rem;
  border-radius: 50%;
  font-family: 'JetBrains Mono', serif;
  background-color: #1ea7fd;
  color: white;
`;

const AccountName = styled.span`
  margin-left: 2rem;
  font-family: 'JetBrains Mono', serif;
  font-size: 1.5rem;
`;

export const AdAccountSelect: FC = () => {
  const { adAccounts, markAdAccountAsSelected } = useContext(
    FacebookAudienceContext,
  );
  const { selectAdAccount, loading } = useFacebookApi(false);
  const [s, setS] = useState(``);

  return (
    <>
      {adAccounts?.map((account) => (
        <Container key={account.id}>
          <IntegrationTile
            mode="outline"
            isSelected={account.id === s}
            staticImage={
              <AdAccount>
                {!loading ? (
                  <Letter>{account.name[0].toUpperCase()}</Letter>
                ) : account.id === s ? (
                  <Spinner size={40} />
                ) : (
                  <Letter>{account.name[0].toUpperCase()}</Letter>
                )}
                <AccountName>{account.name}</AccountName>
              </AdAccount>
            }
            onChange={async () => {
              if (!loading) {
                setS(account.id);
                await selectAdAccount(account);
                markAdAccountAsSelected(account);
              }
            }}
          />
        </Container>
      ))}
    </>
  );
};
