import React, { FC } from 'react';
import { BsPlusLg } from 'react-icons/bs';
import styled from 'styled-components';

const Container = styled.button`
  display: none; //https://loomi-labs.atlassian.net/browse/LVP-638
  width: 177px;
  height: 188.5px;
  //display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: #91a3b3 2px solid;
  background-color: white;
  border-radius: 21.9px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  &:active {
    opacity: 1;
    cursor: default;
  }

  svg {
    margin-top: 1rem;
  }

  p {
    color: #5b656e;
    user-select: none;
    font-family: 'JetBrains Mono', serif;
    font-weight: 600;
    font-size: 1.5rem;
    margin: 10px 0 0 0;
  }
`;

interface Props {
  addSlot: () => void;
}
export const AddAnother: FC<Props> = ({ addSlot }) => (
  <Container onClick={addSlot} type="button">
    <BsPlusLg size={40} color="#A6AFB8" />
    <p>Add Another</p>
  </Container>
);
