import React from 'react';
import styled from 'styled-components';
import { QBItemSelection } from '@/components/query-builder/models';

export interface FormattedSourceUrlProps {
  sourceUrlProps: Array<QBItemSelection>;
}

export function FormattedSourceUrl({
  sourceUrlProps,
}: FormattedSourceUrlProps) {
  return (
    <Wrapper>
      {sourceUrlProps?.map((rule, andIdx) => (
        <AndRule key={andIdx}>
          {andIdx > 0 && <AndOperator>AND</AndOperator>}
          URL{` `}
          <OrRules className={andIdx > 0 ? `double` : ``}>
            {rule.values?.map((value, orIdx) => (
              <OrRule key={orIdx} className={orIdx > 0 ? `inner` : ``}>
                {orIdx > 0 && <OrOperator>OR</OrOperator>}
                {value.op} {value.value}
              </OrRule>
            ))}
          </OrRules>
        </AndRule>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  transition: opacity 0.2s linear;
  line-height: 3rem;
  overflow: hidden;
  &&:hover {
    opacity: 0.8;
  }

  .double > div:not(.inner) {
    margin-left: 3.3rem !important;
  }
`;

const AndRule = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-left: 0rem;
  color: black;
`;

const OrRules = styled.div`
  display: flex;
  flex-direction: column;
  color: black;

  &&.double {
    margin-left: -3.5rem;
  }
`;

const OrRule = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  &&.inner {
    margin-left: -2.5rem;
  }
`;

const OrOperator = styled.span`
  //margin-left: 1rem;
  //margin-top: 0.4rem;
  //margin-bottom: 0.4rem;
  color: #798794;
`;

const AndOperator = styled.span`
  //margin-top: 1rem;
  //margin-bottom: 1rem;
  //margin-left: 1rem;
  color: #798794;
`;
