// @ts-ignore
import { VisualEdit, VisualEditChange, VisualEditSubChange } from '@/pkg/sdk';
import { ExperienceCreationVersion } from '@/webapi/experience-creation-version';

export class VisualEditChangeBuilder {
  private changes: VisualEdit[] = [];

  private readonly experienceCreationVersion: number;

  constructor(version: ExperienceCreationVersion) {
    this.experienceCreationVersion = version;
  }

  private push(selector: string, subChange: VisualEditSubChange) {
    const idx = this.changes.findIndex((ch) => ch.selector === selector);
    if (idx < 0) {
      this.changes.push({
        selector,
        subChanges: [subChange],
        version: this.experienceCreationVersion,
      } as VisualEdit);
    } else this.changes[idx].subChanges.push(subChange);
  }

  changeText(selector: string, value: string, childNode?: number) {
    this.push(selector, { kind: `text`, value, extra: childNode });
  }

  changeAttribute(selector: string, key: string, value: string) {
    this.push(selector, { kind: `attr`, key, value });
  }

  changeStyle(selector: string, key: string, value: string) {
    this.push(selector, { kind: `style`, key, value });
  }

  changeImage(selector: string, value: string) {
    this.push(selector, { kind: `image`, value });
  }

  changeVideo(selector: string, value: string) {
    this.push(selector, { kind: `video`, value });
  }

  asVisualEditChange(): VisualEditChange {
    return {
      changes: this.changes,
    };
  }
}
