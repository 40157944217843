import React from 'react';
import styled from 'styled-components';
import { BiLike } from 'react-icons/bi';
import { FaRegComment } from 'react-icons/fa';
import { InstagramMedia } from '@/features/editor/widgets/custom-widget/inputs/background/image/facebook/models';
import { formatNumber } from '@/utils/format';

export function LikesCommentsBadge({ image }: { image: InstagramMedia }) {
  return (
    <LikesComments>
      <ImageBox>
        <FaRegComment size={16} color="white" />
      </ImageBox>
      <span>{formatNumber(image.commentsCount)}</span>
      <span>|</span>
      <ImageBox>
        <BiLike size={16} color="white" />
      </ImageBox>
      <span>{formatNumber(image.likesCount)}</span>
    </LikesComments>
  );
}

const ImageBox = styled.div`
  height: 16px;
  width: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const LikesComments = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;
  padding: 5px 10px;
  background-color: #4179ff;
  color: white;
  border-radius: 30px;
  top: -8px;
  left: -8px;
`;
