import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DefaultTypography } from '@/components/typography';
import { GroupDisableButton } from '@/features/editor/widgets/custom-widget/inputs/shared/group-disable-button';
import { Flexbox } from '@/components/flex';
import { QuickEditContext } from '@/features/editor/widgets/custom-widget/shared/quick-edit-helper';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import { GenericInput } from '@/features/editor/widgets/custom-widget/inputs';
import { DeviceType } from '@/utils/definitions';
import { MaybeFiltered } from '../../shared/shared-styles';
import { HistoryOpts } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';

export function QuickEditItem({
  quickEdit,
  device,
  showInputName,
}: {
  quickEdit: QuickEditContext[];
  device: DeviceType;
  showInputName?: boolean;
}) {
  const {
    updateCustomizationStatus,
    getResponsiveValue,
    setResponsiveValue,
    evaluateRule,
    currentSchema,
  } = useContext(CustomWidgetContext);
  const customization = quickEdit?.[0]?.customization;
  const customizationIdx = quickEdit?.[0]?.customizationIdx;

  const [isDisabled, setIsDisabled] = useState(
    customization.isDisabled || false,
  );
  const [isOpen, setIsOpen] = useState(!isDisabled);

  useEffect(() => {
    const c = currentSchema.customizations[quickEdit?.[0].customizationIdx];
    if (c.isDisabled !== isDisabled) {
      setIsDisabled(c.isDisabled);
      setIsOpen(!c.isDisabled);
    }
  }, [currentSchema.customizations[quickEdit?.[0].customizationIdx]]);

  const onToggle = () => {
    if (isDisabled) return;
    setIsOpen(!isOpen);
  };

  const onDisableSection = (ev) => {
    ev.stopPropagation();
    setIsDisabled(!isDisabled);
    setIsOpen(isDisabled);
    updateCustomizationStatus(customizationIdx, !isDisabled);
  };

  return (
    <MaybeFiltered isVisible={!evaluateRule(customization?.hideWhen)}>
      <Wrapper key={customization.key}>
        <HeaderSection onClick={onToggle}>
          <Text>{customization.name}</Text>
          <Flexbox direction="row" gap="1rem">
            {customization.canBeDisabled && (
              <GroupDisableButton
                isDisabled={isDisabled}
                onClick={onDisableSection}
              />
            )}
            <Text
              style={{
                opacity: isDisabled ? `0` : `1`,
                pointerEvents: isDisabled ? `none` : `auto`,
              }}
            >
              {isOpen ? `-` : `+`}
            </Text>
          </Flexbox>
        </HeaderSection>
        <ContentSection isOpen={isOpen}>
          {quickEdit.map((qec) => {
            const initialValues = (
              key?: string,
              d?: DeviceType,
              specIdx?: number,
            ): any =>
              getResponsiveValue(
                qec.customizationIdx,
                qec.componentIdx,
                specIdx || qec.specIdx,
                key,
                d || device,
              );

            const onValuesChanged = (
              key: string,
              value: any,
              d?: DeviceType,
              historyOpts?: HistoryOpts,
            ): void => {
              setResponsiveValue(
                qec.customizationIdx,
                qec.componentIdx,
                qec.specIdx,
                key,
                value,
                d || device,
                !!historyOpts?.ignoreHistory,
                !!historyOpts?.forceSameVersion,
              );
            };

            return (
              <MaybeFiltered
                key={`wrapper-${qec.spec.key}`}
                isVisible={
                  !(
                    evaluateRule(qec?.component?.hideWhen) ||
                    evaluateRule(qec?.spec?.hideWhen)
                  )
                }
              >
                <GenericInput
                  key={qec.spec.key}
                  device={device}
                  showInputName={showInputName}
                  customizationIdx={qec.customizationIdx}
                  customization={qec.customization}
                  componentIdx={qec.componentIdx}
                  component={qec.component}
                  specIdx={qec.specIdx}
                  spec={qec.spec}
                  initialValues={initialValues}
                  onValuesChanged={onValuesChanged}
                />
              </MaybeFiltered>
            );
          })}
        </ContentSection>
      </Wrapper>
    </MaybeFiltered>
  );
}

const Wrapper = styled.div`
  && {
    user-select: none;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  cursor: pointer;
`;

const Text = styled(DefaultTypography)`
  color: #818f9c;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.46px;
  text-transform: capitalize;
`;

const ContentSection = styled.div`
  && {
    display: ${(props: { isOpen: boolean }) =>
      props.isOpen ? `flex` : `none`};
    flex-direction: column;
    overflow: ${(props: { isOpen: boolean }) =>
      props.isOpen ? `visible` : `hidden`};

    && > * {
      margin-top: 1.5rem;
    }
  }
`;
