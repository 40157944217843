import { Customization, GeneralSetting } from '@/webapi/use-widget-catalog-api';

export enum StyleTemplateDevice {
  M = `M`,
  D = `D`,
}
export enum StyleTemplateType {
  CUSTOM = `CUSTOM`,
  DEFAULT = `DEFAULT`,
  EDIT_LATEST = `EDIT_LATEST`,
  PUBLISH_LATEST = `PUBLISH_LATEST`,
}

export type StyleTemplate = {
  id: string;
  name: string;
  type: StyleTemplateType;
  appId: string;
  device: StyleTemplateDevice;
  widgetName: string;
  props: { customizations: Array<Customization> };
  generalSettings?: GeneralSetting;
  isGlobal?: boolean;
};
export type StoreStyles = {
  humanEdited?: boolean;
  atcAttributes: {
    textColor: string;
    boxShadow: string;
    border: string;
    borderRadius: string;
    bgColor: string;
  };
  imageAttributes: {
    boxShadow: string;
    border: string;
    borderRadius: string;
  };
};
export type StyleTemplates = {
  templates: Array<StyleTemplate>;
  storeStyles?: StoreStyles;
};
