import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { conditional } from '@/utils/conditional';

export interface VisuallyLogoProps {
  wide?: boolean;
  small?: boolean;
  invert?: boolean;
}

export function VisuallyLogo({ wide, small, invert }: VisuallyLogoProps) {
  return (
    <Wrapper invert={invert}>
      {conditional(
        <StaticImage
          className="normal"
          src="./logo.png"
          alt="Visually logo"
          quality={100}
          objectFit="contain"
          placeholder="none"
        />,
        wide === true,
        <StaticImage
          className="wide"
          src="./logo-wide.svg"
          alt="Visually logo"
          quality={100}
          objectFit="contain"
          placeholder="none"
        />,
        small === true,
        <StaticImage
          className="small"
          src="./logo-small.svg"
          alt="Visually logo"
          quality={100}
          objectFit="contain"
          placeholder="none"
        />,
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  filter: ${(props: VisuallyLogoProps) => (props.invert ? `invert(1)` : ``)};
  .normal.gatsby-image-wrapper {
    width: 100px;
  }

  .wide.gatsby-image-wrapper {
    width: 130px;
  }

  .small.gatsby-image-wrapper {
    width: 30px;
  }
`;
