import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { centered } from '@/components/use-shared-element';
import { GradientModal } from '@/components/gradient-modal';
import { WelcomeModalContext } from '@/features/welcome-quiz/shared/context';

export const WELCOME_MODAL_RECT = centered(65, 80);

export interface WelcomeModalProps {
  fromRef: React.MutableRefObject<HTMLElement>;
  header?: any;
  footer?: any;
  children?: any;
}

export function BaseWelcomeModal({
  fromRef,
  header,
  footer,
  children,
}: WelcomeModalProps) {
  const { isVisible, setIsVisible } = useContext(WelcomeModalContext);

  return (
    <GradientModal
      closeTop="0.5rem"
      closeRight="2.5rem"
      fromStyle={{
        borderRadius: `5rem`,
        background: `white`,
        padding: `2rem 0 0 0`,
      }}
      toStyle={{
        borderRadius: `1rem`,
        background: `white`,
        padding: `2rem 0 0 0`,
      }}
      isVisible={isVisible}
      showBackdrop
      targetPosAndSize={WELCOME_MODAL_RECT}
      fromRef={fromRef}
      overflowTop={{ heightInRem: 8 }}
      overflowBottom={{ heightInRem: 8, turnPointInPercent: 30 }}
      hideCloseButton
      disableCloseOnBackdropClick
      onClose={() => setIsVisible(false)}
      header={header || ``}
      footer={footer ? <FooterWrapper>{footer}</FooterWrapper> : ``}
    >
      <ContentWrapper>{children !== undefined && children}</ContentWrapper>
    </GradientModal>
  );
}

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
  padding: 10rem 2rem 0 2rem;
`;

const FooterWrapper = styled.div`
  margin-bottom: 0.5rem;
`;
