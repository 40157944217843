import React, { useContext } from 'react';
import styled from 'styled-components';
import { ColorSaturationPicker } from '@/features/editor/widgets/custom-widget/inputs/background/color/components/color-sat-picker';
import { ColorPreview } from '@/features/editor/widgets/custom-widget/inputs/background/color/components/color-preview';
import { ColorAlphaPicker } from '@/features/editor/widgets/custom-widget/inputs/background/color/components/color-alpha-picker';
import { ColorPadding, Divider } from './components/commons';
import { ColorFavoritesPicker } from './components/fav-picker';
import { BackgroundColorContext } from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/context';
import { ColorHuePicker } from './components/color-hue-picker';
import { GradientSlider } from '@/features/editor/widgets/custom-widget/inputs/background/color/components/gradient-slider';
import {
  EyeDropper,
  useEyeDropProps,
} from '@/features/editor/widgets/custom-widget/inputs/background/color/eyeDropper';

export function LinearColorTab() {
  const {
    linearColor,
    linearFavourites,
    setLinearFavourites,
    updateLinearColor,
  } = useContext(BackgroundColorContext);
  const props = useEyeDropProps();

  return (
    <Wrapper>
      <GradientSlider />
      <Divider />
      <ControlsSection>
        <ColorSaturationPicker />
        <EyeDropper {...props} />
        <ControlsWrapper>
          <ColorPreview />
          <ColorHuePicker eyeDropColor={props.color} />
          <ColorAlphaPicker />
        </ControlsWrapper>
      </ControlsSection>
      <Divider />
      <ColorPadding>
        <ColorFavoritesPicker
          currentColor={linearColor}
          favorites={linearFavourites}
          setFavourites={setLinearFavourites}
          updateColor={updateLinearColor}
        />
      </ColorPadding>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 8rem 1px 1fr 1px 10rem;
`;

const ControlsSection = styled(ColorPadding)`
  && {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 30px 3fr;
    justify-content: center;
    grid-gap: 0.5rem;
  }
`;

const ControlsWrapper = styled.div`
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  justify-content: center;
  align-items: flex-end;
`;
