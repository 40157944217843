import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import {
  InputComponent,
  InputCustomization,
  TextEditInputSpec,
} from '@/webapi/checkout-widget-props';
import { CheckoutExtensibilityContext } from '@/features/editor/widgets/checkout-extensibility/context';
import { QuickEditTitle } from '@/features/editor/widgets/custom-widget/inputs/shared/radio-buttons';

export interface CheckoutInputEditTextProps {
  cust: InputCustomization;
  custId: number;
  comp: InputComponent;
  compId: number;
  spec: TextEditInputSpec;
  specId: number;
}

export function CheckoutInputEditText({
  cust,
  custId,
  compId,
  comp,
  specId,
  spec,
}: CheckoutInputEditTextProps) {
  const { getValueByPath, currentProps, setCurrentProps } = useContext(
    CheckoutExtensibilityContext,
  );

  const [hasFocus, setHasFocus] = useState(false);
  const value = useMemo(
    () => getValueByPath(custId, compId, specId).value,
    [currentProps],
  );

  const onChange = useCallback(
    (ev: any) => {
      setCurrentProps((draft) => {
        draft[cust.key][comp.key][spec.key] = ev.target.value;
      });
    },
    [currentProps, setCurrentProps],
  );

  return (
    <Wrapper>
      <QuickEditTitle small>{spec.title}</QuickEditTitle>
      <CustomTextInput
        onChange={onChange}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        hasFocus={hasFocus}
        value={value}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CustomTextInput = styled(TextareaAutosize)`
  && {
    resize: none;
    outline: none;
    font-size: 1.4rem;
    font-weight: 600;
    font-family: 'JetBrains Mono', serif;
    letter-spacing: -0.44px;
    color: #4b5564;

    margin: 0;

    border-radius: 0.8rem;
    background: white;
    padding: 0.8rem 1.7rem;
    border: ${(props: { hasFocus: boolean }) =>
      props.hasFocus
        ? `1px solid #95a2ae`
        : `1px solid rgba(149, 162, 174, 0.48)`};
    box-shadow: 0 9px 13px 0 rgba(177, 217, 203, 0.18);
    transition: border 0.3s ease-out;
  }
`;
