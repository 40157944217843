import styled from 'styled-components';
import { FaRedoAlt, FaUndoAlt } from 'react-icons/fa';
import React, { FC, useContext } from 'react';
import { useDetachedState } from '@/components/hooks/use-detached-state';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';
import { hideLoader, showLoader } from '@/components/PageLoader';

type Props = {
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
  debounceInterval?: number;
};
export const UndoRedoBtns: FC<Props> = ({
  undo,
  redo,
  canUndo,
  canRedo,
  debounceInterval,
}) => {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);
  const [debounce, setDebounce, debounceRef] = useDetachedState(false);

  function debounceCall(fn: () => void) {
    return () => {
      if (!debounceRef.current) {
        if (debounceInterval > 300) {
          showLoader();
        }
        setDebounce(true);
        setTimeout(() => {
          hideLoader();
          setDebounce(false);
        }, debounceInterval || 300);
        fn();
      }
    };
  }

  const onUndo = debounceCall(undo);
  const onRedo = debounceCall(redo);
  return (
    <Wrapper bothOff={!canUndo && !canRedo}>
      <Button
        device={device}
        disabled={!canUndo || debounce}
        off={!canUndo}
        onClick={onUndo}
        isLeft
      >
        <FaUndoAlt
          size={device === DeviceType.Desktop ? 12 : 15}
          color="#324A5E"
          opacity={0.35}
        />
      </Button>
      <Seprator />
      <Button
        device={device}
        disabled={!canRedo || debounce}
        off={!canRedo || debounce}
        onClick={onRedo}
      >
        <FaRedoAlt
          size={device === DeviceType.Desktop ? 12 : 15}
          color="#324A5E"
          opacity={0.35}
        />
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.2rem;
  border: 2px solid #ccd1d6;
  background: white;
  opacity: ${(props: any) => (props?.bothOff ? `0.5` : 1)};
`;

type Prop = { off: boolean; isLeft: boolean; device?: DeviceType };

const Button = styled.button`
  height: 100%;
  border-bottom-left-radius: ${(p: Prop) => (p?.isLeft ? `1rem` : `0`)};
  border-top-left-radius: ${(p: Prop) => (p?.isLeft ? `1rem` : `0`)};
  border-bottom-right-radius: ${(p: Prop) => (!p?.isLeft ? `1rem` : `0`)};
  border-top-right-radius: ${(p: Prop) => (!p?.isLeft ? `1rem` : `0`)};

  background: ${(p: Prop) => (p.off ? `#E3E8EB` : `white`)};
  padding: ${(p: Prop) =>
    p?.device === DeviceType.Desktop ? `0 0.8rem` : `0 1.2rem`};
  cursor: ${(p: Prop) => (p.off ? `default` : `pointer`)};
  padding-top: 2px;
  transition: background-color 200ms ease-out;
  margin: 0;

  &:hover {
    background: ${(p: Prop) =>
      p.off ? `#E3E8EB` : `rgba(227, 232, 235, 0.2)`};
  }

  &:active {
    background: ${(p: Prop) =>
      p.off ? `#E3E8EB` : `rgba(227, 232, 235, 0.5)`};
  }
`;

const Seprator = styled.span`
  height: 100%;
  width: 1px;
  background-color: #e3e8eb;
  opacity: 0.3;
`;
