import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';
import { NumberInputImage } from '@/features/editor/widgets/custom-widget/inputs/shared/number-input-image';

const VALUE_KEY = `lineHeight`;

interface FontSizeInputProps extends GenericInputProps {
  fontSize: string;
}

export function LineHeightInput({
  initialValues,
  onValuesChanged,
  fontSize,
}: FontSizeInputProps) {
  const { undoRedoCount } = useContext(UndoRedoCounterContext);

  const getValue = useCallback(() => initialValues(VALUE_KEY), [undoRedoCount]);

  const [value, setValue] = useState(getValue());
  useEffect(() => setValue(getValue()), [undoRedoCount]);

  const onChange = (value) => {
    setValue(value);
  };

  useEffect(() => {
    onValuesChanged(VALUE_KEY, value);
  }, [value]);

  return (
    <Wrapper>
      <NumberInputImage
        min={1}
        max={128}
        fontSize={fontSize}
        suffix="px"
        Image={LineHeightIcon}
        onChange={onChange}
        defaultValue={value}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  && > div {
    box-shadow: none !important;
  }
`;
const LineHeightIcon = () => (
  <StaticImage
    src="../../../../../../assets/line-height.svg"
    alt="line height"
    height={16}
    placeholder="none"
    loading="eager"
    objectFit="contain"
  />
);
