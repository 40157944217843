export function awaitCondition(
  condition: () => boolean,
  timeout = 500,
  retries = 4,
) {
  return new Promise((resolve, reject) => {
    if (condition()) {
      resolve(0);
      return;
    }
    if (retries === 0) {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject(`condition rejected ${condition?.toString()}`);
      return;
    }
    setTimeout(() => {
      awaitCondition(condition, timeout, retries - 1)
        .then(resolve)
        .catch(reject);
    }, timeout);
  });
}
