import React, { useContext } from 'react';
import styled from 'styled-components';
import { CachePolicies, useFetch } from 'use-http';
import { useComplexState } from '@/utils/use-complex-state';
import { VSpace } from '@/components/spacing';
import { TextInput } from '@/components/text-input';
import { StyledTemplateContext } from '@/features/editor/widgets/custom-widget/style-templating/context';
import { StoreStyles } from '@/features/editor/widgets/custom-widget/style-templating/models';
import { STORE_STYLE_ID } from '@/features/editor/widgets/custom-widget/shared/store-style-utils';
import { routes } from '@/webapi/routes';
import { clone } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/shared';

export const Label = styled.span`
  color: #95a2ae;
  font-family: 'JetBrains Mono', serif;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: -0.38px;
  margin-bottom: 0.4rem;
`;

const Container = styled.div`
  flex-direction: column;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function StoreStylesOverrideForm() {
  const { storeStyleOverride: state, setStoreStyleOverride: setState } =
    useContext(StyledTemplateContext);
  const atc = state?.atcAttributes;
  const img = state?.imageAttributes;
  return (
    <Container>
      <VSpace value={4} />
      <Label>atc border</Label>
      <TextInput
        onChange={(e) => {
          setState((draft) => {
            if (!draft.atcAttributes) {
              draft.atcAttributes = {} as any;
            }
            draft.atcAttributes.border = e.target.value;
          });
        }}
        defaultValue={atc?.border || ``}
        placeholder=""
        style={{ width: `95%`, boxShadow: `none` }}
      />
      <VSpace />
      <Label>atc border-radius</Label>
      <TextInput
        onChange={(e) => {
          setState((draft) => {
            if (!draft.atcAttributes) {
              draft.atcAttributes = {} as any;
            }
            draft.atcAttributes.borderRadius = e.target.value;
          });
        }}
        defaultValue={atc?.borderRadius || ``}
        placeholder=""
        style={{ width: `95%`, boxShadow: `none` }}
      />
      <VSpace />
      <Label>atc background-color</Label>
      <TextInput
        onChange={(e) => {
          setState((draft) => {
            if (!draft.atcAttributes) {
              draft.atcAttributes = {} as any;
            }
            draft.atcAttributes.bgColor = e.target.value;
          });
        }}
        defaultValue={atc?.bgColor || ``}
        placeholder=""
        style={{ width: `95%`, boxShadow: `none` }}
      />
      <VSpace />
      <Label>atc color</Label>
      <TextInput
        onChange={(e) => {
          setState((draft) => {
            if (!draft.atcAttributes) {
              draft.atcAttributes = {} as any;
            }
            draft.atcAttributes.textColor = e.target.value;
          });
        }}
        defaultValue={atc?.textColor || ``}
        placeholder=""
        style={{ width: `95%`, boxShadow: `none` }}
      />
      <VSpace />
      <Label>atc box-shadow</Label>
      <TextInput
        onChange={(e) => {
          setState((draft) => {
            if (!draft.atcAttributes) {
              draft.atcAttributes = {} as any;
            }
            draft.atcAttributes.boxShadow = e.target.value;
          });
        }}
        defaultValue={atc?.boxShadow || ``}
        placeholder=""
        style={{ width: `95%`, boxShadow: `none` }}
      />
      <VSpace />
      <Label>image border radius</Label>
      <TextInput
        onChange={(e) => {
          setState((draft) => {
            if (!draft.imageAttributes) {
              draft.imageAttributes = {} as any;
            }
            draft.imageAttributes.borderRadius = e.target.value;
          });
        }}
        defaultValue={img?.borderRadius || ``}
        placeholder=""
        style={{ width: `95%`, boxShadow: `none` }}
      />
      <VSpace />
      <Label>image border</Label>
      <TextInput
        onChange={(e) => {
          setState((draft) => {
            if (!draft.imageAttributes) {
              draft.imageAttributes = {} as any;
            }
            draft.imageAttributes.border = e.target.value;
          });
        }}
        defaultValue={img?.border || ``}
        placeholder=""
        style={{ width: `95%`, boxShadow: `none` }}
      />
      <VSpace />
      <Label>image box shadow</Label>
      <TextInput
        onChange={(e) => {
          setState((draft) => {
            if (!draft.imageAttributes) {
              draft.imageAttributes = {} as any;
            }
            draft.imageAttributes.boxShadow = e.target.value;
          });
        }}
        defaultValue={img?.boxShadow || ``}
        placeholder=""
        style={{ width: `95%`, boxShadow: `none` }}
      />
    </Container>
  );
}

export function useStoreStylesOverride(
  deleteStyleId: string,
  storeStyle: StoreStyles,
) {
  const { post } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
    },
  }));

  const showUpdateStoreStyleModal =
    deleteStyleId === `default` || deleteStyleId === STORE_STYLE_ID;
  const [storeStyleOverride, setStoreStyleOverride] = useComplexState(
    defaultStoreStylesOverride(storeStyle),
  );
  const resetStylesOverride = () => {
    setStoreStyleOverride((draft) => {
      const override = defaultStoreStylesOverride(storeStyle);
      draft.imageAttributes = override.atcAttributes;
      draft.imageAttributes = override.imageAttributes;
    });
  };

  const overrideStoreStyle = async () => {
    const styleOverride = {
      ...storeStyleOverride,
      humanEdited: true,
    };
    await post(routes.overrideStoreStyle, styleOverride);
    return clone(styleOverride);
  };

  return {
    overrideStoreStyle,
    showUpdateStoreStyleModal,
    storeStyleOverride,
    setStoreStyleOverride,
    resetStylesOverride,
  };
}

function defaultStoreStylesOverride(storeStyle: StoreStyles) {
  return (
    storeStyle ||
    ({
      imageAttributes: {},
      atcAttributes: {},
    } as StoreStyles)
  );
}
