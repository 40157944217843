const STOP_NAV_TO_EDITOR = `vsly_stop_nav_to_editor`;

export function shouldNavToEditorOnFirstUse(): boolean {
  const shouldStopNav = sessionStorage.getItem(STOP_NAV_TO_EDITOR);
  return shouldStopNav !== `true`;
}

export function markNavToEditorOnFirstUseAsDone() {
  sessionStorage.setItem(STOP_NAV_TO_EDITOR, `true`);
}
