import { useCallback, useContext, useState } from 'react';
import { EditorContext } from '@/features/editor/context/editor-context';
import { AccountContext } from '@/features/account-context';
import { ExperienceStateHook } from '@/features/editor/context/use-experience-state';
import { UseDeviceNavigationHook } from '@/features/editor/context/use-device-navigation';

export function usePreviewDetails() {
  const {
    showAppEmbedModalIfNeeded,
    account: {
      store: { domain },
    },
  } = useContext(AccountContext);
  const [previewPopoverOpen, setPreviewPopoverOpen] = useState(false);
  const { experienceState, deviceNavigation, currentExtension } =
    useContext(EditorContext);

  const quickPreviewUrl = getCheckoutUrl(
    experienceState,
    deviceNavigation,
    currentExtension,
    domain,
  );

  const togglePopover = useCallback(async () => {
    if (showAppEmbedModalIfNeeded()) {
      return;
    }
    setPreviewPopoverOpen(!previewPopoverOpen);
  }, [previewPopoverOpen]);

  return {
    quickPreviewUrl,
    togglePopover,
    previewPopoverOpen,
  };
}

function getCheckoutUrl(
  experienceState: ExperienceStateHook,
  deviceNavigation: UseDeviceNavigationHook,
  currentExtension:
    | ''
    | 'shopify-post-purchase'
    | 'shopify-checkout-extensibility',
  domain: string,
) {
  let quickPreviewUrl = experienceState?.currentExperience?.quickPreviewUrl;
  if (
    deviceNavigation?.generateCheckoutUrlForPreview &&
    experienceState?.currentExperience?.quickPreviewUrl?.includes(`sdk.loomi`)
  ) {
    quickPreviewUrl = deviceNavigation?.generateCheckoutUrlForPreview();
  } else if (currentExtension === `shopify-checkout-extensibility`) {
    quickPreviewUrl = domain;
  }
  return quickPreviewUrl;
}

export function usePreviewToggle() {
  const {
    account: {
      store: { domain },
    },
  } = useContext(AccountContext);
  const { experienceState, deviceNavigation, currentExtension } =
    useContext(EditorContext);

  const quickPreviewUrl = getCheckoutUrl(
    experienceState,
    deviceNavigation,
    currentExtension,
    domain,
  );
  const [previewPopoverOpen, setPreviewPopoverOpen] = useState(false);
  const togglePopover = useCallback(async () => {
    setPreviewPopoverOpen(!previewPopoverOpen);
  }, [previewPopoverOpen]);

  return {
    quickPreviewUrl,
    togglePopover,
    previewPopoverOpen,
  };
}
