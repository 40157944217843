import { useContext } from 'react';
import * as React from 'react';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';
import {
  DropdownInput,
  DropdownInputOption,
} from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { InputsDarkTheme } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';

export function PostPurchaseShowTimer() {
  const {
    props: {
      settings: { showTimer },
    },
    setProps,
  } = useContext(PostPurchaseContext);

  const options: DropdownInputOption[] = [
    { label: `Without Timer`, value: false },
    { label: `With Timer`, value: true },
  ];

  const onChange = (val: DropdownInputOption) => {
    setProps((draft) => {
      draft.settings.showTimer = val.value as boolean;
    });
  };

  return (
    <DropdownInput
      theme={InputsDarkTheme}
      options={options}
      defaultValue={showTimer ? options[1] : options[0]}
      onChange={onChange}
      optionStyles={{ textTransform: `uppercase` }}
    />
  );
}
