import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import {
  LayeredRulingContext,
  RuleState,
} from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import {
  LoadingStrategyOption,
  RecommendationType,
} from '@/webapi/use-widget-catalog-api';
import { Slot } from '@/features/editor/widgets/custom-widget/loading-section/shared/slot';
import { defaultCondition } from '@/features/editor/widgets/custom-widget/loading-section/util/defaultCondition';
import { hashSlot } from '@/features/editor/widgets/custom-widget/loading-section/shared/util/hashSlot';
import { SlotState } from '@/features/editor/widgets/custom-widget/loading-section/shared/models';
import { VSpace } from '@/components/spacing';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';

type Props = { ruleId: number };

export function StrategyPerSlot({ ruleId }: Props) {
  const ctx = useContext(LayeredRulingContext);
  const rule = ctx.rule(ruleId);
  const onMoreFilters = (s: SlotState) => () => {
    ctx.changeState((draft) => {
      draft.activeCondSlot = s.num;
      draft.showCondBuilder = true;
    });
  };
  const widgetContextProps = useContext(CustomWidgetContext);
  const onStrategySelect =
    (s: SlotState) => (o: LoadingStrategyOption) => () => {
      ctx.changeSlot(ruleId, s.num, (s) => {
        delete s.manualProduct;
        s.strategy = o.value.type;
        s.filter = defaultCondition(
          s.strategy,
          widgetContextProps?.currentSchema?.appId,
        );
        s.hash = hashSlot(s);
      });
    };
  const onDelete = (s: SlotState) => () => {
    ctx.changeSlot(ruleId, s.num, (s) => {
      s.manualProduct = undefined;
      s.strategy = undefined;
      s.filter = undefined;
    });
  };
  const onProductSelect = (s: SlotState) => () => {
    ctx.setProductSearchSlotOpen(s.num);
    ctx.changeSlot(ruleId, s.num, (s) => {
      s.strategy = RecommendationType.MANUAL;
      s.filter = defaultCondition(
        RecommendationType.MANUAL,
        widgetContextProps?.currentSchema?.appId,
      );
      s.hash = hashSlot(s);
    });
  };

  if (rule.strategy !== RecommendationType.MANUAL) {
    return null;
  }

  const numOfSlots = useWatchSlotsSize(rule, ruleId);

  return (
    <Container>
      <Span>Product/Strategy per slot ({numOfSlots} slots)</Span>
      <VSpace />
      <Grid>
        {rule.strategyPerSlot.slots.map((s) => (
          <Slot
            key={`${s.hash || ``}${s.num || ``}`}
            slot={s}
            onMoreFilters={onMoreFilters(s)}
            onStrategySelect={onStrategySelect(s)}
            onDelete={onDelete(s)}
            onProductSelect={onProductSelect(s)}
          />
        ))}
      </Grid>
    </Container>
  );
}
const Span = styled.div`
  text-transform: uppercase;
  width: 100%;
  text-align: start;
  font-weight: 600;
  font-size: 1.4rem;
  color: #959ab5;
  margin: 1rem 0 2rem 0;
`;
const Container = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  width: 100%;
`;

function useWatchSlotsSize(rule: RuleState, ruleId: number) {
  const ctx = useContext(LayeredRulingContext);
  const numOfSlots = ctx.getManualSlots().length;
  useEffect(() => {
    if (rule.strategyPerSlot.slots.length !== numOfSlots) {
      ctx.change(ruleId, (r) => {
        r.strategyPerSlot.slots = ctx
          .getManualSlots()
          .map(
            (empty) =>
              r.strategyPerSlot.slots.find(
                (existing) => existing.num === empty.num,
              ) || empty,
          );
      });
    }
  }, [numOfSlots]);
  return numOfSlots;
}
