import styled from 'styled-components';
import React, { useContext, useEffect, useMemo } from 'react';

import {
  EditorChangeKind,
  EditorDeclarativeBlock,
} from '@/webapi/use-experience-api';
import { Flexbox } from '@/components/flex';
import { INSPECTOR_BACK_ID } from '@/features/editor/inspector';
import { SectionTitle } from '@/features/editor/widgets/custom-widget/shared/shared-styles';
import { DeviceType } from '@/utils/definitions';
import { FakeClickCta } from '@/features/editor/widgets/fake-click/shared';
import {
  FakeClickContext,
  newFakeClickContext,
} from '@/features/editor/widgets/fake-click/context';
import { FakeClickEmptyState } from '@/features/editor/widgets/fake-click/empty-state';
import { FakeClickNonEmptyState } from '@/features/editor/widgets/fake-click/nonempty-state';
import { EditorContext } from '@/features/editor/context/editor-context';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import { AutomationChange, AutomationStep } from '@/pkg/sdk';
import { getDeviceBasedEditorId } from '@/webapi/common';

export interface FakeClickWidgetProps {
  initialChange: EditorDeclarativeBlock;
}

export const fakeClickChange = (
  id: string,
  steps: AutomationStep[],
): EditorDeclarativeBlock => ({
  editorId: id.startsWith(`fakeclick-`) ? id : `fakeclick-${id}`,
  editorSelector: `${id}`,
  editorKind: EditorChangeKind.FAKE_CLICK,
  block: {
    kind: `automation`,
    selector: `div`,
    value: { steps } as AutomationChange,
  },
});

export function FakeClickWidget({ initialChange }: FakeClickWidgetProps) {
  const {
    applyTempChange,
    experienceState: {
      upsertEditorChange,
      currentExperience: { experienceCreationVersion },
    },
    inspectorNav: { gotoChangelog },
  } = useContext(EditorContext);
  const ctx = newFakeClickContext(initialChange);
  const { device, onBack, steps, currentState, isNew } = ctx;

  const editorId = useMemo(
    () =>
      getDeviceBasedEditorId(
        initialChange?.editorId,
        device,
        experienceCreationVersion,
      ),
    [initialChange?.editorId],
  );

  const onAdd = () => {
    upsertEditorChange(fakeClickChange(editorId, ctx.steps), true);
    gotoChangelog();
  };

  useEffect(() => {
    applyTempChange(fakeClickChange(initialChange.editorId, []));
  }, []);

  return (
    <FakeClickContext.Provider value={ctx}>
      <InspectorWidgetLayout
        progress={50}
        title={`2. Let's record`}
        backCaption="< Back to widgets"
        onBackClicked={onBack}
      >
        <Wrapper device={device}>
          <Content>
            <SectionTitle>General Customization</SectionTitle>

            <StepsWrapper>
              {currentState === `paused` && steps.length === 0 ? (
                <FakeClickEmptyState />
              ) : (
                <FakeClickNonEmptyState />
              )}
            </StepsWrapper>
            {currentState === `paused` && (
              <FakeClickCta
                disabled={currentState === `paused` && steps.length === 0}
                br="1rem"
                onClick={onAdd}
              >
                {isNew ? `Add` : `Update`}
              </FakeClickCta>
            )}
          </Content>
        </Wrapper>
      </InspectorWidgetLayout>
    </FakeClickContext.Provider>
  );
}

const Wrapper = styled(Flexbox)`
  && {
    height: auto;
    width: ${(p: any) => (p.device === DeviceType.Desktop ? `100%` : `60%`)};

    #${INSPECTOR_BACK_ID} {
      z-index: 31;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 2rem;
`;

const StepsWrapper = styled.div`
  padding: 3rem;
  border-radius: 1rem;
  background: #f3f6f7;

  color: #222a31;
  letter-spacing: -0.47px;
  font-family: 'JetBrains Mono', serif;
  font-size: 1.4rem;
  text-align: center;
  font-weight: 500;

  line-height: 1.8;
`;
