import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  LinearGradientColor,
  RadialGradientColor,
  SolidColor,
} from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/models';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';

export function ColorTile({
  colorStyleStr,
  color,
  onClick,
  noLabel,
  selected,
  tileStyle,
}: {
  colorStyleStr: string;
  color?: SolidColor | LinearGradientColor | RadialGradientColor;
  onClick?: ({ event, color }) => void;
  noLabel?: boolean;
  selected?: boolean;
  tileStyle?: `circle` | `square`;
}) {
  const onSelected = (event) => {
    onClick && onClick({ event, color });
  };

  const { undoRedoCount } = useContext(UndoRedoCounterContext);
  return (
    <Wrapper onClick={onSelected}>
      <ColorViewWrapper selected={selected} tileStyle={tileStyle}>
        <ColorView
          key={undoRedoCount}
          color={colorStyleStr}
          selected={selected}
          tileStyle={tileStyle}
        />
      </ColorViewWrapper>
      {!noLabel && <Label>Color</Label>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;

const ColorViewWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-position: 0px 0px, 8.5px 8.5px;
  background-size: 17px 17px;
  background-image: ${(props: { selected: boolean }) =>
    props?.selected
      ? `none`
      : `linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.1) 25%,
      transparent 25%,
      transparent 75%,
      rgba(0, 0, 0, 0.1) 75%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.1) 25%,
      white 25%,
      white 75%,
      rgba(0, 0, 0, 0.1) 75%,
      rgba(0, 0, 0, 0.1) 100%
    )`};
  border-radius: ${(props: { tileStyle: string }) =>
    props?.tileStyle === `circle` ? `50%` : `inherit`};
  border: ${(props: { selected: boolean }) =>
    props?.selected ? `3px solid #000000` : `1px solid #dedede`};
`;

const ColorView = styled.div`
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-radius: ${(props: { tileStyle: string }) =>
    props?.tileStyle === `circle` ? `50%` : `10px`};
  background: ${(props: { color: string }) => props?.color};
  border: ${(props: { selected: boolean }) =>
    props?.selected ? `3px solid #ffffff` : `none`};
`;

const Label = styled.span`
  opacity: 0;
  margin-top: 0.4rem;
  color: rgb(193, 196, 203);
  font-family: 'JetBrains Mono', serif;
  font-weight: 700;
  font-size: 0.9rem;
  letter-spacing: -0.43px;
`;
