import React, { useContext } from 'react';
import { CatalogSearch } from '@/components/catalog-search';
import { VSpace } from '@/components/spacing';
import { ImageEditorContext } from '@/features/editor/widgets/custom-widget/inputs/background/image/shared/context';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';

export function ImageEditorMyCatalog({
  getModalRect,
}: {
  getModalRect: () => DOMRect;
}) {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);
  const { setImage } = useContext(ImageEditorContext);
  return (
    <>
      <VSpace value={1} />
      <CatalogSearch
        getModalRect={getModalRect}
        columnsCount={device === DeviceType.Desktop ? 2 : 3}
        onSelected={(p) => {
          setImage(p.image.src);
        }}
      />
    </>
  );
}
