import styled from 'styled-components';

interface SP {
  thickness?: number;
  size?: number;
  color?: string;
}

export const ExperienceSpinner = styled.span`
  border: ${(p: SP) => p?.thickness || 0.2}rem solid
    ${(p: SP) => p?.color || `currentcolor`};
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: 1s loader linear infinite;
  position: relative;
  height: ${(p: SP) => p.size || 1}rem;
  width: ${(p: SP) => p.size || 1}rem;

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
