export function groupBy<T>(
  list: Array<T>,
  keyGetter: (item: T) => any,
): Record<any, T[]> {
  const map = {} as Record<any, T[]>;
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map[key];
    if (!collection) {
      map[key] = [item];
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function groupByAndFlatten<T>(
  list: Array<T>,
  keyGetter: (item: T) => any,
): Record<any, T> {
  const map = {} as Record<any, T>;
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map?.[key];
    if (!collection) {
      map[key] = item;
    } else {
      map[key] = item;
    }
  });
  return map;
}

export function removeDuplicatesByKey<T>(arr: Array<T>, key): Array<T> {
  const seen = new Set();
  return arr.filter((item) => {
    const itemKey = item[key];
    if (!seen.has(itemKey)) {
      seen.add(itemKey);
      return true;
    }
    return false;
  });
}
