import React, {
  FC,
  MutableRefObject,
  useContext,
  useEffect,
  useState,
} from 'react';
import { StyledTemplateContext } from '@/features/editor/widgets/custom-widget/style-templating/context';
import { GradientModal } from '@/components/gradient-modal';
import { centered } from '@/components/use-shared-element';
import { ModalHeader } from '@/features/editor/widgets/custom-widget/style-templating/modal-header';
import { ModalFooter } from '@/features/editor/widgets/custom-widget/style-templating/modal-footer';
import { ModalContent } from '@/features/editor/widgets/custom-widget/style-templating/modal-content';
import { useIsSuperSystemUser } from '@/features/editor/widgets/custom-widget/style-templating/utils';

type Props = { fromRef: MutableRefObject<HTMLElement> };
export const SaveModal: FC<Props> = ({ fromRef: ref }) => {
  const {
    visible,
    modalCtx,
    deleteStyleId,
    renameClicked,
    showOverrideModal,
    showUpdateStoreStyleModal,
  } = useContext(StyledTemplateContext);

  const { onModalClose } = modalCtx;
  const isSuperSystemUser = useIsSuperSystemUser();

  const [heightRem, setHeightRem] = useState(30);
  const shouldShowSuperSaveBtn =
    isSuperSystemUser && !renameClicked && !showOverrideModal && !deleteStyleId;

  useEffect(() => {
    if (shouldShowSuperSaveBtn) {
      setHeightRem(36);
    } else if (showUpdateStoreStyleModal) {
      setHeightRem(80);
    } else {
      setHeightRem(deleteStyleId ? 23 : 30);
    }
  }, [deleteStyleId, shouldShowSuperSaveBtn]);
  return (
    <GradientModal
      key={`gm${heightRem}`}
      closeTop="0.5rem"
      closeRight="2.5rem"
      fromStyle={{
        opacity: 0,
        borderRadius: `5rem`,
        backgroundColor: `#DEDEDE`,
        padding: `2rem 0 0 0`,
      }}
      toStyle={{
        background: `white`,
        opacity: 1,
        borderRadius: `1rem`,
        backgroundColor: `#FFFFFF`,
        padding: `2rem 0 0 0`,
      }}
      isVisible={visible}
      showBackdrop
      targetPosAndSize={centered(heightRem, 50)}
      fromRef={ref}
      overflowTop={{ heightInRem: 8 }}
      overflowBottom={{ heightInRem: 8, turnPointInPercent: 30 }}
      onClose={onModalClose}
      header={<ModalHeader />}
      footer={<ModalFooter />}
    >
      <ModalContent />
    </GradientModal>
  );
};
