import produce from 'immer';
import {
  EditorChangeKind,
  EditorDeclarativeBlock,
  HtmlTransformKind,
} from '@/webapi/use-experience-api';
import { schemaToEnv } from '@/features/editor/widgets/custom-widget/env';
import {
  CatalogWidget,
  CatalogWidgetProps,
} from '@/webapi/use-widget-catalog-api';
import {
  CheckoutNewComponent,
  NewElementMessage,
} from '@/features/editor/context/use-device-preview';
import {
  getOriginExperienceId,
  getOriginExperienceName,
} from '@/features/editor/shared/external_experience_widget';
import {
  AllMutationKind,
  CheckoutWidgetChange,
  CompoundChange,
  DeclarativeBlock,
  HtmlMutationKind,
  MoveChange,
  WidgetChange,
} from '@/pkg/sdk';
import { DeviceType } from '@/utils/definitions';
import { CheckoutWidgetManifest } from '@/webapi/checkout-widget-props';

export const useTranspiler = (): TranspilerHook => {
  const asCompoundChange = (
    editorKind: EditorChangeKind,
    transformKind: HtmlTransformKind,
    editorSelector: string,
    selector: string,
    html: string,
    css?: string,
    js?: string,
    id?: string,
  ): EditorDeclarativeBlock => ({
    editorId: id || selector,
    editorKind,
    editorSelector,
    block: {
      kind: `compound`,
      selector,
      value: {
        htmlKind: fromTransformKind(transformKind) as HtmlMutationKind,
        html,
        css,
        js,
      },
    },
  });

  const asDeclarativeBlock = (
    m: EditorDeclarativeBlock[],
  ): Record<string, DeclarativeBlock> => {
    const out: Record<string, DeclarativeBlock> = {};
    m?.forEach((item, idx) => {
      out[item.editorId] = produce(item.block, (draft) => {
        draft.order = idx;
        if (
          !!item.initialHtml &&
          item.block.kind === `compound` &&
          (item.block.value as CompoundChange).html === ``
        ) {
          (draft.value as CompoundChange).html = item.initialHtml;
        }
      });
    });
    return out;
  };

  const asSingleValueBlock = (
    editorKind: EditorChangeKind,
    id: string,
    kind: AllMutationKind,
    editorSelector: string,
    selector: string,
  ) =>
    ({
      editorId: id,
      editorKind,
      editorSelector,
      block: {
        kind,
        selector,
        value: ``,
      },
    } as EditorDeclarativeBlock);

  const asHideElementBlock = (
    selector: string,
    device?: DeviceType,
  ): EditorDeclarativeBlock => {
    const delChange = asSingleValueBlock(
      EditorChangeKind.HIDE_COMPONENT,
      `hide-${device || ``}-${selector}`,
      `appendCss`,
      selector,
      `div`,
    );
    delChange.block.value = `${selector} {display:none!important;}`;
    return delChange;
  };

  const globalCssId = (expId: string, device?: DeviceType) =>
    device ? `global-css-${device}-${expId}` : `global-css-${expId}`;
  const globalJsId = (expId: string, device?: DeviceType) =>
    device ? `global-js-${device}-${expId}` : `global-js-${expId}`;

  const asNewContentChange = (
    id: string,
    kind: HtmlTransformKind,
    selector: string,
    animate?: boolean,
    asPlusIcon?: boolean,
  ): EditorDeclarativeBlock =>
    asCompoundChange(
      EditorChangeKind.NEW_COMPONENT,
      kind,
      `#${id}`,
      selector,
      `
<section id="${id}" class="__loomi_element_placeholder">
${asPlusIcon ? plusIcon : checkmarkIcon}
</section>`,
      `
      .__loomi_element_placeholder {
        font-size: 20px;
        color: white;
        text-align: center;
        background: linear-gradient(180deg, #FF7EF8 0%, #F23FD1 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3.5rem;
        width: 100%;
        padding: 1rem;
        margin: 1rem 0;

        border-radius: 7px;
        ${animate ? `animation: __loomi_show_placeholder 0.1s linear;` : ``}
      }

      .__loomi_element_placeholder_icon {
        margin-top: -0.3rem;
      }

      @keyframes __loomi_show_placeholder {
        from {
          height: 1rem;
          opacity: 0.5;
        }
        to {
          height: 3.5rem;
          opacity: 1;
        }
      }
      `,
      ``,
      id,
    );

  const asWidgetChange = (
    origChange: EditorDeclarativeBlock,
    widget: CatalogWidget,
    schema: CatalogWidgetProps,
  ): EditorDeclarativeBlock =>
    ({
      editorId: origChange.editorId,
      editorKind: origChange.editorKind,
      editorSelector: origChange.editorSelector,
      widgetProps: schema,
      hint: origChange.hint,
      block: {
        kind: `widget`,
        selector: origChange.block.selector,
        value: {
          htmlKind: (origChange.block.value as CompoundChange).htmlKind,
          env: schemaToEnv(
            schema,
            origChange.editorId,
            getOriginExperienceName(origChange),
            getOriginExperienceId(origChange),
          ),
          widgetId: widget.id,
          version: widget.version,
        } as WidgetChange,
      },
    } as EditorDeclarativeBlock);

  const asCheckoutWidgetChange = (
    widgetManifest: CheckoutWidgetManifest | null,
    widgetProps: any | null,
    mountPointInfo: CheckoutNewComponent,
  ): EditorDeclarativeBlock => {
    const sectionId =
      mountPointInfo?.sectionId || `_loomi_addon_${new Date().getTime()}`;
    const selector = `#${sectionId}`;
    return {
      editorId: sectionId,
      editorKind: EditorChangeKind.NEW_COMPONENT,
      editorSelector: selector,
      checkoutWidgetManifest: widgetProps,
      checkoutMountPointInfo: mountPointInfo,
      block: {
        kind: `checkoutWidget`,
        selector,
        value: {
          op: mountPointInfo?.placement,
          opDependsOn: mountPointInfo?.dependsOnSectionId,
          props: widgetProps,
          sectionId,
          mountPoint: mountPointInfo?.mountPointSelector,
          widgetId: widgetManifest?.id,
          version: widgetManifest?.version,
        } as CheckoutWidgetChange,
      },
    } as EditorDeclarativeBlock;
  };

  const asHideCheckoutWidgetChange = (
    sectionId: string,
  ): EditorDeclarativeBlock => {
    const selector = `#${sectionId}`;
    return {
      editorId: `rm-${sectionId}`,
      editorKind: EditorChangeKind.HIDE_COMPONENT,
      editorSelector: selector,
      block: {
        kind: `rmCheckoutWidget`,
        value: sectionId,
      },
    } as EditorDeclarativeBlock;
  };

  const asMoveChange = (
    editorId: string,
    srcSelector: string,
    destSelector: string,
    transformKind: HtmlMutationKind,
  ): EditorDeclarativeBlock => ({
    editorId,
    editorKind: EditorChangeKind.MOVE_COMPONENT,
    editorSelector: srcSelector,
    block: {
      kind: `moveElem`,
      selector: srcSelector,
      value: {
        destSelector,
        htmlKind: transformKind,
      } as MoveChange,
    } as DeclarativeBlock,
  });

  return {
    asCompoundChange,
    asDeclarativeBlock,
    asSingleValueBlock,
    globalCssId,
    globalJsId,
    asNewContentChange,
    asWidgetChange,
    asCheckoutWidgetChange,
    asHideCheckoutWidgetChange,
    asHideElementBlock,
    asMoveChange,
  };
};

export interface TranspilerHook {
  asCompoundChange: (
    editorKind: EditorChangeKind,
    transformKind: HtmlTransformKind,
    editorSelector: string,
    selector: string,
    html: string,
    css?: string,
    js?: string,
    id?: string,
  ) => EditorDeclarativeBlock;

  asDeclarativeBlock: (
    m: EditorDeclarativeBlock[],
  ) => Record<string, DeclarativeBlock>;

  asSingleValueBlock: (
    editorKind: EditorChangeKind,
    id: string,
    kind: AllMutationKind,
    editorSelector: string,
    selector: string,
  ) => EditorDeclarativeBlock;

  asHideElementBlock: (
    selector: string,
    device?: DeviceType,
  ) => EditorDeclarativeBlock;

  asNewContentChange: (
    id: string,
    transform: HtmlTransformKind,
    selector: string,
    animate?: boolean,
    asPlusIcon?: boolean,
  ) => EditorDeclarativeBlock;

  asWidgetChange: (
    origChange: EditorDeclarativeBlock,
    widget: CatalogWidget,
    schema: CatalogWidgetProps,
  ) => EditorDeclarativeBlock;

  asCheckoutWidgetChange: (
    widgetManifest: CheckoutWidgetManifest,
    widgetProps: any,
    mountPointInfo: CheckoutNewComponent,
  ) => EditorDeclarativeBlock;

  asHideCheckoutWidgetChange: (sectionId: string) => EditorDeclarativeBlock;

  asMoveChange: (
    editorId: string,
    srcSelector: string,
    destSelector: string,
    transformKind: HtmlMutationKind,
  ) => EditorDeclarativeBlock;

  globalCssId: (expId: string, device?: DeviceType) => string;
  globalJsId: (expId: string, device?: DeviceType) => string;
}

export function fromTransformKind(kind: HtmlTransformKind) {
  if (kind === HtmlTransformKind.REPLACE) {
    return `replace`;
  }
  if (kind === HtmlTransformKind.APPEND_BEFORE) {
    return `appendBefore`;
  }
  if (kind === HtmlTransformKind.APPEND_AFTER) {
    return `appendAfter`;
  }

  return ``;
}

export function toTransformKind(kind: AllMutationKind): HtmlTransformKind {
  if (kind === `replace`) {
    return HtmlTransformKind.REPLACE;
  }
  if (kind === `appendBefore`) {
    return HtmlTransformKind.APPEND_BEFORE;
  }
  if (kind === `appendAfter`) {
    return HtmlTransformKind.APPEND_AFTER;
  }

  return HtmlTransformKind.UNKNOWN;
}

export const createNewElementMessageFromOrigChange = (
  origChange: EditorDeclarativeBlock,
) => {
  const msg: NewElementMessage = {
    action: `create`,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    kind: origChange.block?.value?.htmlKind || origChange.block.kind,
    selector: origChange.block.selector,
  };
  return msg;
};

const plusIcon = `<svg
      width="46px"
      height="46px"
      viewBox="0 0 48 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter
          x="-111.1%"
          y="-111.1%"
          width="322.2%"
          height="322.2%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.417584613   0 0 0 0 0   0 0 0 0 1  0 0 0 0.397991047 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Add-Visual---hover-B"
          transform="translate(-425.000000, -340.000000)"
          fill="#FFFFFF"
        >
          <g
            id="Group-6"
            filter="url(#filter-1)"
            transform="translate(440.000000, 355.000000)"
          >
            <rect id="Rectangle" x="7" y="0" width="4" height="18" rx="2" />
            <rect
              id="Rectangle-Copy-2"
              transform="translate(9.000000, 9.000000) rotate(-270.000000) translate(-9.000000, -9.000000) "
              x="7"
              y="1.8189894e-12"
              width="4"
              height="18"
              rx="2"
            />
          </g>
        </g>
      </g>
    </svg>`;

const checkmarkIcon = `<svg class="__loomi_element_placeholder_icon" width="48px" height="48px" viewBox="0 0 52 53" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
          <filter x="-91.2%" y="-91.2%" width="282.5%" height="282.5%" filterUnits="objectBoundingBox" id="filter-1">
              <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
              <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
              <feColorMatrix values="0 0 0 0 0.417584613   0 0 0 0 0   0 0 0 0 1  0 0 0 0.397991047 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
              <feMerge>
                  <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                  <feMergeNode in="SourceGraphic"></feMergeNode>
              </feMerge>
          </filter>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Add-Visual---A-Copy" transform="translate(-426.000000, -327.000000)" fill="#FFFFFF">
              <g id="Group-6" filter="url(#filter-1)" transform="translate(441.039845, 342.539845)">
                  <path d="M14.9601551,1.46015511 C16.0647246,1.46015511 16.9601551,2.35558561 16.9601551,3.46015511 L16.9601551,17.4601551 C16.9601551,17.633173 16.9381852,17.8010596 16.896883,17.9611773 C16.9382769,18.1199516 16.9601551,18.2874984 16.9601551,18.4601551 C16.9601551,19.5647246 16.0647246,20.4601551 14.9601551,20.4601551 L6.96015511,20.4601551 C5.85558561,20.4601551 4.96015511,19.5647246 4.96015511,18.4601551 C4.96015511,17.3555856 5.85558561,16.4601551 6.96015511,16.4601551 L12.9601551,16.4601551 L12.9601551,3.46015511 C12.9601551,2.35558561 13.8555856,1.46015511 14.9601551,1.46015511 Z" id="Combined-Shape" transform="translate(10.960155, 10.960155) rotate(-315.000000) translate(-10.960155, -10.960155) "></path>
              </g>
          </g>
      </g>
  </svg>`;
