import React from 'react';
import styled from 'styled-components';
import { CloseButton } from '@/components/close-button';
import { BigButton } from '@/components/big-button';
import { ComponentSize } from '@/styles/sizes';

export interface ConfirmModalProps {
  title: string | JSX.Element;
  description: string | JSX.Element;
  yesCaption?: string;
  noCaption?: string;
  neutral?: boolean;

  onConfirm?: () => void;
  onDiscard?: () => void;
  onClose?: () => void;
  onlyDismiss?: boolean;
  btnSize?: ComponentSize;
  titleH?: string;
}

export function ConfirmModal({
  title,
  description,
  yesCaption,
  noCaption,
  onConfirm,
  onDiscard,
  onClose,
  onlyDismiss,
  btnSize,
  neutral,
  titleH,
}: ConfirmModalProps) {
  const yes = yesCaption || `Yes`;
  const no = noCaption || `No`;

  return (
    <Wrapper titleH={titleH}>
      <StyledClose onClick={() => (onClose ? onClose() : onDiscard())} />
      <Title>{title}</Title>
      <Desc>{description}</Desc>
      <Footer onlyDismiss={onlyDismiss}>
        <BigButton
          onClick={onConfirm}
          border={neutral ? `2px solid #C8CACB` : `2px solid black`}
          background={neutral ? `white` : undefined}
          color={neutral ? `#97A0A8` : undefined}
          noShadow={neutral}
          noTransform
          size={btnSize || `large`}
          fillWidth
        >
          {yes}
        </BigButton>
        {!onlyDismiss && (
          <BigButton
            onClick={onDiscard}
            border="2px solid #C8CACB"
            background="white"
            color="#97A0A8"
            noShadow
            noTransform
            size={btnSize || `large`}
            fillWidth
          >
            {no}
          </BigButton>
        )}
      </Footer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${(p) => p.titleH || `2rem`} 1fr 5rem;
  grid-row-gap: 2.5rem;
  color: black;
  padding: 2rem;

  font-family: Inter, serif;
  text-align: center;
`;

const Title = styled.span`
  font-size: 2rem;
  font-weight: bold;
`;

const Desc = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #aaaaaa;
`;

const Footer = styled.div`
  display: grid;
  grid-template-columns: ${(p: { onlyDismiss }) =>
    p.onlyDismiss ? `1fr` : `1fr 1fr`};
  grid-column-gap: 2rem;
  button {
    font-size: 1.4rem !important;
  }
`;

const StyledClose = styled(CloseButton)`
  && {
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;
    justify-self: end;

    .gatsby-image-wrapper {
      height: 1rem;
      width: 1rem;
    }
  }
`;
