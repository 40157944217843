import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import { BsArrowClockwise, BsPause, BsPlay } from 'react-icons/bs';
import { FakeClickContext } from '@/features/editor/widgets/fake-click/context';
import { FakeClickStep } from '@/features/editor/widgets/fake-click/automation-step';
import { FakeClickCta } from '@/features/editor/widgets/fake-click/shared';
import { SharedElementOverlay } from '@/components/shared-element-overlay';
import { ConfirmModal } from '@/components/confirm-modal';
import { centered, useSharedElement } from '@/components/use-shared-element';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import resumeImage from '../../../../assets/fake_click_resume.svg';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';

export function FakeClickNonEmptyState() {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);
  const { steps, pause, currentState, restart, record, play } =
    useContext(FakeClickContext);
  const fromRef = useRef(null);

  const { props, show, hide } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `#a6afb8`,
        opacity: `0`,
      },
      extraTo: {
        background: `white`,
        opacity: `1`,
      },
    },
    fromRef,
    () => centered(21, 42),
  );

  const onRestart = () => {
    restart();
    hide();
  };

  return (
    <Wrapper>
      <SharedElementOverlay {...props}>
        <ConfirmModal
          title="Are you sure?"
          description="Clicking on yes will discard previously recorded interactions."
          onConfirm={onRestart}
          onClose={hide}
          onDiscard={hide}
        />
      </SharedElementOverlay>
      <Steps>
        {steps?.map((step) => (
          <FakeClickStep key={`${step.selector}-${step.kind}`} step={step} />
        ))}
      </Steps>
      {currentState === `recording` && (
        <>
          <PauseButton animate onClick={pause}>
            <BsPause size={35} />
          </PauseButton>
          <span>CLICK ON THE STOP BUTTON ONCE YOU FINISHED</span>
        </>
      )}
      {currentState === `paused` && (
        <ControlWrapper ref={fromRef}>
          <ControlButton device={device}>
            <FakeClickCta onClick={show}>
              <BsArrowClockwise size={18} />
              <span>Restart</span>
            </FakeClickCta>
          </ControlButton>
          <ControlButton device={device}>
            <FakeClickCta onClick={record}>
              <img alt="resume" src={resumeImage} />
              <span>Resume</span>
            </FakeClickCta>
          </ControlButton>
          <ControlButton device={device}>
            <FakeClickCta onClick={play}>
              <BsPlay size={20} />
              <span>Play</span>
            </FakeClickCta>
          </ControlButton>
        </ControlWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
  justify-items: center;
  justify-content: center;
  align-items: center;

  && > span {
    font-weight: bold;
    color: #a6afb8;
  }
`;

const ControlWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
`;

const ControlButton = styled.div`
  && > * {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: min-content min-content;
    justify-content: center;
    align-items: center;
    width: ${(p: any) =>
      p.device === DeviceType.Desktop ? `11rem` : `12rem`}!important;
    font-size: ${(p: any) =>
      p.device === DeviceType.Desktop ? `1.3rem` : `1.4rem`}!important;
    font-weight: bold !important;
    border-radius: 2rem !important;
    padding: 0.9rem 0 !important;

    margin: 2rem 0;

    background-color: #a6afb8 !important;
    color: white !important;

    img {
      width: ${(p: any) =>
        p.device === DeviceType.Desktop ? `13px` : `15px`}!important;
      margin: 0.3rem 0;
    }
  }
`;

const Steps = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.3rem;
  width: 100%;
  height: auto;
`;

const PauseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  outline: none;
  border: none;
  appearance: none;

  color: white;
  background-color: #edbaca;
  border-radius: 50%;
  padding: 1rem 1rem;

  box-shadow: 0 0 0 0 rgba(237, 186, 202, 1);
  transform: scale(1);
  animation: ${(p: any) => (p.animate ? `pulse 3s infinite` : `none`)};

  :hover {
    opacity: 0.8;
    cursor: pointer;
  }
  :active {
    opacity: 0.6;
    transform: scale(0.95);
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(237, 186, 202, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 2rem rgba(237, 186, 202, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(237, 186, 202, 0);
    }
  }
`;
