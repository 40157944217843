import { useContext } from 'react';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';

export function useIsPreviewingDesktop() {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);

  return device === DeviceType.Desktop;
}
