import React from 'react';
import styled from 'styled-components';
import { CloseButton } from '@/components/close-button';
import { DeviceType } from '@/utils/definitions';
import { BigButton } from '@/components/big-button';
import {
  newQuickPreviewNavigationContext,
  QuickPreviewNavigationContext,
} from '@/features/editor/preview-navigation/context';
import { QuickPreviewNavigationList } from '@/features/editor/preview-navigation/quick-preview-navigation-list';

export function NavigationOverlay({ close }: { close: () => void }) {
  const ctx = newQuickPreviewNavigationContext(close);
  const { onSave } = ctx;

  return (
    <QuickPreviewNavigationContext.Provider value={ctx}>
      <OverlayWrapper>
        <OverlayHeader>
          <span>Select page for quick preview</span>
          <CloseButton device={DeviceType.Desktop} onClick={close} />
        </OverlayHeader>
        <QuickPreviewNavigationList />
        <OverlayFooter>
          <BigButton
            onClick={onSave}
            size="large"
            fillWidth
            background="#0089FF"
            color="#FFFFFF"
            noShadow
          >
            Save
          </BigButton>
        </OverlayFooter>
      </OverlayWrapper>
    </QuickPreviewNavigationContext.Provider>
  );
}

const OverlayWrapper = styled.div`
  text-transform: initial;
  max-height: 100%;
  height: 100%;
  width: 100%;

  padding: 0.7rem 2rem;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5rem 1fr 5.5rem;
  grid-row-gap: 2rem;

  font-family: Inter, serif;
  font-size: 1.4rem;
`;

const OverlayHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-size: 1.5rem;
  color: black;
  font-weight: bold;

  border-bottom: 1px solid #e5e7e8;

  button {
    transform: scale(0.75, 0.75);
  }
`;

const OverlayFooter = styled.div`
  button {
    text-transform: none;
  }
`;
