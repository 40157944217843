import produce from 'immer';
import { draftExperience, Experience } from '@/webapi/use-experience-api';
import { EDITOR_EXPERIENCE_CACHE } from '@/components/hooks/use-cached-auto-save';
import { CONTROL } from '@/features/details/shared';

export async function getOrCreateExperience(
  expId: string,
  getExperience: (id: string) => Promise<{ experience: Experience }>,
  upsertExperience: (exp: Experience) => Promise<Experience>,
  isPostPurchase: boolean,
) {
  let experience: Experience;
  if (expId) {
    experience =
      (await EDITOR_EXPERIENCE_CACHE.get(expId)) ||
      (await getExperience(expId)).experience;
  } else {
    const ex = draftExperience(isPostPurchase);
    experience = await upsertExperience(ex); // for some reason when this api is called from here, the response does not contain targeting. WTF...
    if (experience) {
      experience.targeting = ex?.targeting;
    }
  }
  return produce(experience, (draft) => {
    if (draft)
      draft.variants =
        experience?.variants?.filter((v) => v.name !== CONTROL) || [];
  });
}
