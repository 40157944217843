import React, { useRef } from 'react';
import styled from 'styled-components';
import { RichTextEditor } from '@/features/editor/widgets/custom-widget/inputs/typography/rich-text';
import { DeviceType } from '@/utils/definitions';
import { HistoryOpts } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';

export interface RichTextDescriptionProps {
  defaultValue: string;
  onChange: (value: string) => void;
}

export function RichTextDescription({
  defaultValue,
  onChange,
}: RichTextDescriptionProps) {
  const ref = useRef(null);

  const onValuesChanged = (
    _key: string,
    _value: any,
    _device?: DeviceType,
    _historyOpts?: HistoryOpts,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) => {};

  return (
    <StyledRichTextEditor>
      <RichTextEditor
        defaultValue={defaultValue || ``}
        onChange={onChange}
        ref={ref}
        spec={{
          noAi: true,
          values: {
            value: defaultValue || ``,
          },
        }}
        handleAiAltText={() => {
          // eslint-disable-next-line no-console
          console.debug(`not implemented`);
        }}
        loading={false}
        device={DeviceType.Mobile}
        customization={undefined}
        onValuesChanged={onValuesChanged}
      />
    </StyledRichTextEditor>
  );
}

const StyledRichTextEditor = styled.div`
  #toolbar-header-0 {
    padding: 0 !important;

    * {
      font-size: 1.2rem;
    }

    div[class*='ButtonsWrapper'] .content {
      display: none;
    }

    div[class*='LineHeightWrapper'] {
      height: 100%;
    }

    div[class*='InputWrapper'] {
      height: 4rem;

      div[class*='number-input'] {
        box-shadow: none;
        padding: 0 0.8rem 0 0;
        height: 100%;
        justify-items: center;
      }
    }
  }

  .ql-snow.ql-toolbar:after,
  .ql-snow .ql-toolbar:after {
    margin-bottom: 1rem;
  }

  .ql-snow .ql-picker:not(.ql-align) {
    box-shadow: none;
  }

  .ql-container {
    margin-top: 1rem;
    min-height: 16rem;
    max-height: 16rem;
    overflow-y: scroll;
  }
`;
