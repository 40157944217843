import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { BackgroundColorContext } from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/context';
import getRgbByHue, {
  changePicker,
} from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/utils';
import useMouseEvents from '../shared/use-mouse-events';

export function ColorSaturationPicker() {
  const { solidColor, updateSolidColor } = useContext(BackgroundColorContext);
  const pickingAreaRef = useRef<HTMLElement>(null);
  const pickerRef = useRef<HTMLElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const mouseDownHandler = useCallback(
    (event) => {
      const elementX = event.currentTarget.getBoundingClientRect().x;
      const elementY = event.currentTarget.getBoundingClientRect().y;
      const startX = event.pageX;
      const startY = event.pageY;
      const positionX = startX - elementX;
      const positionY = startY - elementY;

      const color = changePicker(
        positionX,
        positionY,
        height,
        width,
        solidColor.hue,
      );

      updateSolidColor(color, true);
      return {
        startX,
        startY,
        positionX,
        positionY,
      };
    },
    [height, width, solidColor?.hue, updateSolidColor],
  );

  const changeObjectPositions = useCallback(
    (event, { startX, startY, positionX, positionY }) => {
      const moveX = event.pageX - startX;
      const moveY = event.pageY - startY;
      positionX += moveX;
      positionY += moveY;

      const color = changePicker(
        positionX,
        positionY,
        height,
        width,
        solidColor.hue,
      );

      return {
        positions: {
          positionX,
          positionY,
          startX: event.pageX,
          startY: event.pageY,
        },
        color,
      };
    },
    [height, solidColor?.hue, width],
  );

  const mouseMoveHandler = useCallback(
    (event, { startX, startY, positionX, positionY }) => {
      const { positions, color } = changeObjectPositions(event, {
        startX,
        startY,
        positionX,
        positionY,
      });

      updateSolidColor(color, true);

      return positions;
    },
    [updateSolidColor, changeObjectPositions],
  );

  const mouseUpHandler = useCallback(
    (event, { startX, startY, positionX, positionY }) => {
      const { positions, color } = changeObjectPositions(event, {
        startX,
        startY,
        positionX,
        positionY,
      });

      updateSolidColor(color, true);

      return positions;
    },
    [updateSolidColor, changeObjectPositions],
  );

  const mouseEvents = useMouseEvents(
    mouseDownHandler,
    mouseMoveHandler,
    mouseUpHandler,
  );

  useEffect(() => {
    if (pickingAreaRef.current) {
      setWidth(pickingAreaRef.current.clientWidth);
      setHeight(pickingAreaRef.current.clientHeight);
    }
  }, [pickingAreaRef, setWidth, setHeight]);

  useEffect(() => {
    const { red, green, blue } = getRgbByHue(solidColor.hue);
    if (pickingAreaRef.current)
      pickingAreaRef.current.style.backgroundColor = `rgb(${red}, ${green}, ${blue})`;
  }, [solidColor?.hue]);

  useEffect(() => {
    // eslint-disable-next-line no-bitwise
    const offsetLeft = (((solidColor.saturation * width) / 100) | 0) - 6;
    // eslint-disable-next-line no-bitwise
    const offsetTop = ((height - (solidColor.value * height) / 100) | 0) - 6;

    if (pickerRef.current) {
      pickerRef.current.style.backgroundColor = `rgb(${solidColor.red}, ${solidColor.green}, ${solidColor.blue})`;
      pickerRef.current.style.left = `${offsetLeft}px`;
      pickerRef.current.style.top = `${offsetTop}px`;
    }
  }, [solidColor, height, width, pickerRef]);

  const onMouseDown = (ev) => {
    mouseEvents(ev);
  };

  return (
    <Wrapper ref={pickingAreaRef} onMouseDown={onMouseDown}>
      <Overlay1>
        <Overlay2>
          <Picker ref={pickerRef} />
        </Overlay2>
      </Overlay1>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
  position: relative;
  border-radius: 8px;

  &:hover {
    cursor: default;
  }
`;

const Overlay1 = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 3px;
`;

const Overlay2 = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
  border-radius: 8px;
`;

const Picker = styled.div`
  position: absolute;
  top: 1px;
  height: 1.8rem;
  width: 1.8rem;
  border: 4px solid #ffffff;
  background: transparent;
  border-radius: 50%;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
`;
