import { useContext } from 'react';
import { EditorContext } from '@/features/editor/context/editor-context';
import {
  EditorChangeKind,
  EditorDeclarativeBlock,
  toTargetingDeviceType,
} from '@/webapi/use-experience-api';
import { sendMessageToIframe } from '@/utils/browser';
import { ElementMessage } from '@/features/editor/context/use-device-preview';

export function useCodeEditorLoader() {
  const {
    experienceState,
    inspectorNav,
    transpiler,
    devicePreview: {
      iframeRef,
      editorState: { device },
    },
  } = useContext(EditorContext);

  const { findEditorChange } = experienceState;
  const { gotoCompoundEditor, gotoMoveElement } = inspectorNav;
  const { asSingleValueBlock, globalCssId, globalJsId } = transpiler;

  const gotoGlobalCssEditor = (
    expId: string,
    origChange?: EditorDeclarativeBlock,
  ) => {
    let change: EditorDeclarativeBlock;
    if (origChange?.editorId) {
      change = findEditorChange(origChange?.editorId);
    } else {
      const legacyEditorId = globalCssId(expId);
      const editorId = globalCssId(expId, device);
      change = findEditorChange(editorId) || findEditorChange(legacyEditorId);

      if (!change || change.targetDevice !== toTargetingDeviceType(device)) {
        change = asSingleValueBlock(
          EditorChangeKind.GLOBAL_CSS,
          editorId,
          `appendCss`,
          ``,
          `div`,
        );
      }
    }

    gotoCompoundEditor(change, false);
  };

  const gotoGlobalJsEditor = (
    expId: string,
    origChange?: EditorDeclarativeBlock,
  ) => {
    let change: EditorDeclarativeBlock;
    if (origChange?.editorId) {
      change = findEditorChange(origChange?.editorId);
    } else {
      const legacyEditorId = globalJsId(expId);
      const editorId = globalJsId(expId, device);
      change = findEditorChange(editorId) || findEditorChange(legacyEditorId);

      if (!change || change.targetDevice !== toTargetingDeviceType(device)) {
        change = asSingleValueBlock(
          EditorChangeKind.GLOBAL_JS,
          editorId,
          `appendJs`,
          ``,
          `div`,
        );
      }
    }

    gotoCompoundEditor(change, false);
  };

  const gotoNewElementEditor = (editorId: string) => {
    const change = findEditorChange(editorId);
    gotoCompoundEditor(change, true);
  };

  const gotoSingleValueEditor = (editorId: string) => {
    const change = findEditorChange(editorId);
    if (change) {
      gotoCompoundEditor(change, false);
    }
  };

  const gotoVisualEditor = (change: EditorDeclarativeBlock) => {
    const msg: ElementMessage = {
      action: `getVisualEdit`,
      selector: change.block.selector,
    };
    sendMessageToIframe(msg, iframeRef);
  };

  return {
    gotoMoveElement,
    gotoGlobalCssEditor,
    gotoGlobalJsEditor,
    gotoNewElementEditor,
    gotoSingleValueEditor,
    gotoCompoundEditor,
    gotoVisualEditor,
  };
}
