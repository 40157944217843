import React, { FC, useState } from 'react';
import { centered } from '@/components/use-shared-element';
import { QueryBuilder } from '@/components/query-builder';
import { QBItemSelection } from '@/components/query-builder/models';
import { useConditionalQueryBuilder } from '@/features/editor/widgets/custom-widget/loading-section/conditional-product/useConditionalQueryBuilder';
import { RecommendationType } from '@/webapi/use-widget-catalog-api';
import { GradientModal } from '@/components/gradient-modal';
import { Footer } from '@/features/editor/widgets/shared/modals/commons';

interface ConditionalProductsPicker {
  fromRef: any;
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  initialState?: QBItemSelection[];
  strategy: RecommendationType;
  updateRecommendationCondition: (options: Array<QBItemSelection>) => void;
}

export const AdvancedEditing: FC<ConditionalProductsPicker> = ({
  fromRef,
  isVisible,
  setIsVisible,
  initialState,
  strategy,
  updateRecommendationCondition,
}: ConditionalProductsPicker) => {
  const [currentCondition, setCurrentCondition] = useState<QBItemSelection[]>(
    [],
  );

  const onQueryBuilderChange = (value: QBItemSelection[]) => {
    setCurrentCondition(value);
  };

  const { condition } = useConditionalQueryBuilder(strategy);

  const onSave = () => {
    updateRecommendationCondition(currentCondition);
    setIsVisible(false);
  };

  return (
    <GradientModal
      closeTop="0.5rem"
      closeRight="2.5rem"
      fromStyle={{
        opacity: 0,
        borderRadius: `5rem`,
        backgroundColor: `#DEDEDE`,
        padding: `2rem 0 0 0`,
      }}
      toStyle={{
        background: `white`,
        opacity: 1,
        borderRadius: `1rem`,
        backgroundColor: `#FFFFFF`,
        padding: `2rem 0 0 0`,
      }}
      isVisible={isVisible}
      showBackdrop
      targetPosAndSize={centered(60, 90)}
      fromRef={fromRef}
      overflowTop={{ heightInRem: 8 }}
      overflowBottom={{ heightInRem: 8, turnPointInPercent: 30 }}
      onClose={() => setIsVisible(false)}
      header="More Filters"
      footer={<Footer onClick={onSave} />}
    >
      <QueryBuilder
        onChange={onQueryBuilderChange}
        initialProps={condition}
        initialState={initialState}
      />
    </GradientModal>
  );
};
