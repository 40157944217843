/* eslint-disable no-plusplus */
import {
  AdvancedRulingOption,
  ManualProduct,
} from '@/webapi/use-widget-catalog-api';
import { QBItemSelection } from '@/components/query-builder/models';
import {
  Action,
  DeleteRuleAction,
  ToggleConditionAction,
  ToggleImageSelectModeAction,
  ToggleSearchModeAction,
  UpdateConditionAction,
  UpdateProductsAction,
} from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/context/actions';

export interface Rule {
  id: number;
  itemSelection: 'some_items' | 'all_items';
  isOpen: boolean;
  manualProducts?: Array<ManualProduct>;
  condition?: Array<QBItemSelection>;
}

export interface State {
  searchMode?: boolean;
  imageSelectMode?: boolean;
  rules: Array<Rule>;
}

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case `UPDATE_CONDITION`:
      return updateRuleCond(action as UpdateConditionAction, state);
    case `ADD_RULE`:
      return addARule(state);
    case `TOGGLE_OPEN`:
      return toggleRule(action as ToggleConditionAction, state);
    case `UPDATE_PRODUCTS`:
      return updateProducts(action as UpdateProductsAction, state);
    case `TOGGLE_SEARCH_MODE`:
      return toggleSearchMode(state, action as ToggleSearchModeAction);
    case `TOGGLE_IMAGE_SELECT_MODE`:
      return toggleImageSelectMode(
        state,
        action as ToggleImageSelectModeAction,
      );
    case `DELETE_RULE`:
      return deleteRule(state, action as DeleteRuleAction);
    case `SAVE`:
      return { ...state };
    default:
      return state;
  }
};

function updateProducts(update: UpdateProductsAction, state: State) {
  const nextState = clone(state);
  for (let i = 0; i < nextState.rules.length; i++) {
    if (nextState.rules[i].isOpen) {
      nextState.rules[i].manualProducts = update.products;
    }
  }
  return nextState;
}

function updateRuleCond(action: UpdateConditionAction, state: State) {
  const update = action as UpdateConditionAction;
  const nextState = clone(state);
  if (state?.rules?.length === 0) {
    const rule = newRule(nextState, update);
    nextState.rules.push(rule);
  } else {
    updateExisting(nextState, update);
  }
  return nextState;
}

function toggleRule(action: ToggleConditionAction, state: State) {
  const update = action as ToggleConditionAction;
  const nextState = clone(state);
  toggleOpen(nextState, update.id);
  return nextState;
}

export function transform(state: State): Array<AdvancedRulingOption> {
  return state?.rules?.map(
    (r) =>
      ({
        manualProducts: r.manualProducts,
        condition: r.condition,
        num: r.id,
        itemSelection: r.itemSelection,
      } as AdvancedRulingOption),
  );
}

function newRule(nextState: State, update: UpdateConditionAction) {
  return {
    id: getNewId(nextState),
    isOpen: false,
    itemSelection: update.itemSelection,
    condition: update.condition,
  } as Rule;
}

function updateExisting(nextState: State, update: UpdateConditionAction) {
  for (let i = 0; i < nextState.rules.length; i++) {
    if (nextState.rules[i].id === update.id) {
      nextState.rules[i].condition = update.condition;
      nextState.rules[i].itemSelection = update.itemSelection;
    }
  }
}

function clone(state: State) {
  return JSON.parse(JSON.stringify(state)) as State;
}

function toggleOpen(nextState: State, ruleId: number) {
  for (let i = 0; i < nextState.rules.length; i++) {
    if (nextState.rules[i].id === ruleId) {
      nextState.rules[i].isOpen = !nextState.rules[i].isOpen;
    } else {
      nextState.rules[i].isOpen = false;
    }
  }
}

function addARule(state: State) {
  const newId = getNewId(state);
  const nextState = {
    ...state,
    rules: [
      ...state.rules,
      {
        id: newId,
        isOpen: false,
      } as Rule,
    ],
  } as State;
  toggleOpen(nextState, newId);
  return nextState;
}

function toggleSearchMode(state: State, action: ToggleSearchModeAction) {
  return {
    ...state,
    searchMode: action.isActive,
  };
}

function toggleImageSelectMode(
  state: State,
  action: ToggleImageSelectModeAction,
) {
  return {
    ...state,
    imageSelectMode: (action as ToggleImageSelectModeAction).isActive,
  };
}

function getNewId(state: State) {
  const maxId =
    state.rules?.map((x) => x.id)?.reduce((p, c) => (p > c ? p : c), 0) || 0;
  return maxId + 1;
}

function deleteRule(state: State, action: DeleteRuleAction) {
  const nextState = clone(state);
  nextState.rules = nextState.rules.filter((r) => r.id !== action.id);
  return nextState;
}
