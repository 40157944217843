import * as React from 'react';
import styled from 'styled-components';
import { useEffect, useMemo } from 'react';
import {
  Alignment,
  Color,
  Size,
  TextDecoration,
  TextualProps,
} from '@/features/editor/widgets/post-purchase/props';
import { useComplexState } from '@/utils/use-complex-state';
import { RadioButtons } from '@/features/editor/widgets/custom-widget/inputs/shared/radio-buttons';

export interface PostPurchaseTextualInputProps {
  defaultValue: TextualProps;
  onChange?: (props: TextualProps) => void;
  showAlignment?: boolean;
}

export const POST_PURCHASE_SIZE_OPTIONS = [
  { label: `Small`, value: `small` },
  { label: `Medium`, value: `medium` },
  { label: `Large`, value: `large` },
  { label: `Huge`, value: `xlarge` },
];

export const POST_PURCHASE_COLOR_OPTIONS = [
  { label: `Default`, value: `default` },
  { label: `Light`, value: `light` },
  { label: `Critical`, value: `critical` },
  { label: `Warning`, value: `warning` },
  { label: `Success`, value: `success` },
];

export const POST_PURCHASE_DECORATION_OPTIONS = [
  { label: `None`, value: `none` },
  { label: `Bold`, value: `bold` },
  { label: `Line Through`, value: `line-through` },
  { label: `Bold & Line Through`, value: `all` },
];

export const POST_PURCHASE_TEXT_ALIGN_OPTIONS = [
  { label: `Start`, value: `leading` },
  { label: `Center`, value: `center` },
  { label: `End`, value: `trailing` },
];

export function PostPurchaseTextualInput({
  defaultValue,
  onChange,
  showAlignment,
}: PostPurchaseTextualInputProps) {
  const [props, setProps] = useComplexState(defaultValue);
  useEffect(() => {
    onChange && onChange(props);
  }, [props]);

  const onSizeChanged = (v: Size) =>
    setProps((draft) => {
      draft.size = v;
    });

  const onColorChanged = (v: Color) =>
    setProps((draft) => {
      draft.color = v;
    });

  const onAlignmentChanged = (v: Alignment) =>
    setProps((draft) => {
      draft.alignment = v;
    });

  const decorationDefaultValue = useMemo<TextDecoration>(() => {
    const bold = props?.bold === true;
    const lineThrough = props?.lineThrough === true;

    if (bold && lineThrough) return `all`;
    if (!bold && lineThrough) return `line-through`;
    if (bold && !lineThrough) return `bold`;
    return `none`;
  }, [props]);

  const onDecorationChanged = (v: TextDecoration) =>
    setProps((draft) => {
      if (v === `none`) {
        draft.bold = false;
        draft.lineThrough = false;
      }
      if (v === `bold`) {
        draft.bold = true;
        draft.lineThrough = false;
      }
      if (v === `line-through`) {
        draft.bold = false;
        draft.lineThrough = true;
      }
      if (v === `all`) {
        draft.bold = true;
        draft.lineThrough = true;
      }
    });

  return (
    <Wrapper>
      <RadioButtons
        title="Text Size"
        smallTitle
        defaultValue={props?.size}
        onChange={onSizeChanged}
        options={POST_PURCHASE_SIZE_OPTIONS}
      />
      {showAlignment === true && (
        <RadioButtons
          title="Text Alignment"
          smallTitle
          defaultValue={props?.alignment || `leading`}
          onChange={onAlignmentChanged}
          options={POST_PURCHASE_TEXT_ALIGN_OPTIONS}
        />
      )}
      <RadioButtons
        title="Text Color"
        smallTitle
        defaultValue={props?.color}
        onChange={onColorChanged}
        options={POST_PURCHASE_COLOR_OPTIONS}
      />
      <RadioButtons
        title="Text Decoration"
        smallTitle
        defaultValue={decorationDefaultValue}
        onChange={onDecorationChanged}
        options={POST_PURCHASE_DECORATION_OPTIONS}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  max-width: 100%;
  grid-gap: 1.5rem;
  align-items: center;
  overflow: hidden;
`;

export const TextualColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
`;
