import styled, { createGlobalStyle } from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Spinner } from '@/Spinner';

export function EditIcon() {
  return (
    <StyledIcon>
      <StaticImage
        src="../../../../../../assets/pencil.svg"
        alt="edit"
        placeholder="none"
        loading="eager"
        height={15}
      />
    </StyledIcon>
  );
}

const StyledIcon = styled.div`
  opacity: 0.3;
  display: flex;
  justify-content: flex-end;

  .gatsby_image_wrapper {
    height: 15px;
    width: 15px;

    img {
      object-fit: contain;
    }
  }
`;

const genTextAltWithAiAlt = `genTextAltWithAiAlt`;

interface Props {
  onClick: () => void;
  loading: boolean;
}
export function AltTextTooltip({ loading, onClick }: Props) {
  const [clicks, setClicks] = useState(0);
  if (clicks > 7) {
    return <EditIcon />;
  }
  const handleClick = () => {
    if (loading) return;
    setClicks(clicks + 1);
    onClick();
  };
  return (
    <>
      <TruncatedTextTooltipWrapper />
      <button
        type="button"
        onClick={handleClick}
        data-tip
        data-for={genTextAltWithAiAlt}
      >
        {loading ? (
          <SpinnerWrapper>
            <Spinner size={16} mt="0" border={1} />
          </SpinnerWrapper>
        ) : (
          <AIIcon />
        )}
      </button>
      {/* @ts-ignore */}
      <ReactTooltip
        id={genTextAltWithAiAlt}
        place="top"
        backgroundColor="#fff"
        textColor="#000"
        className="truncate-tooltip"
      >
        <Hint>
          Click to change to <br />
          alternative text
        </Hint>
      </ReactTooltip>
    </>
  );
}

const Hint = styled.span`
  text-align: center;
`;

export function AIIcon() {
  return (
    <AIIconWrapper>
      <StaticImage
        src="../../../../../../assets/ai.svg"
        alt="edit with ai"
        placeholder="none"
        loading="eager"
        height={16}
      />
    </AIIconWrapper>
  );
}

const SpinnerWrapper = styled.div`
  padding: 5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.15),
    0 4px 11px 0 rgba(151, 210, 206, 0.22);
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
`;

const AIIconWrapper = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.15),
    0 4px 11px 0 rgba(151, 210, 206, 0.22);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  &:active {
    cursor: default;
    opacity: 1;
  }

  .gatsby_image_wrapper {
    height: 20px;
    width: 20px;

    img {
      object-fit: contain;
    }
  }
`;

const TruncatedTextTooltipWrapper = createGlobalStyle`
  .truncate-tooltip {
    box-shadow: 0 4px 5px 0 rgba(105, 122, 231, 0.11),
    0 12px 16px 0 rgba(196, 217, 210, 0.38), 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    border-radius: 1rem!important;
    font-family: "JetBrains Mono",serif;
  }
`;
