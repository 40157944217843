import React, { FC } from 'react';
import styled from 'styled-components';
import { Product } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/context/model';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(
    ${(props) => props.columns},
    minmax(100px, 1fr)
  );
  grid-gap: 2em;
  overflow: scroll;
`;

const ProductImage = styled.div`
  border-radius: 22px;
  width: 100px;
  height: 100px;
`;

const ProductWrapper = styled.button`
  display: flex;
  border: none;
  background: none;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  &:active {
    opacity: 1;
    cursor: default;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;

  p:first-child {
    font-weight: 600;
    max-width: 100px;
    max-height: 60px;
    font-size: 1.2rem;
    text-overflow: ellipsis;
  }

  p {
    text-align: center;
    font-size: 1.2rem;
  }
`;

export const ProductsGrid: FC<{
  products: Array<Product>;
  onSelected?: (p: Product) => void;
  columnsCount?: number;
}> = ({ products, onSelected, columnsCount = 3 }) => {
  if (products?.length === 0) {
    return null;
  }
  return (
    <Container columns={columnsCount}>
      {products.map((x) => (
        <ProductWrapper key={x.id} onClick={() => onSelected && onSelected(x)}>
          <ProductImage>
            <img src={getSrc(x)} alt={x.title} />
          </ProductImage>
          <Details>
            <p>{x.title}</p>
            <p>${x.price}</p>
          </Details>
        </ProductWrapper>
      ))}
    </Container>
  );
};

export function getSrc(x: Product) {
  if (!x?.image?.src) {
    return ``;
  }
  try {
    const url = new URL(x.image.src);
    const lastDotIndex = url.pathname.lastIndexOf(`.`);
    const suffix = url.pathname.slice(lastDotIndex + 1);
    const base = url.pathname.slice(0, lastDotIndex);
    const r = (Math.random() + 1).toString(36).substring(7);
    return `https://${url.host}${base}_small.${suffix}?v=${r}`;
  } catch (e) {
    console.error(`vsly`, `failed parsing image src`, e);
    return x.image.src;
  }
}
