import { useContext } from 'react';
import * as React from 'react';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';
import { DropdownInputOption } from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { RadioButtons } from '@/features/editor/widgets/custom-widget/inputs/shared/radio-buttons';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';

export const POST_PURCHASE_HEADER_OPTIONS: DropdownInputOption[] = [
  {
    label: `Last Chance Discount!`,
    value: `Last Chance Discount!`,
  },
  {
    label: `Hurry Up!`,
    value: `Hurry Up!`,
  },
  {
    label: `Take the Deal`,
    value: `Take the Deal`,
  },
  {
    label: `Last Chance!`,
    value: `Last Chance!`,
  },
];

export function PostPurchaseHeaderInput() {
  const { props, setProps } = useContext(PostPurchaseContext);
  const allowCustomEdit = useFeatureBit(
    FeatureBit.ENABLE_POST_PURCHASE_EXPERIMENTAL_OPTIONS,
  );

  const onChange = (val: string) => {
    setProps((draft) => {
      draft.content.header = val;
    });
  };

  return (
    <RadioButtons
      title="Header message"
      defaultValue={props.content.header}
      onChange={onChange}
      options={POST_PURCHASE_HEADER_OPTIONS}
      allowCustomEdit={allowCustomEdit}
      customEditHint="* you can use $product, $discount, $afterDiscountPrice or $beforeDiscountPrice to insert dynamic values"
    />
  );
}
