import React, { useContext, useRef, useState } from 'react';
import Compressor from 'compressorjs';
import styled from 'styled-components';
import useAsyncEffect from 'use-async-effect';
import { BsCheck } from 'react-icons/bs';
import { BiError } from 'react-icons/bi';
import { ImageEditorContext } from '@/features/editor/widgets/custom-widget/inputs/background/image/shared/context';
import { BackModalHeader } from '@/features/editor/widgets/shared/modals/commons';
import { HSpace, VSpace } from '@/components/spacing';
import { CircularCheckmark } from '@/components/circular-checkmark';
import { NumberInput } from '@/features/editor/widgets/custom-widget/inputs/shared/number-input';
import { BigButton } from '@/components/big-button';
import { Flexbox } from '@/components/flex';
import { useDetachedState } from '@/components/hooks/use-detached-state';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';
import { Spinner } from '@/Spinner';

type Props = {
  onReplaceImage: () => any;
  forcePreviewOnSite: () => any;
  onUpdatePreview: (blob: string) => any;
  doUpload: (blob: Blob) => any;
};

export const Compress: React.FC<Props> = ({
  onReplaceImage,
  forcePreviewOnSite,
  onUpdatePreview,
  doUpload,
}) => {
  const { blobToCompress, setShouldCompress, setImage } =
    useContext(ImageEditorContext);

  const [useCompression, setUseCompression] = useState(true);
  const [compressedUrl, setCompressedUrl] = useState(``);
  const compressedBlob = useRef<Blob>();
  const twoMB = 2 * 1024 * 1024;
  const q = Math.round((twoMB / blobToCompress.current.size) * 100);
  const [quality, setQuality] = useState<number>(q);
  const [compressedSize, setCompressedSize] = useState<number>(0);
  const [loading, setLoading] = useDetachedState(false);
  const [init, setInit] = useState(false);
  useAsyncEffect(async () => {
    const compressedSize = async (q: number) =>
      new Promise((resolve, reject) => {
        // eslint-disable-next-line no-new
        new Compressor(blobToCompress.current, {
          quality: q / 100,
          convertSize: 2200000,
          success(result) {
            resolve(result.size / (1024 * 1024));
          },
          error(error: Error) {
            reject(error);
          },
        });
      });
    let q = quality;
    let size = 0;

    // eslint-disable-next-line no-constant-condition
    while (q < 100) {
      // eslint-disable-next-line no-await-in-loop
      size = (await compressedSize(q)) as number;
      if (size < 2) {
        q += 1;
      } else {
        break;
      }
    }
    let prevSize = 0;
    // eslint-disable-next-line no-constant-condition
    while (size > 2 && q > 25) {
      q -= 1;
      // eslint-disable-next-line no-await-in-loop
      size = (await compressedSize(q)) as number;
      if (prevSize === size) {
        // cant compress
        break;
      }
      prevSize = size;
    }
    setQuality(q);
    setInit(true);
  }, []);
  useAsyncEffect(
    (isActive) => {
      if (!init) {
        return;
      }
      setLoading(true);
      // eslint-disable-next-line no-new
      new Compressor(blobToCompress.current, {
        quality: quality / 100,
        convertSize: 2200000,
        success(result) {
          if (isActive) {
            compressedBlob.current = result;
            if (compressedUrl) {
              URL.revokeObjectURL(compressedUrl);
            }
            const url = URL.createObjectURL(result);
            setCompressedUrl(url);
            setCompressedSize(result.size);
          }
          setTimeout(() => setLoading(false), 1000);
        },
        error(err) {
          console.error(`vsly compression error`, err);
          setLoading(false);
        },
      });
    },
    [quality, init],
  );

  const onQualityChange = (ev) => {
    setQuality(Math.max(5, Math.round(parseInt(ev.replace(`%`, ``), 10))));
  };
  const tooLarge = compressedSize / (1024 * 1024) <= 2.2;

  const onConfirm = async () => {
    setLoading(true);
    if (useCompression) {
      await doUpload(compressedBlob.current);
    } else {
      const url = URL.createObjectURL(blobToCompress.current);
      setImage(url);
      onUpdatePreview(url);
      forcePreviewOnSite();
      await doUpload(blobToCompress.current);
    }
    setLoading(false);
    setShouldCompress(false);
  };
  const ex = useContext(EditorContext);
  const isDesk =
    ex?.experienceState?.currentExperience?.quickPreviewDevice ===
    DeviceType.Desktop;
  const smallScreen = window.screen.width <= 1280;
  const isOneCol = isDesk && smallScreen;

  if (!init) {
    return (
      <Container>
        <Flexbox justify="center" align="center">
          <VSpace value={20} />
          <Spinner size={80} />
        </Flexbox>
      </Container>
    );
  }
  return (
    <Container>
      <VSpace value={5} />
      <BackModalHeader
        back={() => setShouldCompress(false)}
        header={<Header>Reduce Image Size</Header>}
        noSeparator
      />
      <VSpace />
      <Section>
        {!useCompression && (
          <CircularCheckmark
            onChange={() => setUseCompression(!useCompression)}
            selected={useCompression}
            caption="USE COMPRESSION"
          />
        )}
        <VSpace />
        {useCompression ? (
          <Row isOneCol={isOneCol}>
            <ImagePreview
              loading={loading}
              tooLarge={tooLarge}
              size={compressedSize}
              src={compressedUrl}
            />
            <Column isOneCol={isOneCol}>
              <CircularCheckmark
                onChange={() => setUseCompression(!useCompression)}
                selected={useCompression}
                caption="USE COMPRESSION"
              />
              <SubTitle>
                Use compression to optimize page speed. Confirm or adjust
                compression below.
              </SubTitle>
              <Astrix>
                *The recommended weight is
                <br />
                less than 2 MB
              </Astrix>
              <VSpace value={3} />
              <QualityInputWrapper>
                <label>Quality</label>
                <NumberInput
                  disabled={loading}
                  minimal
                  min={5}
                  max={100}
                  step={1}
                  suffix="%"
                  defaultValue={quality?.toLocaleString()}
                  onChange={onQualityChange}
                />
              </QualityInputWrapper>
            </Column>
          </Row>
        ) : (
          <SubTitle>Use compression to optimize page speed.</SubTitle>
        )}
      </Section>
      <VSpace />
      <Section>
        <CircularCheckmark
          onChange={() => setUseCompression(!useCompression)}
          selected={!useCompression}
          caption={`Upload the image without compression - ${prettySize(
            blobToCompress.current?.size || 0,
          )}`}
        />
      </Section>
      <VspaceWrapper isOneCol={isOneCol} />
      <Footer>
        <Flexbox direction="row" padding="2rem">
          <BigButton
            disabled={loading}
            background="#FFFFFF"
            color="#8C9BA8"
            border="1px solid #A6AFB8"
            noTransform
            fillWidth
            noShadow
            size="medium"
            onClick={() => {
              setShouldCompress(false);
              onReplaceImage();
            }}
          >
            Replace Image
          </BigButton>
          <HSpace value={2} />
          <BigButton
            disabled={loading}
            background="#334BFF"
            color="#FFFFFF"
            noTransform
            fillWidth
            noShadow
            size="medium"
            onClick={onConfirm}
          >
            Confirm
          </BigButton>
        </Flexbox>
      </Footer>
    </Container>
  );
};

const VspaceWrapper = styled(VSpace)`
  margin-bottom: ${(p: any) => (p.isOneCol ? `70px` : `0`)};
`;

const Footer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const Container = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const QualityInputWrapper = styled.div`
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.09),
    0 18px 20px 0 rgba(177, 217, 203, 0.18), 0 8px 4px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  background-color: #ffffff;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
  padding-right: 1.5rem;

  label {
    margin-left: 3rem;
    color: #ababb1;
    font-family: 'JetBrains Mono', serif;
    font-weight: 700;
    font-size: 1.4rem;
  }

  input {
    padding-right: 10px;
    color: black;
    font-family: 'JetBrains Mono', serif;
    font-weight: 700;
    font-size: 1.4rem;
  }
`;
const Column = styled.div`
  margin-left: ${(p: any) => (p.isOneCol ? `0` : `25px`)};
  margin-bottom: ${(p: any) => (p.isOneCol ? `3rem` : `0`)};
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: center;
  line-height: 30px;
`;

const Astrix = styled.span`
  margin: 2rem 0;
  color: #82909e;
  font-family: 'JetBrains Mono', serif;
  font-weight: 600;
  font-size: 1.2rem;
`;
const SubTitle = styled.span`
  margin-top: 1rem;
  max-width: 210px;
  font-size: 1.4rem;
  color: #6c7a88;
  font-weight: 400;
  font-family: Inter, serif;
  letter-spacing: 0.29px;
  line-height: 30px;
`;

const Row = styled.div`
  flex-direction: ${(p: any) =>
    p.isOneCol ? `column-reverse` : `row-reverse`};
  display: flex;
  justify-content: space-between;
`;

function prettySize(size: number) {
  const kilobyte = 1024;
  const megabyte = kilobyte * kilobyte;

  if (size > megabyte) {
    return `${(size / megabyte).toFixed(2)} MB`;
  }
  if (size > kilobyte) {
    return `${(size / kilobyte).toFixed(2)} KB`;
  }
  if (size >= 0) {
    return `${size} B`;
  }

  return `N/A`;
}

const ImageBox = styled.div`
  position: relative;
  max-width: 280px;

  img {
    width: 100%;
    box-shadow: 0 22px 154px 0 rgba(0, 0, 0, 0.17),
      0 18px 46px 0 rgba(177, 217, 203, 0.18), 0 8px 24px 0 rgba(0, 0, 0, 0.12);
    padding: 0;
    border-radius: 10px;
  }

  span {
    display: flex;
    justify-content: start;
    align-items: center;

    svg {
      margin-right: 1rem;
    }

    position: absolute;
    top: -17px;
    right: -6px;
    color: white;
    font-family: 'JetBrains Mono', serif;
    font-weight: 800;
    font-size: 1.4rem;
    padding: 0.5rem 2rem 0.5rem 1rem;
    border-radius: 15px;
    white-space: nowrap;
  }
`;
const Section = styled.div`
  background-color: #f5f5f5;
  border-radius: 15px;
  padding: 3rem;
  margin: 0 2rem;
`;

const Header = styled.span`
  font-size: 1.8rem;
  font-weight: 500;
  font-family: Inter, serif;
  letter-spacing: 0.29px;
`;

function ImagePreview({
  loading,
  size,
  src,
  tooLarge,
}: {
  loading: boolean;
  tooLarge: boolean;
  size: number;
  src: string;
}) {
  return (
    <ImageBox
      style={{
        opacity: loading ? `0.5` : `1`,
      }}
    >
      <span style={{ backgroundColor: !tooLarge ? `#ffa800` : `#00d448` }}>
        {!tooLarge ? (
          <BiError color="white" size={20} />
        ) : (
          <BsCheck color="white" size={20} />
        )}
        {prettySize(size)}
      </span>
      <img src={src} alt="compressed" />
    </ImageBox>
  );
}
