import { useCallback, useContext } from 'react';
import { DropdownInputOption } from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';

export function useDropdownInputUpdater(
  props: GenericInputProps,
  key: string,
  options: DropdownInputOption[],
) {
  const { undoRedoCount } = useContext(UndoRedoCounterContext);
  const getValue = useCallback(() => {
    const val = props.initialValues(key) || options[0].value;
    const label = options.find(
      // eslint-disable-next-line eqeqeq
      (opt) => opt.value == val || opt.label == val,
    )?.label;
    return {
      label: label || options[0].label,
      value: val,
    } as DropdownInputOption;
  }, [undoRedoCount]);

  const onChange = (value) => {
    props.onValuesChanged(key, value.value);
  };

  return {
    undoRedoCount,
    getValue,
    onChange,
  };
}
