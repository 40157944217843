import { CatalogResponse, Product } from '@/webapi/use-catalog-api';
import { CatalogApp } from '@/webapi/use-widget-catalog-api';
import { ReviewProviders, ReviewsData } from '@/webapi/use-store-settings-api';
import { IntegrationsStatus } from '@/features/integrations/context';

import { Theme } from '@/webapi/models';
import { maybe } from '@/features/details/utils';
import { StoreStyles } from '@/features/editor/widgets/custom-widget/style-templating/models';
import { Audience } from '@/features/dashboard/audiences/models';

const PRODUCTS_PAGE_SIZE = 1000;

export async function fetchEditorData(
  listEntireCatalog: (
    page: number,
    pageSize: number,
  ) => Promise<CatalogResponse>,
  getAllApps: () => Promise<{ apps: CatalogApp[]; storeStyles?: StoreStyles }>,
  listReviewsProviders: () => Promise<ReviewProviders>,
  getIntegrationStatus: () => Promise<IntegrationsStatus>,
  getActivePostPurchaseExperienceId: () => Promise<string>,
  listThemes: () => Promise<Theme[]>,
  listAudiences: () => Promise<{ audiences: Audience[] }>,
  isFetchEntireCatalogAllowed: boolean,
) {
  const result = await Promise.all([
    listEntireCatalog(0, PRODUCTS_PAGE_SIZE),
    getAllApps(),
    getIntegrationStatus(),
    getActivePostPurchaseExperienceId(),
    listThemes(),
    listAudiences(),
  ]);
  const collections = result?.[0]?.collections;
  const products = result?.[0]?.products as Product[];
  const currencies = result?.[0]?.currencies ?? [`USD`];
  const tags = result?.[0]?.productTags;
  const mainAttribute = result?.[0]?.mainAttribute ?? ``;
  const mainAttributeOptions = result?.[0]?.mainAttributeOptions ?? [];
  const customerTags = result?.[0]?.customerTags;
  const appsCatalog = result?.[1]?.apps;
  const storeStyle = result?.[1]?.storeStyles;
  const integrationsStatus = result?.[2];
  const postPurchaseStatus = !!result?.[3];
  const themes = maybe(() => result?.[4] as Array<Theme>, []);
  const enabledOnMultipleThemes = (themes?.[0]?.theme_store_id || 0) >= 2;
  const audiences = result?.[5]?.audiences || [];

  const reviewsProviders = await listReviewsProviders();
  const hasReviewProviders =
    reviewsProviders?.providers?.filter((p) => p?.canBeSelected)?.length > 0;
  const reviewsEnabled =
    reviewsProviders?.providers?.filter((p) => p?.isSelected)?.length > 0;

  const productCount = result?.[0]?.productCount as number;
  if (productCount > products?.length) {
    const productsPromises: Promise<CatalogResponse>[] = [];
    for (
      let size = PRODUCTS_PAGE_SIZE, page = 1;
      size < productCount;
      size += PRODUCTS_PAGE_SIZE, page += 1
    ) {
      if (isFetchEntireCatalogAllowed || page < 11) {
        productsPromises.push(listEntireCatalog(page, PRODUCTS_PAGE_SIZE));
      }
    }
    const productResults = await Promise.all(productsPromises);
    productResults.forEach((resp) => {
      if (resp?.products && Array.isArray(resp?.products))
        products.push(...resp.products);
    });
  }

  const reviews = {
    reviewsProviders,
    reviewsEnabled,
    hasReviewProviders,
  } as ReviewsData;
  return {
    storeStyle,
    collections,
    products,
    tags,
    customerTags,
    appsCatalog,
    reviews,
    currencies,
    integrationsStatus,
    postPurchaseStatus,
    themes,
    mainAttribute,
    mainAttributeOptions,
    enabledOnMultipleThemes,
    audiences,
  };
}

export async function fetchAudiencesData(
  listEntireCatalog: (
    page: number,
    pageSize: number,
  ) => Promise<CatalogResponse>,
) {
  const result = await Promise.all([listEntireCatalog(0, PRODUCTS_PAGE_SIZE)]);
  const collections = result?.[0]?.collections;
  const products = result?.[0]?.products as Product[];
  const currencies = result?.[0]?.currencies ?? [`USD`];
  const tags = result?.[0]?.productTags;
  const mainAttribute = result?.[0]?.mainAttribute ?? ``;
  const mainAttributeOptions = result?.[0]?.mainAttributeOptions ?? [];
  const customerTags = result?.[0]?.customerTags;

  const productCount = result?.[0]?.productCount as number;
  if (productCount > products?.length) {
    const productsPromises: Promise<CatalogResponse>[] = [];
    for (
      let size = PRODUCTS_PAGE_SIZE, page = 1;
      size < productCount;
      size += PRODUCTS_PAGE_SIZE, page += 1
    ) {
      productsPromises.push(listEntireCatalog(page, PRODUCTS_PAGE_SIZE));
    }
    const productResults = await Promise.all(productsPromises);
    productResults.forEach((resp) => {
      if (resp?.products && Array.isArray(resp?.products))
        products.push(...resp.products);
    });
  }

  return {
    collections,
    products,
    tags,
    customerTags,
    currencies,
    mainAttribute,
    mainAttributeOptions,
  };
}
