import React, { FC } from 'react';
import styled from 'styled-components';

export const Loader: FC = () => (
  <PageLoader id="page_loader">
    <div />
  </PageLoader>
);

export const showLoader = () => {
  try {
    const elementById = document?.getElementById(`page_loader`);
    elementById && (elementById.style.display = `block`);
  } catch (e) {
    console.error(`showLoader`, e);
  }
};

export const hideLoader = () => {
  try {
    const elementById = document?.getElementById(`page_loader`);
    elementById && (elementById.style.display = `none`);
  } catch (e) {
    console.error(`hideLoader`, e);
  }
};

const PageLoader = styled.div`
  position: relative;
  width: 100%;
  div:first-child {
    position: absolute;
    background-color: #46464a;
    width: 0;
    height: 5px;
    animation: progress 1.5s infinite linear;
  }
  @keyframes progress {
    0% {
      width: 0;
    }
    25% {
      width: 50%;
    }
    50% {
      width: 75%;
    }
    75% {
      width: 85%;
    }
    100% {
      width: 100%;
    }
  } ;
`;
