import styled from 'styled-components';
import { Card } from '@/components/card';

export const Chip = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  background: transparent;

  font-size: 1.2rem;
  font-weight: 500;
  font-family: 'JetBrains Mono', serif;
  user-select: none;
  :hover {
    opacity: 0.5;
    box-shadow: none;
  }
  :active {
    opacity: 1;
  }

  color: #545454;

  border: 1px solid rgba(0, 0, 0, 0.2);

  box-shadow: 0 1px 4px 0 rgba(68, 72, 80, 0.08);

  border-radius: 2rem;
  padding: 0.6rem 1.5rem;
`;

export const DetailsCard = styled(Card)`
  && {
    cursor: default;
    display: grid;
    grid-row-gap: 2.5rem;
    padding: 3.5rem;
    border-radius: 2rem;
  }
`;
export const CONTROL = `CONTROL`;
export const DELETED = `deleted`;
