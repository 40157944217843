import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import { QuickEditGroup } from '@/webapi/use-widget-catalog-api';
import { extractQuickEditItemsFromGroups } from '@/features/editor/widgets/custom-widget/shared/quick-edit-helper';
import { EditorContext } from '@/features/editor/context/editor-context';
import { QuickEditItem } from '../inputs/shared/quick-edit-item';
import { SectionTitle } from '@/features/editor/widgets/custom-widget/shared/shared-styles';

interface QuickEditGroupsProps {
  groups: QuickEditGroup[];
}

export function QuickEditGroups({ groups }: QuickEditGroupsProps) {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);
  const { currentSchema, setUIStateByGroup } = useContext(CustomWidgetContext);

  const [selected, setSelected] = useState(groups[0]?.name);

  const quickEditGroups = useMemo(
    () =>
      groups
        .map((group) => group)
        .reduce((acc, g) => {
          const qeItemsContext = Object.entries(
            extractQuickEditItemsFromGroups(currentSchema, g),
          );
          if (!acc[g.name]) {
            acc[g.name] = [];
          }
          acc[g.name] = qeItemsContext;
          return acc;
        }, {}),
    [groups],
  );

  useEffect(() => {
    onSelection(groups[0]?.name);
  }, []);

  const onSelection = (key: string): void => {
    setUIStateByGroup(key);
    setSelected(key);
  };

  return (
    <div>
      <QuickEditGroupsHeader>Quick Style</QuickEditGroupsHeader>
      <Wrapper
        count={groups?.length}
        style={{ display: groups?.length > 1 ? `grid` : `none` }}
      >
        {groups.map((k, v) => (
          <Option
            key={v}
            isSelected={selected === k.name}
            onClick={() => onSelection(k.name)}
          >
            {k.name}
          </Option>
        ))}
      </Wrapper>
      {quickEditGroups[selected]?.map(([k, v]) => (
        <QuickEditItem key={k} quickEdit={v} device={device} showInputName />
      ))}
    </div>
  );
}

const Wrapper = styled.div`
  margin-bottom: 3rem;
  width: 100%;
  background-color: rgba(222, 225, 225, 0.39);
  display: grid;
  grid-template-columns: repeat(
    ${(props: { count: number }) => props.count},
    1fr
  );
  padding: 1rem;
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;
  font-family: JetBrains Mono, serif;
  font-size: 1.3rem;
  font-weight: 500;
  border-radius: 1rem;
`;

const Option = styled.div`
  text-transform: uppercase;
  text-align: center;
  border-radius: 1rem;
  padding: 1.5rem 1rem;
  cursor: pointer;
  color: #909ca7;
  ${(props: { isSelected: boolean }) =>
    props.isSelected &&
    `
    font-weight: 600;
    color: #000000;
    background-color: #ffffff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15),
      0 6px 7px 0 rgba(151, 210, 206, 0.22);
  `};
`;

const QuickEditGroupsHeader = styled(SectionTitle)`
  && {
    color: #828f9d !important;
    font-family: Inter;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0.28px;
    margin: 1rem 0 2rem;
    display: block;
  }
`;
