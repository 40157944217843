import * as React from 'react';
import { useContext } from 'react';
import { StylingComponentsGroup } from '@/features/editor/widgets/post-purchase/styling/group';
import { PostPurchaseButtonInput } from '@/features/editor/widgets/post-purchase/inputs/button';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';

export function PostPurchaseButtonStyles() {
  const { props, setProps } = useContext(PostPurchaseContext);

  return (
    <>
      <StylingComponentsGroup title="Button Style">
        <PostPurchaseButtonInput
          defaultValue={props?.style?.acceptCta}
          onChange={(p) =>
            setProps((draft) => {
              draft.style.acceptCta = p;
            })
          }
        />
      </StylingComponentsGroup>
      <StylingComponentsGroup title="Button Style">
        <PostPurchaseButtonInput
          defaultValue={props?.style?.declineCta}
          onChange={(p) =>
            setProps((draft) => {
              draft.style.declineCta = p;
            })
          }
        />
      </StylingComponentsGroup>
    </>
  );
}
