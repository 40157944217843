import * as React from 'react';
import { useContext } from 'react';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';
import { RadioButtons } from '@/features/editor/widgets/custom-widget/inputs/shared/radio-buttons';
import { DropdownInputOption } from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';

export const POST_PURCHASE_COUNTDOWN_OPTIONS: DropdownInputOption[] = [
  { label: `1min`, value: `60` },
  { label: `2min`, value: `120` },
  { label: `3min`, value: `180` },
  { label: `4min`, value: `250` },
  { label: `5min`, value: `300` },
  { label: `10min`, value: `600` },
];

export function TimerCountdownInput() {
  const { props, setProps } = useContext(PostPurchaseContext);
  const allowCustomEdit = useFeatureBit(
    FeatureBit.ENABLE_POST_PURCHASE_EXPERIMENTAL_OPTIONS,
  );
  const onChange = (val: string) => {
    setProps((draft) => {
      draft.content.countdown = val;
    });
  };

  return (
    <RadioButtons
      title="Set the timer countdown"
      defaultValue={`${props?.content?.countdown}`}
      onChange={onChange}
      options={POST_PURCHASE_COUNTDOWN_OPTIONS}
      allowCustomEdit={allowCustomEdit}
      customEditHint="* in seconds, e.g 120 for 2 minutes"
    />
  );
}
