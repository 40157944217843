/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { GradientModal } from '@/components/gradient-modal';
import { Footer } from '@/features/editor/widgets/shared/modals/commons';
import { centered } from '@/components/use-shared-element';
import {
  EditorContext,
  EditorContextProps,
} from '@/features/editor/context/editor-context';
import { useShopifyApi } from '@/webapi/use-shopify-api';
import { Theme } from '@/webapi/models';

interface Props {
  fromRef: React.MutableRefObject<HTMLElement>;
  isVisible: boolean;
  setIsVisible: (state: boolean) => void;
  themes: Array<Theme>;
}

export function ThemesModal({
  fromRef,
  isVisible,
  setIsVisible,
  themes,
}: Props) {
  const editor = useContext(EditorContext);

  const onClose = () => {
    setIsVisible(false);
  };

  const { isAppEmbedEnabled, injectSdk, loading, cleanPreviewCache } =
    useShopifyApi();

  const [selected, select] = useState<number>(getThemeId(editor));
  const selectTheme = (id: number) => {
    if (id === editor.experienceState.currentExperience.mainThemeId) {
      select(0);
    } else {
      select(id);
    }
  };

  const onSelect = async () => {
    const appBlockStatus = await isAppEmbedEnabled(selected);
    if (!appBlockStatus.legacy_sdk_installed) {
      await injectSdk(selected.toString());
    }
    await cleanPreviewCache();
    editor.experienceState.selectTheme(selected);
    onClose();
  };
  return (
    <GradientModal
      {...getGradientModalProps(isVisible, fromRef)}
      onClose={onClose}
      header={`Choose which theme you'd like to view the experience on`}
      footer={
        <Footer
          onClick={onSelect}
          text={loading ? `...` : `Select`}
          isDisabled={loading}
        />
      }
    >
      <Container>
        <ThemesList
          mainThemeId={editor.experienceState.currentExperience.mainThemeId}
          themes={themes}
          selected={selected}
          select={selectTheme}
        />
      </Container>
    </GradientModal>
  );
}

const BoxWrap = styled.div`
  position: relative;
  padding: 1rem;
`;
const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Active = styled.div`
  border-radius: 30px;
  padding: 0 2rem;
  height: 2.5rem;
  background-color: #13d170;
  color: white;
  font-family: 'JetBrains Mono', serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 800;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  strong {
    max-width: 25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    color: #a8b1ba;
    margin-top: 1rem;
  }
`;
const Box = styled.div`
  background-color: #fafafa;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15),
    0 14px 16px 0 rgba(151, 210, 206, 0.22), 0 5px 16px 0 rgba(0, 0, 0, 0);

  border: 2px solid ${(p: any) => (p.selected ? `#0062ff` : `#d5d9dc`)};

  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 3rem;
  width: 44rem;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  &:active {
    opacity: 1;
    cursor: default;
  }
`;

const Container = styled.div`
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  font-family: 'Inter', serif;
  font-size: 1.4rem;
`;

const OkWrapper = styled.div`
  position: absolute;
  top: 10.5rem;
  left: 95%;
`;

function Ok({ isSelected }: { isSelected: boolean }) {
  if (!isSelected) return null;
  return (
    <OkWrapper>
      <StaticImage
        height={20}
        loading="eager"
        placeholder="none"
        src="../../../../../../assets/ok.png"
        alt="minus"
      />
    </OkWrapper>
  );
}

function getGradientModalProps(
  isVisible: boolean,
  fromRef: React.MutableRefObject<HTMLElement>,
) {
  return {
    closeTop: `0.5rem`,
    closeRight: `2.5rem`,
    fromStyle: {
      borderRadius: `5rem`,
      backgroundColor: `#DEDEDE`,
      padding: `2rem 0 0 0`,
    },
    toStyle: {
      borderRadius: `1rem`,
      backgroundColor: `#FFFFFF`,
      padding: `2rem 0 0 0`,
    },
    showBackdrop: true,
    isVisible,
    targetPosAndSize: centered(80, 100),
    fromRef,
    overflowTop: { heightInRem: 8 },
    overflowBottom: { heightInRem: 8, turnPointInPercent: 30 },
  };
}

function getThemeId(editor: EditorContextProps) {
  const { themeId } = editor.experienceState.currentExperience;
  const { mainThemeId } = editor.experienceState.currentExperience;
  if (`${themeId}` === `0` || themeId === mainThemeId) {
    return 0;
  }
  if (typeof themeId === `string`) {
    return parseInt(themeId, 10);
  }
  return themeId;
}

function ThemesList({
  themes,
  selected,
  select,
  mainThemeId,
}: {
  themes: Array<Theme>;
  selected: number;
  mainThemeId: number;
  select: (x: number) => void;
}) {
  return (
    <>
      {themes.map((t) => (
        <BoxWrap key={t.id}>
          <Box
            selected={
              t.id === selected || (selected === 0 && t.id === mainThemeId)
            }
            onClick={() => select(t.id)}
          >
            <Col>
              <strong>{t.name}</strong>
              <span>
                Last Change: {new Date(t.updated_at).toLocaleDateString()}
              </span>
            </Col>
            {t.role === `main` && (
              <Center>
                <Active>Active</Active>
              </Center>
            )}
          </Box>
          <Ok isSelected={t.id === selected} />
        </BoxWrap>
      ))}
    </>
  );
}
