/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import styled from 'styled-components';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useContext, useEffect, useRef, useState } from 'react';
import { AudienceButton } from '@/features/editor/widgets/shared/targeting-controls/audience-button';
import { PlacementButton } from '@/features/editor/widgets/shared/targeting-controls/placement-button';
import { DeviceButton } from '@/features/editor/widgets/shared/targeting-controls/device-button';
import { TriggerButton } from '@/features/editor/widgets/shared/targeting-controls/trigger-button';
import { useIsPreviewingDesktop } from '@/features/editor/shared/use-device-type';
import { OverflowLeft, OverflowRight } from '@/components/overflow';
import { useDetachedState } from '@/components/hooks/use-detached-state';
import { VariantsButton } from '@/features/editor/widgets/shared/targeting-controls/variants-button';
import { EditorContext } from '@/features/editor/context/editor-context';
import {
  ThemesButton,
  useThemesButton,
} from '@/features/editor/widgets/shared/targeting-controls/themes-button';

export const TARGETING_CONTROLS_SELECTOR = `targeting-controls`;

export interface TargetingControlsListProps {
  disableAudiencePicker?: boolean;
  disablePlacementPicker?: boolean;
  disableDevicesPicker?: boolean;
  disableTriggerPicker?: boolean;
  disableThemePickerPicker?: boolean;
}

export function TargetingControlsList({
  disableAudiencePicker,
  disableDevicesPicker,
  disablePlacementPicker,
  disableTriggerPicker,
}: TargetingControlsListProps) {
  const isDesktop = useIsPreviewingDesktop();
  const scrollRef = useRef(null);

  const [showPrev, setShowPrev] = useState(
    scrollRef?.current?.scrollLeft === 0,
  );
  const [_1, setIsHoveringPrev, isHoveringPrev] = useDetachedState(false);

  const [showNext, setShowNext] = useState(
    scrollRef?.current?.scrollWitdh > scrollRef?.current?.clientWidth,
  );
  const [_2, setIsHoveringNext, isHoveringNext] = useDetachedState(false);

  const [scrollPos, setScrollPos] = useDetachedState(
    scrollRef?.current?.scrollLeft || 0,
  );

  const { isThemesDisabled } = useThemesButton();

  const onScroll = (ev) => {
    if (ev?.target) {
      setScrollPos(Math.round(ev.target.scrollLeft));
    }
  };

  const onPrevHover = () => {
    setIsHoveringPrev(true);
    movePrev();
  };

  const onPrevExitHover = () => {
    setIsHoveringPrev(false);
  };

  const movePrev = () => {
    setTimeout(() => {
      if (isHoveringPrev?.current && scrollRef?.current?.scrollBy) {
        scrollRef?.current?.scrollBy(-2, 0);
        movePrev();
      }
    }, 5);
  };

  const onNextHover = () => {
    setIsHoveringNext(true);
    moveNext();
  };

  const moveNext = () => {
    setTimeout(() => {
      if (isHoveringNext?.current && scrollRef?.current?.scrollBy) {
        scrollRef?.current?.scrollBy(2, 0);
        moveNext();
      }
    }, 5);
  };

  const onNextExitHover = () => {
    setIsHoveringNext(false);
  };

  useEffect(() => {
    if (scrollRef?.current) {
      const endLeftover =
        scrollRef.current.scrollWidth -
        scrollRef.current.clientWidth -
        scrollPos;

      const startLeftOver = scrollRef?.current?.scrollLeft;

      if (endLeftover <= 20) {
        setShowNext(false);
      } else {
        setShowNext(true);
      }

      if (startLeftOver >= 10) {
        setShowPrev(true);
      } else {
        setShowPrev(false);
      }
    }
  }, [scrollRef?.current, scrollPos, isDesktop]);

  useEffect(() => {
    if (scrollRef?.current) {
      const scroller = scrollRef?.current as HTMLDivElement;
      scroller?.addEventListener(`scroll`, onScroll);
    }
    return () => {
      if (scrollRef?.current) {
        const scroller = scrollRef?.current as HTMLDivElement;
        scroller?.removeEventListener(`scroll`, onScroll);
      }
    };
  }, [scrollRef, isDesktop]);

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current.scrollLeft = 0;
    }
  }, [scrollRef, isDesktop]);

  const {
    currentExtension,
    experienceState: { currentExperience },
  } = useContext(EditorContext);

  const isMultiVariant = currentExperience.variants.length > 1;
  const extraScrollerColumns =
    `${isMultiVariant ? `max-content` : ``}` +
    ` ${!isThemesDisabled ? `max-content` : ``}`;

  return (
    <ControlsSliderWrapper>
      <ScrollButton
        className="left"
        isShown={showPrev}
        onMouseEnter={onPrevHover}
        onMouseLeave={onPrevExitHover}
      >
        <FaChevronLeft size={8} />
      </ScrollButton>
      <ScrollerWrapper>
        {showPrev && (
          <OverflowLeft className="overflow" left="-2rem" width="7rem" />
        )}
        <Scroller
          id={TARGETING_CONTROLS_SELECTOR}
          ref={scrollRef}
          extraColumns={extraScrollerColumns}
        >
          <VariantsButton />
          <AudienceButton isDisabled={disableAudiencePicker} />
          <PlacementButton isDisabled={disablePlacementPicker} />
          <DeviceButton isDisabled={disableDevicesPicker} />
          {currentExtension === `` && (
            <TriggerButton isDisabled={disableTriggerPicker} />
          )}
          <div />
        </Scroller>
        {showNext && <OverflowRight className="overflow" right="-1rem" />}
      </ScrollerWrapper>
      <ScrollButton
        className="right"
        isShown={showNext}
        onMouseEnter={onNextHover}
        onMouseLeave={onNextExitHover}
      >
        <FaChevronRight size={8} />
      </ScrollButton>
    </ControlsSliderWrapper>
  );
}

const ControlsSliderWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: visible;
`;

const ScrollerWrapper = styled.div`
  position: relative;
  height: auto;
`;

const Scroller = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  padding: 10px;
  margin: -10px;
  overflow-x: scroll;
  grid-template-columns: repeat(4, max-content) ${(p: {
      extraColumns?: string;
    }) => (p.extraColumns ? `${p.extraColumns} 2rem` : `2rem`)};
  grid-gap: 2rem;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: center;
`;

const ScrollButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 1.9rem;
  bottom: 0;

  &&.left {
    cursor: w-resize;
    left: -0.7rem;
  }

  &&.right {
    cursor: e-resize;
    right: -0.7rem;
  }

  background: white;
  border-radius: 50%;
  z-index: 100;

  height: 2.5rem;
  width: 2.5rem;

  box-shadow: 0 6px 7px 0 rgba(181, 193, 204, 0.7);

  :hover {
    box-shadow: 0 6px 7px 0 rgba(181, 193, 204, 1);
    opacity: 0.6;
    background: rgba(222, 222, 222, 0.1);
    backdrop-filter: blur(4px);
  }

  opacity: ${(p) => (p.isShown ? `1` : `0`)};
  pointer-events: ${(p) => (p.isShown ? `auto` : `none`)};
  transition: all 0.2s linear;
`;
