import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { DefaultTypography } from '@/components/typography';
import { TabSelect } from '@/components/tab-select';
import { CloseButton } from '@/components/close-button';
import { SolidColorTab } from '@/features/editor/widgets/custom-widget/inputs/background/color/solid';
import { LinearColorTab } from '@/features/editor/widgets/custom-widget/inputs/background/color/linear';
import { RadialColorTab } from '@/features/editor/widgets/custom-widget/inputs/background/color/radial';
import {
  BackgroundColorContext,
  newBackgroundColorContextProps,
} from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/context';
import {
  ColorMode,
  LinearGradientColor,
  RadialGradientColor,
  SolidColor,
} from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/models';
import { Divider } from '@/features/editor/widgets/custom-widget/inputs/background/color/components/commons';
import { capitalize } from '@/utils/types';
import {
  generateStyleStringFromColor,
  parseModeFromColor,
} from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/utils';
import { CustomizationSpec } from '@/webapi/use-widget-catalog-api';

export interface BackgroundColorInputProps {
  isActive: boolean;
  defaultValue: string;
  onChange?: (
    colorStr: string,
    color?: SolidColor | LinearGradientColor | RadialGradientColor,
  ) => void;
  onCloseClicked?: () => void;
  spec?: CustomizationSpec;
  showHeader?: boolean;
  debounceDelay?: number;
}

export function BackgroundColorInput({
  defaultValue,
  onChange,
  onCloseClicked,
  isActive,
  spec,
  showHeader = true,
  debounceDelay = 50,
}: BackgroundColorInputProps) {
  const defaultMode = parseModeFromColor(defaultValue);
  const ctx = newBackgroundColorContextProps(defaultMode, defaultValue, spec);

  const {
    mode,
    setMode,
    solidColor,
    linearColor,
    radialColor,
    updateLinearColor,
    updateRadialColor,
    updateSolidColor,
  } = ctx;

  const debouncedOnChange = useDebouncedCallback(onChange, debounceDelay);
  const [prevSolid, setPrevSolid] = useState(solidColor);
  const [prevLiner, setPrevLinear] = useState(linearColor);
  const [prevRadial, setPrevRadial] = useState(radialColor);

  useEffect(() => {
    if (onChange && isActive) {
      if (mode === ColorMode.SOLID) {
        setPrevSolid(solidColor);
        debouncedOnChange(generateStyleStringFromColor(solidColor), solidColor);
      }

      if (mode === ColorMode.LINEAR) {
        setPrevLinear(linearColor);
        debouncedOnChange(
          generateStyleStringFromColor(linearColor),
          linearColor,
        );
      }
      if (mode === ColorMode.RADIAL) {
        setPrevRadial(radialColor);
        debouncedOnChange(
          generateStyleStringFromColor(radialColor),
          radialColor,
        );
      }
    }
  }, [mode, solidColor, linearColor, radialColor]);

  return (
    <Wrapper showHeader={showHeader}>
      <BackgroundColorContext.Provider value={ctx}>
        {showHeader && (
          <>
            <Header>
              <Title>Fills</Title>
              <TabSelect
                defaultValue={capitalize(mode)}
                tabs={[
                  capitalize(ColorMode.SOLID),
                  capitalize(ColorMode.LINEAR),
                  capitalize(ColorMode.RADIAL),
                ]}
                tabHeight={3.2}
                tabWidth={10}
                tabRadius={0.8}
                onTabSelected={(_, name: string) => {
                  switch (ColorMode[name.toUpperCase()]) {
                    case ColorMode.SOLID:
                      updateSolidColor(prevSolid);
                      onChange(generateStyleStringFromColor(solidColor));
                      break;
                    case ColorMode.LINEAR:
                      updateLinearColor(prevLiner);
                      onChange(generateStyleStringFromColor(linearColor));
                      break;
                    case ColorMode.RADIAL:
                      updateRadialColor(prevRadial);
                      onChange(generateStyleStringFromColor(radialColor));
                      break;
                    default:
                  }
                  setMode(ColorMode[name.toUpperCase()]);
                }}
              />
              <CloseButton onClick={onCloseClicked} />
            </Header>
            <Divider />
          </>
        )}
        <Content>
          {mode === ColorMode.SOLID && <SolidColorTab />}
          {mode === ColorMode.LINEAR && <LinearColorTab />}
          {mode === ColorMode.RADIAL && <RadialColorTab />}
        </Content>
      </BackgroundColorContext.Provider>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  grid-template-rows: ${(props: { showHeader: boolean }) =>
    props.showHeader ? `7rem 1px 1fr` : `1fr`};
`;

const Header = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;

  .gatsby-image-wrapper {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

const Title = styled(DefaultTypography)`
  && {
    color: #4b5564;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -0.58px;
  }
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
`;
