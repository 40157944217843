import React, { useEffect, useState } from 'react';
import Editor, { useMonaco, loader } from '@monaco-editor/react';

loader.config({
  paths: {
    vs: `https://fastly.jsdelivr.net/npm/monaco-editor@0.48.0/min/vs`,
  },
});

export interface MonacoEditorProps {
  code: string;
  onCodeChange: (string) => void;
  lang: 'html' | 'css' | 'javascript' | 'bash';
  readOnly: boolean;
}

export function MonacoEditor({
  code,
  onCodeChange,
  lang,
  readOnly,
}: MonacoEditorProps) {
  const monaco = useMonaco();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (monaco) {
      monaco.editor.defineTheme(`loomi`, {
        base: `vs`,
        inherit: true,
        rules: [],
        colors: {
          'editor.background': `#F6F8F9`,
        },
      });
      setIsLoaded(true);
    }
  }, [monaco]);

  const handleMount = (editor) => {
    editor.updateOptions({ readOnly });
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isLoaded && (
        <Editor
          height="100%"
          width="100%"
          defaultLanguage={lang}
          language={lang}
          defaultValue={code}
          value={code}
          onChange={onCodeChange}
          theme="loomi"
          options={monacoOptions}
          onMount={handleMount}
        />
      )}
    </>
  );
}

export const HtmlCodeEditor = ({
  code,
  onChange,
  readOnly,
}: {
  code: string;
  onChange: (code: string) => void;
  readOnly: boolean;
}) => (
  <MonacoEditor
    code={code}
    onCodeChange={onChange}
    lang="html"
    readOnly={readOnly}
  />
);

export const CssCodeEditor = ({
  code,
  onChange,
  readOnly,
}: {
  code: string;
  onChange: (code: string) => void;
  readOnly: boolean;
}) => (
  <MonacoEditor
    code={code}
    onCodeChange={onChange}
    lang="css"
    readOnly={readOnly}
  />
);

export const JsCodeEditor = ({
  code,
  onChange,
  readOnly,
}: {
  code: string;
  onChange: (code: string) => void;
  readOnly: boolean;
}) => (
  <MonacoEditor
    code={code}
    onCodeChange={onChange}
    lang="javascript"
    readOnly={readOnly}
  />
);

export const monacoOptions = {
  minimap: {
    enabled: false,
  },
  fontSize: 18,
  fontFamily: `JetBrains Mono`,
};
