import React from 'react';
import styled from 'styled-components';
import { DelSvg } from '@/features/editor/widgets/custom-widget/loading-section/shared/icons/delSvg';

export function DeleteBtn({
  onDelete,
  slotNum,
}: {
  onDelete: () => void;
  slotNum: number;
}) {
  if (typeof slotNum === `undefined`) {
    return null;
  }
  return (
    <Wrapper>
      <IconButton onClick={onDelete}>
        <DelSvg />
      </IconButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  left: 87%;
  top: 9%;
`;

const IconButton = styled.button`
  margin: 0 0.5rem;
  ${(p) => (p.disabled ? `opacity: 0.5;` : `cursor: pointer;`)}
  border-radius: 50%;


    ${(p) =>
      p.disabled
        ? ``
        : `
  svg {
    &:hover {
    rect {
      fill: black;
    }
      g {
        opacity: 1;

      }
    }

    &:active {
      rect {
      fill: #b6bac5;
    }
      g {
        opacity: 0.5;

      }
    }
  }
        `}
  }
`;
