import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Popover } from 'react-tiny-popover';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { Caption, SingleRowWrapper } from '../../../shared/shared-styles';
import { LinkInputPopover } from '@/features/editor/widgets/custom-widget/inputs/shared/link/link-popover';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';
import { useIsPreviewingDesktop } from '@/features/editor/shared/use-device-type';

export const INPUT_LINK_CLASS = `input-link`;

export function LinkInput({
  device,
  initialValues,
  onValuesChanged,
  hover,
}: GenericInputProps) {
  const { undoRedoCount } = useContext(UndoRedoCounterContext);

  const getValue = useCallback(
    () => initialValues(`value`, device),
    [undoRedoCount],
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [link, setLink] = useState(getValue());
  useEffect(() => setLink(getValue()), [undoRedoCount]);
  const containerRef = useRef<HTMLElement>(null);

  const getParentRect = useCallback(() => {
    if (containerRef) {
      return containerRef?.current?.getBoundingClientRect();
    }
    return {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: 0,
      width: 0,
    } as DOMRect;
  }, [containerRef]);

  const onLinkSelected = (text: string) => {
    setIsMenuOpen(false);
    setLink(text);
  };

  useEffect(() => {
    onValuesChanged(`value`, link, device);
  }, [link]);

  return (
    <Wrapper
      hidden={useIsPreviewingDesktop() && hover}
      ref={containerRef}
      className={INPUT_LINK_CLASS}
    >
      <Caption className="prefix">Link to</Caption>
      <Popover
        isOpen={isMenuOpen}
        content={
          <LinkInputPopover
            defaultValue={link}
            width={getParentRect()?.width}
            onClose={() => setIsMenuOpen(false)}
            onSelection={onLinkSelected}
          />
        }
        onClickOutside={() => setIsMenuOpen(false)}
        positions={[`bottom`]}
        contentLocation={getParentRect()}
        containerStyle={{ zIndex: `100`, top: `-285px` }}
      >
        <Content className="content" onClick={() => setIsMenuOpen(true)}>
          {link?.length === 0 ? (
            <>
              Add a link or <u>Select from site</u>
            </>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <NoOverflowText>{link}</NoOverflowText>
          )}
        </Content>
      </Popover>
      <StyledIcon>
        <StaticImage
          src="../../../../../../../assets/anchor.svg"
          alt="edit"
          placeholder="none"
          loading="eager"
          height={15}
        />
      </StyledIcon>
    </Wrapper>
  );
}

const Wrapper = styled(SingleRowWrapper)`
  && {
    display: ${(props: { hidden: boolean }) =>
      props.hidden ? `none` : `grid`};
    grid-template-columns: 6rem minmax(0, 1fr) 4rem;
    padding: 1.4rem 1.8rem;
    grid-gap: 1rem;
  }
`;

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Content = styled.div`
  display: block;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  color: #5b656e;
  font-family: 'JetBrains Mono', serif;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: -0.03px;
  transform: translate(0, 0);
  white-space: nowrap;

  :hover {
    opacity: 0.8;
  }
`;

const NoOverflowText = styled.span`
  display: block;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  font-weight: bold;
`;
