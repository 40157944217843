import React, { useContext } from 'react';
import styled from 'styled-components';
import { SelectOptions, SmallSelect } from '@/components/small-select';
import { QBContext } from '@/components/query-builder/context';
import {
  OrButton,
  QBCaption,
  QBNumberInput,
  QBRemove,
  QBColon,
  QBVSpace,
  shouldShowOrCondition,
} from '@/components/query-builder/shared';
import { Flexbox } from '@/components/flex';
import { QBTimeframeSuffix } from '@/components/query-builder/timeframe-suffix';
import { Checkbox } from '@/components/checkbox';
import { QBItemSelection } from '@/components/query-builder/models';
import { EditorContext } from '@/features/editor/context/editor-context';
import { currencyValue } from '@/utils/currencies';

function getCurrencyCodes(defaultCurrency: string) {
  let currencyCodes = (
    useContext(EditorContext)?.resources?.currencies || [`USD`]
  ).map((x) => ({ key: x, value: x }));
  if (
    defaultCurrency !== `USD` &&
    !currencyCodes.map((x) => x.key).includes(defaultCurrency)
  ) {
    currencyCodes = [
      { key: defaultCurrency, value: defaultCurrency },
      ...currencyCodes,
    ];
  }
  return currencyCodes;
}

export function QBNumericValue({ idx }: { idx: number }) {
  const {
    setCurrencyAutoApply,
    appendValue,
    updateValue,
    queryState,
    removeValue,
    removeKey,
    updateTimeframe,
  } = useContext(QBContext);
  const defaultCurrency = currencyValue();
  const currencyCodes = getCurrencyCodes(defaultCurrency);

  const current = queryState[idx];

  const onOpChanged = (childIdx, op) => {
    const value = current?.values?.[childIdx];
    updateValue(idx, childIdx, op, value?.value, value?.currency);
  };

  const onCurrencyChanged = (childIdx, currency) => {
    const value = current?.values?.[childIdx];
    updateValue(idx, childIdx, value?.op, value?.value, currency);
  };
  const onValueChanged = (childIdx, ev) => {
    updateValue(
      idx,
      childIdx,
      current?.values?.[childIdx]?.op,
      ev.target.value,
      current?.values?.[childIdx]?.currency ||
        (hasCurrencyCode(queryState, idx) ? defaultCurrency : undefined),
    );
  };

  const onAddMore = () => {
    appendValue(
      idx,
      queryState?.[idx]?.qbProps?.defaultNumericValueOp || NumberValueOps.GTE,
      `0`,
      hasCurrencyCode(queryState, idx) ? defaultCurrency : undefined,
    );
  };

  const onRemove = (childIdx: number) => {
    if (current.values.length === 1) {
      removeKey(idx);
    } else {
      removeValue(idx, childIdx);
    }
  };

  return (
    <Wrapper>
      {current?.values?.map((_, childIdx) => (
        <Row key={`numeric-value-${childIdx}`}>
          {childIdx > 0 && <LogicalOr>Or</LogicalOr>}
          <SmallSelect
            minWidth={70}
            isDisabled={isOpDisabled(queryState, idx)}
            options={numericValueOptions}
            defaultValue={
              current?.values?.[childIdx]?.op ||
              current?.qbProps?.defaultNumericValueOp
            }
            onSelection={(op) => onOpChanged(childIdx, op)}
          />
          <QBColon />
          {hasCurrencyCode(queryState, idx) && (
            <>
              <SmallSelect
                options={currencyCodes}
                isDisabled={shouldLockCurrency(queryState, idx)}
                defaultValue={
                  current?.values?.[childIdx]?.currency || defaultCurrency
                }
                onSelection={(currency) =>
                  onCurrencyChanged(childIdx, currency)
                }
              />
              <div style={{ marginRight: `1rem` }} />
            </>
          )}
          <QBNumberInput
            defaultValue={
              current?.values?.[childIdx]?.value ||
              current?.qbProps?.defaultNumericValue
            }
            onChange={(ev) => onValueChanged(childIdx, ev)}
          />
          <QBRemove
            shouldHide={
              queryState?.length <= 1 && current?.values?.length === 1
            }
            onClick={() => onRemove(childIdx)}
          />
        </Row>
      ))}
      {queryState[idx].qbProps.hasTimeframe && (
        <QBTimeframeSuffix
          onChange={(v) => updateTimeframe(idx, v)}
          initialValue={queryState[idx].timeframe}
        />
      )}
      {hasCurrencyCode(queryState, idx) &&
        !shouldHideCurrencyAutoApplyCheckbox(queryState, idx) && (
          <>
            <CurrencyCheckbox>
              <Checkbox
                initialValue={current?.autoApplyCurrency}
                caption="Run on all currencies (according to the conversion based on the defined amount)"
                onSelection={(bool) => setCurrencyAutoApply(idx, bool)}
                size="small"
              />
            </CurrencyCheckbox>
            <QBVSpace />
          </>
        )}
      {hasExplain(queryState, idx) && (
        <Explain>{queryState[idx].qbProps.explain}</Explain>
      )}
      {shouldShowOrCondition(current) && (
        <OrButton onClick={onAddMore}>|| Or</OrButton>
      )}
    </Wrapper>
  );
}

const Explain = styled.span`
  font-family: 'JetBrains Mono', serif;
  font-size: 1rem;
  font-weight: 400;
  color: #656669;
  margin-left: -28rem;
  user-select: none;
`;
const Wrapper = styled(Flexbox)`
  && {
    flex-direction: column;
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  &&:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const LogicalOr = styled(QBCaption)`
  && {
    position: absolute;
    top: calc(50% - 1rem);
    left: -3rem;
  }
`;

const CurrencyCheckbox = styled.div`
  position: relative;
  height: 3rem;

  && > :first-child {
    position: absolute;
    width: 60rem;
    left: -15rem;
    text-transform: lowercase !important;
    grid-gap: 0.2rem;
  }
`;

function hasCurrencyCode(queryState: QBItemSelection[], idx: number) {
  return !!queryState?.[idx]?.qbProps?.hasCurrencyCode;
}

function isOpDisabled(queryState: QBItemSelection[], idx: number) {
  return !!queryState?.[idx]?.qbProps?.disableOp;
}

function hasExplain(queryState: QBItemSelection[], idx: number): boolean {
  return queryState?.[idx]?.qbProps?.explain?.length > 0;
}

function shouldHideCurrencyAutoApplyCheckbox(
  queryState: QBItemSelection[],
  idx: number,
): boolean {
  return !!queryState?.[idx]?.qbProps?.hideCurrencyCodeCheckbox;
}

function shouldLockCurrency(
  queryState: QBItemSelection[],
  idx: number,
): boolean {
  return !!queryState?.[idx]?.qbProps?.lockCurrency;
}

export enum NumberValueOps {
  IS = `is`,
  ISNOT = `isnot`,
  GT = `gt`,
  GTE = `gte`,
  LT = `lt`,
  LTE = `lte`,
}

export const numericValueOptions: SelectOptions[] = [
  {
    key: NumberValueOps.IS,
    value: `Is`,
  },
  {
    key: NumberValueOps.ISNOT,
    value: `Is not`,
  },
  {
    key: NumberValueOps.GT,
    value: `Greater Than`,
  },
  {
    key: NumberValueOps.GTE,
    value: `Greater Eq Than`,
  },
  {
    key: NumberValueOps.LT,
    value: `Lower Than`,
  },
  {
    key: NumberValueOps.LTE,
    value: `Lower Eq Than`,
  },
];
