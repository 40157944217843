import React from 'react';
import styled from 'styled-components';
import { HSpace } from '@/components/spacing';
import { DeleteBtn } from '@/features/editor/widgets/custom-widget/loading-section/shared/delete-btn';
import {
  RecommendationType,
  RecommendationType as T,
} from '@/webapi/use-widget-catalog-api';
import { MoreFilters } from '@/features/editor/widgets/custom-widget/loading-section/shared/more-filters';

const colors = [
  `#622CF6`,
  `#FF7F00`,
  `#00CED1`,
  `#FF1493`,
  `#008000`,
  `#FFD700`,
  `#800080`,
  `#FF4500`,
  `#4169E1`,
  `#DC143C`,
  `#0BCDAE`,
];

const strategyColors = new Map<RecommendationType, string>([
  [RecommendationType.PAST_PURCHASES, colors[0]],
  [RecommendationType.PURCHASED_TOGETHER, colors[1]],
  [RecommendationType.NEW_ARRIVALS, colors[2]],
  [RecommendationType.RECENTLY_VIEWED, colors[3]],
  [RecommendationType.CART_ITEMS, colors[4]],
  [RecommendationType.VIEWD_TOGETHER, colors[6]],
  [RecommendationType.PERSONALIZED, colors[7]],
  [RecommendationType.MOST_POPULAR, colors[8]],
  [RecommendationType.RECENTLY_VIEWED, colors[9]],
  [RecommendationType.PURCHASED_WITH_RECENTLY_PURCHASED, colors[5]],
  [RecommendationType.VIEWED_WITH_RECENTLY_VIEWED, colors[10]],
]);

type Props = {
  strategy: T;
  slotNum?: number;
  filterLen: number;
  onDelete: () => void;
  onMoreFilters: () => void;
};

export function StrategyTile({
  slotNum,
  strategy,
  filterLen,
  onDelete,
  onMoreFilters,
}: Props) {
  return (
    <Item>
      <DeleteBtn onDelete={onDelete} slotNum={slotNum} />
      <Box style={{ color: strategyColors.get(strategy) || `#3451fa` }}>
        <span>{strategy.toString()[0]}</span>
      </Box>
      <HSpace value={2} />
      <StrategyDetails>
        <StrategyTitle>{formatStrategy(strategy)}</StrategyTitle>
        <MoreFilters
          isVisible
          filterLen={filterLen}
          onMoreFilters={onMoreFilters}
        />
      </StrategyDetails>
    </Item>
  );
}

const StrategyTitle = styled.span`
  text-transform: uppercase;

  font-weight: 500;
  color: #4b545e;
  font-size: 1.2rem;

  display: flex;
  flex-wrap: wrap;
  max-width: 20rem;
  max-height: 3rem;
  overflow: hidden;
`;
const Box = styled.div`
  font-size: 3rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 10px;
  background-color: #e1e6e8;
`;
const StrategyDetails = styled.div`
  font-family: 'JetBrains Mono', serif;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
`;

export const Item = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  padding: 2rem;
  border-radius: 10px;
  height: 14rem;
  min-width: 33rem;
  background-color: #fafafa;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15),
    0 14px 16px 0 rgba(151, 210, 206, 0.22), 0 5px 16px 0 rgba(0, 0, 0, 0);
  border: 1px solid ${(p: any) => (p.isClickable ? `#d5d9dc` : `#a6afb8`)};
`;

function formatStrategy(strategy: RecommendationType): string {
  if (strategy === RecommendationType.PAST_PURCHASES) {
    return `PURCHASED ITEMS`;
  }
  return strategy.replaceAll(`_`, ` `);
}
