import React from 'react';
import styled from 'styled-components';
import { extractBlobFromBackgroundUrl } from '@/features/editor/widgets/custom-widget/inputs/shared/utils';

export function ImageTile({
  src,
  onClick,
}: {
  src?: string;
  onClick?: (event) => void;
}) {
  const onSelected = (event) => {
    onClick && onClick(event);
  };

  let source = ``;
  if (
    !src ||
    src === `` ||
    !src.includes(`://`) ||
    src.includes(`placeholder`)
  ) {
    source = ``;
  } else if (src.startsWith(`url(`)) {
    source = extractBlobFromBackgroundUrl(src);
  } else {
    source = src;
  }

  return (
    <Wrapper onClick={onSelected}>
      {source === `` ? (
        <AddButton>Add</AddButton>
      ) : (
        <img src={source} alt="preview" />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border: 1px solid #dedede;
    border-radius: 10px;
  }
`;

export const AddButton = styled.button`
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  appearance: none;
  background: #3451fa;
  border-radius: 10px;
  color: white;
  padding: 0.5rem 1rem;
  font-family: 'JetBrains Mono', serif;
  font-size: 1.2rem;
  font-weight: 400;
  z-index: 1;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.6;
  }
`;
