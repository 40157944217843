import * as React from 'react';
import styled from 'styled-components';
import Switch from 'react-ios-switch';
import { VSpace } from '@/components/spacing';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import vImage from '../../assets/v_indicator.svg';

export type IntegrationTileMode = `toggle` | `outline`;

export interface IntegrationTileProps {
  staticImage: any;
  isDisabled?: boolean;
  allowToDisable?: boolean;
  isSelected?: boolean;
  onChange?: (state: boolean) => void;
  children?: any;

  mode?: IntegrationTileMode;
}

export function IntegrationTile({
  staticImage,
  isDisabled,
  allowToDisable,
  isSelected,
  onChange,
  children,
  mode = `toggle`,
}: IntegrationTileProps) {
  const onStateChanged = (state: boolean) => {
    onChange && onChange(state);
  };

  const onWrapperClicked = (ev) => {
    if (mode === `outline` && !isSelected) {
      ev.preventDefault();
      ev.stopPropagation();
      onStateChanged(!isSelected);
    }
  };

  return (
    <Wrapper
      isCentered={!children}
      isDisabled={isDisabled}
      isSelected={isSelected}
      mode={mode}
      onClick={onWrapperClicked}
    >
      <Header>
        <Logo>{staticImage}</Logo>
        {mode === `toggle` && (
          <SmallSwitch
            style={isSelected && { opacity: 1 }}
            disabled={!allowToDisable && isSelected}
            offColor="rgba(120,120,128,0.16)"
            checked={isSelected}
            onChange={onStateChanged}
          />
        )}
      </Header>
      {children && <Separator />}
      {children && (
        <Content>
          {children}
          <VSpace value={2} />
        </Content>
      )}
      {mode === `outline` && isSelected && <SelectedBadge />}
    </Wrapper>
  );
}

interface WP {
  isCentered?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  mode?: IntegrationTileMode;
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  ${(p: WP) =>
    p?.isCentered
      ? `
  display: flex;
flex-direction: column;
justify-content: center;
align-items: start;`
      : ``}

  filter: ${(p: WP) => (p?.isDisabled ? `grayscale(90%)` : `none`)};
  opacity: ${(p: WP) => (p?.isDisabled ? `0.5` : `1`)};
  pointer-events: ${(p: WP) => (p?.isDisabled ? `none` : `auto`)};

  cursor: ${(p: WP) => (p?.mode === `outline` ? `pointer` : `default`)};

  border: ${(p: WP) =>
    p?.mode === `outline` && p?.isSelected
      ? `3px solid #003CED`
      : `3px solid rgba(0,0,0,0)`};

  text-align: start;
  user-select: none;
  border-radius: 2rem;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15),
    0 7px 18px 0 rgba(90, 201, 172, 0.12), 0 2px 21px 0 rgba(0, 0, 0, 0);
  transition: background-color 500ms ease-out, opacity 500ms ease-in;

  :hover {
    background-color: #fcfbfb;
  }
`;

const Header = styled.div`
  text-align: start;
  display: grid;
  grid-template-columns: 20rem 1fr 3rem;
  padding: 0.5rem 1rem;
`;

const Logo = styled.div``;

const Content = styled.div`
  padding: 2.8rem;
  color: #718083;
  font-size: 1.1rem;
  font-family: 'JetBrains Mono', serif;
  font-weight: bold;
  letter-spacing: 0.21px;
  line-height: 2rem;
`;

const Separator = styled.div`
  width: 90%;
  height: 1px;
  margin: -1rem 0 -0.5rem 5%;

  background: rgba(75, 85, 100, 0.21);
`;

const SmallSwitch = styled(Switch)`
  && {
    justify-self: flex-end;
    align-self: center;
    transform: scale(0.8);
    cursor: grab;
  }
`;

const SelectedBadge = styled.span`
  position: absolute;
  bottom: -1.2rem;
  right: -1.2rem;
  content: '';
  background: url(${vImage});
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 1rem;
  height: 3.2rem;
  width: 3.2rem;
`;
