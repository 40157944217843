import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { Product } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/context/model';
import { ManualProductsPicker } from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/context';
import { Pagination } from '@/components/pagination';
import { Spinner } from '@/Spinner';
import { VSpace } from '@/components/spacing';
import { getSrc } from '@/components/catalog-search/products-grid';
import { currencySymbol } from '@/utils/currencies';

const Container = styled.div`
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto-fill, 100px);
  grid-row-gap: 4rem;
  grid-column-gap: 4rem;
  max-width: 830px;
  height: 440px;
  overflow-y: scroll;
  scrollbar-width: none;
`;

const ProductImage = styled.div`
  border-radius: 22px;
  width: 100px;
  height: 100px;
`;

const ProductWrapper = styled.button`
  display: flex;
  border: none;
  background: none;
  position: relative;

  &:hover {
    ::after {
      display: ${(p: { noImage: boolean }) => (p.noImage ? `block` : `none`)};
    }
  }

  &::after {
    display: none;
    position: absolute;
    top: 20%;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09), 0 1px 4px 0 rgba(7, 6, 6, 0.04);
    max-width: 100px;
    background-color: white;
    border-radius: 15px;
    padding: 10px;
    font-family: Inter, serif;
    content: 'please choose an image for this variant';
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  &:active {
    opacity: 1;
    cursor: default;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;

  p:first-child {
    font-weight: 500;
    max-width: 100px;
    max-height: 60px;
    font-size: 1.2rem;
    overflow: scroll;
    scrollbar-width: 0;
  }

  p:nth-child(2) {
    margin: 5px 0;
  }

  p {
    margin: 0;
    text-align: center;
    font-size: 1.2rem;
  }
`;

export const ProductsGrid: FC<{
  products: Array<Product>;
  loading: boolean;
  noTotalSummary?: boolean;
}> = ({ products, loading: searchLoading, noTotalSummary }) => {
  if (products?.length === 0) {
    return null;
  }
  const { chooseProduct, page, maxPages, total, loading, setPage } =
    useContext(ManualProductsPicker);
  const symbol = currencySymbol();
  return (
    <>
      <Container>
        {!searchLoading ? (
          products.map((x) => (
            <ProductWrapper
              key={x.id}
              noImage={x?.missingVariantImageMapping}
              onClick={() => chooseProduct(x)}
            >
              <ProductImage>
                <img src={getSrc(x)} alt={x.title} />
              </ProductImage>
              <Details>
                <p>{x.title}</p>
                {!!x.mainValue && (
                  <p>
                    <strong>{x.mainValue}</strong>
                  </p>
                )}
                <p>
                  {symbol}
                  {x?.price || 0}
                </p>
              </Details>
            </ProductWrapper>
          ))
        ) : (
          <>
            <div />
            <div />
            <div />
            <div />
            <Spinner />
            <div />
          </>
        )}
      </Container>
      <VSpace value={2.5} />
      <Pagination
        value={page + 1}
        pageCount={maxPages}
        pageSize={15}
        total={total}
        loading={loading || searchLoading}
        onPageChanged={(page) => setPage(page - 1)}
        label="products"
        noTotalSummary={noTotalSummary}
      />
    </>
  );
};
