import { useContext, useMemo, useState } from 'react';
import { EditorContext } from '@/features/editor/context/editor-context';
import {
  allMetricDescriptions,
  MetricKind,
  specificTagMetrics,
} from '@/webapi/models';
import { centered } from '@/components/use-shared-element';
import { formatEnum } from '@/utils/types';

const MIN_SELECTED_GOALS = 1;
export const MAX_SELECTED_GOALS = 10;

export function useGoalsModal(setIsVisible: (value: boolean) => void) {
  const {
    experienceState: { currentExperience, setTargetingGoals },
  } = useContext(EditorContext);

  const toRect = centered(68, 85);

  const [selected, setSelected] = useState<MetricKind[]>(
    currentExperience.goals,
  );

  const onSelection = (isSelected: boolean, s: MetricKind) => {
    if (!isSelected && selected.length >= MAX_SELECTED_GOALS) {
      return;
    }

    if (isSelected && selected.length === MIN_SELECTED_GOALS) {
      return;
    }

    let clone = [...selected];
    if (!isSelected) {
      clone.push(s);
    } else {
      clone = clone.filter((seg) => seg !== s);
    }
    setSelected(clone);
  };

  const onSave = () => {
    setTargetingGoals(selected);
    setIsVisible(false);
  };

  const allGoals = useMemo(
    (): GoalModel[] =>
      [
        MetricKind.REVENUE,
        MetricKind.TRANSACTIONS,
        MetricKind.AVG_ORDER_VALUE,
        MetricKind.PER_SESSION_VALUE,
        MetricKind.SIGNUPS,
        MetricKind.CONVERSION_RATE,
        MetricKind.ADD_TO_CART_RATE,
        MetricKind.PROFIT,
        MetricKind.PROFIT_PER_SESSION,
        MetricKind.RETURNS,
        MetricKind.RETURNS_PER_SESSION,
        MetricKind.PRODUCT_DETAILS_VIEWS_RATE,
        MetricKind.CHECKOUT_RATE,
        ...specificTagMetrics(currentExperience?.tag),
      ]
        .filter((m) => m !== MetricKind.SESSIONS)
        .map((m) => ({
          kind: m,
          name: formatEnum(m),
          description: allMetricDescriptions?.get(m)?.split(`.`)?.[0],
        })),
    [currentExperience?.tag],
  );

  return {
    selected,
    onSave,
    onSelection,
    toRect,
    allGoals,
  };
}

export interface GoalModel {
  kind: MetricKind;
  name: string;
  description: string;
}
