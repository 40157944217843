import React from 'react';

export function DelSvg() {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 45 45"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.164056"
      >
        <g
          id="trash-svgrepo-com-(4)"
          transform="translate(8, 3)"
          stroke="#000000"
          strokeWidth="3.92"
        >
          <path
            d="M1.28692333,15.8776737 C1.11416067,13.61563 2.88981133,11.6842105 5.14218333,11.6842105 L23.85772,11.6842105 C26.1102467,11.6842105 27.88582,13.6156105 27.7131733,15.8776737 L26.3745333,33.4039895 C26.2196733,35.4331474 24.5398,37 22.5192733,37 L6.48064933,37 C4.46018067,37 2.780346,35.4331474 2.62537,33.4039895 L1.28692333,15.8776737 Z"
            id="Path"
          />
          <line
            x1="29"
            y1="5.84210526"
            x2="0"
            y2="5.84210526"
            id="Path"
            strokeLinecap="round"
          />
          <path
            d="M10.6333333,1.94736842 C10.6333333,0.871875789 11.4988867,0 12.5666667,0 L16.4333333,0 C17.5011133,0 18.3666667,0.871875789 18.3666667,1.94736842 L18.3666667,5.84210526 L10.6333333,5.84210526 L10.6333333,1.94736842 Z"
            id="Path"
          />
          <line
            x1="18.3666667"
            y1="19.4736842"
            x2="18.3666667"
            y2="29.2105263"
            id="Path"
            strokeLinecap="round"
          />
          <line
            x1="10.6333333"
            y1="19.4736842"
            x2="10.6333333"
            y2="29.2105263"
            id="Path"
            strokeLinecap="round"
          />
        </g>
      </g>
    </svg>
  );
}
