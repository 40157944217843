import { CachePolicies, useFetch } from 'use-http';
import useAsyncEffect from 'use-async-effect';
import { useSessionStorage } from 'react-use';
import { useDebouncedCallback } from 'use-debounce';
import { routes } from '@/webapi/routes';
import { useDetachedState } from '@/components/hooks/use-detached-state';
import { AccountDetails } from '@/features/account-context';

const STORE_STATS_STORAGE_KEY = `vsly-store-stats`;

export function useStoreStats(account: AccountDetails, debounce = 1000) {
  const { get, post } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: true,
    },
  }));

  const [, setStats, statsRef] = useDetachedState<StoreStats>({ stats: [] });
  const [dedupKeys, setDedupKeys] = useSessionStorage(
    STORE_STATS_STORAGE_KEY,
    [],
  );

  useAsyncEffect(async () => {
    if (account?.store?.alias) {
      const resp = (await get(routes.getStoreStats())) as StoreStats;
      if (resp?.stats) {
        setStats(resp);
        console.debug(`vsly`, `stats`, `getting store stats`, resp.stats);
      }
    }
  }, [account]);

  const track = async (kind: StoreStatsKind): Promise<StoreStat[]> => {
    if (statsRef?.current?.stats && isGoalReached(kind)) {
      return Promise.resolve(statsRef.current.stats);
    }
    console.debug(`vsly`, `stats`, `tracking store stat: ${kind}`);
    const resp = await post(routes.trackStoreEvent(), {
      kind,
    });

    setStats(resp);

    return Promise.resolve(resp.stats);
  };

  const trackStoreEvent =
    debounce === 0 ? track : useDebouncedCallback(track, debounce);

  const isGoalReached = (kind: StoreStatsKind): boolean => {
    if (statsRef?.current?.stats?.length > 0) {
      return (
        statsRef?.current?.stats?.filter((s) => s.kind === kind)?.[0]
          ?.reachedTarget || false
      );
    }
    return undefined;
  };

  const trackIfGoalNotReached = async (
    kind: StoreStatsKind,
    ...dedupKey: string[]
  ): Promise<boolean> => {
    if (
      [...dedupKey]
        .map((key) => dedupKeys.includes(key))
        .filter((res) => res === true)?.length > 0
    ) {
      return true;
    }
    if (!isGoalReached(kind)) {
      const resp = await trackStoreEvent(kind);
      if (dedupKey?.length > 0) setDedupKeys([...dedupKey, ...dedupKeys]);
      return resp?.filter((s) => s.kind === kind)?.[0]?.reachedTarget;
    }
    return true;
  };

  const isDuplicate = (dedupKey: string): boolean => {
    console.log(`stats`, dedupKey, dedupKeys.includes(dedupKey), dedupKeys);
    return dedupKeys.includes(dedupKey);
  };

  return {
    isGoalReached,
    trackStoreEvent,
    trackIfGoalNotReached,
    isDuplicate,
  };
}

interface StoreStats {
  stats: StoreStat[];
}

export interface StoreStat {
  kind: StoreStatsKind;
  counter: number;
  reachedTarget: boolean;
}

export enum StoreStatsKind {
  _____NA = `_____NA`,
  IS_WELCOME_QUIZ_DONE = `IS_WELCOME_QUIZ_DONE`,
  IS_WELCOME_EMAIL_INVITE_DONE = `IS_WELCOME_EMAIL_INVITE_DONE`,
  IS_WELCOME_APP_EMBED_DONE = `IS_WELCOME_APP_EMBED_DONE`,
  IS_WELCOME_DEMO_DONE = `IS_WELCOME_DEMO_DONE`,
  HAS_SAVED_ONE_EXPERIENCE = `HAS_SAVED_ONE_EXPERIENCE`,
  SHOULD_SHOW_EDITOR_HOVER_HINT_OVERLAY = `SHOULD_SHOW_EDITOR_HOVER_HINT_OVERLAY`,
}
