import React, { FC, useContext, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  InstagramAccount,
  useFBImagesApi,
} from '@/features/editor/widgets/custom-widget/inputs/background/image/facebook/fb_api';
import { Spinner } from '@/Spinner';
import { ImageEditorContext } from '@/features/editor/widgets/custom-widget/inputs/background/image/shared/context';
import { Reconnect } from '@/features/editor/widgets/custom-widget/inputs/background/image/facebook/reconnect';
import { DefaultTypography } from '@/components/typography';
import { InstagramMedia } from '@/features/editor/widgets/custom-widget/inputs/background/image/facebook/models';
import { LikesCommentsBadge } from '@/features/editor/widgets/custom-widget/inputs/background/image/facebook/likes_comments_badge';
import { InstaAccountSelect } from '@/features/editor/widgets/custom-widget/inputs/background/image/facebook/insta_account_select';

export const InstagramImages: FC<{ getModalRect: () => DOMRect }> = ({
  getModalRect,
}) => {
  const { listInstagramImages, listInstagramAccounts, nextImages, loading } =
    useFBImagesApi(true);
  const [images, setImages] = useState<Array<InstagramMedia>>([]);
  const [nextPage, setNextPage] = useState<string>(``);
  const [reconnect, setReconnect] = useState(false);
  const [accounts, setAccounts] = useState<Array<InstagramAccount>>([]);
  const [account, setAccount] = useState<InstagramAccount | null>(null);
  const [activeAccountId, setActiveAccountId] = useState<string | null>(null);

  async function loadAccountImages(accountId: string) {
    const resp = await listInstagramImages(accountId);
    const media = resp?.images;
    if (!media || media?.length === 0) {
      setImages([]);
    } else {
      setImages(getInstagramImages(media));
      setNextPage(resp.next);
    }
  }

  useEffect(() => {
    (async () => {
      const { accounts, notLoggedIn } = await listInstagramAccounts();
      if (notLoggedIn || !accounts || accounts?.length === 0) {
        setReconnect(true);
        return;
      }
      setAccounts(accounts);
      if (accounts.length === 1) {
        setActiveAccountId(accounts[0].id);
        await loadAccountImages(accounts[0].id);
      }
    })();
  }, [reconnect]);

  const onAccountSelect = async () => {
    if (account.id) {
      setActiveAccountId(account.id);
      await loadAccountImages(account.id);
    }
  };

  const loadMore = async () => {
    if (nextPage) {
      const resp = await nextImages(nextPage);
      const media = resp?.images;
      if (media?.length > 0) {
        setImages([...images, ...getInstagramImages(media)]);
        setNextPage(resp.next);
      }
    }
  };
  const { setImage } = useContext(ImageEditorContext);
  const [hoverIdx, setHoverIdx] = useState(-1);

  if (reconnect) {
    return (
      <Reconnect
        setReconnect={setReconnect}
        vendor="instagram"
        btnText="Continue to Instagram"
        text="Connect to “Instagram” to pull images from your IG account"
      />
    );
  }

  if (loading) {
    return (
      <SpinnerWrapper>
        <Spinner size={100} />
      </SpinnerWrapper>
    );
  }

  if (!activeAccountId) {
    return (
      <InstaAccountSelect
        setAccount={setAccount}
        instagramAccounts={accounts}
        account={account}
        onClick={onAccountSelect}
      />
    );
  }

  return (
    <Wrapper h={getModalRect().height}>
      <PhotosGrid id="metaImages">
        <InfiniteScroll
          dataLength={images?.length || 0}
          next={loadMore}
          hasMore
          loader=""
          scrollableTarget="metaImages"
        >
          <PhotoGrid>
            {images?.map((image, idx) => (
              <Padding>
                <ImageWrapper
                  key={image.id}
                  onClick={() => setImage(image.mediaUrl)}
                  onMouseEnter={() => setHoverIdx(idx)}
                  onMouseLeave={() => setHoverIdx(-1)}
                >
                  <LikesCommentsBadge image={image} />
                  <img src={image.mediaUrl} alt="instagram_image" />
                  {hoverIdx === idx && (
                    <AdName>{image.caption?.substring(0, 250)}</AdName>
                  )}
                </ImageWrapper>
              </Padding>
            ))}
          </PhotoGrid>
        </InfiniteScroll>
      </PhotosGrid>
    </Wrapper>
  );
};

interface P {
  h: number;
}

const Padding = styled.div`
  padding: 1rem;
`;
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${(p: P) => p.h - 150}px 5rem;

  align-items: center;
  justify-content: center;
`;

const PhotosGrid = styled.div`
  height: 100%;
  margin-top: 2rem;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;

  overflow: auto;
`;

const SpinnerWrapper = styled.div`
  div {
    margin-top: 100px;
  }

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.div`
  position: relative;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const PhotoGrid = styled.div`
  height: 100%;
  margin-top: 2rem;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;

  overflow: auto;
`;

const CreditAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(1, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
`;

const AdName = styled(DefaultTypography)`
  && {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: start;
    font-size: 1.2rem;
    color: #eaeef2;
    font-weight: 500;
    letter-spacing: 0.28px;

    padding: 2rem 1rem;

    transform-origin: 100% 100%;
    animation: ${CreditAnimation} ease-out 0.3s;
  }
`;

function getInstagramImages(media: Array<InstagramMedia>) {
  return media?.filter((i) => i.mediaType !== `VIDEO`);
}
