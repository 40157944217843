import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  AppEmbedInstructions,
  RoundButton,
} from '@/features/welcome-quiz/shared/components';
import {
  WelcomeModalContext,
  WelcomeModalStep,
} from '@/features/welcome-quiz/shared/context';
import { openLinkInNewTab } from '@/utils/browser';
import { AccountContext } from '@/features/account-context';

export function WelcomeModalEnableAppEmbed() {
  const {
    appEmbedStatus,
    refreshAppEmbedStatus,
    account: {
      store: { alias, isTestStore },
    },
  } = useContext(AccountContext);
  const { setFooter, setCurrentStep } = useContext(WelcomeModalContext);

  const [embedLink, setEmbedLink] = useState(``);
  const [clickedActivate, setClickedActivate] = useState(false);

  useEffect(() => {
    if (isTestStore) {
      onNext();
      return;
    }
    if (appEmbedStatus.isInit) {
      if (
        appEmbedStatus.isEnabled ||
        (!appEmbedStatus.isEnabled && !appEmbedStatus.deepLink)
      ) {
        onNext();
      } else {
        setClickedActivate(false);
      }

      setEmbedLink(appEmbedStatus.deepLink);
    } else {
      onNext();
    }
  }, [appEmbedStatus]);

  const onNext = () => setCurrentStep(WelcomeModalStep.BOOK_DEMO);

  const onActivate = async () => {
    if (clickedActivate) {
      setEmbedLink(``);
      refreshAppEmbedStatus(alias).then(() => {
        setClickedActivate(false);
      });
    } else {
      openLinkInNewTab(embedLink, `app-embed`);
      setClickedActivate(true);
    }
  };

  useEffect(() => {
    setFooter(
      <FooterWrapper>
        <RoundButton disabled={!embedLink} onClick={onActivate}>
          {clickedActivate ? `All set, Next` : `Activate`}
        </RoundButton>
        <RoundButton className="secondary" onClick={onNext}>
          Skip
        </RoundButton>
      </FooterWrapper>,
    );
  }, [embedLink, clickedActivate]);

  return <AppEmbedInstructions />;
}

const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;

  .secondary {
    background: transparent;
    border: 2px solid rgb(200, 202, 203);
    color: rgb(151, 160, 168);
    box-shadow: none;
  }
`;
