import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { VSpace } from '@/components/spacing';
import { BigButton } from '@/components/big-button';
import { onOpenFBOAuthPopup } from '@/features/integrations/facebook/api';
import { AccountContext } from '@/features/account-context';
import { useFBImagesApi } from '@/features/editor/widgets/custom-widget/inputs/background/image/facebook/fb_api';
import { onOpenInstagramOAuthPopup } from '@/features/integrations/instagram/api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-family: 'JetBrains Mono', serif;
  span {
    font-size: 1.7rem;
    color: #818e9a;
    font-weight: 400;
  }
`;

type Props = {
  setReconnect: (b: boolean) => any;
  vendor: 'facebook' | 'instagram';
  text?: string;
  btnText?: string;
};
export const Reconnect: FC<Props> = ({
  setReconnect,
  text,
  btnText,
  vendor,
}) => {
  const { encrypt, loading } = useFBImagesApi(false);
  const account = useContext(AccountContext);
  const alias = account?.account?.store?.alias;
  const isFacebook = vendor === `facebook`;
  useEffect(() => {
    const bc = new BroadcastChannel(isFacebook ? `meta_auth` : `insta_auth`);
    bc.onmessage = () => {
      setReconnect(false);
    };
    return () => bc.close();
  }, []);
  const onClick = isFacebook
    ? onOpenFBOAuthPopup(encrypt, alias)
    : onOpenInstagramOAuthPopup(encrypt, alias);
  return (
    <Container>
      <VSpace value={5} />
      <StaticImage
        src="./link.png"
        alt="connect"
        placeholder="none"
        loading="eager"
        height={120}
      />
      <VSpace value={7} />
      <span>
        {text || `Connect to "Meta" to pull images from running campaigns`}
      </span>
      <VSpace value={7} />
      <FooterButton
        disabled={loading}
        color="white"
        background="#0062FF"
        noTransform
        size="large"
        onClick={onClick}
      >
        {btnText || `Continue to Facebook`}
      </FooterButton>
    </Container>
  );
};

const FooterButton = styled(BigButton)`
  && {
    width: 35rem !important;
    font-size: 1.3rem;
  }
`;
