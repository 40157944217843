/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flexbox } from '@/components/flex';
import { DefaultTypography } from '@/components/typography';
import { EditorContext } from '@/features/editor/context/editor-context';
import { Experience } from '@/webapi/use-experience-api';

export const AllocationWrapper = styled(Flexbox)`
  && {
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    background: ${(p) => p.bg || `#f2f4f6`};
    border-radius: 1rem;
    border: 2px solid
      ${(p) => (p.isActive ? `#0085FF;` : p.bg ? `#cdd3d9` : `transparent`)};
  }
`;

export const AllocationText = styled(DefaultTypography)`
  && {
    display: flex;
    align-items: center;
    padding: 1.5rem 2rem;
    font-family: 'Inter', serif;
    font-size: 1.4rem;
    font-weight: 600;
    color: #5b656e;
  }
`;

export function formatAllocation(num: number): string {
  if (Number.isNaN(num) || num < 0) {
    return `0%`;
  }
  return `${num}%`;
}

export function useControlAllocation(chances: Array<number>) {
  const edCtx = useContext(EditorContext);
  const variantChances =
    chances ||
    edCtx?.experienceState?.currentExperience?.variants?.map((x) => x.chance);

  const calcControl = () => 100 - variantChances.reduce((pv, cv) => pv + cv);

  const [control, setControl] = useState(calcControl());

  useEffect(() => {
    setControl(calcControl());
  }, [variantChances?.join(``)]);

  return formatAllocation(control);
}

export function handleInvalidAlloc(currentExperience: Experience) {
  const isInvalid =
    currentExperience.variants?.reduce((p, c) => p + c.chance, 0) > 100;
  if (isInvalid) {
    toast(`Variations must sum to 100%`, {
      theme: `colored`,
      type: `error`,
      className: css({ fontFamily: `JetBrains Mono, Serif` }),
    });
  }
  return isInvalid;
}
