import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { BsCheckLg, BsPlusLg } from 'react-icons/bs';
import {
  ManualProductsPicker,
  SlotData,
} from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/context';

interface P {
  active: boolean;
}
const Container = styled.button`
  border: none;
  width: 177px;
  height: 188.5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #f3f6f7;
  border-radius: 21.9px;
  border: ${({ active }: P) => (active ? `2px solid blue` : `none`)};
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  &:active {
    opacity: 1;
    cursor: default;
  }
  svg {
    min-height: 40px;
    margin-top: 1rem;
  }
  p {
    color: #5b656e;
    user-select: none;
    font-family: 'JetBrains Mono', serif;
    font-weight: 600;
    font-size: 1.5rem;
    margin: 10px 0 0 0;
    max-width: 150px;
    overflow-y: scroll;
    scrollbar-width: none;
  }
`;

export const Slot: FC<{ data: SlotData }> = ({ data }) => {
  const { setOpenSlot } = useContext(ManualProductsPicker);

  const { product } = data;
  const pid = product?.id;
  const title = product?.title;
  return (
    <Container
      type="button"
      onClick={() => setOpenSlot(data.id)}
      active={!!title}
    >
      {pid ? (
        <BsCheckLg size={40} color="blue" />
      ) : (
        <BsPlusLg size={40} color="#A6AFB8" />
      )}
      <p>
        {title ? (
          <span>
            {title}
            {` `}
            {product?.mainValue && (
              <span style={{ fontWeight: 800 }}>
                <br />
                {product?.mainValue}
              </span>
            )}
          </span>
        ) : (
          `slot ${data.id}`
        )}
      </p>
    </Container>
  );
};
