import React, { MutableRefObject, useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { BackgroundColorInput } from '@/features/editor/widgets/custom-widget/inputs/background/color';
import { ColorTile } from '@/features/editor/widgets/custom-widget/inputs/background/color/components/color-tile';

interface BackgroundColorProps extends GenericInputProps {
  defaultColor: string;
  openColorTooltip: MutableRefObject<boolean>;
  revertToActive: () => void;
  isActive: boolean;
}

export function BackgroundColorHeader({
  onValuesChanged,
  defaultColor,
  openColorTooltip,
  revertToActive,
  isActive,
  spec,
}: BackgroundColorProps) {
  const [color, setColor] = useState(defaultColor);
  const [showPicker, setShowPicker] = useState(openColorTooltip.current);

  const onCloseCb = () => {
    if (showPicker && color === defaultColor) {
      revertToActive();
    }
  };

  const handleChange = (colorStr) => {
    setColor(colorStr);
    onValuesChanged(`value`, colorStr);
  };

  const toggleColorPicker = () => {
    onCloseCb();
    setShowPicker(!showPicker);
  };
  const onClickOutside = () => {
    toggleColorPicker();
  };
  return (
    <Popover
      isOpen={showPicker}
      positions={[`bottom`, `top`]}
      onClickOutside={onClickOutside}
      containerStyle={{ zIndex: `10000` }}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="white"
          arrowSize={20}
          arrowStyle={{
            opacity: 1,
          }}
          style={{
            position: `relative`,
            padding: 0,
            background: `white`,
            width: `60rem`,
            borderRadius: `1rem`,
            boxShadow: `0 22px 154px 0 rgba(0,0,0,0.17), 0 18px 46px 0 rgba(177,217,203,0.18), 0 8px 24px 0 rgba(0,0,0,0.12)`,
          }}
        >
          <BackgroundColorInput
            isActive={isActive}
            defaultValue={color}
            onCloseClicked={onClickOutside}
            onChange={handleChange}
            spec={spec}
          />
        </ArrowContainer>
      )}
    >
      <ForwardRefComponent>
        <Wrapper>
          <ColorTile noLabel colorStyleStr={color} onClick={onClickOutside} />
        </Wrapper>
      </ForwardRefComponent>
    </Popover>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 3rem;
  grid-template-columns: 4rem;
  align-items: center;
  justify-content: flex-end;
`;

const ForwardRefComponent = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>((props, ref) => (
  <div ref={ref} onClick={props.onClick}>
    {props.children}
  </div>
));
