import { QBItemSelection } from '@/components/query-builder/models';

export const countryCodes = [
  { name: `Afghanistan`, key: `AF` },
  { name: `Albania`, key: `AL` },
  { name: `Algeria`, key: `DZ` },
  { name: `American Samoa`, key: `AS` },
  { name: `Andorra`, key: `AD` },
  { name: `Angola`, key: `AO` },
  { name: `Anguilla`, key: `AI` },
  { name: `Antarctica`, key: `AQ` },
  { name: `Antigua and Barbuda`, key: `AG` },
  { name: `Argentina`, key: `AR` },
  { name: `Armenia`, key: `AM` },
  { name: `Aruba`, key: `AW` },
  { name: `Australia`, key: `AU` },
  { name: `Austria`, key: `AT` },
  { name: `Azerbaijan`, key: `AZ` },
  { name: `Bahamas`, key: `BS` },
  { name: `Bahrain`, key: `BH` },
  { name: `Bangladesh`, key: `BD` },
  { name: `Barbados`, key: `BB` },
  { name: `Belarus`, key: `BY` },
  { name: `Belgium`, key: `BE` },
  { name: `Belize`, key: `BZ` },
  { name: `Benin`, key: `BJ` },
  { name: `Bermuda`, key: `BM` },
  { name: `Bhutan`, key: `BT` },
  { name: `Bolivia`, key: `BO` },
  { name: `Bonaire`, key: `BQ` },
  { name: `Bosnia and Herzegovina`, key: `BA` },
  { name: `Botswana`, key: `BW` },
  { name: `Bouvet Island`, key: `BV` },
  { name: `Brazil`, key: `BR` },
  { name: `British Indian Ocean Territory`, key: `IO` },
  { name: `Brunei Darussalam`, key: `BN` },
  { name: `Bulgaria`, key: `BG` },
  { name: `Burkina Faso`, key: `BF` },
  { name: `Burundi`, key: `BI` },
  { name: `Cabo Verde`, key: `CV` },
  { name: `Cambodia`, key: `KH` },
  { name: `Cameroon`, key: `CM` },
  { name: `Canada`, key: `CA` },
  { name: `Cayman Islands`, key: `KY` },
  { name: `Central African Republic`, key: `CF` },
  { name: `Chad`, key: `TD` },
  { name: `Chile`, key: `CL` },
  { name: `China`, key: `CN` },
  { name: `Christmas Island`, key: `CX` },
  { name: `Cocos  Islands`, key: `CC` },
  { name: `Colombia`, key: `CO` },
  { name: `Comoros`, key: `KM` },
  { name: `Congo`, key: `CD` },
  { name: `Congo`, key: `CG` },
  { name: `Cook Islands`, key: `CK` },
  { name: `Costa Rica`, key: `CR` },
  { name: `Croatia`, key: `HR` },
  { name: `Cuba`, key: `CU` },
  { name: `Curaçao`, key: `CW` },
  { name: `Cyprus`, key: `CY` },
  { name: `Czechia`, key: `CZ` },
  { name: `Côte d'Ivoire`, key: `CI` },
  { name: `Denmark`, key: `DK` },
  { name: `Djibouti`, key: `DJ` },
  { name: `Dominica`, key: `DM` },
  { name: `Dominican Republic`, key: `DO` },
  { name: `Ecuador`, key: `EC` },
  { name: `Egypt`, key: `EG` },
  { name: `El Salvador`, key: `SV` },
  { name: `Equatorial Guinea`, key: `GQ` },
  { name: `Eritrea`, key: `ER` },
  { name: `Estonia`, key: `EE` },
  { name: `Eswatini`, key: `SZ` },
  { name: `Ethiopia`, key: `ET` },
  { name: `Falkland Islands  [Malvinas]`, key: `FK` },
  { name: `Faroe Islands`, key: `FO` },
  { name: `Fiji`, key: `FJ` },
  { name: `Finland`, key: `FI` },
  { name: `France`, key: `FR` },
  { name: `French Guiana`, key: `GF` },
  { name: `French Polynesia`, key: `PF` },
  { name: `French Southern Territories`, key: `TF` },
  { name: `Gabon`, key: `GA` },
  { name: `Gambia`, key: `GM` },
  { name: `Georgia`, key: `GE` },
  { name: `Germany`, key: `DE` },
  { name: `Ghana`, key: `GH` },
  { name: `Gibraltar`, key: `GI` },
  { name: `Greece`, key: `GR` },
  { name: `Greenland`, key: `GL` },
  { name: `Grenada`, key: `GD` },
  { name: `Guadeloupe`, key: `GP` },
  { name: `Guam`, key: `GU` },
  { name: `Guatemala`, key: `GT` },
  { name: `Guernsey`, key: `GG` },
  { name: `Guinea`, key: `GN` },
  { name: `Guinea-Bissau`, key: `GW` },
  { name: `Guyana`, key: `GY` },
  { name: `Haiti`, key: `HT` },
  { name: `Heard Island and McDonald Islands`, key: `HM` },
  { name: `Holy See`, key: `VA` },
  { name: `Honduras`, key: `HN` },
  { name: `Hong Kong`, key: `HK` },
  { name: `Hungary`, key: `HU` },
  { name: `Iceland`, key: `IS` },
  { name: `India`, key: `IN` },
  { name: `Indonesia`, key: `ID` },
  { name: `Iran`, key: `IR` },
  { name: `Iraq`, key: `IQ` },
  { name: `Ireland`, key: `IE` },
  { name: `Isle of Man`, key: `IM` },
  { name: `Israel`, key: `IL` },
  { name: `Italy`, key: `IT` },
  { name: `Jamaica`, key: `JM` },
  { name: `Japan`, key: `JP` },
  { name: `Jersey`, key: `JE` },
  { name: `Jordan`, key: `JO` },
  { name: `Kazakhstan`, key: `KZ` },
  { name: `Kenya`, key: `KE` },
  { name: `Kiribati`, key: `KI` },
  { name: `Korea`, key: `KP` },
  { name: `Korea`, key: `KR` },
  { name: `Kuwait`, key: `KW` },
  { name: `Kyrgyzstan`, key: `KG` },
  { name: `Lao People's Democratic Republic`, key: `LA` },
  { name: `Latvia`, key: `LV` },
  { name: `Lebanon`, key: `LB` },
  { name: `Lesotho`, key: `LS` },
  { name: `Liberia`, key: `LR` },
  { name: `Libya`, key: `LY` },
  { name: `Liechtenstein`, key: `LI` },
  { name: `Lithuania`, key: `LT` },
  { name: `Luxembourg`, key: `LU` },
  { name: `Macao`, key: `MO` },
  { name: `Madagascar`, key: `MG` },
  { name: `Malawi`, key: `MW` },
  { name: `Malaysia`, key: `MY` },
  { name: `Maldives`, key: `MV` },
  { name: `Mali`, key: `ML` },
  { name: `Malta`, key: `MT` },
  { name: `Marshall Islands`, key: `MH` },
  { name: `Martinique`, key: `MQ` },
  { name: `Mauritania`, key: `MR` },
  { name: `Mauritius`, key: `MU` },
  { name: `Mayotte`, key: `YT` },
  { name: `Mexico`, key: `MX` },
  { name: `Micronesia`, key: `FM` },
  { name: `Moldova`, key: `MD` },
  { name: `Monaco`, key: `MC` },
  { name: `Mongolia`, key: `MN` },
  { name: `Montenegro`, key: `ME` },
  { name: `Montserrat`, key: `MS` },
  { name: `Morocco`, key: `MA` },
  { name: `Mozambique`, key: `MZ` },
  { name: `Myanmar`, key: `MM` },
  { name: `Namibia`, key: `NA` },
  { name: `Nauru`, key: `NR` },
  { name: `Nepal`, key: `NP` },
  { name: `Netherlands`, key: `NL` },
  { name: `New Caledonia`, key: `NC` },
  { name: `New Zealand`, key: `NZ` },
  { name: `Nicaragua`, key: `NI` },
  { name: `Niger`, key: `NE` },
  { name: `Nigeria`, key: `NG` },
  { name: `Niue`, key: `NU` },
  { name: `Norfolk Island`, key: `NF` },
  { name: `Northern Mariana Islands`, key: `MP` },
  { name: `Norway`, key: `NO` },
  { name: `Oman`, key: `OM` },
  { name: `Pakistan`, key: `PK` },
  { name: `Palau`, key: `PW` },
  { name: `Palestine, State of`, key: `PS` },
  { name: `Panama`, key: `PA` },
  { name: `Papua New Guinea`, key: `PG` },
  { name: `Paraguay`, key: `PY` },
  { name: `Peru`, key: `PE` },
  { name: `Philippines`, key: `PH` },
  { name: `Pitcairn`, key: `PN` },
  { name: `Poland`, key: `PL` },
  { name: `Portugal`, key: `PT` },
  { name: `Puerto Rico`, key: `PR` },
  { name: `Qatar`, key: `QA` },
  { name: `Republic of North Macedonia`, key: `MK` },
  { name: `Romania`, key: `RO` },
  { name: `Russian Federation`, key: `RU` },
  { name: `Rwanda`, key: `RW` },
  { name: `Réunion`, key: `RE` },
  { name: `Saint Barthélemy`, key: `BL` },
  { name: `Saint Helena, Ascension and Tristan da Cunha`, key: `SH` },
  { name: `Saint Kitts and Nevis`, key: `KN` },
  { name: `Saint Lucia`, key: `LC` },
  { name: `Saint Martin`, key: `MF` },
  { name: `Saint Pierre and Miquelon`, key: `PM` },
  { name: `Saint Vincent and the Grenadines`, key: `VC` },
  { name: `Samoa`, key: `WS` },
  { name: `San Marino`, key: `SM` },
  { name: `Sao Tome and Principe`, key: `ST` },
  { name: `Saudi Arabia`, key: `SA` },
  { name: `Senegal`, key: `SN` },
  { name: `Serbia`, key: `RS` },
  { name: `Seychelles`, key: `SC` },
  { name: `Sierra Leone`, key: `SL` },
  { name: `Singapore`, key: `SG` },
  { name: `Sint Maarten`, key: `SX` },
  { name: `Slovakia`, key: `SK` },
  { name: `Slovenia`, key: `SI` },
  { name: `Solomon Islands`, key: `SB` },
  { name: `Somalia`, key: `SO` },
  { name: `South Africa`, key: `ZA` },
  { name: `South Georgia and the South Sandwich Islands`, key: `GS` },
  { name: `South Sudan`, key: `SS` },
  { name: `Spain`, key: `ES` },
  { name: `Sri Lanka`, key: `LK` },
  { name: `Sudan`, key: `SD` },
  { name: `Suriname`, key: `SR` },
  { name: `Svalbard and Jan Mayen`, key: `SJ` },
  { name: `Sweden`, key: `SE` },
  { name: `Switzerland`, key: `CH` },
  { name: `Syrian Arab Republic`, key: `SY` },
  { name: `Taiwan`, key: `TW` },
  { name: `Tajikistan`, key: `TJ` },
  { name: `Tanzania, United Republic of`, key: `TZ` },
  { name: `Thailand`, key: `TH` },
  { name: `Timor-Leste`, key: `TL` },
  { name: `Togo`, key: `TG` },
  { name: `Tokelau`, key: `TK` },
  { name: `Tonga`, key: `TO` },
  { name: `Trinidad and Tobago`, key: `TT` },
  { name: `Tunisia`, key: `TN` },
  { name: `Turkey`, key: `TR` },
  { name: `Turkmenistan`, key: `TM` },
  { name: `Turks and Caicos Islands`, key: `TC` },
  { name: `Tuvalu`, key: `TV` },
  { name: `Uganda`, key: `UG` },
  { name: `Ukraine`, key: `UA` },
  { name: `United Arab Emirates`, key: `AE` },
  { name: `United Kingdom`, key: `GB` },
  { name: `USA`, key: `US` },
  { name: `Uruguay`, key: `UY` },
  { name: `Uzbekistan`, key: `UZ` },
  { name: `Vanuatu`, key: `VU` },
  { name: `Venezuela`, key: `VE` },
  { name: `Viet Nam`, key: `VN` },
  { name: `Virgin Islands`, key: `VG` },
  { name: `Virgin Islands`, key: `VI` },
  { name: `Wallis and Futuna`, key: `WF` },
  { name: `Western Sahara`, key: `EH` },
  { name: `Yemen`, key: `YE` },
  { name: `Zambia`, key: `ZM` },
  { name: `Zimbabwe`, key: `ZW` },
  { name: `Åland Islands`, key: `AX` },
];

export function getCountryCode(qbItemSelections: QBItemSelection[]): string {
  return (
    qbItemSelections
      ?.find((x) => x?.qbProps?.caption === `Country`)
      ?.values?.find((x) => x?.op === `is`)?.value || ``
  );
}
