import { ArrowContainer, Popover } from 'react-tiny-popover';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { BigButton } from '@/components/big-button';

export interface PopConfirmProps {
  children?: any;
  payload?: any;
  text?: string;
  onConfirm?: (payload: any) => Promise<void>;
  onDiscard?: () => void;
}

export function PopConfirm({
  children,
  onConfirm,
  onDiscard,
  payload,
  text,
}: PopConfirmProps) {
  const [isOpen, setIsOpen] = useState(false);

  const showPopover = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsOpen(true);
  };

  const hidePopover = () => {
    setIsOpen(false);
  };

  const onPopoverDelete = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (onConfirm) {
      await onConfirm(payload);
    }
    hidePopover();
  };

  const onPopoverDiscard = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    onDiscard && onDiscard();
    hidePopover();
  };

  return (
    <Popover
      containerStyle={{ zIndex: `999999` }}
      isOpen={isOpen}
      positions={[`bottom`, `top`, `left`, `right`]}
      onClickOutside={hidePopover}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="white"
          arrowSize={10}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
          arrowStyle={{
            top: `1px`,
            filter: `drop-shadow(0px -1px 0px rgba(213, 217, 220, 0.36))`,
            opacity: 1,
          }}
        >
          <AreYouSure
            onNo={onPopoverDiscard}
            onYes={onPopoverDelete}
            text={text}
          />
        </ArrowContainer>
      )}
    >
      <div onClick={showPopover}>{children}</div>
    </Popover>
  );
}

const AreYouSureContainer = styled.div`
  background: white;
  border: 1px solid rgba(213, 217, 220, 0.36);
  border-radius: 0.4rem;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15),
    0 6px 7px 0 rgba(151, 210, 206, 0.22), 0 2px 7px 0 rgba(0, 0, 0, 0);
  padding: 2rem;

  max-width: 25rem;

  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  .text {
    font-family: Inter, serif;
    font-size: 1.2rem;
    line-height: 1.5;
  }

  .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
`;

interface AreYouSureProps {
  onNo: (ev?: any) => void;
  onYes: (ev?: any) => void;
  text?: string;
}

const AreYouSure: FC<AreYouSureProps> = ({ onNo, onYes, text }) => (
  <AreYouSureContainer>
    <div className="text">{text || `Are you sure ?`}</div>
    <div className="buttons">
      <BigButton
        br="0.4rem"
        border="1px solid #C8CACB"
        background="white"
        color="#97A0A8"
        noShadow
        noTransform
        size="small"
        fillWidth
        onClick={onNo}
      >
        Discard
      </BigButton>
      <BigButton
        br="0.4rem"
        onClick={onYes}
        border="1px solid black"
        noTransform
        size="small"
        fillWidth
        noShadow
      >
        Yes
      </BigButton>
    </div>
  </AreYouSureContainer>
);
