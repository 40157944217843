import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { ProductFilters } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/filter/filter';
import { GradientModal } from '@/components/gradient-modal';
import { MainHeader } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/header';
import { SlotsList } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/slot';
import { ProductSearch } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/search';
import { gradientProps } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/misc/gradientProps';
import { ModalFooter } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/footer';
import { ManualImageSelect } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/search/imageSelect';
import { StrategyPerSlotContext } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/context/context';
import { VSpace } from '@/components/spacing';

type Props = {
  isOpen: boolean;
  close: () => void;
  fromRef: React.MutableRefObject<any>;
};

export const StrategyPerSlot: FC<Props> = ({ isOpen, fromRef, close }) => {
  const ctx = useContext(StrategyPerSlotContext);
  return (
    <GradientModal
      {...gradientProps}
      isVisible={isOpen}
      fromRef={fromRef}
      onClose={close}
      noScroll={
        (ctx.isProductSearchOpen || ctx.state.slots.length < 13) &&
        !ctx.isManualImageSelectionOpen
      }
      header={<MainHeader />}
      footer={<ModalFooter close={close} />}
    >
      <Container>
        <Grid
          mt={
            ctx.isProductSearchOpen ||
            ctx.isProductCondOpen ||
            ctx.isManualImageSelectionOpen
              ? 3
              : 8
          }
        >
          <SlotsList />
          <VSpace value={3} />
          <VSpace value={3} />
          <VSpace value={3} />
        </Grid>
        <ProductSearch />
        <ProductFilters />
        <ManualImageSelect />
      </Container>
    </GradientModal>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-top: ${(p) => p.mt}rem;
`;
