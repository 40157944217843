import React from 'react';
import styled from 'styled-components';
import produce from 'immer';
import { DefaultTypography } from '@/components/typography';
import { VSpace } from '@/components/spacing';
import {
  LinearGradientColor,
  RadialGradientColor,
  SolidColor,
} from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/models';
import { ColorTile } from '@/features/editor/widgets/custom-widget/inputs/background/color/components/color-tile';
import { generateStyleStringFromColor } from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/utils';

export interface ColorFavoritesPickerProps {
  favorites: (SolidColor | LinearGradientColor | RadialGradientColor)[];
  setFavourites: (
    color: (SolidColor | LinearGradientColor | RadialGradientColor)[],
  ) => void;
  updateColor: (
    color: SolidColor | LinearGradientColor | RadialGradientColor,
  ) => void;
  currentColor: SolidColor | LinearGradientColor | RadialGradientColor;
}

export function ColorFavoritesPicker({
  favorites,
  setFavourites,
  updateColor,
  currentColor,
}: ColorFavoritesPickerProps) {
  const onSaveColor = () => {
    const updated = produce(favorites, (draft) => {
      const styleStr = generateStyleStringFromColor(currentColor);
      const alreadyExists = draft.filter(
        (c) => generateStyleStringFromColor(c) === styleStr,
      );
      if (alreadyExists.length > 0) return;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      draft.push({ ...currentColor });
    });
    setFavourites(updated);
  };

  const onColorClicked = ({ event, color }) => {
    if (event.detail === 1) {
      updateColor(color);
    } else if (event.detail === 2) {
      const updated = produce(favorites, (draft) => {
        const styleStr = generateStyleStringFromColor(color);
        return draft.filter(
          (c) => !(generateStyleStringFromColor(c) === styleStr),
        );
      });
      setFavourites(updated);
    }
  };

  const isSelectedColor = (
    c: SolidColor | LinearGradientColor | RadialGradientColor,
  ): boolean =>
    generateStyleStringFromColor(c) ===
    generateStyleStringFromColor(currentColor);

  return (
    <Wrapper>
      <Title>Favorites</Title>
      <VSpace value={1} />
      <ColorsList>
        {favorites.map((c) => {
          const styleStr = generateStyleStringFromColor(c);
          return (
            <ColorTile
              key={styleStr}
              onClick={onColorClicked}
              noLabel
              color={c}
              colorStyleStr={styleStr}
              selected={isSelectedColor(c)}
              tileStyle="circle"
            />
          );
        })}
        <AddColorTile onClick={onSaveColor}>+</AddColorTile>
      </ColorsList>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  &::after {
    content: '';
    display: block;
    width: 4rem;
    height: 4rem;
    position: absolute;
    right: 6px;
    transform: rotate(270deg);
    bottom: 0px;
    background: linear-gradient(
      rgba(239, 215, 73, 0) 0%,
      rgb(255, 255, 255) 100%
    );
  }
`;

const Title = styled(DefaultTypography)`
  && {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: -0.33px;
    color: #a6afb8;
  }
`;

const ColorsList = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  max-width: 54rem;
  overflow-x: scroll;
  > div {
    min-width: 4rem;
    width: 4rem;
    height: 4rem;
    margin: 0 1rem 0 0;
  }
`;

const AddColorTile = styled.div`
  user-select: none;
  border: 1px solid #dedede;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #c0bfbf;
  font-size: 3rem;
  padding: 0 0 0.45rem 0;
  cursor: pointer;

  transition: background 300ms ease-in-out;

  :hover {
    background: #f3f3f3;
  }
`;
