import * as React from 'react';
import {
  BackModalHeader,
  Footer,
} from '@/features/editor/widgets/shared/modals/commons';
import { GradientModal } from '@/components/gradient-modal';
import {
  TriggerView,
  useTriggersModal,
} from '@/features/editor/widgets/shared/modals/trigger/use-triggers-modal';
import { SelectTrigger } from '@/features/editor/widgets/shared/modals/trigger/select-trigger';
import { KlavioEventSelect } from '@/features/editor/widgets/shared/modals/trigger/klavio-event-select';
import { conditional } from '@/utils/conditional';
import { JsFunctionSelect } from '@/features/editor/widgets/shared/modals/trigger/js-function-select';

export interface TriggersModalProps {
  fromRef: React.MutableRefObject<HTMLElement>;
  isVisible: boolean;
  setIsVisible: (state: boolean) => void;
}

export function TriggersModal({
  fromRef,
  isVisible,
  setIsVisible,
}: TriggersModalProps) {
  const {
    onElementSelectorChanged,
    onTimeoutChanged,
    onEventOnceChanged,
    onCodeChanged,
    selected,
    onSelection,
    onSave,
    toRect,
    view,
    setView,
    step,
    setStep,
    setEvent,
    event,
  } = useTriggersModal(setIsVisible);

  let header = <span>When do you want the experience to be shown?</span>;
  if (view === TriggerView.KLAVIO) {
    header = (
      <BackModalHeader
        header="On Klaviyo Event"
        back={() => setView(TriggerView.SELECT)}
      />
    );
  } else if (view === TriggerView.JAVASCRIPT_FUNCTION) {
    header = (
      <BackModalHeader
        header="Custom Code"
        back={() => setView(TriggerView.SELECT)}
      />
    );
  }
  return (
    <GradientModal
      closeTop="0.5rem"
      closeRight="2.5rem"
      fromStyle={{
        borderRadius: `5rem`,
        backgroundColor: `#DEDEDE`,
        padding: `2rem 0 0 0`,
      }}
      toStyle={{
        borderRadius: `1rem`,
        backgroundColor: `#FFFFFF`,
        padding: `2rem 0 0 0`,
      }}
      isVisible={isVisible}
      showBackdrop
      targetPosAndSize={toRect}
      fromRef={fromRef}
      overflowTop={{ heightInRem: 8 }}
      overflowBottom={{ heightInRem: 8, turnPointInPercent: 30 }}
      onClose={() => setIsVisible(false)}
      header={header}
      footer={
        <Footer
          text={view === TriggerView.SELECT ? `Select` : `Save`}
          onClick={onSave}
        />
      }
    >
      {conditional(
        <SelectTrigger
          selected={selected}
          onSelection={onSelection}
          onTimeoutChange={onTimeoutChanged}
          onSelectorChange={onElementSelectorChanged}
          onEventOnceChanged={onEventOnceChanged}
        />,
        view === TriggerView.KLAVIO,
        <KlavioEventSelect
          event={event}
          setEvent={setEvent}
          step={step}
          setStep={setStep}
        />,
        view === TriggerView.JAVASCRIPT_FUNCTION,
        <JsFunctionSelect
          defaultValue={selected.code}
          onChange={onCodeChanged}
        />,
      )}
    </GradientModal>
  );
}
