import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { BackgroundColorContext } from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/context';
import { ColorTile } from './color-tile';
import { ColorTextInput } from '@/features/editor/widgets/custom-widget/inputs/background/color/components/color-text-input';
import {
  ColorMode,
  SolidColor,
} from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/models';
import {
  generateStyleStringFromColor,
  hexToRgb,
  rgbToHex,
} from '@/features/editor/widgets/custom-widget/inputs/background/color/shared/utils';

export function ColorPreview() {
  const { mode, solidColor, linearColor, radialColor, updateSolidColor } =
    useContext(BackgroundColorContext);
  const hexColor = useCallback(
    (color: SolidColor) => rgbToHex(color.red, color.green, color.blue),
    [solidColor],
  );

  const currentColor = useMemo(() => {
    if (mode === ColorMode.SOLID) return solidColor;
    if (mode === ColorMode.LINEAR) return linearColor;
    if (mode === ColorMode.RADIAL) return radialColor;
    return undefined;
  }, [mode, solidColor, linearColor, radialColor]);

  const [hex, setHex] = useState(hexColor(solidColor));

  function handleColorChange(val: string, b: string) {
    let number: number;
    let isvalid: boolean;
    if (b === `alpha`) {
      if (val.includes(`.`)) {
        number = parseFloat(val);
      } else if (val.length > 1) {
        const strings = val?.split(``);
        strings?.splice(1, 0, `.`);
        number = parseFloat(strings.join(``));
      } else {
        number = parseInt(val, 10);
      }
      isvalid = number >= 0 && number <= 1;
    } else {
      number = parseInt(val, 10);
      isvalid = number >= 0 && number <= 255;
    }

    if (isvalid) {
      const color = {};
      color[b] = number;
      updateSolidColor({
        ...solidColor,
        ...color,
      });
      setHex(hexColor(solidColor));
    }
  }
  useEffect(() => {
    setHex(hexColor(solidColor));
  }, [solidColor.red, solidColor.green, solidColor.blue]);

  return (
    <Wrapper>
      <ColorTile
        colorStyleStr={generateStyleStringFromColor(currentColor)}
        color={currentColor}
        tileStyle="circle"
      />
      <ColorTextInput
        label="HEX"
        value={hex}
        onChange={(value) => {
          setHex(value);
          const rgb = hexToRgb(value);
          if (rgb?.valid && value.length >= 6) {
            updateSolidColor({
              red: rgb?.red,
              green: rgb?.green,
              blue: rgb?.blue,
              alpha: rgb?.alpha,
            });
          }
        }}
      />
      <RGBWrapper>
        <ColorTextInput
          label="R"
          value={`${solidColor.red}`}
          onChange={(val) => {
            handleColorChange(val, `red`);
          }}
        />
        <ColorTextInput
          label="G"
          value={`${solidColor.green}`}
          onChange={(val) => {
            handleColorChange(val, `green`);
          }}
        />
        <ColorTextInput
          label="B"
          value={`${solidColor.blue}`}
          onChange={(val) => {
            handleColorChange(val, `blue`);
          }}
        />
        <ColorTextInput
          label="ALPHA"
          value={`${solidColor.alpha}`}
          onChange={(val) => {
            handleColorChange(val, `alpha`);
          }}
        />
      </RGBWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 3.5rem 2fr 4fr;
  grid-gap: 1.5rem;
`;

const RGBWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0.5rem;
`;
