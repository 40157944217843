import React, { FC } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { breakpoints } from '@/components/responsive';
import { VisuallyLogo } from '@/features/strapi/components/logo';

export const UnsupportedDevice: FC<{
  pathname: string;
}> = ({ pathname }) => {
  const isEmailLanding = pathname.startsWith(`/welcome`);
  const title = isEmailLanding
    ? `Thanks for confirming your email.`
    : `Display size not supported`;
  const paragraph = isEmailLanding
    ? `
Unfortunately, our dashboard is not supported on mobile a device. Please open your registration link on a desktop computer.`
    : `Unfortunately, our dashboard is not supported on a mobile device. Please open it on a desktop computer.`;
  return (
    <UnsupportedOnMobile>
      <div style={{ width: `150px` }}>
        <VisuallyLogo wide />
      </div>
      <Title>{title}</Title>
      <Paragraph>{paragraph}</Paragraph>
      <StaticImage
        placeholder="none"
        width={300}
        src="../assets/unsupported_device.svg"
        alt="unsupported_device"
      />
    </UnsupportedOnMobile>
  );
};

const Title = styled.p`
  font-family: 'JetBrains Mono', serif;
  color: #000;
  font-weight: 800;
  font-size: 24px;
`;

const Paragraph = styled.p`
  font-family: Inter, serif;
  font-size: 17px;
  font-weight: 400;
  color: #6b6b6b;
  margin-bottom: 50px;
`;

const UnsupportedOnMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px auto 0 auto;
  text-align: center;
  max-width: 350px;

  ${breakpoints.up(`md`)} {
    display: none;
  }
`;
