import styled from 'styled-components';
import { ComponentSize, fontSizeOf, paddingOf } from '@/styles/sizes';

export const SmallButton = styled.button`
  appearance: none;
  outline: none;
  text-decoration: none;
  border: none;
  cursor: pointer;

  width: ${(props: SmallButtonProps) => (props.fillWidth ? `100%` : `auto`)};

  padding: ${(props: SmallButtonProps) => paddingOf(props.size)};
  border-radius: 5rem;

  font-size: ${(props: SmallButtonProps) => fontSizeOf(props.size)};
  font-weight: bold;

  box-shadow: ${(props: SmallButtonProps) =>
    props.noShadow
      ? `none`
      : `0 1px 1px 0 rgba(0, 0, 0, 0.15), 0 6px 14px 0 rgba(151, 210, 206, 0.22), 0 12px 14px 0 rgba(0, 0, 0, 0);`};

  color: ${(props: SmallButtonProps) =>
    props.color ? props.color : `#3451FA`};

  background: ${(props: SmallButtonProps) =>
    props.background ? props.background : `#FFFFFF`};

  text-transform: ${(props: SmallButtonProps) =>
    props.noTransform ? `none` : `uppercase`};

  font-family: ${(props: SmallButtonProps) =>
    props.fontFamily ? props.fontFamily : `inherit`};

  display: flex;
  align-items: center;

  //:hover {
  //  opacity: 0.8; //TODO
  //}
  //
  //:active {
  //  opacity: 0.2; //TODO
  //}
`;

export interface SmallButtonProps {
  size?: ComponentSize;
  color?: string;
  background?: string;
  noTransform?: boolean;
  noShadow?: boolean;
  fillWidth?: boolean;
  fontFamily?: string;
}
