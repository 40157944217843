import React, { useMemo } from 'react';
import styled from 'styled-components';

export interface LabelsStripProps {
  labels: string[];
  maxLabels?: number;
}

export function LabelsStrip({ labels, maxLabels }: LabelsStripProps) {
  const collapseAfter = useMemo(() => maxLabels || 3, [maxLabels]);

  const expandedLabels = useMemo(
    () => labels?.slice(0, collapseAfter || labels?.length),
    [labels, collapseAfter],
  );

  const collapsedLabels = useMemo(
    () => labels?.slice(collapseAfter),
    [labels, collapseAfter],
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {labels?.length > 0 && (
        <Wrapper className="labels-strip-wrapper">
          {expandedLabels?.map((label, i) => (
            <LabelChip className="labels-strip-chip" key={i}>
              {label}
            </LabelChip>
          ))}
          {collapsedLabels?.length > 0 && (
            <CollapsedHint>+{collapsedLabels?.length} Labels</CollapsedHint>
          )}
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
`;

const LabelChip = styled.span`
  color: #a6afb8;
  border: 1px solid rgba(201, 208, 217, 0.5);
  background: #fafafa;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  padding: 0.3rem 0.8rem;
`;

const CollapsedHint = styled.span`
  color: #a6afb8;
  text-transform: uppercase;
  font-size: 1.2rem;
`;
