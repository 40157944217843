import styled from 'styled-components';
import { breakpoints } from '@/components/responsive';

export interface SpacingProps {
  value?: number;
  mobileValue?: number;
  xlrValue?: number;
}

export const HSpace = styled.div`
  height: 1px;
  width: ${(props: SpacingProps) => (props.value ? props.value : 1)}rem;
  flex-shrink: 0;
`;

export const VSpace = styled.div`
  height: ${(props: SpacingProps) => (props.value ? props.value : 1)}rem;
  width: 1px;
  flex-shrink: 0;

  ${breakpoints.down(`md`)} {
    height: ${(props: SpacingProps) =>
      props.mobileValue ? props.mobileValue : props.value || 1}rem;
  }

  ${breakpoints.up(`xlr`)} {
    height: ${(props: SpacingProps) =>
      props.xlrValue ? props.xlrValue : props.value || 1}rem;
  }
`;
