import { CatalogWidgetProps } from '@/webapi/use-widget-catalog-api';
import { DeviceType } from '@/utils/definitions';

export function getManualProductsSize(
  currentSchema: CatalogWidgetProps,
  device: DeviceType,
) {
  const spec = currentSchema?.settings?.general?.specs?.find(
    (x) => x?.key === `recsCount`,
  );
  let size = spec?.value?.value;
  if (device === DeviceType.Desktop) {
    const v = spec?.value?.[`desktop`]?.value;
    if (v) {
      size = v as any;
    }
  }
  return size;
}
export { isProductPageRule } from '@/features/editor/widgets/custom-widget/loading-section/util/useQueryBuilder';
export { getAvailableStrategies } from '@/features/editor/widgets/custom-widget/loading-section/util/strategies';
