import * as React from 'react';
import styled from 'styled-components';
import { IoChevronDown } from 'react-icons/io5';
import { CardButton, TextualCardLayout } from '@/components/card-button';
import { useIsPreviewingDesktop } from '@/features/editor/shared/use-device-type';

export interface TargetingButtonProps {
  title: string;
  subtitle: string;
  onClick?: () => void;
  isDisabled?: boolean;
  wrapperRef?: React.MutableRefObject<HTMLElement>;
}

export function TargetingButton({
  title,
  subtitle,
  onClick,
  wrapperRef,
  isDisabled,
}: TargetingButtonProps) {
  const isDesktop = useIsPreviewingDesktop();

  const onClicked = () => {
    onClick && onClick();
  };

  return (
    <CardButton
      wrapperRef={wrapperRef}
      canBeSelected
      onClicked={onClicked}
      padding="0rem 1rem 0rem 0rem"
      borderRadius="1.2rem"
      isDisabled={isDisabled}
    >
      <StyledTextualCardLayout
        verticalGap="0.3rem"
        padding="0.7rem 2rem"
        accentFontFamily="JetBrains Mono"
      >
        <Title isDesktop={isDesktop}>{title}</Title>
        <Subtitle isDesktop={isDesktop}>{subtitle}</Subtitle>
      </StyledTextualCardLayout>
      <Arrow>
        <IoChevronDown size={13} />
      </Arrow>
    </CardButton>
  );
}

const StyledTextualCardLayout = styled(TextualCardLayout)``;

const Title = styled.span`
  font-size: 1rem !important;
  font-weight: bold !important;
  color: #777e81 !important;
  letter-spacing: -0.42px !important;
  text-transform: capitalize !important;
  font-family: 'JetBrains Mono', serif !important;
`;

const Subtitle = styled.span`
  font-size: ${(p: { isDesktop: boolean }) =>
    p.isDesktop ? `1.1rem` : `1.3rem`} !important;
  font-weight: bolder !important;
  color: black !important;
  letter-spacing: -0.42px !important;
  text-transform: uppercase !important;
  max-width: ${(p: { isDesktop: boolean }) =>
    p.isDesktop ? `8rem` : `14rem`} !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Arrow = styled.div`
  position: absolute;
  top: 0;
  right: 0.75rem;
  bottom: 0;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  color: black;
`;
