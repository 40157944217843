import React, { useCallback, useContext } from 'react';
import {
  ImageInputSpec,
  InputComponent,
  InputCustomization,
} from '@/webapi/checkout-widget-props';
import { BackgroundInput } from '@/features/editor/widgets/custom-widget/inputs/background';
import { EditorContext } from '@/features/editor/context/editor-context';
import { CheckoutExtensibilityContext } from '@/features/editor/widgets/checkout-extensibility/context';

export interface CheckoutInputImageUploadProps {
  cust: InputCustomization;
  custId: number;
  comp: InputComponent;
  compId: number;
  spec: ImageInputSpec;
  specId: number;
}

export function ImageUploadInput({
  custId,
  cust,
  compId,
  comp,
  specId,
  spec,
}: CheckoutInputImageUploadProps) {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);

  const { getValueByPath, currentProps, setCurrentProps } = useContext(
    CheckoutExtensibilityContext,
  );

  const initValues = useCallback(
    (key) => {
      if (key === `mode`) return `image`;
      if (key === `allowVideo`) return false;
      if (key === `value`)
        return `url("${getValueByPath(custId, compId, specId).value}")`;
      return {};
    },
    [currentProps, setCurrentProps],
  );

  const onChange = useCallback(
    (key, value) => {
      if (key === `value`) {
        setCurrentProps((draft) => {
          value = (value as string)
            .replaceAll(`url("`, ``)
            .replaceAll(`")`, ``);
          draft[cust.key][comp.key][spec.key] = value;
        });
      }
    },
    [currentProps, setCurrentProps],
  );

  return (
    <BackgroundInput
      customizationIdx={custId}
      customization={undefined}
      componentIdx={custId}
      component={undefined}
      specIdx={specId}
      spec={undefined}
      device={device}
      initialValues={initValues}
      onValuesChanged={onChange}
      noModeSelection
    />
  );
}
