import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { RuleHeader } from './RuleHeader';
import { RuleCondition } from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/condition';
import { ProductsSelection } from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/products';
import { AdvancedRuling } from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/context';
import {
  ManualProductsPicker,
  newProductsPickerCtx,
  ProductPickerCtx,
} from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker/context';
import { ManualProduct } from '@/webapi/use-widget-catalog-api';

interface Props {
  num: number;
}

export const Rule: FC<Props> = ({ num }) => {
  const { isOpen, toggleOpen, updateProducts, getRuleProducts, inSearchMode } =
    useContext(AdvancedRuling);
  const open = isOpen(num);
  const pickerCtx = newProductsPickerCtx(getRuleProducts(num), 16);
  useEffect(() => {
    updateProducts(takeProducts(pickerCtx));
  }, [asDepKey(takeProducts(pickerCtx))]);
  return (
    <ManualProductsPicker.Provider value={pickerCtx}>
      <Container open={open} onClick={() => !open && toggleOpen(num)}>
        <RuleHeader num={num} />
        {open && (
          <>
            <RuleCondition num={num} />
            <Then hidden={inSearchMode}>
              <p>Then show these products</p>
            </Then>
            <ProductsSelection />
          </>
        )}
      </Container>
    </ManualProductsPicker.Provider>
  );
};

const Then = styled.div`
  p {
    user-select: none;
    display: ${(p: { hidden: boolean }) => (p.hidden ? `none` : `block`)};
    margin: -3rem 0 2rem 0;
    width: 100%;
    text-align: center;
    color: #475562;
    font-family: 'JetBrains Mono', serif;
    font-size: 1.5rem;
    font-weight: 600;
  }
`;

const Container = styled.div`
  margin: 1rem 0;
  width: 95%;
  border-radius: 20px;
  border: 1.7px solid #bfcdd1;

  &:hover {
    cursor: ${(p: { open: boolean }) => (p.open ? `default` : `pointer`)};
    opacity: ${(p: { open: boolean }) => (p.open ? 1 : 0.5)};
  }

  &:active {
    opacity: 1;
  }
`;

function asDepKey(products: ManualProduct[]) {
  return products.map((p) => `${p.productId}${p.mainValue}`).join();
}

function takeProducts(pCtx: ProductPickerCtx) {
  return pCtx.slots
    .filter((p) => !!p?.product?.id)
    .map(
      (p) =>
        ({
          productId: p.product.id,
          mainValue: p.product.mainValue,
          imageOverride: p.product?.imageOverride ? p.product.image?.src : ``,
        } as ManualProduct),
    );
}
