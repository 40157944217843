import * as React from 'react';
import { useContext } from 'react';
import { StylingComponentsGroup } from '@/features/editor/widgets/post-purchase/styling/group';
import { PostPurchaseTextualInput } from '@/features/editor/widgets/post-purchase/inputs/textual';
import { PostPurchaseContext } from '../context';

export function PostPurchaseHeaderStyles() {
  const { props, setProps } = useContext(PostPurchaseContext);
  return (
    <StylingComponentsGroup title="Content Style">
      <PostPurchaseTextualInput
        defaultValue={props.style.header}
        onChange={(p) =>
          setProps((draft) => {
            draft.style.header = p;
          })
        }
      />
    </StylingComponentsGroup>
  );
}
