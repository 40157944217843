import styled from 'styled-components';
import React, { useContext, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Flexbox } from '@/components/flex';
import useAutoFocus from '@/components/hooks/useAutoFocus';
import { QuickPreviewNavigationContext } from '@/features/editor/preview-navigation/context';

export const TextInput = styled.input`
  appearance: none;
  -webkit-appearance: none;
  pointer-events: auto;
  outline: none;
  padding: 1rem;
  border: 1.5px solid #c7c8cf;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  font-family: Inter, serif;
  font-weight: 600;
  color: #5b656e;
  font-size: 1.6rem;

  &&::-webkit-outer-spin-button,
  &&::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &&[type='number'] {
    -moz-appearance: textfield;
  }
`;

interface TextInputProps {
  setAutoFocus?: boolean;
  onChange: (ev) => void;
}

export function Search({ onChange, setAutoFocus }: TextInputProps) {
  const { ref } = useAutoFocus({ setAutoFocus });
  const { search, isCustomUrl } = useContext(QuickPreviewNavigationContext);

  useEffect(() => {
    isCustomUrl &&
      setTimeout(
        () =>
          ref.current?.setSelectionRange(
            ref?.current?.value?.length,
            ref?.current?.value?.length,
          ),
        100,
      );
  }, []);
  return (
    <SearchWrapper>
      <SearchInput
        ref={ref}
        onChange={onChange}
        placeholder="Search for page or paste custom url"
        defaultValue={search !== `` ? search : undefined}
      />
      <StaticImage
        src="../assets/search.svg"
        alt="edit"
        placeholder="none"
        loading="eager"
        height={15}
      />
    </SearchWrapper>
  );
}

const SearchWrapper = styled(Flexbox)`
  && {
    height: 100%;
    width: 100%;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .gatsby-image-wrapper {
      position: absolute;
      right: 1.5rem;
    }
  }
`;

interface SP {
  selected?: boolean;
  padding?: string;
  color?: string;
  fontSize?: string;
}
export const SearchInput = styled.input`
  && {
    height: 100%;
    width: 100%;
    transition: width 0.3s ease-in-out;
    appearance: none;
    -webkit-appearance: none;
    pointer-events: auto;
    outline: none;
    background: white;

    font-size: ${(p: SP) => p.fontSize || `1.2rem`};
    font-weight: ${(props: SP) => (props.selected ? `700` : `500`)};
    font-family: 'JetBrains Mono', serif;

    color: ${(p: SP) => p.color || (p.selected ? `#111111` : `#545454`)};

    border: ${(props: SP) =>
      props.selected ? `1.5px solid #111111` : `1px solid rgba(0,0,0,0.2)`};

    box-shadow: 0 1px 4px 0 rgba(68, 72, 80, 0.08);

    border-radius: 2rem;

    padding: ${(p: SP) => p.padding || `0 3.2rem 0 1.5rem`};

    &&::placeholder {
      color: ${(p: SP) => p.color || (p.selected ? `#111111` : `#545454`)};
      opacity: 1;
    }

    &&:-ms-input-placeholder {
      color: ${(p: SP) => p.color || (p.selected ? `#111111` : `#545454`)};
      opacity: 1;
    }

    &&::-ms-input-placeholder {
      color: ${(p: SP) => p.color || (p.selected ? `#111111` : `#545454`)};
      opacity: 1;
    }

    &&::-webkit-outer-spin-button,
    &&::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &&[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;
