import * as React from 'react';
import styled from 'styled-components';
import { useContext, useMemo } from 'react';
import { StickyBigArrow } from '@/components/big-arrow';
import { UserStatsKind } from '@/webapi/use-user-stats';
import { AccountContext } from '@/features/account-context';

export function OnboardingQueryBuilder({ obsRef }) {
  const { trackIfGoalNotReached, isGoalReached } = useContext(AccountContext);

  useMemo(() => {
    trackIfGoalNotReached(
      UserStatsKind.SHOULD_SHOW_QUERY_BUILDER_ONBOARDING,
    ).then();
  }, []);

  if (isGoalReached(UserStatsKind.SHOULD_SHOW_QUERY_BUILDER_ONBOARDING)) {
    return null;
  }

  return (
    <StickyBigArrow
      flip
      fadeIn
      zIndex={50000}
      hideAfter={5000}
      invert={0.4}
      observableRef={obsRef}
      leftOffset={-290}
      topOffset={-30}
    >
      <Text>Click to filter by another parameter</Text>
    </StickyBigArrow>
  );
}

const Text = styled.span`
  margin-right: -2rem;
  padding-top: 1rem;
  font-size: 1.5rem;
  font-weight: bolder;
  max-width: 20rem;
  font-family: 'JetBrains Mono', serif;
  color: #657a88;

  user-select: none;
`;
