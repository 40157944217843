export type ComponentSize =
  | `xs`
  | `small`
  | `medium`
  | `medium-thin`
  | `medium-high`
  | `large`
  | 'xl';

export function paddingOf(size?: ComponentSize): string {
  switch (size) {
    case `xl`:
      return `1.2rem 6rem`;
    case `large`:
      return `1.2rem 3rem`;
    case `medium`:
      return `0.8rem 2.8rem`;
    case `medium-thin`:
      return `0.8rem 2rem`;
    case `medium-high`:
      return `1.1rem 2rem`;
    case `small`:
      return `0.7rem 1.9rem`;
    default:
      return `1rem 1.5rem`;
  }
}

export function fontSizeOf(size?: ComponentSize): string {
  switch (size) {
    case `xl`:
      return `2.1rem`;
    case `large`:
      return `1.6rem`;
    case `medium-high`:
      return `1.5rem`;
    case `medium`:
      return `1.4rem`;
    case `small`:
      return `1.2rem`;
    default:
      return `1.2rem`;
  }
}
