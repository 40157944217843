import React, { useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import Select from 'react-select';
import {
  CustomWidgetContext,
  CustomWidgetContextProps,
} from '@/features/editor/widgets/custom-widget/shared/context';
import { selectStyle } from '@/features/editor/widgets/custom-widget/style-templating/selectStyle';
import { VSpace } from '@/components/spacing';
import { EditorContext } from '@/features/editor/context/editor-context';
import { applyStyle } from '@/features/editor/widgets/custom-widget/style-templating/utils';
import { StyleTemplate } from '@/features/editor/widgets/custom-widget/style-templating/models';
import { makeCustomSelectOption } from '@/features/editor/widgets/custom-widget/style-templating/make-custom-select-option';
import { DeviceType } from '@/utils/definitions';
import { CatalogApp } from '@/webapi/use-widget-catalog-api';
import { defaultStyle } from '@/features/editor/widgets/custom-widget/shared/store-style-utils';

const RainbowAnim = keyframes`
  0% {
    background-position: 20% 50%
  }
  100% {
    background-position: 250% 50%
  }
`;

export function CustomizeDesignBtn({ onClick }) {
  const widget = useContext(CustomWidgetContext);
  const {
    applyTempChange,
    transpiler: { asWidgetChange },
    devicePreview: {
      editorState: { device },
    },
    resources: { appsCatalog },
  } = useContext(EditorContext);

  const options = makeOptions(widget, device, appsCatalog);

  const { CustomOption, revertTempChanges } = makeCustomSelectOption(
    options,
    applyTempChange,
    asWidgetChange,
    widget,
  );

  const defaultValue = getDefaultValue(options, widget);

  return (
    <Container>
      <VSpace value={3} />
      <Header>Styling</Header>
      <Row>
        <div
          style={{ width: `70%` }}
          key={`ss${defaultValue?.value?.id || ``}`}
        >
          <Select
            components={{ Option: CustomOption }}
            menuPlacement="top"
            onMenuClose={revertTempChanges}
            styles={selectStyle}
            defaultValue={defaultValue}
            onChange={(v: { value: StyleTemplate }) => {
              widget.setStyleTemplate(
                applyStyle(widget.currentSchema.customizations, v.value),
                v.value.generalSettings,
                v.value.id,
              );
            }}
            options={options}
          />
        </div>
        <RainbowButton mt="0" width="10rem" onClick={onClick}>
          Customize
        </RainbowButton>
      </Row>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  color: #59656f;
  margin-bottom: 1rem;
  font-family: Inter, serif;
  font-size: 1.5rem;
  font-weight: 500;
`;
const Row = styled.div`
  background-color: #f3f6f7;
  border-radius: 10px;
  padding: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  onClick: () => void;
  children: any;
  width?: string;
  mt?: string;
};

export function RainbowButton({ onClick, children, width, mt }: Props) {
  return (
    <RainbowWrapper mt={mt} width={width} onClick={onClick}>
      <StylingCta>{children}</StylingCta>
    </RainbowWrapper>
  );
}

const RainbowWrapper = styled.div<{ width?: string; mt?: string }>`
  cursor: pointer;
  height: 4rem;
  width: ${(p) => p.width || `20rem`};
  margin-top: ${(p) => p.mt || `1rem`};

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.2rem;
  border-radius: 2rem;
  background: linear-gradient(
      90deg,
      #0ebeff,
      #ffdd40,
      #ae63e4,
      #47cf73,
      #0ebeff,
      #ffdd40,
      #ae63e4,
      #47cf73
    )
    0 50%;
  background-size: 250% 250%;

  :hover {
    animation: ${RainbowAnim} 5s linear infinite;
  }
`;
const StylingCta = styled.button`
  && {
    pointer-events: none;
    height: 100%;
    width: 100%;
    position: relative;
    outline: none;
    border: none;
    appearance: none;
    user-select: none;
    cursor: pointer;
    font-size: 1.4rem;
    text-align: center;
    background: #f3f6f7;
    border-radius: 10rem;
    color: #000000;
    letter-spacing: -0.46px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

function makeOptions(
  widget: CustomWidgetContextProps,
  device: DeviceType,
  appsCatalog: CatalogApp[],
) {
  return [
    {
      value: defaultStyle(
        widget?.currentSchema?.appId || `post-purchase`,
        widget?.currentSchema?.blueprintName || `post-purchase`,
        device,
        appsCatalog,
      ),
      label: `Default`,
    },
    ...(widget.styleTemplates?.map?.((v) => ({
      label: v.name,
      value: v,
    })) || []),
  ];
}

function getDefaultValue(
  options: {
    label: string;
    value: StyleTemplate;
  }[],
  widget: CustomWidgetContextProps,
) {
  return (
    options.find(
      (v) => v.value.id === widget?.currentSchema?.styleTemplateId,
    ) || options?.[0]
  );
}
