import produce from 'immer';
import {
  CatalogApp,
  CatalogWidgetProps,
  Customization,
} from '@/webapi/use-widget-catalog-api';
import {
  StoreStyles,
  StyleTemplate,
  StyleTemplateDevice as D,
  StyleTemplates,
  StyleTemplateType,
  StyleTemplateType as T,
} from '@/features/editor/widgets/custom-widget/style-templating/models';
import { DeviceType } from '@/utils/definitions';

export function defaultStyle(
  appId: string,
  blueprintName: string,
  device: DeviceType,
  appsCatalog: CatalogApp[],
) {
  const schema = getDefaultSchema(appId, blueprintName, appsCatalog);
  return {
    id: `default`,
    name: `Default`,
    type: T.DEFAULT,
    appId,
    widgetName: blueprintName,
    device: device === DeviceType.Mobile ? D.M : D.D,
    props: {
      customizations: schema?.customizations || [],
    },
    generalSettings: schema?.settings?.general,
  } as StyleTemplate;
}

function getDefaultSchema(
  appId: string,
  blueprintName: string,
  appsCatalog: CatalogApp[],
) {
  return appsCatalog
    ?.find?.((app) => app.id === appId)
    ?.widgets?.find?.((widget) => widget.name === blueprintName)?.blueprint
    ?.schema;
}

export const STORE_STYLE_ID = `store_style`;

export function createStoreStyleForWidget(
  currentSchema: CatalogWidgetProps,
  device: DeviceType,
  appsCatalog: CatalogApp[],
  currentStyles: StyleTemplates,
) {
  if (!currentStyles?.storeStyles) {
    return null;
  }
  const storeStyle = defaultStyle(
    currentSchema.appId,
    currentSchema.blueprintName,
    device,
    appsCatalog,
  );

  const customizations = applyStoreStyle(
    storeStyle.props.customizations,
    currentStyles.storeStyles,
  );
  if (!customizations) {
    return null;
  }
  return {
    ...storeStyle,
    name: `Site Style`,
    id: STORE_STYLE_ID,
    type: StyleTemplateType.CUSTOM,
    props: {
      customizations,
    },
  };
}

export function applyStoreStyle(
  customizations: Array<Customization>,
  storeStyles: StoreStyles,
) {
  let hasChanged = false;
  const ret = produce(customizations, (draft) => {
    draft.forEach((cust) => {
      cust.components.forEach((comp) => {
        const border = storeStyles?.imageAttributes?.border;
        const boxShadow = storeStyles?.imageAttributes?.boxShadow;
        const borderRadius = storeStyles?.imageAttributes?.borderRadius;
        if (comp.key === `imgStyle`) {
          comp.specs.forEach((spec) => {
            if (spec.key === `border`) {
              hasChanged = true;
              if (borderRadius) {
                spec.values?.borderRadius &&
                  (spec.values.borderRadius = borderRadius);
                spec.values?.desktop?.border?.borderRadius &&
                  (spec.values.desktop.border.borderRadius = borderRadius);
                spec.values?.desktop?.borderRadius &&
                  (spec.values.desktop.borderRadius = borderRadius);
                spec.values?.desktop?.border?.hover?.borderRadius &&
                  (spec.values.desktop.border.hover.borderRadius =
                    borderRadius);
              }
              if (border && !border?.includes(`0px`)) {
                spec.values?.border && (spec.values.border = border);
                spec.values?.desktop?.border?.border &&
                  (spec.values.desktop.border.border = border);
                spec.values?.desktop?.border &&
                  (spec.values.desktop.border = border);
                spec.values?.desktop?.border?.hover?.border &&
                  (spec.values.desktop.border.hover.border = border);
              }
            } else if (spec.key === `shadow` && boxShadow) {
              hasChanged = true;
              spec.values?.value && (spec.values.value = boxShadow);
              spec.values?.desktop?.value &&
                (spec.values.desktop.value = boxShadow);
              spec.values?.desktop?.hover?.value &&
                (spec.values.desktop.hover.value = boxShadow);
            }
          });
        } else if (
          comp.name === `CTA Style` ||
          (cust.key === `atcButton` && comp.key === `style`) ||
          (cust.key === `cta` && comp.key === `style`) ||
          comp.key === `cta`
        ) {
          comp.specs.forEach((spec) => {
            const color = storeStyles?.atcAttributes?.textColor;
            const bgColor = storeStyles?.atcAttributes?.bgColor;
            const borderRadius = storeStyles?.atcAttributes?.borderRadius;
            const border = storeStyles?.atcAttributes?.border;
            const boxShadow = storeStyles?.atcAttributes?.boxShadow;
            if (spec.key === `font` && color) {
              hasChanged = true;
              spec.values?.color && (spec.values.color = color);
              spec.values?.desktop?.hover?.color &&
                (spec.values.desktop.hover.color = color);
            } else if (spec.key === `background` && bgColor) {
              hasChanged = true;
              spec.values?.value && (spec.values.value = bgColor);
              spec.values?.desktop?.hover?.value &&
                (spec.values.desktop.hover.value = bgColor);
              spec.values?.desktop?.value &&
                (spec.values.desktop.value = bgColor);
            } else if (spec.key === `border`) {
              if (borderRadius && !borderRadius?.includes(`0px`)) {
                hasChanged = true;
                spec.values.enabled = true;
                spec.values?.borderRadius &&
                  (spec.values.borderRadius = borderRadius);
                spec.values?.desktop?.border?.borderRadius &&
                  (spec.values.desktop.border.borderRadius = borderRadius);
                spec.values?.desktop?.borderRadius &&
                  (spec.values.desktop.borderRadius = borderRadius);
                spec.values?.desktop?.border?.hover?.borderRadius &&
                  (spec.values.desktop.border.hover.borderRadius =
                    borderRadius);
              }
              if (border && !border?.includes(`0px`)) {
                spec.values.enabled = true;
                hasChanged = true;
                spec.values?.border && (spec.values.border = border);
                spec.values?.desktop?.border?.border &&
                  (spec.values.desktop.border.border = border);
                spec.values?.desktop?.border &&
                  (spec.values.desktop.border = border);
                spec.values?.desktop?.border?.hover?.border &&
                  (spec.values.desktop.border.hover.border = border);
              }
            } else if (boxShadow && spec.key === `shadow`) {
              hasChanged = true;
              spec.values?.value && (spec.values.value = boxShadow);
              spec.values?.desktop?.value &&
                (spec.values.desktop.value = boxShadow);
              spec.values?.desktop?.hover?.value &&
                (spec.values.desktop.hover.value = boxShadow);
            }
          });
        }
      });
    });
  });
  if (!hasChanged) {
    return null;
  }
  return ret;
}
