import * as React from 'react';
import styled from 'styled-components';
import { useEffect } from 'react';
import {
  Color,
  CtaProps,
  Size,
} from '@/features/editor/widgets/post-purchase/props';
import { RadioButtons } from '@/features/editor/widgets/custom-widget/inputs/shared/radio-buttons';
import {
  POST_PURCHASE_COLOR_OPTIONS,
  POST_PURCHASE_SIZE_OPTIONS,
} from '@/features/editor/widgets/post-purchase/inputs/textual';
import { useComplexState } from '@/utils/use-complex-state';

export interface PostPurchaseButtonInputProps {
  defaultValue: CtaProps;
  onChange?: (props: CtaProps) => void;
}

export const POST_PURCHASE_BOLD_OPTIONS = [
  { label: `Normal`, value: `false` },
  { label: `Bold`, value: `true` },
];
export function PostPurchaseButtonInput({
  defaultValue,
  onChange,
}: PostPurchaseButtonInputProps) {
  const [props, setProps] = useComplexState(defaultValue);
  useEffect(() => {
    onChange && onChange(props);
  }, [props]);

  return (
    <Wrapper>
      <RadioButtons
        title="Text Size"
        smallTitle
        options={POST_PURCHASE_SIZE_OPTIONS}
        defaultValue={defaultValue?.textSize}
        onChange={(p) =>
          setProps((draft) => {
            draft.textSize = p as Size;
          })
        }
      />
      <RadioButtons
        title="Text Color"
        smallTitle
        options={POST_PURCHASE_COLOR_OPTIONS}
        defaultValue={defaultValue?.textColor}
        onChange={(p) =>
          setProps((draft) => {
            draft.textColor = p as Color;
          })
        }
      />
      <RadioButtons
        title="Text Decoration"
        smallTitle
        options={POST_PURCHASE_BOLD_OPTIONS}
        defaultValue={defaultValue?.textBold ? `true` : `false`}
        onChange={(p) =>
          setProps((draft) => {
            draft.textBold = p === `true`;
          })
        }
      />
      <RadioButtons
        title="Button Kind"
        smallTitle
        options={POST_PURCHASE_KIND_OPTIONS}
        defaultValue={defaultValue?.kind}
        onChange={(p) =>
          setProps((draft) => {
            // @ts-ignore
            draft.kind = p;
          })
        }
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  width: 100%;
  max-width: 100%;
  align-items: center;
  overflow: hidden;
`;

export const POST_PURCHASE_KIND_OPTIONS = [
  { label: `Filled`, value: `filled` },
  { label: `Outline`, value: `outline` },
  { label: `Link`, value: `link` },
];
