import * as React from 'react';
import { useContext, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';
import {
  AdvancedRulingOption,
  RecommendationOptions,
  RecommendationType,
} from '@/webapi/use-widget-catalog-api';
import { SectionTitle } from '@/features/editor/widgets/custom-widget/shared/shared-styles';
import {
  CardBtn,
  CardDescription,
  Description,
  ListWrapper,
  Title,
} from '@/features/editor/widgets/custom-widget/loading-section';
import { VSpace } from '@/components/spacing';
import { PostPurchaseOffer } from '@/features/editor/widgets/post-purchase/props';
import { AdvancedEditing } from '@/features/editor/widgets/custom-widget/loading-section/conditional-product';
import { QBItemSelection } from '@/components/query-builder/models';
import {
  AdvancedProductRulingPicker,
  useAdvancedRulingModalProps,
} from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling';
import {
  CustomWidgetContext,
  CustomWidgetContextProps,
} from '@/features/editor/widgets/custom-widget/shared/context';
import { EditorContext } from '@/features/editor/context/editor-context';
import { useStrategyPerSlot } from '@/features/editor';
import { StrategyPerSlotContext } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/context/context';
import { StrategyPerSlot } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot';
import {
  ManualProductsPicker,
  newProductsPickerCtx,
} from '../custom-widget/loading-section/manual-product-picker/context';
import {
  ManualProductPicker,
  useManualProductsModalProps,
} from '@/features/editor/widgets/custom-widget/loading-section/manual-product-picker';
import { useLayeredRuling } from '@/features/editor/widgets/shared/useLayeredRuling';
import { LayeredRulingContext } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import { LayeredRuling } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling';
import { maybe } from '@/features/details/utils';

export function PostPurchaseLoadingSection({ offerNum }: { offerNum: number }) {
  const {
    props,
    updateOfferAdvancedRulingOptions,
    updateOfferStrategyOptions,
  } = useContext(PostPurchaseContext);

  const currentOffer = useMemo(() => props.offers[offerNum], [props, offerNum]);

  const ctx = useMemo(
    () =>
      ({
        updateAdvancedRulingOptions: (options: Array<AdvancedRulingOption>) => {
          updateOfferAdvancedRulingOptions(offerNum, options);
        },
        updateRecommendationOptions: (options: RecommendationOptions) => {
          updateOfferStrategyOptions(offerNum, options);
        },
        currentSchema: {
          settings: {
            loading: {
              loadingEnv: {
                recommendationOptions: {
                  advancedRuling:
                    currentOffer?.loadingEnv?.recommendationOptions
                      ?.advancedRuling,
                  layeredRuling:
                    currentOffer?.loadingEnv?.recommendationOptions
                      ?.layeredRuling,
                },
              },
            },
          },
        },
      } as CustomWidgetContextProps),
    [currentOffer, offerNum],
  );

  return (
    <CustomWidgetContext.Provider value={ctx}>
      <div>
        <SectionTitle>
          Which product do you want to show in the{` `}
          {offerNum === 0 ? `first` : `second`} offer?
        </SectionTitle>
        <ListWrapper>
          <ManualButton offerNum={offerNum} />
          <MostExpensiveButton
            offerNum={offerNum}
            currentOffer={currentOffer}
          />
          <MostCheapButton currentOffer={currentOffer} offerNum={offerNum} />
          <PurchasedTogetherButton
            currentOffer={currentOffer}
            offerNum={offerNum}
          />
          <AdvancedRulingButton
            currentOffer={currentOffer}
            offerNum={offerNum}
          />
        </ListWrapper>
        <VSpace value={3} />
      </div>
    </CustomWidgetContext.Provider>
  );
}

function useLegacyManualPicker(currentOffer: PostPurchaseOffer) {
  const productIds = getProductIds(currentOffer);
  const size = 1;
  const productPickerCtx = newProductsPickerCtx(productIds, size);
  const {
    hide: hideManual,
    show: showManual,
    fromRef: manualRef,
    props: manualProps,
  } = useManualProductsModalProps();
  return {
    productIds,
    productPickerCtx,
    hideManual,
    showManual,
    manualRef,
    manualProps,
  };
}

function useStrategyPerSlotPicker(offerNum) {
  const { currentSchema } = useContext(CustomWidgetContext);
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);

  const { props, setOfferStrategy } = useContext(PostPurchaseContext);

  const currentOffer = props.offers[offerNum];
  const {
    strategyPerSlotCtx,
    showStrategyPerSlot,
    setShowStrategyPerSlot,
    isRecsV2Enabled,
  } = useStrategyPerSlot(
    device,
    currentSchema,
    true,
    getInitialState(currentOffer),
  );
  return {
    setOfferStrategy,
    currentOffer,
    strategyPerSlotCtx,
    showStrategyPerSlot,
    setShowStrategyPerSlot,
    isRecsV2Enabled,
  };
}

const ManualButton = ({ offerNum }) => {
  const { updateRecommendationOptions } = useContext(CustomWidgetContext);
  const {
    setOfferStrategy,
    currentOffer,
    strategyPerSlotCtx,
    showStrategyPerSlot,
    setShowStrategyPerSlot,
    isRecsV2Enabled,
  } = useStrategyPerSlotPicker(offerNum);

  const {
    productIds,
    productPickerCtx,
    hideManual,
    showManual,
    manualRef,
    manualProps,
  } = useLegacyManualPicker(currentOffer);

  const isEnabled = isRecsV2Enabled && productIds.length === 0;

  const onManualClick = () => {
    setOfferStrategy(offerNum, RecommendationType.MANUAL);
    if (isEnabled) {
      setShowStrategyPerSlot(true);
    } else {
      showManual();
    }
  };

  return (
    <div>
      <div ref={manualRef} />
      <ManualProductsPicker.Provider value={productPickerCtx}>
        <ManualProductPicker
          updateRecommendationOptions={updateRecommendationOptions}
          hide={hideManual}
          {...manualProps}
        />
      </ManualProductsPicker.Provider>
      <StrategyPerSlotContext.Provider value={strategyPerSlotCtx}>
        <StrategyPerSlot
          isOpen={showStrategyPerSlot}
          close={() => setShowStrategyPerSlot(false)}
          fromRef={manualRef}
        />
      </StrategyPerSlotContext.Provider>
      <CardBtn
        onClick={onManualClick}
        isSelected={currentOffer?.loadingStrategy === RecommendationType.MANUAL}
      >
        <Title
          isSelected={
            currentOffer?.loadingStrategy === RecommendationType.MANUAL
          }
        >
          I want to choose manually
        </Title>
        <Description>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Choose the specific items you'd like to display
        </Description>
      </CardBtn>
    </div>
  );
};

const MostCheapButton = ({ currentOffer, offerNum }) => {
  const { setOfferStrategy, updateOfferStrategyCondition } =
    useContext(PostPurchaseContext);

  const rulingRef = useRef(null);

  const [isConditionalModalVisible, setIsConditionalModalVisible] =
    useState(false);

  const updateRecommendationCondition = (options: Array<QBItemSelection>) => {
    updateOfferStrategyCondition(offerNum, options);
  };

  const isSelected =
    currentOffer?.loadingStrategy === RecommendationType.MOST_CHEAP_IN_CART;

  return (
    <Wrapper>
      <div ref={rulingRef} />
      <AdvancedEditing
        fromRef={rulingRef}
        setIsVisible={setIsConditionalModalVisible}
        isVisible={isConditionalModalVisible}
        strategy={currentOffer?.loadingStrategy}
        updateRecommendationCondition={updateRecommendationCondition}
        initialState={getInitialCondition(currentOffer)}
      />

      <CardBtn
        onClick={() =>
          setOfferStrategy(offerNum, RecommendationType.MOST_CHEAP_IN_CART)
        }
        isSelected={isSelected}
      >
        <Title isSelected={isSelected}>Cheapest Product</Title>
        {!isSelected && (
          <Description>Show the cheapest product in the cart</Description>
        )}
        <CardDescription
          isRecsV2Enabled={false}
          isAdvancedEditingV2Enabled={false}
          isSelected={isSelected}
          item={{ value: { type: currentOffer?.loadingStrategy } }}
          showCond={() => setIsConditionalModalVisible(true)}
        />
      </CardBtn>
    </Wrapper>
  );
};

const MostExpensiveButton = ({ currentOffer, offerNum }) => {
  const { setOfferStrategy, updateOfferStrategyCondition } =
    useContext(PostPurchaseContext);

  const rulingRef = useRef(null);

  const [isConditionalModalVisible, setIsConditionalModalVisible] =
    useState(false);

  const updateRecommendationCondition = (options: Array<QBItemSelection>) => {
    updateOfferStrategyCondition(offerNum, options);
  };

  const isSelected =
    currentOffer?.loadingStrategy === RecommendationType.MOST_EXPENSIVE_IN_CART;

  return (
    <Wrapper>
      <div ref={rulingRef} />
      <AdvancedEditing
        fromRef={rulingRef}
        setIsVisible={setIsConditionalModalVisible}
        isVisible={isConditionalModalVisible}
        strategy={currentOffer?.loadingStrategy}
        updateRecommendationCondition={updateRecommendationCondition}
        initialState={getInitialCondition(currentOffer)}
      />

      <CardBtn
        onClick={() =>
          setOfferStrategy(offerNum, RecommendationType.MOST_EXPENSIVE_IN_CART)
        }
        isSelected={isSelected}
      >
        <Title isSelected={isSelected}>Most Expensive Product</Title>
        {!isSelected && (
          <Description>Show the most expensive product in the cart</Description>
        )}
        <CardDescription
          isRecsV2Enabled={false}
          isAdvancedEditingV2Enabled={false}
          isSelected={isSelected}
          item={{ value: { type: currentOffer?.loadingStrategy } }}
          showCond={() => setIsConditionalModalVisible(true)}
        />
      </CardBtn>
    </Wrapper>
  );
};

const PurchasedTogetherButton = ({ currentOffer, offerNum }) => {
  const { setOfferStrategy, updateOfferStrategyCondition } =
    useContext(PostPurchaseContext);

  const rulingRef = useRef(null);

  const [isConditionalModalVisible, setIsConditionalModalVisible] =
    useState(false);

  const updateRecommendationCondition = (options: Array<QBItemSelection>) => {
    updateOfferStrategyCondition(offerNum, options);
  };

  const isSelected =
    currentOffer?.loadingStrategy === RecommendationType.PURCHASED_TOGETHER;

  return (
    <Wrapper>
      <div ref={rulingRef} />
      <AdvancedEditing
        fromRef={rulingRef}
        setIsVisible={setIsConditionalModalVisible}
        isVisible={isConditionalModalVisible}
        strategy={currentOffer?.loadingStrategy}
        updateRecommendationCondition={updateRecommendationCondition}
        initialState={getInitialCondition(currentOffer)}
      />

      <CardBtn
        onClick={() =>
          setOfferStrategy(offerNum, RecommendationType.PURCHASED_TOGETHER)
        }
        isSelected={isSelected}
      >
        <Title isSelected={isSelected}>Purchased Together</Title>
        {!isSelected && (
          <Description>Display items frequently purchased together</Description>
        )}
        <CardDescription
          isRecsV2Enabled={false}
          isSelected={isSelected}
          isAdvancedEditingV2Enabled={false}
          item={{ value: { type: currentOffer?.loadingStrategy } }}
          showCond={() => setIsConditionalModalVisible(true)}
        />
      </CardBtn>
    </Wrapper>
  );
};

function isV2Enabled(
  isAdvancedEditingV2Enabled: boolean,
  currentOffer: PostPurchaseOffer,
) {
  return (
    isAdvancedEditingV2Enabled &&
    maybe(
      () => currentOffer.loadingEnv.recommendationOptions.advancedRuling.length,
      0,
    ) === 0
  );
}

function layeredRulingContext() {
  const { currentSchema } = useContext(CustomWidgetContext);
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);
  const {
    showNewRuling,
    setShowRuling,
    layeredRulingCtx,
    isAdvancedEditingV2Enabled,
  } = useLayeredRuling(device, currentSchema, true);
  return {
    showNewRuling,
    setShowRuling,
    layeredRulingCtx,
    isAdvancedEditingV2Enabled,
  };
}

function legacyRulingContext(offerNum: number) {
  const { setOfferStrategy, updateOfferStrategyCondition } =
    useContext(PostPurchaseContext);

  const {
    hide: hideRuling,
    show: showRuling,
    props: rulingProps,
    fromRef: rulingRef,
  } = useAdvancedRulingModalProps();

  const [isConditionalModalVisible, setIsConditionalModalVisible] =
    useState(false);

  const updateRecommendationCondition = (options: Array<QBItemSelection>) => {
    updateOfferStrategyCondition(offerNum, options);
  };
  return {
    setOfferStrategy,
    hideRuling,
    showRuling,
    rulingProps,
    rulingRef,
    isConditionalModalVisible,
    setIsConditionalModalVisible,
    updateRecommendationCondition,
  };
}

const AdvancedRulingButton = ({
  currentOffer,
  offerNum,
}: {
  currentOffer: PostPurchaseOffer;
  offerNum: number;
}) => {
  const {
    setOfferStrategy,
    hideRuling,
    showRuling,
    rulingProps,
    rulingRef,
    isConditionalModalVisible,
    setIsConditionalModalVisible,
    updateRecommendationCondition,
  } = legacyRulingContext(offerNum);

  const isSelected =
    currentOffer?.loadingStrategy === RecommendationType.ADVANCED_RULING;
  const {
    showNewRuling,
    setShowRuling,
    layeredRulingCtx,
    isAdvancedEditingV2Enabled,
  } = layeredRulingContext();

  const v2Enabled = isV2Enabled(isAdvancedEditingV2Enabled, currentOffer);

  return (
    <Wrapper>
      <AdvancedProductRulingPicker hide={hideRuling} {...rulingProps} />
      <div ref={rulingRef} />
      <AdvancedEditing
        fromRef={rulingRef}
        setIsVisible={setIsConditionalModalVisible}
        isVisible={isConditionalModalVisible}
        strategy={currentOffer?.loadingStrategy}
        updateRecommendationCondition={updateRecommendationCondition}
        initialState={getInitialCondition(currentOffer)}
      />
      <LayeredRulingContext.Provider value={layeredRulingCtx}>
        <LayeredRuling
          isOpen={showNewRuling}
          fromRef={rulingRef}
          close={() => setShowRuling(false)}
        />
      </LayeredRulingContext.Provider>

      <CardBtn
        onClick={() => {
          setOfferStrategy(offerNum, RecommendationType.ADVANCED_RULING);
          v2Enabled ? setShowRuling(true) : showRuling();
        }}
        isSelected={isSelected}
      >
        <Title isSelected={isSelected}>Advanced Ruling</Title>
        {!isSelected && (
          <Description>Display items based on custom logic</Description>
        )}
        <CardDescription
          isRecsV2Enabled={false}
          isAdvancedEditingV2Enabled={false}
          isSelected={isSelected}
          item={{ value: { type: currentOffer?.loadingStrategy } }}
          showCond={() => setIsConditionalModalVisible(true)}
        />
      </CardBtn>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  && > :nth-child(2) {
    height: 100%;
  }
`;

function getProductIds(offer: PostPurchaseOffer) {
  const recs = offer?.loadingEnv?.recommendationOptions;
  return recs?.productIds || recs?.manualProducts || [];
}

function getInitialCondition(offer: PostPurchaseOffer) {
  return JSON.parse(
    JSON.stringify(offer?.loadingEnv?.recommendationOptions?.condition || []),
  );
}

function getInitialState(currentOffer: PostPurchaseOffer) {
  return currentOffer?.loadingEnv?.recommendationOptions?.strategyPerSlot;
}
