import React from 'react';

export interface VideoPoster {
  blob: Blob;
  dataUri: string;
  format: string;
}

export async function uploadPosterImage(
  videoRef: React.MutableRefObject<HTMLVideoElement>,
  uploadImage: (
    thumbnail: Blob,
    mobile: Blob,
    desktop: Blob,
  ) => Promise<{ thumbnail: string }>,
) {
  const poster = captureVideoFrame(videoRef?.current);
  const blob = await fetch(poster.dataUri).then((r) => r.blob());
  return uploadImage(blob, blob, blob);
}

export function captureVideoFrame(
  video: HTMLVideoElement,
  quality?: number,
): VideoPoster {
  const format = `jpeg`;
  quality = quality || 0.92;

  const canvas = document.createElement(`canvas`);
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  const ctx = canvas.getContext(`2d`);
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

  const dataUri = canvas.toDataURL(`image/${format}`, quality);
  const data = dataUri.split(`,`)[1];
  const mimeType = dataUri.split(`;`)[0].slice(5);

  const bytes = window.atob(data);
  const buf = new ArrayBuffer(bytes.length);
  const arr = new Uint8Array(buf);

  for (let i = 0; i < bytes.length; i += 1) {
    arr[i] = bytes.charCodeAt(i);
  }

  const blob = new Blob([arr], { type: mimeType });

  return { blob, dataUri, format };
}
