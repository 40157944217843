import { CachePolicies, useFetch } from 'use-http';
import { routes } from '@/webapi/routes';
import { FacebookAuthToken } from '@/features/integrations/facebook/facebook_redirect';
import { AdAccountList } from '@/features/integrations/facebook/models';

export function onOpenInstagramOAuthPopup(
  encrypt: (alias: string) => Promise<{ value: string }>,
  alias: string,
) {
  return async () => {
    const { value } = await encrypt(alias);

    const redirectURI = encodeURIComponent(`${origin}/public/insta/auth/cb/`);
    const state = encodeURIComponent(
      btoa(
        JSON.stringify({
          state: value,
          redirectURI: decodeURIComponent(redirectURI),
        }),
      ),
    );
    window.open(
      `https://www.facebook.com/v18.0/dialog/oauth?config_id=761813192393216&state=${state}&display=popup&client_id=1175514286441606&redirect_uri=${redirectURI}&auth_type=rerequest&response_type=code `,
      `fboauth`,
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
    width=560,height=733,left=500,top=101`,
    );
  };
}

export function useStoreSettingsApi() {
  const { post, get, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: true,
    },
  }));

  async function persist(token: FacebookAuthToken) {
    return post(routes.persistInstagramToken, token);
  }

  async function encrypt(alias: string): Promise<{ value: string }> {
    return post(routes.encryptAlias, { value: alias });
  }

  async function lisInstagramAccounts(): Promise<AdAccountList> {
    return get(routes.lisInstagramAccounts);
  }

  return {
    persist,
    encrypt,
    lisInstagramAccounts,
    loading,
  };
}
