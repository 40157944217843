import {
  PostPurchaseProps,
  TextualProps,
} from '@/features/editor/widgets/post-purchase/props';
import { RecommendationType } from '@/webapi/use-widget-catalog-api';
import { POST_PURCHASE_HEADER_OPTIONS } from '@/features/editor/widgets/post-purchase/inputs/header';
import { POST_PURCHASE_SUB_HEADER_OPTIONS } from '@/features/editor/widgets/post-purchase/inputs/sub-header';
import { POST_PURCHASE_COUNTDOWN_OPTIONS } from '@/features/editor/widgets/post-purchase/inputs/timer-countdown';

const defaultTextualStyles = {
  size: `medium`,
  color: `default`,
  bold: false,
  lineThrough: false,
} as TextualProps;

export const INITIAL_POST_PURCHASE_PROPS = {
  offers: [
    {
      loadingStrategy: RecommendationType.MOST_CHEAP_IN_CART,
      discountPercentage: 0.1,
    },
    {
      loadingStrategy: RecommendationType.MOST_CHEAP_IN_CART,
      discountPercentage: 0.1,
    },
  ],
  settings: {
    offersCount: 1,
    showTimer: true,
    ratingPlacement: `none`,
    ratingType: `stars-count`,
    summaryMode: `expanded`,
    showGallery: true,
    addTaxToFinalPrice: false,
  },

  content: {
    header: POST_PURCHASE_HEADER_OPTIONS[0].value,
    subHeader: POST_PURCHASE_SUB_HEADER_OPTIONS[0].value,
    countdown:
      POST_PURCHASE_COUNTDOWN_OPTIONS[
        POST_PURCHASE_COUNTDOWN_OPTIONS.length - 1
      ].value,
    productCallout1: ``,
    productCallout2: ``,
  },

  style: {
    timer: {
      containerStyle: {
        alignment: `center`,
        spacing: `tight`,
      },
      timerTile: {
        digitStyle: {
          ...defaultTextualStyles,
          size: `large`,
          color: `default`,
        },
        descStyle: {
          ...defaultTextualStyles,
          size: `small`,
          color: `light`,
        },
        hideDesc: {
          value: `false`,
        },
      },
      separator: {
        style: {
          ...defaultTextualStyles,
        },
        text: { value: `:` },
      },
    },
    header: {
      ...defaultTextualStyles,
      size: `medium`,
      color: `light`,
    },
    subHeader: { ...defaultTextualStyles, size: `medium`, color: `default` },
    prices: {
      beforeStyle: {
        ...defaultTextualStyles,
        size: `medium`,
        color: `light`,
        lineThrough: true,
      },
      afterStyle: {
        ...defaultTextualStyles,
        size: `medium`,
        color: `critical`,
        bold: true,
      },
    },
    rating: {
      starsStyle: {
        size: 120,
        spacing: `xtight`,
      },
      countStyle: {
        spacing: `loose`,
      },
    },
    callout: {
      ...defaultTextualStyles,
      size: `small`,
      color: `light`,
    },
    summary: {
      lineItemsStyle: {
        ...defaultTextualStyles,
        color: `light`,
        size: `small`,
        bold: true,
      },
      totalItemsStyle: {
        ...defaultTextualStyles,
        color: `default`,
        size: `medium`,
        bold: true,
      },
      promptStyle: {
        ...defaultTextualStyles,
        color: `light`,
      },
    },
    disclaimer: {
      ...defaultTextualStyles,
      size: `small`,
      color: `light`,
    },
    acceptCta: {
      textSize: `medium`,
      textColor: `default`,
      textBold: false,
      kind: `filled`,
    },
    declineCta: {
      textSize: `medium`,
      textColor: `default`,
      textBold: true,
      kind: `link`,
    },
  },

  summary: {
    tax: 0,
    shipping: 0,
  },
} as PostPurchaseProps;
