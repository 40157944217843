import React, { useContext } from 'react';
import styled from 'styled-components';
import { LayeredRulingContext } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import { Rule } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule';
import { VSpace } from '@/components/spacing';

export function Rules() {
  const ctx = useContext(LayeredRulingContext);

  const hideRules =
    ctx.state.showCondBuilder ||
    ctx.isProductSearchOpen ||
    ctx.isManualImageSelectionOpen;
  if (hideRules) {
    return null;
  }

  return (
    <>
      {ctx.state.rules.map((r) => (
        <Rule key={r.hash} id={r.id} />
      ))}
      <AddAnotherWrapper onClick={ctx.addRule}>
        + Add Another Rule
      </AddAnotherWrapper>
      <VSpace value={4} />
    </>
  );
}

const AddAnotherWrapper = styled.div`
  padding: 3rem 4rem;
  width: 100%;
  border-radius: 10px;
  margin-top: 2rem;

  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25),
    0 1px 34px 0 rgba(151, 210, 206, 0.32), 0 1px 14px 0 rgba(0, 0, 0, 0.1);
  border: 1px dashed #e0e6ea;
  background-color: #f5f7f8;
  color: #828f9c;
  font-family: 'JetBrains Mono', serif;
  font-weight: bold;
  font-size: 1.5rem;
  user-select: none;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  &:active {
    cursor: default;
    opacity: 1;
  }
`;
