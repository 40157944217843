import {
  AudienceModalContext,
  View,
} from '@/features/editor/widgets/shared/modals/audience/audience-context';
import {
  FBAudienceContext,
  FBView,
} from '@/features/editor/widgets/shared/modals/audience/facebook-audience/context';
import { AdAccount } from '@/features/integrations/facebook/models';
import { isEmpty } from '@/components/query-builder/models';
import { AccountContextProps } from '@/features/account-context';
import { onOpenFBOAuthPopup } from '@/features/integrations/facebook/api';
import { useFacebookApi } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/api';

export function getOnAction(
  modalCtx: AudienceModalContext,
  fbCtx: FBAudienceContext,
  accountCtx: AccountContextProps,
): () => void {
  const { encrypt } = useFacebookApi(false);
  if (modalCtx.view === View.FACEBOOK) {
    switch (fbCtx.step) {
      case FBView.CONNECT:
        return onOpenFBOAuthPopup(encrypt, accountCtx.account?.store?.alias);
      case FBView.AD_ACCOUNT_SELECT:
        return () => fbCtx.setStep(FBView.SEARCH);
      case FBView.SEARCH:
        return () => {
          if (!isEmpty(fbCtx.targeting.current)) {
            modalCtx.onSave(fbCtx.targeting.current, false);
            fbCtx.setStep(FBView.VALIDATION);
          } else {
            // please select something !
          }
        };
      case FBView.VALIDATION:
        return fbCtx.revalidate;
      case FBView.SUCCESS:
        return () => modalCtx.onSave(fbCtx.targeting.current);
      default:
        return () => modalCtx.onSave();
    }
  } else if (modalCtx.view === View.AUDIENCE_NAME) {
    return modalCtx.onAudienceSave;
  }
  return () => modalCtx.onSave();
}

export function getCTAText(
  audienceModalCtx: AudienceModalContext,
  facebookAudienceContext: {
    init: () => Promise<void>;
    step: FBView;
    adAccounts: Array<AdAccount>;
    markAdAccountAsSelected: (account: AdAccount) => void;
  },
) {
  let ctaText = `Select`;
  if (audienceModalCtx.view === View.FACEBOOK) {
    if (facebookAudienceContext.step === FBView.CONNECT) {
      ctaText = `Continue to Facebook`;
    } else if (
      [FBView.AD_ACCOUNT_SELECT, FBView.SEARCH].includes(
        facebookAudienceContext.step,
      )
    ) {
      ctaText = `Continue`;
    } else if (facebookAudienceContext.step === FBView.SUCCESS) {
      ctaText = `Great, let's start customizing`;
    } else if (facebookAudienceContext.step === FBView.VALIDATION) {
      ctaText = `Done, check again`;
    }
  }
  return ctaText;
}
