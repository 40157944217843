import React, { useCallback, useRef, useState } from 'react';

export type UseObservableRef<T> = [
  React.MutableRefObject<T>,
  (node) => void,
  number,
];

export function useObservableRef<T>(
  initValue: T | null | undefined,
): UseObservableRef<T> {
  const ref = useRef(initValue);
  const [changed, setChanged] = useState(0);

  const refSetter = useCallback((node) => {
    ref.current = node;
    setChanged((prev) => prev + 1);
  }, []);

  return [ref, refSetter, changed];
}
