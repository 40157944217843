import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import {
  SharedElementOverlay,
  SharedElementOverlayProps,
} from '@/components/shared-element-overlay';
import { isBrowser } from '@/utils/browser';
import { useEscapeKey } from '@/features/editor/widgets/custom-widget/inputs/shared/useControlPopover';

export interface SharedElementOptions {
  showBackdrop?: boolean;
  heightMultiplier?: number;
  widthMultiplier?: number;
  extraFrom?: Record<string, any>;
  extraTo?: Record<string, any>;
  onCloseCb?: () => void;
}

export function useSharedElement(
  options?: SharedElementOptions,
  from?: React.MutableRefObject<HTMLElement>,
  to?: () => DOMRect,
): UseSharedElement {
  const [isVisible, setIsVisible] = useState(false);
  const visibleRef = useRef(isVisible);
  useEffect(() => {
    visibleRef.current = isVisible;
  }, [isVisible]);
  useEscapeKey(() => {
    if (visibleRef.current) {
      onClose(options);
      setIsVisible(false);
    }
  });
  const fromRef = from || useRef<HTMLElement>(null);
  const toRef = useRef<HTMLElement>({
    getBoundingClientRect: to,
  } as HTMLElement);

  const closeOnBackdropClick = (ev) => {
    ev.stopPropagation();
    onClose(options);
    setIsVisible(false);
  };

  const props: SharedElementOverlayProps = {
    isVisible,
    fromElement: fromRef,
    toPosition: toRef,
    onBackdropClick: closeOnBackdropClick,
    ...options,
  };

  const show = () => {
    setIsVisible(true);
  };

  const hide = () => {
    onClose(options);
    setIsVisible(false);
  };

  return { SharedElementOverlay, props, fromRef, toRef, show, hide };
}

export interface UseSharedElement {
  SharedElementOverlay;
  props: SharedElementOverlayProps;
  fromRef: MutableRefObject<any>;
  toRef: MutableRefObject<any>;
  show: () => void;
  hide: () => void;
}

const REM_MULTIPLIER = 10; // Based on 62.5% of 16px on the root font-size => 1rem = 10px

export function centered(
  heightRem: number,
  widthRem: number,
  topScale = 1,
): DOMRect {
  if (isBrowser()) {
    const vpW = window.innerWidth;
    const vpH = window.innerHeight;
    const vpHRem = vpH / REM_MULTIPLIER;
    const height = Math.min(heightRem, vpHRem - 6) * REM_MULTIPLIER;
    const width = widthRem * REM_MULTIPLIER;
    return {
      height,
      width,
      left: vpW / 2 - width / 2,
      top: (vpH / 2 - height / 2) * topScale,
      right: 0,
      bottom: 0,
    } as DOMRect;
  }
  return {
    height: 0,
    width: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  } as DOMRect;
}

export function verticalCentered(
  heightRem: number,
  widthRem: number,
  leftRem: number,
  topScale = 1,
): DOMRect {
  if (isBrowser()) {
    const vpH = window.innerHeight;
    const height = heightRem * REM_MULTIPLIER;
    const width = widthRem * REM_MULTIPLIER;
    return {
      height,
      width,
      left: leftRem,
      top: (vpH / 2 - height / 2) * topScale,
      right: 0,
      bottom: 0,
    } as DOMRect;
  }
  return {
    height: 0,
    width: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  } as DOMRect;
}

export function fixedHeight(
  heightRem: number,
  ref: React.MutableRefObject<HTMLElement>,
  topScale = 1,
): DOMRect {
  if (isBrowser()) {
    const rect = ref?.current?.getBoundingClientRect();
    const vpH = window.innerHeight;
    const height = heightRem * REM_MULTIPLIER;
    return {
      height,
      width: rect.width,
      left: rect.left,
      top: (vpH / 2 - height / 2) * topScale,
      right: 0,
      bottom: 0,
    } as DOMRect;
  }
  return {
    height: 0,
    width: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  } as DOMRect;
}

function onClose(options: SharedElementOptions) {
  options?.onCloseCb && options?.onCloseCb();
}
