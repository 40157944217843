import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flexbox } from '@/components/flex';
import { SelectOptions, SmallSelect } from '@/components/small-select';
import { QBNumberInput } from '@/components/query-builder/shared';
import { TimeframeUnit } from '@/components/query-builder/models';

const timeframeOptions: SelectOptions[] = [
  { key: TimeframeUnit.HOURS, value: `Hours` },
  { key: TimeframeUnit.DAYS, value: `Days` },
  { key: TimeframeUnit.WEEKS, value: `Weeks` },
];

export function QBTimeframeSuffix({
  onChange,
  initialValue,
}: {
  initialValue: { value: number; unit: TimeframeUnit };
  onChange: ({ value: number, unit: TimeframeUnit }) => void;
}) {
  const [value, setValue] = useState(initialValue?.value || 7);
  const [unit, setUnit] = useState(initialValue?.unit || TimeframeUnit.DAYS);

  const onValueChange = (ev) => {
    setValue(ev.target.value);
  };

  const onUnitChanged = (unit: TimeframeUnit) => {
    setUnit(unit);
  };

  useEffect(() => {
    onChange({ value, unit });
  }, [value, unit]);

  return (
    <Wrapper>
      <span>In the last</span>
      {` `}
      <QBNumberInput defaultValue={value} onChange={onValueChange} />
      {` `}
      <SmallSelect
        options={timeframeOptions}
        onSelection={onUnitChanged}
        defaultValue={unit}
      />
    </Wrapper>
  );
}

const Wrapper = styled(Flexbox)`
  && {
    flex-direction: row;
    align-items: center;

    margin-bottom: 1.5rem;

    span {
      color: #959ab5;
    }

    input {
      min-width: 5rem;
      max-width: 5rem;
    }

    && > :not(:last-child) {
      margin-right: 1rem;
    }
  }
`;
