import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { SelectOptions, SmallSelect } from '@/components/small-select';
import { Flexbox } from '@/components/flex';
import {
  CustomOption,
  OrButton,
  QBCaption,
  QBRemove,
  QBColon,
  QBVSpace,
  shouldShowOrCondition,
} from '@/components/query-builder/shared';
import { QBContext } from '@/components/query-builder/context';
import { getGUID } from '@/utils/browser';
import { QBTimeframeSuffix } from '@/components/query-builder/timeframe-suffix';
import { RecommendationType } from '@/webapi/use-widget-catalog-api';
import { hash } from '@/utils/cache';

export enum ArrayValueOps {
  IS = `is`,
  ISNOT = `isnot`,
}

export const arrayValueOptions: SelectOptions[] = [
  {
    key: ArrayValueOps.IS,
    value: `Is`,
  },
  {
    key: ArrayValueOps.ISNOT,
    value: `Is not`,
  },
];

export function QBArrayValue({ idx }: { idx: number }) {
  const {
    queryState,
    appendValue,
    updateValue,
    removeValue,
    removeKey,
    updateTimeframe,
  } = useContext(QBContext);
  const current = queryState[idx];

  const onOpChanged = (childIdx, selected) => {
    updateValue(idx, childIdx, selected, current?.values?.[childIdx]?.value);
  };

  const onOptionChanged = (childIdx, selected) => {
    updateValue(idx, childIdx, current?.values?.[childIdx]?.op, selected);
  };

  const onAddMore = () => {
    appendValue(
      idx,
      current?.qbProps.defaultIsNot ? ArrayValueOps.ISNOT : ArrayValueOps.IS,
      current?.qbProps?.options?.[0].key as string,
    );
  };

  const onRemove = (childIdx: number) => {
    if (current.values.length === 1) {
      removeKey(idx);
    } else {
      removeValue(idx, childIdx);
    }
  };
  let hideRmBtn = queryState?.length <= 1 && current?.values?.length === 1;
  let opSelectDisabled = false;
  let valueSelectDisabled = false;
  if (current.qbProps.envKey === RecommendationType.PAST_PURCHASES) {
    hideRmBtn = idx === 0;
    opSelectDisabled = true;
    valueSelectDisabled = true;
  } else if (current.qbProps.envKey === RecommendationType.CART_ITEMS) {
    hideRmBtn = idx === 0 || idx === 1;
    opSelectDisabled = true;
    valueSelectDisabled = idx === 0;
  }
  const [valueObj, setValueObj] = useState([
    current?.qbProps?.options?.find(
      (o) => o.key === current?.values?.[0]?.value,
    ),
  ]);
  useEffect(() => {
    const result = [];
    current.values.forEach((v, i) => {
      result[i] = current?.qbProps?.options?.find((o) => o.key === v?.value);
    });
    setValueObj(result);
  }, [current?.values]);

  const tooltipRef = useRef(null);
  const [isShowTooltip, setIsShowTooltip] = useState(true);

  return (
    <Wrapper>
      {current?.values?.map((_, childIdx) => (
        <React.Fragment key={`arrayidxx-${childIdx}`}>
          <Row>
            {childIdx > 0 && <LogicalOr>Or</LogicalOr>}
            <SmallSelect
              isDisabled={opSelectDisabled || current.qbProps.disableOp}
              minWidth={70}
              options={arrayValueOptions}
              onSelection={(selected) => onOpChanged(childIdx, selected)}
              defaultValue={current?.values?.[childIdx]?.op}
            />
            <QBColon />
            <div
              data-tip={valueObj[childIdx]?.value?.toUpperCase() || ``}
              data-for={`select-${hash(
                valueObj[childIdx]?.value?.toUpperCase() || ``,
              )}-${childIdx}-${idx}`}
            >
              <SmallSelect
                isDisabled={valueSelectDisabled}
                key={getGUID()}
                components={
                  current?.qbProps?.options?.find((o) => !!o.image) && {
                    Option: CustomOption(
                      current.qbProps.options,
                      current?.values?.[childIdx]?.value,
                    ),
                  }
                }
                onMenuOpen={() => {
                  if (tooltipRef?.current?.hidden)
                    tooltipRef.current.hidden = true;
                }}
                onMenuClose={() => {
                  if (tooltipRef?.current?.hidden)
                    tooltipRef.current.hidden = false;
                  setIsShowTooltip(false);
                  setTimeout(() => {
                    setIsShowTooltip(true);
                  }, 100);
                }}
                options={current.qbProps.options}
                onSelection={(selected) => onOptionChanged(childIdx, selected)}
                defaultValue={current?.values?.[childIdx]?.value}
              />
            </div>
            <div ref={tooltipRef}>
              {isShowTooltip &&
                (valueObj[childIdx]?.value?.length >= 24 ||
                  valueObj[childIdx]?.image) && (
                  <ReactTooltip
                    id={`select-${hash(
                      valueObj[childIdx]?.value?.toUpperCase() || ``,
                    )}-${childIdx}-${idx}`}
                    effect="float"
                    place="top"
                    backgroundColor="#fff"
                    textColor="#000"
                    className="text-tooltip"
                  >
                    {valueObj[childIdx]?.image && (
                      <Image
                        src={valueObj[childIdx]?.image || ``}
                        alt={valueObj[childIdx]?.value?.toUpperCase() || ``}
                      />
                    )}
                    <span>
                      {valueObj[childIdx]?.value?.toUpperCase() || ``}
                    </span>
                  </ReactTooltip>
                )}
            </div>
            <QBRemove
              shouldHide={hideRmBtn}
              onClick={() => onRemove(childIdx)}
            />
          </Row>
          <QBVSpace />
        </React.Fragment>
      ))}
      {queryState[idx].qbProps.hasTimeframe && (
        <QBTimeframeSuffix
          onChange={(v) => updateTimeframe(idx, v)}
          initialValue={queryState[idx].timeframe}
        />
      )}
      {shouldShowOrCondition(current) && (
        <OrButton onClick={onAddMore}>|| Or</OrButton>
      )}
    </Wrapper>
  );
}

const Image = styled.img`
  object-fit: cover;
  height: 4rem;
  width: 4rem;
`;

const Wrapper = styled(Flexbox)`
  && {
    flex-direction: column;
  }
`;

const Row = styled(Flexbox)`
  && {
    position: relative;
    flex-direction: row;
  }
`;

const LogicalOr = styled(QBCaption)`
  && {
    position: absolute;
    top: calc(50% - 1rem);
    left: -4rem;
  }
`;
