import React from 'react';
import styled from 'styled-components';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { QBCaption } from '@/components/query-builder/shared';
import { OverflowRight } from '@/components/overflow';
import { StrategyTile } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule/strategy/strategyTile';
import { useScroll } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule/strategy/useScroll';
import { useAutoScroll } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule/strategy/useAutoScroll';

type Props = { ruleId: number };

export function Strategy({ ruleId }: Props) {
  const {
    scrollRef,
    options,
    showPrev,
    showNext,
    onPrevHover,
    onPrevExitHover,
    onNextHover,
    onNextExitHover,
  } = useScroll();
  useAutoScroll(options, scrollRef);

  return (
    <Wrapper>
      <Where>THEN SHOW</Where>
      <ScrollerWrapper>
        <PrevButton
          isShown={showPrev}
          onMouseEnter={onPrevHover}
          onMouseLeave={onPrevExitHover}
        >
          <FaChevronLeft size={22} />
        </PrevButton>
        {showPrev && <OverflowLeft className="overflow" left="-1rem" />}
        <Scroller size={options.length} ref={scrollRef}>
          {options.map((strategy) => (
            <StrategyTile
              key={strategy.label}
              ruleId={ruleId}
              strategy={strategy}
            />
          ))}
        </Scroller>
        <NextButton
          isShown={showNext}
          onMouseEnter={onNextHover}
          onMouseLeave={onNextExitHover}
        >
          <FaChevronRight size={22} />
        </NextButton>
        <OverflowRight className="overflow" right="-1rem" />
      </ScrollerWrapper>
    </Wrapper>
  );
}
const ScrollerWrapper = styled.div`
  position: relative;
  height: auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Where = styled(QBCaption)`
  && {
    margin-top: 2rem;
    margin-right: 1rem;
  }
`;

const Scroller = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  padding: 10px;
  margin: -10px;
  overflow-x: scroll;
  grid-template-columns: repeat(${(p) => p.size}, max-content) 2rem;
  grid-gap: 0;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: center;
`;

interface BW {
  isShown?: boolean;
}

const NextButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 4rem;
  height: 5rem;
  margin-top: 5rem;
  background-color: transparent;
  z-index: 100;

  color: #cbcbcb;
  font-weight: bolder;

  opacity: ${(p: BW) => (p?.isShown ? 1 : 0)};
  pointer-events: ${(p: BW) => (p?.isShown ? `auto` : `none`)};
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-out;

  :hover {
    color: black;
  }

  cursor: e-resize;
`;

const PrevButton = styled(NextButton)`
  && {
    left: 0;
    cursor: w-resize;
  }
`;

export const OverflowLeft = styled.div`
  height: 100%;
  transition: height 0.6s ease;
  width: ${(props) => (props.width ? props.width : `5rem`)};
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => (props.left ? props.left : `-5px`)};
  background: linear-gradient(
    90deg,
    rgba(${(props) => (props.background ? props.background : `255,255,255`)}, 1)
      0%,
    rgba(
        ${(props) => (props.background ? props.background : `255,255,255`)},
        ${(props) => (props?.stickyEnabled ? 0.9 : 0.8)}
      )
      ${(props) => (props.turnPoint ? props.turnPoint : `60%`)},
    rgba(${(props) => (props.background ? props.background : `255,255,255`)}, 0)
      100%
  );
  z-index: 28;
  pointer-events: none;
`;
