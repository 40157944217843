import { CatalogApp, Spec } from '@/webapi/use-widget-catalog-api';
import { ReviewsData } from '@/webapi/use-store-settings-api';

export interface SpecFiltersEnv {
  reviews: ReviewsData;
  isColorSwatchEnabled: boolean;
}

export function filterAppsCatalogSpecs(
  catalog: CatalogApp[],
  env: SpecFiltersEnv,
): CatalogApp[] {
  return catalog?.map((app) => ({
    ...app,
    widgets: app?.widgets?.map((widget) => {
      const r = {
        ...widget,
      };
      if (r?.blueprint?.schema?.settings?.general?.specs) {
        r.blueprint.schema.settings.general.specs =
          widget.blueprint.schema.settings.general.specs.map((spec) => {
            if (shouldFilterOutGeneralOption(spec, env)) {
              spec.hidden = true;
            }
            return spec;
          });
      }
      return r;
    }),
  }));
}

export function filterUpsellDiscounts(catalog: CatalogApp[]): CatalogApp[] {
  return catalog?.map((app) => ({
    ...app,
    widgets: app?.widgets?.map((widget) => {
      const r = {
        ...widget,
      };
      r.blueprint.schema.customizations =
        r.blueprint.schema.customizations.filter(
          (cust) => cust.key !== `discounts`,
        );
      r.blueprint.schema.settings.quickEdit =
        r.blueprint.schema.settings.quickEdit.filter(
          (qe) => !qe.selector.includes(`discounts.discount`),
        );
      return r;
    }),
  }));
}

export function shouldFilterOutGeneralOption(
  spec: Spec,
  env: SpecFiltersEnv,
): boolean {
  return (
    (spec.key === `reviewsPlacement` && !env.reviews.hasReviewProviders) ||
    (spec.key === `showColorsSwatch` && !env.isColorSwatchEnabled)
  );
}
