import * as React from 'react';
import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  VisualEditorContext,
  VisualEditorCtx,
} from '@/features/editor/widgets/visual-editor/context';
import { EditorContext } from '@/features/editor/context/editor-context';
import { useTextAltApi } from '@/features/editor/shared/use-alt-text';
import { Spinner } from '@/Spinner';
import { VSpace } from '@/components/spacing';
import { hideLoader, showLoader } from '@/components/PageLoader';
import { GlossyWrapper } from '@/components/glossy-wrapper';
import { DeviceType } from '@/utils/definitions';

function getPathname() {
  const {
    deviceNavigation: { previewUrl },
  } = useContext(EditorContext);
  return maybe(() => new URL(previewUrl).pathname) || ``;
}

export const TextAlternatives: FC<any> = () => {
  const visualEditorCtx = useContext(VisualEditorContext);
  const { value: initialValue, setValue } =
    getInitialTextValue(visualEditorCtx);
  const { isHeader, isHomePage, collectionName, productName } =
    getPathContext(visualEditorCtx);

  const { getAltText, loading, options } = useTextAltApi();
  const [selected, setSelected] = useState(initialValue);

  useEffect(() => {
    getAltText(
      getQuery(initialValue, isHeader, isHomePage, collectionName, productName),
      5,
    ).then();
  }, []);

  const clickHandler = (o: string) => () => {
    if (loading) return;
    setSelected(o);
    showLoader();
    setTimeout(hideLoader, 1100);
    setValue(o);
  };
  const initialLoader = loading && options.length === 0;
  if (initialLoader) {
    return (
      <Center>
        <Spinner size={80} />
      </Center>
    );
  }
  const opts = [initialValue, ...options];
  return (
    <Wrapper>
      <SubTitle>Click to select a different text copy</SubTitle>
      <VSpace value={2} />
      <ChipsContainers>
        {opts.map((o) => (
          <Chip key={o} onClick={clickHandler(o)} selected={selected === o}>
            <span>{o}</span>
          </Chip>
        ))}
      </ChipsContainers>
      <VSpace value={7} />
    </Wrapper>
  );
};

function getInitialTextValue(visualEditorCtx: VisualEditorCtx) {
  const customization = visualEditorCtx.visualProps.customizations?.[0];
  const component = customization?.components?.find((c) => c.key === `content`);
  const value = component?.specs?.find((s) => s.type === `TEXT_EDIT`)?.values
    ?.value;

  const specIdx = component?.specs?.findIndex((s) => s.type === `TEXT_EDIT`);

  const compIdx = customization?.components?.findIndex(
    (c) => c.key === `content`,
  );
  const {
    devicePreview: {
      editorState: { device: editorDevice },
    },
  } = useContext(EditorContext);

  function setValue(value: string) {
    visualEditorCtx.setResponsiveValue(
      0,
      compIdx,
      specIdx,
      `value`,
      `${value} `, // forces change even if there is no difference with initial state
      visualEditorCtx.device || editorDevice,
    );
  }
  return { value, setValue };
}

function getSize(size = `1em`, parent = document.body) {
  const l = document.createElement(`div`);
  l.style.visibility = `hidden`;
  // @ts-ignore
  l.style.boxSize = `content-box`;
  l.style.position = `absolute`;
  l.style.maxHeight = `none`;
  l.style.height = size;
  parent.appendChild(l);
  // @ts-ignore
  size = l.clientHeight;
  l.remove();
  return size;
}

function getPathContext(visualEditorCtx: VisualEditorCtx) {
  const isHeader = isTitle(visualEditorCtx);
  let pathname: string = getPathname();
  const isHomePage = pathname === `` || pathname === `/`;
  if (pathname.startsWith(`/`)) {
    pathname = pathname.slice(1);
  }
  const pathParts = pathname.split(`/`);
  const isCollection = maybe(
    () => pathParts.length === 2 && pathParts[0] === `collections`,
  );
  const collectionName = maybe(
    () => isCollection && pathParts[1].replace(`-`, ` `),
  );
  const isProduct = maybe(
    () => pathParts.length === 2 && pathParts[0] === `products`,
  );
  const productName = maybe(() => isProduct && pathParts[1]?.replace(`-`, ` `));
  return { isHeader, pathname, isHomePage, collectionName, productName };
}

function isTitle(visualEditorCtx: VisualEditorCtx) {
  const fontSize = visualEditorCtx.visualProps.customizations?.[0]?.components
    ?.find((c) => c.key === `style`)
    ?.specs?.find((s) => s.type === `TYPOGRAPHY`)?.values?.fontSize;
  const size = getSize(fontSize) || 20;
  return size > 22;
}

function maybe(f: () => any) {
  try {
    return f();
  } catch (e) {
    return undefined;
  }
}

const SubTitle = styled.span`
  font-family: Inter, serif;
  color: #838f9c;
  font-size: 1.4rem;
  font-weight: 700;
  user-select: none;
`;

function getQuery(
  initialValue,
  isHeader: boolean,
  isHomePage: boolean,
  collectionName,
  productName,
) {
  return `rephrase '${initialValue}' as a ${
    isHeader ? `title` : `short description`
  }, in an ecommerce website ${isHomePage ? `homepage` : ``} ${
    collectionName ? `collection page (${collectionName})` : ``
  } ${productName ? `product page (${productName})` : ``}`;
}

const Chip = styled.li`
  margin: 1rem;
  font-family: 'JetBrains Mono', serif;
  list-style-type: none;

  cursor: pointer;
  padding: 1.2rem 2.2rem;
  border-radius: 40px;
  user-select: none;
  font-weight: 600;
  transition: opacity 0.2s ease-out;

  background-color: ${(p: any) => (p.selected ? `#ffffff` : `#dfe6e9`)};
  box-shadow: ${(p: any) =>
    p.selected
      ? ` rgba(157, 255, 221, 0.18) 0px 9px 13px 0px, rgba(0, 0, 0, 0.12) 0px 2px 10px 0px`
      : `none`};

  color: #060606;

  :hover {
    opacity: 0.8;
    cursor: pointer;
  }

  :active {
    opacity: 0.6;
    cursor: default;
  }

  font-size: 1.4rem;
`;

export const TextAltFooter = styled(GlossyWrapper)`
  && {
    left: -0.5rem;
    width: ${(props: { device: DeviceType; fb: boolean }) =>
      props.device === DeviceType.Desktop ? `100%` : `60%`};
    position: absolute;
    bottom: 2rem;
    backdrop-filter: none;
    background: none;
  }
`;

const ChipsContainers = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
`;

const Center = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100%;
`;
