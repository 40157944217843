import React, { useContext } from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { VSpace } from '@/components/spacing';
import { TextInput } from '@/components/text-input';
import { AudiencesContext } from '@/features/dashboard/audiences/context';
import { Audience } from '@/features/dashboard/audiences/models';

export function AudienceDescriptionForm({
  audienceToEdit,
  onChange,
  setDescription,
  value,
  description,
}: {
  audienceToEdit?: Audience;
  value: string;
  onChange: (ev) => void;
  description: string;
  setDescription: (ev) => void;
}) {
  const shouldOverride = audienceToEdit?.connectedExperiences > 0;
  return (
    <Name>
      {shouldOverride ? (
        <p>
          Since there are connected live experiences, you cannot override the
          old audience <br />
          rules, but you can save this as a new audience
        </p>
      ) : (
        <VSpace />
      )}
      <Label>Audience name</Label>
      <VSpace />
      <NameText placeholder="" value={value} onChange={onChange} />

      <VSpace value={2} />

      <Label>Audience description (optional)</Label>
      <VSpace />
      <TextArea value={description} onChange={setDescription} />
    </Name>
  );
}

export function NameForm() {
  const { name, setName, setDescription, description, audienceToEdit } =
    useContext(AudiencesContext);

  return (
    <AudienceDescriptionForm
      audienceToEdit={audienceToEdit}
      value={name}
      onChange={(ev) => setName(ev.target.value)}
      description={description}
      setDescription={(ev) => setDescription(ev.target.value)}
    />
  );
}

const Name = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 1.5rem;
`;

const NameText = styled(TextInput)`
  && {
    padding: 2rem;
    width: 40rem;
    text-align: start;
    font-family: 'JetBrains Mono', serif;
  }
`;

const TextArea = styled(TextareaAutosize)`
  && {
    font-family: 'JetBrains Mono', serif;
    appearance: none;
    -webkit-appearance: none;
    pointer-events: auto;
    outline: none;
    border: 1.5px solid #c7c8cf;
    border-radius: 10px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
    text-align: left;
    color: #5b656e;
    width: 40rem;
    font-size: 1.6rem;

    &&::-webkit-outer-spin-button,
    &&::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &&[type='number'] {
      -moz-appearance: textfield;
    }

    resize: none;
    letter-spacing: -0.44px;
    min-height: 15rem;

    padding: 2rem;
    margin: 0;
  }
`;

const Label = styled.span`
  width: 40rem;
  text-align: start;
  color: #b9c0c7;
  font-size: 1.4rem;
  font-family: Inter, serif;
  font-weight: 500;
`;
