import { CachePolicies, useFetch } from 'use-http';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { routes } from '@/webapi/routes';
import { breakpoints } from '@/components/responsive';

export interface DemoVideoLegendProps {
  videoElem: HTMLVideoElement | undefined;
}

export function DemoVideoLegend({ videoElem }: DemoVideoLegendProps) {
  const { get } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: true,
    },
  }));
  const [legend, setLegend] = useState<Legend | undefined>(undefined);
  const [allChapters, setAllChapters] = useState<Chapter[] | undefined>(
    undefined,
  );

  const [selectedMoment, setSelectedMoment] = useState<Chapter | undefined>();
  const [selectedChapter, setSelectedChapter] = useState<Chapter | undefined>();

  useEffect(() => {
    get(routes.demoVideoLegend()).then((resp) => {
      setAllChapters(resp.chapters);
      setLegend(toLegend(resp as VideoMetadata));
    });
  }, []);

  const onChapterClicked = (chapter: Chapter | KeyMoment) => {
    if (videoElem) {
      videoElem.currentTime = chapter.start > 0 ? chapter.start : 1;
      videoElem.play();
    } else {
      console.error(`failed to get video reference`);
    }
  };

  const onTimeUpdate = (ev) => {
    if (allChapters) {
      const chapterIdx = allChapters.findIndex(
        (ch) =>
          !ch.title.includes(`#`) &&
          ch.start <= ev.target.currentTime &&
          ch.end >= ev.target.currentTime,
      );
      setSelectedChapter(allChapters[chapterIdx]);

      if (chapterIdx >= 0) {
        const moment = allChapters
          .slice(0, chapterIdx)
          .filter((ch) => ch.title.includes(`#`))
          .slice(-1)?.[0];
        setSelectedMoment(moment);
      } else {
        const moment = allChapters.find(
          (ch) => ch.title.includes(`#`) && ch.end > ev.target.currentTime,
        );
        setSelectedMoment(moment);
      }
    }
  };

  useEffect(() => {
    if (videoElem) {
      videoElem.addEventListener(`timeupdate`, onTimeUpdate);
    }

    return () => {
      if (videoElem) {
        videoElem.removeEventListener(`timeupdate`, onTimeUpdate);
      }
    };
  }, [videoElem]);

  return (
    <Wrapper className="demo-video-legend">
      {legend?.groups?.map((group, idx) => (
        <Group className="demo-video-legend-group" key={idx}>
          {group?.map((moment) => (
            <Moment className="demo-video-legend-moment" key={moment.title}>
              <span
                className={`moment demo-video-legend-chapter ${
                  moment?.title === selectedMoment?.title ? `selected` : ``
                }`}
                onClick={() => onChapterClicked(moment)}
              >
                {formatChapter(moment)}
              </span>
              {moment.chapters.map((chapter) => (
                <span
                  key={chapter.title}
                  className={`chapter ${
                    chapter?.title === selectedChapter?.title ? `selected` : ``
                  }`}
                  onClick={() => onChapterClicked(chapter)}
                >
                  {formatChapter(chapter)}
                </span>
              ))}
            </Moment>
          ))}
        </Group>
      ))}
      <div className="only-mobile" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 7rem;

  ${breakpoints.down(`md`)} {
    max-width: 100%;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 60vw) 5rem;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-snap-align: center;
    padding-left: 4rem;
    grid-gap: 4rem;
  }
`;

const Group = styled.div`
  scroll-snap-align: center;
`;

const Moment = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  color: #a6afb8;
  font-family: Eesti, sans-serif;
  gap: 1.2rem;

  &&:not(:first-child) {
    margin-top: 2rem;
  }

  span {
    ${breakpoints.down(`md`)} {
      min-width: 72vw;
    }

    transition: color 0.2s ease-in-out;
    cursor: pointer;
  }

  span:hover {
    color: white;
  }

  span.moment {
    font-size: 1.5rem;
    user-select: none;
    font-weight: bold;

    &.selected {
      filter: brightness(1.5);
    }
  }

  span.chapter {
    break-before: avoid;
    user-select: none;
    font-size: 1.3rem;
    font-weight: normal;
    margin-left: 2rem;

    &.selected {
      filter: brightness(1.5);
    }
  }
`;

function toLegend(md: VideoMetadata): Legend {
  const out: Legend = {
    groups: [[], [], []],
  };

  const moments: KeyMoment[] = [];
  let currentMoment = ``;
  md?.chapters?.forEach((chapter) => {
    if (isKeyMoment(chapter)) {
      currentMoment = chapter.title;
      moments.push({
        title: chapter.title,
        start: chapter.start,
        end: chapter.end,
        chapters: [],
      });
    } else {
      const moment = moments.find((m) => m.title === currentMoment);
      moment?.chapters?.push(chapter);
    }
  });

  moments.forEach((moment) => {
    if (getKeyMomentGroup(moment) === 1) {
      out.groups[0].push(moment);
    }
    if (getKeyMomentGroup(moment) === 2) {
      out.groups[1].push(moment);
    }
    if (getKeyMomentGroup(moment) === 3) {
      out.groups[2].push(moment);
    }
  });
  return out;
}

function isKeyMoment(chapter: Chapter | KeyMoment): boolean {
  return (
    chapter.title.startsWith(`#1#`) ||
    chapter.title.startsWith(`#2#`) ||
    chapter.title.startsWith(`#3#`)
  );
}

function getKeyMomentGroup(chapter: Chapter | KeyMoment): number {
  if (chapter.title.startsWith(`#1#`)) {
    return 1;
  }
  if (chapter.title.startsWith(`#2#`)) {
    return 2;
  }
  if (chapter.title.startsWith(`#3#`)) {
    return 3;
  }
  return 0;
}

function formatChapter(chapter: Chapter | KeyMoment): string {
  const minutes = Math.floor(chapter.start / 60) || 0;
  const seconds = chapter.start - minutes * 60 || 0;
  return `${chapter.title
    .replace(`#1#`, ``)
    .replace(`#2#`, ``)
    .replace(`#3#`, ``)} ${minutes <= 9 ? `0` : ``}${minutes}:${
    seconds <= 9 ? `0` : ``
  }${seconds}`;
}

interface VideoMetadata {
  chapters: Array<Chapter>;
}

interface Chapter {
  title: string;
  start: number;
  end: number;
}

interface KeyMoment {
  title: string;
  start: number;
  end: number;
  chapters: Array<Chapter>;
}

interface Legend {
  groups: KeyMoment[][];
}
