import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';
import { DeviceType } from '@/utils/definitions';
import { CodeEditorMonacoWrapper } from '@/features/editor/widgets/code-editors/shared';
import { MonacoEditor } from '@/features/editor/widgets/code-editors/monaco-editor';
import { Checkbox } from '@/components/checkbox';
import { EditorContext } from '@/features/editor/context/editor-context';

export function JavascriptEditInput(props: GenericInputProps) {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);
  const { specIdx } = props;
  const { undoRedoCount } = useContext(UndoRedoCounterContext);

  const { initialValues, onValuesChanged } = props;

  const [isEnabled, setIsEnabled] = useState(
    initialValues(`isEnabled`, DeviceType.Mobile, specIdx),
  );
  const [isExpanded, setIsExpanded] = useState(false);

  const [code, setCode] = useState(
    initialValues(`value`, DeviceType.Mobile, specIdx),
  );

  const onCodeChanged = (newCode: string) => {
    setCode(newCode);
  };

  useEffect(() => {
    onValuesChanged(`isEnabled`, isEnabled, DeviceType.Mobile);
    onValuesChanged(`value`, code, DeviceType.Mobile);
  }, [code, isEnabled]);

  const onToggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <Wrapper key={undoRedoCount} isExpanded={isExpanded}>
      <Header>
        {!isExpanded && (
          <Checkbox
            size="small"
            device={device}
            initialValue={isEnabled}
            onSelection={setIsEnabled}
            caption="Enable this code extension"
          />
        )}
        {isEnabled && (
          <span className="code-expand-button" onClick={onToggleExpand}>
            {isExpanded ? ` Minimize ` : ` Maximize `}
          </span>
        )}
      </Header>
      {isEnabled && (
        <div className="code-editor">
          <CodeEditorMonacoWrapper>
            <MonacoEditor
              lang="javascript"
              code={code}
              onCodeChange={onCodeChanged}
              readOnly={false}
            />
          </CodeEditorMonacoWrapper>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  position: ${(p) => (p.isExpanded ? `fixed` : `relative`)};
  padding: ${(p) => (p.isExpanded ? `2rem` : `0`)};
  background: ${(p) => (p.isExpanded ? `white` : `unset`)};
  margin: ${(p) => (p.isExpanded ? `0!important` : `unset`)};
  height: ${(p) => (p.isExpanded ? `100vh` : `unset`)};
  width: ${(p) => (p.isExpanded ? `100vw` : `unset`)};
  z-index: ${(p) => (p.isExpanded ? `100` : `unset`)};
  inset: 0;

  .code-editor {
    height: ${(p) => (p.isExpanded ? `94vh` : `40rem`)};
  }

  .code-expand-button {
    cursor: pointer;
    color: #6d6f71;
  }

  .code-expand-button:hover {
    text-decoration: underline;
  }

  .checkbox_wrapper {
    gap: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;
