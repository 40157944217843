import React from 'react';
import styled from 'styled-components';
import {
  DropdownInput,
  DropdownInputOption,
} from '@/features/editor/widgets/custom-widget/inputs/shared/dropdown';
import {
  GenericInputProps,
  InputsLightTheme,
} from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { useDropdownInputUpdater } from '@/features/editor/widgets/custom-widget/inputs/shared/use-dropdown-updater';

const VALUE_KEY = `textTransform`;

const options: DropdownInputOption[] = [
  { label: `Aa`, value: `none` },
  { label: `AA`, value: `uppercase` },
  { label: `aa`, value: `lowercase` },
];

export function TextTransformInput({
  customizationIdx,
  customization,
  componentIdx,
  component,
  specIdx,
  spec,
  device,
  initialValues,
  onValuesChanged,
}: GenericInputProps) {
  const { getValue, onChange, undoRedoCount } = useDropdownInputUpdater(
    {
      customizationIdx,
      customization,
      componentIdx,
      component,
      specIdx,
      spec,
      device,
      initialValues,
      onValuesChanged,
    },
    VALUE_KEY,
    options,
  );

  return (
    <Wrapper>
      <DropdownInput
        key={undoRedoCount}
        defaultValue={getValue()}
        options={options}
        theme={InputsLightTheme}
        onChange={onChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  && > div > div > div {
    div:nth-child(1) > div {
      text-transform: none !important;
    }

    div:nth-child(2) {
      position: absolute;
      right: 0.3rem;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
    }
  }
`;
