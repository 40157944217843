import * as React from 'react';
import { useContext } from 'react';
import { StylingComponentsGroup } from '@/features/editor/widgets/post-purchase/styling/group';
import { PostPurchaseTextualInput } from '@/features/editor/widgets/post-purchase/inputs/textual';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';

export function PostPurchaseSubHeaderStyles() {
  const { props, setProps } = useContext(PostPurchaseContext);

  return (
    <StylingComponentsGroup title="Content Style">
      <PostPurchaseTextualInput
        defaultValue={props.style.subHeader}
        onChange={(p) =>
          setProps((draft) => {
            draft.style.subHeader = p;
          })
        }
      />
    </StylingComponentsGroup>
  );
}
