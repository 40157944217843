/* eslint-disable no-promise-executor-return */
import { useContext } from 'react';
import { AccountContext } from '@/features/account-context';
import { routes } from '@/webapi/routes';

export function useReportError() {
  const {
    account: { store },
  } = useContext(AccountContext);
  const isStage = routes.getEnv() === 1;

  async function reportErr(err: any) {
    console.error(`vsly`, err);
    return fetch(
      `https://api.loomi-${
        isStage ? `stg` : `prod`
      }.xyz/6087fbd0-8017-4c6b-bb36-67c2efdf2a57/logs`,
      {
        method: `POST`,
        body: JSON.stringify({
          alias: store.alias,
          email: store.email,
          err,
        }),
        headers: {
          'Content-Type': `application/json; charset=UTF-8`,
        },
      },
    );
  }
  return { reportErr };
}
