/* eslint-disable no-plusplus,@typescript-eslint/ban-ts-comment */
import React, { useEffect, useReducer, useRef, useState } from 'react';
import produce, { applyPatches, Patch } from 'immer';
import { diff } from 'jsondiffpatch';
import { useLiveQuery } from 'dexie-react-hooks';
import { persistKeysForCleanup } from '@/components/hooks/use-cached-auto-save';
import { History } from '@/db/schemas';
import { changesHistoryTable } from '@/db';

function reducer<T>(_: T, newState: { value: T }) {
  return newState.value;
}

const UNDO_REDO_RING_SIZE = 100;

function lastChangeTS(changes: React.MutableRefObject<History>) {
  return changes.current?.history?.[changes.current.currentVersion]?.ts || 0;
}

export function useStateWithHistory<T>(initialState: T, key: string) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const changes = useRef<History>({
    history: [],
    currentVersion: -1,
  } as History);
  const initialValue = recoverHistory(key);
  initialValue.then((val) => {
    if (val) {
      changes.current = val;
      setCanUndo(hasUndoChange(changes));
      setCanRedo(hasRedoChange(changes));
    }
  });
  const [canUndo, setCanUndo] = useState(hasUndoChange(changes));
  const [canRedo, setCanRedo] = useState(hasRedoChange(changes));
  const { count, inc } = useCount();
  useEffect(
    () => () => {
      persistKeysForCleanup(key).then();
    },
    [],
  );
  const ignoredUndo = useRef<Patch[]>(undefined);
  const ignoredRedo = useRef<Patch[]>(undefined);
  const handleHistory =
    (ignoreHistory: boolean, forceSameVersion: boolean) =>
    (patches, inversePatches) => {
      const hasStateChange = shouldRecordChange(patches, state, changes);

      if (ignoreHistory && hasStateChange) {
        ignoredUndo.current = [
          ...inversePatches,
          ...(ignoredUndo.current || []),
        ];
        ignoredRedo.current = [...(ignoredRedo.current || []), ...patches];
      }

      if (hasStateChange && !ignoreHistory) {
        const now = new Date().valueOf();
        const delta = now - lastChangeTS(changes);
        const shouldBumpVersion = delta > 700 && !forceSameVersion;
        const nextVersion =
          changes.current.currentVersion + (shouldBumpVersion ? 1 : 0);
        let nextUndo: any[];

        if (ignoredUndo.current) {
          nextUndo = [...ignoredUndo.current];
          ignoredUndo.current = undefined;
        } else if (shouldBumpVersion) {
          nextUndo = inversePatches;
        } else {
          nextUndo = [
            ...inversePatches,
            ...changes.current.history[nextVersion].undo,
          ];
        }

        let nextRedo: any[];
        if (ignoredRedo.current) {
          nextRedo = [...ignoredRedo.current, ...patches];
          ignoredRedo.current = undefined;
        } else if (shouldBumpVersion) {
          nextRedo = patches;
        } else {
          nextRedo = [...changes.current.history[nextVersion].redo, ...patches];
        }

        changes.current.history[nextVersion] = {
          redo: nextRedo,
          undo: nextUndo,
          ts: now,
        };

        setCanUndo(nextVersion > 0);
        setCanRedo(!!changes?.current?.history?.[nextVersion + 1]);
        if (changes.current.history.length > UNDO_REDO_RING_SIZE) {
          changes.current.history.shift();
        } else {
          changes.current.currentVersion = nextVersion;
        }
        persistHistory(key, changes.current);
      }
    };

  const produceDispatch = async (
    fn: mutateFn<T>,
    ignoreHistory = false,
    forceSameVersion = false,
  ) => {
    const nextState = produce(
      state,
      fn,
      handleHistory(ignoreHistory, forceSameVersion),
    );
    if (notEqual(nextState, state)) {
      dispatch({ value: nextState });
    }
  };
  const undo = () => {
    const change = getUndoChange(changes);
    if (change) {
      inc();
      setCanRedo(true);
      changes.current.currentVersion -= 1;
      setCanUndo(hasUndoChange(changes));
      changes.current.history[changes.current.currentVersion].ts =
        new Date().valueOf();
      persistHistory(key, changes.current);
      dispatch({ value: applyPatches(state, change.undo) });
    }
  };

  const redo = () => {
    const change = redoChange(changes);

    if (change) {
      inc();
      setCanUndo(true);
      changes.current.currentVersion += 1;
      setCanRedo(hasRedoChange(changes));
      changes.current.history[changes.current.currentVersion].ts =
        new Date().valueOf();
      persistHistory(key, changes.current);
      dispatch({
        value: applyPatches(state, change.redo),
      });
    }
  };

  return {
    state: (state || initialState) as T,
    produceDispatch: produceDispatch as (
      fn: mutateFn<T>,
      ignoreHistory?: boolean,
      forceSameVersion?: boolean,
    ) => void,
    undo,
    redo,
    canUndo,
    canRedo,
    undoRedoCount: count,
  };
}

async function recoverHistory(key: string) {
  return useLiveQuery(async () => {
    const history = await changesHistoryTable().where(`key`).equals(key);
    return history.first();
  }, [key]);
}

function persistHistory(key: string, changes: History) {
  if (changes) {
    changes.key = key;
    changesHistoryTable().put(changes);
  }
}

function shouldRecordChange<T>(
  patches: Patch[],
  state: T,
  changes: React.MutableRefObject<History>,
) {
  return (
    patches.length > 0 &&
    notEqual(state, applyPatches(state, patches)) &&
    notEqual(
      patches,
      changes.current.history?.[changes.current.currentVersion]?.redo,
    )
  );
}

function useCount() {
  const [count, setCount] = useState(0);
  const inc = () => setCount(count + 1);
  return {
    count,
    inc,
  };
}

export function useComplexState<T>(
  initialState: T,
): [T, (fn: mutateFn<T>) => void, any] {
  const [state, dispatch] = useReducer(reducer, initialState);

  const produceDispatch = (fn: mutateFn<T>): void => {
    const updated = produce(state, fn);
    dispatch({ value: updated });
  };
  return [state as T, produceDispatch as (fn: mutateFn<T>) => void, dispatch];
}

export type mutateFn<T> = (draft: T) => any;

function getUndoChange(changes: React.MutableRefObject<History>) {
  return changes.current.history?.[changes.current.currentVersion];
}

function hasUndoChange(changes: React.MutableRefObject<History>) {
  return !!getUndoChange(changes) && changes.current.currentVersion > 0;
}

function redoChange(changes: React.MutableRefObject<History>) {
  return changes.current.history?.[changes.current.currentVersion + 1];
}

function hasRedoChange(changes: React.MutableRefObject<History>) {
  return !!redoChange(changes);
}

function notEqual(updated, state: any): boolean {
  return !!diff(clone(updated), clone(state));
}

function clone(state: any) {
  return state ? JSON.parse(JSON.stringify(state)) : state;
}
