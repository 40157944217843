import React from 'react';

export function DownSvg() {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 45 45"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.175742"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="arrow-up-sm-svgrepo-com-copy"
          transform="translate(22.5, 22.5) rotate(-180) translate(-22.5, -22.5)translate(13, 11)"
          stroke="#000000"
          strokeWidth="4.66666667"
        >
          <path d="M9.5,23 L9.5,0 M9.5,0 L0,9.2 M9.5,0 L19,9.2" id="Vector" />
        </g>
      </g>
    </svg>
  );
}
