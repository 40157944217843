import Select from 'react-select';
import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import { VSpace } from '@/components/spacing';
import { BigButton } from '@/components/big-button';
import { selectStyle } from '@/features/editor/widgets/custom-widget/style-templating/selectStyle';
import {
  newStyleTemplateWidgetContext,
  StyledTemplateContext,
} from '@/features/editor/widgets/custom-widget/style-templating/context';
import { StyleTemplateType } from '@/features/editor/widgets/custom-widget/style-templating/models';
import { SaveModal } from '@/features/editor/widgets/custom-widget/style-templating/save-modal';
import { makeCustomSelectOption } from '@/features/editor/widgets/custom-widget/style-templating/make-custom-select-option';
import { EditorContext } from '@/features/editor/context/editor-context';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import { useIsSuperSystemUser } from '@/features/editor/widgets/custom-widget/style-templating/utils';

import { STORE_STYLE_ID } from '@/features/editor/widgets/custom-widget/shared/store-style-utils';

export function StyleTemplateSelect() {
  const ctx = newStyleTemplateWidgetContext();
  const ref = useRef();
  const {
    applyTempChange,
    transpiler: { asWidgetChange },
  } = useContext(EditorContext);
  const widget = useContext(CustomWidgetContext);
  const { CustomOption, revertTempChanges } = makeCustomSelectOption(
    ctx.options,
    applyTempChange,
    asWidgetChange,
    widget,
    ctx.setDeleteStyleId,
  );
  const superSystemUser = useIsSuperSystemUser();

  const hideRenameBtn =
    ctx.selection.type !== StyleTemplateType.CUSTOM ||
    (!!ctx.selection.isGlobal && !superSystemUser) ||
    ctx.selection.id === STORE_STYLE_ID;
  return (
    <StyledTemplateContext.Provider value={ctx}>
      <>
        <Container ref={ref}>
          <SaveModal fromRef={ref} />
          <Relative loading={ctx.loading}>
            <div style={{ width: `95%` }} key={ctx.hash}>
              <Select
                components={{ Option: CustomOption }}
                onMenuClose={revertTempChanges}
                menuPlacement="bottom"
                styles={selectStyle}
                isDisabled={ctx.loading}
                defaultValue={{
                  label: ctx.selection.name,
                  value: ctx.selection,
                }}
                onChange={ctx.onSelection}
                options={ctx.options}
              />
            </div>
            <RenameBtn hidden={hideRenameBtn} onClick={ctx.onRenameBtnClick}>
              RENAME
            </RenameBtn>
          </Relative>
          <BigButton
            disabled={ctx.loading}
            border="none"
            background="#3b47ff"
            color="#ffffff"
            noShadow
            noTransform
            size="small"
            onClick={ctx.onSave}
          >
            SAVE
          </BigButton>
        </Container>
        <VSpace value={3} />
      </>
    </StyledTemplateContext.Provider>
  );
}

const Relative = styled.div<{ loading: boolean }>`
  opacity: ${(p) => (p.loading ? `0.2` : `1`)};
  position: relative;
  width: 100%;
`;

const RenameBtn = styled.button`
  position: absolute;
  height: 95%;
  top: 1px;
  left: 75%;
  background-color: white;
  font-family: 'JetBrains Mono', serif;

  font-weight: 400;
  font-size: 1rem;
  letter-spacing: -0.33px;
  text-decoration: underline;
  color: #4b5564;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  &:active {
    opacity: 1;
    cursor: default;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;
