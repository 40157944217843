import { useContext } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { VisualEditorContext } from '@/features/editor/widgets/visual-editor/context';
import { CustomizationGroup } from '@/features/editor/widgets/custom-widget/inputs/shared/customization-group';

interface HeaderProps {
  stickyHeader?: string;
  stickyHeaderEnabled?: boolean;
  setStickyHeader?: (s: string) => void;
  setStickyEnabled?: (v: boolean) => void;
}

export function VisualEditor({
  stickyHeader,
  stickyHeaderEnabled,
  setStickyHeader,
  setStickyEnabled,
}: HeaderProps) {
  const { visualProps, getResponsiveValue, setResponsiveValue, device } =
    useContext(VisualEditorContext);

  return (
    <Wrapper>
      {visualProps?.customizations.map((cust, idx) => (
        <CustomizationGroup
          key={`${device}-${cust.key}`}
          custIdx={idx}
          customization={cust}
          onDisabledStatusChanged={undefined}
          getResponsiveValue={getResponsiveValue}
          setResponsiveValue={setResponsiveValue}
          stickyHeader={stickyHeader}
          stickyHeaderEnabled={stickyHeaderEnabled}
          setStickyHeader={setStickyHeader}
          setStickyEnabled={setStickyEnabled}
        />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  && > * {
    margin-bottom: 3rem;
  }
`;
