import React from 'react';
import styled from 'styled-components';
import { Spinner } from '@/Spinner';

export interface BackdropSpinnerProps {
  loading?: boolean;
  backgroundColor?: string;
  blurAmount?: number;
}

export function BackdropSpinner({
  loading,
  backgroundColor,
  blurAmount,
}: BackdropSpinnerProps) {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {loading && (
        <Backdrop
          onClick={(e) => e.stopPropagation()}
          background={backgroundColor || `rgba(0,0,0,0.1)`}
          blurAmount={blurAmount || 12}
        >
          <Spinner mt="0" />
        </Backdrop>
      )}
    </>
  );
}

const Backdrop = styled.div`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background};
  backdrop-filter: blur(${(props) => props.blurAmount}px);
  z-index: 100000;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: fade-in 0.2s ease;
`;
