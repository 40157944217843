import { useContext, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { CompoundBlock } from '@/webapi/use-experience-api';
import { useComplexState } from '@/utils/use-complex-state';
import {
  CatalogWidgetProps,
  CustomizationSpec,
} from '@/webapi/use-widget-catalog-api';
import { AnchorOrigin } from '@/features/editor/context/use-device-preview';
import { asVisualEditorChange } from '@/features/editor/widgets/visual-editor/legacy/visual-editor-transpiler';
import { EditorContext } from '@/features/editor/context/editor-context';
import { iifeWrap } from '@/features/editor/widgets/code-editors/utils';

export function useLegacyVisualEditor(
  selector: string,
  props: CatalogWidgetProps,
  initialHtml?: string,
) {
  const {
    applyTempChange: _applyTempChange,
    experienceState: { upsertEditorChange, removeEditorChange },
    inspectorNav: { gotoChangelog },
    devicePreview: {
      anchor,
      leaveAnchor,
      editorState: { device },
    },
    transpiler: { asHideElementBlock },
  } = useContext(EditorContext);

  const applyTempChange = useDebouncedCallback(_applyTempChange, 1000);

  const [isDirty, setDirty] = useState(false);
  const [change, setChange] = useComplexState(
    asVisualEditorChange(
      selector,
      props,
      initialHtml,
      JSON.parse(JSON.stringify(props)),
    ),
  );

  const onVisualCodeChange = (
    js: string,
    css: string,
    changedSpecs: Record<string, CustomizationSpec>,
  ) => {
    setDirty(true);
    setChange((draft) => {
      (draft.block.value as CompoundBlock).html = ``;
      (draft.block.value as CompoundBlock).js = iifeWrap(js);
      (draft.block.value as CompoundBlock).css = css;
      draft.visualEditSpecs = changedSpecs;
    });
  };

  const onSave = () => {
    upsertEditorChange(change);
    leaveAnchor();
    gotoChangelog();
  };

  const onHide = () => {
    const delChange = asHideElementBlock(selector, device);
    removeEditorChange(change);
    upsertEditorChange(delChange);
    leaveAnchor();
  };

  useEffect(() => {
    anchor(change.editorSelector, AnchorOrigin.NEW_ELEMENT);
  }, []);

  useEffect(() => {
    applyTempChange(change);
  }, [change]);

  return {
    isDirty,
    change,
    onVisualCodeChange,
    onSave,
    onHide,
  };
}
