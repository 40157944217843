import * as React from 'react';

import { EditorDeclarativeBlock } from '@/webapi/use-experience-api';
import { CodeBlockApp } from '@/features/editor/widgets/shared/apps-catalog/static-apps';
import { CatalogAppTile } from '@/features/editor/widgets/shared/apps-catalog/app-tile';
import { CatalogAppsGrid } from '@/features/editor/widgets/shared/apps-catalog/apps-list';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import { LocationPicker } from '@/features/editor/widgets/shared/location-picker';
import { useCodeBlocksSelect } from '@/features/editor/widgets/code-editors/use-code-blocks-select';

export interface CodeTypeSelectProps {
  origChange: EditorDeclarativeBlock;
}

export function CodeTypeSelect({ origChange }: CodeTypeSelectProps) {
  const {
    showPlacementPicker,
    onSelectorPicked,
    onBackFromPicker,
    onBack,
    onCodeBlockSelected,
  } = useCodeBlocksSelect({ origChange });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {showPlacementPicker ? (
        <LocationPicker
          onContinue={onSelectorPicked}
          onBack={onBackFromPicker}
          title="Choose a location for the new section"
          backCaption="< Back to widgets"
        />
      ) : (
        <InspectorWidgetLayout
          progress={50}
          title="2. Select a code block type"
          backCaption="< Back to widgets"
          onBackClicked={onBack}
        >
          <CatalogAppsGrid>
            {CodeBlockApp.widgets?.map((w) => (
              <CatalogAppTile app={w} onClick={() => onCodeBlockSelected(w)} />
            ))}
          </CatalogAppsGrid>
        </InspectorWidgetLayout>
      )}
    </>
  );
}
