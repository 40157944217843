import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { FBAdSet } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/models';
import { Ads } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/ads';
import {
  AdSearchContext,
  AdSearchState,
} from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/context';
import {
  campaignHasSelectedChild,
  clone,
  Details,
  ExpandBtn,
} from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/shared';
import { FacebookAudienceContext } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/context';
import {
  FacebookAdSet,
  FacebookTargeting,
} from '@/components/query-builder/models';

type Props = {
  adSets: Array<FBAdSet>;
  campaignIdx: number;
  campaignId: string;
};

export const AdSets: FC<Props> = ({ adSets, campaignIdx, campaignId }) => {
  const { setState, state } = useContext(AdSearchContext);
  const { targeting } = useContext(FacebookAudienceContext);
  return (
    <AdSetsContainer>
      {adSets?.map((adSet, idx) => {
        const { isSelected } = adSets[idx];
        const onSelect = (isSelected) => {
          handleAdSetTargeting(state, campaignIdx, idx, targeting, isSelected);
          setState((draft) => {
            draft.campaigns[campaignIdx].adSets[idx].isSelected = isSelected;
            draft.campaigns[campaignIdx].adSets?.[idx]?.ads?.forEach((ad) => {
              ad.isSelected = isSelected;
            });
            draft.campaigns[campaignIdx].isSelected =
              isSelected || campaignHasSelectedChild(draft, campaignIdx);
          });
        };
        const { status } = adSet;
        const { name } = adSet;
        const onExpand = () => {
          setState((draft) => {
            draft.campaigns[campaignIdx].adSets[idx].isCollapsed =
              !draft.campaigns[campaignIdx].adSets[idx].isCollapsed;
          });
        };
        const isLast = idx === adSets.length - 1;
        return (
          <AdSetContainer
            isCollapsed={!adSet.isCollapsed}
            key={adSet.name}
            isLast={isLast}
          >
            <AdSetHeader>
              <Details
                defaultValue={isSelected}
                onChange={onSelect}
                status={status}
                name={name}
                onExpand={onExpand}
              />
              <ExpandBtn onClick={onExpand} isCollapsed={adSet.isCollapsed} />
            </AdSetHeader>
            {!adSet.isCollapsed && (
              <Ads
                ads={adSet.ads}
                adSetIdx={idx}
                campaignIdx={campaignIdx}
                campaignId={campaignId}
                adsetId={adSet.id}
                next={adSet.next}
              />
            )}
          </AdSetContainer>
        );
      })}
    </AdSetsContainer>
  );
};

const AdSetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 1.4rem;
  color: #576470;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.09),
    0 5px 10px 0 rgba(182, 207, 205, 0.24);
  width: 100%;
  padding: 1rem 2rem;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 12px;
`;

const AdSetContainer = styled.div`
  ${(p: any) => (p?.isLast ? `` : `margin-bottom: 1rem;`)}
  border-radius: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  background-color: ${(p: { isCollapsed: boolean }) =>
    p?.isCollapsed ? `#ffffff` : ``};
`;
const AdSetsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: #f5f5f5;
  border-radius: 12px;
  margin: 1rem 2rem;
`;

function shouldRemFromTargeting(
  isSelect,
  isTargeted: boolean,
  isParentTargeted: boolean,
  isExcluded: boolean,
) {
  return (
    (!isSelect && isTargeted && isParentTargeted && isExcluded) ||
    (!isSelect && isTargeted && !isParentTargeted && !isExcluded) ||
    (isSelect && isTargeted && isParentTargeted && !isExcluded) ||
    (isSelect && isTargeted && isParentTargeted && isExcluded)
  );
}

function handleAdSetTargeting(
  state: AdSearchState,
  campaignIdx: number,
  idx: number,
  targeting: React.MutableRefObject<FacebookTargeting>,
  isSelect,
) {
  const campaign = state.campaigns[campaignIdx];
  const currAdSet = campaign.adSets[idx];
  const isTargeted = !!targeting.current.adsets.find(
    (ads) => ads.id === currAdSet.id,
  );
  const isParentTargeted = !!targeting.current.campaigns?.find(
    (x) => x.id === campaign.id,
  );
  const isExcluded = targeting.current.adsets?.find(
    (x) => x.id === currAdSet.id,
  )?.isExclude;

  if (
    shouldRemFromTargeting(isSelect, isTargeted, isParentTargeted, isExcluded)
  ) {
    targeting.current = {
      ...targeting.current,
      adsets: targeting.current.adsets.filter((ads) => ads.id !== currAdSet.id),
    };
  } else if (!isSelect && !isTargeted && isParentTargeted && !isExcluded) {
    targeting.current.adsets = [
      ...targeting.current.adsets,
      clone({
        name: currAdSet.name,
        id: currAdSet.id,
        campaign_id: campaign.id,
        isExclude: true,
      } as FacebookAdSet),
    ];
  } else if (!isSelect && isTargeted && isParentTargeted && !isExcluded) {
    targeting.current.adsets.forEach((a) => {
      if (a.id === currAdSet.id) {
        a.isExclude = true;
      }
    });
  } else if (isSelect && !isTargeted && !isParentTargeted && !isExcluded) {
    const adsets = [
      ...targeting.current.adsets,
      clone({
        name: currAdSet.name,
        id: currAdSet.id,
        campaign_id: campaign.id,
        campaignName: campaign.name,
      } as FacebookAdSet),
    ];
    targeting.current = { ...targeting.current, adsets };
  }

  if (!isSelect) {
    targeting.current = {
      ...targeting.current,
      ads: targeting.current.ads.filter((ad) => ad.adSet_id !== currAdSet.id),
    };
  }
}
