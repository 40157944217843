import React, { useEffect, useState } from 'react';

export const UndoRedoCounterContext = React.createContext({
  undoRedoCount: 0,
} as { undoRedoCount: number });

export function newUndoRedoContext(undoRedoCount: number) {
  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount(undoRedoCount);
  }, [undoRedoCount]);
  return { undoRedoCount: count };
}
