import * as React from 'react';
import { useContext } from 'react';
import { StylingComponentsGroup } from '@/features/editor/widgets/post-purchase/styling/group';
import { PostPurchaseTextualInput } from '@/features/editor/widgets/post-purchase/inputs/textual';
import { PostPurchaseContext } from '@/features/editor/widgets/post-purchase/context';

export function PostPurchasePricesStyles() {
  const { props, setProps } = useContext(PostPurchaseContext);
  return (
    <>
      <StylingComponentsGroup title="Before Discount Style">
        <PostPurchaseTextualInput
          defaultValue={props.style.prices.beforeStyle}
          onChange={(p) =>
            setProps((draft) => {
              draft.style.prices.beforeStyle = p;
            })
          }
        />
      </StylingComponentsGroup>

      <StylingComponentsGroup title="After Discount Style">
        <PostPurchaseTextualInput
          defaultValue={props.style.prices.afterStyle}
          onChange={(p) =>
            setProps((draft) => {
              draft.style.prices.afterStyle = p;
            })
          }
        />
      </StylingComponentsGroup>

      {/* <StylingComponentsGroup title="Container"> */}
      {/*  <span>spacing</span> */}
      {/* </StylingComponentsGroup> */}
    </>
  );
}
