import React, { useContext } from 'react';
import { StrategyPerSlotContext } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/context/context';
import { Footer } from '@/features/editor/widgets/shared/modals/commons';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import {
  RecommendationOptions,
  RecommendationType,
} from '@/webapi/use-widget-catalog-api';

export function ModalFooter({ close }: { close: () => void }) {
  const ctx = useContext(StrategyPerSlotContext);
  const { updateRecommendationOptions } = useContext(CustomWidgetContext);
  const action = () => {
    if (ctx.isProductCondOpen) {
      ctx.setProductCondSlotOpen(undefined);
      return;
    }
    const options = {
      type: RecommendationType.MANUAL,
      strategyPerSlot: ctx.state?.slots,
      advancedRuling: undefined,
      condition: undefined,
      manualProducts: undefined,
      productIds: undefined,
    } as RecommendationOptions;

    updateRecommendationOptions(options);

    close();
  };

  if (ctx.isProductSearchOpen || ctx.isManualImageSelectionOpen) {
    return null;
  }

  return (
    <Footer
      onClick={action}
      bg="black"
      size="xl"
      text={ctx.isProductCondOpen ? `Continue` : `Save`}
    />
  );
}
