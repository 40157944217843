import React, { useContext, useRef, useState } from 'react';
import { EditorContext } from '@/features/editor/context/editor-context';
import { TargetingButton } from '@/features/editor/widgets/shared/targeting-controls/targeting-button';
import { VariancesModal } from '@/features/editor/widgets/shared/modals/audience/variants-modal';

export function VariantsButton({ isDisabled }: { isDisabled?: boolean }) {
  const {
    experienceState: { currentExperience },
  } = useContext(EditorContext);
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const onClick = () => {
    setIsVisible(true);
  };
  if (currentExperience.variants.length <= 1) {
    return null;
  }

  const selectedVariantName =
    currentExperience.activeVariantId === `control`
      ? `Original`
      : currentExperience.variants.find(
          (v) => v.id === currentExperience.activeVariantId,
        )?.name || currentExperience.variants[0].name;
  return (
    <>
      <VariancesModal
        fromRef={ref}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
      <TargetingButton
        wrapperRef={ref}
        title="A/B Variation"
        subtitle={selectedVariantName}
        onClick={onClick}
        isDisabled={isDisabled}
      />
    </>
  );
}
