import { useEffect } from 'react';

export function useEscapeKey(close: () => void) {
  useEffect(() => {
    function handleKeyDown(evt: any) {
      const isEscape = evt.key === `Escape` || evt.key === `Esc`;
      if (isEscape) {
        close();
      }
    }
    document.addEventListener(`keydown`, handleKeyDown, true);
    return () => document.removeEventListener(`keydown`, handleKeyDown);
  }, []);
}
