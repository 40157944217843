import { useContext } from 'react';
import { AccountContext } from '@/features/account-context';
import { Role } from '@/utils/definitions';

export function useCanPublish() {
  const ctx = useContext(AccountContext);
  const role = ctx?.account?.store?.role;
  if (role === Role.SYSTEM) {
    return true;
  }
  return (
    !![Role.Admin, Role.Editor, Role.Root, Role.SYSTEM].includes(role) &&
    !ctx?.account?.store?.isTestStore
  );
}
