import React, { useContext } from 'react';
import styled from 'styled-components';
import { LoadingStrategyOption } from '@/webapi/use-widget-catalog-api';
import { StrategySelection } from '@/features/editor/widgets/custom-widget/loading-section/shared/strategy-select';
import { StrategyPerSlotContext } from '@/features/editor/widgets/custom-widget/loading-section/strategy-per-slot/context/context';
import { LayeredRulingContext } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';

export const SlotContainer = styled.div`
  height: 14rem;
  min-width: 33rem;
  background-color: #fafafa;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15),
    0 14px 16px 0 rgba(151, 210, 206, 0.22), 0 5px 16px 0 rgba(0, 0, 0, 0);
  border: 1px solid #d5d9dc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  padding: 3rem;

  ${(p) =>
    p.isClickable
      ? `
    :hover {
    opacity: 0.5;
    cursor: pointer;
  }
  :active {
    opacity: 1;
    cursor: default;
  }
  `
      : ``}

  span:first-child {
    font-size: 1.6rem;
    font-family: 'JetBrains Mono', serif;
    font-weight: bold;
    color: #4b545e;
  }
  span:nth-child(2) {
    margin-top: 1rem;
    font-size: 1.2rem;
    font-family: 'JetBrains Mono', serif;
    font-weight: normal;
    color: #7e8993;
    button {
      font-weight: normal;
      text-decoration: underline;
      margin: 0;
      &:active {
        cursor: default;
        opacity: 1;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }
  }
`;

type Props = {
  num: number;
  onProductSelect: () => void;
  onStrategySelect: (value: LoadingStrategyOption) => () => void;
};

export function EmptySlot({ num, onProductSelect, onStrategySelect }: Props) {
  const sCtx = useContext(StrategyPerSlotContext);
  const lCtx = useContext(LayeredRulingContext);
  const isPostPurchase = sCtx?.isPostPurchase || lCtx?.isPostPurchase;
  if (isPostPurchase) {
    return (
      <SlotContainer onClick={onProductSelect} isClickable={isPostPurchase}>
        <span>SLOT {num + 1}</span>
        <span>Click to select a product</span>
      </SlotContainer>
    );
  }
  return (
    <SlotContainer>
      <span>SLOT {num + 1}</span>
      <span>
        Click to select
        <StrategySelection onSelect={onStrategySelect} />/
        <button type="button" onClick={onProductSelect}>
          product
        </button>
      </span>
    </SlotContainer>
  );
}
