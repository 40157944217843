import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import produce from 'immer';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import {
  INPUT_TEXT_EDIT,
  TextEditInput,
} from '@/features/editor/widgets/custom-widget/inputs/typography/text-edit';
import { LinkInput } from '@/features/editor/widgets/custom-widget/inputs/shared/link';
import { GroupDisableButton } from '@/features/editor/widgets/custom-widget/inputs/shared/group-disable-button';
import { encodeBase64 } from '@/utils/base64';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';

interface CtaValue {
  href: string;
  content: string;
}

export function CtaInput({
  customizationIdx,
  customization,
  componentIdx,
  component,
  specIdx,
  spec,
  device,
  initialValues,
  onValuesChanged,
  hover,
}: GenericInputProps) {
  const { undoRedoCount } = useContext(UndoRedoCounterContext);

  const defaultValue = useCallback(
    () => initialValues(`content`),
    [undoRedoCount],
  );

  const [values, setValues] = useState<CtaValue[]>(defaultValue());
  useEffect(() => setValues(defaultValue()), [undoRedoCount]);

  useEffect(() => {
    onValuesChanged(`content`, values);
  }, [values]);

  const min = spec.values?.min || 1;
  const max = spec.values?.max || 1;

  const isAddition = values.length < max;

  const onAdd = () => {
    const updated = produce(values, (draft) => {
      draft.push({
        content: `Button #${values.length + 1}`,
        href: ``,
      } as CtaValue);
    });
    setValues(updated);
  };

  return (
    <TopWrapper>
      {values.map((cta, idx) => (
        <CtaCard
          key={encodeBase64(idx + cta.href)}
          valueIdx={idx}
          values={values}
          setValues={setValues}
          customizationIdx={customizationIdx}
          customization={customization}
          componentIdx={componentIdx}
          component={component}
          specIdx={specIdx}
          spec={spec}
          device={device}
          min={min}
          onValuesChanged={onValuesChanged}
          initialValues={initialValues}
          hover={hover}
        />
      ))}
      {isAddition && (
        <Chip onClick={onAdd} device={device}>
          + Add another CTA
        </Chip>
      )}
    </TopWrapper>
  );
}

const TopWrapper = styled.div`
  && > * {
    margin-top: 1.5rem;
  }
`;

interface CtaCardProps extends GenericInputProps {
  valueIdx: number;
  values: CtaValue[];
  setValues: (values: CtaValue[]) => void;
  min: number;
}

function CtaCard({
  customizationIdx,
  customization,
  componentIdx,
  component,
  specIdx,
  spec,
  valueIdx,
  device,
  values,
  setValues,
  min,
  hover,
}: CtaCardProps) {
  const inputProps = {
    customizationIdx,
    componentIdx,
    specIdx,
    device,
    customization,
    component,
    spec,
    hover,
  };
  const [isRemoveVisible, setIsRemoveVisible] = useState(false);
  const isMinReached = values.length === min;

  const onRemove = () => {
    const updated = produce(values, (draft) => {
      draft.splice(valueIdx, 1);
    });
    setValues(updated);
    setIsRemoveVisible(false);
  };

  const onMouseEnter = () => {
    if (!isMinReached) {
      setIsRemoveVisible(true);
    }
  };

  const onMouseLeave = () => {
    setIsRemoveVisible(false);
  };

  const onLinkChanged = (key: string, value: any) => {
    const updated = produce(values, (draft) => {
      draft[valueIdx].href = value;
    });
    setValues(updated);
  };

  const onTextChanged = (key: string, value: any) => {
    const updated = produce(values, (draft) => {
      draft[valueIdx].content = value;
    });
    setValues(updated);
  };

  return (
    <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <SubWrapper>
        <div style={{ position: `relative`, height: `100%`, width: `100%` }}>
          <StyledTextInput
            initialValues={() => values[valueIdx].content}
            onValuesChanged={onTextChanged}
            {...inputProps}
          />
          {isRemoveVisible && (
            <RemoveSection>
              <RemoveButton onClick={onRemove} />
            </RemoveSection>
          )}
        </div>
        <Divider />
        <LinkInput
          initialValues={() => values[valueIdx].href}
          onValuesChanged={onLinkChanged}
          {...inputProps}
        />
      </SubWrapper>
    </Wrapper>
  );
}

const SubWrapper = styled.div`
  width: 100%;

  && > * {
    box-shadow: none;
  }

  && > div:first-child > * {
    box-shadow: none;
  }

  .${INPUT_TEXT_EDIT} {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

const removeAnim = keyframes`
  0% {
    right: -2rem;
    opacity: 0;
  }

  100% {
    right: 1rem;
    opacity: 1;
  }
`;

const RemoveSection = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1rem;
    animation: ${removeAnim} 0.3s ease-in-out;
  }
`;

const RemoveButton = styled(GroupDisableButton)`
  && {
    :before {
      content: 'Remove';
    }
  }
`;

const Wrapper = styled.div`
  display: block;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 9px 13px 0 rgba(177, 217, 203, 0.18);

  && > * {
    box-shadow: none;
  }
`;

const Divider = styled.span`
  display: block;
  height: 0.1rem;
  width: 100%;
  background: rgba(192, 205, 216, 0.4);
`;

const Chip = styled.div`
  cursor: pointer;
  background-color: #cfdbdf;
  padding: 1rem;
  border-radius: 2rem;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: 'JetBrains Mono', serif;
  color: #263b4e;
  width: 100%;
  text-align: center;
  opacity: 0.6;

  :hover {
    opacity: 0.7;
  }

  :active {
    opacity: 0.6;
  }
`;

const StyledTextInput = styled(TextEditInput)`
  && {
    border: none !important;
  }
`;
