import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Container } from '@/features/editor/widgets/shared/modals/trigger/select-trigger';
import { MonacoEditor } from '@/features/editor/widgets/code-editors/monaco-editor';
import { CodeEditorMonacoWrapper } from '@/features/editor/widgets/code-editors/shared';

export interface JsFunctionSelectProps {
  defaultValue: string;
  onChange: (code: string) => void;
}

export function JsFunctionSelect({
  defaultValue,
  onChange,
}: JsFunctionSelectProps) {
  useEffect(() => {
    document.querySelectorAll(`#vslyGradientScroller`).forEach((scroller) => {
      scroller.scrollTo(0, 0);
    });
  }, []);
  return (
    <Wrapper>
      <Text>
        Write your custom triggering logic in plain JavaScript. You need to
        return a promise, and when it&apos;s resolved, the experience will be
        triggered.
      </Text>
      <CodeEditorMonacoWrapper>
        <MonacoEditor
          lang="javascript"
          code={defaultValue}
          onCodeChange={onChange}
          readOnly={false}
        />
      </CodeEditorMonacoWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(Container)`
  && {
    height: 100%;
    text-align: left;
    display: grid;
    grid-template-rows: 4rem 45rem;
  }
`;

const Text = styled.span`
  line-height: 1.5;
`;
