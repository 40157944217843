import styled from 'styled-components';

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #f0f0f0;
`;

export const ColorPadding = styled.div`
  padding: 2rem;
`;
