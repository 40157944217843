export const errorPage = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Title</title>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300;400;500;600;700;800&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap" rel="stylesheet">
    <style>
        body {
            height: 100vh;
            width: 100vw;
            background: #f8f9fb;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 0;
        }

        .container {
            margin-top: -40px;
            text-align: center;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 120px max-content max-content max-content;
            grid-gap: 20px;
            justify-content: center;
            align-items: center;
            font-family: Inter, serif;

            padding: 20px;
        }

        .image {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }

        span {
            max-width: 400px;
        }

        .title {
            margin-top: 10px;
            font-family: Inter, serif;
            font-weight: bold;
            font-size: 28px;
        }

        .subtitle {
            font-family: "JetBrains Mono", serif;
            font-size: 14px;
        }

        .description {
            font-family: "JetBrains Mono", serif;
            font-size: 14px;
        }

        @media only screen and (min-width: 768px) {
            span {
                max-width: 700px;
            }

            .title {
                font-size: 36px;
            }

            .subtitle {
                font-size: 20px;
            }

            .description {
                font-size: 20px;
            }
        }
    </style>
</head>
<body>

<div class="container">
    <img class="image" src="https://sdk.loomi-prod.xyz/VSLY/helm.png" alt="navigation helm">
    <span class="title">Ahoy Sailor</span>
    <span class="description">
        We’re sorry, but the page couldn’t be loaded due to an unexpected error.
        <br/>
        <br/>
Possible causes include a temporary server issue, the page no longer existing, a redirection, a network problem, or a broken link.
    </span>
    <span class="subtitle">Please choose a different page from the list above.</span>
</div>

</body>
</html>
`;
