import { useContext, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useIsPreviewingDesktop } from '@/features/editor/shared/use-device-type';
import { toTransformKind } from '@/features/editor/context/use-transpiler';
import { AnchorOrigin } from '@/features/editor/context/use-device-preview';
import {
  asAllMutationKind,
  ContentKind,
} from '@/features/editor/widgets/shared/content-kind-select';
import { EditorContext } from '@/features/editor/context/editor-context';
import { AllMutationKind } from '@/pkg/sdk';

export interface ContentLocation {
  selector: string;
  placement: AllMutationKind;
}

export function useLocationPicker(
  onBack: () => void,
  onLocationChanged?: (selector: string, placement: AllMutationKind) => void,
) {
  const {
    lastPickedElement,
    cleanLastPickedElement,
    applyTempChange,
    resyncMutations,
    transpiler: { asNewContentChange },
    devicePreview: { pickElement, anchor },
  } = useContext(EditorContext);

  const isDesktop = useIsPreviewingDesktop();
  const [isPicked, setIsPicked] = useState(false);
  const [isManual, setIsManual] = useState(false);
  const [location, setLocation] = useState<ContentLocation | undefined>(
    undefined,
  );
  const id = useMemo(() => `lmi-temp-id-${new Date().getTime()}`, []);

  useEffect(() => () => cleanLastPickedElement(), []);

  const onRelocate = () => {
    setIsPicked(false);
    cleanLastPickedElement();
    pickElement(`#vsly-invalid-selector`);
    setLocation(undefined);
  };

  const toggleManual = () => {
    setIsManual(!isManual);
  };

  useEffect(() => {
    setTimeout(() => {
      cleanLastPickedElement();
      pickElement(`#vsly-invalid-selector`);
    }, 500);
  }, []);

  useEffect(() => {
    if (lastPickedElement?.kind && lastPickedElement?.selector) {
      setLocation({
        selector: lastPickedElement?.selector,
        placement: lastPickedElement?.kind,
      });
    } else {
      setLocation(undefined);
    }
  }, [lastPickedElement]);

  useEffect(() => {
    if (location?.selector) {
      setIsPicked(true);
      if (onLocationChanged) {
        onLocationChanged(location.selector, location.placement);
      } else {
        const addition = asNewContentChange(
          id,
          toTransformKind(location?.placement),
          location?.selector,
        );
        applyTempChange(addition);
        setTimeout(() => {
          anchor(`#${id}`, AnchorOrigin.NEW_ELEMENT);
        }, 200);
      }
    } else {
      setIsPicked(false);
      resyncMutations();
    }
  }, [location]);

  const isEnabled = useMemo(
    () => !!location?.selector && !!location?.placement,
    [location],
  );

  const onContentKindChanged = (val: ContentKind) => {
    setLocation({
      selector: location?.selector,
      placement: asAllMutationKind(val),
    });
  };

  const onManualSelectorChanged = useDebouncedCallback((ev) => {
    const val = ev.target.value;
    if (val) {
      setLocation({
        selector: val,
        placement: location?.placement || `appendAfter`,
      });
    }
  }, 500);

  const onBackClicked = () => {
    cleanLastPickedElement();
    resyncMutations();
    onBack && onBack();
  };

  return {
    id,
    isDesktop,
    isPicked,
    location,
    onRelocate,
    isManual,
    toggleManual,
    onContentKindChanged,
    onManualSelectorChanged,
    isEnabled,
    onBackClicked,
  };
}
