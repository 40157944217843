import { CachePolicies, useFetch } from 'use-http';
import { routes } from '@/webapi/routes';
import { Audience } from '@/features/dashboard/audiences/models';

export function useAudiencesApi(initialLoading = false) {
  const { put, post, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: initialLoading,
    },
  }));

  const createAudience = (audience: Audience) =>
    post(routes.audiences, audience);

  const deleteAudience = (audience: Audience) =>
    post(routes.deleteAudiences, audience);

  const updateAudience = (audience: Audience) =>
    put(routes.audiences, audience);

  const listAudiences = (
    startAt = null,
    endAt = null,
    timezoneOffset = new Date().getTimezoneOffset(),
  ) =>
    post(routes.audiencesList, {
      timezoneOffset,
      startAt,
      endAt,
    }) as Promise<{
      audiences: Array<Audience>;
    }>;

  return {
    createAudience,
    listAudiences,
    updateAudience,
    deleteAudience,
    loading,
  };
}
