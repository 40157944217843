import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { GradientModal } from '@/components/gradient-modal';
import { Footer } from '@/features/editor/widgets/shared/modals/commons';
import { centered } from '@/components/use-shared-element';
import {
  EditorContext,
  EditorContextProps,
} from '@/features/editor/context/editor-context';
import { VSpace } from '@/components/spacing';
import {
  ControlGroup,
  Variation,
} from '@/features/editor/widgets/targeting/allocation-card';
import { DeviceType } from '@/utils/definitions';
import { clone } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/shared';
import { Experience } from '@/webapi/use-experience-api';
import { maybe } from '@/features/details/utils';

export function VariancesModal({ fromRef, isVisible, setIsVisible }: Props) {
  const editor = useContext(EditorContext);

  const [selectedToClone, setSelectedToClone] = useState<string | undefined>();
  const [isCreate, setIsCreate] = useState(false);
  const prevState = useRef<Experience>();

  useEffect(() => {
    if (isVisible) {
      prevState.current = clone(editor.experienceState.currentExperience);
    }
  }, [isVisible]);

  const onSelect = () => {
    if (isCreate) {
      if (selectedToClone) {
        editor.experienceState.cloneVariant(selectedToClone);
      } else {
        editor.experienceState.addEmptyVariant();
      }
      setIsCreate(false);
    } else {
      setIsVisible(false);
    }
  };

  const header = isCreate ? (
    <>
      How do you want to begin creating <br /> the new variation?
    </>
  ) : (
    <>Select A/B Variation</>
  );

  const onClose = () => {
    if (isCreate) {
      setIsCreate(false);
    } else {
      setIsVisible(false);
      prevState.current &&
        editor.experienceState.revertMultivariantChanges(prevState.current);
      prevState.current = undefined;
    }
  };

  const gradientProps = getGradientModalProps(isVisible, fromRef);
  return (
    <GradientModal
      {...gradientProps}
      onClose={onClose}
      header={header}
      footer={
        <Footer
          onClick={onSelect}
          text="Save"
          isDisabled={isDisabled(editor)}
        />
      }
    >
      <Container>
        {!isCreate ? (
          <SelectVariant setIsCreate={setIsCreate} />
        ) : (
          <CreateVariant
            selected={selectedToClone}
            setSelected={setSelectedToClone}
          />
        )}
      </Container>
    </GradientModal>
  );
}

interface Props {
  fromRef: React.MutableRefObject<HTMLElement>;
  isVisible: boolean;
  setIsVisible: (state: boolean) => void;
}

type P = {
  setIsCreate: (b: boolean) => void;
};

function SelectVariant({ setIsCreate }: P) {
  const editor = useContext(EditorContext);
  const { variants } = editor.experienceState.currentExperience;
  return (
    <>
      <ControlGroup
        bg="white"
        device={DeviceType.Mobile}
        chances={variants?.map((v) => v.chance)}
      />
      {variants?.map((v) => (
        <Variation
          key={v.id}
          device={DeviceType.Mobile}
          variant={v}
          bg="white"
        />
      ))}
      {variants.length < 6 && (
        <VariantBtn
          key="another"
          isSelected={false}
          onClick={() => setIsCreate(true)}
        >
          <Details>
            <CreateAnotherWrapper>
              Create another variation
            </CreateAnotherWrapper>
          </Details>
        </VariantBtn>
      )}
    </>
  );
}

function CreateVariant({
  selected,
  setSelected,
}: {
  selected: string;
  setSelected: (s: string) => void;
}) {
  const editor = useContext(EditorContext);
  const { variants } = editor.experienceState.currentExperience;

  return (
    <>
      <VSpace value={2} />
      <VariantBtn
        key="empty"
        onClick={() => setSelected(null)}
        isSelected={selected === null}
      >
        Start from scratch with no changes
      </VariantBtn>
      {variants.map((v) => {
        const onCreate = () => setSelected(v.id);
        return (
          <VariantBtn
            key={v.id}
            onClick={onCreate}
            isSelected={v.id === selected}
          >
            Duplicate {v.name}
          </VariantBtn>
        );
      })}
    </>
  );
}

const CreateAnotherWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 0 !important;
  text-decoration: underline;
`;

const VariantBtn = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  border-radius: 10px;
  border: 2px solid
    ${(p: { isSelected: boolean }) => (p.isSelected ? `#0085FF` : `#cdd3d9`)};
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.15),
    0 4px 11px 0 rgba(151, 210, 206, 0.22);
  :hover {
    cursor: ${(p: { disabled: boolean }) =>
      p.disabled ? `default` : `pointer`};
    opacity: ${(p: { disabled: boolean }) => (p.disabled ? `1` : `0.7`)};
    ${(p: { disabled: boolean }) => (p.disabled ? `` : `box-shadow: none;`)}
  }
  :active {
    cursor: default;
    opacity: 1;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  div:first-child {
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 1.4rem;
    color: #58606c;
  }
  div {
    font-family: Inter, serif;
    text-align: start;
    color: #a6afb8;
  }
`;

const Container = styled.div`
  margin: 2rem 1.5rem;
  text-transform: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  text-align: center;
  color: #9ba7b3;
  letter-spacing: -0.38px;
  font-family: Inter, serif;
  font-weight: 500;
  font-size: 1.4rem;
`;

function getGradientModalProps(
  isVisible: boolean,
  fromRef: React.MutableRefObject<HTMLElement>,
) {
  return {
    closeTop: `0.5rem`,
    closeRight: `2.5rem`,
    fromStyle: {
      borderRadius: `5rem`,
      backgroundColor: `#DEDEDE`,
      padding: `2rem 0 0 0`,
    },
    toStyle: {
      borderRadius: `1rem`,
      backgroundColor: `#FFFFFF`,
      padding: `2rem 0 0 0`,
    },
    showBackdrop: true,
    isVisible,
    targetPosAndSize: centered(60, 80),
    fromRef,
    overflowTop: { heightInRem: 8 },
    overflowBottom: { heightInRem: 8, turnPointInPercent: 30 },
  };
}

function isDisabled(editor: EditorContextProps) {
  return maybe(
    () =>
      editor.experienceState.currentExperience.variants.reduce(
        (p, c) => p + c.chance,
        0,
      ) > 100,
  );
}
