import {
  LoadingStrategyOption,
  RecommendationType,
} from '@/webapi/use-widget-catalog-api';

export const CHECKOUT_SUPPORTED_UPSELL_STRATEGIES: LoadingStrategyOption[] = [
  {
    description: `Choose the specific items you'd like to display`,
    label: `I want to choose manually`,
    value: {
      fn: ``,
      template: ``,
      type: RecommendationType.MANUAL,
    },
  },
  {
    description: `Display items that your customers viewed together`,
    label: `Viewed Together`,
    value: {
      fn: ``,
      template: ``,
      type: RecommendationType.VIEWD_TOGETHER,
    },
  },
  {
    description: `Display items based on your customers behaviour`,
    label: `personalized`,
    value: {
      fn: ``,
      template: ``,
      type: RecommendationType.PERSONALIZED,
    },
  },
  {
    description: `Display items frequently purchased together`,
    label: `Purchased Together`,
    value: {
      fn: ``,
      template: ``,
      type: RecommendationType.PURCHASED_TOGETHER,
    },
  },
  {
    description: `Display items based on custom logic`,
    label: `Advanced Ruling`,
    value: {
      fn: ``,
      template: ``,
      type: RecommendationType.ADVANCED_RULING,
    },
  },
  {
    description: `Display items that were sold the most in the past 30 days`,
    label: `Most Popular`,
    value: {
      fn: ``,
      template: ``,
      type: RecommendationType.MOST_POPULAR,
    },
  },
  {
    description: `Display items that just arrived`,
    label: `New Arrivals`,
    value: {
      fn: ``,
      template: ``,
      type: RecommendationType.NEW_ARRIVALS,
    },
  },
  {
    description: `Show purchased products`,
    label: `Purchased Items`,
    value: {
      fn: ``,
      template: ``,
      type: RecommendationType.PAST_PURCHASES,
    },
  },
  {
    description: `Show items that the users viewed on recent visits`,
    label: `Recently Viewed`,
    value: {
      fn: ``,
      template: ``,
      type: RecommendationType.RECENTLY_VIEWED,
    },
  },
];
