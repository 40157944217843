/* eslint-disable no-plusplus */
import React, { Reducer, useContext, useReducer } from 'react';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import { QBItemSelection } from '@/components/query-builder/models';
import {
  reducer,
  Rule,
  State,
  transform,
} from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/context/reducer';
import { Action } from '@/features/editor/widgets/custom-widget/loading-section/advanced-ruling/context/actions';
import {
  CatalogWidgetProps,
  ManualProduct,
} from '@/webapi/use-widget-catalog-api';

interface AdvancedRulingCtx {
  isOneLineCond: (n: number) => boolean;
  getCondition: (n: number) => {
    cond: Array<QBItemSelection>;
    itemSelection: string;
  };
  setCondition: (
    num: number,
    cond: Array<QBItemSelection>,
    itemSelection: string,
  ) => void;
  isOpen: (n: number) => boolean;
  toggleOpen: (n: number) => void;
  addAnother: () => void;
  ruleIds: Array<number>;
  getRuleProducts: (id: number) => Array<ManualProduct>;
  save: () => void;
  updateProducts: (products: Array<ManualProduct>) => void;
  getActiveRuleId: () => number;
  toggleSearchMode: (isActive: boolean) => void;
  toggleImageSelectMode: (isActive: boolean) => void;
  inSearchMode: boolean;
  imageSelectMode: boolean;
  cantSave: () => boolean;
  deleteRule: (id: number) => void;
  ruleIdx: (id: number) => number;
}

export const AdvancedRuling = React.createContext({} as AdvancedRulingCtx);

export function newAdvancedRulingCtx() {
  const { currentSchema, updateAdvancedRulingOptions } =
    useContext(CustomWidgetContext);
  const initialRules = getInitialState(currentSchema);

  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    reducer,
    initialRules,
  );

  function setCondition(
    num: number,
    condition: Array<QBItemSelection>,
    itemSelection: 'some_items' | 'all_items',
  ) {
    dispatch({ type: `UPDATE_CONDITION`, condition, itemSelection, id: num });
  }

  function isOpen(n: number): boolean {
    return state.rules?.find((x) => x.id === n)?.isOpen;
  }

  function toggleOpen(n: number) {
    dispatch({ type: `TOGGLE_OPEN`, id: n });
  }

  function addAnother() {
    dispatch({ type: `ADD_RULE` });
  }

  function toggleSearchMode(isActive: boolean) {
    dispatch({ type: `TOGGLE_SEARCH_MODE`, isActive });
  }

  function deleteRule(id: number) {
    dispatch({ type: `DELETE_RULE`, id });
  }

  function ruleIdx(id: number) {
    return (state.rules?.findIndex((r) => r.id === id) || 0) + 1;
  }

  function toggleImageSelectMode(isActive: boolean) {
    dispatch({ type: `TOGGLE_IMAGE_SELECT_MODE`, isActive });
  }

  function getCondition(n: number) {
    return getRuleCond(state.rules, n);
  }

  function isOneLineCond(n: number) {
    const envKey = state.rules.find(({ id }) => id === n)?.condition?.[0]
      ?.qbProps?.envKey;
    return (
      [`cartSize`, `cartPrice`, `item`].includes(envKey) ||
      state?.rules?.length === 0
    );
  }

  function save() {
    dispatch({ type: `SAVE` });
    updateAdvancedRulingOptions(transform(state));
  }

  function getActiveRuleProducts(id: number) {
    return state.rules.find((x) => x.id === id)?.manualProducts || [];
  }

  function getActiveRuleId() {
    return state.rules.find((x) => x.isOpen)?.id || 0;
  }

  function updateProducts(products: Array<ManualProduct>) {
    dispatch({ type: `UPDATE_PRODUCTS`, products });
  }

  function cantSave(): boolean {
    return !!state.rules.find(
      (r) =>
        !r.manualProducts ||
        r.manualProducts.length === 0 ||
        !r.condition ||
        r.condition.length === 0,
    );
  }

  return {
    deleteRule,
    getActiveRuleId,
    updateProducts,
    save,
    getRuleProducts: getActiveRuleProducts,
    getCondition,
    setCondition,
    isOneLineCond,
    isOpen,
    toggleOpen,
    addAnother,
    ruleIds: state.rules.map((r) => r.id),
    toggleSearchMode,
    inSearchMode: state.searchMode,
    imageSelectMode: state.imageSelectMode,
    toggleImageSelectMode,
    ruleIdx,
    cantSave,
  };
}

function getRuleCond(rules: Array<Rule>, num: number) {
  const rule = rules?.find((x) => x.id === num);
  return {
    cond: JSON.parse(
      JSON.stringify(rule?.condition || []),
    ) as Array<QBItemSelection>,
    itemSelection: `${rule?.itemSelection || `all_items`}`,
  };
}

function getInitialState(currentSchema: CatalogWidgetProps) {
  const rules = getRules(currentSchema).map(
    (x) =>
      ({
        id: x.num,
        itemSelection: x.itemSelection,
        isOpen: false,
        isOneLineCond: false,
        condition: x.condition,
        manualProducts: x.manualProducts,
      } as Rule),
  );
  if (rules.length === 0) {
    rules.push({
      id: 1,
      isOpen: true,
    } as Rule);
  } else if (rules.length === 1) {
    rules[0].isOpen = true;
  }
  return {
    rules,
  };
}

function getRules(currentSchema: CatalogWidgetProps) {
  return (
    currentSchema?.settings?.loading?.loadingEnv?.recommendationOptions
      ?.advancedRuling || []
  );
}
