import md5 from 'blueimp-md5';
import { SlotState } from '@/features/editor/widgets/custom-widget/loading-section/shared/models';
import { RecommendationType } from '@/webapi/use-widget-catalog-api';

export function hashSlot(slot: SlotState) {
  const clone = JSON.parse(JSON.stringify(slot)) as SlotState;
  return md5(
    JSON.stringify({
      type: clone.strategy || RecommendationType.MANUAL,
      filter: clone.filter.sort((a, b) =>
        a.qbProps.envKey.localeCompare(b.qbProps.envKey),
      ),
    }),
  );
}
