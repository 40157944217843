import React, { FC } from 'react';
import styled from 'styled-components';

type Props = {
  isVisible: boolean;
  filterLen: number;
  onMoreFilters: () => void;
};
export const MoreFilters: FC<Props> = ({
  isVisible,
  filterLen,
  onMoreFilters,
}) => {
  const moreFiltersText = getMoreFiltersText(filterLen);

  if (!isVisible) {
    return null;
  }
  return <Wrapper onClick={onMoreFilters}>{moreFiltersText}</Wrapper>;
};
export const Wrapper = styled.span`
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #969696;
  user-select: none;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  &:active {
    cursor: default;
    opacity: 1;
  }
`;

function getMoreFiltersText(filterLen: number) {
  return filterLen > 1 ? `more filters (${filterLen})` : `more filters`;
}
