import React, { useContext } from 'react';
import styled from 'styled-components';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import {
  CheckoutExtensibilityAppsStep,
  CheckoutExtensibilityContext,
  newCheckoutExtensibilityContext,
} from '@/features/editor/widgets/checkout-extensibility/context';
import { CheckoutInspectorAppsList } from '@/features/editor/widgets/checkout-extensibility/checkout-inspector-apps-list';
import { CheckoutInspectorWidgetList } from '@/features/editor/widgets/checkout-extensibility/checkout-inspector-widget-list';
import { CheckoutInspectorWidgetStyleAdvanced } from '@/features/editor/widgets/checkout-extensibility/checkout-inspector-widget-style-advanced';
import { CheckoutInspectorWidgetStyle } from '@/features/editor/widgets/checkout-extensibility/checkout-inspector-widget-style';
import { EditorDeclarativeBlock } from '@/webapi/use-experience-api';
import { GlossyWrapper } from '@/components/glossy-wrapper';
import { EditorContext } from '@/features/editor/context/editor-context';
import { BigButton } from '@/components/big-button';
import { DeviceType } from '@/utils/definitions';
import { UndoRedoBtns } from '@/components/undo-redo-btns';
import { newCustomWidgetMockContext } from '@/features/editor/widgets/checkout-extensibility/custom-widget-mock-context';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';

export interface CheckoutAppsListProps {
  change: EditorDeclarativeBlock;
  initialStep: CheckoutExtensibilityAppsStep;
}

export function CheckoutInspectorApps({
  initialStep,
  change,
}: CheckoutAppsListProps) {
  const ctx = newCheckoutExtensibilityContext(initialStep, change);
  const mockCustomWidgetContext = newCustomWidgetMockContext(ctx);
  return (
    <CheckoutExtensibilityContext.Provider value={ctx}>
      <CustomWidgetContext.Provider value={mockCustomWidgetContext}>
        <InspectorWidgetLayout
          title={
            ctx.isUpdateWidgetFlow
              ? `Edit Widget`
              : SUB_PAGES[ctx.currentStep].title
          }
          backCaption={
            ctx.isUpdateWidgetFlow
              ? `< Back to changes`
              : SUB_PAGES[ctx.currentStep].back
          }
          onBackClicked={ctx.onBack}
          progress={SUB_PAGES[ctx.currentStep].progress}
          footer={<Footer />}
        >
          {ctx.currentStep === CheckoutExtensibilityAppsStep.APP_LISTING && (
            <CheckoutInspectorAppsList />
          )}
          {ctx.currentStep === CheckoutExtensibilityAppsStep.WIDGET_LISTING && (
            <CheckoutInspectorWidgetList app={ctx.currentApp} />
          )}
          {ctx.currentStep === CheckoutExtensibilityAppsStep.WIDGET_STYLE && (
            <CheckoutInspectorWidgetStyle key={ctx.undoRedoCount} />
          )}
          {ctx.currentStep ===
            CheckoutExtensibilityAppsStep.WIDGET_STYLE_ADVANCED && (
            <CheckoutInspectorWidgetStyleAdvanced key={ctx.undoRedoCount} />
          )}
        </InspectorWidgetLayout>
      </CustomWidgetContext.Provider>
    </CheckoutExtensibilityContext.Provider>
  );
}

function Footer() {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);

  const {
    redo,
    undo,
    canUndo,
    canRedo,
    onSave,
    isUpdateWidgetFlow,
    currentStep,
  } = useContext(CheckoutExtensibilityContext);

  if (
    ![
      CheckoutExtensibilityAppsStep.WIDGET_STYLE,
      CheckoutExtensibilityAppsStep.WIDGET_STYLE_ADVANCED,
    ].includes(currentStep)
  ) {
    return null;
  }

  return (
    <FooterSection>
      <UndoRedoBtns
        redo={redo}
        undo={undo}
        canUndo={canUndo}
        canRedo={canRedo}
      />
      <BigButton
        disabled={false}
        color="white"
        background="black"
        noTransform
        fillWidth
        size={device === DeviceType.Desktop ? `medium-thin` : `medium`}
        onClick={onSave}
      >
        {isUpdateWidgetFlow ? `Update` : `Save`}
      </BigButton>
    </FooterSection>
  );
}

const FooterSection = styled(GlossyWrapper)`
  && {
    display: grid;
    grid-template-columns: 0.5fr 2fr;

    grid-gap: 2rem;
    z-index: 50;
    margin-left: -2rem;
    margin-bottom: 2rem;
  }
`;

const SUB_PAGES = {
  [CheckoutExtensibilityAppsStep.APP_LISTING]: {
    title: `1. What would you like to add?`,
    back: `< Back to changes`,
    progress: 20,
  },
  [CheckoutExtensibilityAppsStep.WIDGET_LISTING]: {
    title: `2. Choose a template?`,
    back: `< Back to categories`,
    progress: 20,
  },
  [CheckoutExtensibilityAppsStep.WIDGET_STYLE]: {
    title: `3. All great, let's customize`,
    back: `< Back to widgets`,
    progress: 80,
  },
  [CheckoutExtensibilityAppsStep.WIDGET_STYLE_ADVANCED]: {
    title: `Detailed Styling`,
    back: `< Back to customization`,
    progress: 80,
  },
};
