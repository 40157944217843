/* eslint-disable no-nested-ternary */
import React, { useContext, useRef, useState } from 'react';
import { useFacebookApi } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/api';
import { AdAccount } from '@/features/integrations/facebook/models';
import { FacebookTargeting, isEmpty } from '@/components/query-builder/models';
import { EditorContext } from '@/features/editor/context/editor-context';
import { clone } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/shared';

export enum FBView {
  AD_ACCOUNT_SELECT = `AD_ACCOUNT_SELECT`,
  SEARCH = `SEARCH`,
  SUCCESS = `SUCCESS`,
  CONNECT = `CONNECT`,
  VALIDATION = `VALIDATION`,
}

export interface FBAudienceContext {
  step: FBView;
  setStep: (x: FBView) => void;
  adAccounts: Array<AdAccount>;
  markAdAccountAsSelected: (a: AdAccount) => void;
  init: () => void;
  reInit: () => Promise<void>;
  targeting: React.MutableRefObject<FacebookTargeting>;
  revalidateBit: boolean;
  revalidate: () => void;
  validationLoading: boolean;
  setValidationLoading: (x: boolean) => any;
}

export const FacebookAudienceContext = React.createContext(
  {} as FBAudienceContext,
);

function calcInitialStep(targeting: React.MutableRefObject<FacebookTargeting>) {
  return isEmpty(targeting.current)
    ? FBView.AD_ACCOUNT_SELECT
    : targeting.current?.timeframe?.value
    ? FBView.VALIDATION
    : FBView.SEARCH;
}

export function newFacebookAudienceContext(isVisible: boolean) {
  const editorContext = useContext(EditorContext);
  const { listAdAccounts } = useFacebookApi();
  const [reValidate, setReValidate] = useState(false);
  const savedTargeting =
    editorContext?.experienceState?.currentExperience?.targeting?.facebook ||
    {};
  const targeting = useRef<FacebookTargeting>({
    campaigns: [],
    ads: [],
    adsets: [],
    ...clone(savedTargeting),
  });
  const [step, setStep] = useState(calcInitialStep(targeting));
  const initialized = useRef(false);
  const [adAccounts, setAdAccounts] = useState<Array<AdAccount>>([]);

  const [validationLoading, setValidationLoading] = useState(false);

  function markAdAccountAsSelected(account: AdAccount) {
    setAdAccounts([
      ...adAccounts.map((a) => {
        if (a.id === account.id) {
          a.isSelected = true;
        }
        return a;
      }),
    ]);
  }

  async function init() {
    if (isVisible && !initialized.current) {
      const adAccounts = await listAdAccounts();
      setAdAccounts(adAccounts.accounts);
      if (adAccounts.notLoggedIn) {
        setStep(FBView.CONNECT);
      } else if (!adAccounts.accounts.find((x) => x.isSelected)) {
        setStep(FBView.AD_ACCOUNT_SELECT);
      } else if (
        !targeting?.current?.timeframe ||
        isEmpty(targeting?.current)
      ) {
        setStep(FBView.SEARCH);
      } else {
        setStep(FBView.VALIDATION);
      }
      initialized.current = true;
    }
  }

  async function reInit() {
    initialized.current = false;
    await init();
  }

  return {
    revalidateBit: reValidate,
    revalidate: () => setReValidate(!reValidate),
    step,
    markAdAccountAsSelected,
    reInit,
    setStep,
    init,
    adAccounts,
    targeting,
    validationLoading,
    setValidationLoading,
  };
}
