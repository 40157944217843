import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { FacebookAudienceContext } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/context';
import { VSpace } from '@/components/spacing';
import { QBTimeframeSuffix } from '@/components/query-builder/timeframe-suffix';

const Container = styled.div`
  width: 100%;
`;

export const Success: FC = () => {
  const { targeting } = useContext(FacebookAudienceContext);
  return (
    <Container>
      <StaticImage
        src="./nohead.svg"
        alt="success"
        placeholder="none"
        formats={[`webp`, `png`]}
        loading="eager"
        quality={100}
        height={100}
      />
      <VSpace value={2} />
      <SubTitle>
        The changes you make to this experience will only be shown to visitors
        <br />
        coming from the following Campaigns/Ads/Adsets
      </SubTitle>
      <SelectContainer>
        <div style={{ width: `230px` }}>
          <QBTimeframeSuffix
            onChange={(timeframe) => {
              targeting.current = { ...targeting.current, timeframe };
            }}
            initialValue={targeting?.current?.timeframe}
          />
        </div>
      </SelectContainer>
      <VSpace />
      <TargetedEntities />
    </Container>
  );
};

const NamesContainer = styled.div`
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    padding: 0 2rem;
    user-select: text;
    margin: 0.5rem 0;
  }

  span {
    user-select: text;
  }
`;

export const TargetedEntities: FC = () => {
  const { targeting } = useContext(FacebookAudienceContext);

  const adsets = targeting.current.adsets?.filter((set) => !set.isExclude);
  const ads = targeting.current.ads?.filter((ad) => !ad.isExclude);
  const { campaigns } = targeting.current;
  return (
    <NamesContainer>
      {campaigns?.map((campaign) => (
        <p key={campaign.id}>{campaign.name}</p>
      ))}
      {adsets?.map((adset) => (
        <p key={adset.id}>
          <span>{adset.campaignName}</span>
          {`>`}
          <span>{adset.name}</span>
        </p>
      ))}
      {ads?.map((ad) => (
        <p key={ad.id}>
          <span>{ad.campaignName}</span>
          {`>`}
          <span>{ad.adsetName}</span>
          {`>`}
          <span>{ad.name}</span>
        </p>
      ))}
    </NamesContainer>
  );
};

const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubTitle = styled.p`
  text-align: center;
  font-family: Inter, serif;
  font-size: 1.7rem;
  font-weight: 500;
`;
