import { useEffect, useRef, useState } from 'react';
import { useDetachedState } from '@/components/hooks/use-detached-state';
import { strategyOptions } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/rule/strategy/utils';

export function useScroll() {
  const scrollRef = useRef(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scrollPos, setScrollPos] = useDetachedState(
    scrollRef?.current?.scrollLeft || 0,
  );
  const onScroll = (ev) => {
    if (ev?.target) setScrollPos(Math.round(ev.target.scrollLeft));
  };
  useEffect(() => {
    (scrollRef?.current as HTMLDivElement)?.addEventListener?.(
      `scroll`,
      onScroll,
    );
    scrollRef.current.scrollLeft = 0;
    return () => {
      (scrollRef?.current as HTMLDivElement)?.removeEventListener?.(
        `scroll`,
        onScroll,
      );
    };
  }, []);
  const options = strategyOptions();

  const [showPrev, setShowPrev] = useState(
    scrollRef?.current?.scrollLeft === 0,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_1, setIsHoveringPrev, isHoveringPrev] = useDetachedState(false);

  const [showNext, setShowNext] = useState(
    scrollRef?.current?.scrollWitdh > scrollRef?.current?.clientWidth,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_2, setIsHoveringNext, isHoveringNext] = useDetachedState(false);
  const onPrevHover = () => {
    setIsHoveringPrev(true);
    movePrev();
  };

  const onPrevExitHover = () => {
    setIsHoveringPrev(false);
  };
  const movePrev = () => {
    setTimeout(() => {
      if (isHoveringPrev?.current) {
        scrollRef?.current?.scrollBy(-2, 0);
        movePrev();
      }
    }, 5);
  };

  const onNextHover = () => {
    setIsHoveringNext(true);
    moveNext();
  };

  const moveNext = () => {
    setTimeout(() => {
      if (isHoveringNext?.current) {
        scrollRef?.current?.scrollBy(2, 0);
        moveNext();
      }
    }, 5);
  };

  const onNextExitHover = () => {
    setIsHoveringNext(false);
  };

  useEffect(() => {
    if (scrollRef) {
      const endLeftover =
        scrollRef.current.scrollWidth -
        scrollRef.current.clientWidth -
        scrollPos;

      const startLeftOver = scrollRef?.current?.scrollLeft;

      if (endLeftover <= 20) {
        setShowNext(false);
      } else {
        setShowNext(true);
      }

      if (startLeftOver >= 10) {
        setShowPrev(true);
      } else {
        setShowPrev(false);
      }
    }
  }, [scrollPos]);
  return {
    scrollRef,
    options,
    showPrev,
    showNext,
    onPrevHover,
    onPrevExitHover,
    onNextHover,
    onNextExitHover,
  };
}
