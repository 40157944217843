/* eslint-disable no-promise-executor-return,@typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from 'react';
import { useDebounce } from 'react-use';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import { BaseMessage } from '@/features/editor/context/use-device-preview';

export const isBrowser = () => typeof window !== `undefined`;

export async function nav(path: string) {
  if (!window?.parallelTabs) {
    return navigate(path);
  }
  const aliasQueryParam = getAliasQueryParam();
  if (aliasQueryParam) {
    const url = new URL(`${window.location.origin}${path}`);
    url.searchParams.set(`al`, aliasQueryParam);
    return navigate(url.pathname + url.search);
  }
  return navigate(path);
}

export function openLinkInNewTab(
  link: string,
  tabName: string,
  cb?: () => void,
) {
  if (isSafari()) {
    const newWin = window.open(link, tabName);
    if (isWindowBlocked(newWin)) {
      popupWarningModal();
      cb && cb();
      return;
    }
    newWin?.close(); // safari bug preventing focus of existing tabs
  }
  const newWin = window.open(link, tabName);
  if (isWindowBlocked(newWin)) {
    popupWarningModal();
    cb && cb();
    return;
  }

  newWin?.focus();
  cb && cb();
}

function popupWarningModal() {
  toast(
    `To view the preview link tab, please enable it from the address bar.`,
    {
      theme: `colored`,
      type: `warning`,
    },
  );
}

function isSafari() {
  // @ts-ignore
  return window.safari !== undefined;
}

function isWindowBlocked(newWin: Window) {
  return !newWin || newWin.closed || typeof newWin.closed === `undefined`;
}

export function getAliasQueryParam() {
  if (!isBrowser()) {
    return ``;
  }
  return new URL(window?.location?.href)?.searchParams?.get(`al`) || ``;
}

export function getDeviceQueryParam() {
  if (!isBrowser()) {
    return ``;
  }
  return new URL(window?.location?.href)?.searchParams?.get(`device`) || ``;
}

export function getAppStarterQueryParam() {
  if (!isBrowser()) {
    return ``;
  }
  return new URL(window?.location?.href)?.searchParams?.get(`app`) || ``;
}

export function getAudienceStarterQueryParam() {
  if (!isBrowser()) return ``;
  return new URL(window?.location?.href)?.searchParams?.get(`audience`) || ``;
}

export const setQueryParam = (key: string, value: string) => {
  if (isBrowser()) {
    const url = new URL(window?.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, ``, url);
  }
};

export function setHttpsPrefix(url: string): string {
  let temp = url.replaceAll(`http://`, ``);
  temp = temp.replaceAll(`https://`, ``);
  return `https://${temp}`;
}

export const getGUID = () => `id${Math.random().toString(16).slice(2)}`;

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener(`resize`, handleResize);
    return () => window.removeEventListener(`resize`, handleResize);
  }, []);

  return windowDimensions;
}

export function useDebouncedWindowDims(ms = 300) {
  const { height, width } = useWindowDimensions();
  const [dims, setDims] = useState({ height, width });

  useDebounce(
    () => {
      setDims({ height, width });
    },
    ms,
    [height, width],
  );
  return dims;
}

export function getWindowDimensions() {
  if (typeof window === `undefined`) {
    return {
      width: 0,
      height: 0,
    };
  }
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const sendMessageToIframe = (
  msg: BaseMessage,
  iframeRef: React.MutableRefObject<HTMLIFrameElement>,
) => {
  if (!iframeRef?.current?.contentWindow?.postMessage) {
    setTimeout(sendMessageToIframe, 500, msg);
  } else {
    iframeRef?.current?.contentWindow?.postMessage(msg, `*`);
  }
};

declare global {
  interface Window {
    parallelTabs?: boolean;
    nativeLocalStorage: typeof localStorage;
  }
}

export function openNewTab(link: string, target: string) {
  openLinkInNewTab(link, target);
}
