import { useContext } from 'react';
import { getAvailableStrategies } from '@/features/editor/widgets/custom-widget/loading-section/util';
import {
  LoadingStrategyOption,
  RecommendationType,
} from '@/webapi/use-widget-catalog-api';
import { LayeredRulingContext } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';

export function strategyOptions() {
  const ctx = useContext(LayeredRulingContext);
  if (ctx.isPostPurchase) {
    return [
      {
        value: {
          type: RecommendationType.MANUAL,
          templateFileName: `loading.ejs`,
          template: ``,
          fnFileName: `loading.js`,
          fn: ``,
        },
        description: `Choose the specific items you׳d like to display`,
        label: `Vary strategy/product per slot`,
      } as LoadingStrategyOption,
    ];
  }
  const { options } = getAvailableStrategies();
  return options
    .filter((o) => o.value.type !== RecommendationType.ADVANCED_RULING)
    .map((o) =>
      o.value.type === RecommendationType.MANUAL
        ? {
            ...o,
            description: `Choose the specific items you׳d like to display`,
            label: `Vary strategy/product per slot`,
          }
        : o,
    );
}
