import React, { useContext } from 'react';
import { Footer } from '@/features/editor/widgets/shared/modals/commons';
import { LayeredRulingContext } from '@/features/editor/widgets/custom-widget/loading-section/layered-ruling/context';
import {
  RecommendationOptions,
  RecommendationType,
} from '@/webapi/use-widget-catalog-api';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';

type Props = { close: () => void };

export function ModalFooter({ close }: Props) {
  const ctx = useContext(LayeredRulingContext);
  const { updateRecommendationOptions } = useContext(CustomWidgetContext);
  const onClick = () => {
    if (ctx.state.showCondBuilder) {
      ctx.toggleCondBuilder();
      return;
    }
    updateRecommendationOptions({
      type: RecommendationType.ADVANCED_RULING,
      layeredRuling: ctx.state.rules,
      strategyPerSlot: undefined,
      advancedRuling: undefined,
      condition: undefined,
      manualProducts: undefined,
      productIds: undefined,
    } as RecommendationOptions);
    close();
  };
  let text = `Save`;
  if (ctx.state.showCondBuilder) {
    text = `Continue`;
  }
  if (ctx.isProductSearchOpen || ctx.isManualImageSelectionOpen) {
    return null;
  }

  return <Footer onClick={onClick} bg="black" size="xl" text={text} />;
}
