import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { VSpace } from './spacing';

export interface PaginationProps {
  pageCount: number;
  pageSize: number;
  total: number;
  onPageChanged?: (page: number) => void;
  loading?: boolean;
  value: number;
  label?: string;
  windowSize?: number;
  noTotalSummary?: boolean;
}

export function Pagination({
  pageCount,
  pageSize,
  total,
  onPageChanged,
  loading,
  value,
  label,
  windowSize = 10,
  noTotalSummary,
}: PaginationProps) {
  const arr = pageArr(pageCount);
  const [current, setCurrent] = useState(value);

  const onPageClick = (page: number) => {
    setCurrent(page);
  };

  const onBack = () => {
    setCurrent(current - 1);
  };

  const onForward = () => {
    setCurrent(current + 1);
  };

  useEffect(() => {
    if (onPageChanged) {
      onPageChanged(current);
    }
  }, [current]);

  useEffect(() => {
    setCurrent(value);
  }, [value]);

  const getWindowOffsets = useCallback(() => {
    let left = current - 1 - Math.ceil(windowSize / 2);
    let right = current - 1 + Math.floor(windowSize / 2);

    if (left < 0) {
      const leftOver = left * -1;
      left = 0;
      right = Math.min(right + leftOver, Math.ceil(pageCount));
    }

    return { left, right };
  }, [windowSize, current, pageCount]);

  return (
    <Wrapper loading={loading} total={total}>
      <PageWrapper>
        <LeftArrow onClick={onBack} enabled={current > 1} />
        {arr
          .map((page) => (
            <Page
              key={`page-${page}`}
              selected={current === page}
              onClick={() => {
                onPageClick(page);
              }}
            >
              {page}
            </Page>
          ))
          .slice(getWindowOffsets().left, getWindowOffsets().right)}
        <RightArrow onClick={onForward} enabled={current < pageCount} />
      </PageWrapper>
      <VSpace value={2} />
      {!noTotalSummary && (
        <Progress>
          {(current - 1) * pageSize + 1} -{` `}
          {Math.min(current * pageSize, total)} of {total}
          {` `}
          {label || `experiences`}
        </Progress>
      )}
    </Wrapper>
  );
}

function LeftArrow({
  enabled,
  onClick,
}: {
  enabled: boolean;
  onClick: () => void;
}) {
  return (
    <div
      style={{ cursor: `pointer`, pointerEvents: enabled ? `auto` : `none` }}
      onClick={onClick}
    >
      {enabled ? (
        <StaticImage
          height={15}
          src="../assets/left_arrow_active.svg"
          alt="pagination back"
          placeholder="none"
          loading="eager"
        />
      ) : (
        <StaticImage
          height={15}
          src="../assets/left_arrow_disabled.svg"
          alt="pagination back"
          placeholder="none"
          loading="eager"
        />
      )}
    </div>
  );
}

function RightArrow({
  enabled,
  onClick,
}: {
  enabled: boolean;
  onClick: () => void;
}) {
  return (
    <div
      style={{ cursor: `pointer`, pointerEvents: enabled ? `auto` : `none` }}
      onClick={onClick}
    >
      {enabled ? (
        <StaticImage
          height={15}
          src="../assets/right_arrow_active.svg"
          alt="pagination forward"
          placeholder="none"
          loading="eager"
        />
      ) : (
        <StaticImage
          height={15}
          src="../assets/right_arrow_disabled.svg"
          alt="pagination forward"
          placeholder="none"
          loading="eager"
        />
      )}
    </div>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: ${(props: { loading?: boolean; total: number }) =>
    props.loading ? `none` : `auto`};
  display: ${(props: { loading?: boolean; total: number }) =>
    props.total > 0 ? `auto` : `none`};
  user-select: none;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  && > span {
    margin-right: 2rem;
  }

  && > div {
    margin-right: 4rem;
    margin-left: 2rem;
  }
`;

const Progress = styled.div`
  width: 100%;
  color: #333333;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-family: Eesti, serif;
`;

const Page = styled.span`
  user-select: none;
  cursor: pointer;

  height: 3.5rem;
  width: 3.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props: { selected: boolean }) =>
    props.selected ? `#222222` : `transparent`};

  color: ${(props: { selected: boolean }) =>
    props.selected ? `white` : `#220200`};

  border: ${(props: { selected: boolean }) =>
    props.selected ? `1px solid #222222` : `1px solid rgba(231,235,237,0.75)`};

  border-radius: 50%;

  font-size: 1.4rem;
  font-family: Eesti, serif;
  font-weight: 600;

  transition: background-color 0.3s ease-out;

  :hover {
    background-color: ${(props: { selected: boolean }) =>
      props.selected ? `black` : `#eeeded`};
  }
`;

function pageArr(count: number) {
  const arr = [];
  for (let i = 0; i < count; i += 1) {
    arr.push(i + 1);
  }
  return arr;
}
