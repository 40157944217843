import styled from 'styled-components';
import React, { MutableRefObject, useMemo, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { GradientModal } from '@/components/gradient-modal';
import { centered } from '@/components/use-shared-element';
import { BigButton } from '@/components/big-button';
import { TextInput } from '@/components/text-input';
import { Label, LabelsList } from '@/components/labels-list';
import { ImageThumbnailGallery } from '@/features/shared/experience-info-modal/image-thumbnail-gallery';
import {
  ExperienceInfoRequest,
  ExperienceInfoResponse,
  useExperienceApi,
} from '@/webapi/use-experience-api';
import { BackdropSpinner } from '@/components/backdrop-spinner';
import { RichTextDescription } from '@/features/shared/experience-info-modal/rich-text-description';

export interface ExperienceInfoModalProps {
  fromRef: MutableRefObject<any>;
  visible?: boolean;
  setVisible?: (s: boolean) => void;
  experienceId?: string;
  onDataChanged?: (data: ExperienceInfoResponse) => void;
}

export function ExperienceInfoModal({
  experienceId,
  fromRef,
  visible,
  setVisible,
  onDataChanged,
}: ExperienceInfoModalProps) {
  const modalCfg = getModalConfig(fromRef);

  const { getExperienceInfo, updateExperienceInfo, removeLabels, loading } =
    useExperienceApi();

  const [data, setData] = useState<ExperienceInfoResponse | null>(null);

  const transformedLabels = useMemo<Label[]>(
    () =>
      data?.labels?.map((l) => ({
        label: l?.label,
        isSelected: l?.selected === true,
      })) || [],
    [data],
  );

  useAsyncEffect(async () => {
    const info = await getExperienceInfo(experienceId);
    setData(info);
  }, [experienceId]);

  const onClose = () => {
    setVisible(false);
  };

  const onNameChanged = (ev: any) => {
    setData((draft) => ({ ...draft, name: ev.target.value }));
  };

  const onDescriptionChanged = (value: string) => {
    setData((draft) => ({ ...draft, description: value }));
  };

  const onBeforeImagesChanged = (images: string[]) => {
    setData((draft) => ({ ...draft, beforeImages: images }));
  };

  const onAfterImagesChanged = (images: string[]) => {
    setData((draft) => ({ ...draft, afterImages: images }));
  };

  const onLabelAdded = async (label: string) => {
    setData((draft) => ({
      ...draft,
      labels: [...(draft.labels || []), { label, selected: true }],
    }));
  };

  const onLabelRemoved = async (label: string) => {
    await removeLabels([label]);
    setData((draft) => ({
      ...draft,
      labels: draft.labels?.filter((l) => l.label !== label),
    }));
  };

  const onLabelSelected = async (selected: string[]) => {
    const updated = data?.labels?.map((l) => {
      if (selected.includes(l.label)) {
        return { ...l, selected: true };
      }
      return { ...l, selected: false };
    });
    setData((draft) => ({
      ...draft,
      labels: updated,
    }));
  };

  const onDiscard = () => {
    setVisible(false);
  };

  const onUpdate = async () => {
    const updated = { ...data, labels: [] } as ExperienceInfoRequest;
    updated.labels = data?.labels
      ?.filter((l) => l.selected)
      .map((l) => l.label);
    const resp = await updateExperienceInfo(updated);
    setData(resp);
    onDataChanged?.(resp);
    setVisible(false);
  };

  return (
    <GradientModal
      {...modalCfg}
      isVisible={visible}
      onClose={onClose}
      header={<span style={{ marginTop: `-1rem` }}>Edit Experience Info</span>}
      footer={
        <Footer onDiscard={onDiscard} onUpdate={onUpdate} loading={loading} />
      }
    >
      <BackdropSpinner
        backgroundColor="transparent"
        loading={loading || !data}
      />
      {!loading && !!data && (
        <TwoColumnsWithSeparator id="edit-exp-info-modal">
          <Column>
            <Row>
              <Caption>*Name your experience</Caption>
              <ExperienceName
                defaultValue={data?.name}
                onChange={onNameChanged}
              />
            </Row>
            <Row>
              <Caption>Describe your experience</Caption>
              <Frame>
                <RichTextDescription
                  defaultValue={data.description}
                  onChange={onDescriptionChanged}
                />
              </Frame>
            </Row>
          </Column>
          <Separator />
          <Column>
            <Row>
              <Caption>Add labels</Caption>
              <LabelsList
                labels={transformedLabels}
                onRemoveLabel={onLabelRemoved}
                onLabelSelection={onLabelSelected}
                onAddLabel={onLabelAdded}
                allowAppend
                collapseAfter={7}
              />
            </Row>

            <Row>
              <Caption>Add before and after images</Caption>
              <Frame>
                <SmallCaption>Before</SmallCaption>
                <ImageThumbnailGallery
                  onImageListChanged={onBeforeImagesChanged}
                  maxImages={5}
                  allowUpload
                  initialValue={data?.beforeImages || []}
                />
              </Frame>
              <Frame>
                <SmallCaption>After</SmallCaption>
                <ImageThumbnailGallery
                  onImageListChanged={onAfterImagesChanged}
                  maxImages={5}
                  allowUpload
                  initialValue={data?.afterImages || []}
                />
              </Frame>
            </Row>
          </Column>
        </TwoColumnsWithSeparator>
      )}
    </GradientModal>
  );
}

export function Footer({ onDiscard, onUpdate, loading }) {
  return (
    <FooterWrapper>
      <BigButton
        background="#ffffff"
        color="black"
        border="1px solid #D3D3D3"
        noShadow
        br="0.5rem"
        noTransform
        onClick={onDiscard}
        disabled={loading}
      >
        Discard
      </BigButton>
      <BigButton
        noShadow
        br="0.5rem"
        noTransform
        onClick={onUpdate}
        disabled={loading}
      >
        Update
      </BigButton>
    </FooterWrapper>
  );
}

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const TwoColumnsWithSeparator = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: minmax(47rem, 1fr) auto minmax(45rem, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 4rem;
  height: 100%;
  max-height: 50rem;
  padding: 0.5rem 1rem;
`;

const Column = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: space-between;
`;

const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: 15rem 15rem;
  grid-gap: 2rem;

  && > button {
    font-weight: 500 !important;
  }
`;

const Frame = styled.div`
  border: 1px solid rgb(221, 219, 219);
  border-radius: 1.5rem;
  padding: 1.3rem;
`;

const Separator = styled.div`
  background: #979797;
  height: 100%;
  width: 1px;
  opacity: 0.2;
`;

const Caption = styled.p`
  margin: 0;
  padding: 0;
  color: #a6afb8;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: start;
`;

const SmallCaption = styled.p`
  margin: 0;
  padding: 0;
  color: black;
  opacity: 0.38;
  font-size: 1.2rem;
  font-family: Inter, serif;
  font-weight: 300;
  text-align: start;
`;

const ExperienceName = styled(TextInput)`
  && {
    border: 1px solid rgb(221, 219, 219);
    border-radius: 1rem;
    box-shadow: none;
    font-family: Inter, serif;
    font-size: 1.8rem;
    color: black;
    font-weight: 300;
    padding: 1.2rem 1.5rem;
  }
`;

function getModalConfig(fromRef: React.MutableRefObject<any>) {
  const targetPosAndSize = centered(68, 110);

  return {
    targetPosAndSize,
    closeTop: `0.5rem`,
    closeRight: `2.5rem`,
    fromStyle: {
      borderRadius: `5rem`,
      backgroundColor: `#DEDEDE`,
      padding: `2rem 0 0 0`,
    },
    toStyle: {
      borderRadius: `1rem`,
      backgroundColor: `#FFFFFF`,
      padding: `2rem 0 0 0`,
    },
    showBackdrop: true,
    fromRef,
    overflowTop: { heightInRem: 6 },
    overflowBottom: { heightInRem: 8, turnPointInPercent: 30 },
  };
}
