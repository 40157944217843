import React, { useContext } from 'react';
import styled from 'styled-components';
import { QuickPreviewNavigationContext } from '@/features/editor/preview-navigation/context';
import { Search } from '@/components/text-input';
import { SelectTile } from '@/components/select-tile';

function rmTrailingSlash(cleanUrl: string) {
  return cleanUrl?.endsWith(`/`) ? cleanUrl.slice(0, -1) : cleanUrl;
}

export function QuickPreviewNavigationList() {
  const {
    url,
    urlsToBeDisplayed,
    onTextChanged,
    onUrlSelected,
    isCustomUrl,
    allRelated,
    search,
    scrollRef,
  } = useContext(QuickPreviewNavigationContext);
  const cleanUrl = cleanThemePreview(url);
  const convertedUrl = rmTrailingSlash(cleanUrl);
  const indexInitial = urlsToBeDisplayed.findIndex(
    (entry) => convertedUrl === entry.url || url === entry.url,
  );
  const findRule = (list: any[]) =>
    list.find((entry) => url === entry.url || convertedUrl === entry.url);

  return (
    <Wrapper>
      <Search onChange={onTextChanged} setAutoFocus />
      <PagesWrapper>
        <SelectedTile
          initialValue={
            (indexInitial === -1 &&
              (isCustomUrl || (!search && !!findRule(allRelated)?.url))) ||
            indexInitial > 5
          }
          defaultValue={
            isCustomUrl && search
              ? convertedUrl || url
              : cleanThemePreview(findRule(urlsToBeDisplayed)?.caption) ||
                cleanThemePreview(findRule(allRelated)?.caption) ||
                search ||
                convertedUrl
          }
        />
        <Pages ref={scrollRef} className="scroll">
          {urlsToBeDisplayed.map((entry, index) => (
            <SelectTile
              key={entry.url + index.toString()}
              initialValue={url === entry.url || convertedUrl === entry.url}
              onSelected={() => onUrlSelected(entry)}
            >
              {cleanThemePreview(
                entry.caption.replace(`(Category)`, `(Collection)`),
              )}
            </SelectTile>
          ))}
        </Pages>
      </PagesWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3.5rem 1fr;

  input {
    border: 1px solid #e8e8ea !important;
    opacity: 0.77;
  }
`;

const PagesWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
`;

const Pages = styled.div`
  margin-top: 1.5rem;
  overflow-y: scroll;
  overflow-x: hidden;

  && > div {
    background: transparent;
    border: none;
    height: auto;
    text-align: start;
  }

  && > div:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;

function SelectedTile({
  defaultValue,
  initialValue,
}: {
  initialValue: boolean;
  defaultValue: string;
}) {
  if (!initialValue) return null;
  return (
    <SelectTile
      margin="1.5rem 0 -1rem 0"
      key="custom"
      border="1px #DDDDDD solid !important"
      initialValue
    >
      <P>{cleanThemePreview(defaultValue)}</P>
    </SelectTile>
  );
}

const P = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: start;
  font-size: 1.4rem;
  padding: 0 1rem;
  box-shadow: none;
  margin: 0;
  overflow-x: auto;
  color: black;
  font-weight: 600;
  cursor: default;
  white-space: nowrap;
  width: 100%;
  max-width: 100%;
`;

export function cleanThemePreview(url: string) {
  try {
    const l = new URL(url);
    l.searchParams.delete(`preview_theme_id`);
    return l.toString();
  } catch (e) {
    return url;
  }
}
